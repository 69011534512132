import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  Dropdown,
  Header,
  Icon,
  Input,
  InputOnChangeData,
  Label,
  Loader,
  Placeholder,
  Segment,
} from 'semantic-ui-react'
import { isEqual, times } from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import './index.scss'

import { DAY_HOUR, HOUR_MINUTE, ICity, ICustomer, ICustomersFilter, MINUTE_SEC, SEC } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { EModalType, IModalCustomersFilter } from '../../../../store/reducers/modals'
import { CustomerPanel } from './CustomerPanel'
import { CustomerItem } from '../../../../components/CustomerItem'
import { ChevronUpIcon, FilterIcon } from '../../../../components/Icons'
import { checkMobile } from '../../../../utils/deviceUtils'
import { LOCATION_CUSTOMERS } from '../../../../utils/locationUtils'
import { CUSTOMERS_LIMIT } from '../../../../utils/customersUtils'

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  loadingReportCustomers: boolean,
  filter: ICustomersFilter,
  total: number,
  totalWithOrders: number,
  totalWithoutOrders: number,
  customersList: ICustomer[],
  cities: ICity[],
}

type TDispatchedProps = {
  updateFilter: (filter: ICustomersFilter) => Actions.Action,
  apiCustomersList: (filter: ICustomersFilter) => Actions.Action,
  filterModal: (data: IModalCustomersFilter) => Actions.Action,
  getCities: () => Actions.Action,
  getMarkets: (cityId?: string) => Actions.Action,
  getCustomerReport: (filter: ICustomersFilter) => Actions.Action,
}

type TState = {
  listPlaceholder: boolean,
  search: string,
  selectCustomer?: string,
}

type TProps = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

enum ESortField {
  LAST_ORDER = 'last_order',
  FIRST_ORDER = 'first_order',
  REGISTER_DATE = 'register_date',
  NUM_ORDERS = 'num_orders',
}

class CustomersCmp extends React.Component<TProps, TState> {
  ref!: HTMLDivElement
  timer: number | null = null

  constructor(props: TProps) {
    super(props)

    this.state = {
      listPlaceholder: false,
      search: '',
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    const { filter } = this.props

    if (!isEqual(prevProps.filter, filter)) {
      if (filter.city !== prevProps.filter.city) {
        this.props.getMarkets(filter.city)
      }

      if (!filter.search) {
        this.setState({ search: '' })
      }

      this.refresh(filter)
    }
  }

  componentDidMount(): void {
    const { filter } = this.props

    this.props.getCities()
    this.props.getMarkets(filter.city)

    this.refresh(filter)
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    const { customersList, totalWithOrders, totalWithoutOrders } = this.props
    const { formatMessage } = this.props.intl
    const { selectCustomer } = this.state
    const customer = selectCustomer ? customersList.find((item) => item.id === selectCustomer) : null

    return (
      <div className='customers'>
        <div className='customers-title'>
          <Header as='h1'>{formatMessage(messages.Customers)}</Header>
          <div className='customers-title__total'>
            <div className='customers-title__total-value'>
              <span className='customers-title__total-label'>{formatMessage(messages.CustomersR)}:</span>
              {totalWithOrders}
            </div>
            <div className='customers-title__total-dot' />
            <div className='customers-title__total-value'>
              <span className='customers-title__total-label'>{formatMessage(messages.UsersR)}:</span>
              {totalWithoutOrders}
            </div>
          </div>
        </div>
        <div className='customers-container'>
          <div className='customers-container__block'>
            {this.renderHeader()}
            {this.renderContent()}
          </div>
          {!!customer && (
            <div className='customers-customer'>
              <CustomerPanel customer={customer} />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { selectCustomer } = this.state
    const isMobile = checkMobile()

    return (
      <div className='customers-header'>
        <div className='customers-header__inputs'>{this.renderSearch()}</div>
        <div
          className='customers-header__btns'
          style={{ width: selectCustomer && window.innerWidth < 1535 ? '100%' : undefined }}
        >
          {!isMobile && this.renderCity()}
          {this.renderTabs()}
          {this.renderFilter()}
          {this.renderReport()}
        </div>
      </div>
    )
  }

  renderReport = () => {
    const { loadingReportCustomers } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='customers-header__report'>
        <Dropdown
          className='customers-header__report-dropdown'
          text={formatMessage(messages.DownloadReport)}
          loading={loadingReportCustomers}
          disabled={loadingReportCustomers}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.getReport}>
              {formatMessage(messages.DownloadReportIn, { format: '.csv' })}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }

  getReport = () => {
    const { filter, getCustomerReport } = this.props

    getCustomerReport(filter)
  }

  renderSearch = () => {
    const { formatMessage } = this.props.intl
    const { search } = this.state

    return (
      <div className='customers-header__search'>
        <Input
          value={search}
          className='customers-header__search-input'
          labelPosition='right'
          type='text'
          placeholder={formatMessage(messages.SearchByPhoneName)}
          onChange={this.handleSearch}
        >
          <input />
          <Label className='customers-header__search-label'>
            <Icon className='customers-header__search-icon' name='search' />
          </Label>
        </Input>
      </div>
    )
  }

  renderCity = () => {
    const { cities, filter } = this.props
    const { formatMessage } = this.props.intl

    const options = cities.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='customers-header__city'>
        <Dropdown
          className='customers-header__city-dropdown'
          placeholder={formatMessage(messages.City)}
          value={filter.city}
          search
          selection
          clearable
          options={options}
          onChange={this.changeCity}
        />
      </div>
    )
  }

  renderFilter = () => {
    const { filter } = this.props
    const { formatMessage } = this.props.intl
    let activeFilter = false

    if (
      !isEqual(
        filter.firstOrderDateFrom,
        moment(Date.now() - 6 * DAY_HOUR * HOUR_MINUTE * MINUTE_SEC * SEC).format('YYYY-MM-DD'),
      ) ||
      !isEqual(filter.firstOrderDateTo, moment().format('YYYY-MM-DD')) ||
      filter.dateFrom ||
      filter.dateTo ||
      filter.lastOrderDateFrom ||
      filter.lastOrderDateTo
    ) {
      activeFilter = true
    }

    return (
      <div className='customers-header__filter' onClick={this.openFilter}>
        <div className='customers-header__filter-content'>
          <div className='customers-header__filter-icon'>
            <FilterIcon />
          </div>
          {activeFilter && <div className='customers-header__filter-badge' />}
        </div>
        {formatMessage(messages.Filter)}
      </div>
    )
  }

  renderContent = () => {
    const { loading } = this.props
    const { listPlaceholder } = this.state

    return (
      <div className='customers-list' ref={this.saveRef} onScroll={this.checkEnd}>
        {!listPlaceholder ? this.renderList() : this.renderPlaceholder()}
        {loading && (
          <div className='customers-list__loader'>
            <Loader active inline />
          </div>
        )}
      </div>
    )
  }

  renderList = () => {
    const { customersList, filter } = this.props
    const { formatMessage } = this.props.intl
    const { lastOrderDesc, firstOrderDesc, registerDesc, numOrdersDesc } = filter

    return (
      <div className='customers-list__container'>
        <div className='customers-list__header'>
          <div className={classnames('customers-list__header-value', 'customers-list__num')}>№</div>
          <div className={classnames('customers-list__header-value', 'customers-list__phone-name')}>
            {formatMessage(messages.PhoneAndName)}
          </div>
          {this.renderListHeaderItem(
            formatMessage(messages.LastOrder),
            'customers-list__last-order',
            ESortField.LAST_ORDER,
            lastOrderDesc,
          )}
          {this.renderListHeaderItem(
            formatMessage(messages.FirstOrder),
            'customers-list__first-order',
            ESortField.FIRST_ORDER,
            firstOrderDesc,
          )}
          {this.renderListHeaderItem(
            formatMessage(messages.Registration),
            'customers-list__register-date',
            ESortField.REGISTER_DATE,
            registerDesc,
          )}
          {this.renderListHeaderItem(
            formatMessage(messages.Orders),
            'customers-list__orders',
            ESortField.NUM_ORDERS,
            numOrdersDesc,
          )}
          <div className={classnames('customers-list__header-value', 'customers-list__type')}>
            {formatMessage(messages.Status)}
          </div>
        </div>
        <div className='customers-list__rows'>{customersList.map(this.renderItem)}</div>
      </div>
    )
  }

  renderListHeaderItem = (label: string, labelStyle: string, type: ESortField, value?: boolean) => {
    return (
      <div
        className={classnames(
          'customers-list__header-value',
          labelStyle,
          'customers-list__header-value__filter',
          value !== undefined && 'customers-list__header-value__filter-active',
        )}
        onClick={() => this.updateSort(type, value === undefined ? false : !value)}
      >
        {label}
        <div
          className={classnames(
            'customers-list__header-value__icon',
            (value === undefined || value) && 'customers-list__header-value__icon-down',
          )}
        >
          <ChevronUpIcon color={value !== undefined ? '#7677bc' : '#a4aabf'} width={16} height={16} />
        </div>
      </div>
    )
  }

  renderItem = (item: ICustomer, index: number) => {
    return (
      <React.Fragment key={item.id}>
        {index > 0 && <div className='customers-list__divider' />}
        <CustomerItem
          key={item.id}
          num={index + 1}
          customer={item}
          selectedCustomerId={this.state.selectCustomer}
          onSelectCustomer={this.selectCustomer}
        />
      </React.Fragment>
    )
  }

  checkFilterUpdate = (newFilter: ICustomersFilter) => {
    const { filter } = this.props

    if (!isEqual(filter, newFilter)) {
      this.props.updateFilter({
        ...newFilter,
      })
    }
  }

  renderPlaceholder = () => {
    const items: React.ReactNode[] = []

    times(8, (i) =>
      items.push(
        <Segment key={i} className='customers-list__placeholder-item' style={{ borderLeftColor: 'gray' }}>
          <Placeholder fluid>
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='short' />
            <Placeholder.Line length='very short' />
            <Placeholder.Line length='long' />
            <Placeholder.Line length='short' />
            <Placeholder.Line length='very short' />
          </Placeholder>
        </Segment>,
      ),
    )

    return items
  }

  renderTabs = () => {
    const { filter } = this.props

    return (
      <div className='customers-header__tabs'>
        <div
          className={classnames(
            'customers-header__tabs-btn',
            filter.b2b === false && 'customers-header__tabs-btn-active',
          )}
          onClick={() => this.checkFilterUpdate({ ...filter, b2b: false })}
        >
          B2C
        </div>
        <div
          className={classnames(
            'customers-header__tabs-btn',
            filter.b2b === true && 'customers-header__tabs-btn-active',
          )}
          onClick={() => this.checkFilterUpdate({ ...filter, b2b: true })}
        >
          B2B
        </div>
      </div>
    )
  }

  openFilter = () => {
    const { formatMessage } = this.props.intl

    this.props.filterModal({
      type: EModalType.MODAL_CUSTOMERS_FILTER,
      size: 'mini',
      style: { width: '405px', maxWidth: '90%', borderRadius: '16px' },
      close: true,
      props: {
        title: formatMessage(messages.Filter),
      },
    })
  }

  updateSort = (type: ESortField, value: boolean) => {
    const { filter } = this.props

    switch (type) {
      case ESortField.LAST_ORDER:
        this.checkFilterUpdate({
          ...filter,
          lastOrderDesc: value,
          firstOrderDesc: undefined,
          registerDesc: undefined,
          numOrdersDesc: undefined,
        })
        break
      case ESortField.FIRST_ORDER:
        this.checkFilterUpdate({
          ...filter,
          lastOrderDesc: undefined,
          firstOrderDesc: value,
          registerDesc: undefined,
          numOrdersDesc: undefined,
        })
        break
      case ESortField.REGISTER_DATE:
        this.checkFilterUpdate({
          ...filter,
          lastOrderDesc: undefined,
          firstOrderDesc: undefined,
          registerDesc: value,
          numOrdersDesc: undefined,
        })
        break
      case ESortField.NUM_ORDERS:
        this.checkFilterUpdate({
          ...filter,
          lastOrderDesc: undefined,
          firstOrderDesc: undefined,
          registerDesc: undefined,
          numOrdersDesc: value,
        })
        break
    }
  }

  refresh = (filter: ICustomersFilter) => {
    this.props.apiCustomersList({
      ...filter,
      force: true,
    })
  }

  saveRef = (ref: HTMLDivElement) => {
    this.ref = ref
  }

  selectCustomer = (customer: ICustomer) => {
    const { selectCustomer } = this.state
    const isMobile = checkMobile()

    if (isMobile) {
      this.props.history.push(`${LOCATION_CUSTOMERS}/${customer.userId}`)
    }

    this.setState({ selectCustomer: selectCustomer === customer.id ? undefined : customer.id })
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { filter } = this.props
    const { search } = this.state
    const { value } = data
    const searchString = value.trim()

    this.setState({ search: value })

    if (searchString.length >= 3) {
      this.checkFilterUpdate({
        ...filter,
        offset: 0,
        search: searchString,
        dateTo: moment().format('YYYY-MM-DD'),
      })
    } else if (search.length >= 3 && (!searchString || (searchString && searchString.length < 3))) {
      this.checkFilterUpdate({
        ...filter,
        offset: 0,
        search: undefined,
        dateTo: moment().format('YYYY-MM-DD'),
      })
    }
  }

  changeCity = (event: any, data: any) => {
    const { filter } = this.props
    const { value } = data

    this.checkFilterUpdate({ offset: 0, ...filter, city: value })
  }

  checkEnd = () => {
    const { customersList, loading, loaded, filter } = this.props
    const { scrollHeight, offsetHeight, scrollTop } = this.ref

    if (
      !loaded &&
      !loading &&
      scrollHeight - offsetHeight - scrollTop < 300 &&
      !(customersList.length % CUSTOMERS_LIMIT)
    ) {
      this.props.apiCustomersList({
        ...filter,
        offset: customersList.length,
      })
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loaded, loading, filter, customersList, total, totalWithOrders, totalWithoutOrders } = s.customers

  return {
    loaded,
    loading,
    total,
    totalWithOrders,
    totalWithoutOrders,
    filter,
    customersList,
    cities: s.markets.cities || [],
    loadingReportCustomers: s.reports.loadingReportCustomers,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCustomersList: (filter: ICustomersFilter) => dispatch(Actions.action(Actions.API_CUSTOMERS_LIST, filter)),
  updateFilter: (filter: ICustomersFilter) =>
    dispatch(
      Actions.action(Actions.CUSTOMERS_FILTER, {
        ...filter,
      }),
    ),
  filterModal: (data: IModalCustomersFilter) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  getCities: () =>
    dispatch(
      Actions.action(Actions.API_CITIES, {
        include_all: 'True',
      }),
    ),
  getMarkets: (cityId?: string) =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        city: cityId,
        include_all: 'True',
      }),
    ),
  getCustomerReport: (filter: ICustomersFilter) => dispatch(Actions.action(Actions.API_CUSTOMER_REPORT, filter)),
})

export const Customers = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomersCmp))
