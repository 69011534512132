import React from 'react'

export const PanIcon: React.FC = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.74 8.33969C22.0867 8.68631 22.0867 9.24624 21.74 9.59286L20.1136 11.2193L16.7807 7.88642L18.4071 6.25997C18.7538 5.91334 19.3137 5.91334 19.6603 6.25997L21.74 8.33969ZM6 21.9998V18.6669L15.8298 8.83705L19.1627 12.1699L9.33289 21.9998H6Z'
        fill='white'
      />
    </svg>
  )
}
