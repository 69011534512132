import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Dropdown, Form } from 'semantic-ui-react'
import classnames from 'classnames'
import moment from 'moment'
import { isEqual } from 'lodash'

import './index.scss'

import {
  ECourierRole,
  EReportFormat,
  ICourier,
  ICouriersFilter,
  ICourierShiftsFilter,
  IMarket,
  ISlot,
} from '../../../../../types/TClient'

import * as Actions from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import { EModalType, IModalCourierShift } from '../../../../../store/reducers/modals'
import messages from '../../../../../localization/messages'
import { EditIcon } from '../../../../../components/Icons'
import * as storage from '../../../../../utils/storageUtils'
import { formatPhone } from '../../../../../utils/customersUtils'
import { getTimeWithTimeZone } from '../../../../../utils/ordersUtils'

type TConnectedProps = {
  loadingReport: boolean,
  slots: ISlot[],
  couriersList: ICourier[],
  markets: IMarket[],
}

type TDispatchedProps = {
  getCourierShifts: (marketId: string, date: string) => Actions.Action,
  apiCouriersList: (filter: ICouriersFilter) => Actions.Action,
  getSlots: (market: string) => Actions.Action,
  getMarkets: () => Actions.Action,
  apiReportCourierShifts: (filter: ICourierShiftsFilter, type: EReportFormat) => Actions.Action,
  courierShiftModal: (data: IModalCourierShift) => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  market: string,
  date: string,
}

class ShiftScheduleCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    const { markets } = props
    const savedMarket = storage.getValue(storage.COURIERS_MARKET)
    const market =
      markets.length > 0
        ? savedMarket
          ? markets.find((item) => item.id === savedMarket) || markets[0]
          : markets[0]
        : null

    this.state = {
      market: market ? market.id : '',
      date: moment().format('YYYY-MM-DD'),
    }
  }

  componentDidMount(): void {
    const { markets } = this.props
    this.props.getMarkets()

    if (!this.state.market && markets[0]) {
      this.handleChangeMarket(null, { value: markets[0].id })
    } else if (this.state.market) {
      this.props.apiCouriersList({ market: this.state.market })
      this.props.getSlots(this.state.market)
      this.props.getCourierShifts(this.state.market, this.state.date)
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>): void {
    const { markets, slots, couriersList } = this.props

    if (!this.state.market && markets.length > 0) {
      this.setState({
        market: markets[0].id,
      })
    }

    if (prevState.market !== this.state.market) {
      this.props.apiCouriersList({ market: this.state.market })
      this.props.getSlots(this.state.market)
      this.props.getCourierShifts(this.state.market, this.state.date)
    }

    if (!isEqual(prevProps.slots, slots) || !isEqual(prevProps.couriersList, couriersList)) {
      this.props.getCourierShifts(this.state.market, this.state.date)
    }

    if (prevProps.markets.length === 0 && markets.length > 0 && !this.state.market) {
      const savedMarket = storage.getValue(storage.COURIERS_MARKET)
      const market =
        markets.length > 0
          ? savedMarket
            ? markets.find((item) => item.id === savedMarket) || markets[0]
            : markets[0]
          : null

      this.setState({
        market: market ? market.id : '',
      })

      if (!savedMarket && market) {
        this.props.apiCouriersList({ market: market.id })
        this.props.getSlots(market.id)
        storage.setValue(storage.COURIERS_MARKET, market.id)
      }
    }
  }

  render() {
    // const { formatMessage } = this.props.intl

    return (
      <div className='shift-schedule'>
        <div className='shift-schedule-filter'>
          {this.renderMarket()}
          {this.renderDate()}
          {this.renderReport()}
          {/*<div className='shift-schedule__add'>*/}
          {/*  <div className='shift-schedule__add__icon'>*/}
          {/*    <PlusIcon width={18} height={18} color='#fff' />*/}
          {/*  </div>*/}
          {/*  <div className='shift-schedule__add__text'>{formatMessage(messages.AddEmployee)}</div>*/}
          {/*</div>*/}
        </div>
        {this.renderTableShift()}
      </div>
    )
  }

  renderReport = () => {
    const { loadingReport } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='shift-schedule__report'>
        <Dropdown
          className='shift-schedule__report-dropdown'
          text={formatMessage(messages.DownloadReport)}
          loading={loadingReport}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => this.downloadReport(EReportFormat.CSV)}>
              {formatMessage(messages.DownloadReportIn, { format: '.csv' })}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.downloadReport(EReportFormat.EXCEL)}>
              {formatMessage(messages.DownloadReportIn, { format: 'Excel' })}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }

  renderMarket = () => {
    const { markets } = this.props
    const { market } = this.state

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='shift-schedule-filter__field'>
        <Dropdown value={market} search selection options={options} onChange={this.handleChangeMarket} />
      </div>
    )
  }

  renderDate = () => {
    const { date } = this.state

    return (
      <div className='shift-schedule-filter__field'>
        <Form.Input
          className='shift-schedule-filter__field-value'
          value={date}
          min={'2022-12-01'}
          max={moment(new Date()).add(1, 'month').format('YYYY-MM-DD')}
          type='date'
          onChange={this.handleChangeDate}
        />
      </div>
    )
  }

  renderTableShift = () => {
    const { slots } = this.props
    const { formatMessage } = this.props.intl

    return (
      <table className='shift-schedule__table'>
        <thead>
          <tr className='shift-schedule__table-header'>
            <th className='shift-schedule__table-header__cell-first'>
              {`${formatMessage(messages.Name)} ${formatMessage(messages.Surname)}
                ${formatMessage(messages.CollectorR).toLowerCase()}/${formatMessage(messages.CourierR).toLowerCase()}
                `}
            </th>
            {slots.map((item) => {
              return (
                <th key={item.id} className='shift-schedule__table-header__cell'>
                  {item.from} — {item.to}
                </th>
              )
            })}
            <th className='shift-schedule__table-header__cell-last' />
          </tr>
        </thead>
        <tbody>
          {this.renderTableCollectors()}
          {this.renderTableCouriers()}
        </tbody>
      </table>
    )
  }

  renderTableCollectors = () => {
    const { slots, couriersList } = this.props
    const { formatMessage } = this.props.intl
    const { date } = this.state
    const collectors = couriersList.filter((item) => item.role === ECourierRole.PICKER)

    return (
      <>
        <tr className='shift-schedule__table-sub'>
          <td className='shift-schedule__table-sub__cell-first'>{formatMessage(messages.Collectors)}</td>
          {slots.map((slot) => {
            const inShift = collectors.filter((collector) =>
              collector.shiftSlots.find((shift) => shift.slotId === slot.id && shift.shiftDate === date),
            ).length

            return (
              <td key={slot.id} className='shift-schedule__table-sub__cell'>
                {inShift}
              </td>
            )
          })}
          <td className='shift-schedule__table-sub__cell-last' />
        </tr>
        {collectors.map(this.renderCourierRow)}
      </>
    )
  }

  renderTableCouriers = () => {
    const { slots, couriersList } = this.props
    const { formatMessage } = this.props.intl
    const { date } = this.state
    const couriers = couriersList.filter((item) => item.role === ECourierRole.COURIER)

    return (
      <>
        <tr className='shift-schedule__table-sub'>
          <td className='shift-schedule__table-sub__cell-first'>{formatMessage(messages.Couriers)}</td>
          {slots.map((slot) => {
            const inShift = couriers.filter((courier) =>
              courier.shiftSlots.find((shift) => shift.slotId === slot.id && shift.shiftDate === date),
            ).length

            return (
              <td key={slot.id} className='shift-schedule__table-sub__cell'>
                {inShift}
              </td>
            )
          })}
          <td className='shift-schedule__table-sub__cell-last' />
        </tr>
        {couriers.map(this.renderCourierRow)}
      </>
    )
  }

  renderCourierRow = (courier: ICourier, index: number) => {
    const { slots } = this.props

    return (
      <tr key={courier.id} className='shift-schedule__table-row'>
        <td className='shift-schedule__table-row__cell-first'>
          <span className='shift-schedule__table-item__num'>№{index + 1}.</span>
          <span className='shift-schedule__table-item__name'>
            {courier.firstName} {courier.lastName}
          </span>
          <div className='shift-schedule__table-item__phone'>{formatPhone(courier.phone)}</div>
        </td>
        {slots.map((slot) => this.renderCourierShift(slot, courier))}
        <td className='shift-schedule__table-row__cell-last' onClick={() => this.courierShift(courier, index + 1)}>
          <EditIcon color={'#a0a3b5'} />
        </td>
      </tr>
    )
  }

  renderCourierShift = (slot: ISlot, courier: ICourier) => {
    const { markets } = this.props
    const { date } = this.state
    const market = markets.find((item) => item.id === this.state.market)
    const currentTime = getTimeWithTimeZone(moment().toDate(), market?.city_tz)
    const isShift = courier.shiftSlots.find((item) => item.slotId === slot.id && item.shiftDate === date)
    const isStart =
      date < currentTime.format('YYYY-MM-DD') ||
      (date === currentTime.format('YYYY-MM-DD') && currentTime.format('HH:mm') >= slot.from)
    const shiftInTime =
      isShift && date <= currentTime.format('YYYY-MM-DD') && isShift.startAt
        ? isShift.startAt.slice(0, -3) <= slot.to
        : false
    const shiftFullLate =
      isShift && date <= currentTime.format('YYYY-MM-DD') && isStart
        ? (isStart && !isShift.startAt) || (isShift.startAt && isShift.startAt.slice(0, -3) >= slot.to)
        : false

    return (
      <td
        key={slot.id}
        className={classnames(
          'shift-schedule__table-row__cell',
          shiftInTime && isStart && 'shift-schedule__table-row__cell-in-time',
          shiftFullLate && 'shift-schedule__table-row__cell-full-late',
        )}
      >
        {isShift && (
          <div
            className={classnames(
              'shift-schedule__table-row__cell-shift',
              shiftFullLate && 'shift-schedule__table-row__cell-shift-full-late',
            )}
          />
        )}
      </td>
    )
  }

  courierShift = (courier: ICourier, index: number) => {
    const { date } = this.state

    this.props.courierShiftModal({
      type: EModalType.MODAL_COURIER_SHIFT,
      size: 'mini',
      style: { width: '880px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        courier,
        index,
        shiftDate: date,
      },
    })
  }

  handleChangeMarket = (event: any, data: any) => {
    const { value } = data

    this.props.apiCouriersList({ market: value })
    this.props.getSlots(value)

    this.setState({ market: value })
    storage.setValue(storage.COURIERS_MARKET, value)
  }

  handleChangeDate = (event: any, data: any) => {
    const { market } = this.state
    const { value } = data

    this.setState({ date: value })
    this.props.getCourierShifts(market, value)
  }

  downloadReport = (type: EReportFormat) => {
    const { date, market } = this.state

    this.props.apiReportCourierShifts(
      {
        market,
        date,
      },
      type,
    )
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { couriersList } = s.couriers

  return {
    couriersList,
    loadingReport: s.reports.loadingReportCourierShifts,
    slots: s.markets.slots,
    markets: s.markets.markets || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getCourierShifts: (marketId: string, date: string) =>
    dispatch(
      Actions.action(Actions.API_COURIER_SHIFTS, {
        marketId,
        date,
      }),
    ),
  getSlots: (market: string) => dispatch(Actions.action(Actions.API_SLOTS, { market })),
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  getMarkets: () => dispatch(Actions.action(Actions.API_MARKETS, { include_all: 'True' })),
  courierShiftModal: (data: IModalCourierShift) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  apiReportCourierShifts: (filter: ICourierShiftsFilter, type: EReportFormat) =>
    dispatch(
      Actions.action(Actions.API_REPORT_COURIER_SHIFTS, {
        ...filter,
        type,
      }),
    ),
})

export const ShiftSchedule = connect<TConnectedProps, TDispatchedProps, any, State>(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ShiftScheduleCmp))
