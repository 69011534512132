import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const CheckIcon: React.FC<TProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='m11.223 17.293 10.082-9.95a1.2 1.2 0 0 1 1.68 0c.465.458.465 1.2 0 1.659l-10.923 10.78a1.2 1.2 0 0 1-1.725-.047l-4.155-4.101a1.162 1.162 0 0 1 0-1.659 1.2 1.2 0 0 1 1.68 0l3.361 3.318Z'
      />
    </svg>
  )
}
