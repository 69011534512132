import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Card, Form, Header, Icon } from 'semantic-ui-react'
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown'

import './index.scss'

import { IMarket, ITag } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { EModalType, IModalEditCreateTag } from '../../../../store/reducers/modals'
import * as Actions from '../../../../store/actions'
import { State } from '../../../../store/reducer'
import { TagCard } from '../../../../components/TagCard'
import { ModalConfirmation } from '../../../../components/Modal/ModalConfirmation'
import { historyPush, LOCATION_TAGS } from '../../../../utils/locationUtils'
import { sortByName } from '../../../../utils/marketsUtils'

type TState = {
  market: string,
  removeTag: number | null,
}

type TConnectedProps = {
  markets: IMarket[],
  tags: ITag[],
}

type TDispatchedProps = {
  editCreateTagModal(data: IModalEditCreateTag): Actions.Action,
  getMarkets(): Actions.Action,
  getTags(market_id: string): Actions.Action,
  removeTagById(tagId: number): Actions.Action,
}

type TProps = RouteComponentProps<{ marketId: string }> & TDispatchedProps & TConnectedProps & WrappedComponentProps

class TagsCmp extends Component<TProps, TState> {
  state: TState = {
    market: '',
    removeTag: null,
  }

  componentDidMount() {
    const marketId = this.props.match.params.marketId
    this.props.getMarkets()

    if (marketId) {
      this.setState({ market: marketId })
      this.props.getTags(marketId)
    }
  }

  render() {
    const { tags } = this.props
    const { formatMessage } = this.props.intl
    const { market } = this.state

    return (
      <div className='tags-container'>
        <Header as='h1'>{formatMessage(messages.MarketingTags)}</Header>
        {this.renderRemoveModal()}
        {this.renderMarkets()}
        {market ? (
          <div className='tags-container__list'>
            <Card.Group>
              {!!market && this.renderNewTag()}
              {tags.filter((t) => t.market === market).map((t) => this.renderTag(t))}
            </Card.Group>
          </div>
        ) : (
          <div className='tags-container__placeholder'>
            <h1>{formatMessage(messages.SelectMarket)}</h1>
            <div className='tags-container__placeholder__icon'>
              <Icon name='shop' size='large' />
            </div>
          </div>
        )}
      </div>
    )
  }

  renderMarkets = () => {
    const { markets } = this.props
    const { formatMessage } = this.props.intl
    const { market } = this.state
    const options = markets.map((m) => ({ key: m.id, value: m.id, text: m.name }))

    return (
      <Form.Select
        value={market}
        options={options}
        placeholder={formatMessage(messages.SelectMarket)}
        onChange={this.onSelectMarket}
      />
    )
  }

  renderTag = (tag: ITag) => {
    return (
      <TagCard
        key={tag.id}
        color={tag.color}
        title={tag.tag}
        imageUrl={tag.image_url}
        productsCount={tag.products_count}
        onClick={() => this.openTag(tag.id)}
        onEdit={() => this.editTag({ ...tag })}
        onRemove={() => this.setState({ removeTag: tag.id })}
      />
    )
  }

  renderNewTag = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='tags-container__list__new-tag' onClick={() => this.editTag()}>
        <div>
          <Icon name='plus' size='large' />
        </div>
        <div className='tags-container__list__new-tag__text'>{formatMessage(messages.Add)}</div>
      </div>
    )
  }

  openTag = (id: number) => {
    const { market } = this.state

    this.props.history.push(`${LOCATION_TAGS}/${market}/${id}`)
  }

  renderRemoveModal = () => {
    const { formatMessage } = this.props.intl
    const { removeTag } = this.state

    return (
      <ModalConfirmation onClose={() => this.setState({ removeTag: null })} onOk={this.removeTag} open={!!removeTag}>
        {formatMessage(messages.SureWantRemoveTag)}
      </ModalConfirmation>
    )
  }

  removeTag = () => {
    const { removeTag } = this.state
    if (removeTag) {
      this.props.removeTagById(removeTag)
    }

    this.setState({ removeTag: null })
  }

  onSelectMarket = (_: any, data: DropdownProps) => {
    const { getTags } = this.props
    const value = data.value as string

    historyPush(`${LOCATION_TAGS}/${value}`)

    this.setState({ market: value })

    getTags(value)
  }

  editTag = (tag?: ITag) => {
    const { market } = this.state

    this.props.editCreateTagModal({
      type: EModalType.MODAL_EDIT_CREATE_TAG,
      props: { tag, market },
    })
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  markets: s.markets.markets.sort(sortByName),
  tags: s.tags.tags,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  editCreateTagModal: (data: IModalEditCreateTag) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  getMarkets: () => dispatch(Actions.action(Actions.API_MARKETS, {})),
  getTags: (market_id: string) => dispatch(Actions.action(Actions.API_GET_TAGS, { market_id })),
  removeTagById: (tagId: number) => dispatch(Actions.action(Actions.API_REMOVE_TAG, tagId)),
})

export const Tags = connect(mapStateToProps, mapDispatchToProps)(injectIntl(TagsCmp))
