import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const BackIcon: React.FC<TProps> = ({ width = 24, height = 24, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} d='m15 6-6 6 6 6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
