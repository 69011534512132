import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { ITotalOrders } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import { AnalyticsBlockStat } from '../AnalyticsBlockStat'
import { AnalyticsPieChart } from '../AnalyticsPieChart'

type TOwnProps = {
  marketInfo: ITotalOrders,
}

type Props = TOwnProps & WrappedComponentProps

const DELIVERED_COLOR = '#33b04b'
const CANCELED_COLOR = '#ef7979'

class AnalyticsOrdersCmp extends React.Component<Props> {
  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='analytics__block-container'>
        <div className='analytics__block-title'>{formatMessage(messages.Orders)}</div>
        <div className='analytics__block'>
          {this.renderHeader()}
          {this.renderChart()}
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { marketInfo } = this.props
    const { formatMessage } = this.props.intl
    const averageWeight = marketInfo.totalWeightKg ? marketInfo.totalWeightKg / marketInfo.totalNumDelivered : 0

    return (
      <div className='analytics__block-header'>
        <AnalyticsBlockStat
          label={formatMessage(messages.TotalAmount)}
          value={marketInfo.totalNumDelivered + marketInfo.totalNumCanceled}
        />
        <AnalyticsBlockStat
          label={formatMessage(messages.TotalWeight)}
          value={`${marketInfo.totalWeightKg} ${formatMessage(messages.Kg)}`}
        />
        <AnalyticsBlockStat
          label={formatMessage(messages.AverageWeight)}
          value={`${averageWeight.toFixed(2)} ${formatMessage(messages.Kg)}`}
        />
      </div>
    )
  }

  renderChart = () => {
    const { marketInfo } = this.props
    const { formatMessage } = this.props.intl

    const chartData = [
      { name: formatMessage(messages.CanceledMany), value: marketInfo.totalNumCanceled, color: CANCELED_COLOR },
      { name: formatMessage(messages.DeliveredMany), value: marketInfo.totalNumDelivered, color: DELIVERED_COLOR },
    ]

    return (
      <div className='analytics__block-chart'>
        <div className='analytics__block-chart__legend'>
          <AnalyticsBlockStat
            label={formatMessage(messages.DeliveredMany)}
            value={marketInfo.totalNumDelivered}
            color={DELIVERED_COLOR}
          />
          <AnalyticsBlockStat
            label={formatMessage(messages.CanceledMany)}
            value={marketInfo.totalNumCanceled}
            color={CANCELED_COLOR}
          />
        </div>
        <div>
          <AnalyticsPieChart data={chartData} />
        </div>
      </div>
    )
  }
}

export const AnalyticsOrders = injectIntl(AnalyticsOrdersCmp)
