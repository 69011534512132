import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Loader } from 'semantic-ui-react'

import './index.scss'

import { EReportFormat, IMarket, IProductsFilter, ISeller } from '../../types/TClient'

import messages from '../../localization/messages'
import * as Actions from '../../store/actions'
import { State } from '../../store/reducer'
import { DownloadIcon } from '../Icons'

type TOwnProps = {
  market?: Pick<IMarket, 'id' | 'name'>,
  seller?: Pick<ISeller, 'id' | 'name'>,
  hidden?: boolean,
  byCategories?: boolean,
}

type TConnectedProps = {
  loadingReport: boolean,
}

type TDispatchedProps = {
  apiReportProducts: (data: IProductsFilter, type: EReportFormat) => Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

class ReportProductsBtnCmp extends React.Component<Props> {
  render() {
    const { loadingReport } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='report-products-btn' onClick={() => this.downloadReportProducts(EReportFormat.CSV)}>
        <div className='report-products-btn__icon'>
          {loadingReport ? <Loader active inline /> : <DownloadIcon color={'#7677bc'} />}
        </div>
        <div className='report-products-btn__text'>{formatMessage(messages.ItemsReport)}</div>
      </div>
    )
  }

  downloadReportProducts = (type: EReportFormat) => {
    const { seller, market, byCategories, hidden, loadingReport } = this.props

    if (loadingReport) {
      return
    }

    if (seller) {
      this.props.apiReportProducts(
        {
          seller: seller.id,
          sellerName: seller.name,
          ...(hidden !== undefined && { hidden }),
        },
        type,
      )
    }

    if (market) {
      this.props.apiReportProducts(
        {
          market: market.id,
          marketName: market.name,
          ...(byCategories && { byCategories }),
          ...(hidden !== undefined && { hidden }),
        },
        type,
      )
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  return {
    loadingReport: s.reports.loadingReportProducts,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiReportProducts: (filter: IProductsFilter, type: EReportFormat) =>
    dispatch(
      Actions.action(Actions.API_REPORT_PRODUCTS, {
        ...filter,
        type,
      }),
    ),
})

export const ReportProductsBtn = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportProductsBtnCmp))
