import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const PlusIcon: React.FC<TProps> = ({ width = 20, height = 20, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.386 11.035h-5.642v5.351c0 .496-.394.89-.89.89a.884.884 0 0 1-.889-.89v-5.351H3.614a.884.884 0 0 1-.89-.89c0-.495.394-.89.89-.89h5.351v-5.64c0-.496.394-.89.89-.89.495 0 .89.394.89.89v5.64h5.641c.496 0 .89.395.89.89 0 .496-.394.89-.89.89Z'
      />
    </svg>
  )
}
