import { of as observableOf, EMPTY } from 'rxjs'
import { catchError, mergeMap, filter, delay } from 'rxjs/operators'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'

import * as TApi from '../../types/TApi'
import { EImageType } from '../../types/TClient'
import { ApiSellerCourierResp } from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, guardMergeMap, ofType } from './epicHelpers'
import {
  URL_BANK_INFO,
  URL_PARTNER_INFO,
  URL_SELLERS_INFO_CREATE,
  URL_SELLERS_INFO_LIST,
  URL_SELLERS_INFO_REMOVE,
  URL_SELLERS_INFO_UPDATE,
  URL_SELLER_REGISTER,
  URL_UPLOAD_FILE,
  URL_UPLOAD_IMAGE,
  URL_SELLERS_MARKETS,
  URL_SELLERS_LIST,
  URL_SELLER_UPDATE,
  URL_SELLER_UPDATE_IS_HIDE,
  URL_COPY_PRODUCTS,
  URL_COPY_PRODUCTS_STATUS,
  URL_SELLERS_SEARCH,
  URL_SELLER_COURIER,
} from '../../modules/network/urls'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import {
  convertPartnerFromApi,
  convertBankFromApi,
  convertSellerInfoFromApi,
  convertSellerSearchFromApi,
  convertMarketFromApi,
  convertSellerFromApi,
} from '../../utils/sellerUtils'
import { historyPush, LOCATION_SELLERS_IN_PROGRESS } from '../../utils/locationUtils'

const partnerInfoEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiPartnerInfo>(a$, Actions.API_PARTNER_INFO), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_PARTNER_INFO, {
          ...authRequestConfig(),
          params: {
            query: c.data.query || 0,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiPartnersResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.suggestions) && resp.data.suggestions.length) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.PARTNER_INFO, {
                    id: c.data.id,
                    ...convertPartnerFromApi(resp.data.suggestions[0]),
                  }),
                )
              }
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.SELLERS_DROP_LOADING))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.SELLERS_DROP_LOADING))
          }),
        ),
      ),
    ),
  )

const bankInfoEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiBankInfo>(a$, Actions.API_BANK_INFO), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_BANK_INFO, {
          ...authRequestConfig(),
          params: {
            bik: c.data.bik || 0,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiBankResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.suggestions) && resp.data.suggestions.length) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.BANK_INFO, {
                    id: c.data.id,
                    ...convertBankFromApi(resp.data.suggestions[0]),
                  }),
                )
              }
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.SELLERS_DROP_LOADING))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.SELLERS_DROP_LOADING))
          }),
        ),
      ),
    ),
  )

const uploadFileEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUploadFile>(a$, Actions.API_UPLOAD_FILE), (b) =>
    b.pipe(
      filter((a) => !!a.data.file),
      mergeMap((c) => {
        const formData = new FormData()
        formData.append('file', c.data.file)

        return Axios.post(URL_UPLOAD_FILE, formData).pipe(
          mergeMap((resp: { data: TApi.ApiUploadFileResp }) => {
            let fileResp = resp.data[0]

            if (fileResp && !(fileResp as TApi.StorageFileErrorApi).error) {
              fileResp = fileResp as TApi.StorageFileApi

              return observableOf<Actions.Action>(
                Actions.action(Actions.SELLER_FILE, {
                  type: c.data.type,
                  fileUrl: fileResp.url + fileResp.tkn,
                  id: c.data.id,
                }),
              )
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.SELLERS_DROP_LOADING))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.SELLERS_DROP_LOADING))
          }),
        )
      }),
    ),
  )

const sellersInfoListEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSellersInfoList>(a$, Actions.API_SELLERS_INFO_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_SELLERS_INFO_LIST, {
          ...authRequestConfig(),
          params: {
            offset: c.data.offset || 0,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiSellersInfoListResp }) => {
            if (resp.data && resp.data.sellers) {
              if (Array.isArray(resp.data.sellers)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.SELLERS_INFO_LIST, {
                    sellers: resp.data.sellers.map(convertSellerInfoFromApi),
                  }),
                )
              }
            }

            return observableOf<Actions.Action>(Actions.action(Actions.API_SELLER_INFO_ERROR, {}))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.API_SELLER_INFO_ERROR, {}))
          }),
        ),
      ),
    ),
  )

const createSellerInfoEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCreateSellerInfo>(a$, Actions.API_CREATE_SELLER_INFO), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_SELLERS_INFO_CREATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiSellerInfoCreateResp }) => {
            historyPush(LOCATION_SELLERS_IN_PROGRESS)

            return observableOf<Actions.Action>(
              Actions.action(Actions.CREATE_SELLER_INFO, {
                ...convertSellerInfoFromApi(c.data as TApi.ISellerInfoApi),
                id: resp.data.id,
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_SELLER_INFO_ERROR, {
                error: 'Ошибка создания профиля продавца',
              }),
            )
          }),
        ),
      ),
    ),
  )

const createSellerCourier: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSellerCourier>(a$, Actions.API_COURIER_SELLER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_SELLER_COURIER, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: AxiosResponse<ApiSellerCourierResp>) => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_COURIER_SELLER_SUCCESS, {
                sellerId: c.data.sellerId,
                courierId: resp.data.courierId,
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_SELLER_INFO_ERROR, {
                error: 'Ошибка обновления профиля продавца',
              }),
            )
          }),
        ),
      ),
    ),
  )

const updateSellerInfoEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateSellerInfo>(a$, Actions.API_UPDATE_SELLER_INFO), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_SELLERS_INFO_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.UPDATE_SELLER_INFO, {
                ...convertSellerInfoFromApi(c.data as TApi.ISellerInfoApi),
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_SELLER_INFO_ERROR, {
                error: 'Ошибка обновления профиля продавца',
              }),
            )
          }),
        ),
      ),
    ),
  )

const updateSellerIsHideEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.SellerUpdateIsHide>(a$, Actions.SELLER_UPDATE_IS_HIDE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(
          URL_SELLER_UPDATE_IS_HIDE,
          {
            id: c.data.id,
            isHide: c.data.isHide,
          },
          {
            ...authRequestConfig(),
          },
        ).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(Actions.action(Actions.SELLER_UPDATE_IS_HIDE_SUCCESS, c.data))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_SELLER_INFO_ERROR, {
                error: 'Ошибка обновления профиля продавца',
              }),
            )
          }),
        ),
      ),
    ),
  )

const removeSellerInfoEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiRemoveSellerInfo>(a$, Actions.API_REMOVE_SELLER_INFO), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_SELLERS_INFO_REMOVE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(Actions.action(Actions.REMOVE_SELLER_INFO, { id: c.data.id }))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.API_SELLER_INFO_ERROR, {}))
          }),
        ),
      ),
    ),
  )

const registerSellerEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSellerRegister>(a$, Actions.API_SELLER_REGISTER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_SELLER_REGISTER, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiSellerRegisterResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data.createdUser && resp.data.createdProfile) {
              actions.push(Actions.action(Actions.REMOVE_SELLER_INFO, { id: c.data.id }))
            } else if (!resp.data.createdUser) {
              actions.push(
                Actions.action(Actions.API_SELLER_INFO_ERROR, {
                  error: 'Ошибка создания пользователя',
                }),
              )
            } else if (!resp.data.createdUser) {
              actions.push(
                Actions.action(Actions.API_SELLER_INFO_ERROR, {
                  error: 'Ошибка создания профиля продавца',
                }),
              )
            } else {
              actions.push(
                Actions.action(Actions.API_SELLER_INFO_ERROR, {
                  error: 'Ошибка переноса в БД',
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_SELLER_INFO_ERROR, {
                error: 'Ошибка переноса в БД',
              }),
            )
          }),
        ),
      ),
    ),
  )

const sellersMarketsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSellersMarkets>(a$, Actions.API_SELLERS_MARKETS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_SELLERS_MARKETS, {
          ...authRequestConfig(),
          params: {
            offset: c.data.offset || 0,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiSellersMarketsResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.markets)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.SELLERS_MARKETS, {
                    markets: resp.data.markets.map(convertMarketFromApi),
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const sellersListEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSellersList>(a$, Actions.API_SELLERS_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_SELLERS_LIST, {
          ...authRequestConfig(),
          params: {
            id: c.data.id,
            offset: c.data.offset,
            hidden: c.data.hidden,
            market: c.data.market,
            searchByProducts: c.data.searchByProducts,
            category: c.data.category,
            isSystem: c.data.isSystem,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiSellersListResp }) => {
            if (resp.data) {
              const actions: Actions.Action[] = []

              if (Array.isArray(resp.data.sellers)) {
                actions.push(
                  Actions.action(Actions.SELLERS_LIST, {
                    sellers: resp.data.sellers.map(convertSellerFromApi),
                    isSystem: c.data.isSystem,
                    force: c.data.force,
                  }),
                )
              }

              if (Array.isArray(resp.data.categories)) {
                actions.push(
                  Actions.action(Actions.SELLERS_CATEGORIES, {
                    categories: resp.data.categories,
                  }),
                )
              }

              return observableOf<Actions.Action>(...actions)
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const sellersSearchEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSellersSearch>(a$, Actions.API_SELLERS_SEARCH), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_SELLERS_SEARCH, {
          ...authRequestConfig(),
          params: {
            ...c.data,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiSellersSearchResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.sellers)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.SELLERS_SEARCH, {
                    sellers: resp.data.sellers.map(convertSellerSearchFromApi),
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const uploadImageEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiUploadImage>(a$, Actions.API_UPLOAD_IMAGE), (b) =>
    b.pipe(
      filter((a) => !!a.data.file),
      mergeMap((c) => {
        const formData = new FormData()
        formData.append('file', c.data.file)

        return Axios.post(URL_UPLOAD_IMAGE, formData).pipe(
          mergeMap((resp: { data: TApi.ApiUploadFileResp }) => {
            let fileResp = resp.data[0]

            if (fileResp && !(fileResp as TApi.StorageFileErrorApi).error) {
              fileResp = fileResp as TApi.StorageFileApi

              if (fileResp.thumbnails) {
                const imageUrl = fileResp.url + fileResp.thumbnails.pop() + '/' + fileResp.tkn

                if (c.data.type === EImageType.SELLER_IMAGE) {
                  return observableOf<Actions.Action>(
                    Actions.action(Actions.UPDATE_SAVED_SELLER_PROFILE, {
                      ...store.value.sellers.savedSellerProfile,
                      imageUrl,
                    }),
                  )
                }

                if (c.data.type === EImageType.SELLER_AVATAR) {
                  return observableOf<Actions.Action>(
                    Actions.action(Actions.UPDATE_SAVED_SELLER_PROFILE, {
                      ...store.value.sellers.savedSellerProfile,
                      avatarUrl: imageUrl,
                    }),
                  )
                }

                if (c.data.type === EImageType.PRODUCT_IMAGE) {
                  return observableOf<Actions.Action>(
                    Actions.action(Actions.PRODUCT_SAVED_IMAGE, {
                      imageUrl,
                    }),
                  )
                }

                if (c.data.type === EImageType.TAG_IMAGE) {
                  return observableOf<Actions.Action>(
                    Actions.action(Actions.TAG_SAVED_IMAGE, {
                      imageUrl,
                    }),
                  )
                }

                if (c.data.type === EImageType.MARKET_CATEGORY_IMAGE) {
                  return observableOf<Actions.Action>(
                    Actions.action(Actions.MARKET_CATEGORY_SAVED_IMAGE, {
                      imageUrl,
                    }),
                  )
                }

                return EMPTY
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.API_UPLOAD_IMAGE_ERROR, { type: c.data.type }))
          }),
        )
      }),
    ),
  )

const updateSellerEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateSeller>(a$, Actions.API_UPDATE_SELLER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_SELLER_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiUpdateSellerResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data) {
              actions.push(Actions.action(Actions.UPDATE_SELLER, { ...c.data }))
            } else {
              actions.push(
                Actions.action(Actions.API_UPDATE_SELLER_ERROR, {
                  error: 'Ошибка обновления профиля продавца',
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_UPDATE_SELLER_ERROR, {
                error: 'Ошибка обновления профиля продавца',
              }),
            )
          }),
        ),
      ),
    ),
  )

const copyProductsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCopyProducts>(a$, Actions.API_COPY_PRODUCTS), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_COPY_PRODUCTS, a.data, authRequestConfig()).pipe(
          mergeMap((resp: { data: TApi.ApiCopyProductsResp }) => {
            return observableOf<Actions.Action, Actions.Action>(
              Actions.action(Actions.COPY_PRODUCTS_START, resp.data),
              Actions.action(Actions.API_COPY_PRODUCTS_STATUS, resp.data),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const copyServicesStatusEpic: EpicFunc = (a$, store) =>
  guardMergeMap(ofType<Actions.ApiCopyProductsStatus>(a$, Actions.API_COPY_PRODUCTS_STATUS), (b) =>
    b.pipe(
      delay(2500),
      mergeMap((a) =>
        Axios.post(URL_COPY_PRODUCTS_STATUS, a.data, authRequestConfig()).pipe(
          mergeMap((resp: { data: TApi.ApiCopyProductsStatusResp }) => {
            const actions: Actions.Action[] = [
              Actions.action(Actions.COPY_PRODUCTS_STATUS_SUCCESS, {
                status: resp.data.status.products,
                success: resp.data.success,
              }),
            ]

            if (!resp.data.success && store.value.sellers.copyProducts && store.value.sellers.copyProducts.tkn) {
              actions.push(
                Actions.action(Actions.API_COPY_PRODUCTS_STATUS, {
                  tkn: store.value.sellers.copyProducts.tkn,
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const sellersEpics: EpicFunc[] = [
  partnerInfoEpic,
  bankInfoEpic,
  uploadFileEpic,
  sellersInfoListEpic,
  createSellerInfoEpic,
  updateSellerInfoEpic,
  removeSellerInfoEpic,
  registerSellerEpic,
  sellersMarketsEpic,
  sellersListEpic,
  uploadImageEpic,
  updateSellerEpic,
  updateSellerIsHideEpic,
  sellersSearchEpic,
  copyProductsEpic,
  copyServicesStatusEpic,
  createSellerCourier,
]
