import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const AvatarIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        stroke={color}
        strokeWidth='.3'
        d='M11.183 12.205a8.345 8.345 0 0 0 3.227 6.591c-5.014 2.034-8.56 6.955-8.56 12.691a4.668 4.668 0 0 0 4.663 4.663h18.051a4.668 4.668 0 0 0 4.663-4.663c0-5.736-3.546-10.657-8.56-12.69a8.345 8.345 0 0 0 3.227-6.592c0-4.607-3.748-8.355-8.356-8.355-4.607 0-8.355 3.748-8.355 8.355Zm2.762 0a5.6 5.6 0 0 1 5.593-5.593 5.6 5.6 0 0 1 5.594 5.593 5.6 5.6 0 0 1-5.594 5.594 5.6 5.6 0 0 1-5.593-5.594Zm-3.432 21.183a1.904 1.904 0 0 1-1.901-1.9c0-6.026 4.901-10.928 10.926-10.928 6.026 0 10.927 4.902 10.927 10.927a1.904 1.904 0 0 1-1.9 1.901H10.512Z'
      />
    </svg>
  )
}
