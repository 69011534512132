import React, { ReactText } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import messages from '../../../localization/messages'

type TProps = {
  onClose(): void,
  onOk?(): void,
  open: boolean,
  children: ReactText,
}

export const ModalConfirmation: React.FC<TProps> = ({ open, children, onClose, onOk }) => {
  return (
    <Modal basic onClose={onClose} open={open} size='small'>
      <Header icon>
        <Icon name='edit outline' />
        <p>{children}</p>
      </Header>
      <Modal.Actions>
        <Button basic color='green' inverted onClick={onClose}>
          <Icon name='remove' /> <FormattedMessage {...messages.Cancel} />
        </Button>
        {onOk && (
          <Button color='red' inverted onClick={onOk}>
            <Icon name='checkmark' /> <FormattedMessage {...messages.Confirm} />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}
