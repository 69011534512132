import React from 'react'

type TProps = {
  color?: string,
}

export const EditIcon: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.773 7.047a.774.774 0 0 1 0 1.097l-1.424 1.423-2.916-2.916 1.423-1.424a.774.774 0 0 1 1.097 0l1.82 1.82ZM5 19v-2.916l8.601-8.602 2.916 2.917-8.6 8.6H5Z'
      />
    </svg>
  )
}
