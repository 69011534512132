import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'
import { Map, Placemark } from 'react-yandex-maps'
import classnames from 'classnames'
import moment from 'moment'

import './index.scss'

import { IMarket, IOrderByMap, IOrdersFilter } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import * as Actions from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import { EModalType, IModalCustomer } from '../../../../../store/reducers/modals'
import { formatPhone } from '../../../../../utils/customersUtils'
import * as storage from '../../../../../utils/storageUtils'
import pinMarket from '../../../../../assets/images/pin-map.png'
import pinBlue from '../../../../../assets/images/pin-map-blue.png'
import pinGreen from '../../../../../assets/images/pin-map-green.png'
import pinPurple from '../../../../../assets/images/pin-map-purple.png'
import pinGray from '../../../../../assets/images/pin-map-gray.png'
import pinAggregate from '../../../../../assets/images/aggregate-pin.png'

enum EPeriod {
  THIS_MONTH = 0,
  LAST_MONTH = 1,
  TWO_LAST_MONTH = 2,
  MORE_TWO_LAST_MONTH = 3,
  ALL_TIME = 4,
}

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  ordersMapList: IOrderByMap[],
  markets: IMarket[],
}

type TDispatchedProps = {
  customerModal: (data: IModalCustomer) => Actions.Action,
  apiOrdersMapList: (filter: IOrdersFilter) => Actions.Action,
  getMarkets: () => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  market: string,
  b2b: boolean,
  period: EPeriod,
  dateDeliveryFrom?: string,
  dateDeliveryTo?: string,
  marketLocation?: [number, number],
  mapBounds?: {
    xlt: number,
    ylt: number,
    xrb: number,
    yrb: number,
  },
  zoom: number,
}

class OrdersMapCmp extends React.Component<TProps, TState> {
  mapRef: any = null
  timer: number | null = null
  templateBalloon: string | null = null

  constructor(props: TProps) {
    super(props)

    const { markets } = props

    const savedMarket = storage.getValue(storage.COURIERS_MARKET)
    const market =
      markets.length > 0
        ? savedMarket
          ? markets.find((item) => item.id === savedMarket) || markets[0]
          : markets[0]
        : null

    this.state = {
      market: market ? market.id : '',
      b2b: false,
      period: EPeriod.THIS_MONTH,
      dateDeliveryFrom: moment().startOf('month').format('YYYY-MM-DD'),
      marketLocation: market ? [market.latitude, market.longitude] : undefined,
      mapBounds: market
        ? {
            xlt: market.longitude - 0.03,
            ylt: market.latitude - 0.012,
            xrb: market.longitude + 0.03,
            yrb: market.latitude + 0.012,
          }
        : undefined,
      zoom: 15.4,
    }
  }

  componentDidMount(): void {
    const { markets } = this.props
    this.props.getMarkets()

    if (!this.state.market && markets[0]) {
      this.handleChangeMarket(null, { value: markets[0].id })
    }
  }

  componentDidUpdate(_prevProps: Readonly<TProps>, prevState: Readonly<TState>): void {
    const { markets } = this.props

    if (!this.state.market && markets.length > 0) {
      this.setState({
        market: markets[0].id,
      })
    }

    if (prevState.market !== this.state.market) {
      const market = markets.length > 0 ? markets.find((item) => item.id === this.state.market) : null

      // this.props.apiOrdersMapList({ market: this.state.market })

      if (market) {
        this.setState({
          marketLocation: [market.latitude, market.longitude],
        })
      }
    }

    // if (prevProps.markets.length === 0 && markets.length > 0 && !this.state.market) {
    //   const savedMarket = storage.getValue(storage.COURIERS_MARKET)
    //   const market =
    //     markets.length > 0
    //       ? savedMarket
    //         ? markets.find((item) => item.id === savedMarket) || markets[0]
    //         : markets[0]
    //       : null
    //
    //   this.setState({
    //     market: market ? market.id : '',
    //   })
    //
    //   if (!savedMarket && market) {
    //     this.props.apiOrdersMapList({ market: market.id })
    //     storage.setValue(storage.COURIERS_MARKET, market.id)
    //   }
    // }
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    return (
      <div className='orders-map'>
        {this.renderMarket()}
        {this.renderMarketMap()}
      </div>
    )
  }

  renderMarket = () => {
    const { markets } = this.props
    const { market } = this.state

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='orders-map__filter'>
        <div className='orders-map__filter__field'>
          <Dropdown value={market} search selection options={options} onChange={this.handleChangeMarket} />
        </div>
        {this.renderPeriod()}
        {this.renderTabs()}
      </div>
    )
  }

  renderPeriod = () => {
    const { formatMessage } = this.props.intl
    const { period } = this.state

    const options = [
      {
        key: EPeriod.ALL_TIME,
        value: EPeriod.ALL_TIME,
        text: formatMessage(messages.DuringAllTime),
      },
      {
        key: EPeriod.THIS_MONTH,
        value: EPeriod.THIS_MONTH,
        text: formatMessage(messages.ThisMonth),
      },
      {
        key: EPeriod.LAST_MONTH,
        value: EPeriod.LAST_MONTH,
        text: formatMessage(messages.LastMonth),
      },
      {
        key: EPeriod.TWO_LAST_MONTH,
        value: EPeriod.TWO_LAST_MONTH,
        text: formatMessage(messages.BeforeLastMonth),
      },
      {
        key: EPeriod.MORE_TWO_LAST_MONTH,
        value: EPeriod.MORE_TWO_LAST_MONTH,
        text: formatMessage(messages.OverTwoMonthsAgo),
      },
    ]

    return (
      <div className='orders-map__filter__field'>
        <Dropdown value={period} selection options={options} onChange={this.handleChangePeriod} />
      </div>
    )
  }

  renderTabs = () => {
    const { b2b } = this.state

    return (
      <div className='orders-map__filter__tabs'>
        <div
          className={classnames('orders-map__filter__tabs-btn', !b2b && 'orders-map__filter__tabs-btn-active')}
          onClick={() => this.handleChangeClientType(false)}
        >
          B2C
        </div>
        <div
          className={classnames('orders-map__filter__tabs-btn', b2b && 'orders-map__filter__tabs-btn-active')}
          onClick={() => this.handleChangeClientType(true)}
        >
          B2B
        </div>
      </div>
    )
  }

  renderMarketMap = () => {
    const { markets, ordersMapList } = this.props
    const { formatMessage } = this.props.intl
    const { marketLocation, zoom, mapBounds } = this.state
    const market = markets.find((item) => item.id === this.state.market)

    if (!market) {
      return
    }

    const location = [market.latitude, market.longitude]

    let mapWidth =
      window.innerWidth > 840 ? window.innerWidth - 500 : window.innerWidth < 600 ? 0.9 * window.innerWidth : 480

    if (mapWidth > 960) {
      mapWidth = 960
    }

    const mapHeight = (mapWidth * 10) / 14

    const mapState =
      !marketLocation || marketLocation[0] !== location[0] || marketLocation[1] !== location[1]
        ? { center: location, zoom }
        : undefined

    const aggregateMap: {
      [location: string]: {
        count: number,
        customers: IOrderByMap[],
        lastOrder: Date,
      },
    } = {}

    ordersMapList.forEach((item) => {
      const lat = zoom >= 15 ? item.deliveryLat.toFixed(4) : item.deliveryLat.toFixed(zoom <= 12 ? 1 : 2)
      const long = zoom >= 15 ? item.deliveryLong.toFixed(4) : item.deliveryLong.toFixed(zoom <= 12 ? 1 : 2)

      const key = `${lat}_${long}`

      if (aggregateMap[key]) {
        aggregateMap[key].count += 1
        aggregateMap[key].customers.push(item)

        if (item.lastOrderDate > aggregateMap[key].lastOrder) {
          aggregateMap[key].lastOrder = item.lastOrderDate
        }
      } else {
        aggregateMap[key] = {
          count: 1,
          customers: [item],
          lastOrder: item.lastOrderDate,
        }
      }
    })

    return (
      <div className='orders-map__map' style={{ width: '100%', height: `${mapHeight}px` }}>
        <Map
          defaultState={{ center: location, zoom }}
          modules={['templateLayoutFactory', 'layout.ImageWithContent']}
          state={mapState}
          options={{ maxZoom: 20, minZoom: 11 }}
          width='100%'
          height='100%'
          instanceRef={(inst: any) => (this.mapRef = inst)}
          onLoad={(ymaps: any) => {
            if (this.mapRef) {
              this.mapRef.geoObjects.events.add('contextmenu', this.selectCustomer)
              this.mapRef.events.add('boundschange', (e: any) => {
                if (e.get('newZoom') !== e.get('oldZoom')) {
                  this.setState({ zoom: e.get('newZoom') })
                }

                if (e.get('newCenter') !== e.get('oldCenter')) {
                  const newBounds = e.get('newBounds')

                  this.setState({
                    mapBounds: {
                      xlt: newBounds[0][1] - 0.015,
                      ylt: newBounds[0][0] - 0.006,
                      xrb: newBounds[1][1] + 0.015,
                      yrb: newBounds[1][0] + 0.006,
                    },
                  })
                }
              })

              this.templateBalloon = ymaps.templateLayoutFactory.createClass(
                '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>',
              )
            }
          }}
        >
          <Placemark
            geometry={location}
            options={{
              iconLayout: 'default#image',
              iconImageHref: pinMarket,
              iconImageSize: [50, 69],
              iconImageOffset: [-25, -61],
            }}
          />
          {zoom >= 15 &&
            Object.entries(aggregateMap).map((customersZone, i) => {
              const [pos, value] = customersZone
              const [lat, long] = pos.split('_')

              if (lat && long) {
                const inZone =
                  mapBounds &&
                  +lat >= mapBounds.ylt &&
                  +lat <= mapBounds.yrb &&
                  +long >= mapBounds.xlt &&
                  +long <= mapBounds.xrb

                if (inZone) {
                  const nums = value.customers.length

                  let angle = Math.PI / (nums + i / 25)
                  const latStart = +lat + 0.00016
                  const longStart = +long

                  return (
                    <>
                      {value.customers.map((item) => {
                        const longItem =
                          (longStart - +long) * Math.cos(angle) - (latStart - +lat) * Math.sin(angle) + +long
                        const latItem =
                          (longStart - +long) * Math.sin(angle) + (latStart - +lat) * Math.cos(angle) + +lat
                        angle += angle

                        return (
                          <Placemark
                            key={`${item.deliveryLat}_${item.deliveryLong}_${item.userId}_${i}`}
                            properties={{
                              userId: item.userId,
                              balloonContentBody: `
                                  <div style="max-width:254px;">
                                    <div>
                                      <span style="font-weight:700;">${formatMessage(messages.Address)}:</span>
                                      ${item.deliveryAddress}
                                    </div>
                                    <div>
                                      <span style="font-weight:700;">${formatMessage(messages.Phone)}:</span>
                                      ${formatPhone(item.userPhone)}
                                    </div>
                                    <div>
                                      <span style="font-weight:700;">${formatMessage(messages.Name)}: </span>
                                      ${item.userFirstName} ${item.userLastName}
                                    </div>
                                    <div>
                                      <span style="font-weight:700;">${formatMessage(messages.TotalOrders)}:</span>
                                      ${item.count}
                                    </div>
                                  </div>
                                `,
                            }}
                            options={{
                              iconLayout: 'default#imageWithContent',
                              iconImageHref: this.getPinType(item.lastOrderDate),
                              iconImageSize: [32, 42],
                              iconImageOffset: [-15, -21],
                            }}
                            geometry={[nums > 1 ? latItem : item.deliveryLat, nums > 1 ? longItem : item.deliveryLong]}
                            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                          />
                        )
                      })}
                    </>
                  )
                }
              }

              return
            })}
          {zoom < 15 &&
            Object.entries(aggregateMap).map((customersZone, i) => {
              const [pos, value] = customersZone
              const [lat, long] = pos.split('_')

              if (lat && long) {
                return (
                  <Placemark
                    key={`${lat}_${long}_${i}`}
                    properties={{
                      iconContent: value.count,
                    }}
                    options={{
                      iconLayout: 'default#imageWithContent',
                      iconImageHref: pinAggregate,
                      iconImageSize: [56, 56],
                      iconImageOffset: [-28, -28],
                      iconContentOffset: [23 - value.count.toString().length * 1.5, 20],
                      iconContentLayout: this.templateBalloon,
                    }}
                    geometry={[+lat, +long]}
                  />
                )
              }

              return
            })}
        </Map>
        <div className='orders-map__legend'>
          <div className='orders-map__legend-title'>{formatMessage(messages.LastOrder)}:</div>
          <div className='orders-map__legend__item'>
            <div className='orders-map__legend__item-color' style={{ backgroundColor: '#27ae60' }} />
            <div className='orders-map__legend__item-name'>{formatMessage(messages.ThisMonth)}</div>
          </div>
          <div className='orders-map__legend__item'>
            <div className='orders-map__legend__item-color' style={{ backgroundColor: '#2f80ed' }} />
            <div className='orders-map__legend__item-name'>{formatMessage(messages.LastMonth)}</div>
          </div>
          <div className='orders-map__legend__item'>
            <div className='orders-map__legend__item-color' style={{ backgroundColor: '#9b51e0' }} />
            <div className='orders-map__legend__item-name'>{formatMessage(messages.BeforeLastMonth)}</div>
          </div>
          <div className='orders-map__legend__item'>
            <div className='orders-map__legend__item-color' style={{ backgroundColor: '#a0a3b5' }} />
            <div className='orders-map__legend__item-name'>{formatMessage(messages.OverTwoMonthsAgo)}</div>
          </div>
        </div>
      </div>
    )
  }

  getPinType = (lastOrderDate: Date) => {
    const time = moment(lastOrderDate).valueOf()

    if (time >= moment().startOf('month').valueOf()) {
      return pinGreen
    } else if (time >= moment().subtract(1, 'month').startOf('month').valueOf()) {
      return pinBlue
    } else if (time >= moment().subtract(2, 'month').startOf('month').valueOf()) {
      return pinPurple
    }

    return pinGray
  }

  handleChangeMarket = (event: any, data: any) => {
    const { value } = data

    this.props.apiOrdersMapList({
      market: value,
      b2b: this.state.b2b,
      force: true,
      dateDeliveryFrom: this.state.dateDeliveryFrom,
      dateDeliveryTo: this.state.dateDeliveryTo,
    })

    const market = this.props.markets.find((item) => item.id === value)

    this.setState({
      market: value,
      mapBounds: market
        ? {
            xlt: market.longitude - 0.03,
            ylt: market.latitude - 0.012,
            xrb: market.longitude + 0.03,
            yrb: market.latitude + 0.012,
          }
        : undefined,
    })
    storage.setValue(storage.COURIERS_MARKET, value)
  }

  handleChangePeriod = (event: any, data: any) => {
    const { value } = data
    const dateDeliveryFrom =
      value === EPeriod.ALL_TIME || value === EPeriod.MORE_TWO_LAST_MONTH
        ? undefined
        : moment().subtract(value, 'month').startOf('month').format('YYYY-MM-DD')
    const dateDeliveryTo =
      value === EPeriod.ALL_TIME ? undefined : moment().subtract(value, 'month').endOf('month').format('YYYY-MM-DD')

    this.props.apiOrdersMapList({
      market: this.state.market,
      b2b: this.state.b2b,
      force: true,
      dateDeliveryFrom,
      dateDeliveryTo,
    })

    this.setState({ period: value, dateDeliveryFrom, dateDeliveryTo })
  }

  handleChangeClientType = (value: boolean) => {
    this.props.apiOrdersMapList({
      market: this.state.market,
      b2b: value,
      force: true,
      dateDeliveryFrom: this.state.dateDeliveryFrom,
      dateDeliveryTo: this.state.dateDeliveryTo,
    })

    this.setState({ b2b: value })
  }

  selectCustomer = (event: any) => {
    const { market } = this.state

    if (event.get('target')) {
      const userId = event.get('target')['properties'].get('userId')

      if (userId) {
        if (market) {
          this.props.customerModal({
            type: EModalType.MODAL_CUSTOMER,
            size: 'mini',
            style: { width: '980px', maxWidth: '98%', borderRadius: '16px' },
            close: true,
            props: {
              userId,
              marketId: market,
            },
          })
        }
      }
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loaded, loading } = s.couriers

  return {
    loaded,
    loading,
    ordersMapList: s.orders.ordersMapList,
    markets: s.markets.markets || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  customerModal: (data: IModalCustomer) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  apiOrdersMapList: (filter: IOrdersFilter) => dispatch(Actions.action(Actions.API_ORDERS_MAP_LIST, filter)),
  getMarkets: () =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        include_all: 'True',
      }),
    ),
})

export const OrdersMap = connect<TConnectedProps, TDispatchedProps, any, State>(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrdersMapCmp))
