import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { DropdownItem, Form } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { EOrderStatus, EVehicleType, ICourier, ICouriersFilter, IOrder } from '../../../types/TClient'
import * as TApi from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { Button } from '../../Button'
import { convertRole } from '../../../utils/courierUtils'
import { convertStatus, getColor, localeWeight, round } from '../../../utils/ordersUtils'

const FIELD_COURIER = 'courier'

type TOwnProps = {
  order: IOrder,
  onClose: () => void,
}

type TConnectedProps = {
  couriers: ICourier[],
}

type TDispatchedProps = {
  apiCouriersList(filter: ICouriersFilter): Actions.Action,
  apiAssignOrder(data: TApi.AssignOrderReq): Actions.Action,
}

type Props = TOwnProps & TDispatchedProps & TConnectedProps & WrappedComponentProps

type IState = {
  changed: boolean,
  courier: string,
  visible: boolean,
  errors: FieldError[],
}

type FieldError = {
  field: string,
  message: string,
}

class AssignOrderModalCmp extends React.Component<Props, IState> {
  dropdownRef = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      changed: false,
      courier: props.order.courierId || '',
      visible: false,
      errors: [],
    }
  }

  componentDidMount() {
    const { order } = this.props

    this.props.apiCouriersList({ market: order.marketId })

    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount(): void {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='order-assign'>
        <div className='order-assign__title'>{formatMessage(messages.AppointmentCourier)}</div>
        {this.renderOrder()}
        <div className='order-assign__courier'>
          <div className='order-assign__courier-title'>{formatMessage(messages.SelectCourier)}</div>
          <Form>{this.renderCourierList()}</Form>
        </div>
        <div className='order-assign__actions'>
          <Button outline title={formatMessage(messages.Cancel)} onClick={this.props.onClose} />
          <div style={{ marginLeft: '24px' }}>
            <Button title={formatMessage(messages.Appoint)} onClick={this.assign} />
          </div>
        </div>
      </div>
    )
  }

  renderOrder = () => {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const statusColor = getColor(order.statusId)
    const statusText =
      order.isCollected && order.statusId < EOrderStatus.COURIER
        ? order.isPicked
          ? formatMessage(messages.Picked)
          : formatMessage(messages.Collected)
        : convertStatus(order.statusId) || '–'

    return (
      <div className='order-assign__order'>
        <div className='order-assign__order-title'>{formatMessage(messages.Order)}</div>
        <div className='order-assign__order-info'>
          <div className='order-assign__order-info__num'>№{order.num}</div>
          <div className='order-assign__order-info__dot' />
          <div className='order-assign__order-info__address'>
            {order.selfPickup ? `${order.marketName} (${order.city}, ${order.marketAddress})` : order.deliveryAddress}
            {order.deliveryDistance !== undefined && (
              <div className='order-assign__order-info__distance'>
                ({round(order.deliveryDistance / 1000)}&nbsp;{formatMessage(messages.KmFromMarket).toLowerCase()})
              </div>
            )}
          </div>
          <div className='order-assign__order-info__dot' />
          {!!order.weightGr && (
            <>
              <div className='order-assign__order-info__weight'>{localeWeight(order.weightGr)}</div>
              <div className='order-assign__order-info__dot' />
            </>
          )}
          <div style={{ color: statusColor }}>{statusText}</div>
        </div>
      </div>
    )
  }

  assign = () => {
    const { onClose, apiAssignOrder, order } = this.props
    const { formatMessage } = this.props.intl
    const { courier } = this.state
    const errors: FieldError[] = []

    if (!courier) {
      errors.push({ field: FIELD_COURIER, message: formatMessage(messages.FieldMustNotEmpty) })
    }

    if (errors.length) {
      return this.setState({ errors })
    }

    apiAssignOrder({
      orderId: order.id,
      courierId: courier,
    })

    onClose()
  }

  onChange = (value: string) => {
    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    this.setState({ changed: true, courier: value, errors: this.removeError(FIELD_COURIER) })
  }

  renderCourierList = () => {
    const { couriers } = this.props
    const { formatMessage } = this.props.intl
    const { visible, courier } = this.state
    const options = couriers.map((c) => ({
      key: c.id,
      value: c.id,
      description: convertRole(c.role),
      text: this.getCourierName(c.phone, c.firstName, c.lastName),
      vehicleType: c.vehicleType,
      isBusy: c.isBusy,
      num: c.num,
      numActiveOrders: c.numActiveOrders,
    }))
    const selectCourier = options.find((item) => item.key === courier)

    return (
      <div
        ref={this.dropdownRef}
        className={classnames('ui dropdown', 'order-assign__courier-dropdown', visible && 'visible')}
        onClick={() => this.setState({ visible: !visible })}
      >
        <div className='order-assign__courier-elem'>
          {selectCourier ? (
            this.renderItem(
              `№${selectCourier.num}. ${selectCourier.text}`,
              selectCourier.vehicleType,
              selectCourier.isBusy,
              selectCourier.numActiveOrders,
            )
          ) : (
            <div className='text'>{formatMessage(messages.Courier)}</div>
          )}
          <i className='dropdown icon' />
        </div>
        <div className={classnames('menu transition', 'order-assign__courier-dropdown__menu', visible && 'visible')}>
          {options.map(({ vehicleType, isBusy, num, numActiveOrders, ...option }) => (
            <DropdownItem key={option.key} value={option.value} onClick={() => this.onChange(option.value)}>
              {this.renderItem(`№${num}. ${option.text}`, vehicleType, isBusy, numActiveOrders)}
            </DropdownItem>
          ))}
        </div>
      </div>
    )
  }

  renderItem = (text: string, vehicleType = EVehicleType.CAR, isBusy = false, numActiveOrders: number) => {
    const { formatMessage } = this.props.intl

    return (
      <div className='order-assign__courier-item'>
        <div>{text}</div>
        <div className='order-assign__courier-item__dot' />
        <div>{vehicleType === EVehicleType.MOTO ? formatMessage(messages.Moto) : formatMessage(messages.Car)}</div>
        <div className='order-assign__courier-item__dot' />
        <div className='order-assign__courier-item__busy' style={{ color: !isBusy ? '#33b04b' : '' }}>
          {isBusy ? `${formatMessage(messages.InWork)}: ${numActiveOrders}` : formatMessage(messages.Free)}
        </div>
      </div>
    )
  }

  handleClickOutside = (event: any) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ visible: false })
    }
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  getCourierName = (phone: string, firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
      return phone
    }
    return `${firstName || ''} ${lastName || ''} (${phone})`
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiAssignOrder: (data: TApi.AssignOrderReq) => dispatch(Actions.action(Actions.API_ORDER_ASSIGN, data)),
})

const mapStateToProps = (state: State): TConnectedProps => ({
  couriers: state.couriers.couriersList,
})

export const AssignOrderModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignOrderModalCmp))
