import React from 'react'
import { Dispatch } from 'redux'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Grid, Segment, Form, Header, Message } from 'semantic-ui-react'

import './index.scss'

import * as TApi from '../../../types/TApi'
import { EUserRole } from '../../../types/TClient'

import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { LOCATION_LOGIN } from '../../../utils/locationUtils'

type TConnectedProps = {
  loading: boolean,
  errorApi?: string,
}

type TDispatchedProps = {
  dropApiError: () => Actions.Action,
  apiSignUp: (data: TApi.ApiSignUpReq) => Actions.Action,
}

type Props = RouteComponentProps & TConnectedProps & TDispatchedProps

type FieldError = {
  field: string,
  message: string,
}

type StateSignUp = {
  login: string,
  email: string,
  role: number,
  password: string,
  repeatPassword: string,
  adminKey: string,
  errors: FieldError[],
}

const FIELD_LOGIN = 'login'
const FIELD_EMAIL = 'email'
const FIELD_ROLE = 'role'
const FIELD_PASSWORD = 'password'
const FIELD_REPEAT_PASSWORD = 'repeat_password'
const FIELD_ADMIN_KEY = 'admin_key'

const EMAIL_REGEXP = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'

class SignUpCmp extends React.Component<Props, StateSignUp> {
  constructor(props: Props) {
    super(props)

    this.state = {
      login: '',
      email: '',
      role: 1,
      password: '',
      repeatPassword: '',
      adminKey: '',
      errors: [],
    }
  }

  componentDidMount(): void {
    if (this.props.errorApi) {
      this.props.dropApiError()
    }
  }

  render() {
    const { loading, errorApi } = this.props

    return (
      <div className='signup'>
        <Grid textAlign='center' style={{ height: '100vh', width: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: '90%', width: 400, textAlign: 'left' }}>
            <Header as='h2'>Регистрация</Header>
            <Segment raised>
              <Form size='large' error={!!errorApi}>
                {this.renderErrorMessage()}

                {this.renderEmail()}
                {this.renderLogin()}
                {this.renderRole()}
                {this.renderPassword()}
                {this.renderRepeatPassword()}
                {this.renderAdminKey()}

                <Button
                  fluid
                  loading={loading}
                  disabled={loading}
                  color='teal'
                  size='large'
                  onClick={this.handleSubmit}
                >
                  Зарегистрироваться
                </Button>
              </Form>
            </Segment>

            <Message className='signup__message'>
              Вернуться на <Link to={LOCATION_LOGIN}>Войти</Link>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    )
  }

  renderEmail = () => {
    const error = this.checkError(FIELD_EMAIL)

    return (
      <Form.Input
        fluid
        name={FIELD_EMAIL}
        label='E-mail'
        icon='mail'
        iconPosition='left'
        placeholder='E-mail'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderLogin = () => {
    const error = this.checkError(FIELD_LOGIN)

    return (
      <Form.Input
        fluid
        icon='user'
        name={FIELD_LOGIN}
        label='Логин'
        iconPosition='left'
        placeholder='Введите логин'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderRole = () => {
    const options = [
      { key: 1, value: EUserRole.ADMIN, text: 'Админ' },
      { key: 2, value: EUserRole.MANAGER, text: 'Менеджер' },
      { key: 3, value: EUserRole.OPERATOR, text: 'Оператор' },
    ]

    return (
      <div className='signup__field'>
        <div className='signup__field-label'>Роль</div>
        <Form.Dropdown
          value={this.state.role}
          name={FIELD_ROLE}
          search
          selection
          options={options}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderPassword = () => {
    const error = this.checkError(FIELD_PASSWORD)

    return (
      <Form.Input
        fluid
        name={FIELD_PASSWORD}
        label='Пароль'
        icon='lock open'
        iconPosition='left'
        placeholder='Введите пароль'
        type='password'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderRepeatPassword = () => {
    const error = this.checkError(FIELD_REPEAT_PASSWORD)

    return (
      <Form.Input
        fluid
        name={FIELD_REPEAT_PASSWORD}
        label='Повторить пароль'
        icon='lock'
        iconPosition='left'
        placeholder='Повторите пароль'
        type='password'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderAdminKey = () => {
    const error = this.checkError(FIELD_ADMIN_KEY)

    return (
      <Form.Input
        fluid
        name={FIELD_ADMIN_KEY}
        label='Админ-ключ'
        icon='key'
        iconPosition='left'
        placeholder='Админ-ключ'
        type='password'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderErrorMessage = () => {
    const { errorApi } = this.props

    return (
      <Message error>
        <Message.Content className='signup__message-error'>
          <Message.Header>Ошибка</Message.Header>
          {errorApi}
        </Message.Content>
      </Message>
    )
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    switch (name) {
      case FIELD_EMAIL:
        this.setState({ email: value, errors: this.removeError(FIELD_EMAIL) })
        break
      case FIELD_LOGIN:
        this.setState({ login: value, errors: this.removeError(FIELD_LOGIN) })
        break
      case FIELD_ROLE:
        this.setState({ role: value, errors: this.removeError(FIELD_ROLE) })
        break
      case FIELD_PASSWORD:
        this.setState({ password: value, errors: this.removeError(FIELD_PASSWORD) })
        break
      case FIELD_REPEAT_PASSWORD:
        this.setState({ repeatPassword: value, errors: this.removeError(FIELD_REPEAT_PASSWORD) })
        break
      case FIELD_ADMIN_KEY:
        this.setState({ adminKey: value, errors: this.removeError(FIELD_ADMIN_KEY) })
        break
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    const { login, email, role, password, repeatPassword, adminKey } = this.state
    const errors: FieldError[] = []

    if (!email) {
      errors.push({ field: FIELD_EMAIL, message: 'Это поле обязательно' })
    } else if (email.length < 6 || email.length > 48) {
      errors.push({ field: FIELD_EMAIL, message: 'Не менее 6 и не более 48 символов' })
    } else if (!email.match(EMAIL_REGEXP)) {
      errors.push({ field: FIELD_EMAIL, message: 'Неверный формат' })
    }

    if (!login) {
      errors.push({ field: FIELD_LOGIN, message: 'Это поле обязательно' })
    } else if (login.length < 4 || login.length > 16) {
      errors.push({ field: FIELD_LOGIN, message: 'Не менее 8 и не более 16 символов' })
    }

    if (!password) {
      errors.push({ field: FIELD_PASSWORD, message: 'Это поле обязательно' })
    } else if (password.length < 8 || password.length > 24) {
      errors.push({ field: FIELD_PASSWORD, message: 'Не менее 8 и не более 24 символов' })
    }

    if (!repeatPassword) {
      errors.push({ field: FIELD_REPEAT_PASSWORD, message: 'Это поле обязательно' })
    }

    if (password !== repeatPassword) {
      errors.push({ field: FIELD_REPEAT_PASSWORD, message: 'Пароли не совпадают' })
    }

    if (!adminKey) {
      errors.push({ field: FIELD_ADMIN_KEY, message: 'Это поле обязательно' })
    }

    if (errors.length === 0) {
      this.props.apiSignUp({ login, email, role, password, adminKey })
    } else {
      this.setState({ errors })
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  loading: s.user.loading,
  errorApi: s.user.errorApi,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  dropApiError: () => dispatch(Actions.actionEmpty(Actions.DROP_API_ERROR)),
  apiSignUp: (data: TApi.ApiSignUpReq) => dispatch(Actions.action(Actions.API_SIGN_UP, data)),
})

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpCmp))
