import { Reducer } from 'redux'

import * as Actions from '../actions'

export type MutableStateReports = {
  loadingReportOrders: boolean,
  loadingReportProducts: boolean,
  loadingReportCustomers: boolean,
  loadingReportCourierShifts: boolean,
}

export type StateReports = Readonly<MutableStateReports>

const defStateReports: StateReports = {
  loadingReportOrders: false,
  loadingReportProducts: false,
  loadingReportCustomers: false,
  loadingReportCourierShifts: false,
}

export const reports: Reducer<StateReports, Actions.Action> = (s = defStateReports, a): StateReports => {
  switch (a.type) {
    case Actions.API_REPORT_ORDERS:
      return {
        ...s,
        loadingReportOrders: true,
      }
    case Actions.UPDATE_LOADING_REPORT_ORDERS:
      return {
        ...s,
        loadingReportOrders: a.data,
      }
    case Actions.API_REPORT_PRODUCTS:
      return {
        ...s,
        loadingReportProducts: true,
      }
    case Actions.UPDATE_LOADING_REPORT_PRODUCTS:
      return {
        ...s,
        loadingReportProducts: a.data,
      }
    case Actions.API_CUSTOMER_REPORT:
      return {
        ...s,
        loadingReportCustomers: true,
      }
    case Actions.UPDATE_LOADING_REPORT_CUSTOMERS:
      return {
        ...s,
        loadingReportCustomers: a.data,
      }
    case Actions.API_REPORT_COURIER_SHIFTS:
      return {
        ...s,
        loadingReportCourierShifts: true,
      }
    case Actions.UPDATE_LOADING_REPORT_COURIER_SHIFTS:
      return {
        ...s,
        loadingReportCourierShifts: a.data,
      }
  }
  return s
}
