import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const InformationIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.484 16.05c0-6.871 5.561-12.431 12.431-12.431 6.871 0 12.431 5.56 12.431 12.43 0 6.871-5.56 12.432-12.43 12.432-6.871 0-12.432-5.562-12.432-12.431ZM15.915 5.319c-5.93 0-10.73 4.799-10.73 10.73 0 5.932 4.798 10.732 10.73 10.732 5.931 0 10.731-4.8 10.731-10.731 0-5.932-4.799-10.731-10.73-10.731Zm-.002 17.162c-.71 0-1.287-.576-1.287-1.287v-5.147a1.287 1.287 0 1 1 2.574 0v5.147c0 .71-.576 1.287-1.287 1.287Zm1.287-11.58a1.287 1.287 0 1 0-2.574 0 1.287 1.287 0 0 0 2.574 0Z'
      />
    </svg>
  )
}
