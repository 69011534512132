import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import { Icon } from 'semantic-ui-react'

import './index.scss'

import { IModerationProduct } from '../../../types/TClient'

import messages from '../../../localization/messages'

type TOwnProps = {
  product: IModerationProduct,
}

type TProps = TOwnProps & WrappedComponentProps

class ProductSellerCmp extends React.Component<TProps> {
  render() {
    const { product } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='moderate-product__seller'>
        <div className='moderate-product__block-title'>{formatMessage(messages.Seller)}</div>
        <div>
          <div className='moderate-product__block-item'>
            <div className='moderate-product__block-label'>{formatMessage(messages.EntityShort)}</div>
            <div className={classnames('moderate-product__block-text', 'moderate-product__seller-organization')}>
              {product.sellerName} ({product.sellerOrganisation})
              {product.sellerIsCommon && (
                <div className='moderate-product__seller-common__icon'>
                  <Icon name='home' size='large' />
                </div>
              )}
            </div>
          </div>
          <div className='moderate-product__block-padding' />
          <div className='moderate-product__block-item'>
            <div className='moderate-product__block-label'>{formatMessage(messages.StoreNameAndNumber)}</div>
            <div className={classnames('moderate-product__block-text', 'moderate-product__seller-location')}>
              {product.sellerNickname}
              <div className='moderate-product__block-dot' />
              {product.sellerLocation}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const ProductSeller = injectIntl(ProductSellerCmp)
