import React from 'react'
import { connect } from 'react-redux'

import './index.scss'

import { EModerateProductStatus, IModerationProduct } from '../../../../../types/TClient'

import { State } from '../../../../../store/reducer'
import {
  ProductActions,
  ProductCategory,
  ProductDesc,
  ProductDivider,
  ProductHeader,
  ProductPrice,
  ProductSeller,
} from '../../../../../components/ModerateProductInfo'

type TOwnProps = {
  product: IModerationProduct,
}

type TConnectedProps = {
  loading: boolean,
}

type TProps = TOwnProps & TConnectedProps

class ProductPanelCmp extends React.Component<TProps> {
  render() {
    const { product } = this.props

    return (
      <>
        {product.status === EModerateProductStatus.NEW ? (
          this.renderActions()
        ) : (
          <div className='moderate-product__padding' />
        )}
        <div className='moderate-product'>
          {this.renderHeader()}
          {this.renderCategory()}
          {product.description && this.renderDesc()}
          {this.renderPrice()}
          {this.renderSeller()}
        </div>
      </>
    )
  }

  renderActions = () => {
    const { product } = this.props

    return (
      <div className='moderate-product-actions-container'>
        <div className='moderate-product-actions'>
          <ProductActions product={product} />
        </div>
      </div>
    )
  }

  renderHeader() {
    const { product } = this.props

    return (
      <>
        <ProductHeader product={product} />
        <ProductDivider />
      </>
    )
  }

  renderCategory() {
    const { product } = this.props

    return (
      <>
        <ProductCategory product={product} />
        <ProductDivider />
      </>
    )
  }

  renderDesc() {
    const { product } = this.props

    return (
      <>
        <ProductDesc product={product} />
        <ProductDivider />
      </>
    )
  }

  renderPrice() {
    const { product } = this.props

    return (
      <>
        <ProductPrice product={product} />
        <ProductDivider />
      </>
    )
  }

  renderSeller() {
    const { product } = this.props

    return (
      <>
        <ProductSeller product={product} />
      </>
    )
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading } = s.products.moderationProducts

  return {
    loading,
  }
}

export const ProductPanel = connect(mapStateToProps)(ProductPanelCmp)
