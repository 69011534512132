import { AjaxError } from 'rxjs/ajax'

export function log(err: string | Error, extra?: any): void {
  // eslint-disable-next-line no-console
  console.log(typeof err === 'string' ? new Error(err) : err, extra && { extra })
}

export function httpLogger(url: string, params: any, response: AjaxError): void {
  log(new Error(`${url} ${response.status}`), {
    req: params,
    res: response.xhr.response,
  })
}

export function logAjaxError(err: AjaxError) {
  const { request, xhr, status } = err
  const url = request.url || ''
  const [pathname, query] = url.split('?')

  log(`${request.method} ${pathname} ${status}`, {
    body: request.body,
    query,
    res: xhr.response,
  })
}
