import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const AlertIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} fill='none'>
      <path d='M12 8L12 12M12 15.5L12 15' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M4.9997 19.0005H18.9997C19.326 18.9982 19.6468 18.9161 19.9341 18.7614C20.2214 18.6066 20.4664 18.384 20.6479 18.1127C20.8294 17.8415 20.9417 17.53 20.9751 17.2055C21.0086 16.8809 20.9621 16.553 20.8397 16.2505L13.7397 4.0005C13.5667 3.6879 13.3132 3.42733 13.0055 3.24589C12.6977 3.06445 12.347 2.96875 11.9897 2.96875C11.6324 2.96875 11.2817 3.06445 10.9739 3.24589C10.6662 3.42733 10.4127 3.6879 10.2397 4.0005L3.1397 16.2505C3.01964 16.5461 2.97203 16.8661 3.00085 17.1838C3.02967 17.5015 3.13407 17.8078 3.30535 18.0769C3.47663 18.3461 3.70981 18.5703 3.98543 18.731C4.26104 18.8917 4.5711 18.9841 4.8897 19.0005'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
