import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import moment from 'moment'

import './index.scss'

import { ICustomer } from '../../../types/TClient'

import messages from '../../../localization/messages'

type TOwnProps = {
  customer: ICustomer,
}

type TProps = TOwnProps & WrappedComponentProps

class CustomerActivityCmp extends React.Component<TProps> {
  render() {
    const { customer } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__activity'>
        <div className='customer__block-row'>
          <div className='customer__block-item'>
            <div className='customer__block-label'>{formatMessage(messages.Registration)}</div>
            <div className='customer__block-text'>{moment(customer.registerAt).format('DD.MM.YYYY')}</div>
          </div>
          <div className='customer__block-item'>
            <div className='customer__block-label'>{formatMessage(messages.FirstOrder)}</div>
            <div className='customer__block-text'>
              {customer.firstOrderCreatedAt ? moment(customer.firstOrderCreatedAt).format('DD.MM.YYYY') : '–'}
            </div>
          </div>
          <div className='customer__block-item'>
            <div className='customer__block-label'>{formatMessage(messages.TotalOrders)}</div>
            <div className='customer__block-text'>{customer.ordersCount || '–'}</div>
          </div>
        </div>
      </div>
    )
  }
}

export const CustomerActivity = injectIntl(CustomerActivityCmp)
