import { Reducer } from 'redux'
import { uniqBy } from 'lodash'
import moment from 'moment'

import { EOrderStatus, IOrder, IOrderByMap, IOrdersFilter, OrderHistoryMap, TDeliveryArea } from '../../types/TClient'
import { IClientSlot, ISlotType } from '../../types/TApi'

import * as Actions from '../actions'

export type MutableStateOrders = {
  updateLoading: boolean,
  loading: boolean,
  loaded: boolean,
  filter: IOrdersFilter,
  ordersList: IOrder[],
  ordersMapList: IOrderByMap[],
  total: number,
  updateError: boolean,
  slots: IClientSlot[],
  slotTypes: ISlotType[],
  orderHistoryMap: OrderHistoryMap,
  orderHistoryLoading: boolean,
  deliveryArea?: TDeliveryArea,
}

export type StateOrders = Readonly<MutableStateOrders>

const defStateOrders: StateOrders = {
  updateLoading: false,
  loading: false,
  loaded: false,
  filter: {
    offset: 0,
    statuses: [EOrderStatus.NEW, EOrderStatus.IN_ASSEMBLY, EOrderStatus.COURIER],
    dateDeliveryFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    dateDeliveryTo: moment().format('YYYY-MM-DD'),
  },
  ordersList: [],
  ordersMapList: [],
  total: 0,
  updateError: false,
  slots: [],
  slotTypes: [],
  orderHistoryMap: new Map(),
  orderHistoryLoading: true,
}

const ORDERS_LIMIT = 10

export const orders: Reducer<StateOrders, Actions.Action> = (s = defStateOrders, a): StateOrders => {
  switch (a.type) {
    case Actions.API_ORDERS_LIST:
      return {
        ...s,
        loading: true,
        loaded: false,
      }
    case Actions.ORDERS_LIST: {
      let oldOrders = !a.data.force ? s.ordersList : []
      let newOrders = [...a.data.orders]

      oldOrders = oldOrders.map((order) => {
        const exist = newOrders.find((item) => item.id === order.id)

        if (exist) {
          newOrders = newOrders.filter((item) => item.id !== order.id)

          return exist
        }

        return order
      })

      return {
        ...s,
        loading: false,
        loaded: a.data.orders.length < ORDERS_LIMIT,
        ordersList: uniqBy(
          [...(a.data.offset ? oldOrders : []), ...newOrders, ...(!a.data.offset ? oldOrders : [])],
          'id',
        ),
        total: a.data.force ? a.data.total || a.data.orders.length : a.data.total || s.total + a.data.orders.length,
      }
    }
    case Actions.ORDERS_MAP_LIST: {
      const oldOrders = !a.data.force ? s.ordersMapList : []

      return {
        ...s,
        ordersMapList: [...(a.data.offset ? oldOrders : []), ...a.data.orders, ...(!a.data.offset ? oldOrders : [])],
      }
    }
    case Actions.ORDER_CHANGE_PRODUCT_SUCCESS:
    case Actions.UPDATE_ORDER:
      return {
        ...s,
        updateLoading: false,
        loading: false,
        ordersList: s.ordersList.map((order) => {
          if (order.id === a.data.id) {
            return {
              ...order,
              ...a.data,
            }
          }

          return order
        }),
      }

    case Actions.APP_GET_SLOTS_SUCCESS: {
      return {
        ...s,
        slots: a.data.slots,
        slotTypes: a.data.type,
        deliveryArea: a.data.delivery_area,
      }
    }
    case Actions.APP_GET_SLOTS_ERROR: {
      return {
        ...s,
        slots: [],
        slotTypes: [],
        deliveryArea: undefined,
      }
    }
    case Actions.ORDERS_FILTER:
      return {
        ...s,
        filter: {
          ...a.data,
          ...(!a.data.market && { seller: undefined }),
        },
        ordersList: [],
        loaded: false,
      }
    case Actions.API_ORDER_HISTORY: {
      return {
        ...s,
        orderHistoryLoading: true,
      }
    }
    case Actions.API_ORDER_HISTORY_ERROR: {
      return {
        ...s,
        orderHistoryLoading: false,
      }
    }
    case Actions.ORDER_HISTORY: {
      const oldHistory = new Map(s.orderHistoryMap)

      oldHistory.set(a.data.orderId, a.data.history)

      return {
        ...s,
        orderHistoryMap: oldHistory,
        orderHistoryLoading: false,
      }
    }
    case Actions.UPDATE_ORDER_ERROR:
      return {
        ...s,
        updateError: a.data,
      }
  }
  return s
}
