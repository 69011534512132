import React from 'react'
import { Icon, Input, InputOnChangeData, Label } from 'semantic-ui-react'

import './index.scss'

import { CloseIcon } from '../Icons'

type TProps = {
  onChange(event: React.ChangeEvent<HTMLInputElement> | undefined, data: InputOnChangeData): void,
  value: string,
  maxLength?: number,
  placeholder?: string,
}

export const SearchField: React.FC<TProps> = ({ onChange, value, placeholder }) => {
  return (
    <div className='search-field'>
      <Input
        value={value}
        className='search-field__input'
        labelPosition='right'
        type='text'
        placeholder={placeholder}
        onChange={onChange}
      >
        <input />
        {value.length > 0 && (
          <div className='search-field__clear' onClick={() => onChange(undefined, { value: '' })}>
            <CloseIcon width={20} height={20} color='#000' />
          </div>
        )}
        <Label className='search-field__label'>
          <Icon className='search-field__icon' name='search' />
        </Label>
      </Input>
    </div>
  )
}
