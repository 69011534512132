import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Popup } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { IModerationProduct } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { checkMobile } from '../../../utils/deviceUtils'

type TOwnProps = {
  product: IModerationProduct,
}

type TProps = TOwnProps & WrappedComponentProps

class ProductCategoryCmp extends React.Component<TProps> {
  render() {
    const { product } = this.props
    const { formatMessage } = this.props.intl
    const isMobile = checkMobile()

    return (
      <div className='moderate-product__header'>
        <div className='moderate-product__block-row'>
          <div className='moderate-product__block-item'>
            <div className='moderate-product__block-label'>{formatMessage(messages.Category)}</div>
            {product.category !== product.oldCategory ? (
              <div className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}>
                <Popup
                  position='top center'
                  style={{ marginLeft: isMobile ? '20px' : 0 }}
                  trigger={<span>{product.category}</span>}
                >
                  <b>{formatMessage(messages.PastValue)}:</b>
                  <br />
                  {product.oldCategory}
                </Popup>
              </div>
            ) : (
              <div className='moderate-product__block-text'>{product.category}</div>
            )}
          </div>
          <div className='moderate-product__block-item'>
            <div className='moderate-product__block-label'>{formatMessage(messages.Subcategory)}</div>
            {product.subcategory !== product.oldSubcategory ? (
              <div className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}>
                <Popup position='top center' trigger={<span>{product.subcategory}</span>}>
                  <b>{formatMessage(messages.PastValue)}:</b>
                  <br />
                  {product.oldSubcategory}
                </Popup>
              </div>
            ) : (
              <div className='moderate-product__block-text'>{product.subcategory}</div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export const ProductCategory = injectIntl(ProductCategoryCmp)
