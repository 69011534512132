import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Checkbox, Dropdown, Form, Popup } from 'semantic-ui-react'
import classnames from 'classnames'
import { isEqual } from 'lodash'
import moment from 'moment'

import './index.scss'

import {
  EOrdersFilter,
  EOrderStatus,
  ICity,
  IMarket,
  IOrdersFilter,
  CURRENT_FILTER,
  ARCHIVE_FILTER,
  DAY_HOUR,
  HOUR_MINUTE,
  MINUTE_SEC,
  SEC,
  ISeller,
} from '../../../types/TClient'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'
import { checkMobile } from '../../../utils/deviceUtils'
import { InfoIcon } from '../../Icons'

type TOwnProps = {
  title: string,
  type: EOrdersFilter,
  onClose: () => void,
}

type TConnectedProps = {
  loading: boolean,
  filter: IOrdersFilter,
  cities: ICity[],
  markets: IMarket[],
  sellers: ISeller[],
  errorApi?: string,
}

type TDispatchedProps = {
  updateFilter: (filter: IOrdersFilter) => Actions.Action,
  dropApiError: () => Actions.Action,
  getCities: () => Actions.Action,
  getMarkets: (cityId: string) => Actions.Action,
  getSellers: (marketId: string) => Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  changed: boolean,
  city?: string,
  market?: string,
  dateFrom?: string,
  dateTo?: string,
  seller?: string,
  statuses?: EOrderStatus[],
  b2b?: boolean,
  b2c?: boolean,
  errors: FieldError[],
}

const FIELD_CITY = 'order_city'
const FIELD_MARKET = 'order_market'
const FIELD_SELLERS = 'order_sellers'
const FIELD_STATUSES = 'order_statuses'
const FIELD_DATE_FROM = 'order_date_from'
const FIELD_DATE_TO = 'order_date_to'

class OrdersFilterCmp extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      changed: false,
      dateFrom: props.filter.dateDeliveryFrom,
      dateTo: props.filter.dateDeliveryTo,
      city: props.filter.city || '',
      market: props.filter.market || '',
      statuses: props.filter.statuses || [],
      seller: props.filter.market ? props.filter.seller || '' : '',
      b2b: props.filter.b2b === undefined ? true : props.filter.b2b,
      b2c: props.filter.b2b === undefined || !props.filter.b2b,
      errors: [],
    }
  }

  componentDidMount(): void {
    const { cities, getSellers, filter } = this.props
    const { market } = this.state

    if (cities.length === 0) {
      this.props.getCities()
    }

    if (market !== filter.market) {
      this.setState({ market: filter.market })
    }

    if (filter.market) {
      getSellers(filter.market)
    }
  }

  componentDidUpdate(_prevProps: Readonly<Props>) {
    const { sellers, filter } = this.props
    const { seller, market } = this.state

    if (seller && (!sellers.find((s) => s.id === seller) || !filter.market || !market)) {
      this.setState({ seller: '' })
    }
  }

  render() {
    const { type } = this.props
    const { formatMessage } = this.props.intl
    const isMobile = checkMobile()

    return (
      <div className='orders-filter'>
        <div className='orders-filter__title'>{this.props.title}</div>
        {this.renderDivider()}
        <div>
          <Form>
            {type === EOrdersFilter.ARCHIVE && this.renderPeriod()}
            {isMobile && this.renderCity()}
            {isMobile && this.renderMarket()}
            {this.renderSellers()}
            {this.renderDivider()}
            {this.renderStatuses()}
            {this.renderDivider()}
            {this.renderClientType()}
          </Form>
        </div>
        <div className='orders-filter__actions'>
          <div className='orders-filter__actions-btn' onClick={this.updateFilter}>
            {formatMessage(messages.Apply)}
          </div>
          <div
            className={classnames('orders-filter__actions-btn', 'orders-filter__actions-btn-cancel')}
            onClick={this.dropFilter}
          >
            {formatMessage(messages.Reset)}
          </div>
        </div>
      </div>
    )
  }

  renderSellers = () => {
    const { formatMessage } = this.props.intl
    const { sellers, filter } = this.props
    const { seller } = this.state

    const disabled = !filter.market
    const options = sellers.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='orders-filter__field'>
        <div className='orders-filter__field-label'>
          {formatMessage(messages.Seller)}
          {disabled && (
            <Popup
              offset={[-10, 0]}
              content={formatMessage(messages.SelectMarketFilter)}
              trigger={
                <div className='orders-filter__icon'>
                  <InfoIcon width={20} height={20} color='#a0a3b5' />
                </div>
              }
            />
          )}
        </div>
        <Dropdown
          className='orders-filter__field-drop'
          value={seller}
          name={FIELD_SELLERS}
          placeholder={formatMessage(messages.Seller)}
          search
          selection
          clearable
          disabled={disabled}
          options={options}
          onChange={this.handleChange}
        />
      </div>
    )
  }
  renderCity = () => {
    const { cities } = this.props
    const { formatMessage } = this.props.intl
    const { city } = this.state

    const options = cities.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='orders-filter__field'>
        <div className='orders-filter__field-label'>{formatMessage(messages.City)}:</div>
        <Dropdown
          className='orders-filter__field-drop'
          value={city}
          name={FIELD_CITY}
          search
          selection
          clearable
          options={options}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderMarket = () => {
    const { markets } = this.props
    const { formatMessage } = this.props.intl
    const { market } = this.state

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='orders-filter__field'>
        <div className='orders-filter__field-label'>{formatMessage(messages.Market)}:</div>
        <Dropdown
          className='orders-filter__field-drop'
          value={market}
          name={FIELD_MARKET}
          search
          selection
          clearable
          options={options}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderStatuses = () => {
    const { type } = this.props
    const { formatMessage } = this.props.intl
    const { statuses = [] } = this.state
    const options = [
      { key: '1', text: formatMessage(messages.New), value: EOrderStatus.NEW },
      { key: '2', text: formatMessage(messages.InAssembly), value: EOrderStatus.IN_ASSEMBLY },
      { key: '3', text: formatMessage(messages.AtCourier), value: EOrderStatus.COURIER },
      { key: '4', text: formatMessage(messages.Delivered), value: EOrderStatus.DELIVERED },
      { key: '5', text: formatMessage(messages.Canceled), value: EOrderStatus.CANCELED },
    ].filter((item) => {
      if (type === EOrdersFilter.CURRENT) {
        return item.value < EOrderStatus.DELIVERED
      }

      return item.value >= EOrderStatus.DELIVERED
    })

    return (
      <div className='orders-filter__field'>
        <div className='orders-filter__field-label'>{formatMessage(messages.Statuses)}</div>
        <div className='orders-filter__field-column'>
          {options.map((item) => {
            return (
              <Checkbox
                key={item.key}
                className='orders-filter__field-value'
                label={item.text}
                checked={statuses.includes(item.value)}
                onChange={() => this.changeStatuses(item.value)}
              />
            )
          })}
        </div>
        {/*<Dropdown*/}
        {/*  className='orders-filter__field-value'*/}
        {/*  multiple*/}
        {/*  selection*/}
        {/*  clearable*/}
        {/*  value={statuses}*/}
        {/*  name={FIELD_STATUSES}*/}
        {/*  options={options}*/}
        {/*  onChange={this.handleChange}*/}
        {/*/>*/}
      </div>
    )
  }

  renderClientType = () => {
    const { formatMessage } = this.props.intl
    const { b2b, b2c } = this.state

    return (
      <div className='orders-filter__field'>
        <div className='orders-filter__field-label'>{formatMessage(messages.ClientType)}</div>
        <Checkbox
          className='orders-filter__field-value'
          label={'B2B'}
          checked={b2b}
          onChange={() => this.setState(({ b2b, b2c }) => ({ b2b: !b2b, b2c: b2b && !b2c ? true : b2c }))}
        />
        <Checkbox
          className='orders-filter__field-value'
          label={'B2C'}
          checked={b2c}
          onChange={() => this.setState(({ b2b, b2c }) => ({ b2c: !b2c, b2b: !b2b && b2c ? true : b2b }))}
        />
      </div>
    )
  }

  renderPeriod = () => {
    const { formatMessage } = this.props.intl

    return (
      <div>
        <div className='orders-filter__field-label'>{formatMessage(messages.Period)}</div>
        <div className='orders-filter__period'>
          {this.renderDateFrom()}
          <div className='orders-filter__period-divider'>–</div>
          {this.renderDateTo()}
        </div>
        {this.renderDivider()}
      </div>
    )
  }

  renderDateFrom = () => {
    const { dateFrom } = this.state
    const error = this.checkError(FIELD_DATE_FROM)

    return (
      <Form.Input
        value={dateFrom}
        name={FIELD_DATE_FROM}
        type='date'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderDateTo = () => {
    const { dateTo } = this.state
    const error = this.checkError(FIELD_DATE_TO)

    return (
      <Form.Input
        value={dateTo}
        name={FIELD_DATE_TO}
        type='date'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderDivider = () => {
    return <div className='orders-filter__divider' />
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (this.props.errorApi) {
      this.props.dropApiError()
    }

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_CITY:
        this.setState({ city: value, market: '', errors: this.removeError(FIELD_CITY) })
        this.props.getMarkets(value)
        break
      case FIELD_MARKET:
        this.setState({ market: value, errors: this.removeError(FIELD_MARKET) })
        this.state.market && this.props.getSellers(this.state.market)
        break
      case FIELD_STATUSES: {
        const statuses = [...value]
        statuses.sort()

        this.setState({ statuses, errors: this.removeError(FIELD_STATUSES) })
        break
      }
      case FIELD_SELLERS:
        this.setState({ seller: value, errors: this.removeError(FIELD_SELLERS) })
        break
      case FIELD_DATE_FROM:
        this.setState({ dateFrom: value, errors: this.removeError(FIELD_DATE_FROM) })
        break
      case FIELD_DATE_TO:
        this.setState({ dateTo: value, errors: this.removeError(FIELD_DATE_TO) })
        break
    }
  }

  changeStatuses = (value: EOrderStatus) => {
    const { statuses = [] } = this.state

    const newStatuses = statuses.includes(value) ? statuses.filter((item) => item !== value) : [...statuses, value]
    newStatuses.sort()

    this.setState({ statuses: newStatuses, errors: this.removeError(FIELD_STATUSES) })
  }

  updateFilter = () => {
    const { city, market, statuses, b2b, b2c, dateFrom, dateTo, seller } = this.state
    const errors: FieldError[] = []

    if (!errors.length) {
      const newFilter = {
        offset: 0,
        ...(city && { city }),
        ...(market && { market }),
        ...(statuses && statuses.length > 0 && { statuses }),
        ...(dateFrom && { dateDeliveryFrom: dateFrom }),
        ...(dateTo && { dateDeliveryTo: dateTo }),
        ...((b2b || b2c) && !(b2b && b2c) ? { b2b: b2b } : {}),
        ...(seller && { seller }),
      }

      this.checkFilterUpdate(newFilter)
    } else {
      this.setState({ errors })
    }
  }

  dropFilter = () => {
    const { type } = this.props

    const newFilter = {
      offset: 0,
      statuses: type === EOrdersFilter.CURRENT ? CURRENT_FILTER.statuses : ARCHIVE_FILTER.statuses,
      dateDeliveryTo: moment().format('YYYY-MM-DD'),
      dateDeliveryFrom:
        type === EOrdersFilter.CURRENT
          ? moment().subtract(3, 'days').format('YYYY-MM-DD')
          : moment(Date.now() - 6 * DAY_HOUR * HOUR_MINUTE * MINUTE_SEC * SEC).format('YYYY-MM-DD'),
      ...(type === EOrdersFilter.CURRENT && { dateTo: moment().format('YYYY-MM-DD') }),
    }

    this.checkFilterUpdate(newFilter)
  }

  checkFilterUpdate = (newFilter: IOrdersFilter) => {
    const { filter } = this.props

    if (!isEqual(filter, newFilter)) {
      this.props.updateFilter(newFilter)
    }

    this.props.onClose()
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  return {
    loading: false,
    filter: s.orders.filter,
    cities: s.markets.cities || [],
    markets: s.markets.markets || [],
    sellers: s.sellers.sellersList || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  updateFilter: (filter: IOrdersFilter) =>
    dispatch(
      Actions.action(Actions.ORDERS_FILTER, {
        ...filter,
      }),
    ),
  getCities: () =>
    dispatch(
      Actions.action(Actions.API_CITIES, {
        include_all: 'True',
      }),
    ),
  getMarkets: (cityId: string) =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        city: cityId,
        include_all: 'True',
      }),
    ),
  getSellers: (marketId: string) =>
    dispatch(Actions.action(Actions.API_SELLERS_LIST, { offset: 0, market: marketId, force: true })),
  dropApiError: () => dispatch(Actions.actionEmpty(Actions.DROP_PRODUCT_ERROR)),
})

export const OrdersFilterModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrdersFilterCmp))
