import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Form, Icon, Loader, Popup } from 'semantic-ui-react'
import classnames from 'classnames'
import { round, sortBy, uniq } from 'lodash'

import './index.scss'

import {
  ISeller,
  EImageType,
  IStep,
  IUnit,
  ICountry,
  ICategory,
  ISellerProduct,
  ITag,
  IStockCategory,
  IProduct,
  IMarket,
} from '../../../types/TClient'
import {
  ApiMarketCategoriesReq,
  ApiProductCopyReq,
  ApiProductCreateReq,
  ApiProductUpdateReq,
} from '../../../types/TApi'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'
import { EModalType, IModalImageCropper } from '../../../store/reducers/modals'
import { Button } from '../../Button'
import { CropIcon, PictureIcon, UploadIcon } from '../../Icons'
import { InputField } from '../../InputField'
import { DropdownField } from '../../DropdownField'
import { TextAreaField } from '../../TextAreaField'
import {
  formatPrice,
  linkByProducts,
  linkBySeller,
  sourcePrice,
  UNIT_KG,
  UNIT_LITRE,
  UNIT_PCS,
  unitIntl,
  updatePriceByCommission,
} from '../../../utils/productsUtils'
import { copyToClipboard } from '../../../utils/deviceUtils'
import { sortByName } from '../../../utils/tagsUtils'
import defaultImg from '../../../assets/images/default-image.png'
import { countryLocalization } from '../../../utils/langUtils'

type TOwnProps = {
  title: string,
  marketId: string,
  product: ISellerProduct | null,
  marketProduct?: IProduct,
  seller?: ISeller,
  onClose: () => void,
}

type TConnectedProps = {
  imageUrl: string,
  loading: boolean,
  countries: ICountry[],
  categories: ICategory[],
  marketCategories: IStockCategory[],
  steps: IStep[],
  units: IUnit[],
  tags: ITag[],
  commonSellers: string[],
  market?: IMarket,
  errorApi?: string,
}

type TDispatchedProps = {
  cropImage: (data: IModalImageCropper) => Actions.Action,
  uploadImage: (file: File, type: EImageType) => Actions.Action,
  apiCreateProduct: (data: ApiProductCreateReq) => Actions.Action,
  apiUpdateProduct: (data: ApiProductUpdateReq) => Actions.Action,
  apiCopyProduct: (data: ApiProductCopyReq) => Actions.Action,
  dropApiError: () => Actions.Action,
  getCountries: () => Actions.Action,
  getCategories: () => Actions.Action,
  getMarketCategories: (data: ApiMarketCategoriesReq) => Actions.Action,
  getSteps: () => Actions.Action,
  getUnits: () => Actions.Action,
  dropProductSaved: () => Actions.Action,
  getTags(market_id: string): Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  changed: boolean,
  imgLoading: boolean,
  name: string,
  description: string,
  country: string,
  category: string,
  subcategory: string,
  stockCategory: string,
  stockSubcategory: string,
  vendorCode: string,
  rating?: number,
  unit: string,
  weightPcsGr: number,
  step: number,
  price: number,
  oldPrice: number,
  aboutSeller: string,
  updatedPrice: boolean,
  pricePerWeight: string,
  selectedTags: number[],
  errors: FieldError[],
}

const FIELD_NAME = 'product_name'
const FIELD_DESCRIPTION = 'product_description'
const FIELD_COUNTRY = 'product_country'
const FIELD_CATEGORY = 'product_category'
const FIELD_SUBCATEGORY = 'product_subcategory'
const FIELD_STOCK_CATEGORY = 'product_stock_category'
const FIELD_STOCK_SUBCATEGORY = 'product_stock_subcategory'
const FIELD_VENDOR_CODE = 'product_vendor_code'
const FIELD_RATING = 'product_rating'
const FIELD_UNIT = 'product_unit'
const FIELD_WEIGHT_PCS_GR = 'product_weight_pcs_gr'
const FIELD_PRICE_PER_WEIGHT = 'product_price_per_weight'
const FIELD_STEP = 'product_step'
const FIELD_PRICE = 'product_price'
const FIELD_OLD_PRICE = 'product_old_price'
const FIELD_IMAGE = 'product_image'
const FIELD_ABOUT_SELLER = 'product_about_seller'
const FIELD_TAGS = 'product_tags'

const NAME_LIMIT = 128
const DESCRIPTION_LIMIT = 4096
const MAX_VENDOR_CODE = 48
const DEFAULT_COMMISSION = 15

class ProductCmp extends React.Component<Props, IState> {
  private imageRef = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)

    const { product, categories } = props
    let category = ''

    if (product) {
      category = this.getCategory(product, categories)
    }

    const productPrice = product && product.price ? +product.price : 1

    this.state = {
      changed: false,
      imgLoading: props.loading,
      name: product?.name || '',
      description: product?.description || '',
      country: product?.country || '',
      category,
      subcategory: product?.subcategory || '',
      stockCategory: product?.stockCategory || '',
      stockSubcategory: product?.stockSubcategory || '',
      vendorCode: product?.vendorCode || '',
      rating: product?.rating,
      unit: product?.unit || '',
      weightPcsGr: product?.pcsWeightGr || 1000,
      step: product?.step || 1,
      price: product && product.price ? +product.price : 1,
      oldPrice: product && product.priceWithoutDiscount ? product.priceWithoutDiscount : productPrice,
      updatedPrice: false,
      aboutSeller: product?.aboutSeller || '',
      selectedTags: product?.tags || [],
      pricePerWeight: this.weightToKg(product?.priceWeightGr) || '1',
      errors: [],
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { loading, product, categories } = this.props

    if (product && product.subcategory && categories.length !== prevProps.categories.length) {
      const category = this.getCategory(product, categories)

      this.setState({ category })
    }

    if (loading !== prevProps.loading) {
      this.setState({ imgLoading: loading })
    }
  }

  componentDidMount(): void {
    const { countries, categories, units, steps, marketId } = this.props

    if (countries.length === 0) {
      this.props.getCountries()
    }

    if (categories.length === 0) {
      this.props.getCategories()
    }

    if (units.length === 0) {
      this.props.getUnits()
    }

    if (steps.length === 0) {
      this.props.getSteps()
    }

    this.props.dropProductSaved()
    this.props.getTags(marketId)
    this.props.getMarketCategories({ marketId })
  }

  render() {
    const { seller, product } = this.props
    const { formatMessage } = this.props.intl
    const { unit } = this.state

    return (
      <div className='modal-product'>
        <div className='modal-product__header'>
          <div className='modal-product__title'>{this.props.title}</div>
          {product && this.renderLink(product)}
        </div>
        <Form>
          <div className='modal-product__form'>
            {this.renderImage()}
            <div className='modal-product__form-fields'>
              <div className='modal-product__form-block'>
                {this.renderName()}
                {seller && seller.isCommon && this.renderAboutSeller()}
              </div>
              <div className='modal-product__form-block'>
                {this.renderDescription()}
                <div className='modal-product__country-tags'>
                  {this.renderTags()}
                  {this.renderCountry()}
                </div>
              </div>
              <div className='modal-product__form-block'>
                <div className='modal-product__category-title'>{formatMessage(messages.BestFromMarket)}</div>
                {this.renderStockCategory()}
                {this.renderStockSubcategory()}
                {this.state.stockCategory && this.renderVendorCode()}
                {this.state.stockCategory && this.renderRating()}
              </div>
              <div className='modal-product__form-block'>
                <div className='modal-product__category-title'>{formatMessage(messages.StallsSellers)}</div>
                {this.renderCategory()}
                {this.renderSubcategory()}
              </div>
              <div className='modal-product__divider' />
              <div className='modal-product__form-block'>
                {this.renderUnit()}
                {unit === UNIT_PCS && this.renderWeightPcsGr()}
                {(unit === UNIT_KG || unit === UNIT_LITRE) && this.renderPricePerWeight()}
                {this.renderStep()}
              </div>
              <div className='modal-product__form-block'>
                {this.renderOldPrice()}
                {this.renderPrice()}
              </div>
            </div>
          </div>
        </Form>
        <div className='modal-product__actions'>
          <Button outline title={formatMessage(messages.Cancel)} onClick={this.props.onClose} />
          <Button title={formatMessage(messages.Save)} onClick={this.createProduct} />
        </div>
      </div>
    )
  }

  get currentUnit() {
    const { formatMessage } = this.props.intl
    const { unit } = this.state

    return unit === UNIT_KG || unit === UNIT_PCS
      ? formatMessage(messages.Kg).toLowerCase()
      : formatMessage(messages.Litre).toLowerCase()
  }

  renderAboutSeller = () => {
    const { commonSellers, product } = this.props
    const { formatMessage } = this.props.intl
    const { aboutSeller } = this.state
    const error = this.checkError(FIELD_ABOUT_SELLER)
    const options = commonSellers.map((c) => ({ key: c, value: c, text: c }))

    const exist = aboutSeller ? commonSellers.find((item) => item === aboutSeller) : false

    if (!exist && aboutSeller && aboutSeller !== product?.aboutSeller) {
      options.unshift({ key: aboutSeller, value: aboutSeller, text: aboutSeller })
    }

    return (
      <div className={classnames('modal-product__about')}>
        <DropdownField
          search
          allowAdditions
          name={FIELD_ABOUT_SELLER}
          value={aboutSeller}
          label={formatMessage(messages.SellerPlace)}
          placeholder={formatMessage(messages.SellerPlace)}
          options={options}
          error={error ? error.message : ''}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderLink = (product: ISellerProduct) => {
    const { seller, market } = this.props
    const { formatMessage } = this.props.intl
    const cityId = seller?.cityId || market?.city_id || ''
    const marketId = seller?.marketId || market?.id || ''

    const productsProductLink = linkByProducts(cityId, marketId, product.id)
    const sellerProductLink = linkBySeller(cityId, marketId, product.id)

    return (
      <div className='modal-product__links'>
        <div className='modal-product__links-title'>{formatMessage(messages.ProductLinks)}:</div>
        <div className='modal-product__links-link'>
          <a href={productsProductLink}>{formatMessage(messages.ThroughCategories)}</a>
          <div className='modal-product__links-link__icon' onClick={() => copyToClipboard(productsProductLink)}>
            <Icon name='copy outline' size='large' />
          </div>
        </div>
        <div className='modal-product__links-link'>
          <a href={sellerProductLink}>{formatMessage(messages.ThroughSeller)}</a>
          <div className='modal-product__links-link__icon' onClick={() => copyToClipboard(sellerProductLink)}>
            <Icon name='copy outline' size='large' />
          </div>
        </div>
      </div>
    )
  }

  renderName = () => {
    const { formatMessage } = this.props.intl
    const { name } = this.state
    const error = this.checkError(FIELD_NAME)

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__name')}>
        <InputField
          name={FIELD_NAME}
          value={name}
          label={formatMessage(messages.ProductName)}
          placeholder={formatMessage(messages.ProductName)}
          error={error ? error.message : ''}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderDescription = () => {
    const { formatMessage } = this.props.intl
    const { description } = this.state
    const error = this.checkError(FIELD_DESCRIPTION)

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__description')}>
        <TextAreaField
          name={FIELD_DESCRIPTION}
          value={description}
          label={formatMessage(messages.Description)}
          placeholder={formatMessage(messages.Description)}
          error={error ? error.message : ''}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderCountry = () => {
    const { countries } = this.props
    const { formatMessage } = this.props.intl
    const { country } = this.state
    const error = this.checkError(FIELD_COUNTRY)

    const options = countries.map((c) => ({ key: c.name, value: c.name, text: countryLocalization(c.name) }))

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__country')}>
        <DropdownField
          search
          value={country}
          label={formatMessage(messages.Country)}
          name={FIELD_COUNTRY}
          placeholder={formatMessage(messages.Country)}
          options={options}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderTags = () => {
    const { tags } = this.props
    const { formatMessage } = this.props.intl
    const { selectedTags } = this.state
    const error = this.checkError(FIELD_TAGS)

    const options = tags.map((t) => ({ key: t.id, value: t.id, text: t.tag }))

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__tags')}>
        <DropdownField
          search
          multiple
          value={selectedTags}
          label={formatMessage(messages.MarketingTags)}
          placeholder={formatMessage(messages.MarketingTags)}
          name={FIELD_TAGS}
          options={options}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderCategory = () => {
    const { categories } = this.props
    const { formatMessage } = this.props.intl
    const { category } = this.state
    const error = this.checkError(FIELD_CATEGORY)

    const options = categories.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__category')}>
        <DropdownField
          search
          value={category}
          label={formatMessage(messages.Category)}
          name={FIELD_CATEGORY}
          placeholder={formatMessage(messages.Category)}
          options={options}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderSubcategory = () => {
    const { categories } = this.props
    const { formatMessage } = this.props.intl
    const { subcategory, category } = this.state
    const error = this.checkError(FIELD_SUBCATEGORY)

    const selectedCategory = categories.find((c) => c.id === category)

    if (!selectedCategory) {
      return
    }

    const options = selectedCategory.subcategories.map((c) => ({ key: c.id, value: c.id, text: c.name }))
    const sortedSubcategories = sortBy(options, (sub) => sub.text.toLowerCase())

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__subcategory')}>
        <DropdownField
          search
          value={subcategory}
          label={formatMessage(messages.Subcategory)}
          name={FIELD_SUBCATEGORY}
          placeholder={formatMessage(messages.Subcategory)}
          options={sortedSubcategories}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderStockCategory = () => {
    const { marketCategories } = this.props
    const { formatMessage } = this.props.intl
    const { stockCategory } = this.state
    const error = this.checkError(FIELD_STOCK_CATEGORY)

    const options = marketCategories.map((c) => ({ key: c.id, value: c.id, text: c.name }))
    options.unshift({ key: '0', value: '', text: '' })

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__stock-category')}>
        <DropdownField
          search
          value={stockCategory}
          label={formatMessage(messages.Category)}
          name={FIELD_STOCK_CATEGORY}
          placeholder={formatMessage(messages.Category)}
          options={options}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderStockSubcategory = () => {
    const { marketCategories } = this.props
    const { formatMessage } = this.props.intl
    const { stockSubcategory, stockCategory } = this.state
    const error = this.checkError(FIELD_STOCK_SUBCATEGORY)

    const selectedCategory = marketCategories.find((c) => c.id === stockCategory)

    if (!selectedCategory) {
      return
    }

    const options = selectedCategory.subcategories.map((c) => ({ key: c.id, value: c.id, text: c.name }))
    options.unshift({ key: '0', value: '', text: '' })

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__stock-subcategory')}>
        <DropdownField
          search
          value={stockSubcategory}
          label={formatMessage(messages.Subcategory)}
          name={FIELD_STOCK_SUBCATEGORY}
          placeholder={formatMessage(messages.Subcategory)}
          options={options}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderVendorCode = () => {
    const { formatMessage } = this.props.intl
    const { vendorCode } = this.state
    const error = this.checkError(FIELD_VENDOR_CODE)

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__vendor-code')}>
        <InputField
          value={vendorCode}
          maxLength={MAX_VENDOR_CODE}
          label={formatMessage(messages.VendorCode)}
          name={FIELD_VENDOR_CODE}
          placeholder={formatMessage(messages.VendorCode)}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderRating = () => {
    const { formatMessage } = this.props.intl
    const { rating } = this.state
    const error = this.checkError(FIELD_RATING)

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__rating')}>
        <InputField
          value={rating !== undefined ? `${rating}` : ''}
          label={formatMessage(messages.Rating)}
          name={FIELD_RATING}
          placeholder={formatMessage(messages.Rating)}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderUnit = () => {
    const { units } = this.props
    const { formatMessage } = this.props.intl
    const { unit } = this.state
    const error = this.checkError(FIELD_UNIT)

    const options = units.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__unit')}>
        <DropdownField
          search
          value={unit}
          label={formatMessage(messages.Unit)}
          name={FIELD_UNIT}
          placeholder={formatMessage(messages.Unit)}
          options={options}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderWeightPcsGr = () => {
    const { formatMessage } = this.props.intl
    const { weightPcsGr } = this.state
    const error = this.checkError(FIELD_WEIGHT_PCS_GR)

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__weight')}>
        <InputField
          value={weightPcsGr}
          label={formatMessage(messages.WeightOnePieceGrams)}
          name={FIELD_WEIGHT_PCS_GR}
          placeholder={formatMessage(messages.WeightOnePieceGrams)}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderPricePerWeight = () => {
    const { formatMessage } = this.props.intl
    const { pricePerWeight } = this.state
    const error = this.checkError(FIELD_PRICE_PER_WEIGHT)
    const icon = <div className='modal-product__icon-kg'>{this.currentUnit}</div>

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__weight')}>
        <InputField
          value={pricePerWeight}
          label={formatMessage(messages.SellBy, { unit: this.currentUnit })}
          name={FIELD_PRICE_PER_WEIGHT}
          placeholder={formatMessage(messages.SellBy, { unit: this.currentUnit })}
          onChange={this.handleChange}
          error={error ? error.message : ''}
          icon={icon}
        />
      </div>
    )
  }

  renderStep = () => {
    const { steps } = this.props
    const { formatMessage } = this.props.intl
    const { step } = this.state
    const error = this.checkError(FIELD_STEP)

    const options = steps.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className={classnames('modal-product__form-field', 'modal-product__step')}>
        <DropdownField
          search
          value={step}
          label={formatMessage(messages.PurchaseStep)}
          name={FIELD_STEP}
          placeholder={formatMessage(messages.PurchaseStep)}
          options={options}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderPrice = () => {
    const { seller, product, marketProduct, market } = this.props
    const { formatMessage } = this.props.intl
    const { price, updatedPrice, unit } = this.state
    const error = this.checkError(FIELD_PRICE)
    const isCommon = seller ? seller.isCommon : marketProduct ? marketProduct.sellerIsCommon : false
    const commission = seller ? seller.commission : marketProduct ? marketProduct.sellerCommission : DEFAULT_COMMISSION
    const showSource = (isCommon && commission > 0 && price) || true
    const currentUnit = unitIntl(unit).toLowerCase()

    return (
      <div className={classnames('modal-product__form-field')}>
        <div className='modal-product__price'>
          <InputField
            icon={<div className='modal-product__price-icon'>{market?.currency?.symbol_native}</div>}
            value={price}
            label={formatMessage(messages.DiscountPriceFor, { unit: currentUnit })}
            name={FIELD_PRICE}
            placeholder={formatMessage(messages.DiscountPriceFor, { unit: currentUnit })}
            onChange={this.handleChange}
            error={error ? error.message : ''}
          />
        </div>
        {showSource ? (
          <div className='modal-product__source-price'>
            {updatedPrice || (product && +product.price === price) ? (
              <div className='modal-product__source-price__text'>
                {formatMessage(messages.OriginalPrice)}: {formatPrice(sourcePrice(price, commission), market?.currency)}
              </div>
            ) : (
              !updatedPrice && (
                <div className='modal-product__source-price__btn' onClick={this.updatePrice}>
                  {formatMessage(messages.UpdatePriceWithCommission, { commission })}
                </div>
              )
            )}
          </div>
        ) : (
          <div className='modal-product__source-price' />
        )}
      </div>
    )
  }

  renderOldPrice = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const { oldPrice, unit } = this.state
    const error = this.checkError(FIELD_OLD_PRICE)
    const currentUnit = unitIntl(unit).toLowerCase()

    return (
      <div className='modal-product__price'>
        <InputField
          icon={<div className='modal-product__price-icon'>{market?.currency?.symbol_native}</div>}
          value={oldPrice}
          label={formatMessage(messages.PriceWithoutDiscountFor, { unit: currentUnit })}
          name={FIELD_OLD_PRICE}
          placeholder={formatMessage(messages.PriceWithoutDiscountFor, { unit: currentUnit })}
          onChange={this.handleChange}
          error={error ? error.message : ''}
        />
      </div>
    )
  }

  renderImage = () => {
    const { imageUrl, product } = this.props
    const { formatMessage } = this.props.intl
    const { imgLoading } = this.state
    const img = imageUrl || product?.imageUrl
    const error = this.checkError(FIELD_IMAGE)

    return (
      <div className='modal-product__image'>
        <input
          ref={this.imageRef}
          type='file'
          onChange={this.handleChangeImage}
          accept='image/x-png,image/jpeg'
          style={{ display: 'none' }}
        />
        <div
          className={classnames('modal-product__image-container', !img && 'modal-product__image-empty')}
          onClick={this.uploadImage}
        >
          {!!img && (
            <img
              alt='product'
              className='modal-product__image-img'
              src={img ? img : defaultImg}
              onLoad={() => this.setState({ imgLoading: false })}
              onError={() => this.setState({ imgLoading: false })}
            />
          )}
          {imgLoading && (
            <div className='modal-product__image-placeholder'>
              <Loader active />
            </div>
          )}
          {!img && <PictureIcon />}
        </div>
        {img && (
          <div className='modal-product__image__actions'>
            <div className='modal-product__image__actions-btn' onClick={() => this.editImage(img)}>
              <CropIcon color='#a0a3b5' />
            </div>
            <div className='modal-product__image__actions-btn' onClick={this.uploadImage}>
              <UploadIcon color='#a0a3b5' />
            </div>
          </div>
        )}
        {product && (
          <Popup
            content={formatMessage(messages.CreateCopy)}
            position='bottom center'
            trigger={
              <div className='modal-product__copy-btn' onClick={this.copyProduct}>
                <Icon name='copy outline' size='large' />
              </div>
            }
          />
        )}
        {error && <div className='modal-product__field-error'>{error.message}</div>}
      </div>
    )
  }

  updatePrice = () => {
    const { seller, marketProduct } = this.props
    const { price } = this.state
    const commission = seller ? seller.commission : marketProduct ? marketProduct.sellerCommission : DEFAULT_COMMISSION
    const newPrice = updatePriceByCommission(price, commission)

    this.setState({ price: newPrice, updatedPrice: true })

    return newPrice
  }

  editImage = (imageUrl: string) => {
    const { formatMessage } = this.props.intl

    this.props.cropImage({
      type: EModalType.MODAL_IMAGE_CROPPER,
      props: {
        title: formatMessage(messages.ImageEditing),
        imageUrl,
        aspectRatio: 4 / 3,
        uploadImage: (file: File) => this.props.uploadImage(file, EImageType.PRODUCT_IMAGE),
      },
    })
  }

  uploadImage = () => {
    this.imageRef.current?.click()
  }

  getCategory = (product: ISellerProduct, categories: ICategory[]) => {
    let category = ''

    if (product && product.subcategory && categories) {
      categories.forEach((c) => {
        c.subcategories.forEach((sc) => {
          if (sc.id === product.subcategory) {
            category = c.id
          }
        })
      })
    }

    return category
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (name === FIELD_NAME && value.length > NAME_LIMIT) {
      return
    }

    if (this.props.errorApi) {
      this.props.dropApiError()
    }

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_NAME:
        this.setState({ name: value, errors: this.removeError(FIELD_NAME) })
        break
      case FIELD_TAGS:
        this.setState({ selectedTags: value, errors: this.removeError(FIELD_NAME) })
        break
      case FIELD_DESCRIPTION:
        this.setState({ description: value, errors: this.removeError(FIELD_DESCRIPTION) })
        break
      case FIELD_COUNTRY:
        this.setState({ country: value, errors: this.removeError(FIELD_COUNTRY) })
        break
      case FIELD_PRICE_PER_WEIGHT:
        this.setState({ pricePerWeight: value, errors: this.removeError(FIELD_PRICE_PER_WEIGHT) })
        break
      case FIELD_CATEGORY:
        this.setState({ category: value, subcategory: '', errors: this.removeError(FIELD_CATEGORY) })
        break
      case FIELD_SUBCATEGORY:
        this.setState({ subcategory: value, errors: this.removeError(FIELD_SUBCATEGORY) })
        break
      case FIELD_STOCK_CATEGORY:
        this.setState({ stockCategory: value, stockSubcategory: '', errors: this.removeError(FIELD_STOCK_CATEGORY) })
        break
      case FIELD_STOCK_SUBCATEGORY:
        this.setState({ stockSubcategory: value, errors: this.removeError(FIELD_STOCK_SUBCATEGORY) })
        break
      case FIELD_VENDOR_CODE:
        this.setState({ vendorCode: value, errors: this.removeError(FIELD_VENDOR_CODE) })
        break
      case FIELD_RATING:
        this.setState({ rating: value, errors: this.removeError(FIELD_RATING) })
        break
      case FIELD_UNIT:
        this.setState({ unit: value, errors: this.removeError(FIELD_UNIT) })
        break
      case FIELD_WEIGHT_PCS_GR:
        this.setState({ weightPcsGr: value, errors: this.removeError(FIELD_WEIGHT_PCS_GR) })
        break
      case FIELD_STEP:
        this.setState({ step: value, errors: this.removeError(FIELD_STEP) })
        break
      case FIELD_PRICE:
        this.setState({ price: value, errors: this.removeError(FIELD_PRICE), updatedPrice: false })
        break
      case FIELD_OLD_PRICE:
        this.setState({ oldPrice: value, errors: this.removeError(FIELD_OLD_PRICE), updatedPrice: false })
        break
      case FIELD_ABOUT_SELLER:
        this.setState({ aboutSeller: value, errors: this.removeError(FIELD_ABOUT_SELLER) })
        break
    }
  }

  handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formatMessage } = this.props.intl
    const { files } = event.target

    if (this.props.errorApi) {
      this.props.dropApiError()
    }

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    if (files && files.length) {
      this.props.cropImage({
        type: EModalType.MODAL_IMAGE_CROPPER,
        props: {
          title: formatMessage(messages.ImageEditing),
          image: files[0],
          aspectRatio: 4 / 3,
          uploadImage: (file: File) => this.props.uploadImage(file, EImageType.PRODUCT_IMAGE),
        },
      })
      this.setState({ errors: this.removeError(FIELD_IMAGE), imgLoading: true })
    }
  }

  createProduct = () => {
    const { seller, imageUrl, product, marketProduct } = this.props
    const { formatMessage } = this.props.intl
    const {
      name,
      description,
      country,
      category,
      subcategory,
      unit,
      weightPcsGr,
      step,
      price,
      aboutSeller,
      selectedTags,
      stockCategory,
      stockSubcategory,
      vendorCode,
      rating,
      oldPrice,
      pricePerWeight,
    } = this.state

    const isCommon = seller ? seller.isCommon : marketProduct ? marketProduct.sellerIsCommon : false
    const img = imageUrl || product?.imageUrl
    const errors: FieldError[] = []

    if (!name) {
      errors.push({ field: FIELD_NAME, message: formatMessage(messages.FieldRequired) })
    }

    if (!country) {
      errors.push({ field: FIELD_COUNTRY, message: formatMessage(messages.FieldRequired) })
    }

    if (!category) {
      errors.push({ field: FIELD_CATEGORY, message: formatMessage(messages.FieldRequired) })
    }

    if (!subcategory) {
      errors.push({ field: FIELD_SUBCATEGORY, message: formatMessage(messages.FieldRequired) })
    }

    if (!unit) {
      errors.push({ field: FIELD_UNIT, message: formatMessage(messages.FieldRequired) })
    }

    if (unit === UNIT_PCS && !(isFinite(+weightPcsGr) && +weightPcsGr > 0)) {
      errors.push({ field: FIELD_WEIGHT_PCS_GR, message: formatMessage(messages.ValueMustGreaterThan, { value: 0 }) })
    }

    if ((unit === UNIT_KG || unit === UNIT_LITRE) && !(+pricePerWeight > 0)) {
      errors.push({
        field: FIELD_PRICE_PER_WEIGHT,
        message: formatMessage(messages.ValueMustGreaterThan, { value: 0 }),
      })
    }

    if (!step) {
      errors.push({ field: FIELD_STEP, message: formatMessage(messages.FieldRequired) })
    }

    if (!price) {
      errors.push({ field: FIELD_PRICE, message: formatMessage(messages.FieldRequired) })
    }

    if (!img) {
      errors.push({ field: FIELD_IMAGE, message: formatMessage(messages.MustAddImage) })
    }

    if (description && description.length > DESCRIPTION_LIMIT) {
      errors.push({
        field: FIELD_DESCRIPTION,
        message: formatMessage(messages.ValueMustNotExceedCharacters, { limit: DESCRIPTION_LIMIT }),
      })
    }

    if (rating && (!isFinite(+rating) || +rating < 0)) {
      errors.push({ field: FIELD_RATING, message: formatMessage(messages.ValueMustNumberGreaterThan, { value: 0 }) })
    }

    const vendorCodeValue = vendorCode.trim()

    if (!errors.length) {
      this.props.onClose()
      let productPrice = price

      if (isCommon && !this.state.updatedPrice) {
        if (product && +product.price !== price) {
          productPrice = this.updatePrice()
        } else if (!product) {
          productPrice = this.updatePrice()
        }
      }

      const newOldPrice = oldPrice === price ? '0' : String(oldPrice)

      if (product) {
        this.props.apiUpdateProduct({
          name,
          country,
          subcategory,
          unit,
          weightPcsGr,
          step,
          tags: selectedTags,
          price: (+productPrice).toFixed(2),
          imageUrl: img,
          productId: product.id,
          ...(seller && { userId: seller.userId }),
          sellerId: product.seller,
          hide: product.hide,
          price_weight_gr: unit === UNIT_PCS ? 1000 : this.weightToGram(pricePerWeight),
          ...(oldPrice !== undefined && { price_without_discount: newOldPrice }),
          ...(description !== undefined && { description: description.trim() }),
          ...(aboutSeller && { aboutSeller }),
          ...(stockCategory !== undefined && { stockCategory }),
          ...(stockSubcategory !== undefined && { stockSubcategory }),
          ...(vendorCodeValue !== undefined && { vendorCode: vendorCodeValue }),
          ...(rating !== undefined && { rating: +rating }),
        })
      } else if (seller) {
        this.props.apiCreateProduct({
          name,
          country,
          subcategory,
          unit,
          weightPcsGr,
          step,
          imageUrl,
          tags: selectedTags,
          price_without_discount: newOldPrice,
          price: (+productPrice).toFixed(2),
          userId: seller.userId,
          hide: true,
          price_weight_gr: unit === UNIT_PCS ? 1000 : this.weightToGram(pricePerWeight),
          ...(description && { description: description.trim() }),
          ...(aboutSeller && { aboutSeller }),
          ...(stockCategory && { stockCategory }),
          ...(stockSubcategory && { stockSubcategory }),
          ...(vendorCodeValue && { vendorCode: vendorCodeValue }),
          ...(rating && { rating: +rating }),
        })
      }
    } else {
      this.setState({ errors })
    }
  }

  weightToGram = (value: string): number => {
    return round(+value * 1000, 2)
  }

  weightToKg = (value?: number): string => {
    if (value === undefined) {
      return '1'
    }

    return round(value / 1000, 2) + ''
  }

  copyProduct = () => {
    const { product } = this.props

    if (product) {
      this.props.apiCopyProduct({ productId: product.id, sellerId: product.seller })
      this.props.onClose()
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { errorApi, loading, countries, categories, units, steps, savedImageUrl, sellersProducts } = s.products
  const { markets } = s.markets
  const commonSellers: string[] = []

  const market = markets.find((market) => market.id === own.marketId)

  if (!market && markets && markets.length > 0) {
    own.onClose()
  }

  if (own.seller?.isCommon || own.marketProduct?.sellerIsCommon) {
    sellersProducts.forEach((item) => {
      if (item.aboutSeller) {
        commonSellers.push(item.aboutSeller)
      }
    })
  }

  return {
    countries,
    steps,
    units,
    loading,
    errorApi,
    market,
    tags: s.tags.tags.sort(sortByName),
    commonSellers: uniq(commonSellers),
    imageUrl: savedImageUrl,
    categories: sortBy(categories, (c) => c.name.toLowerCase()),
    marketCategories: markets.find((market) => market.id === own.marketId)?.categories || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  cropImage: (data: IModalImageCropper) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  apiCreateProduct: (data: ApiProductCreateReq) => dispatch(Actions.action(Actions.API_PRODUCT_CREATE, data)),
  apiUpdateProduct: (data: ApiProductUpdateReq) => dispatch(Actions.action(Actions.API_PRODUCT_UPDATE, data)),
  apiCopyProduct: (data: ApiProductCopyReq) => dispatch(Actions.action(Actions.API_PRODUCT_COPY, data)),
  getCountries: () => dispatch(Actions.actionEmpty(Actions.API_PRODUCT_COUNTRIES)),
  getCategories: () => dispatch(Actions.actionEmpty(Actions.API_PRODUCT_CATEGORIES)),
  getMarketCategories: (data: ApiMarketCategoriesReq) => dispatch(Actions.action(Actions.API_MARKET_CATEGORIES, data)),
  getSteps: () => dispatch(Actions.actionEmpty(Actions.API_PRODUCT_STEPS)),
  getUnits: () => dispatch(Actions.actionEmpty(Actions.API_PRODUCT_UNITS)),
  dropApiError: () => dispatch(Actions.actionEmpty(Actions.DROP_PRODUCT_ERROR)),
  uploadImage: (file: File, type: EImageType) =>
    dispatch(
      Actions.action(Actions.API_UPLOAD_IMAGE, {
        file,
        type,
      }),
    ),
  dropProductSaved: () => dispatch(Actions.actionEmpty(Actions.DROP_PRODUCT_SAVED)),
  getTags: (market_id: string) => dispatch(Actions.action(Actions.API_GET_TAGS, { market_id })),
})

export const ProductModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductCmp))
