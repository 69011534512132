import React from 'react'
import { connect } from 'react-redux'

import './index.scss'

import { ICustomer } from '../../../../../types/TClient'

import { State } from '../../../../../store/reducer'
import {
  CustomerDesc,
  CustomerAddresses,
  CustomerOrders,
  CustomerReviews,
  CustomerDivider,
} from '../../../../../components/CustomerInfo'

type TOwnProps = {
  customer: ICustomer,
}

type TConnectedProps = {
  loading: boolean,
}

type TProps = TOwnProps & TConnectedProps

class CustomerPanelCmp extends React.Component<TProps> {
  render() {
    return (
      <div className='customer'>
        {this.renderDesc()}
        {this.renderAddresses()}
        {this.renderOrders()}
        {this.renderReviews()}
      </div>
    )
  }

  renderDesc() {
    const { customer } = this.props

    return (
      <>
        <CustomerDesc customer={customer} />
        <CustomerDivider />
      </>
    )
  }

  renderAddresses() {
    const { customer } = this.props

    return (
      <>
        <CustomerAddresses customer={customer} />
        <CustomerDivider />
      </>
    )
  }

  renderOrders() {
    const { customer } = this.props

    return (
      <>
        <CustomerOrders customer={customer} />
        <CustomerDivider />
      </>
    )
  }

  renderReviews() {
    const { customer } = this.props

    return <CustomerReviews customer={customer} />
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading } = s.customers

  return {
    loading,
  }
}

export const CustomerPanel = connect(mapStateToProps)(CustomerPanelCmp)
