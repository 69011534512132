import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const LinkIcon: React.FC<TProps> = ({ width = 24, height = 24, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)' fill={color}>
        <path d='M20.663 13.332a7.764 7.764 0 0 0-5.49-2.274 7.73 7.73 0 0 0-5.49 2.274l-7.055 7.06a7.764 7.764 0 0 0 5.49 13.249 7.714 7.714 0 0 0 5.485-2.263l5.823-5.823a.554.554 0 0 0-.394-.948h-.222a9.336 9.336 0 0 1-3.549-.688.555.555 0 0 0-.604.122l-4.187 4.192a3.33 3.33 0 1 1-4.709-4.708l7.082-7.076a3.327 3.327 0 0 1 4.703 0 2.274 2.274 0 0 0 3.117 0c.376-.377.605-.877.643-1.409a2.218 2.218 0 0 0-.643-1.708Z' />
        <path d='M31.36 2.634a7.764 7.764 0 0 0-10.98 0l-5.818 5.811a.555.555 0 0 0-.116.61.554.554 0 0 0 .52.339h.206a9.316 9.316 0 0 1 3.544.693.555.555 0 0 0 .604-.122l4.176-4.17a3.33 3.33 0 0 1 4.708 4.708l-5.202 5.196-.044.05-1.819 1.808a3.327 3.327 0 0 1-4.703 0 2.274 2.274 0 0 0-3.116 0c-.38.38-.608.884-.644 1.42-.04.635.194 1.257.644 1.708a7.696 7.696 0 0 0 2.218 1.552c.116.056.233.1.35.15.116.05.238.089.354.133.117.045.239.083.355.117l.327.088c.222.056.444.1.671.139.274.04.55.067.827.078H18.843l.333-.04c.122-.005.25-.032.394-.032h.188l.383-.056.177-.033.322-.067h.061a7.764 7.764 0 0 0 3.6-2.04l7.059-7.06a7.764 7.764 0 0 0 0-10.98Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill={color} transform='translate(.36 .36)' d='M0 0h33.28v33.28H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
