import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import {
  ApiCreateGroupReq,
  ApiGroupsListReq,
  ApiMarketCategoriesReq,
  ApiMarketProductsReq,
  ApiRemoveGroupReq,
  ApiUpdateGroupReq,
} from '../../../../../types/TApi'
import { EGroupType, IGroupProducts, IMarket, IProduct, IStockCategory } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import { State } from '../../../../../store/reducer'
import * as Actions from '../../../../../store/actions'
import { StockCategory } from '../Categories/Category'
import { ProductCard } from '../../../../../components/ProductCard'
import { Button } from '../../../../../components/Button'
import {
  BackIcon,
  EditIcon,
  LinkIcon,
  PictureIcon,
  TrashIcon,
  VisibleIcon,
  VisibleOffIcon,
} from '../../../../../components/Icons'
import { ModalConfirm } from '../../../../../components/Modal/ModalConfirm'
import { convertProductToProductSeller } from '../../../../../utils/productsUtils'

type TOwnProps = {
  market: IMarket,
}

type TConnectedProps = {
  loadingProducts: boolean,
  loadingCategories: boolean,
  marketCategories: IStockCategory[],
  marketProducts: IProduct[],
  productGroups: IGroupProducts[],
}

type TDispatchedProps = {
  getMarketCategories: (data: ApiMarketCategoriesReq) => Actions.Action,
  apiMarketProducts: (data: ApiMarketProductsReq) => Actions.Action,
  apiMarketGroups: (data: ApiGroupsListReq) => Actions.Action,
  apiCreateGroup: (data: ApiCreateGroupReq) => Actions.Action,
  apiUpdateGroup: (data: ApiUpdateGroupReq) => Actions.Action,
  apiRemoveGroup: (data: ApiRemoveGroupReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

enum ETab {
  GROUP = 1,
  LINKS = 2,
}

type TState = {
  activeTab: ETab | null,
  searchText: string,
  selectCategory: string,
  group: IGroupProducts | null,
  chainedProducts: IGroupProducts[],
  editGroup: boolean,
  editChain: boolean,
  changeRootLink: boolean,
  removeGroupModal: boolean,
}

class _ProductsChain extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      activeTab: null,
      searchText: '',
      selectCategory: '',
      group: null,
      chainedProducts: [],
      editGroup: false,
      editChain: false,
      changeRootLink: false,
      removeGroupModal: false,
    }
  }

  componentDidMount(): void {
    const { market } = this.props

    if (market) {
      this.props.getMarketCategories({ marketId: market.id, hiddenSeller: false })
      this.props.apiMarketGroups({ marketId: market.id })
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>): void {
    const { activeTab, group } = this.state
    const { productGroups } = this.props

    if (activeTab !== prevState.activeTab) {
      if (activeTab === ETab.GROUP) {
        this.props.getMarketCategories({ marketId: this.props.market.id, hiddenSeller: false })
      }
    }

    if (JSON.stringify(productGroups) !== JSON.stringify(prevProps.productGroups) && group) {
      this.setState({ group: productGroups.find((item) => item.id === group.id) || this.state.group })
    }
  }

  render() {
    const { activeTab, editGroup, editChain } = this.state

    return (
      <>
        {this.renderTie()}
        {this.renderRemoveGroupModal()}
        <div className='chain-container'>
          {!editGroup && !editChain && (
            <div className='chain__groups'>
              {this.renderGroup()}
              {this.renderLinks()}
            </div>
          )}
          {activeTab === ETab.GROUP && editGroup && this.renderGroupEdit()}
          {activeTab === ETab.LINKS && editChain && this.renderLinkEdit()}
        </div>
      </>
    )
  }

  renderTie = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const { group, editChain, editGroup } = this.state

    if (
      !market ||
      !group ||
      !group.products.length ||
      (!editGroup && !editChain) ||
      (editChain && group.products.length < 2)
    ) {
      return null
    }

    return (
      <>
        {
          <div className='chain__link-btn' onClick={this.saveGroup}>
            {formatMessage(messages.TieUp)}
          </div>
        }
      </>
    )
  }

  renderEmptyGroup = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className={classnames('chain__empty', 'chain__empty-group')}>
        <div className='chain__empty-title'>{formatMessage(messages.DefaultProductGroup)}</div>
        <div className='chain__empty-text'>{formatMessage(messages.DefaultProductGroupInfo)}</div>
        <Button title={formatMessage(messages.CreateGroup)} onClick={this.addGroup} />
      </div>
    )
  }

  renderGroup = () => {
    const { productGroups } = this.props
    const { formatMessage } = this.props.intl
    const group = productGroups.find((item) => item.type === EGroupType.GROUP)

    if (!group || !group.products.length) {
      return this.renderEmptyGroup()
    }

    return (
      <div className={classnames('chain__group')}>
        <div className='chain__group-title'>{formatMessage(messages.DefaultProductGroup)}</div>
        <div className='chain__group-header'>
          <div className='chain__group-header__label'>
            <div>{formatMessage(messages.Group)}</div>
            <div className='chain__group-header__label-dot' />
            <div>
              {formatMessage(messages.GoodsR)}: {group.products.length}
            </div>
          </div>
          <div className='chain__group-header__actions'>
            <div className='chain__group-header__actions-btn' onClick={() => this.editGroup(group)}>
              <div className='chain__group-header__actions-btn__icon'>
                <EditIcon color='#a0a3b5' />
              </div>
              <div className='chain__group-header__actions-btn__text'>{formatMessage(messages.Change)}</div>
            </div>
            <div
              className='chain__group-header__actions-btn'
              onClick={() => this.setState({ removeGroupModal: true, group })}
            >
              <div className='chain__group-header__actions-btn__icon'>
                <TrashIcon color='#a0a3b5' />
              </div>
              <div className='chain__group-header__actions-btn__text'>{formatMessage(messages.Delete)}</div>
            </div>
          </div>
        </div>
        <div className='chain__group-products'>
          {group.products.map((item) => {
            return item.product ? (
              <ProductCard
                editable={false}
                key={item.productId}
                product={convertProductToProductSeller(item.product)}
                marketProduct={item.product}
              />
            ) : (
              <div />
            )
          })}
        </div>
      </div>
    )
  }

  renderGroupEdit = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const { group } = this.state

    return (
      <div className={classnames('chain__products-container')}>
        <div className='chain__back' onClick={this.dropTab}>
          <BackIcon color={'#858897'} /> {formatMessage(messages.Back)}
        </div>
        <div className='chain__header'>
          <div className='chain__header-title'>{formatMessage(messages.DefaultProductGroup)}</div>
          {!!group?.products.length && (
            <div className='chain__header-reset' onClick={this.dropAllProducts}>
              {formatMessage(messages.ResetAllProducts)}
            </div>
          )}
        </div>
        <div className='chain__content'>{market && this.renderCategories(market)}</div>
      </div>
    )
  }

  renderLinks = () => {
    const { productGroups } = this.props
    const { formatMessage } = this.props.intl
    const groups = productGroups.filter((item) => item.type === EGroupType.CHAIN)

    if (!groups || !groups.length) {
      return this.renderEmptyLinks()
    }

    return (
      <div className={classnames('chain__links')}>
        <div className='chain__links-header'>
          <div className='chain__links-title'>{formatMessage(messages.SpecificProductsLinks)}</div>
          <div className='chain__links-add' onClick={this.addLink}>
            {formatMessage(messages.Add)}
          </div>
        </div>
        {groups.map(this.renderLink)}
      </div>
    )
  }

  renderEmptyLinks = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='chain__empty'>
        <div className='chain__empty-title'>{formatMessage(messages.SpecificProductsLinks)}</div>
        <div className='chain__empty-text'>{formatMessage(messages.SpecificProductsLinksInfo)}</div>
        <Button title={formatMessage(messages.AddLinks)} onClick={this.addLink} />
      </div>
    )
  }

  renderLink = (group: IGroupProducts, index: number) => {
    const { formatMessage } = this.props.intl
    const rootElem = group.products.find((item) => item.root)
    const restElem = group.products.filter((item) => !item.root)

    return (
      <div className={classnames('chain__link')} key={group.id}>
        <div className='chain__group-header'>
          <div className='chain__group-header__label'>
            <div>
              {formatMessage(messages.Link)} №{index + 1}
            </div>
            <div className='chain__group-header__label-dot' />
            <div>
              {formatMessage(messages.GoodsR)}: {group.products.length}
            </div>
          </div>
          {group.disable && (
            <div className='chain__group-header__label-visible'>
              <VisibleOffIcon color='#fff' />
            </div>
          )}
          <div className='chain__group-header__actions'>
            <div className='chain__group-header__actions-btn' onClick={() => this.hideGroup(group)}>
              <div className='chain__group-header__actions-btn__icon'>
                {group.disable ? <VisibleIcon color='#a0a3b5' /> : <VisibleOffIcon color='#a0a3b5' />}
              </div>
              <div className='chain__group-header__actions-btn__text'>
                {formatMessage(group.disable ? messages.HideOff : messages.Hide)}
              </div>
            </div>
            <div className='chain__group-header__actions-btn' onClick={() => this.editLink(group)}>
              <div className='chain__group-header__actions-btn__icon'>
                <EditIcon color='#a0a3b5' />
              </div>
              <div className='chain__group-header__actions-btn__text'>{formatMessage(messages.Change)}</div>
            </div>
            <div
              className='chain__group-header__actions-btn'
              onClick={() => this.setState({ removeGroupModal: true, group })}
            >
              <div className='chain__group-header__actions-btn__icon'>
                <TrashIcon color='#a0a3b5' />
              </div>
              <div className='chain__group-header__actions-btn__text'>{formatMessage(messages.Delete)}</div>
            </div>
          </div>
        </div>
        <div className='chain__group-products' style={{ opacity: group.disable ? 0.5 : 1 }}>
          {rootElem && (
            <>
              {rootElem.product && (
                <ProductCard
                  editable={false}
                  product={convertProductToProductSeller(rootElem.product)}
                  marketProduct={rootElem.product}
                />
              )}
              <div className='chain__group-products__icon'>
                <LinkIcon width={34} height={34} color='#fff' />
              </div>
            </>
          )}
          {restElem.map((item) => {
            return item.product ? (
              <ProductCard
                editable={false}
                key={item.productId}
                product={convertProductToProductSeller(item.product)}
                marketProduct={item.product}
              />
            ) : (
              <div />
            )
          })}
        </div>
      </div>
    )
  }

  renderLinkEdit = () => {
    const { market, marketCategories } = this.props
    const { formatMessage } = this.props.intl
    const { activeTab, group, changeRootLink } = this.state
    const selectedRoot = activeTab === ETab.LINKS && group?.products.find((item) => item.root)
    const selectedCategory =
      selectedRoot && selectedRoot.product
        ? marketCategories.find((item) => item.id === selectedRoot.product?.marketCategoryId)
        : null
    const rootProduct = selectedRoot ? selectedRoot.product : null

    return (
      <div className={classnames('chain__products-container')}>
        <div className='chain__back' onClick={this.dropTab}>
          <BackIcon color={'#858897'} /> {formatMessage(messages.Back)}
        </div>
        <>
          {!selectedRoot || changeRootLink ? (
            <>
              <div className='chain__header'>
                <div className='chain__header-title'>{formatMessage(messages.ItemToTie)}</div>
                {!!group?.products.length && (
                  <div className='chain__header-reset' onClick={this.dropAllProducts}>
                    {formatMessage(messages.ResetAllProducts)}
                  </div>
                )}
              </div>
              {changeRootLink && selectedRoot && rootProduct && (
                <div className='chain__link-to-root__root-product'>
                  <ProductCard
                    editable={false}
                    product={convertProductToProductSeller(rootProduct)}
                    marketProduct={rootProduct}
                    updateGroup={() => this.updateGroup(rootProduct)}
                    labelElem={
                      <div
                        className='product-card__label'
                        style={{ backgroundColor: '#5bc258' }}
                        onClick={() => this.updateGroup(rootProduct)}
                      >
                        {formatMessage(messages.Selected)}
                      </div>
                    }
                  />
                </div>
              )}
              <div className='chain__content'>{market && this.renderCategories(market)}</div>
            </>
          ) : (
            market &&
            selectedRoot && (
              <div className='chain__link-to-root'>
                <div className='chain__link-to-root__root'>
                  <div className='chain__header'>
                    <div className='chain__header-title'>{formatMessage(messages.WhatTie)}</div>
                  </div>
                  {selectedCategory && (
                    <div className='chain__link-to-root__category'>
                      <div className='chain__link-to-root__category-image'>
                        {selectedCategory.imageUrl ? (
                          <div
                            className='chain__link-to-root__category-image'
                            style={{ backgroundImage: `url(${selectedCategory.imageUrl})` }}
                          />
                        ) : (
                          <div
                            className={classnames(
                              'chain__link-to-root__category-image',
                              'chain__link-to-root__category-image__empty',
                            )}
                          >
                            <PictureIcon />
                          </div>
                        )}
                      </div>
                      <div className='chain__link-to-root__category-name'>{selectedCategory.name}</div>
                    </div>
                  )}
                  {<div className='chain__link-to-root__subcategory'>{rootProduct?.marketSubcategory}</div>}
                  {rootProduct && (
                    <div className='chain__link-to-root__root-product'>
                      <ProductCard
                        editable={false}
                        product={convertProductToProductSeller(rootProduct)}
                        marketProduct={rootProduct}
                      />
                    </div>
                  )}
                </div>
                <div className='chain__link-to-root__icon'>
                  <LinkIcon width={34} height={34} color='#fff' />
                </div>
                <div className='chain__link-to-root__products'>
                  <div className='chain__header'>
                    <div className='chain__header-title'>{formatMessage(messages.WhatToConnectWith)}</div>
                    {!!group?.products.length && (
                      <div className='chain__header-reset' onClick={this.dropAllProducts}>
                        {formatMessage(messages.ResetAllProducts)}
                      </div>
                    )}
                  </div>
                  <div className='chain__content'>{this.renderCategories(market)}</div>
                </div>
              </div>
            )
          )}
        </>
      </div>
    )
  }

  renderCategories = (market: IMarket) => {
    const { marketCategories } = this.props

    return (
      <div className='chain__best-products'>
        {marketCategories.map((category, i) =>
          this.renderCategory(market, category, marketCategories.length > 1 && i < marketCategories.length - 1),
        )}
      </div>
    )
  }

  renderCategory = (market: IMarket, category: IStockCategory, divider = false) => {
    const { group, changeRootLink, activeTab } = this.state
    const selectRoot = activeTab === ETab.LINKS && !group?.products.find((item) => item.root)

    return (
      <React.Fragment key={category.id}>
        <StockCategory
          market={market}
          category={category}
          groupProducts={group || undefined}
          updateGroup={this.updateGroup}
          byProduct
          byGroup
          selectRoot={selectRoot || changeRootLink}
        />
        {divider && <div className='chain__best-products__divider' />}
      </React.Fragment>
    )
  }

  renderRemoveGroupModal = () => {
    const { formatMessage } = this.props.intl
    const { removeGroupModal, group } = this.state

    if (!group) {
      return
    }

    return (
      <ModalConfirm
        alert
        onClose={() => this.setState({ removeGroupModal: false })}
        onOk={() => this.deleteGroup(group)}
        open={removeGroupModal}
        title={formatMessage(group.type === EGroupType.GROUP ? messages.RemovingGroup : messages.RemovingLink)}
        text={formatMessage(messages.RemovingGroupWarning)}
      />
    )
  }

  addGroup = () => {
    const group = {
      id: Date.now().toString(),
      type: EGroupType.GROUP,
      products: [],
    }
    this.setState({ activeTab: ETab.GROUP, group, editGroup: true })

    return
  }

  addLink = () => {
    const group = {
      id: Date.now().toString(),
      type: EGroupType.CHAIN,
      products: [],
    }
    this.setState({ activeTab: ETab.LINKS, group, editChain: true })

    return
  }

  dropTab = () => {
    this.setState({ activeTab: null, editGroup: false, editChain: false, changeRootLink: false })
  }

  updateGroup = (product: IProduct) => {
    const { group, activeTab, changeRootLink } = this.state
    const selectRoot = activeTab === ETab.LINKS && !group?.products.find((item) => item.root)

    if (group) {
      let newGroupProducts = [...group.products]
      const existIndex = newGroupProducts.findIndex((item) => item.productId === product.id)

      if (existIndex !== -1 && !changeRootLink) {
        newGroupProducts.splice(existIndex, 1)
        newGroupProducts = newGroupProducts.map((item, index) => ({
          ...item,
          index: index + (activeTab === ETab.LINKS ? 0 : 1),
        }))
      }

      if (changeRootLink) {
        const rootIndex = newGroupProducts.findIndex((item) => item.root)

        if (rootIndex >= 0) {
          newGroupProducts[rootIndex] = {
            id: Date.now().toString(),
            productId: product.id,
            index: 0,
            groupId: group.id,
            root: true,
            product,
          }
        }
      } else if (existIndex === -1) {
        newGroupProducts.push({
          id: Date.now().toString(),
          productId: product.id,
          index: newGroupProducts.length + (activeTab === ETab.LINKS ? 0 : 1),
          groupId: group.id,
          product,
          ...(selectRoot && { root: true }),
        })
      }

      this.setState({ group: { ...group, products: newGroupProducts }, changeRootLink: false })
    }
  }

  hideGroup = (group: IGroupProducts) => {
    this.props.apiUpdateGroup({
      id: group.id,
      disable: !group.disable,
    } as any)
  }

  editGroup = (group: IGroupProducts) => {
    this.setState({ editGroup: true, activeTab: ETab.GROUP, group })
  }

  editLink = (group: IGroupProducts) => {
    this.setState({
      editChain: true,
      changeRootLink: true,
      activeTab: ETab.LINKS,
      group,
    })
  }

  deleteGroup = (group: IGroupProducts) => {
    this.setState({ group: null, removeGroupModal: false })

    this.props.apiRemoveGroup({ id: group.id })
  }

  saveGroup = () => {
    const { market, productGroups } = this.props
    const { group } = this.state

    if (group) {
      this.setState({ editGroup: false, editChain: false })
      const exist = productGroups.find((item) => item.id === group.id)

      if (exist) {
        this.props.apiUpdateGroup({
          id: group.id,
          products: group.products.map((item) => {
            const existProduct = exist.products.find((product) => product.id === item.id)

            return {
              ...(existProduct && { id: item.id }),
              productId: item.productId,
              groupId: item.groupId,
              marketId: market.id,
              index: item.index,
              root: item.root,
            }
          }),
        } as any)
      } else {
        this.props.apiCreateGroup({
          marketId: market.id,
          key: group.type.toString(),
          products: group.products.map((item) => {
            return {
              productId: item.productId,
              marketId: market.id,
              index: item.index,
              root: item.root,
            }
          }),
        } as any)
      }
    }
  }

  dropAllProducts = () => {
    const { group } = this.state

    if (group) {
      this.setState({
        group: {
          ...group,
          products: [],
        },
      })
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { loadingCategories, markets } = s.markets
  const { loadingMarketProducts, marketProducts, groupProducts } = s.products

  return {
    loadingCategories,
    loadingProducts: loadingMarketProducts,
    marketCategories: markets.find((market) => market.id === own.market.id)?.categories || [],
    marketProducts: marketProducts || [],
    productGroups: groupProducts,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getMarketCategories: (data: ApiMarketCategoriesReq) => dispatch(Actions.action(Actions.API_MARKET_CATEGORIES, data)),
  apiMarketProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
  apiMarketGroups: (data: ApiGroupsListReq) => dispatch(Actions.action(Actions.API_GROUPS_LIST, data)),
  apiCreateGroup: (data: ApiCreateGroupReq) => dispatch(Actions.action(Actions.API_CREATE_GROUP, data)),
  apiUpdateGroup: (data: ApiUpdateGroupReq) => dispatch(Actions.action(Actions.API_UPDATE_GROUP, data)),
  apiRemoveGroup: (data: ApiRemoveGroupReq) => dispatch(Actions.action(Actions.API_REMOVE_GROUP, data)),
})

export const ProductsChain = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ProductsChain))
