import React from 'react'

type TProps = {
  color?: string,
}

export const CropIcon: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M5.143 0v17.143a1.714 1.714 0 0 0 1.714 1.714H24'
      />
      <path
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M0 5.143h17.143a1.714 1.714 0 0 1 1.714 1.714V24'
      />
    </svg>
  )
}
