import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Modal } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import messages from '../../../localization/messages'

type TProps = {
  onClose(): void,
  onOk?(): void,
  open: boolean,
  title: string,
  text?: string,
  titleBtn?: string,
  alert?: boolean,
} & WrappedComponentProps

const _ModalConfirm: React.FC<TProps> = ({ open, title, text, titleBtn, alert, onClose, onOk, intl }) => {
  const { formatMessage } = intl

  return (
    <Modal closeIcon onClose={onClose} open={open} size='tiny' style={{ borderRadius: '16px' }}>
      <div className='modal-confirm'>
        <div className='modal-confirm__content'>
          {title && <div className='modal-confirm__title'>{title}</div>}
          {text && <div className='modal-confirm__text'>{text}</div>}
        </div>
        <div className='modal-confirm__actions'>
          <div
            className={classnames('modal-confirm__btn', 'modal-confirm__btn-outline')}
            onClick={(event) => {
              event.stopPropagation()
              onClose()
            }}
          >
            {formatMessage(messages.Cancel)}
          </div>
          {onOk && (
            <div
              className={classnames('modal-confirm__btn', alert && 'modal-confirm__btn-alert')}
              onClick={(event) => {
                event.stopPropagation()
                onOk()
              }}
            >
              {titleBtn || (alert ? formatMessage(messages.Delete) : formatMessage(messages.Confirm))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export const ModalConfirm = injectIntl(_ModalConfirm)
