import {
  ApiSellerInfoData,
  ESellerTypeApi,
  IMarketApi,
  IPartnerIPApi,
  IPartnerOOOApi,
  IBankInfoApi,
  ISellerInfoApi,
  ISellerApi,
  ISellerSearchApi,
} from '../types/TApi'
import { ESellerType, IPartner, ISellerInfo, IMarketInfo, ISeller, ISellerSearch } from '../types/TClient'

export const convertPartnerFromApi = (partner: IPartnerOOOApi | IPartnerIPApi): IPartner => {
  switch (partner.data.opf.short) {
    case ESellerTypeApi.IP: {
      const partnerIp = partner as IPartnerIPApi

      return {
        type: ESellerType.IP,
        value: partnerIp.value,
        name: partnerIp.data.name.full,
        registrationDate: new Date(partnerIp.data.state.registration_date),
        inn: partnerIp.data.inn,
        ogrn: partnerIp.data.ogrn,
        okpo: partnerIp.data.okpo,
        okato: partnerIp.data.okato,
        okogu: partnerIp.data.okogu,
        okfs: partnerIp.data.okfs,
        okved: partnerIp.data.okved,
        address: partnerIp.data.address.value,
        postalCode: partnerIp.data.address.data.postal_code,
        country: partnerIp.data.address.data.country,
        countryIsoCode: partnerIp.data.address.data.country_iso_code,
        timezone: partnerIp.data.address.data.timezone,
        sourceAddress: partnerIp.data.address.data.source,
      }
    }
    case ESellerTypeApi.OOO: {
      const partnerOOO = partner as IPartnerOOOApi

      return {
        type: ESellerType.OOO,
        value: partnerOOO.value,
        name: partnerOOO.data.name.short_with_opf,
        kpp: partnerOOO.data.kpp,
        registrationDate: new Date(partnerOOO.data.state.registration_date),
        inn: partnerOOO.data.inn,
        ogrn: partnerOOO.data.ogrn,
        okpo: partnerOOO.data.okpo,
        okato: partnerOOO.data.okato,
        oktmo: partnerOOO.data.oktmo,
        okogu: partnerOOO.data.okogu,
        okfs: partnerOOO.data.okfs,
        okved: partnerOOO.data.okved,
        address: partnerOOO.data.address.value,
        postalCode: partnerOOO.data.address.data.postal_code,
        country: partnerOOO.data.address.data.country,
        countryIsoCode: partnerOOO.data.address.data.country_iso_code,
        timezone: partnerOOO.data.address.data.timezone,
        sourceAddress: partnerOOO.data.address.data.source,
      }
    }
  }
}

export const convertBankFromApi = (bankInfo: IBankInfoApi): Pick<ISellerInfo, 'bik' | 'bankName' | 'corAcc'> => {
  return {
    bik: bankInfo.data.bic,
    bankName: bankInfo.value,
    corAcc: bankInfo.data.correspondent_account,
  }
}

export const convertSellerInfoFromApi = (sellerInfo: ISellerInfoApi): ISellerInfo => {
  return {
    id: sellerInfo.id,
    ...(sellerInfo.createdAt && { createdAt: sellerInfo.createdAt }),
    createdUserDb: sellerInfo.createdUserDb,
    createdProfileDb: sellerInfo.createdProfileDb,
    phone: sellerInfo.phone,
    password: sellerInfo.password,
    isSystem: sellerInfo.isSystem,
    email: sellerInfo.email,
    city: sellerInfo.city,
    market: sellerInfo.market,
    location: sellerInfo.location,
    inn: sellerInfo.inn,
    ...(sellerInfo.type && { type: sellerInfo.type === ESellerTypeApi.IP ? ESellerType.IP : ESellerType.OOO }),
    fio: sellerInfo.fio,
    name: sellerInfo.organizationName,
    ogrnip: sellerInfo.ogrnip,
    ogrn: sellerInfo.ogrn,
    address: sellerInfo.address,
    registrationDate: sellerInfo.registrationDate,
    bik: sellerInfo.bik,
    bankName: sellerInfo.bankName,
    corAcc: sellerInfo.corAcc,
    checkingAcc: sellerInfo.checkingAcc,
    certificateUrl: sellerInfo.certificateUrl,
    passportUrl: sellerInfo.passportUrl,
    bankAccDocUrl: sellerInfo.bankAccDocUrl,
    legalRegistrationCertificateUrl: sellerInfo.legalRegistrationCertificateUrl,
    legalEntitiesStateRegisterUrl: sellerInfo.legalEntitiesStateRegisterUrl,
    decisionAppointmentUrl: sellerInfo.decisionAppointmentUrl,
    orderAppointmentUrl: sellerInfo.orderAppointmentUrl,
    rentContractUrl: sellerInfo.rentContractUrl,
    isHide: sellerInfo.isHide,
  }
}

export const convertMarketFromApi = (market: IMarketApi): IMarketInfo => {
  return {
    id: market.id,
    name: market.name,
    address: market.address,
    openingHours: market.openingHours,
    closingHours: market.closingHours,
  }
}

export const convertSellerFromApi = (seller: ISellerApi): ISeller => {
  return {
    id: seller.id,
    userId: seller.userId,
    courierId: seller.courierId,
    isSystem: seller.isSystem,
    isCommon: seller.isCommon,
    commission: seller.commission,
    isHide: seller.isHide,
    name: seller.name,
    nickname: seller.nickname,
    organization: seller.organization,
    location: seller.location,
    imageUrl: seller.imageUrl,
    avatarUrl: seller.avatarUrl,
    categories: seller.categories || [],
    ...(seller.aboutSellers && { aboutSellers: seller.aboutSellers }),
    cityId: seller.cityId,
    cityName: seller.cityName,
    marketId: seller.marketId,
    marketAddress: seller.marketAddress,
    marketName: seller.marketName,
    ...(seller.marketOpeningHours && { marketOpeningHours: seller.marketOpeningHours }),
    ...(seller.marketClosingHours && { marketClosingHours: seller.marketClosingHours }),
  }
}

export const convertSellerSearchFromApi = (seller: ISellerSearchApi): ISellerSearch => {
  return {
    id: seller.id,
    isSystem: seller.isSystem,
    isCommon: seller.isCommon,
    isHide: seller.isHide,
    name: seller.name,
    nickname: seller.nickname,
    organization: seller.organization,
    location: seller.location,
    marketId: seller.marketId,
    marketName: seller.marketName,
  }
}

export const convertSellerToApi = (sellerInfo: Omit<ISellerInfo, 'id'>): ApiSellerInfoData => {
  const {
    phone,
    password,
    isSystem,
    email,
    city,
    market,
    location,
    inn,
    type,
    fio,
    name,
    ogrnip,
    ogrn,
    address,
    registrationDate,
    bik,
    bankName,
    corAcc,
    checkingAcc,
    certificateUrl,
    passportUrl,
    bankAccDocUrl,
    legalRegistrationCertificateUrl,
    legalEntitiesStateRegisterUrl,
    decisionAppointmentUrl,
    orderAppointmentUrl,
    rentContractUrl,
    isHide,
  } = sellerInfo

  return {
    phone,
    password,
    isSystem,
    isHide,
    ...(email && { email }),
    ...(city && { city }),
    ...(market && { market }),
    ...(location && { location }),
    ...(inn && { inn }),
    ...(type && { type: type === ESellerType.IP ? ESellerTypeApi.IP : ESellerTypeApi.OOO }),
    ...(fio && type === ESellerType.IP && { fio }),
    ...(name && type === ESellerType.OOO && { organizationName: name }),
    ...(ogrnip && type === ESellerType.IP && { ogrnip }),
    ...(ogrn && type === ESellerType.OOO && { ogrn }),
    ...(address && { address }),
    ...(registrationDate && { registrationDate }),
    ...(bik && { bik }),
    ...(bankName && { bankName }),
    ...(corAcc && { corAcc }),
    ...(checkingAcc && { checkingAcc }),
    ...(certificateUrl && { certificateUrl }),
    ...(passportUrl && { passportUrl }),
    ...(bankAccDocUrl && { bankAccDocUrl }),
    ...(legalRegistrationCertificateUrl && { legalRegistrationCertificateUrl }),
    ...(legalEntitiesStateRegisterUrl && { legalEntitiesStateRegisterUrl }),
    ...(decisionAppointmentUrl && { decisionAppointmentUrl }),
    ...(orderAppointmentUrl && { orderAppointmentUrl }),
    ...(rentContractUrl && { rentContractUrl }),
  }
}

export const sortSellerBy = (seller1: ISeller, seller2: ISeller) => {
  const byName = seller1.name.localeCompare(seller2.name, 'ru')

  if (seller1.isHide && !seller2.isHide) {
    return 1
  } else if (seller1.isHide === seller2.isHide) {
    if (seller1.isSystem === seller2.isSystem) {
      if (seller1.isCommon && !seller2.isCommon) {
        return 1
      } else if (seller1.isCommon === seller2.isCommon) {
        return byName
      }
    }

    if (
      (seller1.isSystem && !seller2.isSystem && !seller2.isCommon) ||
      (seller1.isCommon && !seller1.isSystem && seller2.isSystem)
    ) {
      return 1
    }
  }

  return -1
}
