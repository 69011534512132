import { of as observableOf, EMPTY } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { isArray } from 'lodash'

import * as TApi from '../../types/TApi'
import { ApiOrderHistoryResp, IClientSlotsResp } from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, guardMergeMap, ofType } from './epicHelpers'
import {
  URL_DELIVERY_INFO,
  URL_ORDER_ADD_PRODUCTS,
  URL_ORDER_ASSIGN,
  URL_ORDER_CHANGE_PRODUCT,
  URL_ORDER_CHANGE_STATUS,
  URL_ORDER_HISTORY,
  URL_ORDER_UPDATE,
  URL_ORDERS_LIST,
  URL_ORDERS_MAP_LIST,
} from '../../modules/network/urls'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import { convertOrderFromApi, convertOrderMapFromApi, ORDERS_LIMIT, ORDERS_MAP_LIMIT } from '../../utils/ordersUtils'
import { action, actionEmpty } from '../actions'

const ordersListEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiOrdersList>(a$, Actions.API_ORDERS_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_ORDERS_LIST, {
          ...authRequestConfig(),
          params: {
            offset: c.data.offset || 0,
            ...(!c.data.byCouriers && { limit: ORDERS_LIMIT }),
            ...(c.data.search && { search: c.data.search }),
            ...(c.data.ids && { ids: c.data.ids.join(',') }),
            ...(c.data.city && { city: c.data.city }),
            ...(c.data.market && { market: c.data.market }),
            ...(c.data.statuses && c.data.statuses.length > 0 && { statuses: c.data.statuses.join(',') }),
            ...(c.data.dateFrom && { dateFrom: c.data.dateFrom }),
            ...(c.data.dateTo && { dateTo: c.data.dateTo }),
            ...(c.data.dateDeliveryFrom && { dateDeliveryFrom: c.data.dateDeliveryFrom }),
            ...(c.data.dateDeliveryTo && { dateDeliveryTo: c.data.dateDeliveryTo }),
            ...(c.data.asc && { asc: c.data.asc }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
            ...(c.data.byCouriers !== undefined && { byCouriers: c.data.byCouriers }),
            ...(c.data.seller !== undefined && { sellerId: c.data.seller }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiOrdersListResp }) => {
            if (resp.data) {
              const { orders, total } = resp.data

              if (Array.isArray(orders)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.ORDERS_LIST, {
                    orders: orders.map(convertOrderFromApi),
                    ...(total && { total }),
                    ...(c.data.force && { force: c.data.force }),
                    ...(c.data.offset && { offset: c.data.offset }),
                  }),
                )
              }
            }

            return observableOf<Actions.Action>(Actions.action(Actions.ORDERS_LIST, { orders: [] }))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.ORDERS_LIST, { orders: [] }))
          }),
        ),
      ),
    ),
  )

const ordersMapListEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiOrdersMapList>(a$, Actions.API_ORDERS_MAP_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_ORDERS_MAP_LIST, {
          ...authRequestConfig(),
          params: {
            offset: c.data.offset || 0,
            limit: ORDERS_MAP_LIMIT,
            ...(c.data.market && { market: c.data.market }),
            ...(c.data.dateDeliveryFrom && { dateDeliveryFrom: c.data.dateDeliveryFrom }),
            ...(c.data.dateDeliveryTo && { dateDeliveryTo: c.data.dateDeliveryTo }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiOrdersMapListResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data) {
              const { orders } = resp.data

              if (Array.isArray(orders)) {
                actions.push(
                  Actions.action(Actions.ORDERS_MAP_LIST, {
                    orders: orders.map(convertOrderMapFromApi),
                    ...(c.data.force && { force: c.data.force }),
                    ...(c.data.offset && { offset: c.data.offset }),
                  }),
                )

                if (orders.length === ORDERS_MAP_LIMIT) {
                  actions.push(
                    Actions.action(Actions.API_ORDERS_MAP_LIST, {
                      ...c.data,
                      force: false,
                      offset: (c.data.offset || 0) + ORDERS_MAP_LIMIT,
                    }),
                  )
                }
              }
            }

            if (!actions.length) {
              actions.push(Actions.action(Actions.ORDERS_MAP_LIST, { orders: [] }))
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.ORDERS_MAP_LIST, { orders: [] }))
          }),
        ),
      ),
    ),
  )

const changeOrderStatus: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.OrderChangeStatus>(a$, Actions.ORDER_CHANGE_STATUS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(
          URL_ORDER_CHANGE_STATUS,
          {
            id: c.data.orderId,
            status: c.data.status,
          },
          {
            ...authRequestConfig(),
          },
        ).pipe(
          mergeMap(() => {
            const actions: Actions.Action[] = [
              Actions.actionEmpty(Actions.ORDER_CHANGE_STATUS_SUCCESS),
              Actions.action(Actions.API_ORDERS_LIST, { ids: [c.data.orderId] }),
            ]

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const changeOrderProduct: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.OrderChangeProduct>(a$, Actions.ORDER_CHANGE_PRODUCT), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(
          URL_ORDER_CHANGE_PRODUCT,
          {
            order_id: c.data.orderId,
            item_id: c.data.itemId,
            quantity: c.data.quantity,
          },
          {
            ...authRequestConfig(),
          },
        ).pipe(
          mergeMap((resp) => {
            if (resp.data) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.ORDER_CHANGE_PRODUCT_SUCCESS, convertOrderFromApi(resp.data)),
              )
            }
            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateOrder: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateOrder>(a$, Actions.API_UPDATE_ORDER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_ORDER_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp) => {
            const {
              id,
              message,
              message_hide,
              courier,
              payment_type,
              delivery_price,
              delivery_date,
              delivery_time,
              delivery_end_time,
              self_pickup,
              delivery_address,
              delivery_area,
            } = resp.data

            return observableOf<Actions.Action>(
              Actions.action(Actions.UPDATE_ORDER, {
                id,
                deliveryDate: delivery_date ? moment(delivery_date).toDate() : undefined,
                ...(payment_type && { paymentType: payment_type }),
                ...(message !== undefined && { comment: message }),
                ...(message_hide !== undefined && { commentHide: message_hide }),
                ...(courier && { courierId: courier }),
                ...(delivery_price !== undefined && { deliveryPrice: delivery_price }),
                ...(delivery_time !== undefined && { deliveryTime: delivery_time }),
                ...(delivery_end_time !== undefined && { deliveryEndTime: delivery_end_time }),
                ...(self_pickup !== undefined && { selfPickup: self_pickup }),
                ...(delivery_address !== undefined && { deliveryAddress: delivery_address }),
                ...(delivery_area !== undefined && { deliveryArea: delivery_area }),
                ...(c.data.delivery_type !== undefined && { deliveryType: c.data.delivery_type }),
              }),
            )
          }),
          catchError(() => {
            return observableOf<Actions.Action>(action(Actions.UPDATE_ORDER_ERROR, true))
          }),
        ),
      ),
    ),
  )

const assignOrderEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiOrderAssign>(a$, Actions.API_ORDER_ASSIGN), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(
          URL_ORDER_ASSIGN,
          {
            orderId: c.data.orderId,
            courierId: c.data.courierId,
          },
          {
            ...authRequestConfig(),
          },
        ).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(action(Actions.API_ORDERS_LIST, { ids: [c.data.orderId] }))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const addProductsOrderEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiAddProductsOrder>(a$, Actions.API_ADD_PRODUCTS_ORDER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_ORDER_ADD_PRODUCTS, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            const actions: Actions.Action[] = [
              action(Actions.API_ORDERS_LIST, { ids: [c.data.id] }),
              actionEmpty(Actions.ORDER_DROP_PRE_ADD_PRODUCTS),
              action(Actions.MODAL_POP, {}),
            ]

            return observableOf<Actions.Action>(...actions)
          }),
          catchError(() => {
            return observableOf<Actions.Action>(action(Actions.UPDATE_ORDER_ERROR, true))
          }),
        ),
      ),
    ),
  )

const deliveryInfoEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.AppGetSlots>(a$, Actions.APP_GET_SLOTS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_DELIVERY_INFO, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: AxiosResponse<IClientSlotsResp>) => {
            if (resp.data.status) {
              return EMPTY
            }

            return observableOf<Actions.Action>(action(Actions.APP_GET_SLOTS_SUCCESS, resp.data))
          }),
          catchError(() => observableOf<Actions.Action>(actionEmpty(Actions.APP_GET_SLOTS_ERROR))),
        ),
      ),
    ),
  )

const orderHistoryEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiOrderHistory>(a$, Actions.API_ORDER_HISTORY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get<ApiOrderHistoryResp>(URL_ORDER_HISTORY, {
          ...authRequestConfig(),
          params: {
            orderId: c.data.orderId,
          },
        }).pipe(
          mergeMap((resp) => {
            if (!isArray(resp?.data)) {
              return observableOf<Actions.Action>(actionEmpty(Actions.API_ORDER_HISTORY_ERROR))
            }

            return observableOf<Actions.Action>(
              action(Actions.ORDER_HISTORY, { orderId: c.data.orderId, history: resp.data }),
            )
          }),
          catchError(() => observableOf<Actions.Action>(actionEmpty(Actions.APP_GET_SLOTS_ERROR))),
        ),
      ),
    ),
  )

export const ordersEpics: EpicFunc[] = [
  ordersListEpic,
  ordersMapListEpic,
  changeOrderStatus,
  changeOrderProduct,
  updateOrder,
  assignOrderEpic,
  addProductsOrderEpic,
  deliveryInfoEpic,
  orderHistoryEpic,
]
