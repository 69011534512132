import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import moment from 'moment'

import './index.scss'

import { ApiCustomerOrdersReq } from '../../../types/TApi'
import { EOrderStatus, ICustomer, IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { EModalType, IModalOrder } from '../../../store/reducers/modals'
import { getColor, getDeliveryTime } from '../../../utils/ordersUtils'
import { formatPrice } from '../../../utils/productsUtils'

type TOwnProps = {
  customer: ICustomer,
}

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  orders: IOrder[],
  total: number,
}

type TDispatchedProps = {
  apiCustomerOrders: (data: ApiCustomerOrdersReq) => Actions.Action,
  orderModal: (data: IModalOrder) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  pages: number,
}

const PAGE_SIZE = 3

class CustomerOrdersCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      pages: 1,
    }
  }

  componentDidMount(): void {
    const { customer } = this.props

    this.props.apiCustomerOrders({
      userId: customer.userId,
      offset: 0,
    })
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { customer } = this.props

    if (customer.userId !== prevProps.customer.userId) {
      this.props.apiCustomerOrders({
        userId: customer.userId,
        offset: 0,
      })
      this.setState({ pages: 1 })
    }
  }

  render() {
    const { customer, orders, loaded, total } = this.props
    const { formatMessage } = this.props.intl
    const { pages } = this.state
    const currency = orders[0]?.items.length ? orders[0]?.items[0].currency : undefined

    return (
      <div className='customer__orders'>
        <div className='customer__orders-header'>
          <div className='customer__orders-header__title'>
            {formatMessage(messages.Orders)} {total ? `(${total})` : ''}
          </div>
          {customer.averageOrderPrice > 0 && (
            <div className='customer__orders-header__check'>
              <span className='customer__orders-header__check-label'>{formatMessage(messages.AverageBill)}:</span>
              {formatPrice(customer.averageOrderPrice, currency)}
            </div>
          )}
        </div>
        {orders.length > 0 ? (
          <div>
            {orders.slice(0, pages * PAGE_SIZE).map(this.renderItem)}
            {!loaded && orders.length >= pages * PAGE_SIZE && this.renderShowRest()}
          </div>
        ) : (
          this.renderEmpty()
        )}
      </div>
    )
  }

  renderEmpty() {
    const { formatMessage } = this.props.intl

    return <div className='customer__block-label'>{formatMessage(messages.CustomerNoOrder)}</div>
  }

  renderItem = (order: IOrder) => {
    const { formatMessage } = this.props.intl
    const statusColor = getColor(order.statusId)
    const deliveryAt = order.deliveryAt || order.completedAt ? moment(order.deliveryAt || order.completedAt) : ''
    const [, deliveryPlan] = getDeliveryTime(order)
    const deliveryLate = deliveryPlan ? deliveryPlan.diff(deliveryAt, 'minute', true) : 0
    const late = order.statusId > EOrderStatus.COURIER && deliveryLate <= -1

    return (
      <div key={order.id} className='customer__orders-item' onClick={() => this.selectOrder(order)}>
        <div className='customer__orders-item__header'>
          <div className='customer__orders-item__header-num'>
            №{order.num} {formatMessage(messages.From).toLowerCase()} {moment(order.createdAt).format('DD.MM.YYYY')}
          </div>
          <div className='customer__orders-item__header-status' style={{ color: late ? '#eb5757' : statusColor }}>
            {late
              ? formatMessage(messages.DelayedMinutes, { minutes: Math.floor(Math.abs(deliveryLate)) })
              : order.statusName}
          </div>
        </div>
      </div>
    )
  }

  renderShowRest() {
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__orders__show' onClick={this.getPageReviews}>
        {formatMessage(messages.ShowMore)}
      </div>
    )
  }

  selectOrder = (order: IOrder) => {
    this.props.orderModal({
      type: EModalType.MODAL_ORDER,
      size: 'mini',
      style: { width: '980px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        order,
        marketId: order.marketId,
      },
    })
  }

  getPageReviews = () => {
    const { customer, orders } = this.props
    const { pages } = this.state

    this.setState({ pages: pages + 1 })

    if (orders.length <= orders.slice(0, (pages + 1) * PAGE_SIZE).length) {
      this.props.apiCustomerOrders({
        userId: customer.userId,
        offset: orders.length,
      })
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { orders } = s.customers

  const { customer } = own

  return {
    loading: orders.loading,
    loaded: orders.loaded,
    orders: orders.list.filter((item) => item.userId === customer.userId),
    total: orders.total,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCustomerOrders: (data: ApiCustomerOrdersReq) => dispatch(Actions.action(Actions.API_CUSTOMER_ORDERS, data)),
  orderModal: (data: IModalOrder) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
})

export const CustomerOrders = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerOrdersCmp))
