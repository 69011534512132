import { COUNTRY_ID } from '../../types/countryId'

import { withPrefix } from '../../utils/countriesUtils'

const countries = withPrefix({
  [COUNTRY_ID.AFGHANISTAN]: 'Афганистан',
  [COUNTRY_ID.ALAND_ISLANDS]: 'Аландские острова',
  [COUNTRY_ID.ALBANIA]: 'Албания',
  [COUNTRY_ID.ALGERIA]: 'Алжир',
  [COUNTRY_ID.AMERICAN_SAMOA]: 'Американское Самоа',
  [COUNTRY_ID.ANDORRA]: 'Андорра',
  [COUNTRY_ID.ANGOLA]: 'Ангола',
  [COUNTRY_ID.ANGUILLA]: 'Ангилья',
  [COUNTRY_ID.ANTARCTICA]: 'Антарктида',
  [COUNTRY_ID.ANTIGUA_AND_BARBUDA]: 'Антигуа и Барбуда',
  [COUNTRY_ID.ARGENTINA]: 'Аргентина',
  [COUNTRY_ID.ARMENIA]: 'Армения',
  [COUNTRY_ID.ARUBA]: 'Аруба',
  [COUNTRY_ID.AUSTRALIA]: 'Австралия',
  [COUNTRY_ID.AUSTRIA]: 'Австрия',
  [COUNTRY_ID.AZERBAIJAN]: 'Азербайджан',
  [COUNTRY_ID.BAHAMAS]: 'Багамы',
  [COUNTRY_ID.BAHRAIN]: 'Бахрейн',
  [COUNTRY_ID.BANGLADESH]: 'Бангладеш',
  [COUNTRY_ID.BARBADOS]: 'Барбадос',
  [COUNTRY_ID.BENIN]: 'Бенин',
  [COUNTRY_ID.CURACAO]: 'Кюрасао',
  [COUNTRY_ID.SOUTH_SUDAN]: 'Южный Судан',
  [COUNTRY_ID.BONAIRE]: 'Бонайре, Синт-Эстатиус и Саба',
  [COUNTRY_ID.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: 'Южная Георгия и Южные Сандвичевы острова',
  [COUNTRY_ID.SAINT_MAARTEN]: 'Святого Мартина (Остров, нидерландская часть)',
  [COUNTRY_ID.BELARUS]: 'Беларусь',
  [COUNTRY_ID.BELGIUM]: 'Бельгия',
  [COUNTRY_ID.BELIZE]: 'Белиз',
  [COUNTRY_ID.BERMUDA]: 'Бермуды',
  [COUNTRY_ID.BHUTAN]: 'Бутан',
  [COUNTRY_ID.BOLIVIA]: 'Боливия',
  [COUNTRY_ID.BOSNIA_AND_HERZEGOVINA]: 'Босния и Герцеговина',
  [COUNTRY_ID.BOTSWANA]: 'Ботсвана',
  [COUNTRY_ID.BOUVET_ISLAND]: 'Остров Буве',
  [COUNTRY_ID.BRAZIL]: 'Бразилия',
  [COUNTRY_ID.BRITISH_INDIAN_OCEAN_TERRITORY]: 'Британская территория в Индийском океане',
  [COUNTRY_ID.BRUNEI_DARUSSALAM]: 'Бруней-Даруссалам',
  [COUNTRY_ID.BULGARIA]: 'Болгария',
  [COUNTRY_ID.BURKINA_FASO]: 'Буркина-Фасо',
  [COUNTRY_ID.BURUNDI]: 'Бурунди',
  [COUNTRY_ID.CAMBODIA]: 'Камбоджа',
  [COUNTRY_ID.CAMEROON]: 'Камерун',
  [COUNTRY_ID.CANADA]: 'Канада',
  [COUNTRY_ID.CAPE_VERDE]: 'Кабо-Верде',
  [COUNTRY_ID.CAYMAN_ISLANDS]: 'Каймановы острова',
  [COUNTRY_ID.CENTRAL_AFRICAN_REPUBLIC]: 'Центральноафриканская Республика',
  [COUNTRY_ID.CHAD]: 'Чад',
  [COUNTRY_ID.CHILE]: 'Чили',
  [COUNTRY_ID.CHINA]: 'Китай',
  [COUNTRY_ID.CHRISTMAS_ISLAND]: 'Остров Рождества',
  [COUNTRY_ID.COCOS_KEEL_ING_ISLANDS]: 'Кокосовые острова Килинг',
  [COUNTRY_ID.COLOMBIA]: 'Колумбия',
  [COUNTRY_ID.COMOROS]: 'Коморские острова',
  [COUNTRY_ID.CONGO]: 'Конго',
  [COUNTRY_ID.CONGO_DEMOCRATIC_REPUBLIC]: 'Демократическая Республика Конго',
  [COUNTRY_ID.COOK_ISLANDS]: 'Острова Кука',
  [COUNTRY_ID.COSTA_RICA]: 'Коста-Рика',
  [COUNTRY_ID.COTE_DIVOIRE]: 'Кот-Дивуар',
  [COUNTRY_ID.CROATIA]: 'Хорватия',
  [COUNTRY_ID.CUBA]: 'Куба',
  [COUNTRY_ID.CYPRUS]: 'Кипр',
  [COUNTRY_ID.CZECH_REPUBLIC]: 'Чехия',
  [COUNTRY_ID.DENMARK]: 'Дания',
  [COUNTRY_ID.DJIBOUTI]: 'Джибути',
  [COUNTRY_ID.DOMINICA]: 'Доминика',
  [COUNTRY_ID.DOMINICAN_REPUBLIC]: 'Доминиканская Республика',
  [COUNTRY_ID.ECUADOR]: 'Эквадор',
  [COUNTRY_ID.EGYPT]: 'Египет',
  [COUNTRY_ID.EL_SALVADOR]: 'Сальвадор',
  [COUNTRY_ID.EQUATORIAL_GUINEA]: 'Экваториальная Гвинея',
  [COUNTRY_ID.ERITREA]: 'Эритрея',
  [COUNTRY_ID.ESTONIA]: 'Эстония',
  [COUNTRY_ID.ETHIOPIA]: 'Эфиопия',
  [COUNTRY_ID.FALKLAND_ISLANDS]: 'Фолклендские острова',
  [COUNTRY_ID.FAROE_ISLANDS]: 'Фарерские острова',
  [COUNTRY_ID.FIJI]: 'Фиджи',
  [COUNTRY_ID.FINLAND]: 'Финляндия',
  [COUNTRY_ID.FRANCE]: 'Франция',
  [COUNTRY_ID.FRENCH_GUIANA]: 'Французская Гвиана',
  [COUNTRY_ID.FRENCH_POLYNESIA]: 'Французская Полинезия',
  [COUNTRY_ID.FRENCH_SOUTHERN_TERRITORIES]: 'Южные территории Франции',
  [COUNTRY_ID.GABON]: 'Габон',
  [COUNTRY_ID.GAMBIA]: 'Гамбия',
  [COUNTRY_ID.GEORGIA]: 'Грузия',
  [COUNTRY_ID.GERMANY]: 'Германия',
  [COUNTRY_ID.GHANA]: 'Гана',
  [COUNTRY_ID.GIBRALTAR]: 'Гибралтар',
  [COUNTRY_ID.GREECE]: 'Греция',
  [COUNTRY_ID.GREENLAND]: 'Гренландия',
  [COUNTRY_ID.GRENADA]: 'Гренада',
  [COUNTRY_ID.GUADELOUPE]: 'Гваделупа',
  [COUNTRY_ID.GUAM]: 'Гуам',
  [COUNTRY_ID.GUATEMALA]: 'Гватемала',
  [COUNTRY_ID.GUERNSEY]: 'Гернси',
  [COUNTRY_ID.GUINEA]: 'Гвинея',
  [COUNTRY_ID.GUINEA_BISSAU]: 'Гвинея Бисау',
  [COUNTRY_ID.GUYANA]: 'Гайана',
  [COUNTRY_ID.HAITI]: 'Гаити',
  [COUNTRY_ID.HEARD_ISLAND_MCDONALD_ISLANDS]: 'Остров Херд, острова Макдональдс',
  [COUNTRY_ID.HOLY_SEE_VATICAN_CITY_STATE]: 'Святой Престол Ватикан-город-государство',
  [COUNTRY_ID.HONDURAS]: 'Гондурас',
  [COUNTRY_ID.HONG_KONG]: 'Гонконг',
  [COUNTRY_ID.HUNGARY]: 'Венгрия',
  [COUNTRY_ID.ICELAND]: 'Исландия',
  [COUNTRY_ID.INDIA]: 'Индия',
  [COUNTRY_ID.INDONESIA]: 'Индонезия',
  [COUNTRY_ID.IRAN]: 'Иран',
  [COUNTRY_ID.IRAQ]: 'Ирак',
  [COUNTRY_ID.IRELAND]: 'Ирландия',
  [COUNTRY_ID.ISLEOFMAN]: 'ОстровМан',
  [COUNTRY_ID.ISRAEL]: 'Израиль',
  [COUNTRY_ID.ITALY]: 'Италия',
  [COUNTRY_ID.JAMAICA]: 'Ямайка',
  [COUNTRY_ID.JAPAN]: 'Япония',
  [COUNTRY_ID.JERSEY]: 'Джерси',
  [COUNTRY_ID.JORDAN]: 'Иордания',
  [COUNTRY_ID.KAZAKHSTAN]: 'Казахстан',
  [COUNTRY_ID.KENYA]: 'Кения',
  [COUNTRY_ID.KIRIBATI]: 'Кири бати',
  [COUNTRY_ID.REPUBLIC_OF_KOREA]: 'Республика Корея',
  [COUNTRY_ID.DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA]: 'Корейская Народно-Демократическая Республика',
  [COUNTRY_ID.KUWAIT]: 'Кувейт',
  [COUNTRY_ID.KYRGYZSTAN]: 'Кыргызстан',
  [COUNTRY_ID.LAO_PEOPLES_DEMOCRATIC_REPUBLIC]: 'Лаосская Народно-Демократическая Республика',
  [COUNTRY_ID.LATVIA]: 'Латвия',
  [COUNTRY_ID.LEBANON]: 'Ливан',
  [COUNTRY_ID.LESOTHO]: 'Лесото',
  [COUNTRY_ID.LIBERIA]: 'Либерия',
  [COUNTRY_ID.LIBYAN_ARAB_JAMAHIRIYA]: 'Ливийская Арабская Джамахирия',
  [COUNTRY_ID.LIECHTENSTEIN]: 'Лихтенштейн',
  [COUNTRY_ID.LITHUANIA]: 'Литва',
  [COUNTRY_ID.LUXEMBOURG]: 'Люксембург',
  [COUNTRY_ID.MACAO]: 'Макао',
  [COUNTRY_ID.MACEDONIA]: 'Македония',
  [COUNTRY_ID.MADAGASCAR]: 'Мадагаскар',
  [COUNTRY_ID.MALAWI]: 'Малави',
  [COUNTRY_ID.MALAYSIA]: 'Малайзия',
  [COUNTRY_ID.MALDIVES]: 'Мальдивы',
  [COUNTRY_ID.MALI]: 'Мали',
  [COUNTRY_ID.MALTA]: 'Мальта',
  [COUNTRY_ID.MARSHALL_ISLANDS]: 'Маршалловы Острова',
  [COUNTRY_ID.MARTINIQUE]: 'Мартиника',
  [COUNTRY_ID.MAURITANIA]: 'Мавритания',
  [COUNTRY_ID.MAURITIUS]: 'Маврикий',
  [COUNTRY_ID.MAYOTTE]: 'Майотта',
  [COUNTRY_ID.MEXICO]: 'Мексика',
  [COUNTRY_ID.MICRONESIA]: 'Микронезия',
  [COUNTRY_ID.MOLDOVA]: 'Молдова',
  [COUNTRY_ID.MONACO]: 'Монако',
  [COUNTRY_ID.MONGOLIA]: 'Монголия',
  [COUNTRY_ID.MONTENEGRO]: 'Черногория',
  [COUNTRY_ID.MONTSERRAT]: 'Монтсеррат',
  [COUNTRY_ID.MOROCCO]: 'Марокко',
  [COUNTRY_ID.MOZAMBIQUE]: 'Мозамбик',
  [COUNTRY_ID.MYANMAR]: 'Мьянма',
  [COUNTRY_ID.NAMIBIA]: 'Намибия',
  [COUNTRY_ID.NAURU]: 'Науру',
  [COUNTRY_ID.NEPAL]: 'Непал',
  [COUNTRY_ID.NETHERLANDS]: 'Нидерланды',
  [COUNTRY_ID.NETHERLANDS_ANTILLES]: 'Нидерландские Антильские острова',
  [COUNTRY_ID.NEW_CALEDONIA]: 'Новая Каледония',
  [COUNTRY_ID.NEW_ZEALAND]: 'Новая Зеландия',
  [COUNTRY_ID.NICARAGUA]: 'Никарагуа',
  [COUNTRY_ID.NIGER]: 'Нигер',
  [COUNTRY_ID.NIGERIA]: 'Нигерия',
  [COUNTRY_ID.NIUE]: 'Ниуэ',
  [COUNTRY_ID.NORFOLK_ISLAND]: 'Остров Норфолк',
  [COUNTRY_ID.NORTHERN_MARIANA_ISLANDS]: 'Северные Марианские острова',
  [COUNTRY_ID.NORWAY]: 'Норвегия',
  [COUNTRY_ID.OMAN]: 'Оман',
  [COUNTRY_ID.PAKISTAN]: 'Пакистан',
  [COUNTRY_ID.PALAU]: 'Палау',
  [COUNTRY_ID.PALESTINIAN_TERRITORY]: 'Палестинская территория',
  [COUNTRY_ID.PANAMA]: 'Панама',
  [COUNTRY_ID.PAPUA_NEW_GUINEA]: 'Папуа-Новая Гвинея',
  [COUNTRY_ID.PARAGUAY]: 'Парагвай',
  [COUNTRY_ID.PERU]: 'Перу',
  [COUNTRY_ID.PHILIPPINES]: 'Филиппины',
  [COUNTRY_ID.PITCAIRN]: 'Питкэрн',
  [COUNTRY_ID.POLAND]: 'Польша',
  [COUNTRY_ID.PORTUGAL]: 'Португалия',
  [COUNTRY_ID.PUERTO_RICO]: 'Пуэрто-Рико',
  [COUNTRY_ID.QATAR]: 'Катар',
  [COUNTRY_ID.REUNION]: 'Воссоединение',
  [COUNTRY_ID.ROMANIA]: 'Румыния',
  [COUNTRY_ID.RUSSIA]: 'Российская Федерация',
  [COUNTRY_ID.RWANDA]: 'Руанда',
  [COUNTRY_ID.SAINT_BARTHELEMY]: 'Сен-Бартельми',
  [COUNTRY_ID.SAINTHELENA]: 'Святая Елена',
  [COUNTRY_ID.SAINT_KITTS_AND_NEVIS]: 'Сент-Китс и Невис',
  [COUNTRY_ID.SAINT_LUCIA]: 'Сент-Китс и Невис',
  [COUNTRY_ID.SAINT_MARTIN]: 'Сент-Мартин',
  [COUNTRY_ID.SAINT_PIERRE_AND_MIQUELON]: 'Сен-Пьер и Микелон',
  [COUNTRY_ID.SAINT_VINCENT_AND_GRENADINES]: 'Сент-Винсент и Гренадины',
  [COUNTRY_ID.SAMOA]: 'Самоа',
  [COUNTRY_ID.SAN_MARINO]: 'Сан-Марино',
  [COUNTRY_ID.SAO_TOME_AND_PRINCIPE]: 'Сан-Томе и Принсипи',
  [COUNTRY_ID.SAUDI_ARABIA]: 'Саудовская Аравия',
  [COUNTRY_ID.SENEGAL]: 'Сенегал',
  [COUNTRY_ID.SERBIA]: 'Сербия',
  [COUNTRY_ID.SEYCHELLES]: 'Сейшельские острова',
  [COUNTRY_ID.SIERRA_LEONE]: 'Сьерра-Леоне',
  [COUNTRY_ID.SINGAPORE]: 'Сингапур',
  [COUNTRY_ID.SLOVAKIA]: 'Словакия',
  [COUNTRY_ID.SLOVENIA]: 'Словения',
  [COUNTRY_ID.SOLOMON_ISLANDS]: 'Соломоновы острова',
  [COUNTRY_ID.SOMALIA]: 'Сомали',
  [COUNTRY_ID.SOUTH_AFRICA]: 'Южная Африка',
  [COUNTRY_ID.SOUTH_GEORGIA_AND_SANDWICH_ISL]: 'Южная Георгия и Сэндвич-остров',
  [COUNTRY_ID.SPAIN]: 'Испания',
  [COUNTRY_ID.SRI_LANKA]: 'Шри-Ланка',
  [COUNTRY_ID.SUDAN]: 'Судан',
  [COUNTRY_ID.SURINAME]: 'Суринам',
  [COUNTRY_ID.SVALBARD_AND_JAN_MAYEN]: 'Шпицберген и Ян-Майен',
  [COUNTRY_ID.SWAZILAND]: 'Свазиленд',
  [COUNTRY_ID.SWEDEN]: 'Швеция',
  [COUNTRY_ID.SWITZERLAND]: 'Швейцария',
  [COUNTRY_ID.SYRIAN_ARAB_REPUBLIC]: 'Сирийская Арабская Республика',
  [COUNTRY_ID.TAIWAN]: 'Тайвань',
  [COUNTRY_ID.TAJIKISTAN]: 'Таджикистан',
  [COUNTRY_ID.TANZANIA]: 'Танзания',
  [COUNTRY_ID.THAILAND]: 'Таиланд',
  [COUNTRY_ID.TIMOR_LESTE]: 'Тимор-Лешти',
  [COUNTRY_ID.TOGO]: 'Того',
  [COUNTRY_ID.TOKELAU]: 'Токелау',
  [COUNTRY_ID.TONGA]: 'Тонга',
  [COUNTRY_ID.TRINIDAD_AND_TOBAGO]: 'Тринидад и Тобаго',
  [COUNTRY_ID.TUNISIA]: 'Тунис',
  [COUNTRY_ID.TURKEY]: 'Турция',
  [COUNTRY_ID.TURKMENISTAN]: 'Туркменистан',
  [COUNTRY_ID.TURKS_AND_CAICOS_ISLANDS]: 'Острова Теркс и Кайкос',
  [COUNTRY_ID.TUVALU]: 'Т увалу',
  [COUNTRY_ID.UGANDA]: 'Уганда',
  [COUNTRY_ID.UKRAINE]: 'Украина',
  [COUNTRY_ID.UNITED_ARAB_EMIRATES]: 'Объединенные Арабские Эмираты',
  [COUNTRY_ID.UNITED_KINGDOM]: 'Великобритания',
  [COUNTRY_ID.UNITED_STATES]: 'США',
  [COUNTRY_ID.UNITED_STATES_OUTLYING_ISLANDS]: 'Отдаленные острова США',
  [COUNTRY_ID.URUGUAY]: 'Уругвай',
  [COUNTRY_ID.UZBEKISTAN]: 'Узбекистан',
  [COUNTRY_ID.VANUATU]: 'Вану ату',
  [COUNTRY_ID.VENEZUELA]: 'Венесуэла',
  [COUNTRY_ID.VIETNAM]: 'Вьетнам',
  [COUNTRY_ID.VIRGIN_ISLANDS_BRITISH]: 'Британские Виргинские острова',
  [COUNTRY_ID.VIRGIN_ISLANDS_US]: 'Виргинские острова США',
  [COUNTRY_ID.WALLIS_AND_FUTUNA]: 'Уоллис и Футуна',
  [COUNTRY_ID.WESTERN_SAHARA]: 'Западная Сахара',
  [COUNTRY_ID.YEMEN]: 'Йемен',
  [COUNTRY_ID.ZAMBIA]: 'Замбия',
  [COUNTRY_ID.ZIMBABW]: 'Зимбабве',
})

export default countries
