import moment from 'moment-timezone'
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem'

import { IOrderApi, IOrderByMapApi, IProductOrderApi, ISlotType } from '../types/TApi'
import {
  EOrderStatus,
  ESlotType,
  HOUR_MINUTE,
  INTERVAL_SIZE_MINUTE,
  IOrder,
  IOrderBlock,
  IOrderByMap,
  IProductOrder,
} from '../types/TClient'

import { intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'
import { convertCurrency } from './productsUtils'

export const ORDERS_LIMIT = 10
export const ORDER_SLOT_MINUTE = 90
export const ORDER_COLLECTED_TIME = 90
export const ORDER_COURIER_TIME = 60
export const ORDERS_MAP_LIMIT = 150

export const convertOrderFromApi = (order: IOrderApi): IOrder => {
  return {
    id: order.id,
    num: order.num,
    price: order.price,
    paymentType: order.payment_type,
    paid: order.is_payed,
    web: order.web,
    paymentUrl: order.payment_url,
    isCollected: order.is_collected,
    createdAt: moment(order.created_at).toDate(),
    items: order.items.map(convertProductOrderFromApi),
    selfPickup: order.self_pickup,
    orderConfirmed: order.order_confirmed,
    statusId: order.status_id,
    statusName: convertStatus(order.status_id),
    weightGr: order.weight_gr,
    marketName: order.market_name,
    userId: order.user_id,
    userPhone: order.user_phone,
    userBusiness: order.user_business,
    marketId: order.market_id,
    marketSelfPickupStartTime: order.market_self_pickup_start_time,
    marketSelfPickupEndTime: order.market_self_pickup_end_time,
    marketDeliveryStartTime: order.market_delivery_start_time,
    marketDeliveryEndTime: order.market_delivery_end_time,
    city: order.city,
    cityTz: order.city_tz,
    collectedLate: order.collected_late || 0,
    deliveryLate: order.delivery_late || 0,
    deliveryEndLate: order.delivery_end_late || 0,
    deliveryType: order.delivery_type,
    isPicked: order.is_picked,
    ...(order.review && { review: order.review }),
    ...(order.completed_at && { completedAt: moment(order.completed_at).toDate() }),
    ...(order.assembly_at && { assemblyAt: moment(order.assembly_at).toDate() }),
    ...(order.collected_at && { collectedAt: moment(order.collected_at).toDate() }),
    ...(order.courier_at && { courierAt: moment(order.courier_at).toDate() }),
    ...(order.delivery_at && { deliveryAt: moment(order.delivery_at).toDate() }),
    ...(order.canceled_at && { canceledAt: moment(order.canceled_at).toDate() }),
    ...(order.delivery_end_time && { deliveryEndTime: order.delivery_end_time }),
    ...(order.message && { comment: order.message }),
    ...(order.message_hide && { commentHide: order.message_hide }),
    ...(order.user_first_name && { userFirstName: order.user_first_name }),
    ...(order.user_last_name && { userLastName: order.user_last_name }),
    userCallMe: order.user_call_me ?? true,
    userCallAboutChangeProduct: order.user_call_about_change_product ?? true,
    userCallAboutDeleteProduct: order.user_call_about_delete_product ?? true,
    userCallAboutChangeProductWeight: order.user_call_about_change_product_weight ?? true,
    userCallChangeProduct: order.user_call_change_product ?? true,
    userCallDeleteProductByWeight: order.user_call_delete_product_by_weight ?? true,
    ...(order.market_address && { marketAddress: order.market_address }),
    ...(order.market_open_hours_start && { marketOpeningHours: order.market_open_hours_start }),
    ...(order.market_open_hours_end && { marketClosingHours: order.market_open_hours_end }),
    ...(order.updated_at && { updatedAt: new Date(order.updated_at) }),
    ...(order.delivery_price !== undefined && { deliveryPrice: order.delivery_price }),
    ...(order.delivery_address && order.delivery_address !== 'None' && { deliveryAddress: order.delivery_address }),
    ...(order.delivery_date &&
      order.delivery_date !== 'None' && { deliveryDate: moment(order.delivery_date).toDate() }),
    ...(order.delivery_time && order.delivery_time !== 'None' && { deliveryTime: order.delivery_time }),
    ...(order.delivery_distance && { deliveryDistance: order.delivery_distance }),
    ...(order.delivery_lat && { deliveryLat: order.delivery_lat }),
    ...(order.delivery_lon && { deliveryLon: order.delivery_lon }),
    ...(order.picker_id && { pickerId: order.picker_id }),
    ...(order.picker_phone && { pickerPhone: order.picker_phone }),
    ...(order.picker_first_name && { pickerFirstName: order.picker_first_name }),
    ...(order.picker_last_name && { pickerLastName: order.picker_last_name }),
    ...(order.courier_id && { courierId: order.courier_id }),
    ...(order.courier_phone && { courierPhone: order.courier_phone }),
    ...(order.courier_first_name && { courierFirstName: order.courier_first_name }),
    ...(order.courier_last_name && { courierLastName: order.courier_last_name }),
    ...((order.collector_id || order.courier_id) && { collectorId: order.collector_id || order.courier_id }),
    ...((order.collector_phone || order.courier_phone) && {
      collectorPhone: order.collector_phone || order.courier_phone,
    }),
    ...((order.collector_first_name || order.courier_first_name) && {
      collectorFirstName: order.collector_first_name || order.courier_first_name,
    }),
    ...((order.collector_last_name || order.courier_last_name) && {
      collectorLastName: order.collector_last_name || order.courier_last_name,
    }),
    ...(order.delivery_area && { deliveryArea: order.delivery_area }),
    ...(order.ext_paid !== undefined && { extPaid: order.ext_paid }),
    ...(order.ext_payment_url && { extPaymentUrl: order.ext_payment_url }),
  }
}

export const convertOrderMapFromApi = (orderByMap: IOrderByMapApi): IOrderByMap => {
  return {
    deliveryAddress: orderByMap.delivery_address,
    deliveryLat: +orderByMap.delivery_lat,
    deliveryLong: +orderByMap.delivery_long,
    userId: orderByMap.user_id,
    userPhone: orderByMap.user_phone,
    userBusiness: orderByMap.user_business,
    userFirstName: orderByMap.user_first_name,
    userLastName: orderByMap.user_last_name,
    count: orderByMap.count,
    lastOrderDate: orderByMap.last_order_date,
  }
}

export const convertProductOrderFromApi = (product: IProductOrderApi): IProductOrder => {
  return {
    id: product.id,
    itemId: product.item_id,
    name: product.name,
    country: product.country,
    market: product.market,
    image: product.image_url,
    price: product.price,
    totalPrice: product.total_price,
    quantity: product.quantity,
    quantityInitial: product.quantity_initial,
    paymentQuantity: product.payment_quantity,
    extendQuantity: product.extend_quantity,
    pcsWeightGr: product.pcs_weight_gr,
    sellerId: product.seller_id,
    sellerName: product.seller_name,
    sellerIsSystem: product.seller_is_system,
    sellerIsCommon: product.seller_is_common,
    sellerCommission: product.seller_commission,
    subcategory: product.subcategory,
    unit: product.unit,
    added: product.added,
    createdAt: product.created_at,
    currency: convertCurrency(product.currency_code),
    ...(product.organisation_name && { organisationName: product.organisation_name }),
    ...(product.about_seller && { aboutSeller: product.about_seller }),
  }
}

export const convertStatus = (statusId: EOrderStatus) => {
  switch (statusId) {
    case EOrderStatus.NEW:
      return intlMessage(messages.New)
    case EOrderStatus.IN_ASSEMBLY:
      return intlMessage(messages.InAssembly)
    case EOrderStatus.COURIER:
      return intlMessage(messages.AtCourier)
    case EOrderStatus.DELIVERED:
      return intlMessage(messages.Delivered)
    case EOrderStatus.CANCELED:
      return intlMessage(messages.Canceled)
  }
}

export const OrderHistoryConvertStatus = (statusId: EOrderStatus) => {
  switch (statusId) {
    case EOrderStatus.NEW:
      return intlMessage(messages.OrderCreated)
    case EOrderStatus.IN_ASSEMBLY:
      return intlMessage(messages.OrderInAssembly)
    case EOrderStatus.COURIER:
      return intlMessage(messages.CourierTookOrderForDelivery)
    case EOrderStatus.DELIVERED:
      return intlMessage(messages.OrderDeliveredToCustomer)
    case EOrderStatus.CANCELED:
      return intlMessage(messages.OrderCancelled)
  }
}

export const getColor = (status: EOrderStatus) => {
  switch (status) {
    case EOrderStatus.NEW:
      return '#00c752'
    case EOrderStatus.IN_ASSEMBLY:
      return '#2f80ed'
    case EOrderStatus.COURIER:
      return '#2f80ed'
    case EOrderStatus.DELIVERED:
      return '#00c752'
    case EOrderStatus.CANCELED:
      return '#d8553f'
  }
}

export const round = (price: number) => {
  return +price.toFixed(2).toLocaleString()
}

export const localeWeight = (weight: number) => {
  const weightKilo = weight / 1000

  return weightKilo >= 1
    ? `${+weightKilo.toFixed(2)}\u00A0${intlMessage(messages.Kg).toLowerCase()}`
    : `${round(weight)}\u00A0${intlMessage(messages.Gram).toLowerCase()}`
}

export const getOrderDeliveryTime = (timeFirst: string): string => {
  const [startDeliveryHour, startDeliveryMinute] = timeFirst.split(':').slice(0, 2)
  const endDeliveryTime = +startDeliveryHour * HOUR_MINUTE + +startDeliveryMinute + INTERVAL_SIZE_MINUTE
  const endDeliveryHour = Math.floor(endDeliveryTime / HOUR_MINUTE)
  const endDeliveryMinute =
    endDeliveryTime % HOUR_MINUTE >= 10
      ? (endDeliveryTime % HOUR_MINUTE).toString()
      : `0${endDeliveryTime % HOUR_MINUTE}`

  return `${startDeliveryHour}:${startDeliveryMinute} – ${endDeliveryHour}:${endDeliveryMinute}`
}

export const formatTime = (time: string) => {
  return time.split(':').slice(0, 2).join(':')
}

export const getDeliveryTime = (order: IOrder) => {
  const deliveryStartTime = order.deliveryTime
    ? moment(order.deliveryDate)
        .hours(+order.deliveryTime.split(':')[0])
        .minutes(+order.deliveryTime.split(':')[1])
    : moment(order.createdAt)

  const deliveryEndTime = order.deliveryEndTime
    ? moment(order.deliveryDate)
        .hours(+order.deliveryEndTime.split(':')[0])
        .minutes(+order.deliveryEndTime.split(':')[1])
    : moment(deliveryStartTime).add(ORDER_SLOT_MINUTE, 'minute')

  return [deliveryStartTime, deliveryEndTime]
}

export const getCollectedPlan = (order: IOrder) => {
  const createdAt = moment(order.createdAt)
  const [deliveryStartTime, deliveryEndTime] = getDeliveryTime(order)

  let [collectPlanHour, collectPlanMinute] = order.deliveryEndTime
    ? formatTime(order.deliveryEndTime)
        .split(':')
        .map((i) => +i)
    : []

  collectPlanMinute = collectPlanMinute - ORDER_COLLECTED_TIME
  collectPlanHour -= Math.floor(ORDER_COLLECTED_TIME / HOUR_MINUTE)
  collectPlanMinute += Math.floor(ORDER_COLLECTED_TIME / HOUR_MINUTE) * HOUR_MINUTE

  if (collectPlanMinute < 0) {
    collectPlanHour -= 1
    collectPlanMinute += HOUR_MINUTE
  }

  if (collectPlanHour < 0) {
    collectPlanHour += 24
  }

  let collectPlan = moment(deliveryStartTime).hours(collectPlanHour).minutes(collectPlanMinute)

  if (collectPlan.diff(createdAt, 'minute', true) < 0 && deliveryEndTime.diff(createdAt, 'minute', true) > 0) {
    collectPlan = createdAt
  }

  return collectPlan
}

export const getCourierPlan = (order: IOrder) => {
  const [deliveryStartTime, deliveryEndTime] = getDeliveryTime(order)
  const createdAt = moment(order.createdAt)

  let [courierPlanHour, courierPlanMinute] = order.deliveryEndTime
    ? formatTime(order.deliveryEndTime)
        .split(':')
        .map((i) => +i)
    : []

  courierPlanMinute = courierPlanMinute - ORDER_COURIER_TIME

  if (courierPlanMinute < 0) {
    courierPlanHour -= 1
    courierPlanMinute += HOUR_MINUTE
  }

  if (courierPlanHour < 0) {
    courierPlanHour += 24
  }

  let courierPlan = moment(deliveryStartTime).hours(courierPlanHour).minutes(courierPlanMinute)

  if (courierPlan.diff(createdAt, 'minute', true) < 0 && deliveryEndTime.diff(createdAt, 'minute', true) > 0) {
    courierPlan = createdAt
  }

  return courierPlan
}

export const byCurrentStatus = (option: DropdownItemProps, status: EOrderStatus) => {
  return status === EOrderStatus.NEW
    ? option.value === EOrderStatus.CANCELED || option.value === EOrderStatus.NEW
    : option.value === status || option.value === status + 1 || option.value === EOrderStatus.CANCELED
}

export const getTimeWithTimeZone = (time?: Date | string, zone?: string) =>
  zone ? moment(time).tz(zone) : moment(time)

export const checkLate = (order: IOrder) =>
  (order.deliveryEndLate > 0 && order.statusId < EOrderStatus.DELIVERED) ||
  (order.deliveryLate > 0 && order.statusId < EOrderStatus.COURIER) ||
  (order.collectedLate > 0 && !order.isCollected && order.statusId < EOrderStatus.COURIER)

export const groupByBlock = (orders: IOrder[], date?: Date): IOrderBlock[] => {
  const ordersBlocks: {
    [id: string]: IOrderBlock,
  } = {}

  for (const order of orders) {
    const currentDate = getTimeWithTimeZone(date || moment().toDate(), order.cityTz)
    const deliveryDate = moment(order.selfPickup ? order.createdAt : order.deliveryDate || order.createdAt)
    const deliveryDateText = deliveryDate.format('YYYYMMDD')

    const slot =
      order.deliveryEndTime && order.deliveryTime
        ? `${formatTime(order.deliveryTime)} – ${formatTime(order.deliveryEndTime)}`
        : order.deliveryTime
        ? getOrderDeliveryTime(formatTime(order.deliveryTime))
        : ''

    const lateCheck = checkLate(order) || (order.selfPickup && deliveryDateText !== moment().format('YYYYMMDD'))

    const id = order.selfPickup
      ? `${deliveryDateText}_${intlMessage(messages.Pickup)}`
      : `${deliveryDateText}_${slot.replace(' ', '')}${lateCheck ? 'alert' : ''}`

    const currentOrderBlock = ordersBlocks[id]
    const titles = currentOrderBlock ? currentOrderBlock.titles : []

    let tagText = order.selfPickup ? intlMessage(messages.Pickup) : `${slot}`

    if (order.statusId < EOrderStatus.DELIVERED) {
      const diffDate =
        currentDate.month() === deliveryDate.month()
          ? currentDate.date() - deliveryDate.date()
          : Math.ceil(currentDate.diff(deliveryDate, 'day'))

      if (diffDate === 1) {
        tagText = `${intlMessage(messages.Yesterday)}, ` + tagText
      } else if (diffDate >= 2 || diffDate <= -1) {
        tagText = `${moment(deliveryDate).format('DD.MM.YYYY')}, ` + tagText
      }

      if (lateCheck) {
        const alertTitle = {
          text: intlMessage(messages.PayAttention),
          color: '#fdeeee',
          colorText: '#eb5757',
        }

        if (!titles.find((item) => item.text === alertTitle.text)) {
          titles.unshift(alertTitle)
        }
      }
    }

    if (!titles.find((item) => item.text === tagText)) {
      titles.push({ text: tagText })
    }

    ordersBlocks[id] = {
      index: id,
      deliveryDate: deliveryDate.toDate(),
      orders: currentOrderBlock ? [...currentOrderBlock.orders, order] : [order],
      titles,
    }
  }

  return Object.values(ordersBlocks)
}

export const checkIsInterval = (deliveryType?: ESlotType) => {
  return deliveryType === ESlotType.AS_SOON_AS_POSSIBLE || deliveryType === ESlotType.DURING_THE_DAY
}

export const getDeliveryTypeName = (deliveryType?: ESlotType) => {
  if (deliveryType !== ESlotType.AS_SOON_AS_POSSIBLE && deliveryType !== ESlotType.DURING_THE_DAY) {
    return ''
  }

  return (
    {
      [ESlotType.AS_SOON_AS_POSSIBLE]: intlMessage(messages.AsSoonAsPossible),
      [ESlotType.DURING_THE_DAY]: intlMessage(messages.DuringDay),
    }[deliveryType] || ''
  )
}

export const getSlotType = (slotTypes: ISlotType[]) =>
  slotTypes.find(
    (s) =>
      s.delivery_interval === ESlotType.NEAREST_SLOT ||
      s.delivery_interval === ESlotType.THROUGH_ONE_SLOT ||
      s.delivery_interval === ESlotType.THROUGH_TWO_SLOTS,
  )
