import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { HistoryChangeProductAction } from '../../../../types/TClient'

import { Action } from '../Action'
import { unitIntl } from '../../../../utils/productsUtils'
import messages from '../../../../localization/messages'

type IProps = HistoryChangeProductAction & WrappedComponentProps

const _ChangeProductAction: React.FC<IProps> = ({ data: { name, quantity, quantityInitial, unit }, date }) => {
  const unitLocal = unitIntl(unit)
  return (
    <Action date={date}>
      <FormattedMessage {...messages.ChangedAmount} />
      <div className='change-product-action__product-name'>
        {name} {quantityInitial} {unitLocal} {'→'} {quantity} {unitLocal}
      </div>
    </Action>
  )
}

export const ChangeProductAction = injectIntl(_ChangeProductAction)
