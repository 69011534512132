import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'

import './index.scss'

import { ESlotAction, IMarket, ISlot, ISlotInfo } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import { Segment } from '../../../../../components/Segment'
import { SlotCard } from '../../../../../components/SlotCard'
import { Action } from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import * as Actions from '../../../../../store/actions'

type TConnectedProps = {
  slotsInfo: ISlotInfo[],
  markets: IMarket[],
}

type TDispatchToProps = {
  getSlotsInfo(market: string): Action,
  getMarkets(): Actions.Action,
  updateSlot(market: string, slot: ISlot, isActive: boolean): Action,
}

type TState = {
  market: string,
}

type TProps = TDispatchToProps & TConnectedProps & WrappedComponentProps

class _SlotsMonitoring extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    const { markets } = props

    const market = markets[0]

    this.state = {
      market: market ? market.id : '',
    }
  }

  componentDidMount() {
    const { getSlotsInfo, getMarkets } = this.props

    getMarkets()

    if (this.props.markets[0]) {
      this.handleChangeMarket(null, { value: this.props.markets[0]?.id })
      getSlotsInfo(this.props.markets[0].id)
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    if (!prevProps.markets.length && this.props.markets.length) {
      this.handleChangeMarket(null, { value: this.props.markets[0]?.id })
    }
  }

  render() {
    const { slotsInfo } = this.props

    return (
      <div>
        {this.renderMarket()}
        <div className='slots-monitoring'>
          <div className='slots-monitoring__content'>
            {slotsInfo.map((slot) => (
              <SlotCard key={slot.id} slot={slot} changeActive={(isActive) => this.updateSlotStatus(slot, isActive)} />
            ))}
          </div>
          <div className='slots-monitoring__info'>{this.renderHistory()}</div>
        </div>
      </div>
    )
  }

  renderHistory = () => {
    const { formatMessage } = this.props.intl

    return (
      <Segment className='slots-monitoring__info__item'>
        <div className='slots-monitoring__info__item__header'>
          <span className='slots-monitoring__info__item__header__title'>{formatMessage(messages.History)}</span>
          <div className='slots-monitoring__info__item__header__dot' />
          <span className='slots-monitoring__info__item__header__date'>{moment().format('DD MMMM YYYY')}</span>
        </div>
        <div className='slots-monitoring__info__item__content'>{formatMessage(messages.HistoryNotAvailable)}</div>
      </Segment>
    )
  }

  renderMarket = () => {
    const { markets } = this.props
    const { market } = this.state

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='slots-monitoring__filter'>
        <div className='slots-monitoring__filter__field'>
          <Dropdown value={market} search selection options={options} onChange={this.handleChangeMarket} />
        </div>
      </div>
    )
  }

  handleChangeMarket = (event: any, data: any) => {
    const { getSlotsInfo } = this.props
    const { value } = data

    getSlotsInfo(value)

    this.setState({ market: value })
  }

  updateSlotStatus = (slot: ISlotInfo, isActive: boolean) => {
    const { market } = this.state
    const clientSlot: ISlot = {
      id: slot.id,
      from: slot.timeStart,
      to: slot.timeEnd,
      isActive,
      error: [],
    }

    this.props.updateSlot(market, clientSlot, isActive)
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  slotsInfo: s.markets.slotsInfo,
  markets: s.markets.markets || [],
})

const dispatchToProps = (dispatch: Dispatch): TDispatchToProps => ({
  getSlotsInfo: (market: string) => dispatch(Actions.action(Actions.API_SLOTS_INFO, { market })),
  getMarkets: () =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        include_all: 'True',
      }),
    ),
  updateSlot: (market: string, slot: ISlot, isActive: boolean) =>
    dispatch(
      Actions.action(Actions.API_UPDATE_SLOTS, {
        market,
        updateInfo: true,
        slots: [
          {
            ...slot,
            isActive,
            type: ESlotAction.UPDATE,
          },
        ],
      }),
    ),
})

export const SlotsMonitoring = connect<TConnectedProps, TDispatchToProps, any, State>(
  mapStateToProps,
  dispatchToProps,
)(injectIntl(_SlotsMonitoring))
