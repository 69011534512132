import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import { IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'

type TOwnProps = {
  order: IOrder,
}

type TProps = TOwnProps & WrappedComponentProps

class OrderMarketCmp extends React.Component<TProps> {
  render() {
    const { formatMessage } = this.props.intl
    const { marketSelfPickupStartTime, marketSelfPickupEndTime, marketName, selfPickup } = this.props.order

    return (
      <div className='order__market'>
        <div className='order__block-title'>{formatMessage(messages.Market)}</div>
        <div>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.NamePlace)}</div>
            <div className='order__block-text'>{marketName}</div>
          </div>
          {selfPickup && (
            <div className={classnames('order__block-item', 'order__block-padding')}>
              <div className='order__block-label'>{formatMessage(messages.PickupTime)}</div>
              <div className='order__block-text'>
                {marketSelfPickupStartTime &&
                  marketSelfPickupEndTime &&
                  `${marketSelfPickupStartTime.slice(0, -3)} – ${marketSelfPickupEndTime.slice(0, -3)}`}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export const OrderMarket = injectIntl(OrderMarketCmp)
