export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8100'
export const FILE_STORAGE_URL = process.env.REACT_APP_FILES_URL || 'http://localhost:8200'
export const APP_LINK = process.env.REACT_APP_APP_LINK || 'tvoyrynok://'

const API_PREFIX = '/api'

export const URL_OK = API_URL + API_PREFIX + '/ok'

export const URL_LOGIN = API_URL + API_PREFIX + '/login'
export const URL_SIGN_UP = API_URL + API_PREFIX + '/signup'

export const URL_USER_INFO = API_URL + API_PREFIX + '/user/info'

export const URL_ORDERS_LIST = API_URL + API_PREFIX + '/orders/list'
export const URL_UPDATE_ORDER_COMMENT = API_URL + API_PREFIX + '/orders/comment'
export const URL_ORDER_CHANGE_STATUS = API_URL + API_PREFIX + '/orders/status'
export const URL_ORDER_CHANGE_PRODUCT = API_URL + API_PREFIX + '/orders/product'
export const URL_ORDER_UPDATE = API_URL + API_PREFIX + '/orders/update'
export const URL_ORDER_ASSIGN = API_URL + API_PREFIX + '/orders/assign'
export const URL_ORDER_ADD_PRODUCTS = API_URL + API_PREFIX + '/orders/addProducts'
export const URL_DELIVERY_INFO = API_URL + API_PREFIX + '/orders/delivery/info'
export const URL_ORDERS_MAP_LIST = API_URL + API_PREFIX + '/orders/map'
export const URL_ORDER_HISTORY = API_URL + API_PREFIX + '/orders/logs'

export const URL_PARTNER_INFO = API_URL + API_PREFIX + '/sellers/partner'
export const URL_BANK_INFO = API_URL + API_PREFIX + '/sellers/bank'
export const URL_CITIES = API_URL + API_PREFIX + '/cities'
export const URL_MARKETS = API_URL + API_PREFIX + '/markets'

export const URL_DELIVERY_AREA_ADD = API_URL + API_PREFIX + '/markets/deliveryArea/add'
export const URL_DELIVERY_AREA_UPDATE = API_URL + API_PREFIX + '/markets/deliveryArea/update'
export const URL_DELIVERY_AREA_REMOVE = API_URL + API_PREFIX + '/markets/deliveryArea/remove'

export const URL_MARKET_CATEGORIES = API_URL + API_PREFIX + '/markets/stock/categories'
export const URL_MARKET_CATEGORY_CREATE = API_URL + API_PREFIX + '/markets/stock/category/create'
export const URL_MARKET_CATEGORY_UPDATE = API_URL + API_PREFIX + '/markets/stock/category/update'
export const URL_MARKET_CATEGORY_REMOVE = API_URL + API_PREFIX + '/markets/stock/category/remove'
export const URL_MARKET_SUBCATEGORY_CREATE = API_URL + API_PREFIX + '/markets/stock/subcategory/create'
export const URL_MARKET_SUBCATEGORY_UPDATE = API_URL + API_PREFIX + '/markets/stock/subcategory/update'
export const URL_MARKET_SUBCATEGORY_REMOVE = API_URL + API_PREFIX + '/markets/stock/subcategory/remove'
export const URL_MARKET_GROUPS = API_URL + API_PREFIX + '/markets/groups'
export const URL_MARKET_GROUP_CREATE = API_URL + API_PREFIX + '/markets/groups/create'
export const URL_MARKET_GROUP_UPDATE = API_URL + API_PREFIX + '/markets/groups/update'
export const URL_MARKET_GROUP_REMOVE = API_URL + API_PREFIX + '/markets/groups/remove'
export const URL_SLOTS_INFO = API_URL + API_PREFIX + '/slots/info'

export const URL_MARKET_ROUTES = API_URL + API_PREFIX + '/markets/routes'
export const URL_MARKET_ROUTE_CREATE = API_URL + API_PREFIX + '/markets/routes/create'
export const URL_MARKET_ROUTE_UPDATE = API_URL + API_PREFIX + '/markets/routes/update'
export const URL_MARKET_ROUTE_REMOVE = API_URL + API_PREFIX + '/markets/routes/remove'

export const URL_UPLOAD_FILE = FILE_STORAGE_URL + '/files/upload'
export const URL_UPLOAD_IMAGE = FILE_STORAGE_URL + '/images/upload'

export const URL_SELLERS_INFO_LIST = API_URL + API_PREFIX + '/sellersInfo/list'
export const URL_SELLERS_INFO_CREATE = API_URL + API_PREFIX + '/sellersInfo/create'
export const URL_SELLERS_INFO_UPDATE = API_URL + API_PREFIX + '/sellersInfo/update'
export const URL_SELLERS_INFO_REMOVE = API_URL + API_PREFIX + '/sellersInfo/remove'
export const URL_SELLER_COURIER = API_URL + API_PREFIX + '/sellers/courier/create'
export const URL_SELLER_REGISTER = API_URL + API_PREFIX + '/sellers/register'
export const URL_SELLER_UPDATE = API_URL + API_PREFIX + '/sellers/update'
export const URL_SELLER_UPDATE_IS_HIDE = API_URL + API_PREFIX + '/sellers/hide/update'
export const URL_COPY_PRODUCTS = API_URL + API_PREFIX + '/sellers/copyProducts'
export const URL_COPY_PRODUCTS_STATUS = API_URL + API_PREFIX + '/sellers/copyProducts/status'

export const URL_SELLERS_MARKETS = API_URL + API_PREFIX + '/sellers/markets'
export const URL_SELLERS_LIST = API_URL + API_PREFIX + '/sellers/list'
export const URL_SELLER_PRODUCTS = API_URL + API_PREFIX + '/sellers/products'
export const URL_SELLERS_SEARCH = API_URL + API_PREFIX + '/sellers/search'

export const URL_PRODUCT_CATEGORIES = API_URL + API_PREFIX + '/products/categories'
export const URL_PRODUCT_COUNTRIES = API_URL + API_PREFIX + '/products/countries'
export const URL_PRODUCT_STEPS = API_URL + API_PREFIX + '/products/steps'
export const URL_PRODUCT_UNITS = API_URL + API_PREFIX + '/products/units'
export const URL_PRODUCT_CREATE = API_URL + API_PREFIX + '/products/create'
export const URL_PRODUCT_UPDATE = API_URL + API_PREFIX + '/products/update'
export const URL_PRODUCT_DELETE = API_URL + API_PREFIX + '/products/delete'
export const URL_PRODUCT_COPY = API_URL + API_PREFIX + '/products/copy'
export const URL_PRODUCTS_LIST = API_URL + API_PREFIX + '/products/list'
export const URL_PRODUCTS_UPDATE_RATING = API_URL + API_PREFIX + '/products/updateRatings'
export const URL_PRODUCTS_MODERATION = API_URL + API_PREFIX + '/products/moderationList'
export const URL_PRODUCT_MODERATION_UPDATE = API_URL + API_PREFIX + '/products/moderate'

export const URL_COURIERS_LIST = API_URL + API_PREFIX + '/couriers/list'
export const URL_COURIERS_LOCATIONS = API_URL + API_PREFIX + '/couriers/locations'

export const URL_NOTIFICATIONS_LIST = API_URL + API_PREFIX + '/notifications/list'
export const URL_NOTIFICATION_CREATE = API_URL + API_PREFIX + '/notifications/create'
export const URL_NOTIFICATION_UPDATE = API_URL + API_PREFIX + '/notifications/update'
export const URL_NOTIFICATION_REMOVE = API_URL + API_PREFIX + '/notifications/remove'
export const URL_NOTIFICATIONS_LOG = API_URL + API_PREFIX + '/notifications/log'

export const URL_REPORT_ORDERS = API_URL + API_PREFIX + '/report/orders'
export const URL_REPORT_ORDERS_CUSTOMERS = API_URL + API_PREFIX + '/report/ordersByCustomers'
export const URL_REPORT_PRODUCTS = API_URL + API_PREFIX + '/report/products'
export const URL_REPORT_CUSTOMERS = API_URL + API_PREFIX + '/report/customers'
export const URL_REPORT_COURIER_SHIFTS = API_URL + API_PREFIX + '/report/courierShifts'

export const URL_ORDERS_BY_MARKETS = API_URL + API_PREFIX + '/analytics/ordersByMarkets'
export const URL_CUSTOMERS = API_URL + API_PREFIX + '/analytics/customers'
export const URL_TOTAL_ORDERS = API_URL + API_PREFIX + '/analytics/totalOrders'

export const URL_GET_CLIENTS = API_URL + API_PREFIX + '/users/list'
export const URL_CUSTOMERS_LIST = API_URL + API_PREFIX + '/users/customers'
export const URL_CUSTOMER_REVIEWS = API_URL + API_PREFIX + '/orders/reviews'
export const URL_CUSTOMER_ADDRESSES = API_URL + API_PREFIX + '/orders/addresses'
export const URL_CUSTOMER_UPDATE_PROFILE = API_URL + API_PREFIX + '/users/profile/update'

export const URL_GET_TAGS = API_URL + API_PREFIX + '/tags'
export const URL_CREATE_TAG = API_URL + API_PREFIX + '/tags/create'
export const URL_UPDATE_TAG = API_URL + API_PREFIX + '/tags/update'
export const URL_REMOVE_TAGS = API_URL + API_PREFIX + '/tags/remove'
export const URL_TAGS_PRODUCTS = API_URL + API_PREFIX + '/tags/products'

export const URL_SLOTS_LIST = API_URL + API_PREFIX + '/slots/list'
export const URL_SLOTS_UPDATE = API_URL + API_PREFIX + '/slots/update'

export const URL_COURIER_SHIFTS = API_URL + API_PREFIX + '/couriers/shifts'
export const URL_COURIER_SHIFTS_UPDATE = API_URL + API_PREFIX + '/couriers/shifts/update'
