import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Dropdown, Icon, Input, InputOnChangeData, Label, Loader, Placeholder, Segment } from 'semantic-ui-react'
import { isEqual, isUndefined, times } from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import './index.scss'

import {
  ARCHIVE_FILTER,
  CURRENT_FILTER,
  DAY_HOUR,
  EOrdersFilter,
  EOrderStatus,
  EReportFormat,
  HOUR_MINUTE,
  ICity,
  IMarket,
  IOrder,
  IOrderBlock,
  IOrdersFilter,
  MINUTE_SEC,
  SEC,
} from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { EModalType, IModalOrdersFilter } from '../../../../store/reducers/modals'
import { OrderPanel } from './OrderPanel'
import { OrdersBlock } from '../../../../components/OrdersBlock'
import { FilterIcon } from '../../../../components/Icons'
import { groupByBlock, ORDERS_LIMIT } from '../../../../utils/ordersUtils'
import { checkMobile } from '../../../../utils/deviceUtils'
import { LOCATION_ORDERS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  loadingReport: boolean,
  filter: IOrdersFilter,
  total: number,
  ordersBlocks: IOrderBlock[],
  ordersList: IOrder[],
  markets: IMarket[],
  cities: ICity[],
}

type TDispatchedProps = {
  updateFilter: (filter: IOrdersFilter) => Actions.Action,
  apiReportOrders: (filter: IOrdersFilter, type: EReportFormat) => Actions.Action,
  apiReportOrdersCustomers: (filter: IOrdersFilter, type: EReportFormat) => Actions.Action,
  apiOrdersList: (filter: IOrdersFilter) => Actions.Action,
  filterModal: (data: IModalOrdersFilter) => Actions.Action,
  getCities: () => Actions.Action,
  getMarkets: (cityId?: string) => Actions.Action,
}

enum ETab {
  CURRENT = 1,
  ARCHIVE = 2,
}

enum EDayTab {
  TODAY = 1,
  TOMORROW = 2,
}

type TState = {
  orderPlaceholder: boolean,
  activeTab: ETab,
  dayTab: EDayTab,
  search: string,
  selectOrder?: string,
}

type TProps = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

class OrdersCmp extends React.Component<TProps, TState> {
  ref!: HTMLDivElement
  timer: number | null = null

  constructor(props: TProps) {
    super(props)

    this.state = {
      orderPlaceholder: false,
      activeTab: ETab.CURRENT,
      dayTab: EDayTab.TODAY,
      search: '',
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    const { filter, ordersList, loading } = this.props
    const { orderPlaceholder, activeTab } = this.state
    const refresh =
      activeTab === ETab.CURRENT &&
      ordersList.find((order) => order.statusId === EOrderStatus.DELIVERED || order.statusId === EOrderStatus.CANCELED)

    if (!orderPlaceholder && prevProps.loading) {
      this.setState({ orderPlaceholder: true })
    }

    if (!isEqual(prevProps.filter, filter) || (refresh && !loading)) {
      if (filter.city !== prevProps.filter.city) {
        this.props.getMarkets(filter.city)
      }

      if (!filter.search) {
        this.setState({ search: '' })
      }

      if (refresh) {
        this.selectCurrent()
      }

      this.restartInterval(filter)
    }
  }

  componentDidMount(): void {
    const { filter } = this.props

    this.props.getCities()
    this.props.getMarkets(filter.city)
    this.selectCurrent()

    this.restartInterval(filter)
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    const { ordersList } = this.props
    const { activeTab, selectOrder, search } = this.state
    const order = selectOrder ? ordersList.find((item) => item.id === selectOrder) : null

    return (
      <div className='orders'>
        {this.renderTabs()}
        <div className='orders-container'>
          <div className='orders-container__block'>
            {this.renderHeader()}
            {activeTab === ETab.CURRENT && search.length < 3 && this.renderDayTabs()}
            {this.renderList()}
          </div>
          {!!order && (
            <div className='orders-order'>
              <OrderPanel order={order} />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderTabs = () => {
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state

    return (
      <div className='orders-tabs'>
        <div
          className={classnames('orders-tabs__item', activeTab === ETab.CURRENT && 'orders-tabs__item-active')}
          onClick={this.selectCurrent}
        >
          {formatMessage(messages.CurrentOrders)}
        </div>
        <div
          className={classnames('orders-tabs__item', activeTab === ETab.ARCHIVE && 'orders-tabs__item-active')}
          onClick={this.selectArchive}
        >
          {formatMessage(messages.Archive)}
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { loadingReport } = this.props
    const { formatMessage } = this.props.intl
    const { selectOrder } = this.state
    const isMobile = checkMobile()

    return (
      <div className='orders-header'>
        <div className='orders-header__inputs'>
          {this.renderSearch()}
          {!isMobile && this.renderCity()}
          {!isMobile && this.renderMarket()}
        </div>
        <div className='orders-header__btns' style={{ flexDirection: selectOrder ? 'column' : undefined }}>
          {this.renderFilter()}
          <div className='orders-header__report'>
            <Dropdown
              className='orders-header__report-dropdown'
              text={formatMessage(messages.DownloadReport)}
              loading={loadingReport}
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.downloadReportOrders(EReportFormat.CSV)}>
                  {formatMessage(messages.DownloadReportIn, { format: '.csv' })}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.downloadReportOrders(EReportFormat.EXCEL)}>
                  {formatMessage(messages.DownloadReportIn, { format: 'Excel' })}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.downloadReportOrdersCustomers(EReportFormat.CSV)}>
                  {formatMessage(messages.OrdersByUsers)}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }

  renderFilter = () => {
    const { filter } = this.props
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state
    let activeFilter = false

    if (activeTab === ETab.CURRENT) {
      if (
        !isEqual(filter.statuses, CURRENT_FILTER.statuses) ||
        !isUndefined(filter.b2b) ||
        !isUndefined(filter.seller)
      ) {
        activeFilter = true
      }
    } else {
      if (
        !isEqual(filter.statuses, ARCHIVE_FILTER.statuses) ||
        filter.b2b !== undefined ||
        !isEqual(
          filter.dateDeliveryFrom,
          moment(Date.now() - 6 * DAY_HOUR * HOUR_MINUTE * MINUTE_SEC * SEC).format('YYYY-MM-DD'),
        ) ||
        !isEqual(filter.dateDeliveryTo, moment().format('YYYY-MM-DD'))
      ) {
        activeFilter = true
      }
    }

    return (
      <div className='orders-header__filter' onClick={this.openFilter}>
        <div className='orders-header__filter-content'>
          <div className='orders-header__filter-icon'>
            <FilterIcon />
          </div>
          {activeFilter && <div className='orders-header__filter-badge' />}
        </div>
        {formatMessage(messages.Filter)}
      </div>
    )
  }

  renderSearch = () => {
    const { formatMessage } = this.props.intl
    const { search } = this.state

    return (
      <div className='orders-header__search'>
        <Input
          value={search}
          className='orders-header__search-input'
          labelPosition='right'
          type='text'
          placeholder={formatMessage(messages.SearchByOrders)}
          onChange={this.handleSearch}
        >
          <input />
          <Label className='orders-header__search-label'>
            <Icon className='orders-header__search-icon' name='search' />
          </Label>
        </Input>
      </div>
    )
  }

  renderCity = () => {
    const { cities, filter } = this.props
    const { formatMessage } = this.props.intl

    const options = cities.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='orders-header__city'>
        <Dropdown
          className='orders-header__city-dropdown'
          placeholder={formatMessage(messages.City)}
          value={filter.city}
          search
          selection
          clearable
          options={options}
          onChange={this.changeCity}
        />
      </div>
    )
  }

  renderMarket = () => {
    const { markets, filter } = this.props
    const { formatMessage } = this.props.intl

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='orders-header__market'>
        <Dropdown
          className='orders-header__market-dropdown'
          placeholder={formatMessage(messages.Market)}
          value={filter.market}
          search
          selection
          clearable
          options={options}
          onChange={this.changeMarket}
        />
      </div>
    )
  }

  renderDayTabs = () => {
    const { formatMessage } = this.props.intl
    const { dayTab } = this.state

    return (
      <div className='orders-day-tabs'>
        <div
          className={classnames('orders-day-tabs__item', dayTab === EDayTab.TODAY && 'orders-day-tabs__item-active')}
          onClick={this.selectToday}
        >
          {formatMessage(messages.Today)}, {moment().format('D MMM')}
        </div>
        <div
          className={classnames('orders-day-tabs__item', dayTab === EDayTab.TOMORROW && 'orders-day-tabs__item-active')}
          onClick={this.selectTomorrow}
        >
          {formatMessage(messages.Tomorrow)}, {moment().add(1, 'day').format('D MMM')}
        </div>
      </div>
    )
  }

  renderList = () => {
    const { ordersBlocks, loading } = this.props
    const { activeTab, orderPlaceholder } = this.state

    return (
      <div className='orders-list' ref={this.saveRef} onScroll={this.checkEnd}>
        {orderPlaceholder
          ? ordersBlocks.map((item, i) => {
              const pre = i > 0 ? ordersBlocks[i - 1] : null
              const renderDate =
                activeTab === ETab.ARCHIVE &&
                !!pre &&
                moment(pre.deliveryDate).format('YYYY-MM-DD') !== moment(item.deliveryDate).format('YYYY-MM-DD')

              return this.renderOrdersBlock(item, renderDate || (activeTab === ETab.ARCHIVE && i === 0))
            })
          : this.orderPlaceholder()}
        {loading && (
          <div className='orders-list__loader'>
            <Loader active inline />
          </div>
        )}
      </div>
    )
  }

  renderOrdersBlock = (ordersBlock: IOrderBlock, date = false) => {
    const { formatMessage } = this.props.intl
    let diffDateText = moment(ordersBlock.deliveryDate).format('D MMMM YYYY')

    if (date) {
      const diffDate = moment().diff(ordersBlock.deliveryDate, 'day')

      if (diffDate === 0) {
        diffDateText = `${formatMessage(messages.Today)}, ${diffDateText}`
      } else if (diffDate === 1) {
        diffDateText = `${formatMessage(messages.Yesterday)}, ${diffDateText}`
      }
    }

    return (
      <React.Fragment key={ordersBlock.index}>
        {date && <div className='orders-list__date'>{diffDateText}</div>}
        <OrdersBlock
          selectedOrderId={this.state.selectOrder}
          ordersBlock={ordersBlock}
          onSelectOrder={this.selectOrder}
        />
      </React.Fragment>
    )
  }

  selectCurrent = () => {
    this.checkFilterUpdate({
      ...CURRENT_FILTER,
      offset: 0,
      dateDeliveryFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      dateDeliveryTo: moment().format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
    })

    this.dropState()
    this.setState({ activeTab: ETab.CURRENT, dayTab: EDayTab.TODAY })
  }

  selectArchive = () => {
    this.checkFilterUpdate({
      ...ARCHIVE_FILTER,
      offset: 0,
      dateDeliveryFrom: moment(Date.now() - 6 * DAY_HOUR * HOUR_MINUTE * MINUTE_SEC * SEC).format('YYYY-MM-DD'),
      dateDeliveryTo: moment().format('YYYY-MM-DD'),
    })

    this.dropState()
    this.setState({ activeTab: ETab.ARCHIVE })
  }

  selectToday = () => {
    this.checkFilterUpdate({
      ...this.props.filter,
      dateDeliveryFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      dateDeliveryTo: moment().format('YYYY-MM-DD'),
    })

    this.setState({ dayTab: EDayTab.TODAY })
  }

  selectTomorrow = () => {
    this.checkFilterUpdate({
      ...this.props.filter,
      dateDeliveryFrom: moment().add(1, 'day').format('YYYY-MM-DD'),
      dateDeliveryTo: undefined,
    })

    this.setState({ dayTab: EDayTab.TOMORROW })
  }

  checkFilterUpdate = (newFilter: IOrdersFilter) => {
    const { filter } = this.props

    if (!isEqual(filter, newFilter)) {
      this.props.updateFilter(newFilter)
    }
  }

  orderPlaceholder = () => {
    const items: React.ReactNode[] = []

    times(8, (i) =>
      items.push(
        <Segment key={i} className='orders-list__placeholder-item' style={{ borderLeftColor: 'gray' }}>
          <Placeholder fluid>
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='short' />
            <Placeholder.Line length='very short' />
            <Placeholder.Line length='long' />
            <Placeholder.Line length='short' />
            <Placeholder.Line length='very short' />
          </Placeholder>
        </Segment>,
      ),
    )

    return items
  }

  restartInterval = (filter: IOrdersFilter) => {
    if (this.timer) {
      clearInterval(this.timer)
    }

    this.props.apiOrdersList({
      ...filter,
      force: true,
    })

    if (this.state.activeTab === ETab.CURRENT) {
      this.timer = Number(
        setInterval(() => {
          this.props.apiOrdersList({
            ...filter,
            offset: 0,
            force: true,
          })
        }, 45000),
      )
    }
  }

  saveRef = (ref: HTMLDivElement) => {
    this.ref = ref
  }

  selectOrder = (order: IOrder) => {
    const { selectOrder } = this.state
    const isMobile = checkMobile()

    if (isMobile) {
      this.props.history.push(`${LOCATION_ORDERS}/${order.id}`)
    }

    this.setState({ selectOrder: selectOrder === order.id ? undefined : order.id })
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { filter } = this.props
    const { activeTab, search } = this.state
    const { value } = data
    const searchString = value.trim()

    this.setState({ search: value })

    if (searchString.length >= 3) {
      this.checkFilterUpdate({
        statuses: filter.statuses,
        offset: 0,
        search: searchString,
        // dateTo: moment().format('YYYY-MM-DD'),
        // ...(activeTab === ETab.CURRENT && { dateDeliveryTo: moment().format('YYYY-MM-DD') }),
        // ...(activeTab === ETab.CURRENT && {
        //   dateDeliveryFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        // }),
      })
    } else if (search.length >= 3 && (!searchString || (searchString && searchString.length < 3))) {
      this.checkFilterUpdate({
        statuses: filter.statuses,
        offset: 0,
        dateTo: moment().format('YYYY-MM-DD'),
        ...(activeTab === ETab.CURRENT && { dateDeliveryTo: moment().format('YYYY-MM-DD') }),
        ...(activeTab === ETab.CURRENT && {
          dateDeliveryFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        }),
        ...(activeTab === ETab.ARCHIVE && {
          dateDeliveryFrom: moment(Date.now() - 6 * DAY_HOUR * HOUR_MINUTE * MINUTE_SEC * SEC).format('YYYY-MM-DD'),
        }),
      })
    }
  }

  changeCity = (event: any, data: any) => {
    const { filter } = this.props
    const { value } = data

    this.checkFilterUpdate({ offset: 0, ...filter, city: value })
  }

  changeMarket = (event: any, data: any) => {
    const { filter } = this.props
    const { value } = data

    this.checkFilterUpdate({ offset: 0, ...filter, market: value })
  }

  dropState = () => {
    this.setState({ search: '' })
  }

  openFilter = () => {
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state

    this.props.filterModal({
      type: EModalType.MODAL_ORDERS_FILTER,
      size: 'mini',
      style: { width: '405px', maxWidth: '90%', borderRadius: '16px' },
      close: true,
      props: {
        type: activeTab === ETab.CURRENT ? EOrdersFilter.CURRENT : EOrdersFilter.ARCHIVE,
        title: formatMessage(messages.OrderFilter),
      },
    })
  }

  downloadReportOrders = (type: EReportFormat) => {
    const { filter } = this.props
    this.props.apiReportOrders(
      {
        ...filter,
        dateFrom: filter.dateDeliveryFrom,
        dateTo: filter.dateDeliveryTo,
      },
      type,
    )
  }

  downloadReportOrdersCustomers = (type: EReportFormat) => {
    const { filter } = this.props
    this.props.apiReportOrdersCustomers(
      {
        ...filter,
        dateFrom: filter.dateDeliveryFrom,
        dateTo: filter.dateDeliveryTo,
      },
      type,
    )
  }

  checkEnd = () => {
    const { ordersList, loading, loaded, filter } = this.props
    const { scrollHeight, offsetHeight, scrollTop } = this.ref

    if (!loaded && !loading && scrollHeight - offsetHeight - scrollTop < 300 && !(ordersList.length % ORDERS_LIMIT)) {
      this.props.apiOrdersList({
        ...filter,
        offset: ordersList.length,
      })
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loaded, loading, filter, ordersList, total } = s.orders
  const tomorrow = moment().add(1, 'day')

  return {
    loaded,
    loading,
    total,
    filter,
    ordersList,
    ordersBlocks: groupByBlock(
      ordersList,
      filter.dateDeliveryFrom === tomorrow.format('YYYY-MM-DD') ? tomorrow.toDate() : undefined,
    ),
    loadingReport: s.reports.loadingReportOrders,
    markets: s.markets.markets || [],
    cities: s.markets.cities || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiReportOrders: (filter: IOrdersFilter, type: EReportFormat) =>
    dispatch(
      Actions.action(Actions.API_REPORT_ORDERS, {
        ...filter,
        type,
      }),
    ),
  apiReportOrdersCustomers: (filter: IOrdersFilter, type: EReportFormat) =>
    dispatch(
      Actions.action(Actions.API_REPORT_ORDERS_CUSTOMERS, {
        ...filter,
        type,
      }),
    ),
  apiOrdersList: (filter: IOrdersFilter) => dispatch(Actions.action(Actions.API_ORDERS_LIST, filter)),
  filterModal: (data: IModalOrdersFilter) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  updateFilter: (filter: IOrdersFilter) =>
    dispatch(
      Actions.action(Actions.ORDERS_FILTER, {
        ...filter,
      }),
    ),
  getCities: () =>
    dispatch(
      Actions.action(Actions.API_CITIES, {
        include_all: 'True',
      }),
    ),
  getMarkets: (cityId?: string) =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        city: cityId,
        include_all: 'True',
      }),
    ),
})

export const Orders = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrdersCmp))
