import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, DropdownProps, Form, Icon } from 'semantic-ui-react'

import './index.scss'

import { EOrderStatus, ICourier, ICouriersFilter, IOrder } from '../../../types/TClient'
import * as TApi from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { convertRole } from '../../../utils/courierUtils'

type TOwnProps = {
  order: IOrder,
  disabled?: boolean,
}

type TConnectedProps = {
  updateLoading: boolean,
  loading: boolean,
  couriers: ICourier[],
}

type TDispatchedProps = {
  apiCouriersList(filter: ICouriersFilter): Actions.Action,
  apiAssignOrder(data: TApi.AssignOrderReq): Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  assignCourierActive: boolean,
  courier?: string,
}

class OrderCourierCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      assignCourierActive: false,
    }
  }

  componentDidMount() {
    const { order } = this.props

    this.props.apiCouriersList({ market: order.marketId })
  }

  render() {
    const { order, disabled } = this.props
    const { formatMessage } = this.props.intl
    const { assignCourierActive } = this.state
    const { courierPhone } = order
    const editableCourier = !disabled && order.statusId < EOrderStatus.DELIVERED
    const isCourier = !!courierPhone

    return (
      <div className='order__courier'>
        <div className='order__block-title'>
          {formatMessage(messages.Courier)}
          {editableCourier && isCourier && !assignCourierActive && this.renderEditBtn(this.activeAssignOrder)}
        </div>
        {assignCourierActive && this.renderAssignField()}
        {isCourier && !assignCourierActive && this.renderInfo()}
        {!disabled && !isCourier && !assignCourierActive && (
          <>
            {order.statusId < EOrderStatus.DELIVERED ? (
              <Button className='order__btn' color='blue' onClick={this.activeAssignOrder}>
                {formatMessage(messages.AssignCourier)}
              </Button>
            ) : (
              <div className='order__block-text'>{formatMessage(messages.NotBeenAssigned)}</div>
            )}
          </>
        )}
        {disabled && !isCourier && !assignCourierActive && (
          <div className='order__block-text'>{formatMessage(messages.NotAssigned)}</div>
        )}
      </div>
    )
  }

  renderAssignField = () => {
    const { updateLoading, couriers } = this.props
    const { formatMessage } = this.props.intl
    const { courier } = this.state

    const options = couriers.map((c) => ({
      key: c.id,
      value: c.id,
      description: convertRole(c.role),
      text: this.getCourierName(c.phone, c.firstName, c.lastName),
    }))

    return (
      <Form className='order__courier-form'>
        <Form.Dropdown
          search
          selection
          placeholder={formatMessage(messages.SelectCourier)}
          value={courier}
          options={options}
          onChange={this.onChangeCourier}
        />
        <Button
          className='order__btn'
          loading={updateLoading}
          disabled={updateLoading}
          color='blue'
          onClick={this.assignOrder}
        >
          {formatMessage(messages.Save)}
        </Button>
        <div
          className='order__btn-outline'
          onClick={() => this.setState({ assignCourierActive: false, courier: undefined })}
        >
          {formatMessage(messages.Cancel)}
        </div>
      </Form>
    )
  }

  renderInfo = () => {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const { courierFirstName, courierLastName, courierPhone } = order

    return (
      <div className='order__block-row'>
        <div className='order__block-item'>
          <div className='order__block-label'>{formatMessage(messages.Name)}</div>
          <div className='order__block-text'>
            {courierFirstName ? `${courierFirstName} ${courierLastName || ''}` : '–'}
          </div>
        </div>
        <div className='order__block-item'>
          <div className='order__block-label'>{formatMessage(messages.Phone)}</div>
          <div className='order__block-text'>{courierPhone}</div>
        </div>
      </div>
    )
  }

  renderEditBtn = (action: () => any, color?: string) => {
    return (
      <div className='order__edit-btn' onClick={action} style={{ backgroundColor: color }}>
        <Icon className='order__edit-btn__icon' name='pencil alternate' />
      </div>
    )
  }

  getCourierName = (phone: string, firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
      return phone
    }
    return `${firstName || ''} ${lastName || ''} (${phone})`
  }

  activeAssignOrder = () => {
    const { order } = this.props

    this.setState({ assignCourierActive: true, courier: order.courierId || undefined })
  }

  assignOrder = () => {
    const { order } = this.props
    const { courier } = this.state

    if (courier) {
      this.props.apiAssignOrder({
        orderId: order.id,
        courierId: courier,
      })

      this.setState({ assignCourierActive: false, courier: undefined })
    }
  }

  onChangeCourier = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data

    this.setState({ courier: String(value) })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading, updateLoading } = s.orders

  return {
    updateLoading,
    loading,
    couriers: s.couriers.couriersList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiAssignOrder: (data: TApi.AssignOrderReq) => dispatch(Actions.action(Actions.API_ORDER_ASSIGN, data)),
})

export const OrderCourier = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderCourierCmp))
