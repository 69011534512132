import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './styles.scss'

import { ICourier } from '../../types/TClient'

import messages from '../../localization/messages'
import { formatPhoneNumber, getCourierStatus } from '../../utils/courierUtils'

type TProps = {
  courier: ICourier,
} & WrappedComponentProps

const _CourierCard: React.FC<TProps> = ({ courier, intl }) => {
  const { id, num, firstName, lastName, phone, numActiveOrders, role, isOnShift, isBusy } = courier
  const status = getCourierStatus(role, isOnShift, isBusy)

  return (
    <div key={`${id}`} className='courier-card'>
      <div>
        <div className='courier-card__header'>
          <div className='courier-card__header__name'>
            <span className='courier-card__header__name-number'>№{num}</span>
            {` ${firstName || ''} ${lastName || ''}`}
          </div>
          <div className='courier-card__header__status' style={{ color: status.textColor, background: status.color }}>
            {status.title}
          </div>
        </div>
        <div className='courier-card__info'>{formatPhoneNumber(phone)}</div>
      </div>
      <div>
        {intl.formatMessage(messages.OrdersProgress)}:
        <span className='courier-card__in-work-count'>{' ' + numActiveOrders}</span>
      </div>
    </div>
  )
}

export const CourierCard = injectIntl(_CourierCard)
