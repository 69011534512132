import { of as observableOf, EMPTY } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'

import * as TApi from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import {
  URL_COURIERS_LIST,
  URL_COURIERS_LOCATIONS,
  URL_COURIER_SHIFTS_UPDATE,
  URL_COURIER_SHIFTS,
} from '../../modules/network/urls'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import { convertCourierFromApi, convertCourierLocationFromApi, convertShiftSlotFromApi } from '../../utils/courierUtils'

const couriersListEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCouriersList>(a$, Actions.API_COURIERS_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_COURIERS_LIST, {
          ...authRequestConfig(),
          params: {
            ...(c.data.id && { id: c.data.id }),
            ...(c.data.offset && { offset: c.data.offset }),
            ...(c.data.market && { market: c.data.market }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCouriersListResp }) => {
            if (resp.data && Array.isArray(resp.data.couriers)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.COURIERS_LIST, {
                  couriers: resp.data.couriers.map(convertCourierFromApi),
                }),
              )
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const couriersLocationsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCouriersLocations>(a$, Actions.API_COURIERS_LOCATIONS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_COURIERS_LOCATIONS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.ids && { ids: c.data.ids.join(',') }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCouriersLocationsResp }) => {
            if (resp.data && Array.isArray(resp.data.couriersLocations)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.COURIERS_LOCATIONS, {
                  couriersLocations: resp.data.couriersLocations.map(convertCourierLocationFromApi),
                }),
              )
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const courierShiftsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCourierShifts>(a$, Actions.API_COURIER_SHIFTS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_COURIER_SHIFTS, {
          ...authRequestConfig(),
          params: {
            ...c.data,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCourierShiftResp }) => {
            if (resp.data && resp.data.shiftSlots) {
              const couriers = Array.from(new Set(resp.data.shiftSlots.map((item) => item.courierId)))
              const actions: Actions.Action[] = []

              for (const courier of couriers) {
                actions.push(
                  Actions.action(Actions.COURIER_UPDATE_SHIFTS, {
                    courierId: courier,
                    date: c.data.date,
                    shiftSlots: resp.data.shiftSlots
                      .filter((item) => item.courierId === courier)
                      .map(convertShiftSlotFromApi),
                  }),
                )
              }

              return observableOf<Actions.Action>(...actions)
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateShiftEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateCourierShifts>(a$, Actions.API_UPDATE_COURIER_SHIFTS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_COURIER_SHIFTS_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiUpdateCourierShiftsResp }) => {
            if (resp.data && resp.data.shiftSlots) {
              const actions: Actions.Action[] = [
                Actions.action(Actions.COURIER_UPDATE_SHIFTS, {
                  courierId: c.data.courierId,
                  date: c.data.date,
                  shiftSlots: resp.data.shiftSlots.map(convertShiftSlotFromApi),
                }),
              ]

              return observableOf<Actions.Action>(...actions)
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const couriersEpics: EpicFunc[] = [couriersListEpic, couriersLocationsEpic, courierShiftsEpic, updateShiftEpic]
