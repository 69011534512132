import { EDeliverySlotType, IMarket, ISlot, POLYGON_COLOR, TDeliveryType } from '../types/TClient'
import { IMarketApi, ISlotApi } from '../types/TApi'

import { intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'
import { convertCurrency } from './productsUtils'

export const convertMarketFromApi = (market: IMarketApi): IMarket => {
  return {
    id: market.id,
    name: market.name,
    city: market.city,
    city_id: market.city_id,
    city_tz: market.city_tz,
    address: market.address,
    active: market.active,
    delivery_end_time: market.delivery_end_time,
    delivery_radius: market.delivery_radius,
    delivery_start_time: market.delivery_start_time,
    latitude: market.latitude,
    longitude: market.longitude,
    max_delivery_radius: market.max_delivery_radius,
    open_hours_end: market.open_hours_end,
    open_hours_start: market.open_hours_start,
    self_pickup_end_time: market.self_pickup_end_time,
    self_pickup_start_time: market.self_pickup_start_time,
    currency: convertCurrency(market.currency_code),
    delivery_areas: market.delivery_areas,
    categories: market.categories,
  }
}

export const sortByName = (m1: IMarket, m2: IMarket) => m1.name.localeCompare(m2.name, 'ru', { sensitivity: 'base' })

export const getColorByPolygonNum = (num: number) => {
  switch (num) {
    case 1:
      return POLYGON_COLOR.GREEN
    case 2:
      return POLYGON_COLOR.ORANGE
    case 3:
      return POLYGON_COLOR.RED
    case 4:
      return POLYGON_COLOR.BLUE
    case 5:
      return POLYGON_COLOR.PURPLE
    default:
      return POLYGON_COLOR.DEFAULT
  }
}

export const getDeliverySlotTypeName = (type: EDeliverySlotType, numBlock: number) => {
  switch (type) {
    case EDeliverySlotType.ASAP:
      return intlMessage(messages.AsSoonAsPossible)
    case EDeliverySlotType.BY_SLOTS:
      if (numBlock === 1) {
        return intlMessage(messages.BySlotsThroughOne)
      } else if (numBlock === 2) {
        return intlMessage(messages.BySlotsThroughTwo)
      }

      return intlMessage(messages.BySlotNearest)
    case EDeliverySlotType.BY_DAY:
      return intlMessage(messages.AllDay)
  }
}

export const getOtherDeliverySlotType = (types: EDeliverySlotType[]): TDeliveryType | null => {
  if (!types.includes(EDeliverySlotType.BY_DAY)) {
    return {
      type: EDeliverySlotType.BY_DAY,
      price: 79,
    }
  }

  if (!types.includes(EDeliverySlotType.BY_SLOTS)) {
    return {
      type: EDeliverySlotType.BY_SLOTS,
      numBlockSlot: 0,
      price: 79,
    }
  }

  if (!types.includes(EDeliverySlotType.ASAP)) {
    return {
      type: EDeliverySlotType.ASAP,
      price: 149,
    }
  }

  return null
}

export const apiSlotToClient = (slot: ISlotApi): ISlot => ({
  id: slot.id || '',
  from: (slot.timeStart || '00:00').slice(0, 5),
  to: (slot.timeEnd || '00:00').slice(0, 5),
  error: [],
})

export const clientSlotToApi = (market: string, slot: ISlot): ISlotApi => ({
  market,
  id: slot.id,
  timeStart: slot.from,
  timeEnd: slot.to,
  isActive: slot.isActive === undefined ? true : slot.isActive,
})
