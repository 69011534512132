import { Reducer } from 'redux'

import { ICity, IMarket, ISlot, EImageType, ISlotInfo } from '../../types/TClient'

import * as Actions from '../actions'

export type MutableStateMarkets = {
  loading: boolean,
  loadingUpdateArea: boolean,
  updateSlotsErrorModal: boolean,
  loadingCategories: boolean,
  loadingUpdateCategories: boolean,
  loadingImage: boolean,
  categorySavedImageUrl: string,
  cities: ICity[],
  markets: IMarket[],
  slots: ISlot[],
  slotsInfo: ISlotInfo[],
}

export type StateMarkets = Readonly<MutableStateMarkets>

const defStateMarkets: StateMarkets = {
  loading: false,
  loadingUpdateArea: false,
  loadingCategories: false,
  updateSlotsErrorModal: false,
  loadingUpdateCategories: false,
  loadingImage: false,
  cities: [],
  markets: [],
  slots: [],
  slotsInfo: [],
  categorySavedImageUrl: '',
}

export const markets: Reducer<StateMarkets, Actions.Action> = (s = defStateMarkets, a): StateMarkets => {
  switch (a.type) {
    case Actions.API_CITIES:
    case Actions.API_MARKETS:
      return {
        ...s,
        loading: true,
      }
    case Actions.API_UPLOAD_IMAGE: {
      if (a.data.type === EImageType.MARKET_CATEGORY_IMAGE) {
        return {
          ...s,
          loadingImage: true,
        }
      }

      return s
    }
    case Actions.CITIES:
      return {
        ...s,
        loading: false,
        cities: a.data.cities,
      }
    case Actions.MARKETS:
      return {
        ...s,
        loading: false,
        markets: a.data.markets,
      }
    case Actions.API_SAVE_DELIVERY_AREAS:
      return {
        ...s,
        loadingUpdateArea: true,
      }
    case Actions.MARKET_DELIVERY_AREA_ADD:
      return {
        ...s,
        markets: s.markets.map((market) => {
          if (market.id === a.data.market) {
            return {
              ...market,
              delivery_areas: [...(market.delivery_areas || []), a.data.deliveryArea],
            }
          }

          return market
        }),
      }
    case Actions.MARKET_DELIVERY_AREA_UPDATE:
      return {
        ...s,
        markets: s.markets.map((market) => {
          if (market.id === a.data.market) {
            const newDeliveryAreas = (market.delivery_areas || []).map((item) => {
              if (item.id === a.data.deliveryArea.id) {
                return a.data.deliveryArea
              }

              return item
            })

            return {
              ...market,
              delivery_areas: newDeliveryAreas,
            }
          }

          return market
        }),
      }
    case Actions.MARKET_DELIVERY_AREA_REMOVE:
      return {
        ...s,
        markets: s.markets.map((market) => {
          if (market.id === a.data.market) {
            return {
              ...market,
              delivery_areas: (market.delivery_areas || []).filter((item) => item.id !== a.data.deliveryAreaId),
            }
          }

          return market
        }),
      }
    case Actions.MARKET_DELIVERY_AREAS:
      return {
        ...s,
        loadingUpdateArea: false,
      }
    case Actions.UPDATE_SLOTS:
    case Actions.SLOTS:
      return {
        ...s,
        slots: a.data,
      }
    case Actions.UPDATE_SLOTS_ERROR_MODAL:
      return {
        ...s,
        updateSlotsErrorModal: a.data,
      }
    case Actions.API_MARKET_CATEGORIES:
      return {
        ...s,
        loadingCategories: true,
      }
    case Actions.MARKET_CATEGORIES:
      return {
        ...s,
        loadingCategories: false,
        markets: s.markets.map((market) => {
          if (market.id === a.data.market) {
            return {
              ...market,
              categories: a.data.categories,
            }
          }

          return market
        }),
      }
    case Actions.MARKET_CATEGORY_CREATE:
      return {
        ...s,
        markets: s.markets.map((market) => {
          if (market.id === a.data.market) {
            const newCategories = (market.categories || []).map((item) => {
              if (item.index >= a.data.category.index) {
                return {
                  ...item,
                  index: item.index + 1,
                }
              }

              return item
            })

            return {
              ...market,
              categories: [...newCategories, a.data.category].sort((a, b) => a.index - b.index),
            }
          }

          return market
        }),
      }
    case Actions.MARKET_CATEGORY_UPDATE:
      return {
        ...s,
        markets: s.markets.map((market) => {
          if (market.id === a.data.market) {
            const newCategories = (market.categories || [])
              .map((item) => {
                if (item.id === a.data.category.id) {
                  return a.data.category
                }

                return item
              })
              .sort((a, b) => a.index - b.index)

            return {
              ...market,
              categories: newCategories,
            }
          }

          return market
        }),
      }
    case Actions.MARKET_CATEGORY_SAVED_IMAGE:
      return {
        ...s,
        loadingImage: false,
        categorySavedImageUrl: a.data.imageUrl,
      }
    case Actions.MARKET_CATEGORY_REMOVE:
      return {
        ...s,
        markets: s.markets.map((market) => {
          if (market.id === a.data.market) {
            return {
              ...market,
              categories: (market.categories || []).filter((item) => item.id !== a.data.categoryId),
            }
          }

          return market
        }),
      }
    case Actions.SLOTS_INFO: {
      return {
        ...s,
        slotsInfo: a.data,
      }
    }
  }
  return s
}
