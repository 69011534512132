import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header, Form, Checkbox } from 'semantic-ui-react'
import classnames from 'classnames'
import moment from 'moment'
import { uniqBy } from 'lodash'

import './index.scss'

import {
  IAnalyticsFilter,
  ICity,
  ICurrencyPairs,
  ICustomersFilter,
  IMarket,
  ITotalOrders,
} from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { IModalOrdersFilter } from '../../../../store/reducers/modals'
import * as Actions from '../../../../store/actions'
import { State } from '../../../../store/reducer'
import { MarketAnalytics } from './MarketAnalytics'
import { Button } from '../../../../components/Button'
import { AvatarIcon, Cart2Icon } from '../../../../components/Icons'
import { calcTotal } from '../../../../utils/analyticsUtils'

type TConnectedProps = {
  loading: boolean,
  filter: IAnalyticsFilter,
  totalOrders: ITotalOrders[],
  currencyPairs: ICurrencyPairs,
  newCustomers: number,
  markets: IMarket[],
  cities: ICity[],
}

type TDispatchedProps = {
  apiTotalOrders: (filter: IAnalyticsFilter) => Actions.Action,
  apiCustomers: (filter: ICustomersFilter) => Actions.Action,
  filterModal: (data: IModalOrdersFilter) => Actions.Action,
  getCities: () => Actions.Action,
  getMarkets: (cityId: string) => Actions.Action,
}

type Props = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type IState = {
  changed: boolean,
  city?: string,
  market?: string,
  dateFrom?: string,
  dateTo?: string,
  b2b?: boolean,
  b2c?: boolean,
  mobile?: boolean,
  web?: boolean,
}

const FIELD_CITY = 'order_city'
const FIELD_MARKET = 'order_market'
const FIELD_DATE_FROM = 'order_date_from'
const FIELD_DATE_TO = 'order_date_to'

class AnalyticsCmp extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      changed: false,
      dateFrom: props.filter.dateFrom,
      dateTo: props.filter.dateTo,
      city: props.filter.city || '',
      market: props.filter.market || '',
      b2b: true,
      b2c: true,
      mobile: true,
      web: true,
    }
  }

  componentDidMount(): void {
    const { filter } = this.props

    if (filter.city) {
      this.props.getMarkets(filter.city)
    }

    this.props.apiTotalOrders(filter)
    this.props.apiCustomers(filter)
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='analytics'>
        <div className='analytics-title'>
          <Header as='h1'>{formatMessage(messages.Analytics)}</Header>
        </div>
        <div className='analytics-header__wrapper'>{this.renderHeader()}</div>
        <div className='analytics-container'>
          {/*{this.renderTotal()}*/}
          {this.renderTotalOrders()}
        </div>
      </div>
    )
  }

  renderHeader() {
    const { formatMessage } = this.props.intl
    const { b2b, b2c, mobile, web, dateFrom, dateTo } = this.state
    const isWeek = moment(dateTo).diff(dateFrom, 'week', true) <= 1
    const isMonth = moment(dateTo).diff(dateFrom, 'month', true) <= 1 && !isWeek
    const isQuarter = moment(dateTo).diff(dateFrom, 'quarter', true) <= 1 && !isWeek && !isMonth
    const isYear = !isWeek && !isMonth && !isQuarter
    const constDateTo = moment().subtract(1, 'day')

    return (
      <div className='analytics-header'>
        <div className='analytics-filter__period'>
          <div className='analytics-filter__period-title'>
            {formatMessage(messages.Period)}
            <div className='analytics-filter__period-title__sub'>
              <div
                className={classnames(
                  'analytics-filter__period-title__sub-item',
                  isWeek && 'analytics-filter__period-title__sub-item-active',
                )}
                onClick={() =>
                  this.setState({
                    dateTo: constDateTo.format('YYYY-MM-DD'),
                    dateFrom: moment().subtract(1, 'week').format('YYYY-MM-DD'),
                  })
                }
              >
                {formatMessage(messages.Week)}
              </div>
              <div
                className={classnames(
                  'analytics-filter__period-title__sub-item',
                  isMonth && 'analytics-filter__period-title__sub-item-active',
                )}
                onClick={() =>
                  this.setState({
                    dateTo: constDateTo.format('YYYY-MM-DD'),
                    dateFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                  })
                }
              >
                {formatMessage(messages.Month)}
              </div>
              <div
                className={classnames(
                  'analytics-filter__period-title__sub-item',
                  isQuarter && 'analytics-filter__period-title__sub-item-active',
                )}
                onClick={() =>
                  this.setState({
                    dateTo: constDateTo.format('YYYY-MM-DD'),
                    dateFrom: moment().subtract(1, 'quarter').format('YYYY-MM-DD'),
                  })
                }
              >
                {formatMessage(messages.Quarter)}
              </div>
              <div
                className={classnames(
                  'analytics-filter__period-title__sub-item',
                  isYear && 'analytics-filter__period-title__sub-item-active',
                )}
                onClick={() =>
                  this.setState({
                    dateTo: constDateTo.format('YYYY-MM-DD'),
                    dateFrom: moment().subtract(1, 'year').format('YYYY-MM-DD'),
                  })
                }
              >
                {formatMessage(messages.Year)}
              </div>
            </div>
          </div>
          <div className='analytics-filter__period-value'>
            {this.renderDateFrom()}
            <div className='analytics-filter__period-divider' />
            {this.renderDateTo()}
          </div>
        </div>
        <div className='analytics-filter__types'>
          <div className='analytics-filter__divider-vertical' />
          <div className='analytics-filter__users'>
            <div className='analytics-filter__users-title'>{formatMessage(messages.ClientType)}</div>
            <Checkbox
              className='analytics-filter__users-type'
              label={'B2B'}
              checked={b2b}
              onChange={() => this.setState(({ b2b, b2c }) => ({ b2b: !b2b, b2c: b2b && !b2c ? true : b2c }))}
            />
            <Checkbox
              className='analytics-filter__users-type'
              label={'B2C'}
              checked={b2c}
              onChange={() => this.setState(({ b2b, b2c }) => ({ b2c: !b2c, b2b: !b2b && b2c ? true : b2b }))}
            />
          </div>
          <div className='analytics-filter__divider-vertical' />
          <div className='analytics-filter__orders'>
            <div className='analytics-filter__orders-title'>{formatMessage(messages.Source)}</div>
            <Checkbox
              className='analytics-filter__orders-type'
              label={formatMessage(messages.MobileApp)}
              checked={mobile}
              onChange={() =>
                this.setState(({ mobile, web }) => ({ mobile: !mobile, web: mobile && !web ? true : web }))
              }
            />
            <Checkbox
              className='analytics-filter__orders-type'
              label={formatMessage(messages.WebSite)}
              checked={web}
              onChange={() =>
                this.setState(({ mobile, web }) => ({ web: !web, mobile: !mobile && web ? true : mobile }))
              }
            />
          </div>
        </div>
        <div className='analytics-header__btn'>
          <Button title={formatMessage(messages.Refresh)} onClick={this.updateFilter} />
        </div>
      </div>
    )
  }

  renderDateFrom = () => {
    const { dateFrom } = this.state

    return (
      <div className='analytics-filter__field'>
        <Form.Input
          className='analytics-filter__field-value'
          value={dateFrom}
          name={FIELD_DATE_FROM}
          type='date'
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderDateTo = () => {
    const { dateTo } = this.state

    return (
      <div className='analytics-filter__field'>
        <Form.Input
          className='analytics-filter__field-value'
          value={dateTo}
          name={FIELD_DATE_TO}
          type='date'
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderTotal = () => {
    const { newCustomers, totalOrders } = this.props
    const { formatMessage } = this.props.intl
    const totalInfo = totalOrders[0]
    const totalOrdersValue = (totalInfo?.totalNumDelivered || 0) + (totalInfo?.totalNumCanceled || 0)
    // const totalCommissionValue = convertPriceByCurrency(
    //   totalInfo?.totalCommission || 0,
    //   currencyPairs,
    //   totalInfo ? totalInfo.currency.code : DEF_CURRENCY.code,
    //   TOTAL_INFO_CURRENCY,
    // )

    return (
      <div className='total-info'>
        <div className='total-info__header'>{formatMessage(messages.GeneralAll)}</div>
        <div className='total-info__content'>
          <div className='total-info__block'>
            <div>
              <div className='total-info__block-title'>{formatMessage(messages.TotalOrders)}</div>
              <div className='total-info__block-value'>{totalOrdersValue}</div>
            </div>
            <div className='total-info__block-icon'>
              <Cart2Icon width={40} height={40} color='white' />
            </div>
          </div>
          {/*<div className='total-info__block'>*/}
          {/*  <div>*/}
          {/*    <div className='total-info__block-title'>{formatMessage(messages.CommissionPlatform)}</div>*/}
          {/*    <div className='total-info__block-value'>*/}
          {/*      {formatPrice(totalCommissionValue, convertCurrency(TOTAL_INFO_CURRENCY))}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className='total-info__block-icon'>*/}
          {/*    <DiscountIcon width={40} height={40} color='white' />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className='total-info__block'>
            <div>
              <div className='total-info__block-title'>{formatMessage(messages.NewUsers)}</div>
              <div className='total-info__block-value'>{newCustomers}</div>
            </div>
            <div className='total-info__block-icon'>
              <AvatarIcon width={40} height={40} color='white' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTotalOrders = () => {
    const { totalOrders } = this.props
    const cities = uniqBy(
      totalOrders.map((item) => ({ id: item.cityId, name: item.cityName })),
      'id',
    )

    return (
      <div className='total-orders'>
        <div className='total-orders__content'>
          {cities.map((city) =>
            this.renderByCity(
              city,
              totalOrders.filter((item) => item.cityId === city.id),
            ),
          )}
        </div>
      </div>
    )
  }

  renderByCity = (city: { id: string, name: string }, ordersByCity: ITotalOrders[]) => {
    const isTotal = city.id === '0'

    return (
      <div key={city.id} className='market-analytics__container'>
        <div className={classnames('market-analytics', isTotal && 'market-analytics__total')}>
          <div className='market-analytics__title'>{city.name}</div>
          <div className='market-analytics__content-container'>
            {ordersByCity.map((item) => this.renderMarketsInfo(item))}
          </div>
        </div>
      </div>
    )
  }

  renderMarketsInfo = (ordersInfo: ITotalOrders) => {
    return <MarketAnalytics key={ordersInfo.marketId + ordersInfo.currency?.code} marketInfo={ordersInfo} />
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_CITY:
        this.setState({ city: value, market: '' })
        this.props.getMarkets(value)
        break
      case FIELD_MARKET:
        this.setState({ market: value })
        break
      case FIELD_DATE_FROM:
        this.setState({ dateFrom: value })
        break
      case FIELD_DATE_TO:
        this.setState({ dateTo: value })
        break
    }
  }

  updateFilter = () => {
    const { filter } = this.props
    const { dateFrom, dateTo, b2b, b2c, mobile, web } = this.state

    const newFilter = {
      ...filter,
      ...(dateFrom && { dateFrom }),
      ...(dateTo && { dateTo }),
      ...((b2b || b2c) && !(b2b && b2c) ? { b2b } : {}),
      ...((mobile || web) && !(mobile && web) ? { web } : {}),
    }

    this.props.apiTotalOrders(newFilter)
    this.props.apiCustomers(newFilter)
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading, filter, totalOrders, currencyPairs, newCustomers } = s.analytics
  const totalCurs: { [cur: string]: ITotalOrders } = calcTotal(totalOrders, currencyPairs)

  return {
    loading,
    filter,
    newCustomers,
    currencyPairs,
    totalOrders: [...Object.values(totalCurs), ...totalOrders],
    markets: s.markets.markets || [],
    cities: s.markets.cities || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiTotalOrders: (filter: IAnalyticsFilter) => dispatch(Actions.action(Actions.API_TOTAL_ORDERS, filter)),
  apiCustomers: (filter: ICustomersFilter) => dispatch(Actions.action(Actions.API_CUSTOMERS, filter)),
  filterModal: (data: IModalOrdersFilter) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  getCities: () =>
    dispatch(
      Actions.action(Actions.API_CITIES, {
        include_all: 'True',
      }),
    ),
  getMarkets: (cityId: string) =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        city: cityId,
        include_all: 'True',
      }),
    ),
})

export const Analytics = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnalyticsCmp))
