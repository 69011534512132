import React from 'react'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header, Card } from 'semantic-ui-react'

import './index.scss'

import messages from '../../../../localization/messages'
import {
  LOCATION_SELLERS_REGISTER,
  LOCATION_NEW_SELLER,
  LOCATION_SELLERS_IN_PROGRESS,
  LOCATION_SELLERS_MARKETS,
  LOCATION_SELLERS_COPY_PRODUCTS,
} from '../../../../utils/locationUtils'

type Props = RouteComponentProps & WrappedComponentProps

type SellersOperation = {
  title: string,
  description: string,
  link: string,
}

class SellersCmp extends React.Component<Props> {
  render() {
    const { formatMessage } = this.props.intl

    const operations: SellersOperation[] = [
      {
        title: formatMessage(messages.RegisterSeller),
        description: formatMessage(messages.FullRegistrationSeller),
        link: `${LOCATION_SELLERS_REGISTER}/${LOCATION_NEW_SELLER}`,
      },
      {
        title: formatMessage(messages.InProcessRegistration),
        description: formatMessage(messages.ListTemplatesInProgress),
        link: `${LOCATION_SELLERS_IN_PROGRESS}`,
      },
      {
        title: formatMessage(messages.ShowcasesSellers),
        description: formatMessage(messages.InformationSellersProducts),
        link: `${LOCATION_SELLERS_MARKETS}`,
      },
      {
        title: formatMessage(messages.CopyProducts),
        description: formatMessage(messages.CopyingAllProductsInfo),
        link: `${LOCATION_SELLERS_COPY_PRODUCTS}`,
      },
    ]

    return (
      <div className='sellers'>
        <Header as='h1'>{formatMessage(messages.Sellers)}</Header>
        <div className='sellers__operations'>{operations.map(this.renderItem)}</div>
      </div>
    )
  }

  renderItem = (item: SellersOperation) => {
    return (
      <Card as='a' key={item.title} onClick={() => this.toOperation(item.link)}>
        <Card.Content>
          <Card.Header>{item.title}</Card.Header>
          <Card.Description>{item.description}</Card.Description>
        </Card.Content>
      </Card>
    )
  }

  toOperation = (link: string) => {
    this.props.history.push(link)
  }
}

export const Sellers = injectIntl(SellersCmp)
