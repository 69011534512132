import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const MapRouteIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M18 6v.01M18 12.999l-3.5-5a4 4 0 1 1 7 0l-3.5 5Z'
      />
      <path
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M10.5 4.75 9 4 3 7v13l6-3 6 3 6-3v-2M9 4v13M15 15v5'
      />
    </svg>
  )
}
