import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import { INotification } from '../../../types/TClient'
import { ApiNotificationRemoveReq } from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'

type TOwnProps = {
  title: string,
  notification: INotification,
  onClose: () => void,
}

type TDispatchedProps = {
  deleteNotification: (data: ApiNotificationRemoveReq) => Actions.Action,
}

type Props = TOwnProps & TDispatchedProps & WrappedComponentProps

class DeleteNotificationCmp extends React.Component<Props> {
  render() {
    const { notification } = this.props
    const { formatMessage } = this.props.intl

    return (
      <>
        <Header icon>
          <Icon name='trash alternate' />
          <p>{formatMessage(messages.SureRemoveNotification, { title: notification.title })}</p>
        </Header>
        <Modal.Actions>
          <Button basic color='green' inverted onClick={this.props.onClose}>
            <Icon name='remove' /> {formatMessage(messages.Cancel)}
          </Button>
          <Button color='red' inverted onClick={this.deleteNotification}>
            <Icon name='checkmark' /> {formatMessage(messages.Confirm)}
          </Button>
        </Modal.Actions>
      </>
    )
  }

  deleteNotification = () => {
    const { notification } = this.props

    this.props.onClose()
    this.props.deleteNotification({ notification })
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  deleteNotification: (data: ApiNotificationRemoveReq) =>
    dispatch(Actions.action(Actions.API_NOTIFICATION_REMOVE, data)),
})

export const DeleteNotificationModal = connect(null, mapDispatchToProps)(injectIntl(DeleteNotificationCmp))
