import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const InfoUpdateIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={color}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M20 11A8.1 8.1 0 0 0 4.5 9M4 5v4h4M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4M12 9v3M12 15h.01'
      />
    </svg>
  )
}
