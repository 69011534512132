import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Header, Input } from 'semantic-ui-react'
import Select, { OptionsType } from 'react-select'
import moment from 'moment'

import './index.scss'

import {
  ENotificationDay,
  ENotificationFrequency,
  ENotificationType,
  ENotificationUsers,
  IClientProfile,
  INotification,
} from '../../../../types/TClient'
import * as TApi from '../../../../types/TApi'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { LOCATION_NEW_NOTIFICATION, LOCATION_NOTIFICATIONS } from '../../../../utils/locationUtils'
import { frequencyToString, typeToString, userGroupToString } from '../../../../utils/notificationsUtils'

type TConnectedProps = {
  loading: boolean,
  clients: IClientProfile[],
  notification?: INotification,
  // errorApi?: string,
}

type TDispatchedProps = {
  createNotification: (data: TApi.ApiNotificationCreateReq) => Actions.Action,
  updateNotification: (data: TApi.ApiNotificationUpdateReq) => Actions.Action,
  getClients: (data: { phone: string }) => Actions.Action,
  resetClients: () => Actions.Action,
}

type TProps = RouteComponentProps<{ type: string, id: string }> &
  TConnectedProps &
  TDispatchedProps &
  WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type TState = {
  title: string,
  body: string,
  times: string[],
  type: ENotificationType,
  days: ENotificationDay[],
  date?: string,
  frequency: ENotificationFrequency,
  frequencyWeek?: number,
  userGroup: ENotificationUsers,
  changed: boolean,
  errors: FieldError[],
  selectClients: Array<{ value: number, label: string }>,
}

const FIELD_TITLE = 'title'
const FIELD_BODY = 'body'
const FIELD_TYPE = 'type'
const FIELD_DAYS = 'days'
const FIELD_TIMES = 'times'
const FIELD_DATE = 'date'
const FIELD_FREQUENCY = 'frequency'
const FIELD_FREQUENCY_WEEK = 'frequency_week'
const FIELD_USER_GROUP = 'user_group'
const FIELD_CLIENTS = 'clients'

const CLIENTS_LIMIT = 3

class NotificationCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    const { notification } = props

    this.state = {
      title: notification?.title || '',
      body: notification?.body || '',
      times: notification?.times || [''],
      type: notification?.type || ENotificationType.DAILY,
      days: notification?.days || [],
      date: notification?.date ? moment(notification.date).format('YYYY-MM-DD') : undefined,
      frequency: notification?.frequency || ENotificationFrequency.WEEKLY,
      frequencyWeek: notification?.frequencyWeek || 2,
      userGroup: notification?.userGroup || ENotificationUsers.ALL_CUSTOMER,
      selectClients: [],
      changed: false,
      errors: [],
    }
  }

  render() {
    const { loading } = this.props
    const { formatMessage } = this.props.intl
    const { changed, type } = this.state

    return (
      <div className='notification'>
        {this.renderBack()}
        <Header as='h1'>{formatMessage(messages.Notification)}</Header>

        <Form size='large'>
          {/*{errorApi && this.renderErrorMessage()}*/}

          {this.renderTitle()}
          {this.renderBody()}
          {this.renderTimes()}
          {this.renderType()}
          {type === ENotificationType.SPECIFIC_DAY && this.renderDate()}
          {type === ENotificationType.BY_DAY_WEEK && this.renderDays()}
          {type === ENotificationType.BY_DAY_WEEK && this.renderFrequency()}
          {this.renderUserGroup()}

          <div className='notification__actions'>
            {changed && (
              <Button
                className='notification__actions-btn'
                loading={loading}
                disabled={loading}
                color='blue'
                size='large'
                onClick={this.handleSubmit}
              >
                {formatMessage(messages.Save)}
              </Button>
            )}
          </div>
        </Form>
      </div>
    )
  }

  renderBack() {
    const { formatMessage } = this.props.intl

    return (
      <div className='notification__back'>
        <Button size='large' onClick={this.back}>
          {formatMessage(messages.Back)}
        </Button>
      </div>
    )
  }

  renderTitle = () => {
    const { formatMessage } = this.props.intl
    const { title } = this.state
    const error = this.checkError(FIELD_TITLE)

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.Header)}:</div>
        <Form.Input
          value={title}
          className='notification__field-value'
          name={FIELD_TITLE}
          placeholder=''
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderBody = () => {
    const { formatMessage } = this.props.intl
    const { body } = this.state
    const error = this.checkError(FIELD_BODY)

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.Text)}:</div>
        <Form.Input
          value={body}
          className='notification__field-value'
          name={FIELD_BODY}
          placeholder=''
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderTimes = () => {
    const { formatMessage } = this.props.intl
    const { times } = this.state
    const error = this.checkError(FIELD_TIMES)

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.DispatchTime)}:</div>
        {error && error.message && <div className='notification__field-error'>{error.message}</div>}
        {times.map((time, i) => {
          return (
            <div className='notification__times' key={i}>
              <Input
                className='notification__field-value'
                value={time}
                name={FIELD_TIMES}
                type='time'
                onChange={this.handleChange}
                label='UTC+3 (Msk)'
                labelPosition='right'
              />
              {/*<div className='notification__times-actions'>*/}
              {/*  {*/}
              {/*    times.length > 1 &&*/}
              {/*    <div className='notification__times-actions__minus' onClick={() => this.removeTimes(i)}>*/}
              {/*      <Icon name='minus circle' size='large'/>*/}
              {/*    </div>*/}
              {/*  }*/}
              {/*  {*/}
              {/*    i === times.length - 1 &&*/}
              {/*    <div className='notification__times-actions__plus' onClick={this.addTimes}>*/}
              {/*      <Icon name='plus circle' size='large'/>*/}
              {/*    </div>*/}
              {/*  }*/}
              {/*</div>*/}
            </div>
          )
        })}
      </div>
    )
  }

  renderDate = () => {
    const { formatMessage } = this.props.intl
    const { date } = this.state
    const error = this.checkError(FIELD_DATE)

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.Date)}:</div>
        <Form.Input
          className='notification__field-value'
          value={date}
          name={FIELD_DATE}
          type='date'
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderType = () => {
    const { formatMessage } = this.props.intl
    const { type } = this.state

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.Type)}:</div>
        <Form.Radio
          label={typeToString(ENotificationType.DAILY)}
          name={FIELD_TYPE}
          value={ENotificationType.DAILY}
          checked={type === ENotificationType.DAILY}
          onChange={this.handleChange}
        />
        <Form.Radio
          label={typeToString(ENotificationType.BY_DAY_WEEK)}
          name={FIELD_TYPE}
          value={ENotificationType.BY_DAY_WEEK}
          checked={type === ENotificationType.BY_DAY_WEEK}
          onChange={this.handleChange}
        />
        <Form.Radio
          label={typeToString(ENotificationType.SPECIFIC_DAY)}
          name={FIELD_TYPE}
          value={ENotificationType.SPECIFIC_DAY}
          checked={type === ENotificationType.SPECIFIC_DAY}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderDays = () => {
    const { formatMessage } = this.props.intl
    const { days } = this.state
    const options = [
      { key: '1', text: formatMessage(messages.Monday), value: ENotificationDay.MONDAY },
      { key: '2', text: formatMessage(messages.Tuesday), value: ENotificationDay.TUESDAY },
      { key: '3', text: formatMessage(messages.Wednesday), value: ENotificationDay.WEDNESDAY },
      { key: '4', text: formatMessage(messages.Thursday), value: ENotificationDay.THURSDAY },
      { key: '5', text: formatMessage(messages.Friday), value: ENotificationDay.FRIDAY },
      { key: '6', text: formatMessage(messages.Saturday), value: ENotificationDay.SATURDAY },
      { key: '7', text: formatMessage(messages.Sunday), value: ENotificationDay.SUNDAY },
    ]

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.DaysOfWeek)}:</div>
        <Form.Dropdown
          className='notification__field-value'
          multiple
          selection
          value={days}
          name={FIELD_DAYS}
          options={options}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderFrequency = () => {
    const { formatMessage } = this.props.intl
    const { frequency, frequencyWeek } = this.state
    const error = this.checkError(FIELD_FREQUENCY_WEEK)

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.Periodicity)}:</div>
        {/*<Form.Radio*/}
        {/*  label={frequencyToString(ENotificationFrequency.ONCE)}*/}
        {/*  name={FIELD_FREQUENCY}*/}
        {/*  value={ENotificationFrequency.ONCE}*/}
        {/*  checked={frequency === ENotificationFrequency.ONCE}*/}
        {/*  onChange={this.handleChange}*/}
        {/*/>*/}
        <Form.Radio
          label={frequencyToString(ENotificationFrequency.WEEKLY)}
          name={FIELD_FREQUENCY}
          value={ENotificationFrequency.WEEKLY}
          checked={frequency === ENotificationFrequency.WEEKLY}
          onChange={this.handleChange}
        />
        <div>
          <Form.Radio
            label={`${frequencyToString(ENotificationFrequency.BY_X_WEEK)} (${formatMessage(
              messages.StartingFromCurrent,
            )})`}
            name={FIELD_FREQUENCY}
            value={ENotificationFrequency.BY_X_WEEK}
            checked={frequency === ENotificationFrequency.BY_X_WEEK}
            onChange={this.handleChange}
          />
          {frequency === ENotificationFrequency.BY_X_WEEK && (
            <div className='notification__field'>
              <div className='notification__field-label'>{formatMessage(messages.HowManyWeeksRepeat)}:</div>
              <Form.Input
                type='number'
                value={frequencyWeek}
                className='notification__field-value'
                name={FIELD_FREQUENCY_WEEK}
                placeholder='2'
                onChange={this.handleChange}
                error={error ? error.message : false}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderUserGroup = () => {
    const { formatMessage } = this.props.intl
    const { userGroup } = this.state

    return (
      <div className='notification__field'>
        <div className='notification__field-label'>{formatMessage(messages.UserGroup)}:</div>
        <Form.Radio
          label={userGroupToString(ENotificationUsers.ALL_CUSTOMER)}
          name={FIELD_USER_GROUP}
          value={ENotificationUsers.ALL_CUSTOMER}
          checked={userGroup === ENotificationUsers.ALL_CUSTOMER}
          onChange={this.handleChange}
        />
        <Form.Radio
          label={userGroupToString(ENotificationUsers.REGISTER_CUSTOMER)}
          name={FIELD_USER_GROUP}
          value={ENotificationUsers.REGISTER_CUSTOMER}
          checked={userGroup === ENotificationUsers.REGISTER_CUSTOMER}
          onChange={this.handleChange}
        />
        <Form.Radio
          label={userGroupToString(ENotificationUsers.NOT_REGISTER_CUSTOMER)}
          name={FIELD_USER_GROUP}
          value={ENotificationUsers.NOT_REGISTER_CUSTOMER}
          checked={userGroup === ENotificationUsers.NOT_REGISTER_CUSTOMER}
          onChange={this.handleChange}
        />
        <Form.Radio
          label={userGroupToString(ENotificationUsers.SPECIFIC_USERS)}
          name={FIELD_USER_GROUP}
          value={ENotificationUsers.SPECIFIC_USERS}
          checked={userGroup === ENotificationUsers.SPECIFIC_USERS}
          onChange={this.handleChange}
        />
        {userGroup === ENotificationUsers.SPECIFIC_USERS && this.renderSelectUsers()}
      </div>
    )
  }

  renderSelectUsers = () => {
    const { clients, resetClients } = this.props
    const { formatMessage } = this.props.intl
    const { selectClients } = this.state
    const users = clients.map((c) => ({ value: c.id, label: c.phone }))
    const error = this.checkError(FIELD_CLIENTS)

    return (
      <div className='notification__users-container'>
        {error && <div className='notification__field-error'>{error.message}</div>}
        <Select
          isMulti
          options={users}
          value={selectClients}
          name={FIELD_CLIENTS}
          placeholder={formatMessage(messages.EnterClientNumberOrName)}
          onInputChange={this.onChangeClientsText}
          onChange={this.onChangeClients}
          onBlur={resetClients}
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            border: 1,
            colors: {
              ...theme.colors,
              primary: '#85b7d9',
            },
          })}
        />
      </div>
    )
  }

  onChangeClients = (selectClients: OptionsType<{ value: number, label: string }>) => {
    const { resetClients } = this.props

    if (Array.isArray(selectClients)) {
      this.setState(
        {
          selectClients,
          errors: this.removeError(FIELD_CLIENTS),
        },
        resetClients,
      )
    }
  }

  onChangeClientsText = (phone: string) => {
    const { getClients } = this.props

    if (phone.length <= CLIENTS_LIMIT) {
      return
    }

    getClients({ phone })
  }

  addTimes = () => {
    this.setState({ times: [...this.state.times, ''] })
  }

  removeTimes = (i: number) => {
    const newTimes = [...this.state.times]
    newTimes.splice(i, 1)

    this.setState({ times: newTimes })
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (value === ENotificationUsers.SPECIFIC_USERS) {
      this.setState({ selectClients: [] })
    }

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_TITLE:
        this.setState({ title: value, errors: this.removeError(FIELD_TITLE) })
        break
      case FIELD_BODY:
        this.setState({ body: value, errors: this.removeError(FIELD_BODY) })
        break
      case FIELD_TIMES:
        this.setState({ times: [value], errors: this.removeError(FIELD_TIMES) })
        break
      case FIELD_TYPE:
        this.setState({ type: value, errors: this.removeError(FIELD_TYPE) })
        break
      case FIELD_DATE:
        this.setState({ date: value, errors: this.removeError(FIELD_DATE) })
        break
      case FIELD_DAYS: {
        const days = [...value]
        days.sort()

        this.setState({ days, errors: this.removeError(FIELD_DAYS) })
        break
      }
      case FIELD_FREQUENCY:
        this.setState({ frequency: +value, errors: this.removeError(FIELD_FREQUENCY) })
        break
      case FIELD_FREQUENCY_WEEK:
        this.setState({
          frequencyWeek: isFinite(value) && +value > 2 ? parseInt(value) : 2,
          errors: this.removeError(FIELD_FREQUENCY_WEEK),
        })
        break
      case FIELD_USER_GROUP:
        this.setState({ userGroup: +value, errors: this.removeError(FIELD_USER_GROUP) })
        break
    }
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  back = () => {
    this.props.history.goBack()
  }

  handleSubmit = () => {
    const { notification } = this.props
    const { formatMessage } = this.props.intl
    const { type, times, date, days, frequency, frequencyWeek, userGroup, selectClients } = this.state
    const errors: FieldError[] = []
    const title = this.state.title.trim()
    const body = this.state.body.trim()
    const pushUserGroup = userGroup === ENotificationUsers.SPECIFIC_USERS ? undefined : userGroup
    const pushClients = userGroup === ENotificationUsers.SPECIFIC_USERS ? selectClients.map((c) => c.value) : undefined

    if (userGroup === ENotificationUsers.SPECIFIC_USERS && !selectClients.length) {
      errors.push({ field: FIELD_CLIENTS, message: formatMessage(messages.FieldRequired) })
    }

    if (!title) {
      errors.push({ field: FIELD_TITLE, message: formatMessage(messages.FieldRequired) })
    } else if (title.length < 2 || title.length > 96) {
      errors.push({
        field: FIELD_TITLE,
        message: formatMessage(messages.NotLessAndNotMoreCharacters, {
          less: 2,
          more: 96,
        }),
      })
    }

    if (!body) {
      errors.push({ field: FIELD_BODY, message: formatMessage(messages.FieldRequired) })
    } else if (body.length < 4 || body.length > 356) {
      errors.push({
        field: FIELD_BODY,
        message: formatMessage(messages.NotLessAndNotMoreCharacters, {
          less: 4,
          more: 356,
        }),
      })
    }

    if (!times || !times.find((item) => item.match(/\d+:\d+/))) {
      errors.push({ field: FIELD_TIMES, message: formatMessage(messages.IncorrectFormat) })
    }

    if (type === ENotificationType.DAILY) {
      if (errors.length > 0) {
        this.setState({ errors })
      } else {
        if (notification) {
          this.props.updateNotification({
            id: notification.id,
            userGroup: pushUserGroup,
            clients: pushClients,
            title,
            body,
            type,
            times,
          })
        } else {
          this.props.createNotification({
            userGroup: pushUserGroup,
            clients: pushClients,
            title,
            body,
            type,
            times,
          })
        }
      }
    }

    if (type === ENotificationType.SPECIFIC_DAY) {
      if (!date) {
        errors.push({ field: FIELD_DATE, message: formatMessage(messages.FieldRequired) })
      } else {
        if (errors.length > 0) {
          this.setState({ errors })
        } else {
          if (notification) {
            this.props.updateNotification({
              id: notification.id,
              userGroup: pushUserGroup,
              clients: pushClients,
              date: new Date(date),
              title,
              body,
              type,
              times,
            })
          } else {
            this.props.createNotification({
              userGroup: pushUserGroup,
              date: new Date(date),
              clients: pushClients,
              title,
              body,
              type,
              times,
            })
          }
        }
      }
    }

    if (type === ENotificationType.BY_DAY_WEEK) {
      if (!days || days.length === 0) {
        errors.push({ field: FIELD_DAYS, message: formatMessage(messages.FieldRequired) })
      }

      if (errors.length > 0) {
        this.setState({ errors })
      } else {
        if (notification) {
          this.props.updateNotification({
            id: notification.id,
            userGroup: pushUserGroup,
            clients: pushClients,
            title,
            body,
            type,
            times,
            days,
            frequency,
            frequencyWeek,
          })
        } else {
          this.props.createNotification({
            userGroup: pushUserGroup,
            clients: pushClients,
            title,
            body,
            type,
            times,
            days,
            frequency,
            frequencyWeek,
          })
        }
      }
    }
  }
}

const mapStateToProps = (s: State, own: TProps): TConnectedProps => {
  const { notificationsList } = s.notifications
  const { type, id } = own.match.params
  const notification =
    id === LOCATION_NEW_NOTIFICATION
      ? undefined
      : notificationsList.find((notification) => notification.type === +type && notification.id === id)

  if (!notification && id !== LOCATION_NEW_NOTIFICATION) {
    own.history.push(`${LOCATION_NOTIFICATIONS}`)
  }

  return {
    loading: s.notifications.loading,
    clients: s.notifications.clients,
    notification,
    // errorApi: s.notifications.errorApi,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  createNotification: (data: TApi.ApiNotificationCreateReq) =>
    dispatch(Actions.action(Actions.API_NOTIFICATION_CREATE, data)),
  updateNotification: (data: TApi.ApiNotificationUpdateReq) =>
    dispatch(Actions.action(Actions.API_NOTIFICATION_UPDATE, data)),
  getClients: (data: { phone: string }) => dispatch(Actions.action(Actions.API_CLIENTS, data)),
  resetClients: () => dispatch(Actions.actionEmpty(Actions.CLIENTS_RESET)),
})

export const Notification = connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationCmp))
