import React from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header } from 'semantic-ui-react'

import './index.scss'

import { IMarket } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import * as Actions from '../../../../store/actions'
import { State } from '../../../../store/reducer'
import { LOCATION_MARKETS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  markets: IMarket[],
}

type TDispatchedProps = {
  getMarkets: () => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & RouteComponentProps & WrappedComponentProps

class MarketsCmp extends React.Component<TProps> {
  componentDidMount(): void {
    this.props.getMarkets()
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='markets'>
        <Header as='h1'>{formatMessage(messages.Markets)}</Header>
        <div className='markets-content'>
          <div className='markets__list-header'>
            <div className='markets__list-header__item'>{formatMessage(messages.City)}</div>
            <div className='markets__list-header__item'>{formatMessage(messages.MarketName)}</div>
          </div>
          {this.renderMarkets()}
        </div>
      </div>
    )
  }

  renderMarkets = () => {
    const { markets } = this.props

    return <div className='markets__list'>{markets.map(this.renderItem)}</div>
  }

  renderItem = (market: IMarket, index: number) => {
    return (
      <div key={market.id} className='markets__item-wrapper'>
        {index > 0 && <div className='markets__item-divider' />}
        <div className='markets__item' onClick={() => this.toMarket(market.id)}>
          <div className='markets__item-city'>{market.city}</div>
          <div className='markets__item-name'>{market.name}</div>
        </div>
      </div>
    )
  }

  toMarket = (id: string) => {
    this.props.history.push(`${LOCATION_MARKETS}/${id}`)
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  return {
    markets: s.markets.markets || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getMarkets: () =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        include_all: 'True',
      }),
    ),
})

export const Markets = connect(mapStateToProps, mapDispatchToProps)(injectIntl(MarketsCmp))
