import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import './index.scss'

import { IOrder, OrderHistoryActionType, TLogAction } from '../../../types/TClient'

import { State } from '../../../store/reducer'
import {
  AssignAction,
  ChangeProductAction,
  CollectedAction,
  PickAction,
  PickProductAction,
  RemoveProductAction,
  StatusAction,
} from './actions'
import messages from '../../../localization/messages'
import { ArrowMore } from '../../Icons'
import { PayedAction } from './PayedAction'

type TProps = {
  order: IOrder,
}

export const OrderHistory: React.FC<TProps> = ({ order }) => {
  const historyData = useSelector<State, TLogAction[]>((s) => s.orders.orderHistoryMap.get(order.id) || [])
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    setOpened(false)
  }, [order.id])

  const history = historyData.map((item) => {
    switch (item.type) {
      case OrderHistoryActionType.STATUS:
        return <StatusAction {...item} order={order} />
      case OrderHistoryActionType.ASSIGN:
        return <AssignAction {...item} order={order} />
      case OrderHistoryActionType.PICK_PRODUCT:
        return <PickProductAction {...item} />
      case OrderHistoryActionType.CHANGE_PRODUCT:
        return <ChangeProductAction {...item} />
      case OrderHistoryActionType.REMOVE_PRODUCT:
        return <RemoveProductAction {...item} />
      case OrderHistoryActionType.COLLECTED:
        return <CollectedAction {...item} />
      case OrderHistoryActionType.PICKED:
        return <PickAction {...item} order={order} />
      case OrderHistoryActionType.PAYED:
        return <PayedAction {...item} />
      default:
        return null
    }
  })

  return (
    <div className='order-history'>
      <div className='order-history__title' onClick={() => setOpened((prev) => !prev)}>
        <FormattedMessage {...messages.OrderHistory} /> ({historyData.length})
        <div className={classNames('order-history__icon', opened && 'order-history__icon-open')}>
          <ArrowMore color='#1D1D1B' />
        </div>
      </div>
      {opened && <div>{history}</div>}
    </div>
  )
}
