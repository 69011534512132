import { of as observableOf, EMPTY } from 'rxjs'
import Axios from 'axios-observable'
import { catchError, mergeMap } from 'rxjs/operators'

import { ITag } from '../../types/TClient'

import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import * as Actions from '../actions'
import { URL_CREATE_TAG, URL_GET_TAGS, URL_REMOVE_TAGS, URL_UPDATE_TAG } from '../../modules/network/urls'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'

const getTagsEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiGetTags>(a$, Actions.API_GET_TAGS), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.get(URL_GET_TAGS, {
          ...authRequestConfig(),
          params: {
            market_id: a.data.market_id,
          },
        }).pipe(
          mergeMap((resp: { data: ITag[] }) => {
            const tags: ITag[] = (resp.data || []).map((t) => ({
              id: t.id,
              tag: t.tag,
              market: t.market,
              color: t.color,
              position: t.position,
              image_url: t.image_url,
              products_count: t.products_count,
            }))

            return observableOf<Actions.Action>(Actions.action(Actions.GET_TAGS, tags))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const createTagEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiCreateTag>(a$, Actions.API_CREATE_TAG), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_CREATE_TAG, a.data, authRequestConfig()).pipe(
          mergeMap((resp: { data: ITag }) => {
            const tag: ITag = {
              id: resp.data.id,
              tag: resp.data.tag,
              color: resp.data.color,
              position: resp.data.position,
              image_url: resp.data.image_url,
              market: resp.data.market,
              products_count: resp.data.products_count,
            }

            return observableOf<Actions.Action>(Actions.action(Actions.CREATE_TAG, tag))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateTagEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiUpdateTag>(a$, Actions.API_UPDATE_TAG), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_UPDATE_TAG, a.data, authRequestConfig()).pipe(
          mergeMap((resp: { data: ITag }) => {
            const tag: ITag = {
              id: resp.data.id,
              tag: resp.data.tag,
              color: resp.data.color,
              position: resp.data.position,
              image_url: resp.data.image_url,
              market: resp.data.market,
              products_count: resp.data.products_count,
            }

            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_TAG, tag))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const removeTagEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiRemoveTag>(a$, Actions.API_REMOVE_TAG), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_REMOVE_TAGS, { tagId: a.data }, authRequestConfig()).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(Actions.action(Actions.REMOVE_TAG, a.data))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const tagsEpics: EpicFunc[] = [getTagsEpic, createTagEpic, updateTagEpic, removeTagEpic]
