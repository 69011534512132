import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Icon, Input, InputOnChangeData, Label, Loader, Placeholder, Segment } from 'semantic-ui-react'
import { isEqual, times } from 'lodash'
import classnames from 'classnames'

import './index.scss'

import { EModerateProductStatus, ICity, IModerationProduct, IModerationProductsFilter } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { ModerateProductItem } from '../../../../components/ModerateProductItem'
import { ProductPanel } from './ProductPanel'
import { InfoIcon } from '../../../../components/Icons'
import { checkMobile } from '../../../../utils/deviceUtils'
import { LOCATION_MODERATION_PRODUCTS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  filter: IModerationProductsFilter,
  total: number,
  list: IModerationProduct[],
  cities: ICity[],
}

type TDispatchedProps = {
  updateFilter: (filter: IModerationProductsFilter) => Actions.Action,
  apiProductsList: (filter: IModerationProductsFilter) => Actions.Action,
  getCities: () => Actions.Action,
  getMarkets: (cityId?: string) => Actions.Action,
}

enum ETab {
  CURRENT = 1,
  ARCHIVE = 2,
}

const PRODUCTS_LIMIT = 10
const MODERATION_RULE =
  'https://docs.google.com/document/d/1vMZk4IQtWLer26K2OIccU_-LUlHIMoxY0abJ7lHrnV0/edit#heading=h.moc9sfr9d90j'

type TState = {
  listPlaceholder: boolean,
  search: string,
  activeTab: ETab,
  selectItem?: string,
}

type TProps = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

class ModerationProductsCmp extends React.Component<TProps, TState> {
  ref!: HTMLDivElement
  timer: number | null = null

  constructor(props: TProps) {
    super(props)

    this.state = {
      listPlaceholder: false,
      search: '',
      activeTab: ETab.CURRENT,
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    const { filter } = this.props

    if (!isEqual(prevProps.filter, filter)) {
      if (filter.city !== prevProps.filter.city) {
        this.props.getMarkets(filter.city)
      }

      if (!filter.search) {
        this.setState({ search: '' })
      }

      this.refresh(filter)
    }
  }

  componentDidMount(): void {
    const { filter } = this.props

    this.props.getCities()
    this.props.getMarkets(filter.city)

    this.refresh(filter)
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    const { list } = this.props
    const { selectItem } = this.state
    const item = selectItem ? list.find((item) => item.id === selectItem) : null

    return (
      <div className='moderation-products'>
        {this.renderTabs()}
        <div className='moderation-products-container'>
          <div className='moderation-products-container__block'>
            {this.renderHeader()}
            {this.renderContent()}
          </div>
          {!!item && (
            <div className='moderation-products-product'>
              <ProductPanel product={item} />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderTabs = () => {
    const { total } = this.props
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state

    return (
      <div className='moderation-products-tabs'>
        <div
          className={classnames(
            'moderation-products-tabs__item',
            activeTab === ETab.CURRENT && 'moderation-products-tabs__item-active',
          )}
          onClick={this.selectCurrent}
        >
          {formatMessage(messages.OnModeration)} ({total})
        </div>
        <div
          className={classnames(
            'moderation-products-tabs__item',
            activeTab === ETab.ARCHIVE && 'moderation-products-tabs__item-active',
          )}
          onClick={this.selectArchive}
        >
          {formatMessage(messages.Archive)}
        </div>
        <div className='moderation-products-info'>
          <div className='moderation-products-info__icon'>
            <InfoIcon color='#7677bc' />
          </div>
          <div className='moderation-products-info__text' onClick={this.toModerationRule}>
            {formatMessage(messages.ProductsModerationRules)}
          </div>
        </div>
      </div>
    )
  }

  renderHeader = () => {
    return (
      <div className='moderation-products-header'>
        <div className='moderation-products-header__inputs'>{this.renderSearch()}</div>
      </div>
    )
  }

  renderSearch = () => {
    const { formatMessage } = this.props.intl
    const { search } = this.state

    return (
      <div className='moderation-products-header__search'>
        <Input
          value={search}
          className='moderation-products-header__search-input'
          labelPosition='right'
          type='text'
          placeholder={formatMessage(messages.SearchByProductOrEntity)}
          onChange={this.handleSearch}
        >
          <input />
          <Label className='moderation-products-header__search-label'>
            <Icon className='moderation-products-header__search-icon' name='search' />
          </Label>
        </Input>
      </div>
    )
  }

  // renderCity = () => {
  //   const { cities, filter } = this.props
  // const { formatMessage } = this.props.intl
  //   const options = cities.map((c) => ({ key: c.id, value: c.id, text: c.name }))
  //
  //   return (
  //     <div className='moderation-products-header__city'>
  //       <Dropdown
  //         className='moderation-products-header__city-dropdown'
  //         placeholder={formatMessage(messages.City)}
  //         value={filter.city}
  //         search
  //         selection
  //         clearable
  //         options={options}
  //         onChange={this.changeCity}
  //       />
  //     </div>
  //   )
  // }

  renderContent = () => {
    const { loading } = this.props
    const { listPlaceholder } = this.state

    return (
      <div className='moderation-products-list'>
        {!listPlaceholder ? this.renderList() : this.renderPlaceholder()}
        {loading && (
          <div className='moderation-products-list__loader'>
            <Loader active inline />
          </div>
        )}
      </div>
    )
  }

  renderList = () => {
    const { list } = this.props
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state

    return (
      <div className='moderation-products-list__block' ref={this.saveRef} onScroll={this.checkEnd}>
        <div className='moderation-products-list__header'>
          <div className={classnames('moderation-products-list__header-value', 'moderation-products-list__photo')}>
            {formatMessage(messages.ProductPhoto)}
          </div>
          <div className={classnames('moderation-products-list__header-value', 'moderation-products-list__name')}>
            {formatMessage(messages.ProductName)}
          </div>
          <div className={classnames('moderation-products-list__header-value', 'moderation-products-list__seller')}>
            {formatMessage(messages.Seller)}
          </div>
          <div className={classnames('moderation-products-list__header-value', 'moderation-products-list__actions')}>
            {activeTab === ETab.CURRENT ? formatMessage(messages.Actions) : ''}
          </div>
        </div>
        <div className='moderation-products-list__rows'>{list.map(this.renderItem)}</div>
      </div>
    )
  }

  renderItem = (item: IModerationProduct, index: number) => {
    return (
      <React.Fragment key={item.id}>
        {index > 0 && <div className='moderation-products-list__divider' />}
        <ModerateProductItem
          key={item.id}
          num={index + 1}
          product={item}
          selectedProductId={this.state.selectItem}
          onSelectProduct={this.selectItem}
        />
      </React.Fragment>
    )
  }

  selectCurrent = () => {
    this.checkFilterUpdate({
      statuses: [EModerateProductStatus.NEW],
      offset: 0,
    })

    this.dropState()
    this.setState({ activeTab: ETab.CURRENT })
  }

  selectArchive = () => {
    this.checkFilterUpdate({
      statuses: [EModerateProductStatus.APPROVED, EModerateProductStatus.REJECTED],
      offset: 0,
    })

    this.dropState()
    this.setState({ activeTab: ETab.ARCHIVE })
  }

  dropState = () => {
    this.setState({ search: '' })
  }

  checkFilterUpdate = (newFilter: IModerationProductsFilter) => {
    const { filter } = this.props

    if (!isEqual(filter, newFilter)) {
      this.props.updateFilter({
        ...newFilter,
      })
    }
  }

  renderPlaceholder = () => {
    const items: React.ReactNode[] = []

    times(8, (i) =>
      items.push(
        <Segment key={i} className='moderation-products-list__placeholder-item' style={{ borderLeftColor: 'gray' }}>
          <Placeholder fluid>
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='short' />
            <Placeholder.Line length='very short' />
            <Placeholder.Line length='long' />
            <Placeholder.Line length='short' />
            <Placeholder.Line length='very short' />
          </Placeholder>
        </Segment>,
      ),
    )

    return items
  }

  refresh = (filter: IModerationProductsFilter) => {
    this.props.apiProductsList({
      ...filter,
      force: true,
    })
  }

  saveRef = (ref: HTMLDivElement) => {
    this.ref = ref
  }

  selectItem = (product: IModerationProduct) => {
    const { selectItem } = this.state
    const isMobile = checkMobile()

    if (isMobile) {
      this.props.history.push(`${LOCATION_MODERATION_PRODUCTS}/${product.id}`)
    }

    this.setState({ selectItem: selectItem === product.id ? undefined : product.id })
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { filter } = this.props
    const { search } = this.state
    const { value } = data
    const searchString = value.trim()

    this.setState({ search: value })

    if (searchString.length >= 3) {
      this.checkFilterUpdate({
        ...filter,
        offset: 0,
        search: searchString,
      })
    } else if (search.length >= 3 && (!searchString || (searchString && searchString.length < 3))) {
      this.checkFilterUpdate({
        ...filter,
        offset: 0,
        search: undefined,
      })
    }
  }

  // changeCity = (event: any, data: any) => {
  //   const { filter } = this.props
  //   const { value } = data
  //
  //   this.checkFilterUpdate({ offset: 0, ...filter, city: value })
  // }

  checkEnd = () => {
    const { list, loading, loaded, filter } = this.props
    const { scrollHeight, offsetHeight, scrollTop } = this.ref

    if (!loaded && !loading && scrollHeight - offsetHeight - scrollTop < 300 && !(list.length % PRODUCTS_LIMIT)) {
      this.props.apiProductsList({
        ...filter,
        offset: list.length,
      })
    }
  }

  toModerationRule = () => {
    window.open(MODERATION_RULE)
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loaded, loading, filter, list, totalOnModeration } = s.products.moderationProducts

  return {
    loaded,
    loading,
    total: totalOnModeration,
    filter,
    list,
    cities: s.markets.cities || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiProductsList: (filter: IModerationProductsFilter) =>
    dispatch(Actions.action(Actions.API_PRODUCTS_MODERATION_LIST, filter)),
  updateFilter: (filter: IModerationProductsFilter) =>
    dispatch(
      Actions.action(Actions.MODERATION_PRODUCTS_FILTER, {
        ...filter,
      }),
    ),
  getCities: () =>
    dispatch(
      Actions.action(Actions.API_CITIES, {
        include_all: 'True',
      }),
    ),
  getMarkets: (cityId?: string) =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        city: cityId,
        include_all: 'True',
      }),
    ),
})

export const ModerationProducts = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModerationProductsCmp))
