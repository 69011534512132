import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import { EModerateProductStatus, IModerationProduct } from '../../../types/TClient'
import { ApiUpdateModerationProductReq } from '../../../types/TApi'

import messages from '../../../localization/messages'
import { CheckIcon, CloseIcon } from '../../Icons'
import { EModalType, IModalRejectProduct } from '../../../store/reducers/modals'
import * as Actions from '../../../store/actions'

type TOwnProps = {
  product: IModerationProduct,
}

type TDispatchedProps = {
  apiUpdateModerationProduct(data: ApiUpdateModerationProductReq): Actions.Action,
  rejectProductModal: (data: IModalRejectProduct) => Actions.Action,
}

type TProps = TOwnProps & TDispatchedProps & WrappedComponentProps

class ProductActionsCmp extends React.Component<TProps> {
  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='moderate-product__actions'>
        <div
          className={classnames('moderate-product__actions-btn', 'moderate-product__actions-btn-approve')}
          onClick={this.approveProduct}
        >
          <div className='moderate-product__actions-btn-icon'>
            <CheckIcon width={24} height={24} color='#ffffff' />
          </div>
          <div className='moderate-product__actions-btn-text'>{formatMessage(messages.Approve)}</div>
        </div>
        <div
          className={classnames('moderate-product__actions-btn', 'moderate-product__actions-btn-reject')}
          onClick={this.rejectProduct}
        >
          <div className='moderate-product__actions-btn-icon'>
            <CloseIcon width={20} height={20} color='#ffffff' />
          </div>
          <div className='moderate-product__actions-btn-text'>{formatMessage(messages.Reject)}</div>
        </div>
      </div>
    )
  }

  approveProduct = () => {
    const { product } = this.props

    this.props.apiUpdateModerationProduct({
      id: product.id,
      status: EModerateProductStatus.APPROVED,
    })
  }

  rejectProduct = () => {
    this.props.rejectProductModal({
      type: EModalType.MODAL_REJECT_PRODUCT,
      size: 'mini',
      style: { width: '600px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        product: this.props.product,
      },
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiUpdateModerationProduct: (data: ApiUpdateModerationProductReq) =>
    dispatch(Actions.action(Actions.API_UPDATE_MODERATION_PRODUCT, data)),
  rejectProductModal: (data: IModalRejectProduct) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
})

export const ProductActions = connect(null, mapDispatchToProps)(injectIntl(ProductActionsCmp))
