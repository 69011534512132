import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Dropdown, DropdownProps, Icon } from 'semantic-ui-react'
import classnames from 'classnames'
import moment from 'moment'

import './index.scss'

import * as TApi from '../../../types/TApi'
import { ECourierRole, EOrderStatus, EPaymentType, ICourier, ICouriersFilter, IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { ModalConfirm } from '../../Modal/ModalConfirm'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { byCurrentStatus, checkLate, convertStatus, getColor, localeWeight, round } from '../../../utils/ordersUtils'

type TOwnProps = {
  order: IOrder,
  selectedOrderId?: string,
  onSelectOrder: (order: IOrder) => any,
}

type TConnectedProps = {
  couriers: ICourier[],
}

type TDispatchedProps = {
  changeOrderStatus(orderId: string, status: EOrderStatus): Actions.Action,
  apiOrderUpdate: (data: TApi.ApiUpdateOrderReq) => Actions.Action,
  apiCouriersList(filter: ICouriersFilter): Actions.Action,
  apiAssignOrder(data: TApi.AssignOrderReq): Actions.Action,
  resetError(): void,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  changeStatusModal: boolean,
  changePaymentModal: boolean,
  noPaidModal: boolean,
  status: EOrderStatus,
  newStatus?: EOrderStatus,
  newPaymentType?: EPaymentType,
}

class OrderItemCmp extends React.Component<Props, TState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      changeStatusModal: false,
      changePaymentModal: false,
      noPaidModal: false,
      status: props.order?.statusId || EOrderStatus.NEW,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { order } = this.props

    if (order && order.statusId && (!prevProps.order || order.statusId !== prevProps.order.statusId)) {
      this.setState({ status: order.statusId })
    }
  }

  render = () => {
    const { order, selectedOrderId } = this.props
    const { formatMessage } = this.props.intl
    const deliveryAt = order.deliveryAt || order.completedAt ? moment(order.deliveryAt || order.completedAt) : ''
    const deliveryPlan = order.deliveryEndTime
      ? moment(order.deliveryDate)
          .hours(+order.deliveryEndTime.split(':')[0])
          .minutes(+order.deliveryEndTime.split(':')[1])
      : ''
    const deliveryLate =
      deliveryPlan && order.statusId === EOrderStatus.DELIVERED ? deliveryPlan.diff(deliveryAt, 'minute', true) : 0

    return (
      <div
        className={classnames('order-item-wrapper', order.id === selectedOrderId && 'order-item-wrapper__selected')}
        onClick={() => this.selectOrder(order)}
      >
        <div className={classnames('order-item', deliveryLate <= -1 && 'order-item__late')}>
          {this.renderChangeStatusModal()}
          {this.renderChangePaymentModal()}
          {this.renderNoPaidModal()}
          <div className={classnames('order-item__value', 'order-item__num')}>
            <div className='order-item__num-value'>{order.num}</div>
            <div className='order-item__subvalue'>#{order.id.split('-')[0]}</div>
          </div>
          <div className={classnames('order-item__value', 'order-item__address')}>
            <div className='order-item__address-value'>
              {order.selfPickup ? `${order.marketName} (${order.city}, ${order.marketAddress})` : order.deliveryAddress}
            </div>
            {order.deliveryDistance !== undefined && (
              <div className='order-item__address-distance'>
                ({round(order.deliveryDistance / 1000)}&nbsp;{formatMessage(messages.KmFromMarket).toLowerCase()})
              </div>
            )}
          </div>
          <div className={classnames('order-item__value', 'order-item__weight')}>
            {order.weightGr ? localeWeight(order.weightGr) : ''}
          </div>
          {this.renderCourier(order)}
          {this.renderPayment(order)}
          {this.renderStatus(order)}
        </div>
      </div>
    )
  }

  renderCourier = (order: IOrder) => {
    const { couriers } = this.props
    const editableCourier = order.statusId < EOrderStatus.DELIVERED
    const assignCouriers = couriers.filter((courier) => {
      if (order.statusId === EOrderStatus.NEW) {
        return courier.role === ECourierRole.PICKER || courier.role === ECourierRole.MANAGER
      }

      if (order.statusId === EOrderStatus.IN_ASSEMBLY || order.statusId === EOrderStatus.COURIER) {
        return courier.role === ECourierRole.COURIER || courier.role === ECourierRole.MANAGER
      }

      return false
    })

    const options = assignCouriers.slice(0, 6).map((c) => ({
      key: c.id,
      value: c.id,
      text: this.getCourierName(c.phone, c.firstName, c.lastName),
    }))

    if (!order.courierId) {
      options.unshift({
        key: '–',
        value: '–',
        text: '–',
      })
    }

    return (
      <div className={classnames('order-item__value', 'order-item__courier')} onMouseEnter={this.getCouriers}>
        <div>
          {editableCourier ? (
            <Dropdown
              className='order-item__value'
              text={order.courierFirstName || '–'}
              value={order.courierId || '–'}
              icon={null}
              options={options}
              onChange={this.onChangeCourier}
            />
          ) : (
            <div className='order-item__value'>{order.courierFirstName || '–'}</div>
          )}
          {/* eslint-disable-next-line no-console */}
          {editableCourier && this.renderEditBtn(() => console.log('edit courier'))}
        </div>
      </div>
    )
  }

  renderPayment = (order: IOrder) => {
    const { formatMessage } = this.props.intl
    const editablePayment =
      order.paymentType === EPaymentType.BY_CARD && !order.paid && order.statusId < EOrderStatus.DELIVERED

    const options = [
      { key: EPaymentType.BY_CARD, value: EPaymentType.BY_CARD, text: formatMessage(messages.Card) },
      { key: EPaymentType.CASH, value: EPaymentType.CASH, text: formatMessage(messages.Cash) },
    ]

    return (
      <div className={classnames('order-item__value', 'order-item__payment')}>
        <div>
          {editablePayment ? (
            <Dropdown
              className='order-item__value'
              options={options}
              text={
                order.paymentType === EPaymentType.CASH ? formatMessage(messages.Cash) : formatMessage(messages.Card)
              }
              icon={null}
              onChange={this.onChangePayment}
            />
          ) : (
            <div className='order-item__value'>
              {order.paymentType === EPaymentType.CASH ? formatMessage(messages.Cash) : formatMessage(messages.Card)}
            </div>
          )}
          {/* eslint-disable-next-line no-console */}
          {editablePayment && this.renderEditBtn(() => console.log('edit payment'))}
        </div>
        {order.paymentType === EPaymentType.BY_CARD ? (
          <div className={classnames('order-item__payment-status')}>{this.paymentStatus(order)}</div>
        ) : (
          ''
        )}
      </div>
    )
  }

  renderStatus = (order: IOrder) => {
    const { formatMessage } = this.props.intl
    const { status } = this.state
    const statusColor = getColor(order.statusId)
    const editableStatus = order.statusId < EOrderStatus.DELIVERED
    const statusText =
      order.isCollected && order.statusId < EOrderStatus.COURIER
        ? order.isPicked
          ? formatMessage(messages.Picked)
          : formatMessage(messages.Collected)
        : convertStatus(order.statusId) || '–'

    const options = [
      { key: EOrderStatus.NEW, value: EOrderStatus.NEW, text: formatMessage(messages.New) },
      { key: EOrderStatus.IN_ASSEMBLY, value: EOrderStatus.IN_ASSEMBLY, text: formatMessage(messages.InAssembly) },
      { key: EOrderStatus.COURIER, value: EOrderStatus.COURIER, text: formatMessage(messages.AtCourier) },
      { key: EOrderStatus.DELIVERED, value: EOrderStatus.DELIVERED, text: formatMessage(messages.Delivered) },
      { key: EOrderStatus.CANCELED, value: EOrderStatus.CANCELED, text: formatMessage(messages.Canceled) },
    ].filter((option) => byCurrentStatus(option, status))

    let errorText = ''
    const lateCheck = checkLate(order)

    if (lateCheck) {
      errorText = order.isCollected ? formatMessage(messages.Late) : formatMessage(messages.NotCollected)
    }

    return (
      <div className={classnames('order-item__value', 'order-item__status')}>
        {!!errorText && <div className='order-item__value-error'>{errorText}</div>}
        <div style={{ color: errorText ? undefined : statusColor }}>
          {editableStatus ? (
            <Dropdown
              className={classnames(
                'order-item__value',
                order.isPicked && order.statusId < EOrderStatus.COURIER && 'order-item__value-picked',
              )}
              options={options}
              text={statusText}
              value={order.statusId || EOrderStatus.NEW}
              icon={null}
              onChange={this.onChangeStatus}
            />
          ) : (
            this.renderStatusName()
          )}
          {editableStatus && this.renderEditBtn()}
        </div>
      </div>
    )
  }

  renderStatusName = () => {
    const { order } = this.props
    const { formatMessage } = this.props.intl

    if (order.statusId === EOrderStatus.IN_ASSEMBLY && order.isPicked) {
      return <div className='order-item__value order-item__value-picked'>{formatMessage(messages.Picked)}</div>
    }

    return <div className='order-item__value'>{order.statusName || '–'}</div>
  }

  renderEditBtn = (action?: () => any) => {
    return (
      <div className='order-item__edit-btn' onClick={action}>
        <Icon className='order-item__edit-btn__icon' name='pencil alternate' />
      </div>
    )
  }

  paymentStatus = (order: IOrder) => {
    const { formatMessage } = this.props.intl

    if (order.paymentType === EPaymentType.BY_CARD) {
      if (order.paid && order.statusId === EOrderStatus.CANCELED) {
        return (
          <div className={classnames('order-item__payment-status__status', 'order-item__payment-status__refund')}>
            {formatMessage(messages.Refund)}
          </div>
        )
      }

      if (order.extPaymentUrl !== undefined) {
        return (
          <div
            className={classnames(
              'order-item__payment-status__status',
              order.extPaid ? 'order-item__payment-status__paid' : 'order-item__payment-status__not-paid',
            )}
          >
            {order.extPaid ? formatMessage(messages.ExtraPaid) : formatMessage(messages.Surcharge)}
          </div>
        )
      }

      return (
        <div
          className={classnames(
            'order-item__payment-status__status',
            order.paid ? 'order-item__payment-status__paid' : 'order-item__payment-status__not-paid',
          )}
        >
          {order.paid ? formatMessage(messages.Paid) : formatMessage(messages.NotPaid)}
        </div>
      )
    }
  }

  getCourierName = (phone: string, firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
      return phone
    }
    return `${firstName || ''} ${lastName || ''}`
  }

  renderChangeStatusModal = () => {
    const { formatMessage } = this.props.intl
    const { changeStatusModal, newStatus } = this.state

    if (!newStatus) {
      return
    }

    return (
      <ModalConfirm
        onClose={() => this.setState({ changeStatusModal: false, newStatus: undefined })}
        onOk={() => this.apiChangeStatus(newStatus)}
        open={changeStatusModal}
        title={formatMessage(messages.SureChangeOrderStatus)}
        text={formatMessage(messages.NotCancelChangeOrderStatus)}
      />
    )
  }

  renderChangePaymentModal = () => {
    const { formatMessage } = this.props.intl
    const { changePaymentModal, newPaymentType } = this.state

    return (
      <ModalConfirm
        onClose={() => this.setState({ changePaymentModal: false, newPaymentType: undefined })}
        onOk={() => this.updateOrder(newPaymentType)}
        open={changePaymentModal}
        title={formatMessage(messages.SureChangeOrderPayment)}
        text={formatMessage(messages.NoCancelChangePaymentFromCashToCard)}
      />
    )
  }

  renderNoPaidModal = () => {
    const { formatMessage } = this.props.intl
    const { noPaidModal } = this.state

    return (
      <ModalConfirm
        onClose={() => this.setState({ noPaidModal: false })}
        open={noPaidModal}
        title={formatMessage(messages.OrderCannotCompleted)}
      />
    )
  }

  apiChangeStatus = (status: EOrderStatus) => {
    this.props.changeOrderStatus(this.props.order.id, status)
    this.setState({ status, changeStatusModal: false, newStatus: undefined })
  }

  onChangeStatus = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { order } = this.props
    const { value } = data
    const status = value as EOrderStatus

    if (status === EOrderStatus.DELIVERED && order.paymentType === EPaymentType.BY_CARD && !order.paid) {
      return this.setState({ noPaidModal: true })
    }

    if (value !== order.statusId) {
      this.setState({ newStatus: status, changeStatusModal: true })
    }
  }

  onChangePayment = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { order } = this.props
    const { value } = data

    if (value !== order.paymentType) {
      this.setState({ changePaymentModal: true, newPaymentType: Number(value) })
    }
  }

  onChangeCourier = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data

    if (value !== '–') {
      this.props.apiAssignOrder({
        orderId: this.props.order.id,
        courierId: value as string,
      })
    }
  }

  getCouriers = () => {
    const { order, couriers } = this.props

    if (
      order.statusId < EOrderStatus.DELIVERED &&
      (couriers.length === 0 || couriers.filter((item) => item.marketId === order.marketId).length === 0)
    ) {
      this.props.apiCouriersList({ market: order.marketId })
    }
  }

  updateOrder = (paymentType?: EPaymentType) => {
    const { order } = this.props

    if (!order || !paymentType) {
      return
    }

    this.props.apiOrderUpdate({ id: order.id, payment_type: paymentType })
    this.setState({ changePaymentModal: false, newPaymentType: undefined })
  }

  selectOrder = (order: IOrder) => {
    this.props.onSelectOrder(order)
  }
}

const mapStateToProps = (state: State): TConnectedProps => ({
  couriers: state.couriers.couriersList,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  changeOrderStatus: (orderId: string, status: EOrderStatus) =>
    dispatch(
      Actions.action(Actions.ORDER_CHANGE_STATUS, {
        orderId,
        status,
      }),
    ),
  apiOrderUpdate: (data: TApi.ApiUpdateOrderReq) => dispatch(Actions.action(Actions.API_UPDATE_ORDER, data)),
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiAssignOrder: (data: TApi.AssignOrderReq) => dispatch(Actions.action(Actions.API_ORDER_ASSIGN, data)),
  resetError: () => dispatch(Actions.action(Actions.UPDATE_ORDER_ERROR, false)),
})

export const OrderItem = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderItemCmp))
