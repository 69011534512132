import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Popup } from 'semantic-ui-react'
import moment from 'moment'
import classnames from 'classnames'

import './index.scss'

import { EModerateProductStatus, IModerationProduct } from '../../../types/TClient'

import messages from '../../../localization/messages'
import defaultImg from '../../../assets/images/default-image.png'
import { firstModeration } from '../../../utils/productsUtils'
import { countryLocalization } from '../../../utils/langUtils'

type TOwnProps = {
  product: IModerationProduct,
}

type TProps = TOwnProps & WrappedComponentProps

class ProductHeaderCmp extends React.Component<TProps> {
  render() {
    const { product } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='moderate-product__header'>
        {product.status === EModerateProductStatus.REJECTED && this.renderRejectedText()}
        <div className='moderate-product__block-row'>
          <div className='moderate-product__header-photo'>
            {this.renderImageStatus()}
            {product.imageUrl !== product.oldImageUrl ? (
              <div
                className={classnames(
                  'moderate-product__header-photo-image-container',
                  'moderate-product__header-changed',
                )}
              >
                <Popup
                  content={product.oldName}
                  position='right center'
                  trigger={
                    <img
                      alt={'product-image'}
                      className='moderate-product__header-photo-image'
                      src={product.imageUrl ? product.imageUrl : defaultImg}
                    />
                  }
                >
                  <b>{formatMessage(messages.PastValue)}:</b>
                  <br />
                  <img
                    alt={'product-image'}
                    className='moderate-product__header-photo-image'
                    src={product.oldImageUrl ? product.oldImageUrl : defaultImg}
                  />
                </Popup>
              </div>
            ) : (
              <div className='moderate-product__header-photo-image-container'>
                <img
                  alt={'product-image'}
                  className='moderate-product__header-photo-image'
                  src={product.imageUrl ? product.imageUrl : defaultImg}
                />
              </div>
            )}
          </div>
          <div className='moderate-product__block-block'>
            <div className='moderate-product__header-date'>
              {formatMessage(messages.TaskAt, {
                num: product.num,
                date: moment(product.createdAt).format('DD.MM.YYYY, HH:mm'),
              })}
            </div>
            {product.name !== product.oldName ? (
              <div className='moderate-product__header-name'>
                <div className={classnames('moderate-product__header-value', 'moderate-product__header-changed')}>
                  <Popup position='top center' trigger={<span>{product.name}</span>}>
                    <b>{formatMessage(messages.PastValue)}:</b>
                    <br />
                    {product.oldName}
                  </Popup>
                </div>
              </div>
            ) : (
              <div className='moderate-product__header-name'>{product.name}</div>
            )}
            {product.country !== product.oldCountry ? (
              <div className='moderate-product__header-country'>
                <div className={classnames('moderate-product__header-value', 'moderate-product__header-changed')}>
                  <Popup position='top center' trigger={<span>{countryLocalization(product.country)}</span>}>
                    <b>{formatMessage(messages.PastValue)}:</b>
                    <br />
                    {product.oldCountry}
                  </Popup>
                </div>
              </div>
            ) : (
              <div className='moderate-product__header-country'>{countryLocalization(product.country)}</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderRejectedText = () => {
    const { product } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='moderate-product__header-status'>
        <div className='moderate-product__header-status__label'>{formatMessage(messages.RejectionReason)}</div>
        <div className='moderate-product__header-status__text'>{product.text}</div>
      </div>
    )
  }

  renderImageStatus = () => {
    const { product } = this.props
    const isFirst = firstModeration(product)

    return (
      <div
        className='moderate-product__header-photo-status'
        style={{ backgroundColor: this.getStatusColor(product.status, isFirst) }}
      >
        <div className='moderate-product__header-photo-status__text'>{this.getStatusText(product.status, isFirst)}</div>
      </div>
    )
  }

  getStatusText = (status: EModerateProductStatus, isFirst: boolean) => {
    const { formatMessage } = this.props.intl

    switch (status) {
      case EModerateProductStatus.APPROVED:
        return formatMessage(messages.Approved)
      case EModerateProductStatus.REJECTED:
        return formatMessage(messages.Rejected)
      case EModerateProductStatus.NEW:
        return isFirst ? formatMessage(messages.New) : formatMessage(messages.Repeated)
    }
  }

  getStatusColor = (status: EModerateProductStatus, isFirst: boolean) => {
    switch (status) {
      case EModerateProductStatus.APPROVED:
        return '#5bc258'
      case EModerateProductStatus.REJECTED:
        return '#d8663f'
      case EModerateProductStatus.NEW:
        return isFirst ? '#5bc258' : '#f5934d'
    }
  }
}

export const ProductHeader = injectIntl(ProductHeaderCmp)
