import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header, List, Icon, Modal, Button } from 'semantic-ui-react'

import './index.scss'

import { ISellerInfo } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { LOCATION_SELLERS_REGISTER } from '../../../../utils/locationUtils'

type TConnectedProps = {
  loading: boolean,
  sellersInfoList: ISellerInfo[],
}

type TDispatchedProps = {
  apiSellersInfoList: (offset?: number) => Actions.Action,
  apiRemoveSellerInfo: (id: string) => Actions.Action,
}

type Props = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  modalOpened: boolean,
  removedItem: ISellerInfo | null,
}

class SellersInfoListCmp extends React.Component<Props, TState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      modalOpened: false,
      removedItem: null,
    }
  }

  componentDidMount(): void {
    this.props.apiSellersInfoList()
  }

  render() {
    const { sellersInfoList } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='sellers-info'>
        <Header as='h1'>{formatMessage(messages.SellersInProcessRegistration)}</Header>
        {this.renderModalRemove()}
        <List animated selection size='huge' className='sellers-info__list'>
          {sellersInfoList.map(this.renderItem)}
        </List>
      </div>
    )
  }

  renderItem = (item: ISellerInfo) => {
    const name = item.fio || item.name

    return (
      <List.Item key={item.phone} className='sellers-info__list-item' onClick={() => this.toRegister(item.id)}>
        <Icon size='large' name='user circle' />
        <List.Content>
          <List.Header as='a'>{item.phone}</List.Header>
          {name && <List.Description>{name}</List.Description>}
        </List.Content>
        <List.Content floated='right' onClick={(e: any) => this.activeModal(e, item)}>
          <Icon name='trash alternate' />
        </List.Content>
      </List.Item>
    )
  }

  renderModalRemove = () => {
    const { formatMessage } = this.props.intl
    const { modalOpened, removedItem } = this.state

    if (!removedItem) {
      return
    }

    return (
      <Modal basic onClose={this.dropModal} open={modalOpened} size='small'>
        <Header icon>
          <Icon name='trash alternate' />
          <p>{formatMessage(messages.SureDeleteSellerProfile, { seller: removedItem.phone })}</p>
        </Header>
        <Modal.Actions>
          <Button basic color='green' inverted onClick={this.dropModal}>
            <Icon name='remove' /> {formatMessage(messages.Cancel)}
          </Button>
          <Button color='red' inverted onClick={() => this.deleteSellerInfo(removedItem.id)}>
            <Icon name='checkmark' /> {formatMessage(messages.Confirm)}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  toRegister = (id: string) => {
    this.props.history.push(`${LOCATION_SELLERS_REGISTER}/${id}`)
  }

  activeModal = (e: any, item: ISellerInfo) => {
    e.stopPropagation()
    this.setState({ modalOpened: true, removedItem: item })
  }

  dropModal = () => {
    this.setState({ modalOpened: false, removedItem: null })
  }

  deleteSellerInfo = (id: string) => {
    this.props.apiRemoveSellerInfo(id)
    this.dropModal()
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  loading: s.sellers.loading,
  sellersInfoList: s.sellers.sellersInfoList,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiSellersInfoList: (offset = 0) => dispatch(Actions.action(Actions.API_SELLERS_INFO_LIST, { offset })),
  apiRemoveSellerInfo: (id: string) => dispatch(Actions.action(Actions.API_REMOVE_SELLER_INFO, { id })),
})

export const SellersInfoList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SellersInfoListCmp))
