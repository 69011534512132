import React from 'react'
import { Link, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Icon, Menu, Segment } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { EUserRole, IModerationProductsFilter, IUser } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'
import { Orders } from './NewOrders'
import { Order } from './OrderNew'
import { Sellers } from './Sellers'
import { Notifications } from './Notifications'
import { Analytics } from './Analytics'
import { Notification } from './Notification'
import { NotificationsLog } from './NotificationsLog'
import { Markets } from './Markets'
import { Market } from './Market'
import { Customers } from './Customers'
import { Customer } from './Customer'
import { SellerRegister } from './SellerRegister'
import { SellersInfoList } from './SellersInfoList'
import { SellersMarkets } from './SellersMarkets'
import { Seller } from './Seller'
import { CopyProducts } from './CopyProducts'
import { Tags } from './Tags'
import { TagProducts } from './TagProducts'
import { Monitoring } from './Monitoring'
import { ModerationProducts } from './ModerationProducts'
import { ModerationProduct } from './ModerationProduct'
import { ModerationIcon } from '../../../components/Icons'
import { HeaderPanel } from '../../../components/Header'
import {
  // LOCATION_MAIN,
  LOCATION_ORDERS,
  LOCATION_SELLERS,
  LOCATION_SELLERS_IN_PROGRESS,
  LOCATION_SELLERS_MARKETS,
  LOCATION_SELLERS_REGISTER,
  LOCATION_SELLERS_SELLER,
  LOCATION_SELLERS_COPY_PRODUCTS,
  LOCATION_NOTIFICATIONS,
  LOCATION_NOTIFICATIONS_LOG,
  LOCATION_MARKETS,
  LOCATION_TAGS,
  LOCATION_ANALYTICS,
  LOCATION_COURIERS,
  LOCATION_CUSTOMERS,
  LOCATION_MODERATION_PRODUCTS,
} from '../../../utils/locationUtils'
import { checkAvailableContent } from '../../../utils/userUtils'
import { checkMobile } from '../../../utils/deviceUtils'

type TConnectedProps = {
  lang: string,
  moderationProductsTotal: number,
  filterModerationProducts: IModerationProductsFilter,
  user?: IUser,
}

type TDispatchedProps = {
  apiModerationProductsList: (filter: IModerationProductsFilter) => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & RouteComponentProps & WrappedComponentProps

type TState = {
  sidebar: boolean,
}

export class HomeCmp extends React.Component<TProps, TState> {
  state = {
    sidebar: !checkMobile(),
  }

  componentDidMount(): void {
    this.initialize()
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    if (prevProps.lang !== this.props.lang) {
      this.setState({})
    }
  }

  render() {
    const { sidebar } = this.state

    return (
      <div className='home'>
        <HeaderPanel leftAction={() => this.setState({ sidebar: !this.state.sidebar })} />
        <div className='home__container'>
          {this.renderSidebar()}
          <div className={classnames('home__area', sidebar && 'home__area-with-sidebar')}>{this.renderArea()}</div>
        </div>
      </div>
    )
  }

  renderSidebar = () => {
    const { user, moderationProductsTotal } = this.props
    const { formatMessage } = this.props.intl
    const { sidebar } = this.state
    const path = window.location.pathname

    return (
      <Menu className={classnames('home__sidebar', !sidebar && 'home__sidebar-hide')} as={Segment} vertical>
        {/*<Link to={LOCATION_MAIN}>*/}
        {/*  <Menu.Item className='link sidebar-menu__item'>*/}
        {/*    <Icon name='home'/>*/}
        {/*    Главная*/}
        {/*  </Menu.Item>*/}
        {/*</Link>*/}
        <Link to={LOCATION_ORDERS} onClick={this.selectMenuItem}>
          <Menu.Item active={!!path.match(LOCATION_ORDERS)} className='link sidebar-menu__item'>
            <Icon name='tasks' />
            {formatMessage(messages.Orders)}
          </Menu.Item>
        </Link>
        {user && checkAvailableContent(user.role, EUserRole.MANAGER) && (
          <Link to={LOCATION_SELLERS} onClick={this.selectMenuItem}>
            <Menu.Item active={!!path.match(LOCATION_SELLERS)} className='link sidebar-menu__item'>
              <Icon name='address card' />
              {formatMessage(messages.Sellers)}
            </Menu.Item>
          </Link>
        )}
        <Link to={LOCATION_COURIERS} onClick={this.selectMenuItem}>
          <Menu.Item active={!!path.match(LOCATION_COURIERS)} className='link sidebar-menu__item'>
            <Icon name='truck' />
            {formatMessage(messages.Couriers)}
          </Menu.Item>
        </Link>
        <Link to={LOCATION_MARKETS} onClick={this.selectMenuItem}>
          <Menu.Item active={!!path.match(LOCATION_MARKETS)} className='link sidebar-menu__item'>
            <Icon name='home' />
            {formatMessage(messages.Markets)}
          </Menu.Item>
        </Link>
        <Link to={LOCATION_CUSTOMERS} onClick={this.selectMenuItem}>
          <Menu.Item active={!!path.match(LOCATION_CUSTOMERS)} className='link sidebar-menu__item'>
            <Icon name='users' />
            {formatMessage(messages.Customers)}
          </Menu.Item>
        </Link>
        {user && checkAvailableContent(user.role, EUserRole.MANAGER) && (
          <Link to={LOCATION_NOTIFICATIONS} onClick={this.selectMenuItem}>
            <Menu.Item active={!!path.match(LOCATION_NOTIFICATIONS)} className='link sidebar-menu__item'>
              <Icon name='paper plane' />
              {formatMessage(messages.Notifications)}
            </Menu.Item>
          </Link>
        )}
        {user && checkAvailableContent(user.role, EUserRole.MANAGER) && (
          <Link to={LOCATION_ANALYTICS} onClick={this.selectMenuItem}>
            <Menu.Item active={!!path.match(LOCATION_ANALYTICS)} className='link sidebar-menu__item'>
              <Icon name='chart line' />
              {formatMessage(messages.Analytics)}
            </Menu.Item>
          </Link>
        )}
        {user && checkAvailableContent(user.role, EUserRole.MANAGER) && (
          <Link to={LOCATION_TAGS} onClick={this.selectMenuItem}>
            <Menu.Item active={!!path.match(LOCATION_TAGS)} className='link sidebar-menu__item'>
              <Icon name='tags' />
              {formatMessage(messages.Tags)}
            </Menu.Item>
          </Link>
        )}
        {user && checkAvailableContent(user.role, EUserRole.MANAGER) && (
          <Link to={LOCATION_MODERATION_PRODUCTS} onClick={this.selectMenuItem}>
            <Menu.Item active={!!path.match(LOCATION_MODERATION_PRODUCTS)} className='link sidebar-menu__item'>
              <div className='sidebar-menu__item-row'>
                <div className='sidebar-menu__item-text'>
                  {formatMessage(messages.Moderation)}{' '}
                  <span className='sidebar-menu__item-text-sub'>({moderationProductsTotal})</span>
                </div>
                <div className='sidebar-menu__item-icon'>
                  <ModerationIcon color='#383838' />
                </div>
              </div>
            </Menu.Item>
          </Link>
        )}
      </Menu>
    )
  }

  selectMenuItem = () => {
    const mobile = checkMobile()

    if (mobile) {
      this.setState({ sidebar: false })
    }
  }

  renderArea = () => {
    return (
      <Switch>
        {/*<Route exact path={LOCATION_MAIN} component={Main}/>*/}
        <Route exact path={LOCATION_ORDERS} component={Orders} />
        <Route exact path={LOCATION_SELLERS} component={Sellers} />
        <Route exact path={LOCATION_COURIERS} component={Monitoring} />
        <Route exact path={LOCATION_NOTIFICATIONS} component={Notifications} />
        <Route exact path={LOCATION_NOTIFICATIONS_LOG} component={NotificationsLog} />
        <Route exact path={`${LOCATION_NOTIFICATIONS}/:id`} component={Notification} />
        <Route exact path={`${LOCATION_NOTIFICATIONS}/:type/:id`} component={Notification} />
        <Route exact path={LOCATION_ANALYTICS} component={Analytics} />
        <Route exact path={`${LOCATION_MARKETS}`} component={Markets} />
        <Route exact path={`${LOCATION_MARKETS}/:id?`} component={Market} />
        <Route exact path={`${LOCATION_CUSTOMERS}`} component={Customers} />
        <Route exact path={`${LOCATION_MODERATION_PRODUCTS}`} component={ModerationProducts} />
        <Route exact path={`${LOCATION_MODERATION_PRODUCTS}/:id`} component={ModerationProduct} />
        <Route exact path={`${LOCATION_CUSTOMERS}/:userId`} component={Customer} />
        <Route exact path={`${LOCATION_TAGS}/:marketId?`} component={Tags} />
        <Route exact path={`${LOCATION_TAGS}/:marketId/:tagId`} component={TagProducts} />
        <Route path={`${LOCATION_ORDERS}/:id`} component={Order} />
        <Route path={`${LOCATION_SELLERS_REGISTER}/:id`} component={SellerRegister} />
        <Route path={LOCATION_SELLERS_IN_PROGRESS} component={SellersInfoList} />
        <Route path={LOCATION_SELLERS_MARKETS} component={SellersMarkets} />
        <Route path={`${LOCATION_SELLERS_SELLER}/:id`} component={Seller} />
        <Route path={LOCATION_SELLERS_COPY_PRODUCTS} component={CopyProducts} />
        <Route path='*' component={Orders} />
      </Switch>
    )
  }

  initialize = () => {
    this.props.apiModerationProductsList({
      ...this.props.filterModerationProducts,
      offset: 0,
    })
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  lang: s.lang,
  moderationProductsTotal: s.products.moderationProducts.totalOnModeration,
  filterModerationProducts: s.products.moderationProducts.filter,
  user: s.user.user,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiModerationProductsList: (filter: IModerationProductsFilter) =>
    dispatch(Actions.action(Actions.API_PRODUCTS_MODERATION_LIST, filter)),
})

export const Home = connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(HomeCmp)))
