import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const TrashIcon: React.FC<TProps> = ({ width = 24, height = 24, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={color}
        strokeWidth='1.7'
        strokeLinecap='round'
        d='m6 6 .967 13.536a.5.5 0 0 0 .499.464h9.068a.5.5 0 0 0 .5-.464L18 6M4 6h16M14.2 10.5 9.7 15M9.7 10.5l4.5 4.5M15 6a3 3 0 1 0-6 0'
      />
    </svg>
  )
}
