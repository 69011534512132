import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { HistoryRemoveProductAction } from '../../../../types/TClient'

import { Action } from '../Action'
import { convertCurrency, formatPrice, unitIntl } from '../../../../utils/productsUtils'
import messages from '../../../../localization/messages'

type IProps = HistoryRemoveProductAction & WrappedComponentProps

const _RemoveProductAction: React.FC<IProps> = ({ data: { name, price, unit, currencyCode, quantity }, date }) => {
  return (
    <Action date={date}>
      <FormattedMessage {...messages.ProductDeleted} />
      <div className='remove-product-action__product-name'>
        {name} {quantity} {unitIntl(unit)} / {formatPrice(price, convertCurrency(currencyCode))}
      </div>
    </Action>
  )
}

export const RemoveProductAction = injectIntl(_RemoveProductAction)
