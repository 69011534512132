import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Input, InputOnChangeData, Popup } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { EImageType, IMarket, IStockCategory } from '../../../../../../types/TClient'
import {
  ApiCreateMarketCategoryReq,
  ApiUpdateMarketCategoryReq,
  ApiRemoveMarketCategoryReq,
} from '../../../../../../types/TApi'

import messages from '../../../../../../localization/messages'
import * as Actions from '../../../../../../store/actions'
import { State } from '../../../../../../store/reducer'
import { EModalType, IModalImageCropper } from '../../../../../../store/reducers/modals'
import {
  ArrowUpIcon,
  EditIcon,
  PictureIcon,
  PlusIcon,
  TrashIcon,
  VisibleIcon,
  VisibleOffIcon,
} from '../../../../../../components/Icons'
import { ModalConfirm } from '../../../../../../components/Modal/ModalConfirm'

type TOwnProps = {
  pos: number,
  market: IMarket,
  category: IStockCategory,
}

type TConnectedProps = {
  loading: boolean,
  savedImageUrl: string,
}

type TDispatchedProps = {
  cropImage: (data: IModalImageCropper) => Actions.Action,
  uploadImage: (file: File, type: EImageType) => Actions.Action,
  createCategory: (data: ApiCreateMarketCategoryReq) => Actions.Action,
  updateCategory: (data: ApiUpdateMarketCategoryReq) => Actions.Action,
  removeCategory: (data: ApiRemoveMarketCategoryReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  edit: boolean,
  title: string,
  uploadImage: boolean,
  removeCategoryModal: boolean,
}

const MAX_CATEGORY_LENGTH = 64

class CategoryEditCmp extends React.Component<TProps, TState> {
  private imageRef = React.createRef<HTMLInputElement>()

  constructor(props: TProps) {
    super(props)

    this.state = {
      edit: false,
      title: props.category.name,
      uploadImage: false,
      removeCategoryModal: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { category, savedImageUrl } = this.props

    if (category.name !== prevProps.category.name) {
      this.setState({ title: category.name })
    }

    if (this.state.uploadImage && savedImageUrl && savedImageUrl !== prevProps.savedImageUrl) {
      this.props.updateCategory({
        category: category,
        req: {
          ...category,
          imageUrl: savedImageUrl,
        },
      })

      this.setState({ uploadImage: false })
    }
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='stock-category-edit'>
        {this.renderRemoveModal()}
        <div className='stock-category-edit__content'>
          {this.renderImage()}
          {this.renderTitle()}
          {this.renderPosition()}
          {this.renderVisibility()}
          {this.renderRemove()}
        </div>
        <div className='stock-category-edit__add' onClick={this.addCategory}>
          <PlusIcon color='#7677bc' />
          <div className='stock-category-edit__add-text'>{formatMessage(messages.AddCategory)}</div>
        </div>
      </div>
    )
  }

  renderImage = () => {
    const { category } = this.props

    return (
      <div className={classnames('stock-category-edit__image', category.hidden && 'stock-category-edit__image-hidden')}>
        <input
          ref={this.imageRef}
          type='file'
          onChange={this.handleChangeImage}
          accept='image/x-png,image/jpeg'
          style={{ display: 'none' }}
        />
        {category.imageUrl ? (
          <div
            className='stock-category-edit__image'
            style={{ backgroundImage: `url(${category.imageUrl})` }}
            onClick={this.uploadImage}
          >
            <div className='stock-category-edit__image-edit'>
              <EditIcon color='#7677bc' />
            </div>
          </div>
        ) : (
          <div
            className={classnames('stock-category-edit__image', 'stock-category-edit__image-empty')}
            onClick={this.uploadImage}
          >
            <PictureIcon />
          </div>
        )}
      </div>
    )
  }

  renderTitle = () => {
    const { formatMessage } = this.props.intl
    const { title } = this.state

    return (
      <div className='stock-category-edit__title'>
        <Input
          maxLength={MAX_CATEGORY_LENGTH}
          className='stock-category-edit__title-input'
          placeholder={formatMessage(messages.CategoryName)}
          value={title}
          onChange={this.changeTitle}
          onBlur={this.updateTitle}
          onMouseOut={this.updateTitle}
        />
      </div>
    )
  }

  renderPosition = () => {
    const { pos, market } = this.props
    const categories = market.categories

    return (
      <div className='stock-category-edit__position'>
        <div
          className='stock-category-edit__position-up'
          onClick={() => {
            if (categories && pos - 1 >= 0) {
              this.shiftPosition(categories[pos - 1])
            }
          }}
        >
          <ArrowUpIcon />
        </div>
        <div className='stock-category-edit__position-divider' />
        <div
          className='stock-category-edit__position-down'
          onClick={() => {
            if (categories && pos + 1 < categories.length) {
              this.shiftPosition(categories[pos + 1])
            }
          }}
        >
          <ArrowUpIcon />
        </div>
      </div>
    )
  }

  renderVisibility = () => {
    const { market, category } = this.props
    const { formatMessage } = this.props.intl
    const disabled = (market.categories || []).filter((item) => !item.hidden).length <= 1 && !category.hidden

    const popTitle = category.hidden
      ? formatMessage(messages.CategoryCurrentlyHidden)
      : formatMessage(messages.CategoryIsVisible)
    const popText = category.hidden
      ? formatMessage(messages.ClickCategoryVisible)
      : formatMessage(messages.ClickHideCategory)

    return (
      <Popup
        wide
        content={
          disabled ? (
            <div>{formatMessage(messages.OnlyCategoryCannotHidden)}</div>
          ) : (
            <>
              <div className='stock-category-edit__visibility-title'>{popTitle}</div>
              <div className='stock-category-edit__visibility-text'>{popText}</div>
            </>
          )
        }
        position='top center'
        trigger={
          <div
            className={classnames(
              'stock-category-edit__visibility',
              !category.hidden && 'stock-category-edit__visibility-active',
              disabled && 'stock-category-edit__visibility-disable',
            )}
            onClick={disabled ? undefined : this.toggleVisible}
          >
            {!category.hidden ? <VisibleIcon /> : <VisibleOffIcon color='#f2994a' />}
          </div>
        }
      />
    )
  }

  renderRemove = () => {
    const { market, category } = this.props
    const { formatMessage } = this.props.intl
    const disabled =
      (market.categories || []).length <= 1 ||
      ((market.categories || []).filter((item) => !item.hidden).length <= 1 && !category.hidden)

    return (
      <>
        {disabled ? (
          <Popup
            wide
            content={formatMessage(messages.OnlyCategoryCannotDeleted)}
            position='top center'
            trigger={
              <div
                className={classnames('stock-category-edit__remove', disabled && 'stock-category-edit__remove-disable')}
              >
                <TrashIcon />
              </div>
            }
          />
        ) : (
          <div className='stock-category-edit__remove' onClick={() => this.setState({ removeCategoryModal: true })}>
            <TrashIcon />
          </div>
        )}
      </>
    )
  }

  renderRemoveModal = () => {
    const { formatMessage } = this.props.intl
    const { removeCategoryModal, title } = this.state

    return (
      <ModalConfirm
        alert
        onClose={() => this.setState({ removeCategoryModal: false })}
        onOk={this.remove}
        open={removeCategoryModal}
        title={formatMessage(messages.RemovingCategory, { category: title })}
        text={formatMessage(messages.DeletingCategoryWarning)}
      />
    )
  }

  handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formatMessage } = this.props.intl
    const { files } = event.target

    if (files && files.length) {
      this.props.cropImage({
        type: EModalType.MODAL_IMAGE_CROPPER,
        props: {
          title: formatMessage(messages.ImageEditing),
          image: files[0],
          aspectRatio: 1,
          uploadImage: (file: File) => this.props.uploadImage(file, EImageType.MARKET_CATEGORY_IMAGE),
        },
      })
    }
  }

  uploadImage = () => {
    this.setState({ uploadImage: true })
    this.imageRef.current?.click()
  }

  addCategory = () => {
    const { market, category } = this.props
    const { formatMessage } = this.props.intl

    this.props.createCategory({
      marketId: market.id,
      name: formatMessage(messages.Category),
      index: category.index + 1,
      hidden: false,
    })
  }

  changeTitle = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data

    this.setState({ title: value })
  }

  updateTitle = () => {
    const { category } = this.props
    const { title } = this.state
    const newName = title.trim()

    if (newName !== category.name) {
      this.props.updateCategory({
        category: category,
        req: {
          ...category,
          name: newName || category.name,
        },
      })
    }
  }

  shiftPosition = (changedCategory: IStockCategory) => {
    const { category } = this.props

    if (changedCategory) {
      this.props.updateCategory({
        category: category,
        changed: changedCategory,
        req: {
          ...category,
          index: changedCategory.index,
        },
      })
    }
  }

  toggleVisible = () => {
    const { category } = this.props

    this.props.updateCategory({
      category: category,
      req: {
        ...category,
        hidden: !category.hidden,
      },
    })
  }

  remove = () => {
    const { market, category } = this.props

    this.props.removeCategory({
      marketId: market.id,
      id: category.id,
    })

    this.setState({ removeCategoryModal: false })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loadingUpdateCategories, categorySavedImageUrl } = s.markets

  return {
    loading: loadingUpdateCategories,
    savedImageUrl: categorySavedImageUrl,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  cropImage: (data: IModalImageCropper) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  uploadImage: (file: File, type: EImageType) =>
    dispatch(
      Actions.action(Actions.API_UPLOAD_IMAGE, {
        file,
        type,
      }),
    ),
  createCategory: (data: ApiCreateMarketCategoryReq) =>
    dispatch(Actions.action(Actions.API_CREATE_MARKET_CATEGORY, data)),
  updateCategory: (data: ApiUpdateMarketCategoryReq) =>
    dispatch(Actions.action(Actions.API_UPDATE_MARKET_CATEGORY, data)),
  removeCategory: (data: ApiRemoveMarketCategoryReq) =>
    dispatch(Actions.action(Actions.API_REMOVE_MARKET_CATEGORY, data)),
})

export const CategoryEdit = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoryEditCmp))
