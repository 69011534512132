import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'

import './index.scss'

type TProps = {
  label: string,
  value: number | string,
  info?: string,
  color?: string,
}

export const AnalyticsBlockStat: React.FC<TProps> = ({ label, value, info, color }) => {
  return (
    <div className='analytics__block__stat'>
      {color && <div className='analytics__block__stat-color' style={{ backgroundColor: color }} />}
      <div>
        <div className='analytics__block__stat-label'>
          {label}
          {!!info && (
            <Popup
              wide
              content={info}
              position='top center'
              trigger={<Icon className='analytics__block__stat-label__icon' name='question circle outline' />}
            />
          )}
        </div>
        <div className='analytics__block__stat-value'>{value}</div>
      </div>
    </div>
  )
}
