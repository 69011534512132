import { Reducer } from 'redux'
import { uniqBy } from 'lodash'
import moment from 'moment'

import {
  ICustomer,
  ICustomersFilter,
  IDeliveryAddress,
  IOrder,
  IReview,
  DAY_HOUR,
  HOUR_MINUTE,
  MINUTE_SEC,
  SEC,
} from '../../types/TClient'

import * as Actions from '../actions'
import { CUSTOMERS_LIMIT } from '../../utils/customersUtils'

export type MutableStateCustomers = {
  loading: boolean,
  loaded: boolean,
  filter: ICustomersFilter,
  customersList: ICustomer[],
  addresses: {
    list: IDeliveryAddress[],
    total: number,
    loading: boolean,
    loaded: boolean,
  },
  reviews: {
    list: IReview[],
    total: number,
    loading: boolean,
    loaded: boolean,
  },
  orders: {
    list: IOrder[],
    total: number,
    loading: boolean,
    loaded: boolean,
  },
  total: number,
  totalWithOrders: number,
  totalWithoutOrders: number,
}

export type StateCustomers = Readonly<MutableStateCustomers>

const defStateCustomers: StateCustomers = {
  loading: false,
  loaded: false,
  filter: {
    b2b: false,
    offset: 0,
    lastOrderDesc: true,
    firstOrderDateTo: moment().format('YYYY-MM-DD'),
    firstOrderDateFrom: moment(Date.now() - 6 * DAY_HOUR * HOUR_MINUTE * MINUTE_SEC * SEC).format('YYYY-MM-DD'),
  },
  customersList: [],
  addresses: {
    list: [],
    total: 0,
    loading: false,
    loaded: false,
  },
  reviews: {
    list: [],
    total: 0,
    loading: false,
    loaded: false,
  },
  orders: {
    list: [],
    total: 0,
    loading: false,
    loaded: false,
  },
  total: 0,
  totalWithOrders: 0,
  totalWithoutOrders: 0,
}

export const customers: Reducer<StateCustomers, Actions.Action> = (s = defStateCustomers, a): StateCustomers => {
  switch (a.type) {
    case Actions.API_CUSTOMERS_LIST:
      return {
        ...s,
        loading: true,
        loaded: false,
      }
    case Actions.CUSTOMERS_LIST: {
      let oldCustomers = !a.data.force ? s.customersList : []
      let newCustomers = [...a.data.customers]

      oldCustomers = oldCustomers.map((order) => {
        const exist = newCustomers.find((item) => item.id === order.id)

        if (exist) {
          newCustomers = newCustomers.filter((item) => item.id !== order.id)

          return exist
        }

        return order
      })

      return {
        ...s,
        loading: false,
        loaded: a.data.customers.length < CUSTOMERS_LIMIT,
        customersList: uniqBy([...oldCustomers, ...newCustomers], 'id'),
        total: a.data.force
          ? a.data.total || a.data.customers.length
          : a.data.total || s.total + a.data.customers.length,
        totalWithOrders: a.data.totalWithOrders || s.totalWithOrders,
        totalWithoutOrders: a.data.totalWithoutOrders || s.totalWithoutOrders,
      }
    }
    case Actions.CUSTOMER_UPDATE:
      return {
        ...s,
        customersList: s.customersList.map((customer) =>
          customer.id !== a.data.id ? customer : { ...customer, ...a.data },
        ),
      }
    case Actions.API_CUSTOMER_ADDRESSES:
      return {
        ...s,
        addresses: {
          ...s.addresses,
          loading: true,
        },
      }
    case Actions.CUSTOMER_ADDRESSES: {
      const newAddresses = uniqBy([...(a.data.force ? [] : s.addresses.list), ...a.data.addresses], 'address')

      return {
        ...s,
        addresses: {
          list: newAddresses,
          total: a.data.total || newAddresses.length,
          loading: true,
          loaded: a.data.addresses.length === 0,
        },
      }
    }
    case Actions.API_CUSTOMER_REVIEWS:
      return {
        ...s,
        reviews: {
          ...s.reviews,
          loading: true,
        },
      }
    case Actions.CUSTOMER_REVIEWS: {
      const newReviews = uniqBy([...(a.data.force ? [] : s.reviews.list), ...a.data.reviews], 'id')

      return {
        ...s,
        reviews: {
          list: newReviews,
          total: a.data.total || newReviews.length,
          loading: true,
          loaded: a.data.reviews.length === 0,
        },
      }
    }
    case Actions.API_CUSTOMER_ORDERS:
      return {
        ...s,
        orders: {
          ...s.orders,
          loading: true,
        },
      }
    case Actions.CUSTOMER_ORDERS: {
      const newOrders = uniqBy([...(a.data.force ? [] : s.orders.list), ...a.data.orders], 'id')

      return {
        ...s,
        orders: {
          list: newOrders,
          total: a.data.total || newOrders.length,
          loading: true,
          loaded: a.data.orders.length === 0,
        },
      }
    }
    case Actions.CUSTOMERS_FILTER:
      return {
        ...s,
        filter: { ...a.data },
        customersList: [],
        loaded: false,
      }
  }
  return s
}
