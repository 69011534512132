import React from 'react'
import { Form } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

type TProps = {
  onChange(event: any, data: any): void,
  value: string,
  name?: string,
  label?: string,
  placeholder?: string,
  error?: string,
}

export const TextAreaField: React.FC<TProps> = ({ onChange, value, label, placeholder, name, error }) => {
  return (
    <div className='textarea-field'>
      {!!label && !!value && <div className='textarea-field__label'>{label}</div>}
      <Form.TextArea
        value={value}
        rows={5}
        placeholder={placeholder}
        className={classnames('textarea-field__input', !!label && !!value && 'textarea-field__input-with-label')}
        name={name}
        onChange={onChange}
        error={error ? error : false}
      />
    </div>
  )
}
