import { Reducer } from 'redux'

import { EUserRole, IUser } from '../../types/TClient'

import * as Actions from '../actions'
import * as storage from '../../utils/storageUtils'

export type MutableStateUser = {
  loading: boolean,
  user?: IUser,
  errorApi?: string,
}

export type StateUser = Readonly<MutableStateUser>

const defStateUsers: StateUser = {
  loading: false,
}

export const user: Reducer<StateUser, Actions.Action> = (s = defStateUsers, a): StateUser => {
  switch (a.type) {
    case Actions.API_LOGIN:
      return {
        ...s,
        loading: true,
      }
    case Actions.USER:
      if (a.data.sid) {
        storage.setValue(storage.SID_KEY, a.data.sid)
      }

      return {
        ...s,
        loading: false,
        user: {
          login: a.data.login,
          email: a.data.email,
          role: a.data.role,
        },
      }
    case Actions.USER_LOGIN_ERROR:
      return {
        ...s,
        loading: false,
        errorApi: a.data.error,
      }
    case Actions.API_SIGN_UP:
      return {
        ...s,
        loading: true,
        user: {
          login: a.data.login,
          email: a.data.email,
          role: a.data.role as any as EUserRole,
        },
      }
    case Actions.USER_SIGN_UP_ERROR:
      return {
        ...s,
        loading: false,
        errorApi: a.data.error,
      }
    case Actions.LOGOUT: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { user, ...rest } = s
      storage.removeValue(storage.SID_KEY)
      window.location.reload(true)

      return {
        ...rest,
      }
    }
    case Actions.DROP_API_ERROR:
      return {
        ...s,
        errorApi: undefined,
      }
  }
  return s
}
