import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Icon, Loader, Modal } from 'semantic-ui-react'
import Cropper from 'react-cropper'

import './index.scss'
import 'cropperjs/dist/cropper.css'

import messages from '../../localization/messages'
import * as Actions from '../../store/actions'

type TOwnProps = {
  title: string,
  aspectRatio: number,
  image?: File,
  imageUrl?: string,
  uploadImage: (file: File) => Actions.Action,
  onClose: () => void,
}

type Props = TOwnProps & WrappedComponentProps

type IState = {
  img: string | null,
  imgLoading: boolean,
}

class ImageCropperCmp extends React.Component<Props, IState> {
  private cropperRef = React.createRef<HTMLImageElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      img: this.props.imageUrl || URL.createObjectURL(props.image),
      imgLoading: true,
    }
  }

  componentWillUnmount(): void {
    if (this.state.img && !this.props.imageUrl) {
      URL.revokeObjectURL(this.state.img)
    }
  }

  render() {
    const { formatMessage } = this.props.intl
    const { img } = this.state

    return (
      <>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>{img && this.renderImage(img)}</Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={this.props.onClose}>
            {formatMessage(messages.Cancel)}
          </Button>
          <Button positive onClick={this.uploadImage}>
            {formatMessage(messages.Save)}
          </Button>
        </Modal.Actions>
      </>
    )
  }

  renderImage = (imageSrc: string) => {
    const { aspectRatio } = this.props
    const { imgLoading } = this.state

    return (
      <div className='image-cropper'>
        {imgLoading ? (
          <div className='image-cropper__loading'>
            <Loader active />
          </div>
        ) : (
          this.renderActions()
        )}
        <Cropper
          src={imageSrc}
          aspectRatio={aspectRatio}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={aspectRatio}
          guides={false}
          ref={this.cropperRef}
          zoom={this.onZoom}
          minCropBoxHeight={32}
          minCropBoxWidth={32}
          ready={this.onLoad}
          zoomOnTouch
        />
      </div>
    )
  }

  renderActions = () => {
    return (
      <div className='image-cropper__actions'>
        <div className='image-cropper__rotate' onClick={() => this.rotateImage(-30)}>
          <Icon name='undo' size='large' />
        </div>
        <div className='image-cropper__rotate' onClick={() => this.rotateImage(30)}>
          <Icon name='redo' size='large' />
        </div>
      </div>
    )
  }

  onLoad = () => {
    this.setState({ imgLoading: false })
  }

  rotateImage = (deg: number) => {
    const imageElement: any = this.cropperRef?.current
    const cropper: any = imageElement?.cropper

    if (cropper) {
      cropper.rotate(deg)
    }
  }

  onZoom = (event: Cropper.ZoomEvent<HTMLImageElement>) => {
    const imageElement: any = this.cropperRef?.current
    const cropper: any = imageElement?.cropper

    if (cropper) {
      const image = cropper.getImageData()
      const zoomLevel = image.width / image.naturalWidth

      if (
        (zoomLevel > 2.5 && event.detail.ratio > event.detail.oldRatio) ||
        (zoomLevel < 0.4 && event.detail.ratio < event.detail.oldRatio)
      ) {
        event.preventDefault()
      }
    }
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob
    b.lastModifiedDate = new Date()
    b.name = fileName

    return b as File
  }

  uploadImage = () => {
    const { image } = this.props
    const imageElement: any = this.cropperRef?.current
    const cropper: any = imageElement?.cropper

    if (cropper) {
      cropper.getCroppedCanvas().toBlob(
        (blob: Blob | null) => {
          if (blob) {
            const file = this.blobToFile(blob, image ? image.name : 'image')
            this.props.uploadImage(file)
            this.props.onClose()
          }
        },
        image ? image.type : 'image/png',
        1,
      )
    }
  }
}

export const ImageCropperModal = connect(null)(injectIntl(ImageCropperCmp))
