import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Popup } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { IModerationProduct } from '../../../types/TClient'

import messages from '../../../localization/messages'

type TOwnProps = {
  product: IModerationProduct,
}

type TProps = TOwnProps & WrappedComponentProps

class ProductDescCmp extends React.Component<TProps> {
  render() {
    const { product } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='moderate-product__header'>
        <div className='moderate-product__block-row'>
          <div className='moderate-product__block-item'>
            <div className='moderate-product__block-label'>{formatMessage(messages.Description)}</div>
            {product.description !== product.oldDescription ? (
              <div
                className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                <Popup wide='very' position='top center' trigger={<span>{product.description}</span>}>
                  <b>{formatMessage(messages.PastValue)}:</b>
                  <br />
                  {product.oldDescription || '—'}
                </Popup>
              </div>
            ) : (
              <div className='moderate-product__block-text' style={{ whiteSpace: 'pre-wrap' }}>
                {product.description}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export const ProductDesc = injectIntl(ProductDescCmp)
