import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { InputOnChangeData } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { IMarket, IProduct, IProductOrder, ISeller, ISellerCategory, IStockCategory } from '../../../types/TClient'
import {
  ApiAddProductsOrderReq,
  ApiMarketCategoriesReq,
  ApiMarketProductsReq,
  ApiSellersListReq,
} from '../../../types/TApi'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'
import { Button } from '../../Button'
import { StockCategory } from '../../../App/pages/Home/Market/Categories/Category'
import { SearchField } from '../../SearchField'
import { SellerItem } from '../../SellerItem'
import { ProductItem } from '../../ProductItem'
import { SellerProducts } from '../../SellerProducts'
import { BackIcon, CloseIcon } from '../../Icons'
import { sortSellerBy } from '../../../utils/sellerUtils'
import { formatPrice, totalByProducts } from '../../../utils/productsUtils'
import { DEF_CURRENCY } from '../../../localization/currencies'

type TOwnProps = {
  orderId: string,
  marketId: string,
  onClose: () => void,
}

type TConnectedProps = {
  loadingProducts: boolean,
  loadingCategories: boolean,
  loadingSellers: boolean,
  sellers: ISeller[],
  marketCategories: IStockCategory[],
  marketProducts: IProduct[],
  preAddProducts: IProductOrder[],
  sellersCategories: ISellerCategory[],
  market?: IMarket,
}

type TDispatchedProps = {
  apiSellersList: (data: ApiSellersListReq) => Actions.Action,
  getMarketCategories: (data: ApiMarketCategoriesReq) => Actions.Action,
  apiMarketProducts: (data: ApiMarketProductsReq) => Actions.Action,
  apiAddProductsOrder: (data: ApiAddProductsOrderReq) => Actions.Action,
  dropPreAddProducts: () => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

enum ETab {
  CATEGORIES = 1,
  SELLERS = 2,
}

type IState = {
  activeTab: ETab,
  searchText: string,
  selectCategory: string,
  selectSeller: ISeller | null,
}

const PRODUCTS_LIMIT = 10
const SELLERS_LIMIT = 25
const SEARCH_MIN = 3

class AddProductOrderCmp extends React.Component<TProps, IState> {
  private sellerRef = React.createRef<HTMLDivElement>()
  private productsRef = React.createRef<HTMLDivElement>()

  constructor(props: TProps) {
    super(props)

    this.state = {
      activeTab: ETab.CATEGORIES,
      searchText: '',
      selectCategory: '',
      selectSeller: null,
    }
  }

  componentDidMount(): void {
    const { market } = this.props

    if (market) {
      this.props.getMarketCategories({ marketId: market.id, hidden: false, notInOrder: this.props.orderId })
    }
  }

  componentDidUpdate(_prevProps: Readonly<TProps>, prevState: Readonly<IState>): void {
    const { activeTab } = this.state

    if (prevState.activeTab !== activeTab) {
      if (activeTab === ETab.CATEGORIES) {
        this.props.getMarketCategories({
          marketId: this.props.marketId,
          hidden: false,
          notInOrder: this.props.orderId,
        })
      }

      if (activeTab === ETab.SELLERS) {
        this.getSellersMarket(0)
      }
    }
  }

  render() {
    const { preAddProducts } = this.props
    const { formatMessage } = this.props.intl
    const { selectSeller } = this.state

    return (
      <div className='add-product-order'>
        <div className='add-product-order__close' onClick={this.cancel}>
          <CloseIcon color='#a7aeb8' />
        </div>
        <div className='add-product-order__header'>
          <div className='add-product-order__title'>{formatMessage(messages.AddedProducts)}</div>
          {!selectSeller && this.renderTabs()}
        </div>
        <div className='add-product-order__content'>{this.renderContent()}</div>
        <div className='add-product-order__actions'>
          {preAddProducts.length > 0 && this.renderPreAddedProducts()}
          <div className='add-product-order__actions-btn'>
            <Button outline title={formatMessage(messages.Cancel)} onClick={this.cancel} />
          </div>
          <div className='add-product-order__actions-btn'>
            <Button title={formatMessage(messages.Save)} onClick={this.onSave} />
          </div>
        </div>
      </div>
    )
  }

  renderContent = () => {
    const { market, orderId } = this.props
    const { formatMessage } = this.props.intl
    const { selectSeller, searchText, activeTab } = this.state

    if (selectSeller) {
      if (selectSeller && market) {
        return (
          <>
            <div className='add-product-order__seller-back' onClick={this.dropSeller}>
              <BackIcon color={'#858897'} /> {formatMessage(messages.Save)}
            </div>
            <SellerProducts market={market} seller={selectSeller} orderId={orderId} />
          </>
        )
      }
    }

    return (
      <>
        {this.renderSearch()}
        {market && activeTab === ETab.SELLERS && this.renderSellersCategories()}
        {market &&
          activeTab === ETab.CATEGORIES &&
          (searchText.length < SEARCH_MIN ? this.renderCategories(market) : this.renderProducts())}
        {activeTab === ETab.SELLERS && this.renderSellers()}
      </>
    )
  }

  renderTabs = () => {
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state

    return (
      <div className='add-product-order__tabs'>
        <div
          className={classnames(
            'add-product-order__tabs-btn',
            activeTab === ETab.CATEGORIES && 'add-product-order__tabs-btn-active',
          )}
          onClick={() => this.setState({ activeTab: ETab.CATEGORIES })}
        >
          {formatMessage(messages.BestFromMarket)}
        </div>
        <div
          className={classnames(
            'add-product-order__tabs-btn',
            activeTab === ETab.SELLERS && 'add-product-order__tabs-btn-active',
          )}
          onClick={() => this.setState({ activeTab: ETab.SELLERS })}
        >
          {formatMessage(messages.Stalls)}
        </div>
      </div>
    )
  }

  renderSearch = () => {
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state

    return (
      <div className='add-product-order__search'>
        <SearchField
          value={this.state.searchText}
          placeholder={
            activeTab === ETab.CATEGORIES
              ? formatMessage(messages.SearchByProducts)
              : formatMessage(messages.SearchBySellers)
          }
          onChange={this.handleSearch}
        />
      </div>
    )
  }

  renderCategories = (market: IMarket) => {
    const { marketCategories } = this.props

    return (
      <div className='add-product-order__best-products'>
        {marketCategories.map((category, i) =>
          this.renderCategory(market, category, marketCategories.length > 1 && i < marketCategories.length - 1),
        )}
      </div>
    )
  }

  renderCategory = (market: IMarket, category: IStockCategory, divider = false) => {
    return (
      <React.Fragment key={category.id}>
        <StockCategory market={market} category={category} orderId={this.props.orderId} byProduct />
        {divider && <div className='add-product-order__best-products__divider' />}
      </React.Fragment>
    )
  }

  renderSellersCategories = () => {
    const { sellersCategories } = this.props
    const { formatMessage } = this.props.intl
    const { selectCategory } = this.state
    const options = [{ id: '', name: formatMessage(messages.All) }, ...sellersCategories]

    if (sellersCategories.length === 0) {
      return
    }

    return (
      <div className='add-product-order__sellers-categories'>
        {options.map((item) => (
          <div
            key={item.id}
            className={classnames(
              'add-product-order__sellers-categories__category',
              selectCategory === item.id && 'add-product-order__sellers-categories__category-active',
            )}
            onClick={() => this.selectSellersCategory(item)}
          >
            {item.name}
          </div>
        ))}
      </div>
    )
  }

  renderSellers = () => {
    const { sellers } = this.props
    const { formatMessage } = this.props.intl

    if (sellers.length === 0) {
      return (
        <div className='add-product-order__sellers-empty'>
          <div className='add-product-order__sellers-empty__title'>{formatMessage(messages.ShopsNotFound)}</div>
          <div className='add-product-order__sellers-empty__text'>{formatMessage(messages.StallsSearchEmpty)}</div>
        </div>
      )
    }

    return (
      <div className='add-product-order__sellers' ref={this.sellerRef} onScroll={this.checkEndSellers}>
        {sellers.map((item) => (
          <div key={item.id} className='add-product-order__sellers-seller'>
            <SellerItem seller={item} toSeller={this.toSeller} byProduct />
          </div>
        ))}
      </div>
    )
  }

  renderProducts = () => {
    const { marketProducts } = this.props
    const { formatMessage } = this.props.intl

    if (marketProducts.length === 0) {
      return (
        <div className='add-product-order__products-empty'>
          <div className='add-product-order__products-empty__title'>{formatMessage(messages.ProductsNotFound)}</div>
          <div className='add-product-order__products-empty__text'>
            {formatMessage(messages.BestFromMarketSearchEmpty)}
          </div>
        </div>
      )
    }

    return (
      <div className='add-product-order__products' ref={this.productsRef} onScroll={this.checkEndProducts}>
        {marketProducts.map(this.renderProduct)}
      </div>
    )
  }

  renderProduct = (product: IProduct) => {
    const { orderId, preAddProducts, market } = this.props
    const isPreAdd = preAddProducts.find((item) => item.id === product.id)
    const productOrder = isPreAdd || {
      ...product,
      itemId: '0',
      image: product.imageUrl,
      quantity: 0,
      quantityInitial: 0,
      market: product.marketId,
      currency: market?.currency || DEF_CURRENCY,
    }

    return <ProductItem editable preAdd key={product.id} orderId={orderId} product={productOrder} />
  }

  renderPreAddedProducts = () => {
    const { preAddProducts } = this.props
    const { formatMessage } = this.props.intl
    const { totalPrice, totalWeightKg } = totalByProducts(preAddProducts)
    const currency = preAddProducts.length ? preAddProducts[0].currency : undefined

    return (
      <div className='add-product-order__added'>
        <div className='add-product-order__added-title'>{formatMessage(messages.Added)}:</div>
        <div className='add-product-order__added-info'>
          <div className='add-product-order__added-value'>
            {formatMessage(messages.GoodsR)}: <b>{preAddProducts.length}</b>
          </div>
          <div className='add-product-order__added-dot' />
          <div className='add-product-order__added-value'>
            {formatMessage(messages.Weight)}:{' '}
            <b>
              {totalWeightKg} {formatMessage(messages.Kg).toLowerCase()}
            </b>
          </div>
          <div className='add-product-order__added-dot' />
          <div className='add-product-order__added-value'>
            {formatMessage(messages.ForAmount)}: <b>{formatPrice(totalPrice, currency)}</b>
          </div>
        </div>
      </div>
    )
  }

  selectSellersCategory = (item: ISellerCategory) => {
    this.setState({ selectCategory: item.id }, () => this.getSellersMarket(0))
  }

  checkEndSellers = () => {
    const { sellers, loadingSellers } = this.props

    if (this.sellerRef.current) {
      const { scrollHeight, offsetHeight, scrollTop } = this.sellerRef.current

      if (
        !loadingSellers &&
        sellers.length &&
        sellers.length % SELLERS_LIMIT === 0 &&
        scrollHeight - offsetHeight - scrollTop < 300
      ) {
        this.getSellersMarket(sellers.length)
      }
    }
  }

  checkEndProducts = () => {
    const { marketProducts, loadingProducts } = this.props

    if (this.productsRef.current) {
      const { scrollHeight, offsetHeight, scrollTop } = this.productsRef.current

      if (
        !loadingProducts &&
        marketProducts.length &&
        marketProducts.length % PRODUCTS_LIMIT === 0 &&
        scrollHeight - offsetHeight - scrollTop < 300
      ) {
        this.getMarketProducts(marketProducts.length)
      }
    }
  }

  getSellersMarket = (offset: number) => {
    const { marketId } = this.props
    const { selectCategory, searchText } = this.state

    this.props.apiSellersList({
      offset,
      force: !offset,
      hidden: false,
      searchByProducts: searchText.length >= SEARCH_MIN ? searchText.trim() : undefined,
      ...(marketId && { market: marketId }),
      ...(selectCategory && { category: selectCategory }),
    })
  }

  getMarketProducts = (offset: number) => {
    const { marketId, orderId } = this.props
    const { searchText } = this.state

    this.props.apiMarketProducts({
      offset,
      market: marketId,
      byCategories: true,
      hidden: false,
      search: searchText.length >= SEARCH_MIN ? searchText.trim() : undefined,
      notInOrder: orderId,
    })
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data
    const { searchText, activeTab } = this.state
    const searchString = value.trim()

    this.setState({ searchText: value })

    if (searchString.length >= SEARCH_MIN) {
      if (activeTab === ETab.CATEGORIES) {
        this.setState({ searchText: value }, () => this.getMarketProducts(0))
      } else {
        this.setState({ searchText: value }, () => this.getSellersMarket(0))
      }
    } else if (
      searchText.length >= SEARCH_MIN &&
      (!searchString || (searchString && searchString.length < SEARCH_MIN))
    ) {
      if (activeTab === ETab.SELLERS) {
        this.setState({ searchText: value }, () => this.getSellersMarket(0))
      }
    }
  }

  toSeller = (item: ISeller) => {
    this.setState({ selectSeller: item })
  }

  dropSeller = () => {
    this.setState({ selectSeller: null })
  }

  cancel = () => {
    this.props.dropPreAddProducts()
    this.props.onClose()
  }

  onSave = () => {
    const { preAddProducts, orderId } = this.props

    this.props.apiAddProductsOrder({
      id: orderId,
      products: preAddProducts.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    })
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { loadingCategories, markets } = s.markets
  const { loading: loadingSellers, sellersList, sellersCategories } = s.sellers
  const { loadingMarketProducts, marketProducts, preAddProducts } = s.products

  const market = markets.find((market) => market.id === own.marketId)

  if (!market && markets && markets.length > 0 && !loadingCategories) {
    own.onClose()
  }

  const sellers: ISeller[] = sellersList.filter((seller) => seller.marketId === own.marketId)
  sellers.sort(sortSellerBy)

  return {
    loadingCategories,
    loadingSellers,
    market,
    preAddProducts,
    sellersCategories,
    loadingProducts: loadingMarketProducts,
    sellers: [...sellers],
    marketCategories: markets.find((market) => market.id === own.marketId)?.categories || [],
    marketProducts: marketProducts || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getMarketCategories: (data: ApiMarketCategoriesReq) => dispatch(Actions.action(Actions.API_MARKET_CATEGORIES, data)),
  apiSellersList: (data: ApiSellersListReq) => dispatch(Actions.action(Actions.API_SELLERS_LIST, data)),
  apiMarketProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
  apiAddProductsOrder: (data: ApiAddProductsOrderReq) => dispatch(Actions.action(Actions.API_ADD_PRODUCTS_ORDER, data)),
  dropPreAddProducts: () => dispatch(Actions.actionEmpty(Actions.ORDER_DROP_PRE_ADD_PRODUCTS)),
})

export const AddProductOrderModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddProductOrderCmp))
