import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const CarIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 12.398v4.697c0 .597-.089 1.043-.346 1.51a2.651 2.651 0 0 1-1.105 1.076c-.458.238-.892.319-1.481.319h-1.045c-.59 0-1.023-.081-1.481-.32a2.65 2.65 0 0 1-1.106-1.076 2.661 2.661 0 0 1-.207-.479 47.43 47.43 0 0 1-6.457 0 2.651 2.651 0 0 1-1.313 1.556C7 19.919 6.565 20 5.976 20H4.932c-.59 0-1.023-.081-1.481-.32a2.651 2.651 0 0 1-1.106-1.076C2.09 18.138 2 17.692 2 17.095v-4.697c0-.731.237-1.378.637-1.988.218-.332.407-.56.783-.974a2.71 2.71 0 0 0 .105-.288c.098-.313.17-.652.201-.847l.04-.189c.059-.22.148-.531.255-.854.059-.175.117-.337.177-.485.112-.278.223-.503.388-.73A3.955 3.955 0 0 1 6.2 4.742C7.041 4.37 9.707 4 12 4c2.293 0 4.958.37 5.799.742.65.287 1.2.729 1.615 1.301.165.227.276.452.388.73.06.148.119.31.177.485.107.323.196.634.254.854l.04.19c.032.194.105.533.202.846.037.12.074.219.105.288.376.414.565.642.783.974.4.61.637 1.257.637 1.988ZM12 8.716c2.505 0 4.407.124 5.922.453.177.039.35.08.52.126-.094-.376-.142-.672-.142-.672s-.296-1.118-.504-1.404a1.955 1.955 0 0 0-.805-.648C16.662 6.426 14.48 6 12 6c-2.48 0-4.662.426-4.99.571a1.955 1.955 0 0 0-.806.648c-.208.286-.504 1.404-.504 1.404s-.053.329-.157.734c1.677-.463 3.88-.64 6.457-.64ZM5 18a1 1 0 0 1-1-1v-4c0-1.636 4.008-2.284 8-2.284s8 .326 8 2.284v4a1 1 0 0 1-1 1h-.977c-.324 0-.442-.033-.56-.094a.651.651 0 0 1-.275-.267c-.063-.115-.097-.23-.097-.544V15.95a45.576 45.576 0 0 1-10.182 0v1.146c0 .315-.034.429-.097.544a.651.651 0 0 1-.275.267c-.118.061-.236.094-.56.094H5Z'
      />
      <path
        fill={color}
        d='M9 14a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1ZM19 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z'
      />
    </svg>
  )
}
