import { ENotificationDay, ENotificationFrequency, ENotificationType, ENotificationUsers } from '../types/TClient'

import { intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'

export const dayToString = (day: ENotificationDay) => {
  switch (day) {
    case ENotificationDay.MONDAY:
      return intlMessage(messages.Mon)
    case ENotificationDay.TUESDAY:
      return intlMessage(messages.Tue)
    case ENotificationDay.WEDNESDAY:
      return intlMessage(messages.Wed)
    case ENotificationDay.THURSDAY:
      return intlMessage(messages.Thu)
    case ENotificationDay.FRIDAY:
      return intlMessage(messages.Fri)
    case ENotificationDay.SATURDAY:
      return intlMessage(messages.Sat)
    case ENotificationDay.SUNDAY:
      return intlMessage(messages.Sun)
  }
}

export const typeToString = (type: ENotificationType) => {
  switch (type) {
    case ENotificationType.DAILY:
      return intlMessage(messages.Daily)
    case ENotificationType.BY_DAY_WEEK:
      return intlMessage(messages.ByDayWeek)
    case ENotificationType.SPECIFIC_DAY:
      return intlMessage(messages.Date)
  }
}

export const frequencyToString = (userGroup: ENotificationFrequency, frequencyWeek = 2) => {
  switch (userGroup) {
    case ENotificationFrequency.ONCE:
      return intlMessage(messages.Once)
    case ENotificationFrequency.WEEKLY:
      return intlMessage(messages.ToAllCustomers)
    case ENotificationFrequency.BY_X_WEEK:
      return intlMessage(messages.EveryWeeks, { num: frequencyWeek })
  }
}

export const userGroupToString = (userGroup: ENotificationUsers) => {
  switch (userGroup) {
    case ENotificationUsers.ALL_CUSTOMER:
      return intlMessage(messages.ToAllCustomers)
    case ENotificationUsers.REGISTER_CUSTOMER:
      return intlMessage(messages.ToRegisteredCustomers)
    case ENotificationUsers.NOT_REGISTER_CUSTOMER:
      return intlMessage(messages.ToNotRegisteredCustomers)
    case ENotificationUsers.SPECIFIC_USERS:
      return intlMessage(messages.ForSpecificCustomers)
  }
}
