import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Icon } from 'semantic-ui-react'

import './index.scss'

import messages from '../../localization/messages'

type TProps = {
  color?: string,
  title?: string,
  imageUrl?: string,
  interactive?: boolean,
  productsCount?: number,
  onEdit?(): void,
  onRemove?(): void,
  onClick?(): void,
} & WrappedComponentProps

const DEFAULT_COLOR = '#7677BC'

const _TagCard: React.FC<TProps> = ({
  color = DEFAULT_COLOR,
  title,
  interactive = true,
  productsCount = 0,
  imageUrl,
  onClick,
  onRemove,
  onEdit,
  intl,
}) => {
  return (
    <div
      className='tag-card'
      style={{
        backgroundColor: color || DEFAULT_COLOR,
        ...(!interactive && {
          transition: 'none',
          pointerEvents: 'none',
        }),
      }}
      onClick={onClick}
    >
      <div className='tag-card__image' style={{ backgroundImage: `url('${imageUrl}')` }} />
      {interactive && (
        <div className='tag-card__icons'>
          {onEdit && (
            <div className='tag-card__icons__icon' onClick={(e) => onClickIcon(onEdit, e)}>
              <Icon className='tag-card__icons__icon-edit' name='pencil' />
            </div>
          )}
          {onRemove && (
            <div className='tag-card__icons__icon' onClick={(e) => onClickIcon(onRemove, e)}>
              <Icon className='tag-card__icons__icon tag-card__icons__icon-trash' name='trash' />
            </div>
          )}
        </div>
      )}
      <div className='tag-card__title'>{title || intl.formatMessage(messages.TagName)}</div>
      <div className='tag-card__count'>{productsCount}</div>
    </div>
  )

  function onClickIcon(fn: () => void, event?: React.MouseEvent<HTMLDivElement>) {
    if (event) {
      event.stopPropagation()
    }
    fn()
  }
}

export const TagCard = injectIntl(_TagCard)
