import React from 'react'
import { IntlProvider, createIntl, createIntlCache, IntlShape } from 'react-intl'
import { connect } from 'react-redux'

import { State } from '../../store/reducer'
import { Message } from '../../localization/messages'
import en from '../../localization/en'
import ru from '../../localization/ru'
import { LANG_DEF } from '../../utils/langUtils'

const messagesDict: { [l: string]: any } = { ru, en }

const cache = createIntlCache()
let intl: IntlShape | null

type IntlProps = { lang: string, children?: React.ReactNode }

export const LangCmp = (props: IntlProps) => {
  intl = createIntl(
    {
      locale: props.lang,
      messages: messagesDict[props.lang],
    },
    cache,
  )

  return (
    <IntlProvider locale={props.lang} messages={messagesDict[props.lang]} defaultLocale={LANG_DEF}>
      {props.children}
    </IntlProvider>
  )
}

export const LangContainer = connect((s: State) => ({ lang: s.lang }))(LangCmp)

export const intlMessage = (d: Message, v?: any) => (intl ? intl.formatMessage(d, v) : '')

export const getIntl = () => intl
