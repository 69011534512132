import { Reducer } from 'redux'
import { uniqBy } from 'lodash'

import {
  EImageType,
  EModerateProductStatus,
  ICategory,
  ICountry,
  IGroupProducts,
  IModerationProduct,
  IModerationProductsFilter,
  IProduct,
  IProductOrder,
  ISellerProduct,
  IStep,
  IUnit,
} from '../../types/TClient'

import * as Actions from '../actions'
import { sortProductBy } from '../../utils/productsUtils'

export type MutableStateProducts = {
  uploadingProductImg: boolean,
  updateLoading: boolean,
  loadingMarketProducts: boolean,
  loading: boolean,
  loaded: boolean,
  savedImageUrl: string,
  sellersProducts: ISellerProduct[],
  marketProducts: IProduct[],
  preAddProducts: IProductOrder[],
  activeGroup?: string,
  groupProducts: IGroupProducts[],
  moderationProducts: {
    loading: boolean,
    loaded: boolean,
    filter: IModerationProductsFilter,
    total: number,
    totalOnModeration: number,
    list: IModerationProduct[],
  },
  countries: ICountry[],
  categories: ICategory[],
  steps: IStep[],
  units: IUnit[],
  errorApi: string,
}

export type StateProducts = Readonly<MutableStateProducts>

const defStateProducts: StateProducts = {
  uploadingProductImg: false,
  updateLoading: false,
  loadingMarketProducts: false,
  loading: false,
  loaded: false,
  savedImageUrl: '',
  sellersProducts: [],
  marketProducts: [],
  preAddProducts: [],
  groupProducts: [],
  moderationProducts: {
    loading: false,
    loaded: false,
    filter: {
      statuses: [EModerateProductStatus.NEW],
    },
    total: 0,
    totalOnModeration: 0,
    list: [],
  },
  countries: [],
  categories: [],
  steps: [],
  units: [],
  errorApi: '',
}

export const products: Reducer<StateProducts, Actions.Action> = (s = defStateProducts, a): StateProducts => {
  switch (a.type) {
    case Actions.API_SELLER_PRODUCTS:
      return {
        ...s,
        loaded: false,
        loading: true,
      }
    case Actions.SELLER_PRODUCTS: {
      return {
        ...s,
        loading: false,
        loaded: a.data.products.length === 0,
        sellersProducts: a.data.force
          ? a.data.products.sort(sortProductBy)
          : uniqBy([...(s.sellersProducts || []), ...a.data.products], 'id').sort(sortProductBy),
      }
    }
    case Actions.API_PRODUCT_ERROR:
      return {
        ...s,
        loading: false,
        ...(a.data.error && { errorApi: a.data.error }),
      }
    case Actions.DROP_PRODUCT_ERROR:
      return {
        ...s,
        loading: false,
        errorApi: '',
      }
    case Actions.PRODUCT_COUNTRIES:
      return {
        ...s,
        loading: false,
        countries: a.data.countries,
      }
    case Actions.PRODUCT_CATEGORIES:
      return {
        ...s,
        loading: false,
        categories: a.data.categories,
      }
    case Actions.PRODUCT_STEPS:
      return {
        ...s,
        loading: false,
        steps: a.data.steps,
      }
    case Actions.PRODUCT_UNITS:
      return {
        ...s,
        loading: false,
        units: a.data.units,
      }
    case Actions.PRODUCT_SAVED_IMAGE:
      return {
        ...s,
        loading: false,
        savedImageUrl: a.data.imageUrl,
      }
    case Actions.DROP_PRODUCT_SAVED:
      return {
        ...s,
        loading: false,
        savedImageUrl: '',
      }
    case Actions.API_PRODUCT_CREATE:
      return {
        ...s,
        loaded: false,
        loading: true,
      }
    case Actions.PRODUCT_CREATE:
      return {
        ...s,
        loading: false,
        sellersProducts: uniqBy([a.data, ...(s.sellersProducts || [])], 'id'),
      }
    case Actions.API_PRODUCT_UPDATE:
      return {
        ...s,
        loaded: false,
        loading: true,
      }
    case Actions.PRODUCT_UPDATE: {
      let newProducts = (s.sellersProducts || []).map((product) => {
        if (product.id === a.data.id) {
          return {
            ...product,
            ...a.data,
          }
        }

        return product
      })

      newProducts = newProducts.sort(sortProductBy)

      return {
        ...s,
        loading: false,
        sellersProducts: newProducts,
      }
    }
    case Actions.API_PRODUCT_DELETE:
      return {
        ...s,
        loading: true,
      }
    case Actions.PRODUCT_DELETE:
      return {
        ...s,
        loaded: false,
        loading: false,
        sellersProducts: s.sellersProducts.filter((product) => product.id !== a.data.id),
      }
    case Actions.API_UPLOAD_IMAGE: {
      if (a.data.type === EImageType.PRODUCT_IMAGE) {
        return {
          ...s,
          loading: true,
        }
      }

      return s
    }
    case Actions.API_UPLOAD_IMAGE_ERROR: {
      if (a.data.type === EImageType.PRODUCT_IMAGE) {
        return {
          ...s,
          loading: false,
        }
      }

      return s
    }
    case Actions.API_TAG_PRODUCTS:
      return {
        ...s,
        sellersProducts: [],
      }
    case Actions.API_MARKET_PRODUCTS: {
      return {
        ...s,
        loadingMarketProducts: true,
      }
    }
    case Actions.MARKET_PRODUCTS: {
      const newProducts = a.data.force
        ? a.data.products
        : uniqBy([...(s.marketProducts || []), ...a.data.products], 'id')

      return {
        ...s,
        loadingMarketProducts: false,
        marketProducts: newProducts,
      }
    }
    case Actions.MARKET_PRODUCT: {
      const newProducts = (s.marketProducts || []).map((product) => {
        if (product.id === a.data.product.id) {
          return {
            ...product,
            ...a.data.product,
          }
        }

        return product
      })

      return {
        ...s,
        loading: false,
        marketProducts: newProducts,
      }
    }
    case Actions.API_PRODUCTS_MODERATION_LIST: {
      return {
        ...s,
        moderationProducts: {
          ...s.moderationProducts,
          loading: true,
          loaded: false,
        },
      }
    }
    case Actions.PRODUCTS_MODERATION_LIST: {
      const newProducts = a.data.force
        ? a.data.products
        : uniqBy([...(s.moderationProducts.list || []), ...a.data.products], 'id')

      return {
        ...s,
        moderationProducts: {
          ...s.moderationProducts,
          loading: false,
          loaded: !a.data.products.length,
          list: newProducts,
          total: a.data.total || newProducts.length,
          ...(a.data.total && a.data.totalOnModeration && { totalOnModeration: a.data.totalOnModeration }),
        },
      }
    }
    case Actions.MODERATION_PRODUCTS_FILTER: {
      return {
        ...s,
        moderationProducts: {
          filter: { ...a.data },
          total: 0,
          totalOnModeration: 0,
          list: [],
          loaded: false,
          loading: false,
        },
      }
    }
    case Actions.UPDATE_MODERATION_PRODUCT: {
      const newProducts = s.moderationProducts.list.map((item) => {
        if (item.id === a.data.id) {
          return a.data
        }

        return item
      })

      return {
        ...s,
        moderationProducts: {
          ...s.moderationProducts,
          loading: false,
          list: newProducts,
          totalOnModeration: s.moderationProducts.totalOnModeration - 1,
        },
      }
    }
    case Actions.ORDER_PRE_ADD_PRODUCT: {
      let newPreAddProducts = s.preAddProducts
      let updated = false

      newPreAddProducts = newPreAddProducts.map((item) => {
        if (item.id === a.data.product.id) {
          updated = true

          return {
            ...a.data.product,
          }
        }

        return item
      })

      if (!updated) {
        newPreAddProducts.push(a.data.product)
      }

      return {
        ...s,
        preAddProducts: newPreAddProducts,
      }
    }
    case Actions.ORDER_REMOVE_PRE_ADD_PRODUCT: {
      return {
        ...s,
        preAddProducts: s.preAddProducts.filter((item) => item.id !== a.data.id),
      }
    }
    case Actions.ORDER_DROP_PRE_ADD_PRODUCTS: {
      return {
        ...s,
        preAddProducts: [],
      }
    }
    case Actions.GROUPS_LIST: {
      return {
        ...s,
        groupProducts: a.data.groups,
      }
    }
    case Actions.CREATE_GROUP_PRODUCT: {
      return {
        ...s,
        groupProducts: [...s.groupProducts, a.data],
      }
    }
    case Actions.SET_GROUP_PRODUCTS: {
      return {
        ...s,
        groupProducts: s.groupProducts.map((group) => {
          if (group.id === a.data.groupId) {
            return {
              ...group,
              products: a.data.products,
            }
          }

          return group
        }),
      }
    }
    case Actions.UPDATE_GROUP_PRODUCT: {
      return {
        ...s,
        groupProducts: s.groupProducts.map((group) => {
          if (group.id === a.data.id) {
            return {
              ...a.data,
            }
          }

          return group
        }),
      }
    }
    case Actions.REMOVE_GROUP_PRODUCT: {
      return {
        ...s,
        groupProducts: s.groupProducts.filter((group) => group.id !== a.data),
      }
    }
  }
  return s
}
