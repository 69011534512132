import * as React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'

import './index.scss'

import { State } from '../../store/reducer'
import { EModalType, IModal } from '../../store/reducers/modals'
import * as Actions from '../../store/actions'
import { ImageCropperModal } from '../../components/ImageCropper'
import { UpdateSellerModal } from '../../components/Modal/UpdateSellerModal'
import { ProductModal } from '../../components/Modal/ProductNewModal'
import { ProductChangeModal } from '../../components/Modal/ProductChangeModal'
import { DeleteNotificationModal } from '../../components/Modal/DeleteNotificationModal'
import { OrdersFilterModal } from '../../components/Modal/OrdersFilterModal'
import { DeliveryChangeModal } from '../../components/Modal/DeliveryChangeModal'
import { EditCreateTagModal } from '../../components/Modal/EditCreateTagModal'
import { ProductTagChangeModal } from '../../components/Modal/ProductChangeTagModal'
import { AssignOrderModal } from '../../components/Modal/AssignOrderModal'
import { AddProductOrderModal } from '../../components/Modal/AddProductOrderModal'
import { OrderModal } from '../../components/Modal/OrderModal'
import { CustomerModal } from '../../components/Modal/CustomerModal'
import { CustomerPhoneMeModal } from '../../components/Modal/CustomerPhoneMeModal'
import { CustomersFilterModal } from '../../components/Modal/CustomersFilterModal'
import { RejectProductModal } from '../../components/Modal/RejectProductModal'
import { CourierShiftModal } from '../../components/Modal/CourierShiftModal'

type Props = {
  modal: IModal | null,
  close(): any,
  closeAll(): any,
}

type IState = {
  modal: IModal | null,
  key: number,
}

class ModalCmp extends React.Component<Props, IState> {
  state = {
    modal: null,
    key: 1,
  }

  render() {
    const cmp1 = this.renderModal(this.state.modal)
    const cmp2 = this.renderModal(this.props.modal)
    const result = []

    if (cmp1) {
      result.push(cmp1)
    }
    if (cmp2) {
      result.push(cmp2)
    }
    return result.length ? result : null
  }

  private renderModal(modal: IModal | null) {
    return modal ? (
      <Modal
        open
        closeIcon={!!modal.close}
        basic={!!modal.basic}
        key={modal.type}
        size={modal.size ? modal.size : 'small'}
        style={modal.style}
        onClose={this.props.close}
      >
        {this.renderContent(modal)}
      </Modal>
    ) : null
  }

  private renderContent(modal: IModal) {
    switch (modal.type) {
      case EModalType.MODAL_IMAGE_CROPPER:
        return <ImageCropperModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_EDIT_SELLER:
        return <UpdateSellerModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_PRODUCT:
        return <ProductModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_PRODUCT_CHANGE:
        return <ProductChangeModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_DELETE_NOTIFICATION:
        return <DeleteNotificationModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_ORDERS_FILTER:
        return <OrdersFilterModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_DELIVERY_CHANGE:
        return <DeliveryChangeModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_EDIT_CREATE_TAG:
        return <EditCreateTagModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_EDIT_PRODUCT_TAG:
        return <ProductTagChangeModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_ASSIGN_ORDER:
        return <AssignOrderModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_ADD_PRODUCT_ORDER:
        return <AddProductOrderModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_ORDER:
        return <OrderModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_CUSTOMER:
        return <CustomerModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_CUSTOMER_CALL_ME:
        return <CustomerPhoneMeModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_CUSTOMERS_FILTER:
        return <CustomersFilterModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_REJECT_PRODUCT:
        return <RejectProductModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_COURIER_SHIFT:
        return <CourierShiftModal onClose={this.props.close} {...modal.props} />
    }
  }
}

const mapStateToProps = (s: State) => {
  const modals = s.modals.modals
  if (!modals.length) {
    return { modal: null }
  }
  return { modal: modals[modals.length - 1] }
}

const mapDispatchToProps = {
  close: () => Actions.action(Actions.MODAL_POP, {}),
  closeAll: () => Actions.actionEmpty(Actions.MODAL_CLEAR),
}

export const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(ModalCmp)
