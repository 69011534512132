import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Menu, Container, Modal, Button, Header, Dropdown, Icon } from 'semantic-ui-react'

import './index.scss'

import { EUserRole, IUser } from '../../types/TClient'

import messages from '../../localization/messages'
import { State } from '../../store/reducer'
import * as Actions from '../../store/actions'
import langs from '../../localization/langs'

type TOwnProps = {
  leftAction?: () => void,
}

type TConnectedProps = {
  lang: string,
  user?: IUser,
}

type TDispatchedProps = {
  logout: () => Actions.Action,
  selectLang: (lang: string) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & RouteComponentProps & WrappedComponentProps

type IState = {
  modalLogout: boolean,
}

class HeaderPanelCmp extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      modalLogout: false,
    }
  }

  render() {
    const { user, leftAction } = this.props

    return (
      <div className='header'>
        <Menu fixed='top'>
          <Container fluid>
            {this.renderModal()}
            {leftAction !== undefined && (
              <div className='header__menu' onClick={leftAction}>
                <Icon className='header__menu-icon' name='bars' size='large' />
              </div>
            )}
            <div className='header__local'>
              {this.renderLang()}
              {user && (
                <Menu.Item
                  className='header__user'
                  position='right'
                  onClick={() => this.setState({ modalLogout: true })}
                >
                  <Icon name='user' />
                  <div className='header__user-info'>
                    <div className='header__user-name'>{user.login}</div>
                    <div className='header__user-role'>{this.userRole(user.role)}</div>
                  </div>
                </Menu.Item>
              )}
            </div>
          </Container>
        </Menu>
      </div>
    )
  }

  renderLang = () => {
    const { lang } = this.props
    const langDesc = langs.find((item) => item.language === lang)

    return (
      <div className='header__language'>
        <Dropdown className='header__language-text' text={langDesc?.nativeName} icon='chevron down'>
          <Dropdown.Menu className='header__language-menu'>
            {langs.map((item) => (
              <Dropdown.Item key={item.language} onClick={() => this.selectLang(item.language)}>
                {item.nativeName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }

  renderModal = () => {
    const { formatMessage } = this.props.intl

    return (
      <Modal
        basic
        onClose={() => this.setState({ modalLogout: false })}
        onOpen={() => this.setState({ modalLogout: true })}
        open={this.state.modalLogout}
        size='small'
      >
        <Header icon>
          <Icon name='sign-out alternate' />
          {formatMessage(messages.SignOut)}
        </Header>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => this.setState({ modalLogout: false })}>
            <Icon name='remove' /> {formatMessage(messages.No)}
          </Button>
          <Button color='green' inverted onClick={this.userLogout}>
            <Icon name='checkmark' /> {formatMessage(messages.Yes)}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  selectLang = (lang: string) => {
    this.props.selectLang(lang)
  }

  userLogout = () => {
    this.setState({ modalLogout: false })
    this.props.logout()
  }

  userRole = (role: EUserRole) => {
    const { formatMessage } = this.props.intl

    switch (role) {
      case EUserRole.ADMIN:
        return formatMessage(messages.Admin)
      case EUserRole.MANAGER:
        return formatMessage(messages.Manager)
      case EUserRole.OPERATOR:
        return formatMessage(messages.Operator)
    }
  }

  onTitleClick = () => {
    this.props.history.push('/')
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  lang: s.lang,
  user: s.user.user,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  logout: () => dispatch(Actions.actionEmpty(Actions.LOGOUT)),
  selectLang: (lang: string) => dispatch(Actions.action(Actions.SET_INTERFACE_LANG, { lang })),
})

export const HeaderPanel = connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(HeaderPanelCmp)))
