import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { ICity, ICurrencyPairs, IMarket, ITotalOrders } from '../../../../../types/TClient'

import { State } from '../../../../../store/reducer'
import { AnalyticsOrders } from '../AnalyticsOrders'
import { AnalyticsCustomers } from '../AnalyticsCustomers'
import { AnalyticsRevenue } from '../AnalyticsRevenue'
import { AnalyticsOrdersByPeriod } from '../AnalyticsOrdersByPeriod'

type TOwnProps = {
  marketInfo: ITotalOrders,
}

type TConnectedProps = {
  currencyPairs: ICurrencyPairs,
  markets: IMarket[],
  cities: ICity[],
}

type Props = TOwnProps & TConnectedProps & WrappedComponentProps

class MarketAnalyticsCmp extends React.Component<Props> {
  render() {
    const { marketInfo } = this.props

    return (
      <div className='market-analytics__content' style={{ marginTop: marketInfo.marketName ? 0 : 20 }}>
        {marketInfo.marketName && <div className='market-analytics__content-title'>{marketInfo.marketName}</div>}
        <div className='market-analytics__content-blocks'>
          {this.renderOrders()}
          {this.renderOrdersByPeriod()}
          {this.renderCustomers()}
          {this.renderRevenue()}
        </div>
      </div>
    )
  }

  renderOrders = () => {
    return <AnalyticsOrders marketInfo={this.props.marketInfo} />
  }

  renderOrdersByPeriod = () => {
    return <AnalyticsOrdersByPeriod marketInfo={this.props.marketInfo} />
  }

  renderCustomers = () => {
    return <AnalyticsCustomers marketInfo={this.props.marketInfo} />
  }

  renderRevenue = () => {
    return <AnalyticsRevenue marketInfo={this.props.marketInfo} currencyPairs={this.props.currencyPairs} />
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { currencyPairs } = s.analytics

  return {
    currencyPairs,
    markets: s.markets.markets || [],
    cities: s.markets.cities || [],
  }
}

export const MarketAnalytics = connect<TConnectedProps, null, TOwnProps, State>(mapStateToProps)(
  injectIntl(MarketAnalyticsCmp),
)
