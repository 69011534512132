import React from 'react'
import classnames from 'classnames'

import './index.scss'

type TOwnProps = {
  vertical?: boolean,
}

type TProps = TOwnProps

class ProductDividerCmp extends React.Component<TProps> {
  render() {
    const { vertical } = this.props

    return <div className={classnames(vertical ? 'moderate-product__vertical-divider' : 'moderate-product__divider')} />
  }
}

export const ProductDivider = ProductDividerCmp
