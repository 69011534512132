import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import moment from 'moment/moment'

import './index.scss'

import { HistoryPickedAction, IOrder } from '../../../../types/TClient'

import { Action } from '../Action'
import messages from '../../../../localization/messages'
import { getCollectedPlan } from '../../../../utils/ordersUtils'

interface IProps extends HistoryPickedAction {
  order: IOrder,
}

type TProps = IProps & WrappedComponentProps

const _PickAction: React.FC<TProps> = ({ date, order, intl }) => {
  const { formatMessage } = intl
  const collectPlan = getCollectedPlan(order)
  const assemblyLate = collectPlan.diff(order.collectedAt, 'minute', true)
  const collectedAt = order.collectedAt ? moment(order.collectedAt) : ''
  const isLate = assemblyLate <= -1

  return (
    <Action date={date}>
      <div className='pick-action__title'>
        <FormattedMessage {...messages.OrderPicked} />
      </div>
      <div>
        <FormattedMessage {...messages.IsLate} />{' '}
        <span style={{ color: isLate ? '#eb5757' : '' }}>
          {isLate
            ? `${Math.floor(Math.abs(assemblyLate))} ${formatMessage(messages.Min).toLowerCase()}`
            : collectedAt
            ? formatMessage(messages.No).toLowerCase()
            : '-'}
        </span>
      </div>
    </Action>
  )
}

export const PickAction = injectIntl(_PickAction)
