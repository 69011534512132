import React from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header } from 'semantic-ui-react'

import './index.scss'

import { IMarket } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import * as Actions from '../../../../store/actions'
import { State } from '../../../../store/reducer'
import { Polygons } from './Polygons'
import { Categories } from './Categories'
import { Slots } from './Slots'
import { Catalog } from './Catalog'
import { ProductsChain } from './ProductsChain'
import { ITab, Tabs } from '../../../../components/Tabs'
import { LOCATION_MARKETS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  market?: IMarket,
  markets: IMarket[],
}

type TDispatchedProps = {
  getMarkets: () => Actions.Action,
}

type TProps = RouteComponentProps<{ id: string }> & TConnectedProps & TDispatchedProps & WrappedComponentProps

enum ETab {
  POLYGONS = 'polygons',
  SLOTS = 'slots',
  CATEGORIES = 'categories',
  CATALOG = 'catalog',
  PRODUCTS_CHAIN = 'products_chain',
}

type TState = {
  editPolygons: boolean,
}

class MarketCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      editPolygons: false,
    }
  }

  componentDidMount(): void {
    this.props.getMarkets()
  }

  getTabsData = (): ITab[] => {
    const { market } = this.props
    const { formatMessage } = this.props.intl

    if (!market) {
      return []
    }

    return [
      {
        name: formatMessage(messages.Polygons),
        id: ETab.POLYGONS,
        component: this.renderPolygons(),
        tabActions: this.editPolygon,
      },
      { name: formatMessage(messages.Slots), id: ETab.SLOTS, component: <Slots market={market.id} /> },
      { name: formatMessage(messages.Assortment), id: ETab.CATEGORIES, component: <Categories market={market} /> },
      { name: formatMessage(messages.Catalog), id: ETab.CATALOG, component: <Catalog market={market} /> },
      {
        name: formatMessage(messages.ProductsChain),
        id: ETab.PRODUCTS_CHAIN,
        component: <ProductsChain market={market} />,
      },
    ]
  }

  render() {
    const { market } = this.props

    return (
      <div className='market-wrapper'>
        {market && (
          <div className='market'>
            <Header as='h1'>{market.name}</Header>
            {this.renderInfo(market)}
            <Tabs className='market-content' tabs={this.getTabsData()} />
          </div>
        )}
      </div>
    )
  }

  editPolygon = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const { editPolygons } = this.state

    if (!market) {
      return null
    }

    return (
      <>
        {!editPolygons && market.delivery_areas && market.delivery_areas.length > 0 && (
          <div className='market-edit-btn' onClick={() => this.setState({ editPolygons: true })}>
            {formatMessage(messages.EditPolygons)}
          </div>
        )}
      </>
    )
  }

  renderInfo = (market: IMarket) => {
    const { formatMessage } = this.props.intl

    return (
      <div className='market-info'>
        <div className='market-info__item'>
          <span className='market-info__item-label'>{formatMessage(messages.Address)}:</span>
          {formatMessage(messages.CityShort)} {market.city}, {market.address}
        </div>
        <div className='market-info__item'>
          <span className='market-info__item-label'>{formatMessage(messages.OpeningHours)}:</span>
          {formatMessage(messages.MonSunFromTo, {
            startTime: market.open_hours_start.slice(0, -3),
            endTime: market.open_hours_end.slice(0, -3),
          })}
        </div>
        <div className='market-info__item'>
          <span className='market-info__item-label'>{formatMessage(messages.DeliveryHours)}:</span>
          {formatMessage(messages.MonSunFromTo, {
            startTime: market.delivery_start_time.slice(0, -3),
            endTime: market.delivery_end_time.slice(0, -3),
          })}
        </div>
      </div>
    )
  }

  renderPolygons = () => {
    const { market } = this.props

    if (!market) {
      return <div />
    }

    return (
      <Polygons market={market} edit={this.state.editPolygons} onEdit={() => this.setState({ editPolygons: false })} />
    )
  }
}

const mapStateToProps = (s: State, own: TProps): TConnectedProps => {
  const { loading, markets } = s.markets

  const market = markets.find((market) => market.id === own.match.params.id)

  if (!market && markets && markets.length > 0 && !loading) {
    own.history.push(`${LOCATION_MARKETS}`)
  }

  return {
    market,
    markets: s.markets.markets || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getMarkets: () =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        include_all: 'True',
      }),
    ),
})

export const Market = connect(mapStateToProps, mapDispatchToProps)(injectIntl(MarketCmp))
