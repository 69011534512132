import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { ICurrencyPairs, ITotalOrders } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import { AnalyticsBlockStat } from '../AnalyticsBlockStat'
import { AnalyticsPieChart } from '../AnalyticsPieChart'
import { DropdownField } from '../../../../../components/DropdownField'
import { convertCurrency, formatPrice } from '../../../../../utils/productsUtils'
import { round } from '../../../../../utils/ordersUtils'
import { convertPriceByCurrency } from '../../../../../utils/analyticsUtils'

type TOwnProps = {
  marketInfo: ITotalOrders,
  currencyPairs: ICurrencyPairs,
}

type TProps = TOwnProps & WrappedComponentProps

type TState = {
  currencyCode: string,
}

const COMMISSION_COLOR = '#6516ce'
const DELIVERY_COLOR = '#b894e8'
const ITEMS_COLOR = '#d3cdd8'

class AnalyticsRevenueCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      currencyCode: props.marketInfo.currency.code,
    }
  }

  render() {
    const { marketInfo } = this.props
    const { formatMessage } = this.props.intl
    const { currencyCode } = this.state

    const currencies = marketInfo.currencies || (marketInfo.currency ? [marketInfo.currency] : [])
    const options = currencies.map((item) => ({
      key: item.name,
      value: item.code,
      text: `${item.symbol_native} - ${item.name}`,
    }))

    return (
      <div className='analytics__block-container'>
        <div className='analytics__block-title'>
          {formatMessage(messages.Profit)}
          <div className='analytics__block-revenue__currency'>
            <DropdownField
              search
              value={currencyCode}
              label={formatMessage(messages.Currency)}
              options={options}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='analytics__block'>
          {this.renderHeader()}
          {this.renderChart()}
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { marketInfo, currencyPairs } = this.props
    const { formatMessage } = this.props.intl
    const { currencyCode } = this.state
    const price = convertPriceByCurrency(marketInfo.totalPrice, currencyPairs, marketInfo.currency.code, currencyCode)
    const currency = convertCurrency(currencyCode)

    return (
      <div className='analytics__block-header'>
        <AnalyticsBlockStat label={formatMessage(messages.Turnover)} value={formatPrice(price, currency)} />
        <AnalyticsBlockStat
          label={formatMessage(messages.AverageBill)}
          value={formatPrice(marketInfo.totalNumDelivered ? round(price / marketInfo.totalNumDelivered) : 0, currency)}
        />
      </div>
    )
  }

  renderChart = () => {
    const { marketInfo, currencyPairs } = this.props
    const { formatMessage } = this.props.intl
    const { currencyCode } = this.state
    const price = convertPriceByCurrency(marketInfo.totalPrice, currencyPairs, marketInfo.currency.code, currencyCode)
    const commission = convertPriceByCurrency(
      marketInfo.totalCommission,
      currencyPairs,
      marketInfo.currency.code,
      currencyCode,
    )
    const deliveryPrice = convertPriceByCurrency(
      marketInfo.totalDeliveryPrice,
      currencyPairs,
      marketInfo.currency.code,
      currencyCode,
    )
    const currency = convertCurrency(currencyCode)

    const chartData = [
      {
        name: formatMessage(messages.PaymentForGoods),
        value: round(price - commission - deliveryPrice),
        color: ITEMS_COLOR,
      },
      { name: formatMessage(messages.Delivery), value: round(deliveryPrice), color: DELIVERY_COLOR },
      { name: formatMessage(messages.CommissionPlatform), value: round(commission), color: COMMISSION_COLOR },
    ]

    return (
      <div className='analytics__block-chart'>
        <div className='analytics__block-chart__legend'>
          <AnalyticsBlockStat
            label={formatMessage(messages.CommissionPlatform)}
            value={formatPrice(commission, currency)}
            color={COMMISSION_COLOR}
          />
          <AnalyticsBlockStat
            label={formatMessage(messages.Delivery)}
            value={formatPrice(deliveryPrice, currency)}
            color={DELIVERY_COLOR}
          />
          <AnalyticsBlockStat
            label={formatMessage(messages.PaymentForGoods)}
            value={formatPrice(price - commission - deliveryPrice, currency)}
            color={ITEMS_COLOR}
          />
        </div>
        <div>
          <AnalyticsPieChart data={chartData} />
        </div>
      </div>
    )
  }

  handleChange = (event: any, data: any) => {
    const { value } = data

    this.setState({ currencyCode: value })
  }
}

export const AnalyticsRevenue = injectIntl(AnalyticsRevenueCmp)
