import { of as observableOf, EMPTY } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'

import * as TApi from '../../types/TApi'
import { EUserRole } from '../../types/TClient'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import { URL_LOGIN, URL_SIGN_UP, URL_USER_INFO } from '../../modules/network/urls'
import { historyReplace, LOCATION_MAIN } from '../../utils/locationUtils'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'

const loginEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiLogin>(a$, Actions.API_LOGIN), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_LOGIN, a.data).pipe(
          mergeMap((resp: { data: TApi.ApiLoginResp }) => {
            historyReplace(LOCATION_MAIN)
            return observableOf<Actions.Action>(
              Actions.action(Actions.USER, {
                login: a.data.login,
                email: resp.data.user.email,
                role: resp.data.user.role as any as EUserRole,
                sid: resp.data.sid,
              }),
            )
          }),
          catchError((_err) => {
            return observableOf<Actions.Action>(Actions.action(Actions.USER_LOGIN_ERROR, { error: 'Api error' }))
          }),
        ),
      ),
    ),
  )

const signUpEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSignUp>(a$, Actions.API_SIGN_UP), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(
          URL_SIGN_UP,
          {
            email: a.data.email,
            login: a.data.login,
            role: a.data.role,
            password: a.data.password,
          },
          {
            headers: { Authorization: 'Bearer ' + a.data.adminKey },
          },
        ).pipe(
          mergeMap((resp: { data: TApi.ApiSignUpResp }) => {
            historyReplace(LOCATION_MAIN)
            return observableOf<Actions.Action>(
              Actions.action(Actions.USER, {
                login: a.data.login,
                email: a.data.email,
                role: a.data.role as any as EUserRole,
                sid: resp.data.sid,
              }),
            )
          }),
          catchError((err) => {
            const error = err.response && err.response.status === 401 ? ': Invalid key' : ''
            return observableOf<Actions.Action>(
              Actions.action(Actions.USER_SIGN_UP_ERROR, { error: 'Api error' + error }),
            )
          }),
        ),
      ),
    ),
  )

const userInfoEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUserInfo>(a$, Actions.API_USER_INFO), (b) =>
    b.pipe(
      mergeMap((_a) =>
        Axios.get(URL_USER_INFO, authRequestConfig()).pipe(
          mergeMap((resp: { data: TApi.ApiUserInfoResp }) => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.USER, {
                login: resp.data.login,
                email: resp.data.email,
                role: resp.data.role as any as EUserRole,
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const userEpics: EpicFunc[] = [loginEpic, signUpEpic, userInfoEpic]
