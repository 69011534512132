import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const ForwardIcon: React.FC<TProps> = ({ width = 24, height = 24, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={color}
        d='m8.625 5.75 5.75 5.75-5.75 5.75'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
