import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import messages from '../../../localization/messages'
import { CheckIcon, CloseIcon } from '../../Icons'

type TOwnProps = {
  customerCallMe: {
    callMe?: boolean,
    aboutChangeProduct?: boolean,
    aboutDeleteProduct?: boolean,
    aboutChangeProductWeight?: boolean,
    changeProduct?: boolean,
    deleteProductByWeight?: boolean,
  },
  onClose: () => void,
}

type Props = TOwnProps & WrappedComponentProps

class _CustomerPhoneMeModal extends React.Component<Props> {
  render() {
    const { callMe } = this.props.customerCallMe
    const { formatMessage } = this.props.intl

    return (
      <div className='modal-customer-phone-me'>
        <div className='modal-customer-phone-me__header'>
          <div className='modal-customer-phone-me__title'>
            {callMe ? formatMessage(messages.CallMe) : formatMessage(messages.NotCallMe)}
          </div>
        </div>
        {callMe ? this.renderCallMe() : this.renderNotCallMe()}
      </div>
    )
  }

  renderNotCallMe = () => {
    const { changeProduct, deleteProductByWeight } = this.props.customerCallMe
    const { formatMessage } = this.props.intl

    return (
      <div className='modal-customer-phone-me__not-call-me'>
        <div className='modal-customer-phone-me__block'>
          <div className='modal-customer-phone-me__block-title'>{formatMessage(messages.IfProductOutOfStock)}:</div>
          <div className='modal-customer-phone-me__block-value'>
            {changeProduct ? formatMessage(messages.PickReplacement) : formatMessage(messages.DeleteProduct)}
          </div>
        </div>
        <div className='modal-customer-phone-me__block'>
          <div className='modal-customer-phone-me__block-title'>{formatMessage(messages.IfWeightItemChanged)}:</div>
          <div className='modal-customer-phone-me__block-value'>
            {deleteProductByWeight
              ? formatMessage(messages.RemoveItemIfWeightChanges)
              : formatMessage(messages.ChangeItemWeight)}
          </div>
        </div>
      </div>
    )
  }

  renderCallMe = () => {
    const { aboutChangeProduct, aboutDeleteProduct, aboutChangeProductWeight, changeProduct, deleteProductByWeight } =
      this.props.customerCallMe
    const { formatMessage } = this.props.intl

    return (
      <div className='modal-customer-phone-me__call-me'>
        <div className='modal-customer-phone-me__call-me-item'>
          <div className='modal-customer-phone-me__call-me-item__icon'>
            {aboutChangeProduct ? <CheckIcon color='#00c752' /> : <CloseIcon color='#d8553f' />}
          </div>
          <div className='modal-customer-phone-me__call-me-item__text'>
            {formatMessage(messages.AboutProductReplacement)}
          </div>
        </div>
        <div className='modal-customer-phone-me__call-me-item'>
          <div className='modal-customer-phone-me__call-me-item__icon'>
            {aboutDeleteProduct ? <CheckIcon color='#00c752' /> : <CloseIcon color='#d8553f' />}
          </div>
          <div className='modal-customer-phone-me__call-me-item__text'>{formatMessage(messages.AboutDeletingItem)}</div>
        </div>
        <div className='modal-customer-phone-me__call-me-item'>
          <div className='modal-customer-phone-me__call-me-item__icon'>
            {aboutChangeProductWeight ? <CheckIcon color='#00c752' /> : <CloseIcon color='#d8553f' />}
          </div>
          <div className='modal-customer-phone-me__call-me-item__text'>
            {formatMessage(messages.IfWeightItemChanges)}
          </div>
        </div>
        <div className='modal-customer-phone-me__divider' />
        <div className='modal-customer-phone-me__not-answer'>{formatMessage(messages.IfNotAnswer)}</div>
        <div className='modal-customer-phone-me__block'>
          <div className='modal-customer-phone-me__block-title'>{formatMessage(messages.IfProductOutOfStock)}:</div>
          <div className='modal-customer-phone-me__block-value'>
            {changeProduct ? formatMessage(messages.PickReplacement) : formatMessage(messages.DeleteProduct)}
          </div>
        </div>
        <div className='modal-customer-phone-me__block'>
          <div className='modal-customer-phone-me__block-title'>{formatMessage(messages.IfWeightItemChanged)}:</div>
          <div className='modal-customer-phone-me__block-value'>
            {deleteProductByWeight
              ? formatMessage(messages.RemoveItemIfWeightChanges)
              : formatMessage(messages.ChangeItemWeight)}
          </div>
        </div>
      </div>
    )
  }
}

export const CustomerPhoneMeModal = injectIntl(_CustomerPhoneMeModal)
