import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Header, Message, Loader } from 'semantic-ui-react'

import './index.scss'

import * as TApi from '../../../../types/TApi'
import { CopyProductsStatus, ISellerSearch } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'

type TConnectedProps = {
  status?: CopyProductsStatus,
  loading: boolean,
  sellers: ISellerSearch[],
}

type TDispatchedProps = {
  apiSellersSearch: (data: TApi.ApiSellersSearchReq) => Actions.Action,
  apiCopyProducts: (data: { source: string, target: string }) => Actions.Action,
}

type Props = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  sourceSeller: string,
  targetSeller: string,
  changed: boolean,
}

enum SellerType {
  SOURCE = 1,
  TARGET = 2,
}

class CopyProductsCmp extends React.Component<Props, TState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      changed: true,
      sourceSeller: '',
      targetSeller: '',
    }
  }

  componentDidMount(): void {
    this.getSellers()
  }

  render() {
    const { loading, status } = this.props
    const { formatMessage } = this.props.intl
    const { sourceSeller, targetSeller, changed } = this.state

    return (
      <div className='copy-products'>
        <Header as='h1'>{formatMessage(messages.CopyingProducts)}</Header>
        <div className='copy-products__text'>
          {formatMessage(messages.SelectSellers)}
          <div className='copy-products__text-info'>{formatMessage(messages.CopyingAllProductsInfo)}</div>
        </div>
        <Form success={status && !loading} className='copy-products__form'>
          {!changed && status && !loading && this.renderMessage()}

          {this.renderSelectSeller(sourceSeller, formatMessage(messages.SellerCopyFrom), SellerType.SOURCE)}
          {this.renderSelectSeller(targetSeller, formatMessage(messages.SellerCopyTo), SellerType.TARGET)}
        </Form>
        {sourceSeller && targetSeller && (!status || !loading) && (
          <div className='copy-products__actions'>
            {sourceSeller && targetSeller && (
              <Button
                className='copy-products__actions-btn'
                loading={loading}
                disabled={loading}
                color='blue'
                size='large'
                onClick={this.handleSubmit}
              >
                {formatMessage(messages.Save)}
              </Button>
            )}
          </div>
        )}
        {status && loading && this.renderLoadingStatus()}
      </div>
    )
  }

  renderMessage = () => {
    const { formatMessage } = this.props.intl

    return (
      <Message success className='copy-products__message'>
        <Message.Content>
          <Message.Header>{formatMessage(messages.Completed)}</Message.Header>
          {formatMessage(messages.CopiedSuccessfully)}
        </Message.Content>
      </Message>
    )
  }

  renderLoadingStatus = () => {
    const { status } = this.props
    const { formatMessage } = this.props.intl

    if (!status) {
      return
    }

    return (
      <div className='copy-products__form-loading'>
        <span className='copy-products__form-loading__text'>
          {formatMessage(messages.CopyingProducts)}: <i>{status.current}</i> / <i>{status.all}</i>
        </span>
        <Loader className='copy-products__form-loading__icon' inline active />
      </div>
    )
  }

  renderSelectSeller = (value: string, text: string, type: SellerType) => {
    const { sellers } = this.props
    const options = sellers.map((c) => ({
      key: c.id,
      value: c.id,
      text: `${c.name}, ${c.location} (${c.marketName})`,
    }))

    return (
      <div className='copy-products__form-field'>
        <Form.Dropdown
          fluid
          search
          selection
          clearable
          value={value}
          placeholder={text}
          className='copy-products__form-field__value'
          options={options}
          onChange={(event, data) => this.selectSeller(String(data.value), type)}
        />
      </div>
    )
  }

  selectSeller = (value: string, type: SellerType) => {
    if (type === SellerType.SOURCE) {
      this.setState({ sourceSeller: value, changed: true })
    } else {
      this.setState({ targetSeller: value, changed: true })
    }
  }

  handleSubmit = () => {
    const { sourceSeller, targetSeller } = this.state

    this.setState({ changed: false })

    this.props.apiCopyProducts({ source: sourceSeller, target: targetSeller })
  }

  getSellers = () => {
    this.props.apiSellersSearch({})
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  status: s.sellers.copyProducts?.status || undefined,
  loading: s.sellers.copyProducts?.loading || false,
  sellers: s.sellers.sellersSearchList,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiSellersSearch: (data: TApi.ApiSellersSearchReq) => dispatch(Actions.action(Actions.API_SELLERS_SEARCH, data)),
  apiCopyProducts: (data: { source: string, target: string }) =>
    dispatch(Actions.action(Actions.API_COPY_PRODUCTS, data)),
})

export const CopyProducts = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CopyProductsCmp))
