import { AjaxError } from 'rxjs/ajax'

import * as storage from './storageUtils'
import { reload } from './locationUtils'
import { log } from './logUtils'

export const authRequestConfig = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + storage.getValue(storage.SID_KEY),
    },
  }
}

export const checkNotAuth = (error: AjaxError) => {
  if (error && error.response && error.response.status === 401) {
    storage.removeValue(storage.SID_KEY)
    reload()
  } else {
    log(error)
  }
}
