import React from 'react'
import classnames from 'classnames'

import './index.scss'

type TOwnProps = {
  vertical?: boolean,
}

type TProps = TOwnProps

class OrderDividerCmp extends React.Component<TProps> {
  render() {
    const { vertical } = this.props

    return <div className={classnames(vertical ? 'order__vertical-divider' : 'order__divider')} />
  }
}

export const OrderDivider = OrderDividerCmp
