import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Input, InputOnChangeData, Popup } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { IMarket, IStockCategory, IStockSubcategory } from '../../../../../../types/TClient'
import { ApiUpdateMarketSubcategoryReq, ApiRemoveMarketSubcategoryReq } from '../../../../../../types/TApi'

import messages from '../../../../../../localization/messages'
import * as Actions from '../../../../../../store/actions'
import { State } from '../../../../../../store/reducer'
import { ArrowUpIcon, TrashIcon, VisibleIcon, VisibleOffIcon } from '../../../../../../components/Icons'

type TOwnProps = {
  pos: number,
  market: IMarket,
  category: IStockCategory,
  subcategory: IStockSubcategory,
}

type TConnectedProps = {
  loading: boolean,
}

type TDispatchedProps = {
  updateSubcategory: (data: ApiUpdateMarketSubcategoryReq) => Actions.Action,
  removeSubcategory: (data: ApiRemoveMarketSubcategoryReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  edit: boolean,
  title: string,
}

const MAX_SUBCATEGORY_LENGTH = 64

class SubcategoryEditCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      edit: false,
      title: props.subcategory.name,
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { subcategory } = this.props

    if (subcategory.name !== prevProps.subcategory.name) {
      this.setState({ title: subcategory.name })
    }
  }

  render() {
    return (
      <div className='stock-subcategory-edit'>
        <div className='stock-subcategory-edit__content'>
          {this.renderTitle()}
          {this.renderPosition()}
          {this.renderVisibility()}
          {this.renderRemove()}
        </div>
      </div>
    )
  }

  renderTitle = () => {
    const { formatMessage } = this.props.intl
    const { title } = this.state

    return (
      <div className='stock-subcategory-edit__title'>
        <Input
          maxLength={MAX_SUBCATEGORY_LENGTH}
          className='stock-subcategory-edit__title-input'
          placeholder={formatMessage(messages.SubcategoryName)}
          value={title}
          onChange={this.changeTitle}
          onBlur={this.updateTitle}
          onMouseOut={this.updateTitle}
        />
      </div>
    )
  }

  renderPosition = () => {
    const { category, pos } = this.props

    return (
      <div className='stock-subcategory-edit__position'>
        <div
          className='stock-subcategory-edit__position-up'
          onClick={() => {
            if (pos - 1 >= 0) {
              this.shiftPosition(category.subcategories[pos - 1])
            }
          }}
        >
          <ArrowUpIcon />
        </div>
        <div className='stock-subcategory-edit__position-divider' />
        <div
          className='stock-subcategory-edit__position-down'
          onClick={() => {
            if (pos + 1 < category.subcategories.length) {
              this.shiftPosition(category.subcategories[pos + 1])
            }
          }}
        >
          <ArrowUpIcon />
        </div>
      </div>
    )
  }

  renderVisibility = () => {
    const { subcategory } = this.props
    const { formatMessage } = this.props.intl
    const popTitle = subcategory.hidden
      ? formatMessage(messages.SubcategoryCurrentlyHidden)
      : formatMessage(messages.SubcategoryIsVisible)
    const popText = subcategory.hidden
      ? formatMessage(messages.ClickHideSubcategory)
      : formatMessage(messages.ClickSubcategoryVisible)

    return (
      <Popup
        wide
        content={
          <>
            <div className='stock-subcategory-edit__visibility-title'>{popTitle}</div>
            <div className='stock-subcategory-edit__visibility-text'>{popText}</div>
          </>
        }
        position='top center'
        trigger={
          <div
            className={classnames(
              'stock-subcategory-edit__visibility',
              !subcategory.hidden && 'stock-subcategory-edit__visibility-active',
            )}
            onClick={this.toggleVisible}
          >
            {!subcategory.hidden ? <VisibleIcon /> : <VisibleOffIcon color='#f2994a' />}
          </div>
        }
      />
    )
  }

  renderRemove = () => {
    return (
      <div className='stock-subcategory-edit__remove' onClick={this.remove}>
        <TrashIcon />
      </div>
    )
  }

  changeTitle = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { value } = data

    this.setState({ title: value })
  }

  updateTitle = () => {
    const { category, subcategory } = this.props
    const { title } = this.state
    const newName = title.trim()

    if (newName !== subcategory.name) {
      this.props.updateSubcategory({
        category,
        req: {
          ...subcategory,
          name: newName || subcategory.name,
        },
      })
    }
  }

  shiftPosition = (changedSub: IStockSubcategory) => {
    const { category, subcategory } = this.props

    if (changedSub) {
      this.props.updateSubcategory({
        category,
        changedSub,
        oldIndex: subcategory.index,
        req: {
          ...subcategory,
          index: changedSub.index,
        },
      })
    }
  }

  toggleVisible = () => {
    const { category, subcategory } = this.props

    this.props.updateSubcategory({
      category,
      req: {
        ...subcategory,
        hidden: !subcategory.hidden,
      },
    })
  }

  remove = () => {
    const { category, subcategory } = this.props

    this.props.removeSubcategory({
      category,
      req: {
        id: subcategory.id,
      },
    })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loadingUpdateCategories } = s.markets

  return {
    loading: loadingUpdateCategories,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  updateSubcategory: (data: ApiUpdateMarketSubcategoryReq) =>
    dispatch(Actions.action(Actions.API_UPDATE_MARKET_SUBCATEGORY, data)),
  removeSubcategory: (data: ApiRemoveMarketSubcategoryReq) =>
    dispatch(Actions.action(Actions.API_REMOVE_MARKET_SUBCATEGORY, data)),
})

export const SubcategoryEdit = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SubcategoryEditCmp))
