import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { ITotalOrders } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import { AnalyticsBlockStat } from '../AnalyticsBlockStat'
import { AnalyticsPieChart } from '../AnalyticsPieChart'

type TOwnProps = {
  marketInfo: ITotalOrders,
}

type Props = TOwnProps & WrappedComponentProps

const FIRST_CUSTOMERS_COLOR = '#6516ce'
const REPEAT_CUSTOMERS_COLOR = '#d3cdd8'

class AnalyticsCustomersCmp extends React.Component<Props> {
  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='analytics__block-container'>
        <div className='analytics__block-title'>{formatMessage(messages.Customers)}</div>
        <div className='analytics__block'>
          {this.renderHeader()}
          {this.renderChart()}
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { marketInfo } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='analytics__block-header'>
        <AnalyticsBlockStat
          label={formatMessage(messages.TotalAmount)}
          value={marketInfo.totalUniqueClients}
          info={formatMessage(messages.NumberCustomersLeastOneOrder)}
        />
      </div>
    )
  }

  renderChart = () => {
    const { marketInfo } = this.props
    const { formatMessage } = this.props.intl

    const chartData = [
      {
        name: formatMessage(messages.RepeatBuyers),
        value: marketInfo.totalUniqueClients - marketInfo.totalNumFirst,
        color: REPEAT_CUSTOMERS_COLOR,
      },
      {
        name: formatMessage(messages.OfTheseNumberFirst),
        value: marketInfo.totalNumFirst,
        color: FIRST_CUSTOMERS_COLOR,
      },
    ]

    return (
      <div className='analytics__block-chart'>
        <div className='analytics__block-chart__legend'>
          <AnalyticsBlockStat
            label={formatMessage(messages.OfTheseNumberFirst)}
            value={marketInfo.totalNumFirst}
            info={formatMessage(messages.NumberCustomersFirstOrder)}
            color={FIRST_CUSTOMERS_COLOR}
          />
          <AnalyticsBlockStat
            label={formatMessage(messages.RepeatBuyers)}
            value={marketInfo.totalUniqueClients - marketInfo.totalNumFirst}
            color={REPEAT_CUSTOMERS_COLOR}
          />
        </div>
        <div>
          <AnalyticsPieChart data={chartData} />
        </div>
      </div>
    )
  }
}

export const AnalyticsCustomers = injectIntl(AnalyticsCustomersCmp)
