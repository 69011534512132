import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Form } from 'semantic-ui-react'

import '../index.scss'

import { ESlotType, IMarket, IOrder } from '../../../types/TClient'
import { IClientSlot, ISlotType } from '../../../types/TApi'

import { formatTime, getDeliveryTypeName, getOrderDeliveryTime, getSlotType } from '../../../utils/ordersUtils'
import messages from '../../../localization/messages'
import { formatPrice } from '../../../utils/productsUtils'

type ListItem = {
  text: string,
  price?: string | number,
  id: string | number,
}

type IProps = {
  name: string,
  slots: IClientSlot[],
  slotTypes: ISlotType[],
  order: IOrder,
  slot?: string | ESlotType,
  onChange(event: any, data: any): void,
  error?: string | boolean,
  customMargin?: number,
  disabled?: boolean,
  market?: IMarket,
} & WrappedComponentProps

class _DeliveryTimePicker extends React.Component<IProps> {
  render() {
    const { disabled, error, onChange, name } = this.props
    const { formatMessage } = this.props.intl
    const options = this.getOptions()
    const currentOption = this.getCurrentOption(options)

    return (
      <div className='picker-item'>
        {!disabled ? (
          <Form.Dropdown
            className='picker-item-value'
            name={name}
            value={currentOption?.value}
            fluid
            selection
            placeholder={formatMessage(messages.DeliverySlot)}
            options={options}
            onChange={onChange}
            error={error}
          />
        ) : (
          this.renderTime()
        )}
      </div>
    )
  }

  renderTime = () => {
    const { deliveryTime, deliveryEndTime } = this.props.order

    return (
      <div>
        {deliveryEndTime && deliveryTime
          ? `${formatTime(deliveryTime)} – ${formatTime(deliveryEndTime)}`
          : deliveryTime
          ? getOrderDeliveryTime(formatTime(deliveryTime))
          : ''}
      </div>
    )
  }

  renderItem = (data: ListItem) => {
    const { market } = this.props

    return (
      <div className='list-item' onClick={(e) => this.handleClickItem(e, data.id)} key={String(data.id)}>
        <div className='list-item__text'>{data.text}</div>
        <div className='list-item__price'>{formatPrice(data.price, market?.currency)}</div>
      </div>
    )
  }

  renderTitle = (text: string, price?: string | number) => {
    const { market } = this.props

    return (
      <div className='list-item__value' key={text}>
        <span>{text}</span>
        <span className='list-item__value-price'>({formatPrice(price, market?.currency)})</span>
      </div>
    )
  }

  renderDivider = (key: string) => {
    return <div className='list-item__divider' key={key} />
  }

  getOptions = () => {
    const slotTypeOptions = this.getSlotTypeOptions()

    return [
      ...(slotTypeOptions.length ? [slotTypeOptions[0], this.createDividerOption('d1')] : []),
      ...this.getSlotOptions(),
      ...(slotTypeOptions.length >= 2 ? [this.createDividerOption('d2'), slotTypeOptions[1]] : []),
    ]
  }

  createDividerOption = (key: string) => ({
    value: '',
    children: () => this.renderDivider(key),
  })

  getCurrentOption = (options: { value: any, children: any }[]) => {
    const { slot } = this.props

    return options.find((option) => option.value === slot)
  }

  getSlotOptions = () => {
    const { slotTypes, slots } = this.props
    const price = getSlotType(slotTypes)?.delivery_price

    return slots.map((s) =>
      this.mapListItem({
        price,
        id: s.id,
        text: `${s.time_start.slice(0, 5)} – ${s.time_end.slice(0, 5)}`,
      }),
    )
  }

  getSlotTypeOptions = () => {
    return this.props.slotTypes
      .filter(
        (slotType) =>
          slotType.delivery_interval === ESlotType.AS_SOON_AS_POSSIBLE ||
          slotType.delivery_interval === ESlotType.DURING_THE_DAY,
      )
      .map(({ delivery_price, delivery_interval }) =>
        this.mapListItem({
          text: getDeliveryTypeName(delivery_interval),
          price: delivery_price,
          id: delivery_interval,
        }),
      )
  }

  mapListItem = (data: ListItem) => ({
    text: this.renderTitle(data.text, data.price),
    value: data.id,
    children: this.renderItem(data),
  })

  handleClickItem = (e: any, value: number | string) => {
    const { onChange, name } = this.props

    onChange(e, { name, value })
  }
}

export const DeliveryTimePicker = injectIntl(_DeliveryTimePicker)
