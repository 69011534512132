import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Form, Icon } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { EModerateProductStatus, IModerationProduct } from '../../../types/TClient'
import { ApiUpdateModerationProductReq } from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { Button } from '../../Button'
import { TextAreaField } from '../../TextAreaField'
import defaultImg from '../../../assets/images/default-image.png'

type TOwnProps = {
  product: IModerationProduct,
  onClose: () => void,
}

type TDispatchedProps = {
  apiUpdateModerationProduct(data: ApiUpdateModerationProductReq): Actions.Action,
}

type Props = TOwnProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  text: string,
  changed: boolean,
  deleteModal: boolean,
  errors: FieldError[],
}

const FIELD_TEXT = 'text'

class RejectProductModalCmp extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      text: '',
      changed: false,
      deleteModal: false,
      errors: [],
    }
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='modal-reject-product'>
        <div className='modal-reject-product__header'>
          <div className='modal-reject-product__title'>{formatMessage(messages.ItemRejection)}</div>
        </div>
        {this.renderInfo()}
        <Form>{this.renderTextField()}</Form>
        <div className='modal-reject-product__actions'>
          <Button outline title={formatMessage(messages.Cancel)} onClick={this.props.onClose} />
          <Button title={formatMessage(messages.Confirm)} onClick={this.rejectProduct} />
        </div>
      </div>
    )
  }

  renderInfo = () => {
    const { product } = this.props

    return (
      <div className='modal-reject-product__info'>
        <div className={classnames('modal-reject-product__info-value', 'modal-reject-product__info-photo')}>
          <img
            alt='product-image'
            className='modal-reject-product__info-photo-image'
            src={product.imageUrl ? product.imageUrl : defaultImg}
          />
        </div>
        <div className={classnames('modal-reject-product__info-value', 'modal-reject-product__info-name')}>
          {product.name}
        </div>
        <div className={classnames('modal-reject-product__info-value', 'modal-reject-product__info-seller')}>
          {product.sellerName}
          {product.sellerIsCommon && (
            <div className='modal-reject-product__info-seller-common__icon'>
              <Icon name='home' size='large' />
            </div>
          )}
        </div>
      </div>
    )
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  renderTextField = () => {
    const { formatMessage } = this.props.intl
    const { text } = this.state
    const error = this.checkError(FIELD_TEXT)

    return (
      <div className='modal-reject-product__field'>
        <div className='modal-reject-product__field-label'>{formatMessage(messages.SpecifyReason)}</div>
        <TextAreaField
          name={FIELD_TEXT}
          value={text}
          placeholder={formatMessage(messages.RejectionReason)}
          error={error ? error.message : ''}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_TEXT:
        this.setState({ text: value, errors: this.removeError(FIELD_TEXT) })
        break
    }
  }

  rejectProduct = () => {
    const { product } = this.props
    const { text, changed } = this.state
    const errors: FieldError[] = []

    if (!changed) {
      return
    }

    if (!errors.length) {
      this.props.onClose()

      this.props.apiUpdateModerationProduct({
        id: product.id,
        text,
        status: EModerateProductStatus.REJECTED,
      })
    } else {
      this.setState({ errors })
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiUpdateModerationProduct: (data: ApiUpdateModerationProductReq) =>
    dispatch(Actions.action(Actions.API_UPDATE_MODERATION_PRODUCT, data)),
})

export const RejectProductModal = connect(null, mapDispatchToProps)(injectIntl(RejectProductModalCmp))
