import 'semantic-ui-css/semantic.min.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, Dispatch } from 'redux'
import { Provider } from 'react-redux'
import { createEpicMiddleware } from 'redux-observable'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

import './index.scss'

import App from './App/App'
import * as Actions from './store/actions'
import rootEpic from './store/epics'
import { rootReducer, State } from './store/reducer'
import * as storage from './utils/storageUtils'
import { LOCATION_LOGIN, isAuthRequired, historyPush } from './utils/locationUtils'
import { checkLang } from './utils/langUtils'

const epicMiddleware = createEpicMiddleware<Actions.Action, Actions.Action, State>()

const middlewares: any = [epicMiddleware]

let composeEnhancers = null

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(
    createLogger({
      collapsed: true,
    }),
  )

  composeEnhancers = composeWithDevTools({
    actionSanitizer: (a: any) => a,
  })
}

export const store = createStore(
  rootReducer,
  composeEnhancers ? composeEnhancers(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares),
)

epicMiddleware.run(rootEpic)

export const dispatch: Dispatch = store.dispatch
const sessionId = storage.getValue(storage.SID_KEY)

if (sessionId) {
  dispatch(Actions.actionEmpty(Actions.API_USER_INFO))
} else if (isAuthRequired(window.location.pathname)) {
  historyPush(LOCATION_LOGIN)
}

setTimeout(() => {
  const lang = storage.getValue(storage.LANG) || navigator.language

  dispatch(Actions.action(Actions.SET_INTERFACE_LANG, { lang: checkLang(lang) }))
}, 100)

moment.locale('ru')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
