import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Button, Checkbox, Dropdown, Icon, Modal, Popup } from 'semantic-ui-react'
import { Map, Placemark, Polygon } from 'react-yandex-maps'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import moment from 'moment'
import { isEqual } from 'lodash'

import './index.scss'

import {
  EDeliveryAreaStatus,
  EDeliverySlotType,
  IDeliveryArea,
  IDeliveryBySlots,
  IMarket,
  TDeliveryType,
} from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import * as Actions from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import { InputField } from '../../../../../components/InputField'
import {
  getColorByPolygonNum,
  getDeliverySlotTypeName,
  getOtherDeliverySlotType,
} from '../../../../../utils/marketsUtils'
import { formatPrice } from '../../../../../utils/productsUtils'
import pinMarket from '../../../../../assets/images/pin-map.png'

type TOwnProps = {
  edit: boolean,
  market: IMarket,
  onEdit(): void,
}

type TConnectedProps = {
  loading: boolean,
}

type TDispatchedProps = {
  saveDeliveryAreas: (market: string, deliveryAreas: IDeliveryArea[]) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  edit: boolean,
  modalHideArea: boolean,
  activeDeliveryArea?: number,
  currentAreaHiddenType: EDeliveryAreaStatus,
  areaAction?: number,
  deliveryAreas: IDeliveryArea[],
}

class PolygonsCmp extends React.Component<TProps, TState> {
  mapRef: any = null
  tabBlockRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  constructor(props: TProps) {
    super(props)

    this.state = {
      deliveryAreas: props.market.delivery_areas || [],
      modalHideArea: false,
      activeDeliveryArea: 1,
      currentAreaHiddenType: EDeliveryAreaStatus.ACTIVE,
      edit: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { market, edit } = this.props

    if (!isEqual(prevProps.market.delivery_areas, market.delivery_areas)) {
      this.setState({ deliveryAreas: market.delivery_areas || [] })
    }

    if (prevProps.edit !== edit) {
      this.setState({ edit: edit })
    }
  }

  render() {
    const { edit, deliveryAreas } = this.state

    return (
      <div className='polygons-areas' ref={this.tabBlockRef}>
        {this.renderModal()}
        {deliveryAreas.length === 0 && this.renderEmptyAreasHead()}
        {deliveryAreas.length !== 0 && (edit ? this.renderEditAreasHead() : this.renderAreasHead())}
        {this.renderMarketMap()}
        {edit && this.renderActions()}
      </div>
    )
  }

  renderEmptyAreasHead = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='polygons-areas__empty'>
        <div className='polygons-areas__empty-title'>{formatMessage(messages.PolygonsNotSet)}</div>
        <div className='polygons-areas__empty-text'>{formatMessage(messages.ConfigurePolygons)}</div>
        <div className='polygons-areas__empty-btn'>
          <Button primary size='large' onClick={this.startAdd}>
            {formatMessage(messages.AddPolygons)}
          </Button>
        </div>
      </div>
    )
  }

  renderEditAreasHead = () => {
    return (
      <div className='polygons-areas__edit'>
        {this.renderEditHeader()}
        {this.renderAreaEdit()}
      </div>
    )
  }

  renderEditHeader = () => {
    const { formatMessage } = this.props.intl
    const { deliveryAreas, activeDeliveryArea } = this.state

    let widthList = 960

    if (this.tabBlockRef && this.tabBlockRef.current) {
      const size = this.tabBlockRef.current.getBoundingClientRect()

      widthList = size.width - 72 - 244
    }

    return (
      <div className='polygons-areas__edit-header'>
        <div className='polygons-areas__edit-header__list' style={{ maxWidth: `${widthList}px` }}>
          {deliveryAreas.map((area) => {
            return (
              <div
                key={area.index}
                className={classnames(
                  'polygons-areas__edit-header__tab',
                  activeDeliveryArea !== area.index && 'polygons-areas__edit-header__tab-not-active',
                )}
                onClick={() => this.selectDeliveryArea(area.index)}
              >
                <div
                  className='polygons-areas__edit-header__tab-color'
                  style={{ backgroundColor: getColorByPolygonNum(area.index) }}
                />
                <div className='polygons-areas__edit-header__tab-name'>
                  {formatMessage(messages.Polygon)} №{area.index}
                </div>
                {deliveryAreas.length > 1 && (
                  <div
                    className='polygons-areas__edit-header__tab-remove'
                    onClick={(e) => this.removeDeliveryArea(area.index, e)}
                  >
                    <Icon className='polygons-areas__edit-header__tab-remove__icon' name='trash alternate outline' />
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className='polygons-areas__edit-header__add' onClick={this.addDeliveryArea}>
          <div className='polygons-areas__edit-header__add-plus'>+</div>
          <div className='polygons-areas__edit-header__add-text'>{formatMessage(messages.AddPolygon)}</div>
        </div>
      </div>
    )
  }

  renderAreasHead = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const { deliveryAreas, areaAction } = this.state

    let widthList = 960

    if (this.tabBlockRef && this.tabBlockRef.current) {
      const size = this.tabBlockRef.current.getBoundingClientRect()

      widthList = size.width
    }

    return (
      <div className='polygons-areas__area-list' style={{ maxWidth: `${widthList}px` }}>
        {deliveryAreas.map((area) => {
          const hiddenPopup = area.status
            ? area.status === EDeliveryAreaStatus.BLOCK_TODAY
              ? formatMessage(messages.PolygonDisabledToday, { time: moment().format('D MMM') })
              : formatMessage(messages.PolygonDisabledUnlimited)
            : ''

          return (
            <div className='polygons-areas__area' key={area.index}>
              <div className='polygons-areas__area__header-container'>
                <div className='polygons-areas__area__header'>
                  <div
                    className='polygons-areas__area__header-color'
                    style={{ backgroundColor: getColorByPolygonNum(area.index) }}
                  />
                  <div className='polygons-areas__area__header-name'>
                    {formatMessage(messages.Polygon)} №{area.index}
                  </div>
                  <Checkbox toggle checked={!area.status} onChange={() => this.toggleAreaStatus(area)} />
                  {!!area.status && (
                    <Popup
                      wide
                      position='top center'
                      content={hiddenPopup}
                      style={{ borderRadius: '12px' }}
                      trigger={
                        <Icon
                          className='polygons-areas__area__header-icon'
                          name='question circle outline'
                          size='large'
                        />
                      }
                    />
                  )}
                </div>
                <Popup
                  wide
                  open={areaAction === area.index}
                  position='bottom right'
                  style={{ borderRadius: '12px' }}
                  trigger={
                    <div
                      className='polygons-areas__area__header-action'
                      onClick={() => this.areaActionToggle(area.index)}
                    >
                      <Icon
                        className='polygons-areas__area__header-action-icon'
                        name={areaAction === area.index ? 'close' : 'ellipsis horizontal'}
                      />
                    </div>
                  }
                >
                  <div
                    className='polygons-areas__area__header-action__remove'
                    onClick={() => this.removeDeliveryArea(area.index)}
                    onMouseLeave={() => this.setState({ areaAction: undefined })}
                  >
                    {formatMessage(messages.Delete)}
                  </div>
                </Popup>
              </div>
              {area.types.map((type) => {
                const typeName = getDeliverySlotTypeName(type.type, (type as IDeliveryBySlots).numBlockSlot)

                return (
                  <div className='polygons-areas__area__type-item' key={typeName}>
                    <div className='polygons-areas__area__type'>
                      <div className='polygons-areas__area__type-label'>
                        {formatMessage(messages.AvailableOrderTime)}
                      </div>
                      <div className='polygons-areas__area__type-value'>{typeName}</div>
                    </div>
                    <div className='polygons-areas__area__price'>
                      <div className='polygons-areas__area__price-label'>{formatMessage(messages.PriceDelivery)}</div>
                      <div className='polygons-areas__area__price-value'>
                        {formatPrice(type.price, market.currency)}
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className='polygons-areas__area__min'>
                <div className='polygons-areas__area__min-label'>{formatMessage(messages.MinimumOrder)}:</div>
                <div className='polygons-areas__area__min-value'>
                  {area.minOrderPrice
                    ? `${formatPrice(area.minOrderPrice, market.currency)}`
                    : formatMessage(messages.No)}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderAreaEdit = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const { deliveryAreas, activeDeliveryArea } = this.state
    const deliveryArea = deliveryAreas.find((area) => area.index === activeDeliveryArea)

    if (!deliveryArea || activeDeliveryArea === undefined) {
      return <div />
    }

    const addedType = getOtherDeliverySlotType(deliveryArea.types.map((type) => type.type))

    return (
      <div>
        <div className='polygons-areas__edit__type-list'>
          {deliveryArea.types.map((type, i) => {
            const typeName = getDeliverySlotTypeName(type.type, (type as IDeliveryBySlots).numBlockSlot)
            const existAsap = deliveryArea.types.find((item) => item.type === EDeliverySlotType.ASAP)
            const existBySlots = deliveryArea.types.find((item) => item.type === EDeliverySlotType.BY_SLOTS)
            const existByDay = deliveryArea.types.find((item) => item.type === EDeliverySlotType.BY_DAY)

            return (
              <div className='polygons-areas__edit__type-item' key={type.type}>
                <div className='polygons-areas__edit__type-item__type'>
                  <div className='polygons-areas__edit__type-item__type-label'>
                    {formatMessage(messages.AvailableOrderTime)}
                  </div>
                  <Dropdown className='polygons-areas__edit__type-item__type-value' text={typeName} pointing>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={!!existAsap && type.type !== EDeliverySlotType.ASAP}
                        value={EDeliverySlotType.ASAP}
                        onClick={() =>
                          this.changeDeliveryAreaType(
                            {
                              type: EDeliverySlotType.ASAP,
                              price: type.price,
                            },
                            activeDeliveryArea,
                            i,
                          )
                        }
                      >
                        {formatMessage(messages.AsSoonAsPossible)}
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        disabled={!!existBySlots && type.type !== EDeliverySlotType.BY_SLOTS}
                        value={`${EDeliverySlotType.BY_SLOTS}_0`}
                        onClick={() =>
                          this.changeDeliveryAreaType(
                            {
                              type: EDeliverySlotType.BY_SLOTS,
                              price: type.price,
                              numBlockSlot: 0,
                            },
                            activeDeliveryArea,
                            i,
                          )
                        }
                      >
                        {formatMessage(messages.BySlotNearest)}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={!!existBySlots && type.type !== EDeliverySlotType.BY_SLOTS}
                        value={`${EDeliverySlotType.BY_SLOTS}_1`}
                        onClick={() =>
                          this.changeDeliveryAreaType(
                            {
                              type: EDeliverySlotType.BY_SLOTS,
                              price: type.price,
                              numBlockSlot: 1,
                            },
                            activeDeliveryArea,
                            i,
                          )
                        }
                      >
                        {formatMessage(messages.BySlotsThroughOne)}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={!!existBySlots && type.type !== EDeliverySlotType.BY_SLOTS}
                        value={`${EDeliverySlotType.BY_SLOTS}_2`}
                        onClick={() =>
                          this.changeDeliveryAreaType(
                            {
                              type: EDeliverySlotType.BY_SLOTS,
                              price: type.price,
                              numBlockSlot: 2,
                            },
                            activeDeliveryArea,
                            i,
                          )
                        }
                      >
                        {formatMessage(messages.BySlotsThroughTwo)}
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        disabled={!!existByDay && type.type !== EDeliverySlotType.BY_DAY}
                        value={EDeliverySlotType.BY_DAY}
                        onClick={() =>
                          this.changeDeliveryAreaType(
                            {
                              type: EDeliverySlotType.BY_DAY,
                              price: type.price,
                            },
                            activeDeliveryArea,
                            i,
                          )
                        }
                      >
                        {formatMessage(messages.AllDay)}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className='polygons-areas__edit__type-item__price'>
                  <div className='polygons-areas__edit__type-item__price-label'>
                    {formatMessage(messages.PriceDelivery)}
                  </div>
                  <div className='polygons-areas__edit__type-item__price-value'>
                    <InputField
                      icon={<div className='polygons-areas__price-icon'>{market?.currency?.symbol_native}</div>}
                      value={Number(type.price).toString()}
                      onChange={(_event: any, data: any) =>
                        this.changeDeliveryAreaType(
                          {
                            ...type,
                            price: +data.value,
                          },
                          activeDeliveryArea,
                          i,
                        )
                      }
                    />
                  </div>
                </div>
                {deliveryArea.types.length > 1 && (
                  <div
                    className='polygons-areas__edit__type-item__remove'
                    onClick={() => this.removeDeliveryAreaType(activeDeliveryArea, i)}
                  >
                    <Icon className='polygons-areas__edit__type-item__remove-icon' name='trash alternate outline' />
                  </div>
                )}
              </div>
            )
          })}
          {addedType && (
            <div
              className='polygons-areas__edit__type-item__add'
              onClick={() => this.addDeliveryType(activeDeliveryArea)}
            >
              + {formatMessage(messages.AddAvailableTime)}
            </div>
          )}
        </div>
        <div className='polygons-areas__edit__type__min'>
          <div className='polygons-areas__edit__type__min-label'>{formatMessage(messages.MinimumOrder)}</div>
          <div className='polygons-areas__edit__type__min-check'>
            <div
              className={classnames(
                'polygons-areas__edit__type__min-check-btn',
                deliveryArea.minOrderPrice !== undefined && 'polygons-areas__edit__type__min-check-btn-active',
              )}
              onClick={() => this.changeMinPrice(399, activeDeliveryArea)}
            >
              {formatMessage(messages.Yes)}
            </div>
            <div
              className={classnames(
                'polygons-areas__edit__type__min-check-btn',
                deliveryArea.minOrderPrice === undefined && 'polygons-areas__edit__type__min-check-btn-active',
              )}
              onClick={() => this.changeMinPrice(undefined, activeDeliveryArea)}
            >
              {formatMessage(messages.No)}
            </div>
          </div>
          <div className='polygons-areas__edit__type__min-value'>
            <InputField
              disabled={deliveryArea.minOrderPrice === undefined}
              icon={<div className='polygons-areas__price-icon'>{market?.currency?.symbol_native}</div>}
              value={deliveryArea.minOrderPrice !== undefined ? Number(deliveryArea.minOrderPrice).toString() : ''}
              onChange={(_event: any, data: any) => this.changeMinPrice(data.value, activeDeliveryArea)}
            />
          </div>
        </div>
      </div>
    )
  }

  renderMarketMap = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const { deliveryAreas, edit } = this.state
    const location = [market.latitude, market.longitude]

    let mapWidth = 960

    if (this.tabBlockRef && this.tabBlockRef.current) {
      const size = this.tabBlockRef.current.getBoundingClientRect()

      mapWidth = size.width - 72
    } else {
      mapWidth =
        window.innerWidth > 840 ? window.innerWidth - 500 : window.innerWidth < 600 ? 0.9 * window.innerWidth : 480
    }

    const mapHeight = 575

    return (
      <div className='polygons-map' style={{ width: `${mapWidth}px`, height: `${mapHeight}px` }}>
        <Map
          defaultState={{ center: location, zoom: 15.4 }}
          options={{ maxZoom: 17, minZoom: 12 }}
          width={mapWidth}
          height={mapHeight}
          instanceRef={(inst: any) => (this.mapRef = inst)}
          onLoad={() => {
            if (this.mapRef) {
              this.mapRef.events.add('click', this.setMark)
              this.mapRef.events.add('contextmenu', this.undoMark)
            }
          }}
        >
          <Placemark
            geometry={location}
            options={{
              iconLayout: 'default#image',
              interactivityModel: 'default#transparent',
              iconImageHref: pinMarket,
              iconImageSize: [50, 69],
              iconImageOffset: [-25, -61],
              iconShape: {
                type: 'Circle',
                coordinates: [0, 0],
                radius: 20,
              },
            }}
          />
          {deliveryAreas.map((area, i) => {
            const polygonArea = area.polygon ? [area.polygon] : undefined
            const polygonColor = getColorByPolygonNum(area.index)

            return (
              <Polygon
                key={`${area.index}_${i}`}
                geometry={polygonArea}
                options={{
                  fillColor: polygonColor,
                  strokeColor: polygonColor,
                  interactivityModel: 'default#transparent',
                  strokeOpacity: 1,
                  fillOpacity: 0.3,
                  strokeWidth: 2,
                  strokeStyle: 'solid',
                }}
              />
            )
          })}
        </Map>
        {edit && (
          <div className='polygons-map__actions'>
            <div className='polygons-map__actions-btn' onClick={this.undoMark}>
              {formatMessage(messages.Cancel)}
            </div>
            <div className='polygons-map__actions-btn' onClick={this.removePolygon}>
              {formatMessage(messages.Clear)}
            </div>
          </div>
        )}
        <div className='polygons-map__legend'>
          {deliveryAreas.map((area, i) => {
            const polygonColor = getColorByPolygonNum(area.index)

            return (
              <div key={i} className='polygons-map__legend__item'>
                <div className='polygons-map__legend__item-color' style={{ backgroundColor: polygonColor }} />
                <div className='polygons-map__legend__item-name'>
                  {formatMessage(messages.Polygon)} №{area.index}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderActions = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='polygons__actions'>
        <div className={classnames('polygons__btn', 'polygons__btn-outline')} onClick={this.cancelChanges}>
          {formatMessage(messages.Cancel)}
        </div>
        <div className='polygons__btn' onClick={this.saveChanges}>
          {formatMessage(messages.Save)}
        </div>
      </div>
    )
  }

  renderModal = () => {
    const { formatMessage } = this.props.intl
    const { activeDeliveryArea, currentAreaHiddenType, modalHideArea } = this.state

    return (
      <Modal
        closeIcon
        onClose={() => this.setState({ modalHideArea: false })}
        onOpen={() => this.setState({ modalHideArea: true })}
        open={modalHideArea}
        className='polygons-modal__area-hidden'
      >
        <Modal.Content className='polygons-modal__area-hidden'>
          <div className='polygons-modal__area-hidden__title'>
            {formatMessage(messages.PolygonShutdown)} №{activeDeliveryArea}
          </div>
          <div>
            <div
              className='polygons-modal__area-hidden__type'
              onClick={() => this.setState({ currentAreaHiddenType: EDeliveryAreaStatus.BLOCK_TODAY })}
            >
              {currentAreaHiddenType === EDeliveryAreaStatus.BLOCK_TODAY ? (
                <Icon
                  className='polygons-modal__area-hidden__type-icon-active'
                  name='dot circle outline'
                  size='large'
                />
              ) : (
                <Icon className='polygons-modal__area-hidden__type-icon' name='circle outline' size='large' />
              )}
              <div className='polygons-modal__area-hidden__type-label'>
                {formatMessage(messages.DisableDeliverySlotsToday, { time: moment().format('D MMM') })}
              </div>
            </div>
            <div
              className='polygons-modal__area-hidden__type'
              onClick={() => this.setState({ currentAreaHiddenType: EDeliveryAreaStatus.BLOCK_ALWAYS })}
            >
              {currentAreaHiddenType === EDeliveryAreaStatus.BLOCK_ALWAYS ? (
                <Icon
                  className='polygons-modal__area-hidden__type-icon-active'
                  name='dot circle outline'
                  size='large'
                />
              ) : (
                <Icon className='polygons-modal__area-hidden__type-icon' name='circle outline' size='large' />
              )}
              <div className='polygons-modal__area-hidden__type-label'>
                {formatMessage(messages.DisableDeliverySlotsUnlimited)}
              </div>
            </div>
          </div>
          {activeDeliveryArea !== undefined && (
            <div className='polygons-modal__area-hidden__actions'>
              <div
                className={classnames('polygons__btn', 'polygons__btn-outline', 'polygons-modal__area-hidden__btn')}
                onClick={() => this.setState({ modalHideArea: false })}
              >
                {formatMessage(messages.Cancel)}
              </div>
              <div
                className={classnames(
                  'polygons__btn',
                  'polygons-modal__area-hidden__btn',
                  currentAreaHiddenType === EDeliveryAreaStatus.ACTIVE && 'polygons-modal__area-hidden__btn-disable',
                )}
                onClick={() => this.changeAreaHiddenType(currentAreaHiddenType, activeDeliveryArea)}
              >
                {formatMessage(messages.SwitchOff)}
              </div>
            </div>
          )}
        </Modal.Content>
      </Modal>
    )
  }

  startAdd = () => {
    this.setState({
      deliveryAreas: [
        {
          index: 1,
          polygon: [],
          types: [
            {
              type: EDeliverySlotType.BY_SLOTS,
              numBlockSlot: 0,
              price: 79,
            },
          ],
          status: EDeliveryAreaStatus.ACTIVE,
          marketId: this.props.market.id || '',
        },
      ],
      activeDeliveryArea: 1,
      edit: true,
    })
  }

  setMark = (event: any) => {
    const { deliveryAreas, activeDeliveryArea, edit } = this.state

    if (activeDeliveryArea !== undefined && edit) {
      const coords = event.get('coords')
      const newDeliveryAreas = deliveryAreas.map((deliveryArea) => {
        if (deliveryArea.index === activeDeliveryArea) {
          return {
            ...deliveryArea,
            polygon: [...deliveryArea.polygon, coords],
          }
        }

        return deliveryArea
      })

      this.setState({ deliveryAreas: newDeliveryAreas })
    }
  }

  undoMark = () => {
    const { deliveryAreas, activeDeliveryArea, edit } = this.state

    if (activeDeliveryArea !== undefined && edit) {
      const newDeliveryAreas = deliveryAreas.map((deliveryArea) => {
        if (deliveryArea.index === activeDeliveryArea && deliveryArea.polygon.length > 0) {
          return {
            ...deliveryArea,
            polygon: deliveryArea.polygon.slice(0, -1),
          }
        }

        return deliveryArea
      })

      this.setState({ deliveryAreas: newDeliveryAreas })
    }
  }

  removePolygon = () => {
    const { deliveryAreas, activeDeliveryArea, edit } = this.state

    if (activeDeliveryArea !== undefined && edit) {
      const newDeliveryAreas = deliveryAreas.map((deliveryArea) => {
        if (deliveryArea.index === activeDeliveryArea && deliveryArea.polygon.length > 0) {
          return {
            ...deliveryArea,
            polygon: [],
          }
        }

        return deliveryArea
      })

      this.setState({ deliveryAreas: newDeliveryAreas })
    }
  }

  addDeliveryArea = () => {
    const { deliveryAreas } = this.state

    this.setState({
      deliveryAreas: [
        ...deliveryAreas,
        {
          index: deliveryAreas.length + 1,
          polygon: [],
          types: [
            {
              type: EDeliverySlotType.BY_SLOTS,
              numBlockSlot: 0,
              price: 79,
            },
          ],
          status: EDeliveryAreaStatus.ACTIVE,
          marketId: this.props.market.id || '',
        },
      ],
    })
  }

  removeDeliveryArea = (index: number, event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) {
      event.stopPropagation()
    }

    const { market } = this.props
    const { deliveryAreas, edit } = this.state

    let newDeliveryAreas = deliveryAreas.filter((area) => area.index !== index)
    newDeliveryAreas = newDeliveryAreas.map((area, i) => {
      return {
        ...area,
        index: i + 1,
      }
    })

    if (!edit) {
      this.props.saveDeliveryAreas(market.id, newDeliveryAreas)
      this.setState({ areaAction: undefined })
    } else {
      this.setState({
        deliveryAreas: newDeliveryAreas,
        activeDeliveryArea:
          newDeliveryAreas.length > 0 ? newDeliveryAreas[newDeliveryAreas.length - 1].index : undefined,
        areaAction: undefined,
      })
    }
  }

  selectDeliveryArea = (index: number) => {
    this.setState({ activeDeliveryArea: index })
  }

  changeDeliveryAreaType = (value: TDeliveryType, num: number, index: number) => {
    const { deliveryAreas } = this.state

    let newDeliveryAreas = [...deliveryAreas]
    newDeliveryAreas = newDeliveryAreas.map((area) => {
      if (num === area.index) {
        return {
          ...area,
          types: area.types.map((type, i) => (i === index ? value : type)),
        }
      }

      return area
    })

    this.setState({
      deliveryAreas: newDeliveryAreas,
    })
  }

  removeDeliveryAreaType = (num: number, index: number) => {
    const { deliveryAreas } = this.state

    let newDeliveryAreas = [...deliveryAreas]
    newDeliveryAreas = newDeliveryAreas.map((area) => {
      if (num === area.index) {
        return {
          ...area,
          types: area.types.filter((_type, i) => i !== index),
        }
      }

      return area
    })

    this.setState({
      deliveryAreas: newDeliveryAreas,
    })
  }

  changeMinPrice = (value: number | string | undefined, num: number) => {
    const { deliveryAreas } = this.state

    let newDeliveryAreas = [...deliveryAreas]
    newDeliveryAreas = newDeliveryAreas.map((area) => {
      if (num === area.index) {
        return {
          ...area,
          minOrderPrice: value !== undefined ? +value : undefined,
        }
      }

      return area
    })

    this.setState({
      deliveryAreas: newDeliveryAreas,
    })
  }

  changeAreaHiddenType = (value: EDeliveryAreaStatus, num: number) => {
    const { market } = this.props
    const { deliveryAreas } = this.state

    let newDeliveryAreas = [...deliveryAreas]
    newDeliveryAreas = newDeliveryAreas.map((area) => {
      if (num === area.index) {
        return {
          ...area,
          status: value,
        }
      }

      return area
    })

    this.props.saveDeliveryAreas(market.id, newDeliveryAreas)
    this.setState({ modalHideArea: false })
  }

  addDeliveryType = (num: number) => {
    const { deliveryAreas } = this.state

    let newDeliveryAreas = [...deliveryAreas]
    newDeliveryAreas = newDeliveryAreas.map((area) => {
      if (num === area.index) {
        const addedType = getOtherDeliverySlotType(area.types.map((type) => type.type))

        return {
          ...area,
          types: addedType ? [...area.types, addedType] : area.types,
        }
      }

      return area
    })

    this.setState({
      deliveryAreas: newDeliveryAreas,
    })
  }

  areaActionToggle = (num: number) => {
    const { areaAction } = this.state

    this.setState({ areaAction: areaAction === num ? undefined : num })
  }

  toggleAreaStatus = (area: IDeliveryArea) => {
    if (area.status === EDeliveryAreaStatus.ACTIVE) {
      this.setState({
        modalHideArea: true,
        activeDeliveryArea: area.index,
        currentAreaHiddenType: area.status,
      })
    } else {
      this.changeAreaHiddenType(EDeliveryAreaStatus.ACTIVE, area.index)
    }
  }

  cancelChanges = () => {
    const { market } = this.props

    this.setState({ deliveryAreas: market.delivery_areas || [], edit: false })
    this.props.onEdit()
  }

  saveChanges = () => {
    const { market, loading } = this.props
    const { deliveryAreas } = this.state

    if (!loading) {
      const newDeliveryAreas = deliveryAreas.map((area) => {
        const oldArea = market.delivery_areas?.find((item) => item.id === area.id)

        if (oldArea) {
          return {
            ...area,
            ...((area.minOrderPrice || (oldArea.minOrderPrice && !area.minOrderPrice)) && {
              minOrderPrice: oldArea.minOrderPrice && !area.minOrderPrice ? 0 : area.minOrderPrice,
            }),
          }
        }

        return {
          ...area,
        }
      })

      this.props.saveDeliveryAreas(market.id, newDeliveryAreas)
      this.setState({ edit: false })
      this.props.onEdit()
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loadingUpdateArea } = s.markets

  return {
    loading: loadingUpdateArea,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  saveDeliveryAreas: (market: string, deliveryAreas: IDeliveryArea[]) =>
    dispatch(
      Actions.action(Actions.API_SAVE_DELIVERY_AREAS, {
        market,
        deliveryAreas,
      }),
    ),
})

export const Polygons = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PolygonsCmp))
