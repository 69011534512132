import React from 'react'
import { Form } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

type TProps = {
  onChange(event: any, data: any): void,
  value: string | number,
  maxLength?: number,
  icon?: any,
  name?: string,
  label?: string,
  placeholder?: string,
  disabled?: boolean,
  error?: string,
}

export const InputField: React.FC<TProps> = ({ onChange, value, label, placeholder, name, icon, disabled, error }) => {
  return (
    <div className={classnames('input-field', !!error && 'input-field__error')}>
      {!!label && (typeof value === 'string' ? value.length > 0 : true) && (
        <div className='input-field__label'>{label}</div>
      )}
      <Form.Input
        fluid
        transparent
        disabled={disabled}
        icon={icon}
        value={value}
        placeholder={placeholder}
        className={classnames('input-field__input', !!label && value !== undefined && 'input-field__input-with-label')}
        name={name}
        onChange={onChange}
        error={!!error}
      />
    </div>
  )
}
