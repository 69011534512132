import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import moment from 'moment'

import './index.scss'

import { ICustomer } from '../../types/TClient'

import messages from '../../localization/messages'
import { formatPhone, getName } from '../../utils/customersUtils'

type TOwnProps = {
  num: number,
  customer: ICustomer,
  selectedCustomerId?: string,
  onSelectCustomer: (customer: ICustomer) => any,
}

type Props = TOwnProps & WrappedComponentProps

class CustomerItemCmp extends React.Component<Props> {
  render() {
    const { customer, num, selectedCustomerId } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div
        className={classnames(
          'customer-item-wrapper',
          customer.id === selectedCustomerId && 'customer-item-wrapper__selected',
        )}
        onClick={() => this.selectCustomer(customer)}
      >
        <div className='customer-item'>
          <div className={classnames('customer-item__value', 'customer-item__num')}>{num}</div>
          <div className={classnames('customer-item__value', 'customer-item__phone-name')}>
            {formatPhone(customer.phone)}
            <span className='customer-item__phone-name-sep'>/</span>
            {getName(customer.firstName, customer.lastName)}
          </div>
          {/*<div className={classnames('customer-item__value', 'customer-item__name')}>*/}
          {/*  {getName(customer.firstName, customer.lastName)}*/}
          {/*</div>*/}
          <div className={classnames('customer-item__value', 'customer-item__last-order')}>
            {customer.lastOrderCreatedAt ? moment(customer.lastOrderCreatedAt).format('DD.MM.YYYY') : '–'}
          </div>
          <div className={classnames('customer-item__value', 'customer-item__first-order')}>
            {customer.firstOrderCreatedAt ? moment(customer.firstOrderCreatedAt).format('DD.MM.YYYY') : '–'}
          </div>
          <div className={classnames('customer-item__value', 'customer-item__register-date')}>
            {moment(customer.registerAt).format('DD.MM.YYYY')}
          </div>
          <div className={classnames('customer-item__value', 'customer-item__orders')}>
            {customer.ordersCount || '–'}
          </div>
          <div className={classnames('customer-item__value', 'customer-item__type')}>
            {customer.ordersCount > 0 ? formatMessage(messages.Customer) : formatMessage(messages.User)}
          </div>
        </div>
      </div>
    )
  }

  selectCustomer = (customer: ICustomer) => {
    this.props.onSelectCustomer(customer)
  }
}

export const CustomerItem = injectIntl(CustomerItemCmp)
