import { of as observableOf } from 'rxjs'
import { catchError, debounceTime, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'

import * as TApi from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import {
  URL_NOTIFICATIONS_LIST,
  URL_NOTIFICATION_CREATE,
  URL_NOTIFICATION_UPDATE,
  URL_NOTIFICATION_REMOVE,
  URL_NOTIFICATIONS_LOG,
  URL_GET_CLIENTS,
} from '../../modules/network/urls'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import { historyPush, LOCATION_NOTIFICATIONS } from '../../utils/locationUtils'
import { clientsToApp } from '../../utils/userUtils'

const notificationsListEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiNotificationsList>(a$, Actions.API_NOTIFICATIONS_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_NOTIFICATIONS_LIST, {
          ...authRequestConfig(),
          params: {
            offset: c.data.offset || 0,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiNotificationsListResp }) => {
            if (resp.data && resp.data.notifications) {
              if (Array.isArray(resp.data.notifications)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.NOTIFICATIONS_LIST, {
                    notifications: resp.data.notifications,
                  }),
                )
              }
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.NOTIFICATIONS_RESET_LOADING))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.NOTIFICATIONS_RESET_LOADING))
          }),
        ),
      ),
    ),
  )

const createNotificationEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiNotificationCreate>(a$, Actions.API_NOTIFICATION_CREATE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_NOTIFICATION_CREATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiNotificationCreateResp }) => {
            historyPush(LOCATION_NOTIFICATIONS)

            const actions: Actions.Action[] = []

            for (const notification of resp.data) {
              actions.push(Actions.action(Actions.NOTIFICATION_CREATE, notification))
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.NOTIFICATIONS_RESET_LOADING))
          }),
        ),
      ),
    ),
  )

const updateNotificationEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiNotificationUpdate>(a$, Actions.API_NOTIFICATION_UPDATE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_NOTIFICATION_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((_resp: { data: any }) => {
            historyPush(LOCATION_NOTIFICATIONS)

            return observableOf<Actions.Action>(
              Actions.action(Actions.NOTIFICATION_UPDATE, {
                ...c.data,
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.NOTIFICATIONS_RESET_LOADING))
          }),
        ),
      ),
    ),
  )

const removeNotificationEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiNotificationRemove>(a$, Actions.API_NOTIFICATION_REMOVE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_NOTIFICATION_REMOVE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.NOTIFICATION_REMOVE, {
                id: c.data.notification.id,
                type: c.data.notification.type,
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.NOTIFICATIONS_RESET_LOADING))
          }),
        ),
      ),
    ),
  )

const notificationsLogEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiNotificationsLog>(a$, Actions.API_NOTIFICATIONS_LOG), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_NOTIFICATIONS_LOG, {
          ...authRequestConfig(),
          params: {
            offset: c.data.offset || 0,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiNotificationsLogResp }) => {
            if (resp.data && resp.data) {
              if (Array.isArray(resp.data)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.NOTIFICATIONS_LOG, {
                    notifications: resp.data,
                  }),
                )
              }
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.API_NOTIFICATIONS_LOG_ERROR))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.API_NOTIFICATIONS_LOG_ERROR))
          }),
        ),
      ),
    ),
  )

const getClientsListEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiClients>(a$, Actions.API_CLIENTS).pipe(debounceTime(300)), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_GET_CLIENTS, {
          ...authRequestConfig(),
          params: c.data,
        }).pipe(
          mergeMap((resp: { data: TApi.ApiGetClientsResp }) => {
            const clients = resp.data.map(clientsToApp)

            return observableOf<Actions.Action>(Actions.action(Actions.CLIENTS_SUCCESS, clients))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.CLIENTS_ERROR))
          }),
        ),
      ),
    ),
  )

export const notificationsEpics: EpicFunc[] = [
  notificationsListEpic,
  createNotificationEpic,
  updateNotificationEpic,
  removeNotificationEpic,
  notificationsLogEpic,
  getClientsListEpic,
]
