import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import moment from 'moment'

import './index.scss'

import { IMarket, IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { checkIsInterval, convertStatus, formatTime, getDeliveryTypeName } from '../../../utils/ordersUtils'
import { formatPrice } from '../../../utils/productsUtils'

type TOwnProps = {
  order: IOrder,
  market?: IMarket,
  withDate?: boolean,
  withStatus?: boolean,
}

type TProps = TOwnProps & WrappedComponentProps

class OrderSlotCmp extends React.Component<TProps> {
  render() {
    const { order, market, withDate, withStatus } = this.props
    const { formatMessage } = this.props.intl
    const { deliveryTime, deliveryEndTime, deliveryPrice, deliveryType } = order
    const byType = checkIsInterval(deliveryType)

    return (
      <div className='order__slot'>
        <div className='order__block-row'>
          {withDate && (
            <div className='order__block-item'>
              <div className='order__block-title'>{formatMessage(messages.Date)}</div>
              <div className='order__block-text'>
                {moment(order.deliveryDate || order.createdAt).format('DD.MM.YYYY')}
              </div>
            </div>
          )}
          <div className='order__block-item'>
            <div className='order__block-title'>{formatMessage(messages.TimeSlot)}</div>
            <div className='order__block-text'>
              {byType
                ? getDeliveryTypeName(deliveryType)
                : `${formatTime(deliveryTime || '')} – ${formatTime(deliveryEndTime || '')}`}
              <span className='order__slot__delivery-price'> ({formatPrice(deliveryPrice, market?.currency)})</span>
            </div>
          </div>
          {withStatus && (
            <div className='order__block-item'>
              <div className='order__block-title'>{formatMessage(messages.Status)}</div>
              <div className='order__block-text'>{convertStatus(order.statusId)}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export const OrderSlot = injectIntl(OrderSlotCmp)
