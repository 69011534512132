import { ICourierApi, ICourierLocationApi, IRouteApi, IShiftSlotApi } from '../types/TApi'
import { ECourierRole, EVehicleType, ICourier, ICourierLocation, IRoute, IShiftSlot } from '../types/TClient'

import { intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'
import { convertOrderFromApi } from './ordersUtils'

export const convertCourierFromApi = (courier: ICourierApi, index: number): ICourier => {
  return {
    num: index + 1,
    id: courier.id,
    firstName: courier.firstName,
    lastName: courier.lastName,
    phone: courier.phone,
    role: courier.role,
    isOnShift: courier.isOnShift,
    cityId: courier.cityId,
    cityName: courier.cityName,
    marketId: courier.marketId,
    marketAddress: courier.marketAddress,
    marketName: courier.marketName,
    numActiveOrders: +courier.numActiveOrders,
    isBusy: courier.isBusy !== undefined ? courier.isBusy : +courier.numActiveOrders > 0,
    ...(courier.vehicleType && { vehicleType: courier.vehicleType as EVehicleType }),
    ...(courier.carNumber && { carNumber: courier.carNumber }),
    ...(courier.latitude && { latitude: courier.latitude }),
    ...(courier.longitude && { longitude: courier.longitude }),
    shiftSlots: courier.shiftSlots ? courier.shiftSlots.map(convertShiftSlotFromApi) : [],
  }
}

export const convertShiftSlotFromApi = (shiftSlot: IShiftSlotApi): IShiftSlot => {
  return {
    id: shiftSlot.id,
    marketId: shiftSlot.marketId,
    courierId: shiftSlot.courierId,
    shiftDate: shiftSlot.shiftDate,
    slotId: shiftSlot.slotId,
    startAt: shiftSlot.startAt,
    completeAt: shiftSlot.completeAt,
  }
}

export const convertCourierLocationFromApi = (courier: ICourierLocationApi): ICourierLocation => {
  return {
    id: courier.id,
    isOnShift: courier.isOnShift,
    latitude: courier.latitude,
    longitude: courier.longitude,
  }
}

export const convertRole = (role: ECourierRole): string => {
  switch (role) {
    case ECourierRole.MANAGER:
      return intlMessage(messages.Manager)
    case ECourierRole.COURIER:
      return intlMessage(messages.Courier)
    case ECourierRole.PICKER:
      return intlMessage(messages.Collector)
  }
}

export const compareRole = (role1: ECourierRole, role2: ECourierRole): boolean => {
  if (role1 === ECourierRole.MANAGER) {
    return true
  }

  if (role1 === ECourierRole.COURIER && (role2 === ECourierRole.COURIER || role2 === ECourierRole.PICKER)) {
    return true
  }

  if (role1 === ECourierRole.PICKER && role2 === ECourierRole.PICKER) {
    return true
  }

  return false
}

export const formatPhoneNumber = (phone: string): string => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) {
    const intlCode = match[1] ? '+7 ' : ''
    return [intlCode, match[2], match[3], match[4], match[5]].join(' ').trim()
  }

  return phone
}

export const sortCouriersByRole = (couriers: ICourier[]): Map<ECourierRole, ICourier[]> => {
  const couriersMap = new Map<ECourierRole, ICourier[]>()

  couriers.forEach((courier) => {
    const containCouriers = couriersMap.get(courier.role) || []

    couriersMap.set(courier.role, [...containCouriers, courier])
  })

  return couriersMap
}

enum STATUS_COLORS {
  RED = '#D8553F1A',
  RED_1 = '#D8553F',
  GREEN = '#5BC2581A',
  GREEN_1 = '#5BC258',
  GRAY = '#A0A3B51A',
  GRAY_1 = '#A0A3B5',
}

export const getCourierStatus = (
  role: ECourierRole,
  isOnShift: boolean,
  isBusy?: boolean,
): { color: string, title: string, textColor: string, available: boolean } => {
  const style = isBusy
    ? { color: STATUS_COLORS.RED, textColor: STATUS_COLORS.RED_1, available: false }
    : { color: STATUS_COLORS.GREEN, textColor: STATUS_COLORS.GREEN_1, available: true }

  if (!isOnShift) {
    return {
      title: intlMessage(messages.NotShift),
      color: STATUS_COLORS.GRAY,
      textColor: STATUS_COLORS.GRAY_1,
      available: false,
    }
  }

  switch (role) {
    case ECourierRole.MANAGER:
      return {
        title: intlMessage(messages.OnShift),
        color: STATUS_COLORS.GREEN,
        textColor: STATUS_COLORS.GREEN_1,
        available: true,
      }
    case ECourierRole.COURIER:
      return {
        ...style,
        title: isBusy ? intlMessage(messages.DeliversOrder) : intlMessage(messages.Free),
      }
    case ECourierRole.PICKER:
      return {
        ...style,
        title: isBusy ? intlMessage(messages.CollectsOrder) : intlMessage(messages.Free),
      }
  }
}

export const convertRouteFromApi = (route: IRouteApi): IRoute => {
  return {
    id: route.id,
    marketId: route.marketId,
    courierId: route.courierId,
    createdAt: route.createdAt,
    estimateTime: route.estimateTime,
    startAt: route.startAt,
    completedAt: route.completedAt,
    points: route.points.map((point) => {
      return {
        id: point.id,
        index: point.index,
        marketId: point.marketId,
        orderId: point.orderId,
        text: point.text,
        address: point.address,
        ...(point.order && { order: convertOrderFromApi(point.order) }),
      }
    }),
  }
}
