import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Icon, Input } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { EOrderStatus, EPaymentType, IOrder } from '../../../types/TClient'
import * as TApi from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { OrderDivider } from '../OrderDivider'
import { copyToClipboard } from '../../../utils/deviceUtils'
import { formatPrice } from '../../../utils/productsUtils'

type TOwnProps = {
  order: IOrder,
  byCustomer?: boolean,
}

type TConnectedProps = {
  updateLoading: boolean,
  loading: boolean,
  updateError: boolean,
}

type TDispatchedProps = {
  apiOrderUpdate: (data: TApi.ApiUpdateOrderReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  commentHide: string,
  commentActive: boolean,
  deliveryPriceActive: boolean,
  deliveryPrice: number | '',
}

class OrderPaymentCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      commentHide: '',
      commentActive: false,
      deliveryPrice: props.order?.deliveryPrice || '',
      deliveryPriceActive: false,
    }
  }

  render() {
    const { order, updateLoading, byCustomer } = this.props
    const { formatMessage } = this.props.intl
    const { deliveryPriceActive, deliveryPrice } = this.state
    const editableDelivery = !order.paid && order.statusId <= EOrderStatus.COURIER && !!order.deliveryPrice
    const { paymentUrl, extPaymentUrl } = order
    const currency = order.items.length ? order.items[0].currency : undefined

    if (byCustomer) {
      return this.renderStaticDelivery()
    }

    return (
      <div className='order__payment'>
        {order.paymentType === EPaymentType.BY_CARD && (!order.paid || (!order.extPaid && extPaymentUrl)) && (
          <div className='order__block-block'>
            <div className='order__block-label'>{formatMessage(messages.PaymentLink)}</div>
            <div className='order__payment__url'>
              <div className='order__block-text'>{order.paid ? extPaymentUrl : paymentUrl}</div>
              <div
                className='order__payment__url-btn'
                onClick={() => copyToClipboard(order.paid ? extPaymentUrl || '' : paymentUrl || '')}
              >
                <Icon name='copy outline' size='large' />
              </div>
            </div>
          </div>
        )}
        <div className='order__block-row'>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.DeliveryCost)}</div>
            {editableDelivery ? (
              deliveryPriceActive ? (
                <div className='order__payment__delivery'>
                  <Input
                    className='order__payment__delivery__input'
                    type='number'
                    value={deliveryPrice}
                    onChange={this.changeDeliveryPrice}
                  />
                  <Button
                    className='order__payment__delivery-btn'
                    loading={updateLoading}
                    disabled={updateLoading}
                    onClick={this.updateDeliveryPrice}
                  >
                    <Icon name='check' className='order__payment__delivery-btn__icon' />
                  </Button>
                </div>
              ) : (
                <div className='order__block-text'>
                  {order.deliveryPrice !== undefined ? `${formatPrice(order.deliveryPrice, currency)}` : '–'}
                  {editableDelivery && this.renderEditBtn(this.activeDeliveryPrice)}
                </div>
              )
            ) : (
              <div className='order__block-text'>
                {order.deliveryPrice !== undefined ? `${formatPrice(order.deliveryPrice, currency)}` : '–'}
              </div>
            )}
          </div>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.TotalPayable)}</div>
            <div className='order__block-text'>{formatPrice(order.price + (order.deliveryPrice || 0), currency)}</div>
          </div>
        </div>
        {order.comment && (
          <div className={classnames('order__block-item', 'order__block-padding')}>
            <div className='order__block-label'>{formatMessage(messages.CustomerComment)}</div>
            <div className='order__block-text'>{order.comment}</div>
          </div>
        )}
        {this.renderCommentHide()}
      </div>
    )
  }

  renderStaticDelivery = () => {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const currency = order.items.length ? order.items[0].currency : undefined

    return (
      <div>
        <div className='order__block-row'>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.DeliveryCost)}</div>
            <div className='order__block-text'>
              {order.deliveryPrice !== undefined ? `${formatPrice(order.deliveryPrice, currency)}` : '–'}
            </div>
          </div>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.TotalPayable)}</div>
            <div className='order__block-text'>{formatPrice(order.price + (order.deliveryPrice || 0), currency)}</div>
          </div>
        </div>
        {(order.comment || order.commentHide) && <OrderDivider />}
        {order.comment && (
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.CustomerComment)}</div>
            <div className='order__block-text'>{order.comment}</div>
          </div>
        )}
        {order.commentHide && (
          <div className={classnames('order__block-item', 'order__block-padding')}>
            <div className='order__block-label'>{formatMessage(messages.Comment)}</div>
            <div className='order__block-text'>{order.commentHide}</div>
          </div>
        )}
      </div>
    )
  }

  renderCommentHide = () => {
    const { updateLoading, order } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='order__comment'>
        <div className='order__comment-text'>
          <div className='order__block-label'>{formatMessage(messages.Comment)}</div>
          {order.commentHide && !this.state.commentActive && (
            <div className='order__block-text'>{order.commentHide}</div>
          )}
        </div>
        {this.state.commentActive ? (
          <Form className='order__comment-form'>
            <Form.TextArea
              className='order__comment-form__text'
              rows={3}
              value={this.state.commentHide}
              onChange={this.changeCommentHide}
            />
            <Button
              className='order__btn'
              loading={updateLoading}
              disabled={updateLoading}
              color='blue'
              onClick={this.updateCommentHide}
            >
              {formatMessage(messages.Save)}
            </Button>
            <div
              className='order__btn-outline'
              onClick={() => this.setState({ commentActive: false, commentHide: order.commentHide || '' })}
            >
              {formatMessage(messages.Cancel)}
            </div>
          </Form>
        ) : (
          <Button
            className='order__btn'
            loading={updateLoading}
            disabled={updateLoading}
            color='blue'
            onClick={this.activeComment}
          >
            {order.commentHide ? formatMessage(messages.EditComment) : formatMessage(messages.AddComment)}
          </Button>
        )}
      </div>
    )
  }

  renderEditBtn = (action: () => any, color?: string) => {
    return (
      <div className='order__edit-btn' onClick={action} style={{ backgroundColor: color }}>
        <Icon className='order__edit-btn__icon' name='pencil alternate' />
      </div>
    )
  }

  activeComment = () => {
    const { order } = this.props

    this.setState({ commentActive: true, commentHide: order && order.commentHide ? order.commentHide : '' })
  }

  changeCommentHide = (event: any) => {
    const { value } = event.target

    this.setState({ commentHide: value })
  }

  updateCommentHide = () => {
    const { order } = this.props
    const message = this.state.commentHide.trim()

    if (order && (order.commentHide || message)) {
      this.props.apiOrderUpdate({ id: order.id, message_hide: message })
    }

    this.setState({ commentActive: false, commentHide: order.commentHide || '' })
  }

  changeDeliveryPrice = (event: any) => {
    const { order } = this.props
    const { value } = event.target
    const price = value === '' ? '' : +value

    if (order.deliveryPrice !== undefined && (price >= order.deliveryPrice || price < 0)) {
      return
    }

    this.setState({ deliveryPrice: price })
  }

  activeDeliveryPrice = () => {
    const { order } = this.props

    this.setState({ deliveryPriceActive: true, deliveryPrice: order && order.deliveryPrice ? order.deliveryPrice : 0 })
  }

  updateDeliveryPrice = () => {
    const { order } = this.props
    const { deliveryPrice } = this.state

    if (order?.deliveryPrice && order.deliveryPrice <= deliveryPrice) {
      this.setState({ deliveryPriceActive: false })
      return
    }

    if (order) {
      const newPrice: number = deliveryPrice !== '' ? deliveryPrice : 0

      this.props.apiOrderUpdate({ id: order.id, delivery_price: newPrice })
      this.setState({ deliveryPriceActive: false })
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading, updateLoading } = s.orders

  return {
    updateLoading,
    loading,
    updateError: s.orders.updateError,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiOrderUpdate: (data: TApi.ApiUpdateOrderReq) => dispatch(Actions.action(Actions.API_UPDATE_ORDER, data)),
})

export const OrderPayment = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderPaymentCmp))
