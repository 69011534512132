import React from 'react'

type TProps = {
  color?: string,
}

export const ArrowMore: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='14' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.988281 6.29868L6.99869 0.288269L13.0091 6.29868L11.5949 7.71289L6.99869 3.1167L2.4025 7.71289L0.988281 6.29868Z'
        fill={color}
      />
    </svg>
  )
}
