import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import { Popup } from 'semantic-ui-react'

import './index.scss'

import { IModerationProduct } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { round } from '../../../utils/ordersUtils'
import { checkMobile } from '../../../utils/deviceUtils'
import { formatPrice, UNIT_KG, UNIT_LITRE, UNIT_PCS, unitIntl } from '../../../utils/productsUtils'

type TOwnProps = {
  product: IModerationProduct,
}

type TProps = TOwnProps & WrappedComponentProps

class ProductPriceCmp extends React.Component<TProps> {
  get currentUnit() {
    const { product } = this.props
    const { formatMessage } = this.props.intl

    return product.unit === UNIT_KG || product.unit === UNIT_PCS
      ? formatMessage(messages.Kg)
      : formatMessage(messages.Litre)
  }

  render() {
    const { product } = this.props
    const { formatMessage } = this.props.intl
    const isMobile = checkMobile()

    return (
      <div className={classnames('moderate-product__block-row', 'moderate-product__price')}>
        <div className={classnames('moderate-product__block-item')}>
          <div className='moderate-product__block-label'>{formatMessage(messages.UnitMeasureShort)}</div>
          {product.unit !== product.oldUnit ? (
            <div className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}>
              <Popup position={isMobile ? 'top left' : 'top center'} trigger={<span>{unitIntl(product.unit)}</span>}>
                <b>{formatMessage(messages.PastValue)}:</b>
                <br />
                {unitIntl(product.oldUnit)}
              </Popup>
            </div>
          ) : (
            <div className='moderate-product__block-text'>{unitIntl(product.unit)}</div>
          )}
        </div>
        {(product.unit === UNIT_KG || product.unit === UNIT_LITRE) && (
          <div className='moderate-product__block-item'>
            <div className='moderate-product__block-label'>
              {formatMessage(messages.SaleBy, { value: this.currentUnit })}
            </div>
            {product.priceWeightGr !== product.oldPriceWeightGr ? (
              <div className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}>
                <Popup
                  position={isMobile ? 'top left' : 'top center'}
                  trigger={<span>{round((product.priceWeightGr || 1000) / 1000)}</span>}
                >
                  <b>{formatMessage(messages.PastValue)}:</b>
                  <br />
                  {`${round((product.oldPriceWeightGr || 1000) / 1000)}`}
                </Popup>
              </div>
            ) : (
              <div className='moderate-product__block-text'>{round((product.priceWeightGr || 1000) / 1000)}</div>
            )}
          </div>
        )}
        {product.unit === UNIT_PCS && (
          <div className='moderate-product__block-item'>
            <div className='moderate-product__block-label'>{formatMessage(messages.WeightOfOnePiece)}</div>
            {product.pcsWeightGr !== product.oldPcsWeightGr ? (
              <div className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}>
                <Popup
                  position={isMobile ? 'top left' : 'top center'}
                  trigger={
                    <span>
                      {product.pcsWeightGr} {formatMessage(messages.Gram)}
                    </span>
                  }
                >
                  <b>{formatMessage(messages.PastValue)}:</b>
                  <br />
                  {`${product.oldPcsWeightGr} ${formatMessage(messages.Gram)}`}
                </Popup>
              </div>
            ) : (
              <div className='moderate-product__block-text'>
                {product.pcsWeightGr} {formatMessage(messages.Gram)}
              </div>
            )}
          </div>
        )}
        <div className='moderate-product__block-item'>
          <div className='moderate-product__block-label'>{formatMessage(messages.PurchaseStep)}</div>
          {product.step !== product.oldStep ? (
            <div className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}>
              <Popup position={isMobile ? 'top left' : 'top center'} trigger={<span>{product.step}</span>}>
                <b>{formatMessage(messages.PastValue)}:</b>
                <br />
                {`${product.oldStep}`}
              </Popup>
            </div>
          ) : (
            <div className='moderate-product__block-text'>{product.step}</div>
          )}
        </div>
        <div className='moderate-product__block-item'>
          <div className='moderate-product__price-price'>
            <div className='moderate-product__block-label'>
              {formatMessage(messages.PricePer, { value: unitIntl(product.unit).toLowerCase() })}
            </div>
            <div className={classnames('moderate-product__block-text')}>
              {product.price !== product.oldPrice ? (
                <div className={classnames('moderate-product__block-text', 'moderate-product__block-changed')}>
                  <Popup
                    position={isMobile ? 'top left' : 'top center'}
                    trigger={
                      <span className='moderate-product__price-price-value'>
                        {formatPrice(product.price, product.currency)}
                      </span>
                    }
                  >
                    <b>{formatMessage(messages.PastValue)}:</b>
                    <br />
                    {formatPrice(product.oldPrice, product.currency)}
                  </Popup>
                </div>
              ) : (
                <span className='moderate-product__price-price-value'>
                  {formatPrice(product.price, product.currency)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const ProductPrice = injectIntl(ProductPriceCmp)
