import { MessageKeys } from '../messages'
import countries from './countries'

const en: { [x in MessageKeys]: string } = {
  ...countries,
  CurrentOrders: 'Current orders',
  Archive: 'Archive',
  DownloadReport: 'Download report',
  DownloadReportIn: 'Download report in {format}',
  OrdersByUsers: 'Orders by buyers',
  Filter: 'Filter',
  SearchByOrders: 'Search by order number, address or phone number',
  City: 'City',
  CityShort: 'c.',
  Market: 'Market',
  Today: 'Today',
  Tomorrow: 'Tomorrow',
  Yesterday: 'Yesterday',
  OrderFilter: 'Order filter',
  Customer: 'Customer',
  Name: 'Name',
  Phone: 'Phone',
  ClientType: 'Client type',
  CallMeIf: 'Call me if',
  NotCallMeIf: 'Don\'t call me if',
  Collector: 'Collector',
  AssignCollector: 'Assign collector',
  NotBeenAssigned: 'Has not been assigned',
  NotAssigned: 'Not assigned',
  Picker: 'Picker',
  AssignPicker: 'Assign picker',
  SelectPicker: 'Select picker',
  Courier: 'Courier',
  AssignCourier: 'Assign courier',
  SelectCourier: 'Select courier',
  Save: 'Save',
  Cancel: 'Cancel',
  Receipt: 'Receipt',
  Address: 'Address',
  Pickup: 'Pickup',
  Delivery: 'Delivery',
  DeliveryDate: 'Delivery date',
  DeliveryTime: 'Time of delivery',
  ChangeReceiveMethod: 'Change how you receive',
  OrderCreatedAt: 'The order was made on {date} at {time}',
  WebSite: 'Web site',
  MobileApp: 'Mobile app',
  OrderHistory: 'Order history',
  AssemblyNotLaterThan: 'Assembly, no later than',
  TakenForAssembly: 'Taken for assembly/collected',
  BeingLate: 'Being late',
  Min: 'Min',
  No: 'No',
  DeliveryStartBefore: 'Delivery, start before',
  TakenDelivery: 'Taken for delivery',
  DeliveryToDoor: 'Delivery to the door to',
  Delivered: 'Delivered',
  NamePlace: 'Name',
  PickupTime: 'Pickup time',
  PaymentLink: 'Payment Link',
  DeliveryCost: 'Cost of delivery',
  TotalPayable: 'Total payable',
  CustomerComment: 'Customer\'s comment',
  Comment: 'Comment',
  EditComment: 'Edit comment',
  AddComment: 'Add comment',
  Payment: 'Payment',
  PaymentMethod: 'Payment method',
  Cash: 'Cash',
  Card: 'Card',
  Refund: 'Refund',
  ExtraPaid: 'Extra paid',
  Surcharge: 'Surcharge',
  Paid: 'Paid',
  NotPaid: 'Not paid',
  SureRemoveItemFromOrder: 'Are you sure you want to remove the item from the order?',
  NotCancelRemoveItemOrder: 'It will not be possible to return an item to an order',
  ValueMustNumber: 'Value must be a number',
  QuantityItemMustLess: 'Quantity of goods must be less than current',
  QuantityPcsMustInteger: 'Quantity of piece goods must be an integer',
  OrderList: 'Order list',
  OrderWeight: 'Order weight',
  AddProducts: 'Add products',
  AdditionalOrder: 'Additional order',
  Seller: 'Seller',
  Place: 'Place',
  SettlementAmount: 'Settlement amount',
  NoSeller: 'No seller',
  CustomerReview: 'Customer review',
  Assessment: 'Assessment',
  Review: 'Review',
  Date: 'Date',
  TimeSlot: 'Time slot',
  Status: 'Status',
  TotalOrder: 'Total for the order',
  Number: 'Number',
  CollectorCourier: 'Collector/Courier',
  KmFromMarket: 'Km from market',
  Picked: 'Picked',
  Collected: 'Collected',
  NotCollected: 'Not collected',
  Late: 'Late',
  SureChangeOrderStatus: 'Are you sure you want to change the order status?',
  NotCancelChangeOrderStatus: 'It will not be possible to change the status back to the same',
  SureChangeOrderPayment: 'Are you sure you want to change your payment method?',
  NoCancelChangePaymentFromCashToCard:
    'It will not be possible to change the payment method back from "Cash" to "Card". Proceed?',
  OrderCannotCompleted: 'The order cannot be completed until the buyer makes the payment online',
  OrdersProgress: 'Orders in progress',
  OnlyOnShift: 'Only on shift',
  HideCollectors: 'Hide collectors',
  Collectors: 'Collectors',
  Couriers: 'Couriers',
  Managers: 'Managers',
  History: 'History',
  HistoryNotAvailable: 'History not available',
  DuringAllTime: 'During all this time',
  ThisMonth: 'This calendar month',
  LastMonth: 'Last calendar month',
  BeforeLastMonth: 'In the month before last',
  OverTwoMonthsAgo: 'Over 2 months ago',
  TotalOrders: 'Total orders',
  LastOrder: 'Last order',
  Monitoring: 'Monitoring',
  CollectorsAndCouriers: 'Collectors and couriers',
  SlotsLoad: 'Slots load',
  ShiftSchedule: 'Shift schedule',
  ShiftIndicators: 'Shift indicators',
  OrdersMap: 'Orders on the map',
  Back: 'Back',
  Customers: 'Customers',
  CustomersR: 'Customers',
  UsersR: 'Users',
  SearchByPhoneName: 'Search by phone or name',
  PhoneAndName: 'Phone and name',
  FirstOrder: 'First order',
  Registration: 'Registration',
  Orders: 'Orders',
  DeliveryAddresses: 'Delivery addresses',
  CustomerNoOrder: 'The buyer has not placed an order yet',
  ShowMore: 'Show more',
  B2B: 'B2B',
  B2C: 'B2C',
  AverageBill: 'Average bill',
  From: 'From',
  DelayedMinutes: 'Delayed {minutes} minutes',
  OrdersReviews: 'Feedback on orders',
  CustomerNoReviews: 'The buyer has not left any feedback yet',
  User: 'User',
  OnModeration: 'On moderation',
  ProductsModerationRules: 'Goods moderation rules',
  SearchByProductOrEntity: 'Search by product name or legal entity',
  ProductPhoto: 'Product photo',
  ProductName: 'Name of product',
  Actions: 'Actions',
  Approve: 'Approve',
  Reject: 'Reject',
  Category: 'Category',
  Subcategory: 'Subcategory',
  PastValue: 'Past value',
  Description: 'Description',
  TaskAt: 'Task #{num} dated {date}',
  RejectionReason: 'Rejection reason',
  Approved: 'Approved',
  Rejected: 'Rejected',
  New: 'New',
  Repeated: 'Repeated',
  Pcs: 'pcs',
  Kg: 'kg',
  Gram: 'G',
  Litre: 'l',
  Millilitre: 'ml',
  UnitMeasureShort: 'Unit',
  SaleBy: 'Sale by ({value})',
  WeightOfOnePiece: 'Weight of one piece',
  PurchaseStep: 'Purchase step',
  PricePer: 'Price per ({value})',
  EntityShort: 'Entity',
  StoreNameAndNumber: 'Store name and number',
  MarketingTags: 'Marketing tags',
  SelectMarket: 'Select market',
  Add: 'Add',
  SureWantRemoveTag: 'Are you sure you want to remove the tag?',
  Tag: 'Tag',
  ChangeProductTag: 'Change product tag',
  ProductChange: 'Product change',
  AddingProduct: 'Adding a product',
  Sellers: 'Sellers',
  Markets: 'Markets',
  Notifications: 'Notifications',
  Analytics: 'Analytics',
  Tags: 'Tags',
  Moderation: 'Moderation',
  RegisterSeller: 'Register seller',
  FullRegistrationSeller: 'Full registration of the seller (IP or LLC)',
  InProcessRegistration: 'In the process of registration',
  ListTemplatesInProgress: 'List of templates in progress',
  ShowcasesSellers: 'Showcases of sellers',
  InformationSellersProducts: 'Information about all sellers and their products',
  CopyProducts: 'Copy Products',
  CopyingAllProductsInfo:
    'Copying all products from one seller to another' +
    ' (if the goods are transferred to the "General" seller, then the "place" is filled from the profile)',
  SystemVendors: 'System vendors',
  SellersInProcessRegistration: 'Sellers in the process of registration',
  SureDeleteSellerProfile: 'Are you sure you want to delete your seller profile {seller}?',
  Confirm: 'Confirm',
  ShowcaseSeller: 'Showcase of the seller',
  Missing: 'Missing',
  SureDeleteProduct: 'Are you sure you want to delete the item?',
  Hidden: 'Hidden',
  All: 'All',
  Active: 'Active',
  HiddenS: 'Hidden',
  SellerPlace: 'Seller\'s place',
  AddNewProduct: 'Add new item',
  EditingSellerShowcase: 'Editing the seller\'s showcase',
  Polygons: 'Polygons',
  Slots: 'Slots',
  Assortment: 'Assortment',
  Catalog: 'Catalog',
  EditPolygons: 'Edit polygons',
  OpeningHours: 'Opening hours',
  DeliveryHours: 'Delivery hours',
  MonSunFromTo: 'Mon-Sun from {startTime} to {endTime}',
  SomeIntervalsNotSaved: 'Some intervals were not saved',
  CannotCreateIntervalsOverlapAnother: 'You cannot create intervals that overlap one another.',
  DeliverySlots: 'Delivery slots',
  DeliverySlotsNotSet: 'Delivery slots not set yet',
  HereConfigureDeliverySlots: 'Here you can add and configure delivery slots',
  AddSlots: 'Add slots',
  AddSlot: 'Add slot',
  BestFromMarket: 'Best from the market',
  Stalls: 'Stalls',
  SearchByProducts: 'Search by products',
  SearchBySellers: 'Search by sellers',
  ShopsNotFound: 'Shops not found',
  ProductsNotFound: 'Products not found',
  StallsSearchEmpty:
    'Check the request. Perhaps there is an error in it. Or look for goods at the best from the market.',
  BestFromMarketSearchEmpty: 'Check the request. Perhaps there is an error in it. Or look for goods in shops.',
  AssortmentNotCreated: 'Assortment not created yet',
  HereCreateCategoriesSubcategories: 'Here you can create categories and subcategories for the market',
  CreateAssortment: 'Create assortment',
  CategoriesAndSubcategories: 'Categories and subcategories',
  Edit: 'Edit',
  Categories: 'Categories',
  Subcategories: 'Subcategories',
  AddSubcategory: 'Add subcategory',
  FinishEditing: 'Finish editing',
  SubcategoriesR: 'Subcategories',
  GoodsR: 'Goods',
  AddedProducts: 'Added products',
  CategoryHidden: 'The category is currently hidden in the app',
  CategoryHiddenInfo: 'Users will not see it in categories',
  SubcategoryHidden: 'The subcategory is currently hidden in the app',
  SubcategoryHiddenInfo: 'Users will not see her products in the category',
  Other: 'Other',
  UpdateProductRatings: 'Update product ratings',
  SureUpdateRatingsInSubcategory: 'Are you sure you want to update the ratings of all products in a subcategory?',
  UpdateRatingsWarning:
    'After the update, all products in the subcategory will have a real rating,' +
    ' which depends on the frequency of purchase of the product.' +
    ' Accordingly, their order of issuance in the application will change.',
  Refresh: 'Refresh',
  AddCategory: 'Add category',
  CategoryName: 'Name of category',
  CategoryCurrentlyHidden: 'The category is currently hidden in the app',
  CategoryIsVisible: 'The category is now visible in the app',
  ClickCategoryVisible: 'Click to make category visible',
  ClickHideCategory: 'Click to hide category',
  OnlyCategoryCannotHidden: 'The only category in the assortment cannot be hidden',
  OnlyCategoryCannotDeleted: 'The only category in the assortment cannot be deleted',
  RemovingCategory: 'Removing the «{category}» category',
  DeletingCategoryWarning:
    'Deleting a category will delete all subcategories in that category. This action cannot be undone. Delete?',
  ImageEditing: 'Image editing',
  SubcategoryName: 'Subcategory name',
  SubcategoryCurrentlyHidden: 'The subcategory is currently hidden in the application.',
  SubcategoryIsVisible: 'The subcategory is now visible in the application.',
  ClickHideSubcategory: 'Click to hide subcategory',
  ClickSubcategoryVisible: 'Click to make a subcategory visible',
  MarketName: 'Market name',
  TagName: 'Tag name',
  SignIn: 'Sign in',
  Login: 'Login',
  EnterLogin: 'Enter login',
  Password: 'Password',
  EnterPassword: 'Enter password',
  Error: 'Error',
  FieldRequired: 'This field is required',
  ForSpecificCustomers: 'For specific customers',
  SureRemoveNotification: 'Are you sure you want to remove the notification "{title}"?',
  Notification: 'Notification',
  Header: 'Header',
  Text: 'Text',
  DispatchTime: 'Dispatch time',
  Type: 'Type',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  DaysOfWeek: 'Days of the week',
  Periodicity: 'Periodicity',
  StartingFromCurrent: 'Starting from the current',
  HowManyWeeksRepeat: 'How many weeks will it repeat',
  UserGroup: 'User group',
  EnterClientNumberOrName: 'Enter client number or name',
  NotLessAndNotMoreCharacters: 'Not less than {less} and not more than {more} characters',
  IncorrectFormat: 'Incorrect format',
  SentNotifications: 'Sent notifications',
  Quantity: 'Quantity',
  DepartureDate: 'Departure date',
  Single: 'Single',
  Scheduled: 'Scheduled',
  AllUsers: 'All users',
  RegisteredUsers: 'Registered users',
  UnregisteredUsers: 'Unregistered users',
  UserType: 'User type',
  SignOut: 'Sign out?',
  Yes: 'Yes',
  Admin: 'Admin',
  Manager: 'Manager',
  Operator: 'Operator',
  SelectTags: 'Select tags',
  ChangeHowReceive: 'Change how you receive',
  DeliveryAddress: 'Delivery address',
  EnterAddress: 'Enter address',
  EnterDeliveryDate: 'Please enter a delivery date',
  SpecifyDeliveryTime: 'Specify delivery time',
  Apply: 'Apply',
  Reset: 'Reset',
  Delete: 'Delete',
  QuantityGoods: 'Quantity of goods',
  EnterTitle: 'Enter the title',
  EditTag: 'Edit tag',
  CreateTag: 'Create tag',
  Color: 'Color',
  Priority: 'Priority',
  Image: 'Image',
  Download: 'Download',
  NoMoreThanCharacters: 'No more than {length} characters',
  StallsSellers: 'Stalls (sellers)',
  ProductLinks: 'Product links',
  ThroughCategories: 'Through categories',
  ThroughSeller: 'Through the seller',
  Country: 'Country',
  VendorCode: 'Vendor code',
  Rating: 'Rating',
  Unit: 'Unit',
  WeightOnePieceGrams: 'Weight of one piece in grams',
  SellBy: 'Sell by ({unit})',
  DiscountPriceFor: 'Discount price for ({unit})',
  OriginalPrice: 'Original price',
  UpdatePriceWithCommission: 'Update price with commission ({commission}%)',
  PriceWithoutDiscountFor: 'Price without discount for ({unit})',
  CreateCopy: 'Create a copy',
  ValueMustGreaterThan: 'Value must be greater than {value}',
  ValueMustNotExceedCharacters: 'The value must not exceed {limit} characters',
  ValueMustNumberGreaterThan: 'Value must be a number and greater than {value}',
  MustAddImage: 'Add image',
  Added: 'Added',
  ShopName: 'Shop name',
  SellerNameNickname: 'Seller name/Nickname',
  PhotoYourShop: 'Photo of your shop',
  SellerPhoto: 'Seller photo',
  AssignOrder: 'Assign order',
  FieldMustNotEmpty: 'The field must not be empty',
  Weight: 'Weight',
  ForAmount: 'ForAmount',
  InAssembly: 'In assembly',
  AtCourier: 'At courier',
  OnDelivery: 'On delivery',
  Statuses: 'Statuses',
  Canceled: 'Canceled',
  Period: 'Period',
  Users: 'Users',
  NewRegisteredUsers: 'New registered users',
  DeliveredMany: 'Delivered',
  CanceledMany: 'Canceled',
  NumberCustomers: 'Number of customers',
  NumberCustomersLeastOneOrder: 'Number of customers who made at least 1 order (delivered)',
  OfTheseNumberFirst: 'Of these, number of first',
  NumberCustomersFirstOrder: 'Number of customers who made an order (delivered) for the first time',
  NumberOrdersFirstMonth: 'Number of user orders (first month)',
  AverageOrdersFirstMonth:
    'Average number of orders in the first month of use after registration' +
    ' for users created since the start of the selected interval',
  NumberOrdersLastMonth: 'Number of user orders (last month)',
  AverageOrdersLastMonth:
    'The average number of orders for the last month of use after registration' +
    ' for users created before the end of the selected interval',
  Turnover: 'Turnover',
  CommissionPlatform: 'Tvoy Rynok Commission',
  TotalWeight: 'Total weight',
  Total: 'Total',
  TotalStats: 'Total stats',
  OrderCard: 'Order card',
  ItemRejection: 'Item rejection',
  SpecifyReason: 'Specify the reason',
  DeliverySlot: 'Delivery slot',
  CustomerCard: 'Customer card',
  ItemsReport: 'Items report',
  Polygon: 'Polygon',
  CopyingProducts: 'Copying products',
  SelectSellers: 'Select sellers',
  SellerCopyFrom: 'From which seller to copy',
  SellerCopyTo: 'Which seller to copy',
  CopiedSuccessfully: 'All items have been copied successfully',
  Completed: 'Completed',
  PolygonsNotSet: 'Delivery polygons not set yet',
  ConfigurePolygons: 'Here you can add and configure delivery polygons',
  AddPolygons: 'Add polygons',
  AddPolygon: 'Add polygon',
  PolygonDisabledToday:
    'Polygon is disabled for today ({time}). This means that users within it cannot order in-app delivery for today.',
  PolygonDisabledUnlimited:
    'The polygon is turned off indefinitely. This means that users within it cannot order delivery in the app.',
  AvailableOrderTime: 'Available order time',
  PriceDelivery: 'Delivery cost',
  MinimumOrder: 'Minimum order',
  AsSoonAsPossible: 'As soon as possible',
  BySlotNearest: 'By slot (nearest slot)',
  BySlotsThroughOne: 'By slots (through one slot)',
  BySlotsThroughTwo: 'By slots (through two slots)',
  AllDay: 'All day',
  AddAvailableTime: 'Add available time',
  Clear: 'Clear',
  PolygonShutdown: 'Polygon shutdown',
  DisableDeliverySlotsToday: 'Disable delivery slots for today ({time}) Users will only be able to order for tomorrow',
  DisableDeliverySlotsUnlimited: 'Turn off the delivery range for an unlimited time',
  SwitchOff: 'Switch off',
  FromWith: 'From',
  Before: 'Before',
  SlotOverloaded: 'The slot is overloaded. Shut it down or turn it up',
  CloseSlot: 'Close slot',
  OpenSlot: 'Open slot',
  SlotLoad: 'Slot load',
  FactPlan: 'Fact / plan',
  CouriersSlot: 'Couriers on the slot',
  PickersSlot: 'Pickers on the slot',
  ManagersSlot: 'Managers on the slot',
  DownloadIndex: 'Download index',
  CallMe: 'Call me',
  NotCallMe: 'Do not call me',
  IfProductOutOfStock: 'If the product is out of stock or defective',
  PickReplacement: 'Pick a replacement',
  DeleteProduct: 'Delete product',
  IfWeightItemChanged: 'If the weight of the item has changed',
  RemoveItemIfWeightChanges: 'Remove an item if its weight changes by more than 10%',
  ChangeItemWeight: 'Change the item\'s weight',
  AboutProductReplacement: 'About product replacement',
  AboutDeletingItem: 'About deleting an item',
  IfWeightItemChanges: 'If the weight of the item changes',
  IfNotAnswer: 'If I don\'t answer',
  DuringDay: 'During the day',
  PayAttention: 'Pay attention',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
  Daily: 'Daily',
  ByDayWeek: 'By day of the week',
  Once: 'Once',
  Weekly: 'Weekly',
  ToAllCustomers: 'To all customers',
  ToRegisteredCustomers: 'Registered customers',
  ToNotRegisteredCustomers: 'Not registered customers',
  EveryWeeks: 'Every {num} weeks',
  NotShift: 'Not shift',
  OnShift: 'On shift',
  DeliversOrder: 'Delivers order',
  Free: 'Free',
  CollectsOrder: 'Collects an order',
  ProductCreationError: 'Product creation error',
  ProductUpdateError: 'Product update error',
  ProductDeletionError: 'Product deletion error',
  ProductCopyError: 'Product copy error',
  ProductApproved: 'Product approved and moved to Archive',
  ProductRejected: 'Product rejected and moved to Archive',
  Successfully: 'Successfully',
  ModerationUpdateError: 'Product moderation status update error',
  General: 'General',
  CustomerOrders: 'Customer orders',
  Products: 'Products',
  ProductsChain: 'Product links',
  DefaultProductGroup: 'Default product group',
  DefaultProductGroupInfo: 'Displayed for products if links are not configured for them',
  CreateGroup: 'Create group',
  SpecificProductsLinks: 'Links to specific products',
  SpecificProductsLinksInfo: 'Here you can add links between products',
  AddLinks: 'Add links',
  ProductsSelected: 'Products selected',
  ResetAllProducts: 'Reset all products',
  TieUp: 'Tie up',
  Change: 'Change',
  Group: 'Group',
  RemovingGroup: 'Are you sure you want to delete the default group?',
  RemovingGroupWarning: 'This action cannot be undone',
  ItemToTie: 'Item to tie',
  WhatTie: 'What to tie',
  WhatToConnectWith: 'What to connect with',
  Select: 'Select',
  Link: 'Link',
  Hide: 'Hide',
  HideOff: 'Make visible',
  RemovingLink: 'Are you sure you want to delete this product link?',
  INN: 'TIN',
  Selected: 'Selected',
  SelectAnother: 'Select another',
  InWork: 'In work',
  CourierRoutes: 'Courier routes',
  Route: 'Route',
  ViewRoute: 'View route',
  ChangeRoute: 'Change route',
  OrderStatus: 'Order status',
  Set: 'Set',
  NotSet: 'Not set',
  SetRoute: 'Set route',
  Order: 'Order',
  DeliveryTo: 'Delivery to',
  Assigned: 'Assigned',
  OnRoute: 'On route',
  BuildRoute: 'Build route',
  Km: 'Km',
  Appoint: 'Appoint',
  AppointmentCourier: 'Appointment of a courier',
  Car: 'Car',
  Moto: 'Moto',
  DeleteRouteWarning: 'Are you sure you want to delete this route?',
  CannotUndone: 'This action cannot be undone',
  BreakFreeIn: 'Break free in',
  RouteCreatedSuccessfully: 'Route created successfully',
  AssemblyTo: 'Assembly to',
  IsDeliveryTo: 'Delivery to',
  ChangedAmount: 'Changed amount',
  OrderCollected: 'Order collected',
  OrderPicked: 'Order picked',
  IsLate: 'Being late:',
  ProductAssembled: 'Product assembled',
  ProductDeleted: 'Product deleted',
  OrderCreated: 'Order created',
  OrderInAssembly: 'Order in assembly',
  CourierTookOrderForDelivery: 'Courier took the order for delivery',
  OrderDeliveredToCustomer: 'Order delivered to customer',
  OrderCancelled: 'Order cancelled',
  OrderPayed: 'Order payed',
  Assign: 'assign',
  Week: 'Week',
  Month: 'Month',
  Quarter: 'Quarter',
  Year: 'Year',
  Source: 'Source',
  GeneralAll: 'General',
  AllMarkets: 'All markets',
  NumberOrdersPerUser: 'Number of orders per user',
  TotalAmount: 'Total amount',
  AverageWeight: 'Average weight',
  NewUsers: 'New users',
  Profit: 'Profit',
  FirstMonthUsersOrders: 'First month',
  LastMonthUsersOrders: 'Last month',
  PaymentForGoods: 'Payment for goods',
  RepeatBuyers: 'Repeat buyers',
  AverageForPeriod: 'Average for the period',
  Currency: 'Currency',
  SelectMarketFilter: 'Select a market to activate the filter',
  AddEmployee: 'Add employee',
  Surname: 'Surname',
  CollectorR: 'Collector',
  CourierR: 'Courier',
  WorkingHours: 'Working hours',
  SlotsWork: 'Slots of work',
  DeleteEmployee: 'Delete employee',
  DeletingEmployee: 'Deleting employee',
  WarningDeletingEmployee: 'Removal {courier} from the schedule for {date}?',
}

export default en
