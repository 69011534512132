import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const InfoIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='M12.879 16.307a.879.879 0 1 1-1.758 0 .879.879 0 0 1 1.758 0ZM9.188 10.33A2.816 2.816 0 0 1 12 7.518a2.816 2.816 0 0 1 2.813 2.812c0 1.308-.898 2.41-2.11 2.724v1.143a.703.703 0 0 1-1.406 0V12.44c0-.389.315-.704.703-.704.775 0 1.406-.63 1.406-1.406 0-.775-.63-1.406-1.406-1.406-.775 0-1.406.63-1.406 1.406a.703.703 0 0 1-1.406 0Z'
      />
      <path
        fill={color}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 12c0-4.975 4.026-9 9-9 4.975 0 9 4.026 9 9 0 4.975-4.026 9-9 9-4.975 0-9-4.026-9-9Zm1.406 0A7.59 7.59 0 0 0 12 19.594 7.59 7.59 0 0 0 19.594 12 7.59 7.59 0 0 0 12 4.406 7.59 7.59 0 0 0 4.406 12Z'
      />
    </svg>
  )
}
