import { mergeMap, catchError } from 'rxjs/operators'
import { of as observableOf } from 'rxjs'
import Axios from 'axios-observable'

import { ApiReportProductsResp, ApiReportOrdersResp, ApiReportCourierShiftsResp } from '../../types/TApi'
import { EReportFormat } from '../../types/TClient'

import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import * as Actions from '../actions'
import {
  URL_REPORT_PRODUCTS,
  URL_REPORT_ORDERS,
  URL_REPORT_ORDERS_CUSTOMERS,
  URL_REPORT_CUSTOMERS,
  URL_REPORT_COURIER_SHIFTS,
} from '../../modules/network/urls'
import { downloadFile } from '../../utils/fileUtils'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import { intlMessage } from '../../App/LangContainer'
import messages from '../../localization/messages'

const reportOrdersEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiReportOrders>(a$, Actions.API_REPORT_ORDERS), (b) =>
    b.pipe(
      mergeMap((c) => {
        return Axios.get(URL_REPORT_ORDERS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.city && { city: c.data.city }),
            ...(c.data.market && { market: c.data.market }),
            ...(c.data.statuses && c.data.statuses.length > 0 && { statuses: c.data.statuses.join(',') }),
            ...(c.data.dateFrom && { dateFrom: c.data.dateFrom }),
            ...(c.data.dateTo && { dateTo: c.data.dateTo }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
            asc: c.data.asc || c.data.asc === undefined,
          },
        }).pipe(
          mergeMap((resp: { data: ApiReportOrdersResp }) => {
            const reportName =
              `${intlMessage(messages.Orders)}_${
                c.data.b2b !== undefined ? (c.data.b2b ? 'B2B' : 'B2C') : intlMessage(messages.General).toLowerCase()
              }_` + `${c.data.dateFrom}_${c.data.dateTo}.csv`

            if (resp.data && resp.data.data) {
              switch (c.data.type) {
                case EReportFormat.EXCEL:
                  downloadFile('"sep=;"\n' + resp.data.data, reportName)
                  break
                default:
                  downloadFile(resp.data.data, reportName)
              }
            }

            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_ORDERS, false))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_ORDERS, false))
          }),
        )
      }),
    ),
  )

const reportOrdersCustomersEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiReportOrdersCustomers>(a$, Actions.API_REPORT_ORDERS_CUSTOMERS), (b) =>
    b.pipe(
      mergeMap((c) => {
        return Axios.get(URL_REPORT_ORDERS_CUSTOMERS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.dateFrom && { dateFrom: c.data.dateFrom }),
            ...(c.data.dateTo && { dateTo: c.data.dateTo }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
          },
        }).pipe(
          mergeMap((resp: { data: ApiReportOrdersResp }) => {
            const reportName =
              `${intlMessage(messages.CustomerOrders).replace(' ', '_')}_${
                c.data.b2b !== undefined ? (c.data.b2b ? 'B2B' : 'B2C') : intlMessage(messages.General).toLowerCase()
              }_` + `${c.data.dateFrom}_${c.data.dateTo}.csv`

            if (resp.data && resp.data.data) {
              switch (c.data.type) {
                case EReportFormat.EXCEL:
                  downloadFile('"sep=;"\n' + resp.data.data, reportName)
                  break
                default:
                  downloadFile(resp.data.data, reportName)
              }
            }

            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_ORDERS, false))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_ORDERS, false))
          }),
        )
      }),
    ),
  )

const reportProductsEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiReportProducts>(a$, Actions.API_REPORT_PRODUCTS), (b) =>
    b.pipe(
      mergeMap((c) => {
        return Axios.get(URL_REPORT_PRODUCTS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.byCategories && { byCategories: c.data.byCategories }),
            ...(c.data.market && { market: c.data.market }),
            ...(c.data.seller && { seller: c.data.seller }),
            ...(c.data.hidden !== undefined && { hidden: c.data.hidden }),
          },
        }).pipe(
          mergeMap((resp: { data: ApiReportProductsResp }) => {
            const marketName = c.data.marketName ? `_${c.data.marketName.replaceAll(' ', '_')}` : ''
            const sellerName = c.data.sellerName ? `_${c.data.sellerName.replaceAll(' ', '_')}` : ''
            const reportName = `${intlMessage(messages.Products)}${marketName}${
              c.data.byCategories ? `_${intlMessage(messages.BestFromMarket).replace(/ /g, '_')}` : `${sellerName}`
            }.csv`

            if (resp.data && resp.data.data) {
              switch (c.data.type) {
                case EReportFormat.EXCEL:
                  downloadFile('"sep=;"\n' + resp.data.data, reportName)
                  break
                default:
                  downloadFile(resp.data.data, reportName)
              }
            }

            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_PRODUCTS, false))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_PRODUCTS, false))
          }),
        )
      }),
    ),
  )

const reportCustomersEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiCustomerReport>(a$, Actions.API_CUSTOMER_REPORT), (b) =>
    b.pipe(
      mergeMap((c) => {
        return Axios.get(URL_REPORT_CUSTOMERS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.ids && { ids: c.data.ids.join(',') }),
            ...(c.data.offset && { offset: c.data.offset }),
            ...(c.data.city && { city: c.data.city }),
            ...(c.data.search && { search: c.data.search }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
            ...(c.data.lastOrderDesc !== undefined && { lastOrderDesc: c.data.lastOrderDesc }),
            ...(c.data.firstOrderDesc !== undefined && { firstOrderDesc: c.data.firstOrderDesc }),
            ...(c.data.registerDesc !== undefined && { registerDesc: c.data.registerDesc }),
            ...(c.data.numOrdersDesc !== undefined && { numOrdersDesc: c.data.numOrdersDesc }),
            ...(c.data.dateTo !== undefined && { dateTo: c.data.dateTo }),
            ...(c.data.dateFrom !== undefined && { dateFrom: c.data.dateFrom }),
            ...(c.data.lastOrderDateTo !== undefined && { lastOrderDateTo: c.data.lastOrderDateTo }),
            ...(c.data.lastOrderDateFrom !== undefined && { lastOrderDateFrom: c.data.lastOrderDateFrom }),
            ...(c.data.firstOrderDateTo !== undefined && { firstOrderDateTo: c.data.firstOrderDateTo }),
            ...(c.data.firstOrderDateFrom !== undefined && { firstOrderDateFrom: c.data.firstOrderDateFrom }),
          },
        }).pipe(
          mergeMap((resp: { data: ApiReportProductsResp }) => {
            const reportName = `${intlMessage(messages.Customers)}_${
              c.data.b2b !== undefined ? (c.data.b2b ? 'B2B' : 'B2C') : intlMessage(messages.General).toLowerCase()
            }.csv`

            downloadFile(resp.data.data, reportName)

            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_CUSTOMERS, false))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_CUSTOMERS, false))
          }),
        )
      }),
    ),
  )

const reportCourierShiftsEpic: EpicFunc = (a$, _s) =>
  guardExhaustMap(ofType<Actions.ApiReportCourierShifts>(a$, Actions.API_REPORT_COURIER_SHIFTS), (b) =>
    b.pipe(
      mergeMap((c) => {
        return Axios.get(URL_REPORT_COURIER_SHIFTS, {
          ...authRequestConfig(),
          params: {
            market: c.data.market,
            date: c.data.date,
            ...(c.data.dateFrom && { dateFrom: c.data.dateFrom }),
            ...(c.data.dateTo && { dateTo: c.data.dateTo }),
          },
        }).pipe(
          mergeMap((resp: { data: ApiReportCourierShiftsResp }) => {
            const reportName = `${intlMessage(messages.ShiftSchedule)}_${c.data.date}.csv`

            if (resp.data && resp.data.data) {
              switch (c.data.type) {
                case EReportFormat.EXCEL:
                  downloadFile('"sep=;"\n' + resp.data.data, reportName)
                  break
                default:
                  downloadFile(resp.data.data, reportName)
              }
            }

            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_COURIER_SHIFTS, false))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_LOADING_REPORT_COURIER_SHIFTS, false))
          }),
        )
      }),
    ),
  )

export const reportsEpics = [
  reportOrdersEpic,
  reportOrdersCustomersEpic,
  reportProductsEpic,
  reportCustomersEpic,
  reportCourierShiftsEpic,
]
