import React from 'react'

type TProps = {
  color?: string,
}

export const VisibleOffIcon: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={color}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M10.282 5.478A7.592 7.592 0 0 1 12 5.285c5.727 0 9 6.429 9 6.429a14.855 14.855 0 0 1-1.767 2.563m-5.498-.86a2.455 2.455 0 0 1-.797.559 2.493 2.493 0 0 1-2.735-.498 2.408 2.408 0 0 1-.54-.801 2.372 2.372 0 0 1 .034-1.884c.134-.296.327-.562.569-.783m6.594 6.477A8.335 8.335 0 0 1 12 18.142c-5.727 0-9-6.428-9-6.428A14.9 14.9 0 0 1 7.14 6.94l9.72 9.546ZM4.286 4l15.428 15.429'
      />
    </svg>
  )
}
