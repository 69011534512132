import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const CloseIcon: React.FC<TProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox='4 4 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} d='M20 8 8 20M8 8l12 12' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
