import React from 'react'
import { Router } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { YMaps } from 'react-yandex-maps'
import { LoadScript } from '@react-google-maps/api'

import './App.scss'

import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { SignUp } from './pages/SignUp'
import { ModalContainer } from './ModalContainer'
import { AlertsContainer } from './AlertsContainer'
import { LangContainer } from './LangContainer'
import { LOCATION_LOGIN, LOCATION_SIGN_UP, LOCATION_MAIN, history } from '../utils/locationUtils'

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || ''

function App() {
  return (
    <YMaps>
      <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
        <LangContainer>
          <div className='app'>
            <Router history={history}>
              <Switch>
                <Route path={LOCATION_SIGN_UP} component={SignUp} />
                <Route path={LOCATION_LOGIN} component={Login} />
                <Route path={LOCATION_MAIN} component={Home} />
              </Switch>
            </Router>
            <ModalContainer />
            <AlertsContainer />
          </div>
        </LangContainer>
      </LoadScript>
    </YMaps>
  )
}

export default App
