import * as TApi from '../types/TApi'
import * as TClient from '../types/TClient'
import {
  EOrderStatus,
  IClientProfile,
  ICustomersFilter,
  IOrder,
  IShiftSlot,
  ISlot,
  ISlotInfo,
  ITag,
} from '../types/TClient'
import {
  ApiGetTagsReq,
  TCreateTag,
  TUpdateTag,
  IClientSlotsReq,
  IClientSlotsResp,
  ApiUpdateCourierShiftsReq,
  ApiCourierShiftsReq,
} from '../types/TApi'

import { EModalType, IModal } from './reducers/modals'
import { IAlert } from './reducers/alerts'

export type AE<T> = { type: T }
export type A<T, D> = {
  type: T,
  data: D,
}

export const STOP_EPIC = 'STOP_EPIC'
export type StopEpic = AE<typeof STOP_EPIC>

export const APP_LOADED = 'APP_LOADED'
export type AppLoaded = AE<typeof APP_LOADED>

export const SET_INTERFACE_LANG = 'SET_INTERFACE_LANG'
export type SetInterfaceLang = A<typeof SET_INTERFACE_LANG, { lang: string }>

export const MODAL_PUSH = 'MODAL_PUSH'
export type ModalPush = A<typeof MODAL_PUSH, IModal>

export const MODAL_POP = 'MODAL_POP'
export type ModalPop = A<typeof MODAL_POP, { modal?: EModalType }>

export const MODAL_CLEAR = 'MODAL_CLEAR'
export type ModalClear = AE<typeof MODAL_CLEAR>

export const ALERT_PUSH = 'ALERT_PUSH'
export type AlertPush = A<typeof ALERT_PUSH, IAlert>

export const ALERT_POP = 'ALERT_POP'
export type AlertPop = A<typeof ALERT_POP, { id?: string }>

export const ALERT_CLEAR = 'ALERT_CLEAR'
export type AlertClear = AE<typeof ALERT_CLEAR>

export const API_LOGIN = 'API_LOGIN'
export type ApiLogin = A<typeof API_LOGIN, TApi.ApiLoginReq>

export const USER = 'USER'
export type User = A<typeof USER, { sid?: string } & TClient.IUser>

export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export type UserLoginError = A<typeof USER_LOGIN_ERROR, { error: string }>

export const API_SIGN_UP = 'API_SIGN_UP'
export type ApiSignUp = A<typeof API_SIGN_UP, TApi.ApiSignUpReq>

export const USER_SIGN_UP_ERROR = 'USER_SIGN_UP_ERROR'
export type UserSignUpError = A<typeof USER_SIGN_UP_ERROR, { error: string }>

export const API_USER_INFO = 'API_USER_INFO'
export type ApiUserInfo = AE<typeof API_USER_INFO>

export const DROP_API_ERROR = 'DROP_ERROR'
export type DropApiError = AE<typeof DROP_API_ERROR>

export const LOGOUT = 'LOGOUT'
export type Logout = AE<typeof LOGOUT>

export const API_ORDERS_LIST = 'API_ORDERS_LIST'
export type ApiOrdersList = A<typeof API_ORDERS_LIST, TClient.IOrdersFilter>

export const ORDERS_LIST = 'ORDERS_LIST'
export type OrdersList = A<
  typeof ORDERS_LIST,
  {
    orders: TClient.IOrder[],
    total?: number,
    force?: boolean,
    offset?: number,
  }
>

export const API_ORDERS_MAP_LIST = 'API_ORDERS_MAP_LIST'
export type ApiOrdersMapList = A<typeof API_ORDERS_MAP_LIST, TClient.IOrdersFilter>

export const ORDERS_MAP_LIST = 'ORDERS_MAP_LIST'
export type OrdersMapList = A<
  typeof ORDERS_MAP_LIST,
  {
    orders: TClient.IOrderByMap[],
    total?: number,
    force?: boolean,
    offset?: number,
  }
>

export const API_UPDATE_ORDER = 'API_UPDATE_ORDER'
export type ApiUpdateOrder = A<typeof API_UPDATE_ORDER, TApi.ApiUpdateOrderReq>

export const UPDATE_ORDER = 'UPDATE_ORDER'
export type UpdateOrder = A<typeof UPDATE_ORDER, { id: string } & Partial<TClient.IOrder>>

export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR'
export type UpdateOrderError = A<typeof UPDATE_ORDER_ERROR, boolean>

export const API_ADD_PRODUCTS_ORDER = 'API_ADD_PRODUCTS_ORDER'
export type ApiAddProductsOrder = A<typeof API_ADD_PRODUCTS_ORDER, TApi.ApiAddProductsOrderReq>

export const ORDERS_FILTER = 'ORDERS_FILTER'
export type OrdersFilter = A<typeof ORDERS_FILTER, TClient.IOrdersFilter>

export const API_ORDERS_BY_MARKETS = 'API_ORDERS_BY_MARKETS'
export type ApiOrdersByMarkets = A<typeof API_ORDERS_BY_MARKETS, TClient.IOrdersByMarketsFilter>

export const API_TOTAL_ORDERS = 'API_TOTAL_ORDERS'
export type ApiTotalOrders = A<typeof API_TOTAL_ORDERS, TClient.IAnalyticsFilter>

export const TOTAL_ORDERS = 'TOTAL_ORDERS'
export type TotalOrders = A<
  typeof TOTAL_ORDERS,
  {
    totalOrders: TClient.ITotalOrders[],
    currencyPairs?: TClient.ICurrencyPairs,
  }
>

export const API_CUSTOMERS = 'API_CUSTOMERS'
export type ApiCustomers = A<typeof API_CUSTOMERS, TClient.ICustomersFilter>

export const NEW_CUSTOMERS = 'NEW_CUSTOMERS'
export type NewCustomers = A<typeof NEW_CUSTOMERS, TClient.INewCustomers>

export const API_CUSTOMERS_LIST = 'API_CUSTOMERS_LIST'
export type ApiCustomersList = A<typeof API_CUSTOMERS_LIST, TApi.ApiCustomersListReq>

export const API_CUSTOMER_UPDATE = 'API_CUSTOMER_UPDATE'
export type ApiCustomerUpdate = A<typeof API_CUSTOMER_UPDATE, TApi.ApiCustomerUpdateProfileReq>

export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS'
export type CustomerUpdateSuccess = AE<typeof CUSTOMER_UPDATE_SUCCESS>

export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR'
export type CustomerUpdateError = AE<typeof CUSTOMER_UPDATE_ERROR>

export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE'
export type CustomerUpdate = A<typeof CUSTOMER_UPDATE, TClient.ICustomerUpdateProfile>

export const CUSTOMERS_LIST = 'CUSTOMERS_LIST'
export type CustomersList = A<
  typeof CUSTOMERS_LIST,
  {
    customers: TClient.ICustomer[],
    total?: number,
    totalWithOrders?: number,
    totalWithoutOrders?: number,
    force?: boolean,
    offset?: number,
  }
>

export const CUSTOMERS_FILTER = 'CUSTOMERS_FILTER'
export type CustomersFilter = A<typeof CUSTOMERS_FILTER, TClient.ICustomersFilter>

export const API_PRODUCTS_MODERATION_LIST = 'API_PRODUCTS_MODERATION_LIST'
export type ApiProductsModerationList = A<typeof API_PRODUCTS_MODERATION_LIST, TApi.ApiProductsModerationListReq>

export const PRODUCTS_MODERATION_LIST = 'PRODUCTS_MODERATION_LIST'
export type ProductsModerationList = A<
  typeof PRODUCTS_MODERATION_LIST,
  {
    products: TClient.IModerationProduct[],
    totalOnModeration?: number,
    total?: number,
    force?: boolean,
    offset?: number,
  }
>

export const API_UPDATE_MODERATION_PRODUCT = 'API_UPDATE_MODERATION_PRODUCT'
export type ApiUpdateModerationProduct = A<typeof API_UPDATE_MODERATION_PRODUCT, TApi.ApiUpdateModerationProductReq>

export const UPDATE_MODERATION_PRODUCT = 'UPDATE_MODERATION_PRODUCT'
export type UpdateModerationProduct = A<typeof UPDATE_MODERATION_PRODUCT, TClient.IModerationProduct>

export const MODERATION_PRODUCTS_FILTER = 'MODERATION_PRODUCTS_FILTER'
export type ModerationProductsFilter = A<typeof MODERATION_PRODUCTS_FILTER, TClient.IModerationProductsFilter>

export const API_GROUPS_LIST = 'API_GROUPS_LIST'
export type ApiGroupsList = A<typeof API_GROUPS_LIST, TApi.ApiGroupsListReq>

export const GROUPS_LIST = 'GROUPS_LIST'
export type GroupsList = A<
  typeof GROUPS_LIST,
  {
    groups: TClient.IGroupProducts[],
  }
>

export const API_CREATE_GROUP = 'API_CREATE_GROUP'
export type ApiCreateGroup = A<typeof API_CREATE_GROUP, TApi.ApiCreateGroupReq>

export const API_UPDATE_GROUP = 'API_UPDATE_GROUP'
export type ApiUpdateGroup = A<typeof API_UPDATE_GROUP, TApi.ApiUpdateGroupReq>

export const API_REMOVE_GROUP = 'API_REMOVE_GROUP'
export type ApiRemoveGroup = A<typeof API_REMOVE_GROUP, TApi.ApiRemoveGroupReq>

export const CREATE_GROUP_PRODUCT = 'CREATE_GROUP_PRODUCT'
export type CreateGroupProduct = A<typeof CREATE_GROUP_PRODUCT, TClient.IGroupProducts>

export const SET_GROUP_PRODUCTS = 'SET_GROUP_PRODUCTS'
export type SetGroupProducts = A<
  typeof SET_GROUP_PRODUCTS,
  {
    groupId: string,
    products: TClient.IGroupProduct[],
  }
>

export const UPDATE_GROUP_PRODUCT = 'UPDATE_GROUP_PRODUCT'
export type UpdateGroupProduct = A<typeof UPDATE_GROUP_PRODUCT, TClient.IGroupProducts>

export const REMOVE_GROUP_PRODUCT = 'REMOVE_GROUP_PRODUCT'
export type RemoveGroupProduct = A<typeof REMOVE_GROUP_PRODUCT, string>

export const API_CUSTOMER_ADDRESSES = 'API_CUSTOMER_ADDRESSES'
export type ApiCustomerAddresses = A<typeof API_CUSTOMER_ADDRESSES, TApi.ApiCustomerAddressesReq>

export const CUSTOMER_ADDRESSES = 'CUSTOMER_ADDRESSES'
export type CustomerAddresses = A<
  typeof CUSTOMER_ADDRESSES,
  {
    addresses: TClient.IDeliveryAddress[],
    total?: number,
    force?: boolean,
    offset?: number,
  }
>

export const API_CUSTOMER_REVIEWS = 'API_CUSTOMER_REVIEWS'
export type ApiCustomerReviews = A<typeof API_CUSTOMER_REVIEWS, TApi.ApiCustomerReviewsReq>

export const CUSTOMER_REVIEWS = 'CUSTOMER_REVIEWS'
export type CustomerReviews = A<
  typeof CUSTOMER_REVIEWS,
  {
    reviews: TClient.IReview[],
    total?: number,
    force?: boolean,
    offset?: number,
  }
>

export const API_CUSTOMER_ORDERS = 'API_CUSTOMER_ORDERS'
export type ApiCustomerOrders = A<typeof API_CUSTOMER_ORDERS, TApi.ApiCustomerReviewsReq>

export const CUSTOMER_ORDERS = 'CUSTOMER_ORDERS'
export type CustomerOrders = A<
  typeof CUSTOMER_ORDERS,
  {
    orders: TClient.IOrder[],
    total?: number,
    force?: boolean,
    offset?: number,
  }
>

export const API_CITIES = 'API_CITIES'
export type ApiCities = A<typeof API_CITIES, TApi.ApiCitiesReq>

export const CITIES = 'CITIES'
export type Cities = A<typeof CITIES, { cities: TClient.ICity[] }>

export const API_MARKETS = 'API_MARKETS'
export type ApiMarkets = A<typeof API_MARKETS, TApi.ApiMarketsReq>

export const MARKETS = 'MARKETS'
export type Markets = A<typeof MARKETS, { markets: TClient.IMarket[] }>

export const MARKET_DELIVERY_AREAS = 'MARKET_DELIVERY_AREAS'
export type MarketDeliveryAreas = A<
  typeof MARKET_DELIVERY_AREAS,
  { market: string, deliveryAreas: TClient.IDeliveryArea[] }
>

export const MARKET_DELIVERY_AREA_ADD = 'MARKET_DELIVERY_AREA_ADD'
export type MarketDeliveryAreaAdd = A<
  typeof MARKET_DELIVERY_AREA_ADD,
  { market: string, deliveryArea: TClient.IDeliveryArea }
>

export const MARKET_DELIVERY_AREA_UPDATE = 'MARKET_DELIVERY_AREA_UPDATE'
export type MarketDeliveryAreaUpdate = A<
  typeof MARKET_DELIVERY_AREA_UPDATE,
  { market: string, deliveryArea: TClient.IDeliveryArea }
>

export const MARKET_DELIVERY_AREA_REMOVE = 'MARKET_DELIVERY_AREA_REMOVE'
export type MarketDeliveryAreaRemove = A<typeof MARKET_DELIVERY_AREA_REMOVE, { market: string, deliveryAreaId: number }>

export const API_SAVE_DELIVERY_AREAS = 'API_SAVE_DELIVERY_AREAS'
export type ApiSaveDeliveryAreas = A<
  typeof API_SAVE_DELIVERY_AREAS,
  {
    market: string,
    deliveryAreas: TClient.IDeliveryArea[],
  }
>

export const API_ADD_DELIVERY_AREA = 'API_ADD_DELIVERY_AREA'
export type ApiAddDeliveryArea = A<typeof API_ADD_DELIVERY_AREA, TApi.ApiAddDeliveryAreaReq>

export const API_UPDATE_DELIVERY_AREA = 'API_UPDATE_DELIVERY_AREA'
export type ApiUpdateDeliveryArea = A<typeof API_UPDATE_DELIVERY_AREA, TApi.ApiUpdateDeliveryAreaReq>

export const API_REMOVE_DELIVERY_AREA = 'API_REMOVE_DELIVERY_AREA'
export type ApiRemoveDeliveryArea = A<typeof API_REMOVE_DELIVERY_AREA, TApi.ApiRemoveDeliveryAreaReq>

export const API_MARKET_CATEGORIES = 'API_MARKET_CATEGORIES'
export type ApiMarketCategories = A<typeof API_MARKET_CATEGORIES, TApi.ApiMarketCategoriesReq>

export const MARKET_CATEGORIES = 'MARKET_CATEGORIES'
export type MarketCategories = A<typeof MARKET_CATEGORIES, { market: string, categories: TClient.IStockCategory[] }>

export const API_CREATE_MARKET_CATEGORY = 'API_CREATE_MARKET_CATEGORY'
export type ApiCreateMarketCategory = A<typeof API_CREATE_MARKET_CATEGORY, TApi.ApiCreateMarketCategoryReq>

export const API_UPDATE_MARKET_CATEGORY = 'API_UPDATE_MARKET_CATEGORY'
export type ApiUpdateMarketCategory = A<typeof API_UPDATE_MARKET_CATEGORY, TApi.ApiUpdateMarketCategoryReq>

export const API_REMOVE_MARKET_CATEGORY = 'API_REMOVE_MARKET_CATEGORY'
export type ApiRemoveMarketCategory = A<typeof API_REMOVE_MARKET_CATEGORY, TApi.ApiRemoveMarketCategoryReq>

export const API_CREATE_MARKET_SUBCATEGORY = 'API_CREATE_MARKET_SUBCATEGORY'
export type ApiCreateMarketSubcategory = A<typeof API_CREATE_MARKET_SUBCATEGORY, TApi.ApiCreateMarketSubcategoryReq>

export const API_UPDATE_MARKET_SUBCATEGORY = 'API_UPDATE_MARKET_SUBCATEGORY'
export type ApiUpdateMarketSubcategory = A<typeof API_UPDATE_MARKET_SUBCATEGORY, TApi.ApiUpdateMarketSubcategoryReq>

export const API_REMOVE_MARKET_SUBCATEGORY = 'API_REMOVE_MARKET_SUBCATEGORY'
export type ApiRemoveMarketSubcategory = A<typeof API_REMOVE_MARKET_SUBCATEGORY, TApi.ApiRemoveMarketSubcategoryReq>

export const MARKET_CATEGORY_CREATE = 'MARKET_CATEGORY_CREATE'
export type MarketCategoryCreate = A<
  typeof MARKET_CATEGORY_CREATE,
  { market: string, category: TClient.IStockCategory }
>

export const MARKET_CATEGORY_UPDATE = 'MARKET_CATEGORY_UPDATE'
export type MarketCategoryUpdate = A<
  typeof MARKET_CATEGORY_UPDATE,
  { market: string, category: TClient.IStockCategory }
>

export const MARKET_CATEGORY_REMOVE = 'MARKET_CATEGORY_REMOVE'
export type MarketCategoryRemove = A<typeof MARKET_CATEGORY_REMOVE, { market: string, categoryId: string }>

export const MARKET_CATEGORY_SAVED_IMAGE = 'MARKET_CATEGORY_SAVED_IMAGE'
export type MarketCategorySavedImage = A<typeof MARKET_CATEGORY_SAVED_IMAGE, { imageUrl: string }>

export const API_SELLERS_INFO_LIST = 'API_SELLERS_INFO_LIST'
export type ApiSellersInfoList = A<typeof API_SELLERS_INFO_LIST, { offset?: number }>

export const SELLERS_INFO_LIST = 'SELLERS_INFO_LIST'
export type SellersInfoList = A<typeof SELLERS_INFO_LIST, { sellers: TClient.ISellerInfo[] }>

export const API_PARTNER_INFO = 'API_PARTNER_INFO'
export type ApiPartnerInfo = A<typeof API_PARTNER_INFO, { id?: string } & TApi.ApiPartnersReq>

export const PARTNER_INFO = 'PARTNER_INFO'
export type PartnerInfo = A<typeof PARTNER_INFO, { id?: string } & TClient.IPartner>

export const API_BANK_INFO = 'API_BANK_INFO'
export type ApiBankInfo = A<typeof API_BANK_INFO, { id?: string } & TApi.ApiBankReq>

export const BANK_INFO = 'BANK_INFO'
export type BankInfo = A<typeof BANK_INFO, { id?: string } & Pick<TClient.ISellerInfo, 'bik' | 'bankName' | 'corAcc'>>

export const SELLERS_DROP_LOADING = 'SELLERS_DROP_LOADING'
export type SellersDropLoading = AE<typeof SELLERS_DROP_LOADING>

export const API_CREATE_SELLER_INFO = 'API_CREATE_SELLER_INFO'
export type ApiCreateSellerInfo = A<typeof API_CREATE_SELLER_INFO, TApi.ApiSellerInfoCreateReq>

export const API_UPDATE_SELLER_INFO = 'API_UPDATE_SELLER_INFO'
export type ApiUpdateSellerInfo = A<typeof API_UPDATE_SELLER_INFO, TApi.ApiSellerInfoUpdateReq>

export const API_COURIER_SELLER = 'API_COURIER_SELLER'
export type ApiSellerCourier = A<typeof API_COURIER_SELLER, TApi.ApiSellerCourierReq>

export const API_COURIER_SELLER_SUCCESS = 'API_COURIER_SELLER_SUCCESS'
export type UpdateCourierSellerSuccess = A<typeof API_COURIER_SELLER_SUCCESS, { sellerId: string, courierId?: string }>

export const API_REMOVE_SELLER_INFO = 'API_REMOVE_SELLER_INFO'
export type ApiRemoveSellerInfo = A<typeof API_REMOVE_SELLER_INFO, { id: string }>

export const CREATE_SELLER_INFO = 'CREATE_SELLER_INFO'
export type CreateSellerInfo = A<typeof CREATE_SELLER_INFO, TClient.ISellerInfo>

export const API_SELLER_INFO_ERROR = 'API_SELLER_INFO_ERROR'
export type ApiSellerInfoError = A<typeof API_SELLER_INFO_ERROR, { error?: string }>

export const DROP_SELLER_INFO_ERROR = 'DROP_SELLER_INFO_ERROR'
export type DropSellerInfoError = AE<typeof DROP_SELLER_INFO_ERROR>

export const UPDATE_SELLER_INFO = 'UPDATE_SELLER_INFO'
export type UpdateSellerInfo = A<typeof UPDATE_SELLER_INFO, TClient.ISellerInfo>

export const REMOVE_SELLER_INFO = 'REMOVE_SELLER_INFO'
export type RemoveSellerInfo = A<typeof REMOVE_SELLER_INFO, { id: string }>

export const API_UPLOAD_FILE = 'API_UPLOAD_FILE'
export type ApiUploadFile = A<typeof API_UPLOAD_FILE, { file: File, type: TClient.EFileType, id?: string }>

export const SELLER_FILE = 'SELLER_FILE'
export type SellerFile = A<typeof SELLER_FILE, { id?: string, fileUrl: string, type: TClient.EFileType }>

export const API_SELLER_REGISTER = 'API_SELLER_REGISTER'
export type ApiSellerRegister = A<typeof API_SELLER_REGISTER, TApi.ApiSellerRegisterReq>

export const API_SELLERS_MARKETS = 'API_SELLERS_MARKETS'
export type ApiSellersMarkets = A<typeof API_SELLERS_MARKETS, TApi.ApiSellersMarketsReq>

export const SELLERS_MARKETS = 'SELLERS_MARKETS'
export type SellersMarkets = A<typeof SELLERS_MARKETS, { markets: TClient.IMarketInfo[] }>

export const API_SELLERS_LIST = 'API_SELLERS_LIST'
export type ApiSellersList = A<typeof API_SELLERS_LIST, TApi.ApiSellersListReq>

export const SELLERS_LIST = 'SELLERS_LIST'
export type SellersList = A<typeof SELLERS_LIST, { sellers: TClient.ISeller[], isSystem?: boolean, force?: boolean }>

export const RESET_SELLERS_LIST = 'RESET_SELLERS_LIST'
export type ResetSellersList = AE<typeof RESET_SELLERS_LIST>

export const SELLERS_CATEGORIES = 'SELLERS_CATEGORIES'
export type SellersCategories = A<typeof SELLERS_CATEGORIES, { categories: TClient.ISellerCategory[] }>

export const API_SELLERS_SEARCH = 'API_SELLERS_SEARCH'
export type ApiSellersSearch = A<typeof API_SELLERS_SEARCH, TApi.ApiSellersSearchReq>

export const SELLERS_SEARCH = 'SELLERS_SEARCH'
export type SellersSearch = A<typeof SELLERS_SEARCH, { sellers: TClient.ISellerSearch[] }>

export const API_SELLER_PRODUCTS = 'API_SELLER_PRODUCTS'
export type ApiSellerProducts = A<typeof API_SELLER_PRODUCTS, TApi.ApiSellerProductsReq>

export const SELLER_PRODUCTS = 'SELLER_PRODUCTS'
export type SellerProducts = A<typeof SELLER_PRODUCTS, { products: TClient.ISellerProduct[], force?: boolean }>

export const API_MARKET_PRODUCTS = 'API_MARKET_PRODUCTS'
export type ApiMarketProducts = A<typeof API_MARKET_PRODUCTS, TApi.ApiMarketProductsReq>

export const MARKET_PRODUCTS = 'MARKET_PRODUCTS'
export type MarketProducts = A<typeof MARKET_PRODUCTS, { products: TClient.IProduct[], force?: boolean }>

export const MARKET_PRODUCT = 'MARKET_PRODUCT'
export type MarketProduct = A<typeof MARKET_PRODUCT, { product: { id: string } & Partial<TClient.IProduct> }>

export const API_COPY_PRODUCTS = 'API_COPY_PRODUCTS'
export type ApiCopyProducts = A<typeof API_COPY_PRODUCTS, TApi.ApiCopyProductsReq>

export const COPY_PRODUCTS_START = 'COPY_PRODUCTS_START'
export type CopyProductsStart = A<typeof COPY_PRODUCTS_START, { tkn: string }>

export const API_COPY_PRODUCTS_STATUS = 'API_COPY_PRODUCTS_STATUS'
export type ApiCopyProductsStatus = A<typeof API_COPY_PRODUCTS_STATUS, TApi.ApiCopyProductsStatusReq>

export const COPY_PRODUCTS_STATUS_SUCCESS = 'COPY_PRODUCTS_STATUS_SUCCESS'
export type CopyProductsStatusSuccess = A<
  typeof COPY_PRODUCTS_STATUS_SUCCESS,
  {
    status: TClient.CopyProductsStatus,
    success: boolean,
  }
>

export const UPDATE_SAVED_SELLER_PROFILE = 'UPDATE_SAVED_SELLER_PROFILE'
export type UpdateSavedSellerProfile = A<typeof UPDATE_SAVED_SELLER_PROFILE, TClient.ISavedSellerProfile>

export const DROP_SAVED_SELLER_PROFILE = 'DROP_SAVED_SELLER_PROFILE'
export type DropSavedSellerProfile = AE<typeof DROP_SAVED_SELLER_PROFILE>

export const API_UPLOAD_IMAGE = 'API_UPLOAD_IMAGE'
export type ApiUploadImage = A<typeof API_UPLOAD_IMAGE, { file: File, type: TClient.EImageType }>

export const API_UPLOAD_IMAGE_ERROR = 'API_UPLOAD_IMAGE_ERROR'
export type ApiUploadImageError = A<typeof API_UPLOAD_IMAGE_ERROR, { type: TClient.EImageType }>

export const API_UPDATE_SELLER = 'API_UPDATE_SELLER'
export type ApiUpdateSeller = A<typeof API_UPDATE_SELLER, TApi.ApiUpdateSellerReq>

export const API_UPDATE_SELLER_ERROR = 'API_UPDATE_SELLER_ERROR'
export type ApiUpdateSellerError = A<typeof API_UPDATE_SELLER_ERROR, { error?: string }>

export const UPDATE_SELLER = 'UPDATE_SELLER'
export type UpdateSeller = A<typeof UPDATE_SELLER, { id: string } & Partial<TClient.ISeller>>

export const API_PRODUCT_COUNTRIES = 'API_PRODUCT_COUNTRIES'
export type ApiProductCountries = AE<typeof API_PRODUCT_COUNTRIES>

export const PRODUCT_COUNTRIES = 'PRODUCT_COUNTRIES'
export type ProductCountries = A<typeof PRODUCT_COUNTRIES, { countries: TClient.ICountry[] }>

export const API_PRODUCT_CATEGORIES = 'API_PRODUCT_CATEGORIES'
export type ApiProductCategories = AE<typeof API_PRODUCT_CATEGORIES>

export const PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES'
export type ProductCategories = A<typeof PRODUCT_CATEGORIES, { categories: TClient.ICategory[] }>

export const API_PRODUCT_STEPS = 'API_PRODUCT_STEPS'
export type ApiProductSteps = AE<typeof API_PRODUCT_STEPS>

export const PRODUCT_STEPS = 'PRODUCT_STEPS'
export type ProductSteps = A<typeof PRODUCT_STEPS, { steps: TClient.IStep[] }>

export const API_PRODUCT_UNITS = 'API_PRODUCT_UNITS'
export type ApiProductUnits = AE<typeof API_PRODUCT_UNITS>

export const PRODUCT_UNITS = 'PRODUCT_UNITS'
export type ProductUnits = A<typeof PRODUCT_UNITS, { units: TClient.IUnit[] }>

export const API_PRODUCT_ERROR = 'API_PRODUCT_ERROR'
export type ApiProductError = A<typeof API_PRODUCT_ERROR, { error: string }>

export const DROP_PRODUCT_ERROR = 'DROP_PRODUCT_ERROR'
export type DropProductError = AE<typeof DROP_PRODUCT_ERROR>

export const PRODUCT_SAVED_IMAGE = 'PRODUCT_SAVED_IMAGE'
export type ProductSavedImage = A<typeof PRODUCT_SAVED_IMAGE, { imageUrl: string }>

export const DROP_PRODUCT_SAVED = 'DROP_PRODUCT_SAVED'
export type DropProductSaved = AE<typeof DROP_PRODUCT_SAVED>

export const API_PRODUCT_CREATE = 'API_PRODUCT_CREATE'
export type ApiProductCreate = A<typeof API_PRODUCT_CREATE, TApi.ApiProductCreateReq>

export const PRODUCT_CREATE = 'PRODUCT_CREATE'
export type ProductCreate = A<typeof PRODUCT_CREATE, TClient.ISellerProduct>

export const API_PRODUCT_UPDATE = 'API_PRODUCT_UPDATE'
export type ApiProductUpdate = A<typeof API_PRODUCT_UPDATE, TApi.ApiProductUpdateReq>

export const PRODUCT_UPDATE = 'PRODUCT_UPDATE'
export type ProductUpdate = A<typeof PRODUCT_UPDATE, { id: string } & Partial<TClient.ISellerProduct>>

export const API_PRODUCT_DELETE = 'API_PRODUCT_DELETE'
export type ApiProductDelete = A<typeof API_PRODUCT_DELETE, TApi.ApiProductDeleteReq>

export const PRODUCT_DELETE = 'PRODUCT_DELETE'
export type ProductDelete = A<typeof PRODUCT_DELETE, { id: string }>

export const API_PRODUCT_COPY = 'API_PRODUCT_COPY'
export type ApiProductCopy = A<typeof API_PRODUCT_COPY, TApi.ApiProductCopyReq>

export const API_PRODUCTS_UPDATE_RATING = 'API_PRODUCTS_UPDATE_RATING'
export type ApiProductsUpdateRating = A<typeof API_PRODUCTS_UPDATE_RATING, TApi.ApiProductsUpdateRating>

export const ORDER_CHANGE_STATUS = 'ORDER_CHANGE_STATUS'
export type OrderChangeStatus = A<typeof ORDER_CHANGE_STATUS, { orderId: string, status: EOrderStatus }>

export const ORDER_CHANGE_STATUS_SUCCESS = 'ORDER_CHANGE_STATUS_SUCCESS'
export type OrderChangeStatusSuccess = AE<typeof ORDER_CHANGE_STATUS_SUCCESS>

export const ORDER_CHANGE_PRODUCT = 'ORDER_CHANGE_PRODUCT'
export type OrderChangeProduct = A<typeof ORDER_CHANGE_PRODUCT, { orderId: string, itemId: string, quantity: number }>

export const ORDER_CHANGE_PRODUCT_SUCCESS = 'ORDER_CHANGE_PRODUCT_SUCCESS'
export type OrderChangeProductSuccess = A<typeof ORDER_CHANGE_PRODUCT_SUCCESS, IOrder>

export const API_COURIERS_LIST = 'API_COURIERS_LIST'
export type ApiCouriersList = A<typeof API_COURIERS_LIST, TApi.ApiCouriersListReq>

export const COURIERS_LIST = 'COURIERS_LIST'
export type CouriersList = A<typeof COURIERS_LIST, { couriers: TClient.ICourier[] }>

export const API_COURIERS_LOCATIONS = 'API_COURIERS_LOCATIONS'
export type ApiCouriersLocations = A<typeof API_COURIERS_LOCATIONS, TApi.ApiCouriersLocationsReq>

export const COURIERS_LOCATIONS = 'COURIERS_LOCATIONS'
export type CouriersLocations = A<typeof COURIERS_LOCATIONS, { couriersLocations: TClient.ICourierLocation[] }>

export const API_NOTIFICATION_CREATE = 'API_NOTIFICATION_CREATE'
export type ApiNotificationCreate = A<typeof API_NOTIFICATION_CREATE, TApi.ApiNotificationCreateReq>

export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE'
export type NotificationCreate = A<typeof NOTIFICATION_CREATE, TClient.INotification>

export const API_NOTIFICATION_UPDATE = 'API_NOTIFICATION_UPDATE'
export type ApiNotificationUpdate = A<typeof API_NOTIFICATION_UPDATE, TApi.ApiNotificationUpdateReq>

export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE'
export type NotificationUpdate = A<typeof NOTIFICATION_UPDATE, { id: string } & Partial<TClient.INotification>>

export const API_NOTIFICATION_REMOVE = 'API_NOTIFICATION_REMOVE'
export type ApiNotificationRemove = A<typeof API_NOTIFICATION_REMOVE, TApi.ApiNotificationRemoveReq>

export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE'
export type NotificationRemove = A<typeof NOTIFICATION_REMOVE, { id: string, type: TClient.ENotificationType }>

export const API_NOTIFICATIONS_LIST = 'API_NOTIFICATIONS_LIST'
export type ApiNotificationsList = A<typeof API_NOTIFICATIONS_LIST, TApi.ApiNotificationsListReq>

export const NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST'
export type NotificationsList = A<typeof NOTIFICATIONS_LIST, { notifications: TClient.INotification[] }>

export const SELLER_UPDATE_IS_HIDE = 'SELLER_UPDATE_IS_HIDE'
export type SellerUpdateIsHide = A<typeof SELLER_UPDATE_IS_HIDE, TApi.UpdateIsHideReq>

export const NOTIFICATIONS_RESET_LOADING = 'NOTIFICATIONS_RESET_LOADING'
export type NotificationsResetLoading = AE<typeof NOTIFICATIONS_RESET_LOADING>

export const SELLER_UPDATE_IS_HIDE_SUCCESS = 'SELLER_UPDATE_IS_HIDE_SUCCESS'
export type SellerUpdateIsHideSuccess = A<typeof SELLER_UPDATE_IS_HIDE_SUCCESS, TApi.UpdateIsHideReq>

export const API_REPORT_ORDERS = 'API_REPORT_ORDERS'
export type ApiReportOrders = A<typeof API_REPORT_ORDERS, TClient.IOrdersFilter & { type: TClient.EReportFormat }>

export const API_REPORT_ORDERS_CUSTOMERS = 'API_REPORT_ORDERS_CUSTOMERS'
export type ApiReportOrdersCustomers = A<
  typeof API_REPORT_ORDERS_CUSTOMERS,
  TClient.IOrdersFilter & { type: TClient.EReportFormat }
>

export const API_REPORT_PRODUCTS = 'API_REPORT_PRODUCTS'
export type ApiReportProducts = A<typeof API_REPORT_PRODUCTS, TClient.IProductsFilter & { type: TClient.EReportFormat }>

export const UPDATE_LOADING_REPORT_ORDERS = 'UPDATE_LOADING_REPORT_ORDERS'
export type UpdateLoadingReportOrders = A<typeof UPDATE_LOADING_REPORT_ORDERS, boolean>

export const UPDATE_LOADING_REPORT_PRODUCTS = 'UPDATE_LOADING_REPORT_PRODUCTS'
export type UpdateLoadingReportProducts = A<typeof UPDATE_LOADING_REPORT_PRODUCTS, boolean>

export const API_NOTIFICATIONS_LOG = 'API_NOTIFICATIONS_LOG'
export type ApiNotificationsLog = A<typeof API_NOTIFICATIONS_LOG, TApi.ApiNotificationsLogReq>

export const NOTIFICATIONS_LOG = 'NOTIFICATIONS_LOG '
export type NotificationsLog = A<typeof NOTIFICATIONS_LOG, { notifications: TClient.INotificationInfo[] }>

export const API_NOTIFICATIONS_LOG_ERROR = 'API_NOTIFICATIONS_LOG_ERROR'
export type ApiNotificationsLogError = AE<typeof API_NOTIFICATIONS_LOG_ERROR>

export const API_CLIENTS = 'API_CLIENTS'
export type ApiClients = A<typeof API_CLIENTS, { phone: string }>

export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS'
export type ClientsSuccess = A<typeof CLIENTS_SUCCESS, IClientProfile[]>

export const CLIENTS_ERROR = 'CLIENTS_ERROR'
export type ClientsError = AE<typeof CLIENTS_ERROR>

export const CLIENTS_RESET = 'CLIENTS_RESET'
export type ClientsReset = AE<typeof CLIENTS_RESET>

export const TAG_SAVED_IMAGE = 'TAG_SAVED_IMAGE'
export type TagSavedImage = A<typeof TAG_SAVED_IMAGE, { imageUrl: string }>

export const DROP_TAG_SAVED = 'DROP_TAG_SAVED'
export type DropTagSaved = AE<typeof DROP_TAG_SAVED>

export const API_GET_TAGS = 'API_GET_TAGS'
export type ApiGetTags = A<typeof API_GET_TAGS, ApiGetTagsReq>

export const GET_TAGS = 'GET_TAGS'
export type GetTags = A<typeof GET_TAGS, ITag[]>

export const API_CREATE_TAG = 'API_CREATE_TAG'
export type ApiCreateTag = A<typeof API_CREATE_TAG, TCreateTag>

export const CREATE_TAG = 'CREATE_TAG'
export type CreateTag = A<typeof CREATE_TAG, ITag>

export const API_UPDATE_TAG = 'API_UPDATE_TAG'
export type ApiUpdateTag = A<typeof API_UPDATE_TAG, TUpdateTag>

export const UPDATE_TAG = 'UPDATE_TAG'
export type UpdateTag = A<typeof UPDATE_TAG, ITag>

export const API_TAG_PRODUCTS = 'API_TAG_PRODUCTS'
export type ApiTagProducts = A<typeof API_TAG_PRODUCTS, TApi.TagProductsReq>

export const API_REMOVE_TAG = 'API_REMOVE_TAG'
export type ApiRemoveTag = A<typeof API_REMOVE_TAG, number>

export const REMOVE_TAG = 'REMOVE_TAG'
export type RemoveTag = A<typeof REMOVE_TAG, number>

export const API_ORDER_ASSIGN = 'API_ORDER_ASSIGN'
export type ApiOrderAssign = A<typeof API_ORDER_ASSIGN, TApi.AssignOrderReq>

export const ORDER_ASSIGN = 'ORDER_ASSIGN'
export type OrderAssign = AE<typeof ORDER_ASSIGN>

export const ORDER_ASSIGN_ERROR = 'ORDER_ASSIGN_ERROR'
export type OrderAssignError = AE<typeof ORDER_ASSIGN_ERROR>

export const ORDER_PRE_ADD_PRODUCT = 'ORDER_PRE_ADD_PRODUCT'
export type OrderPreAddProduct = A<typeof ORDER_PRE_ADD_PRODUCT, { product: TClient.IProductOrder }>

export const ORDER_REMOVE_PRE_ADD_PRODUCT = 'ORDER_REMOVE_PRE_ADD_PRODUCT'
export type OrderRemovePreAddProduct = A<typeof ORDER_REMOVE_PRE_ADD_PRODUCT, { id: string }>

export const ORDER_DROP_PRE_ADD_PRODUCTS = 'ORDER_DROP_PRE_ADD_PRODUCTS'
export type OrderDropPreAddProducts = AE<typeof ORDER_DROP_PRE_ADD_PRODUCTS>

export const APP_GET_SLOTS = 'APP_GET_SLOTS'
export type AppGetSlots = A<typeof APP_GET_SLOTS, IClientSlotsReq>

export const APP_GET_SLOTS_SUCCESS = 'APP_GET_SLOTS_SUCCESS'
export type AppGetSlotsSuccess = A<typeof APP_GET_SLOTS_SUCCESS, IClientSlotsResp>

export const APP_GET_SLOTS_ERROR = 'APP_GET_SLOTS_ERROR'
export type AppGetSlotsError = AE<typeof APP_GET_SLOTS_ERROR>

export const API_SLOTS = 'API_SLOTS'
export type ApiSlots = A<typeof API_SLOTS, { market: string }>

export const SLOTS = 'SLOTS'
export type Slots = A<typeof SLOTS, ISlot[]>

export const API_UPDATE_SLOTS = 'API_UPDATE_SLOTS'
export type ApiUpdateSlots = A<typeof API_UPDATE_SLOTS, { slots: ISlot[], market: string, updateInfo?: boolean }>

export const UPDATE_SLOTS = 'UPDATE_SLOTS'
export type UpdateSlots = A<typeof UPDATE_SLOTS, ISlot[]>

export const API_SLOTS_INFO = 'API_SLOTS_INFO'
export type ApiSlotsInfo = A<typeof API_SLOTS_INFO, { market: string }>

export const SLOTS_INFO = 'SLOTS_INFO'
export type SlotsInfo = A<typeof SLOTS_INFO, ISlotInfo[]>

export const UPDATE_SLOTS_ERROR_MODAL = 'UPDATE_SLOTS_ERROR_MODAL'
export type UpdateSlotsErrorModal = A<typeof UPDATE_SLOTS_ERROR_MODAL, boolean>

export const API_COURIER_SHIFTS = 'API_COURIER_SHIFTS'
export type ApiCourierShifts = A<typeof API_COURIER_SHIFTS, ApiCourierShiftsReq>

export const API_UPDATE_COURIER_SHIFTS = 'API_UPDATE_COURIER_SHIFTS'
export type ApiUpdateCourierShifts = A<typeof API_UPDATE_COURIER_SHIFTS, ApiUpdateCourierShiftsReq>

export const COURIER_UPDATE_SHIFTS = 'COURIER_UPDATE_SHIFTS'
export type CourierUpdateShift = A<
  typeof COURIER_UPDATE_SHIFTS,
  {
    courierId: string,
    date: string,
    shiftSlots: IShiftSlot[],
  }
>

export const API_REPORT_COURIER_SHIFTS = 'API_REPORT_COURIER_SHIFTS'
export type ApiReportCourierShifts = A<
  typeof API_REPORT_COURIER_SHIFTS,
  TClient.ICourierShiftsFilter & { type: TClient.EReportFormat }
>

export const UPDATE_LOADING_REPORT_COURIER_SHIFTS = 'UPDATE_LOADING_REPORT_COURIER_SHIFTS'
export type UpdateLoadingReportCourierShifts = A<typeof UPDATE_LOADING_REPORT_COURIER_SHIFTS, boolean>

export const API_CUSTOMER_REPORT = 'API_CUSTOMER_REPORT'
export type ApiCustomerReport = A<typeof API_CUSTOMER_REPORT, ICustomersFilter>

export const UPDATE_LOADING_REPORT_CUSTOMERS = 'UPDATE_LOADING_REPORT_CUSTOMERS'
export type UpdateLoadingReportCustomers = A<typeof UPDATE_LOADING_REPORT_CUSTOMERS, boolean>

export const API_MARKET_ROUTES = 'API_MARKET_ROUTES'
export type ApiMarketRoutes = A<typeof API_MARKET_ROUTES, TApi.ApiMarketRoutesReq>

export const MARKET_ROUTES = 'MARKET_ROUTES'
export type MarketRoutes = A<typeof MARKET_ROUTES, { market: string, routes: TClient.IRoute[] }>

export const API_CREATE_MARKET_ROUTE = 'API_CREATE_MARKET_ROUTE'
export type ApiCreateMarketRoute = A<typeof API_CREATE_MARKET_ROUTE, TApi.ApiCreateMarketRouteReq>

export const API_UPDATE_MARKET_ROUTE = 'API_UPDATE_MARKET_ROUTE'
export type ApiUpdateMarketRoute = A<typeof API_UPDATE_MARKET_ROUTE, TApi.ApiUpdateMarketRouteReq>

export const API_REMOVE_MARKET_ROUTE = 'API_REMOVE_MARKET_ROUTE'
export type ApiRemoveMarketRoute = A<typeof API_REMOVE_MARKET_ROUTE, TApi.ApiRemoveMarketRouteReq>

export const API_ORDER_HISTORY = 'API_ORDER_HISTORY'
export type ApiOrderHistory = A<typeof API_ORDER_HISTORY, TApi.ApiOrderHistoryReq>

export const ORDER_HISTORY = 'ORDER_HISTORY'
export type OrderHistory = A<typeof ORDER_HISTORY, TApi.ApiOrderHistoryData>

export const API_ORDER_HISTORY_ERROR = 'API_ORDER_HISTORY_ERROR'
export type ApiOrderHistoryError = AE<typeof API_ORDER_HISTORY_ERROR>

export type Action =
  | StopEpic
  | AppLoaded
  | ModalPush
  | ModalPop
  | ModalClear
  | AlertPush
  | AlertPop
  | AlertClear
  | ApiLogin
  | ApiSignUp
  | User
  | UserLoginError
  | UserSignUpError
  | DropApiError
  | ApiUserInfo
  | Logout
  | ApiOrdersList
  | OrdersList
  | ApiOrdersMapList
  | OrdersMapList
  | ApiUpdateOrder
  | UpdateOrder
  | UpdateOrderError
  | ApiAddProductsOrder
  | OrdersFilter
  | ApiOrderAssign
  | OrderAssign
  | OrderAssignError
  | OrderPreAddProduct
  | OrderRemovePreAddProduct
  | OrderDropPreAddProducts
  | ApiCities
  | Cities
  | ApiMarkets
  | Markets
  | MarketDeliveryAreaAdd
  | MarketDeliveryAreaUpdate
  | MarketDeliveryAreaRemove
  | MarketDeliveryAreas
  | ApiSaveDeliveryAreas
  | ApiAddDeliveryArea
  | ApiUpdateDeliveryArea
  | ApiRemoveDeliveryArea
  | ApiMarketCategories
  | MarketCategories
  | ApiCreateMarketCategory
  | ApiUpdateMarketCategory
  | ApiRemoveMarketCategory
  | ApiCreateMarketSubcategory
  | ApiUpdateMarketSubcategory
  | ApiRemoveMarketSubcategory
  | MarketCategoryCreate
  | MarketCategoryUpdate
  | MarketCategoryRemove
  | MarketCategorySavedImage
  | ApiMarketRoutes
  | MarketRoutes
  | ApiCreateMarketRoute
  | ApiUpdateMarketRoute
  | ApiRemoveMarketRoute
  | ApiSellersInfoList
  | SellersInfoList
  | ApiPartnerInfo
  | PartnerInfo
  | ApiBankInfo
  | BankInfo
  | ApiCreateSellerInfo
  | CreateSellerInfo
  | ApiSellerInfoError
  | DropSellerInfoError
  | ApiUpdateSellerInfo
  | UpdateSellerInfo
  | ApiRemoveSellerInfo
  | RemoveSellerInfo
  | ApiUploadFile
  | SellerFile
  | ApiSellerRegister
  | SellersDropLoading
  | ApiSellersMarkets
  | SellersMarkets
  | ApiSellersList
  | SellersList
  | ResetSellersList
  | SellersCategories
  | ApiSellersSearch
  | SellersSearch
  | ApiSellerProducts
  | SellerProducts
  | ApiMarketProducts
  | MarketProducts
  | MarketProduct
  | UpdateSavedSellerProfile
  | DropSavedSellerProfile
  | ApiUploadImage
  | ApiUploadImageError
  | ApiUpdateSeller
  | ApiUpdateSellerError
  | UpdateSeller
  | SellerUpdateIsHide
  | SellerUpdateIsHideSuccess
  | ApiCopyProducts
  | CopyProductsStart
  | ApiCopyProductsStatus
  | CopyProductsStatusSuccess
  | ApiProductCountries
  | ProductCountries
  | ApiProductCategories
  | ProductCategories
  | ApiProductSteps
  | ProductSteps
  | ApiProductUnits
  | ProductUnits
  | ApiProductError
  | ProductSavedImage
  | DropProductError
  | DropProductSaved
  | ApiProductCreate
  | ProductCreate
  | ApiProductUpdate
  | ProductUpdate
  | ApiProductDelete
  | ProductDelete
  | ApiProductCopy
  | ApiProductsUpdateRating
  | OrderChangeStatus
  | OrderChangeStatusSuccess
  | OrderChangeProduct
  | OrderChangeProductSuccess
  | ApiCustomersList
  | CustomersList
  | CustomersFilter
  | ApiProductsModerationList
  | ProductsModerationList
  | ApiUpdateModerationProduct
  | UpdateModerationProduct
  | ModerationProductsFilter
  | ApiCustomerAddresses
  | CustomerAddresses
  | ApiCustomerOrders
  | CustomerOrders
  | ApiCustomerReviews
  | CustomerReviews
  | ApiCouriersList
  | CouriersList
  | ApiCouriersLocations
  | CouriersLocations
  | ApiNotificationCreate
  | NotificationCreate
  | ApiNotificationUpdate
  | NotificationUpdate
  | ApiNotificationRemove
  | NotificationRemove
  | ApiNotificationsList
  | NotificationsList
  | NotificationsResetLoading
  | NotificationsLog
  | ApiNotificationsLog
  | ApiNotificationsLogError
  | ApiReportOrders
  | ApiReportOrdersCustomers
  | ApiReportProducts
  | UpdateLoadingReportOrders
  | UpdateLoadingReportProducts
  | ApiOrdersByMarkets
  | ApiTotalOrders
  | TotalOrders
  | ApiCustomers
  | NewCustomers
  | ApiClients
  | ClientsSuccess
  | ClientsError
  | ClientsReset
  | TagSavedImage
  | DropTagSaved
  | ApiGetTags
  | GetTags
  | ApiCreateTag
  | CreateTag
  | ApiUpdateTag
  | UpdateTag
  | ApiTagProducts
  | ApiRemoveTag
  | RemoveTag
  | ApiSlots
  | Slots
  | ApiUpdateSlots
  | UpdateSlots
  | UpdateSlotsErrorModal
  | AppGetSlots
  | AppGetSlotsSuccess
  | AppGetSlotsError
  | ApiSlotsInfo
  | SlotsInfo
  | ApiCourierShifts
  | ApiUpdateCourierShifts
  | CourierUpdateShift
  | ApiReportCourierShifts
  | UpdateLoadingReportCourierShifts
  | ApiCustomerReport
  | UpdateLoadingReportCustomers
  | SetInterfaceLang
  | ApiGroupsList
  | ApiCreateGroup
  | ApiUpdateGroup
  | ApiRemoveGroup
  | GroupsList
  | CreateGroupProduct
  | SetGroupProducts
  | UpdateGroupProduct
  | RemoveGroupProduct
  | ApiSellerCourier
  | UpdateCourierSellerSuccess
  | ApiOrderHistory
  | OrderHistory
  | ApiOrderHistoryError
  | ApiCustomerUpdate
  | CustomerUpdateSuccess
  | CustomerUpdateError
  | CustomerUpdate

export const actionEmpty = <T>(type: T) => ({ type })
export const action = <T, D>(type: T, data: D) => ({ type, data })
