import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const DownloadIcon: React.FC<TProps> = ({ color, width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='M20.604 14.56V20c0 .56-.44 1-1 1h-15.2c-.56 0-1-.44-1-1v-5.44c0-.56.44-1 1-1s1 .44 1 1V19h13.2v-4.44c0-.56.44-1 1-1s1 .44 1 1Zm-9.32 1.24c.18.2.44.3.72.3.28 0 .52-.1.72-.3l3.98-4.08c.38-.4.38-1.02-.02-1.42-.4-.38-1.02-.38-1.42.02l-2.28 2.32V4c0-.56-.44-1-1-1s-1 .44-1 1v8.66l-2.28-2.32c-.38-.4-1.02-.4-1.42-.02-.4.38-.4 1.02-.02 1.42l4.02 4.06Z'
      />
    </svg>
  )
}
