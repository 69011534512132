import { Reducer } from 'redux'

import {
  IProductOrder,
  ISeller,
  ISellerProduct,
  INotification,
  EPaymentType,
  IOrder,
  ITag,
  EOrdersFilter,
  IProduct,
  IModerationProduct,
  ICourier,
} from '../../types/TClient'

import * as Actions from '../actions'

export enum EModalType {
  MODAL_IMAGE_CROPPER = 'IMAGE_CROPPER',
  MODAL_EDIT_SELLER = 'EDIT_SELLER',
  MODAL_PRODUCT = 'PRODUCT',
  MODAL_PRODUCT_CHANGE = 'PRODUCT_CHANGE',
  MODAL_DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
  MODAL_ORDERS_FILTER = 'ORDERS_FILTER',
  MODAL_DELIVERY_CHANGE = 'ORDERS_DELIVERY_CHANGE',
  MODAL_EDIT_CREATE_TAG = 'MODAL_EDIT_CREATE_TAG',
  MODAL_EDIT_PRODUCT_TAG = 'MODAL_EDIT_PRODUCT_TAG',
  MODAL_ASSIGN_ORDER = 'MODAL_ASSIGN_ORDER',
  MODAL_ADD_PRODUCT_ORDER = 'MODAL_ADD_PRODUCT_ORDER',
  MODAL_ORDER = 'MODAL_ORDER',
  MODAL_CUSTOMER = 'MODAL_CUSTOMER',
  MODAL_CUSTOMER_CALL_ME = 'MODAL_CUSTOMER_CALL_ME',
  MODAL_CUSTOMERS_FILTER = 'MODAL_CUSTOMERS_FILTER',
  MODAL_REJECT_PRODUCT = 'MODAL_REJECT_PRODUCT',
  MODAL_COURIER_SHIFT = 'MODAL_COURIER_SHIFT',
}

export interface IModalCommon {
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen',
  basic?: boolean,
  close?: boolean,
  style?: React.CSSProperties,
}

export interface IModalImageCropper extends IModalCommon {
  type: EModalType.MODAL_IMAGE_CROPPER,
  props: {
    title: string,
    image?: File,
    imageUrl?: string,
    aspectRatio: number,
    uploadImage(file: File): Actions.Action,
  },
}

export interface IModalEditSeller extends IModalCommon {
  type: EModalType.MODAL_EDIT_SELLER,
  props: {
    title: string,
    seller: ISeller,
  },
}

export interface IModalAssignOrder extends IModalCommon {
  type: EModalType.MODAL_ASSIGN_ORDER,
  props: {
    order: IOrder,
  },
}

export interface IModalEditProductTag extends IModalCommon {
  type: EModalType.MODAL_EDIT_PRODUCT_TAG,
  props: {
    title: string,
    product: ISellerProduct,
  },
}

export interface IModalEditCreateTag extends IModalCommon {
  type: EModalType.MODAL_EDIT_CREATE_TAG,
  props: {
    market: string,
    tag?: ITag,
  },
}

export interface IModalProduct extends IModalCommon {
  type: EModalType.MODAL_PRODUCT,
  props: {
    title: string,
    marketId: string,
    product: ISellerProduct | null,
    seller?: ISeller,
    marketProduct?: IProduct,
  },
}

export interface IModalProductChange extends IModalCommon {
  type: EModalType.MODAL_PRODUCT_CHANGE,
  props: {
    title: string,
    orderId: string,
    paymentType: EPaymentType,
    product: IProductOrder,
  },
}
export interface IModalDeliveryChange extends IModalCommon {
  type: EModalType.MODAL_DELIVERY_CHANGE,
  props: {
    title: string,
    order: IOrder,
  },
}

export interface IModalDeleteNotification extends IModalCommon {
  type: EModalType.MODAL_DELETE_NOTIFICATION,
  props: {
    title: string,
    notification: INotification,
  },
}

export interface IModalOrdersFilter extends IModalCommon {
  type: EModalType.MODAL_ORDERS_FILTER,
  props: {
    type: EOrdersFilter,
    title: string,
  },
}

export interface IModalAddProductOrder extends IModalCommon {
  type: EModalType.MODAL_ADD_PRODUCT_ORDER,
  props: {
    orderId: string,
    marketId: string,
  },
}

export interface IModalOrder extends IModalCommon {
  type: EModalType.MODAL_ORDER,
  props: {
    order: IOrder,
    marketId: string,
  },
}

export interface IModalCustomer extends IModalCommon {
  type: EModalType.MODAL_CUSTOMER,
  props: {
    userId: number,
    marketId: string,
  },
}

export interface IModalCustomerCallMe extends IModalCommon {
  type: EModalType.MODAL_CUSTOMER_CALL_ME,
  props: {
    userId: number,
    customerCallMe: {
      callMe?: boolean,
      aboutChangeProduct?: boolean,
      aboutDeleteProduct?: boolean,
      aboutChangeProductWeight?: boolean,
      changeProduct?: boolean,
      deleteProductByWeight?: boolean,
    },
  },
}

export interface IModalCustomersFilter extends IModalCommon {
  type: EModalType.MODAL_CUSTOMERS_FILTER,
  props: {
    title: string,
  },
}

export interface IModalRejectProduct extends IModalCommon {
  type: EModalType.MODAL_REJECT_PRODUCT,
  props: {
    product: IModerationProduct,
  },
}

export interface IModalCourierShift extends IModalCommon {
  type: EModalType.MODAL_COURIER_SHIFT,
  props: {
    courier: ICourier,
    index: number,
    shiftDate: string,
  },
}

export type IModal =
  | IModalImageCropper
  | IModalEditSeller
  | IModalProduct
  | IModalProductChange
  | IModalDeleteNotification
  | IModalOrdersFilter
  | IModalDeliveryChange
  | IModalEditCreateTag
  | IModalEditProductTag
  | IModalAssignOrder
  | IModalAddProductOrder
  | IModalOrder
  | IModalCustomer
  | IModalCustomerCallMe
  | IModalCustomersFilter
  | IModalRejectProduct
  | IModalCourierShift

export type MutableStateModals = {
  modals: IModal[],
}

export type StateModals = Readonly<MutableStateModals>

const defStateModals: StateModals = {
  modals: [],
}

export const modals: Reducer<StateModals, Actions.Action> = (s = defStateModals, a): StateModals => {
  switch (a.type) {
    case Actions.MODAL_PUSH: {
      const newModals = [...s.modals]
      newModals.push(a.data)

      return {
        ...s,
        modals: newModals,
      }
    }
    case Actions.MODAL_POP: {
      const newModals = [...s.modals]
      newModals.pop()

      return {
        ...s,
        modals: newModals,
      }
    }
    case Actions.MODAL_CLEAR:
      return {
        ...s,
        modals: [],
      }
  }
  return s
}
