import { COUNTRY_ID } from '../../types/countryId'

import { withPrefix } from '../../utils/countriesUtils'

const countries = withPrefix({
  [COUNTRY_ID.AFGHANISTAN]: 'Afghanistan',
  [COUNTRY_ID.ALAND_ISLANDS]: 'Aland Islands',
  [COUNTRY_ID.ALBANIA]: 'Albania',
  [COUNTRY_ID.ALGERIA]: 'Algeria',
  [COUNTRY_ID.AMERICAN_SAMOA]: 'American Samoa',
  [COUNTRY_ID.ANDORRA]: 'Andorra',
  [COUNTRY_ID.ANGOLA]: 'Angola',
  [COUNTRY_ID.ANGUILLA]: 'Anguilla',
  [COUNTRY_ID.ANTARCTICA]: 'Antarctica',
  [COUNTRY_ID.ANTIGUA_AND_BARBUDA]: 'Antigua And Barbuda',
  [COUNTRY_ID.ARGENTINA]: 'Argentina',
  [COUNTRY_ID.ARMENIA]: 'Armenia',
  [COUNTRY_ID.ARUBA]: 'Aruba',
  [COUNTRY_ID.AUSTRALIA]: 'Australia',
  [COUNTRY_ID.AUSTRIA]: 'Austria',
  [COUNTRY_ID.AZERBAIJAN]: 'Azerbaijan',
  [COUNTRY_ID.BAHAMAS]: 'Bahamas',
  [COUNTRY_ID.BAHRAIN]: 'Bahrain',
  [COUNTRY_ID.BANGLADESH]: 'Bangladesh',
  [COUNTRY_ID.BARBADOS]: 'Barbados',
  [COUNTRY_ID.BELARUS]: 'Belarus',
  [COUNTRY_ID.BELGIUM]: 'Belgium',
  [COUNTRY_ID.BELIZE]: 'Belize',
  [COUNTRY_ID.BENIN]: 'Benin',
  [COUNTRY_ID.CURACAO]: 'Curacao',
  [COUNTRY_ID.SOUTH_SUDAN]: 'South Sudan',
  [COUNTRY_ID.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: 'South Georgia and the South Sandwich Islands',
  [COUNTRY_ID.SAINT_MAARTEN]: 'Sint Maarten (Dutch part)',
  [COUNTRY_ID.BONAIRE]: 'Bonaire, Sint Eustatius and Saba',
  [COUNTRY_ID.BERMUDA]: 'Bermuda',
  [COUNTRY_ID.BHUTAN]: 'Bhutan',
  [COUNTRY_ID.BOLIVIA]: 'Bolivia',
  [COUNTRY_ID.BOSNIA_AND_HERZEGOVINA]: 'Bosnia And Herzegovina',
  [COUNTRY_ID.BOTSWANA]: 'Botswana',
  [COUNTRY_ID.BOUVET_ISLAND]: 'Bouvet Island',
  [COUNTRY_ID.BRAZIL]: 'Brazil',
  [COUNTRY_ID.BRITISH_INDIAN_OCEAN_TERRITORY]: 'British Indian Ocean Territory',
  [COUNTRY_ID.BRUNEI_DARUSSALAM]: 'Brunei Darussalam',
  [COUNTRY_ID.BULGARIA]: 'Bulgaria',
  [COUNTRY_ID.BURKINA_FASO]: 'Burkina Faso',
  [COUNTRY_ID.BURUNDI]: 'Burundi',
  [COUNTRY_ID.CAMBODIA]: 'Cambodia',
  [COUNTRY_ID.CAMEROON]: 'Cameroon',
  [COUNTRY_ID.CANADA]: 'Canada',
  [COUNTRY_ID.CAPE_VERDE]: 'Cape Verde',
  [COUNTRY_ID.CAYMAN_ISLANDS]: 'Cayman Islands',
  [COUNTRY_ID.CENTRAL_AFRICAN_REPUBLIC]: 'Central African Republic',
  [COUNTRY_ID.CHAD]: 'Chad',
  [COUNTRY_ID.CHILE]: 'Chile',
  [COUNTRY_ID.CHINA]: 'China',
  [COUNTRY_ID.CHRISTMAS_ISLAND]: 'Christmas Island',
  [COUNTRY_ID.COCOS_KEEL_ING_ISLANDS]: 'Cocos Keeling Islands',
  [COUNTRY_ID.COLOMBIA]: 'Colombia',
  [COUNTRY_ID.COMOROS]: 'Comoros',
  [COUNTRY_ID.CONGO]: 'Congo',
  [COUNTRY_ID.CONGO_DEMOCRATIC_REPUBLIC]: 'Congo Democratic Republic',
  [COUNTRY_ID.COOK_ISLANDS]: 'Cook Islands',
  [COUNTRY_ID.COSTA_RICA]: 'Costa Rica',
  [COUNTRY_ID.COTE_DIVOIRE]: 'Cote DIvoire',
  [COUNTRY_ID.CROATIA]: 'Croatia',
  [COUNTRY_ID.CUBA]: 'Cuba',
  [COUNTRY_ID.CYPRUS]: 'Cyprus',
  [COUNTRY_ID.CZECH_REPUBLIC]: 'Czech Republic',
  [COUNTRY_ID.DENMARK]: 'Denmark',
  [COUNTRY_ID.DJIBOUTI]: 'Djibouti',
  [COUNTRY_ID.DOMINICA]: 'Dominica',
  [COUNTRY_ID.DOMINICAN_REPUBLIC]: 'Dominican Republic',
  [COUNTRY_ID.ECUADOR]: 'Ecuador',
  [COUNTRY_ID.EGYPT]: 'Egypt',
  [COUNTRY_ID.EL_SALVADOR]: 'El Salvador',
  [COUNTRY_ID.EQUATORIAL_GUINEA]: 'Equatorial Guinea',
  [COUNTRY_ID.ERITREA]: 'Eritrea',
  [COUNTRY_ID.ESTONIA]: 'Estonia',
  [COUNTRY_ID.ETHIOPIA]: 'Ethiopia',
  [COUNTRY_ID.FALKLAND_ISLANDS]: 'Falkland Islands',
  [COUNTRY_ID.FAROE_ISLANDS]: 'Faroe Islands',
  [COUNTRY_ID.FIJI]: 'Fiji',
  [COUNTRY_ID.FINLAND]: 'Finland',
  [COUNTRY_ID.FRANCE]: 'France',
  [COUNTRY_ID.FRENCH_GUIANA]: 'French Guiana',
  [COUNTRY_ID.FRENCH_POLYNESIA]: 'French Polynesia',
  [COUNTRY_ID.FRENCH_SOUTHERN_TERRITORIES]: 'French Southern Territories',
  [COUNTRY_ID.GABON]: 'Gabon',
  [COUNTRY_ID.GAMBIA]: 'Gambia',
  [COUNTRY_ID.GEORGIA]: 'Georgia',
  [COUNTRY_ID.GERMANY]: 'Germany',
  [COUNTRY_ID.GHANA]: 'Ghana',
  [COUNTRY_ID.GIBRALTAR]: 'Gibraltar',
  [COUNTRY_ID.GREECE]: 'Greece',
  [COUNTRY_ID.GREENLAND]: 'Greenland',
  [COUNTRY_ID.GRENADA]: 'Grenada',
  [COUNTRY_ID.GUADELOUPE]: 'Guadeloupe',
  [COUNTRY_ID.GUAM]: 'Guam',
  [COUNTRY_ID.GUATEMALA]: 'Guatemala',
  [COUNTRY_ID.GUERNSEY]: 'Guernsey',
  [COUNTRY_ID.GUINEA]: 'Guinea',
  [COUNTRY_ID.GUINEA_BISSAU]: 'Guinea Bissau',
  [COUNTRY_ID.GUYANA]: 'Guyana',
  [COUNTRY_ID.HAITI]: 'Haiti',
  [COUNTRY_ID.HEARD_ISLAND_MCDONALD_ISLANDS]: 'Heard Island Mcdonald Islands',
  [COUNTRY_ID.HOLY_SEE_VATICAN_CITY_STATE]: 'Holy See Vatican City State',
  [COUNTRY_ID.HONDURAS]: 'Honduras',
  [COUNTRY_ID.HONG_KONG]: 'HongKong',
  [COUNTRY_ID.HUNGARY]: 'Hungary',
  [COUNTRY_ID.ICELAND]: 'Iceland',
  [COUNTRY_ID.INDIA]: 'India',
  [COUNTRY_ID.INDONESIA]: 'Indonesia',
  [COUNTRY_ID.IRAN]: 'Iran',
  [COUNTRY_ID.IRAQ]: 'Iraq',
  [COUNTRY_ID.IRELAND]: 'Ireland',
  [COUNTRY_ID.ISLEOFMAN]: 'IsleOfMan',
  [COUNTRY_ID.ISRAEL]: 'Israel',
  [COUNTRY_ID.ITALY]: 'Italy',
  [COUNTRY_ID.JAMAICA]: 'Jamaica',
  [COUNTRY_ID.JAPAN]: 'Japan',
  [COUNTRY_ID.JERSEY]: 'Jersey',
  [COUNTRY_ID.JORDAN]: 'Jordan',
  [COUNTRY_ID.KAZAKHSTAN]: 'Kazakhstan',
  [COUNTRY_ID.KENYA]: 'Kenya',
  [COUNTRY_ID.KIRIBATI]: 'Kiribati',
  [COUNTRY_ID.REPUBLIC_OF_KOREA]: 'Republic Of Korea',
  [COUNTRY_ID.DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA]: 'Democratic Peoples Republic Of Korea',
  [COUNTRY_ID.KUWAIT]: 'Kuwait',
  [COUNTRY_ID.KYRGYZSTAN]: 'Kyrgyzstan',
  [COUNTRY_ID.LAO_PEOPLES_DEMOCRATIC_REPUBLIC]: 'Lao Peoples Democratic Republic',
  [COUNTRY_ID.LATVIA]: 'Latvia',
  [COUNTRY_ID.LEBANON]: 'Lebanon',
  [COUNTRY_ID.LESOTHO]: 'Lesotho',
  [COUNTRY_ID.LIBERIA]: 'Liberia',
  [COUNTRY_ID.LIBYAN_ARAB_JAMAHIRIYA]: 'Libyan ArabJamahiriya',
  [COUNTRY_ID.LIECHTENSTEIN]: 'Liechtenstein',
  [COUNTRY_ID.LITHUANIA]: 'Lithuania',
  [COUNTRY_ID.LUXEMBOURG]: 'Luxembourg',
  [COUNTRY_ID.MACAO]: 'Macao',
  [COUNTRY_ID.MACEDONIA]: 'Macedonia',
  [COUNTRY_ID.MADAGASCAR]: 'Madagascar',
  [COUNTRY_ID.MALAWI]: 'Malawi',
  [COUNTRY_ID.MALAYSIA]: 'Malaysia',
  [COUNTRY_ID.MALDIVES]: 'Maldives',
  [COUNTRY_ID.MALI]: 'Mali',
  [COUNTRY_ID.MALTA]: 'Malta',
  [COUNTRY_ID.MARSHALL_ISLANDS]: 'Marshall Islands',
  [COUNTRY_ID.MARTINIQUE]: 'Martinique',
  [COUNTRY_ID.MAURITANIA]: 'Mauritania',
  [COUNTRY_ID.MAURITIUS]: 'Mauritius',
  [COUNTRY_ID.MAYOTTE]: 'Mayotte',
  [COUNTRY_ID.MEXICO]: 'Mexico',
  [COUNTRY_ID.MICRONESIA]: 'Micronesia',
  [COUNTRY_ID.MOLDOVA]: 'Moldova',
  [COUNTRY_ID.MONACO]: 'Monaco',
  [COUNTRY_ID.MONGOLIA]: 'Mongolia',
  [COUNTRY_ID.MONTENEGRO]: 'Montenegro',
  [COUNTRY_ID.MONTSERRAT]: 'Montserrat',
  [COUNTRY_ID.MOROCCO]: 'Morocco',
  [COUNTRY_ID.MOZAMBIQUE]: 'Mozambique',
  [COUNTRY_ID.MYANMAR]: 'Myanmar',
  [COUNTRY_ID.NAMIBIA]: 'Namibia',
  [COUNTRY_ID.NAURU]: 'Nauru',
  [COUNTRY_ID.NEPAL]: 'Nepal',
  [COUNTRY_ID.NETHERLANDS]: 'Netherlands',
  [COUNTRY_ID.NETHERLANDS_ANTILLES]: 'Netherlands Antilles',
  [COUNTRY_ID.NEW_CALEDONIA]: 'New Caledonia',
  [COUNTRY_ID.NEW_ZEALAND]: 'New Zealand',
  [COUNTRY_ID.NICARAGUA]: 'Nicaragua',
  [COUNTRY_ID.NIGER]: 'Niger',
  [COUNTRY_ID.NIGERIA]: 'Nigeria',
  [COUNTRY_ID.NIUE]: 'Niue',
  [COUNTRY_ID.NORFOLK_ISLAND]: 'Norfolk Island',
  [COUNTRY_ID.NORTHERN_MARIANA_ISLANDS]: 'Northern Mariana Islands',
  [COUNTRY_ID.NORWAY]: 'Norway',
  [COUNTRY_ID.OMAN]: 'Oman',
  [COUNTRY_ID.PAKISTAN]: 'Pakistan',
  [COUNTRY_ID.PALAU]: 'Palau',
  [COUNTRY_ID.PALESTINIAN_TERRITORY]: 'Palestinian Territory',
  [COUNTRY_ID.PANAMA]: 'Panama',
  [COUNTRY_ID.PAPUA_NEW_GUINEA]: 'Papua New Guinea',
  [COUNTRY_ID.PARAGUAY]: 'Paraguay',
  [COUNTRY_ID.PERU]: 'Peru',
  [COUNTRY_ID.PHILIPPINES]: 'Philippines',
  [COUNTRY_ID.PITCAIRN]: 'Pitcairn',
  [COUNTRY_ID.POLAND]: 'Poland',
  [COUNTRY_ID.PORTUGAL]: 'Portugal',
  [COUNTRY_ID.PUERTO_RICO]: 'PuertoRico',
  [COUNTRY_ID.QATAR]: 'Qatar',
  [COUNTRY_ID.REUNION]: 'Reunion',
  [COUNTRY_ID.ROMANIA]: 'Romania',
  [COUNTRY_ID.RUSSIA]: 'Russian Federation',
  [COUNTRY_ID.RWANDA]: 'Rwanda',
  [COUNTRY_ID.SAINT_BARTHELEMY]: 'Saint Barthelemy',
  [COUNTRY_ID.SAINTHELENA]: 'Saint Helena',
  [COUNTRY_ID.SAINT_KITTS_AND_NEVIS]: 'Saint Kitts and Nevis',
  [COUNTRY_ID.SAINT_LUCIA]: 'Saint Kitts and Nevis',
  [COUNTRY_ID.SAINT_MARTIN]: 'Saint Martin',
  [COUNTRY_ID.SAINT_PIERRE_AND_MIQUELON]: 'Saint Pierre and Miquelon',
  [COUNTRY_ID.SAINT_VINCENT_AND_GRENADINES]: 'Saint Vincent and Grenadines',
  [COUNTRY_ID.SAMOA]: 'Samoa',
  [COUNTRY_ID.SAN_MARINO]: 'San Marino',
  [COUNTRY_ID.SAO_TOME_AND_PRINCIPE]: 'Sao Tome and Principe',
  [COUNTRY_ID.SAUDI_ARABIA]: 'Saudi Arabia',
  [COUNTRY_ID.SENEGAL]: 'Senegal',
  [COUNTRY_ID.SERBIA]: 'Serbia',
  [COUNTRY_ID.SEYCHELLES]: 'Seychelles',
  [COUNTRY_ID.SIERRA_LEONE]: 'Sierra Leone',
  [COUNTRY_ID.SINGAPORE]: 'Singapore',
  [COUNTRY_ID.SLOVAKIA]: 'Slovakia',
  [COUNTRY_ID.SLOVENIA]: 'Slovenia',
  [COUNTRY_ID.SOLOMON_ISLANDS]: 'Solomon Islands',
  [COUNTRY_ID.SOMALIA]: 'Somalia',
  [COUNTRY_ID.SOUTH_AFRICA]: 'South Africa',
  [COUNTRY_ID.SOUTH_GEORGIA_AND_SANDWICH_ISL]: 'South Georgia and Sandwich isl',
  [COUNTRY_ID.SPAIN]: 'Spain',
  [COUNTRY_ID.SRI_LANKA]: 'Sri Lanka',
  [COUNTRY_ID.SUDAN]: 'Sudan',
  [COUNTRY_ID.SURINAME]: 'Suriname',
  [COUNTRY_ID.SVALBARD_AND_JAN_MAYEN]: 'Svalbard and Jan Mayen',
  [COUNTRY_ID.SWAZILAND]: 'Swaziland',
  [COUNTRY_ID.SWEDEN]: 'Sweden',
  [COUNTRY_ID.SWITZERLAND]: 'Switzerland',
  [COUNTRY_ID.SYRIAN_ARAB_REPUBLIC]: 'Syrian Arab Republic',
  [COUNTRY_ID.TAIWAN]: 'Taiwan',
  [COUNTRY_ID.TAJIKISTAN]: 'Tajikistan',
  [COUNTRY_ID.TANZANIA]: 'Tanzania',
  [COUNTRY_ID.THAILAND]: 'Thailand',
  [COUNTRY_ID.TIMOR_LESTE]: 'TimorLeste',
  [COUNTRY_ID.TOGO]: 'Togo',
  [COUNTRY_ID.TOKELAU]: 'Tokelau',
  [COUNTRY_ID.TONGA]: 'Tonga',
  [COUNTRY_ID.TRINIDAD_AND_TOBAGO]: 'Trinidad and Tobago',
  [COUNTRY_ID.TUNISIA]: 'Tunisia',
  [COUNTRY_ID.TURKEY]: 'Turkey',
  [COUNTRY_ID.TURKMENISTAN]: 'Turkmenistan',
  [COUNTRY_ID.TURKS_AND_CAICOS_ISLANDS]: 'Turks and Caicos islands',
  [COUNTRY_ID.TUVALU]: 'Tuvalu',
  [COUNTRY_ID.UGANDA]: 'Uganda',
  [COUNTRY_ID.UKRAINE]: 'Ukraine',
  [COUNTRY_ID.UNITED_ARAB_EMIRATES]: 'United Arab Emirates',
  [COUNTRY_ID.UNITED_KINGDOM]: 'United Kingdom',
  [COUNTRY_ID.UNITED_STATES]: 'United States',
  [COUNTRY_ID.UNITED_STATES_OUTLYING_ISLANDS]: 'United States Outlying Islands',
  [COUNTRY_ID.URUGUAY]: 'Uruguay',
  [COUNTRY_ID.UZBEKISTAN]: 'Uzbekistan',
  [COUNTRY_ID.VANUATU]: 'Vanuatu',
  [COUNTRY_ID.VENEZUELA]: 'Venezuela',
  [COUNTRY_ID.VIETNAM]: 'VietNam',
  [COUNTRY_ID.VIRGIN_ISLANDS_BRITISH]: 'Virgin Islands British',
  [COUNTRY_ID.VIRGIN_ISLANDS_US]: 'Virgin Islands US',
  [COUNTRY_ID.WALLIS_AND_FUTUNA]: 'Wallis and Futuna',
  [COUNTRY_ID.WESTERN_SAHARA]: 'Western Sahara',
  [COUNTRY_ID.YEMEN]: 'Yemen',
  [COUNTRY_ID.ZAMBIA]: 'Zambia',
  [COUNTRY_ID.ZIMBABW]: 'Zimbabw',
})

export default countries
