import React from 'react'
import classnames from 'classnames'

import './index.scss'

type TProps = {
  onClick(): void,
  title: string,
  secondary?: boolean,
  outline?: boolean,
  warning?: boolean,
}

export const Button: React.FC<TProps> = ({ onClick, title, secondary, warning, outline }) => {
  return (
    <div
      className={classnames(
        'button-cmp',
        !outline && (secondary ? 'button-cmp-secondary' : 'button-cmp-primary'),
        warning && 'button-cmp-warning',
        outline && 'button-cmp-outline',
      )}
      onClick={onClick}
    >
      {title}
    </div>
  )
}
