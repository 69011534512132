import { Reducer } from 'redux'
import { uniqBy } from 'lodash'

import { ICourier, IMarket, IRoute } from '../../types/TClient'

import * as Actions from '../actions'
import { compareRole } from '../../utils/courierUtils'

export type MutableStateCouriers = {
  loading: boolean,
  loaded: boolean,
  markets: IMarket[],
  couriersList: ICourier[],
  routes: IRoute[],
  errorApi: string,
}

export type StateCouriers = Readonly<MutableStateCouriers>

const defStateCouriers: StateCouriers = {
  loading: false,
  loaded: false,
  markets: [],
  couriersList: [],
  routes: [],
  errorApi: '',
}

const sortCouriers = (couriers: ICourier[]) => {
  const sortedCouriers = [...couriers]

  return sortedCouriers.sort((o1, o2) => {
    if (o2.isOnShift && !o1.isOnShift) {
      return 1
    }

    if (o2.isOnShift === o1.isOnShift && compareRole(o2.role, o1.role)) {
      return 1
    }

    return -1
  })
}

export const couriers: Reducer<StateCouriers, Actions.Action> = (s = defStateCouriers, a): StateCouriers => {
  switch (a.type) {
    case Actions.API_COURIERS_LIST:
      return {
        ...s,
        loading: true,
        couriersList: [],
      }
    case Actions.COURIERS_LIST:
      return {
        ...s,
        loading: false,
        couriersList: sortCouriers(uniqBy([...a.data.couriers, ...(s.couriersList || [])], 'id')),
      }
    case Actions.COURIERS_LOCATIONS:
      return {
        ...s,
        couriersList: s.couriersList.map((courier) => {
          const exist = a.data.couriersLocations.find((item) => item.id === courier.id)

          if (exist) {
            return {
              ...courier,
              ...exist,
            }
          }

          return courier
        }),
      }
    case Actions.COURIER_UPDATE_SHIFTS:
      return {
        ...s,
        couriersList: s.couriersList.map((courier) => {
          if (courier.id === a.data.courierId) {
            const shiftSlots = courier.shiftSlots.filter((item) => item.shiftDate !== a.data.date)

            return {
              ...courier,
              shiftSlots: [...shiftSlots, ...a.data.shiftSlots],
            }
          }

          return courier
        }),
      }
    case Actions.MARKET_ROUTES:
      return {
        ...s,
        loading: false,
        routes: a.data.routes,
      }
  }
  return s
}
