import React from 'react'

type TProps = {
  color?: string,
}

export const VisibleIcon: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        stroke={color}
        strokeWidth='.1'
        d='m.957 11.954-.014.025.013.025.173.32c2.255 4.167 6.21 6.693 10.591 6.693 4.38 0 8.343-2.492 10.59-6.693l.174-.32.013-.024-.013-.024-.173-.313C20.056 7.475 16.1 4.95 11.72 4.95c-4.38 0-8.342 2.525-10.577 6.692l-.186.312ZM11.72 17.55c-3.725 0-7.149-2.073-9.142-5.57C4.57 8.483 7.995 6.41 11.72 6.41c3.725 0 7.108 2.079 9.136 5.57-2.028 3.498-5.418 5.57-9.136 5.57Z'
      />
      <path stroke={color} d='M14.25 12a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z' strokeWidth='1.5' />
    </svg>
  )
}
