import { Reducer } from 'redux'
import { uniqBy, unionBy } from 'lodash'

import {
  EFileType,
  IMarketInfo,
  ISellerInfo,
  ISeller,
  ISellerSearch,
  ISavedSellerProfile,
  EImageType,
  CopyProductsStatus,
  ISellerCategory,
} from '../../types/TClient'

import * as Actions from '../actions'

export type MutableStateSellers = {
  imageLoading: boolean,
  avatarLoading: boolean,
  updateLoading: boolean,
  loading: boolean,
  loadingSearch: boolean,
  loaded: boolean,
  sellersMarketsLoading: boolean,
  sellersMarketsLoaded: boolean,
  sellersInfoList: ISellerInfo[],
  sellersMarketsList: IMarketInfo[],
  sellersList: ISeller[],
  sellersCategories: ISellerCategory[],
  sellersSearchList: ISellerSearch[],
  errorApi: string,
  newSellerInfo?: Partial<ISellerInfo>,
  savedSellerProfile: ISavedSellerProfile,
  copyProducts?: {
    loading: boolean,
    tkn?: string,
    status?: CopyProductsStatus,
  },
}

export type StateSellers = Readonly<MutableStateSellers>

const defStateSellers: StateSellers = {
  imageLoading: false,
  avatarLoading: false,
  updateLoading: false,
  loading: false,
  loadingSearch: false,
  loaded: false,
  sellersMarketsLoading: false,
  sellersMarketsLoaded: false,
  sellersInfoList: [],
  sellersMarketsList: [],
  sellersList: [],
  sellersCategories: [],
  sellersSearchList: [],
  savedSellerProfile: {
    imageUrl: '',
    avatarUrl: '',
  },
  errorApi: '',
}

const SELLERS_LIMIT = 10
const SELLERS_MARKETS_LIMIT = 10

export const sellers: Reducer<StateSellers, Actions.Action> = (s = defStateSellers, a): StateSellers => {
  switch (a.type) {
    case Actions.API_SELLERS_INFO_LIST:
      return {
        ...s,
        loading: true,
        loaded: false,
      }
    case Actions.SELLERS_INFO_LIST:
      return {
        ...s,
        loading: false,
        loaded: a.data.sellers.length < SELLERS_LIMIT,
        sellersInfoList: uniqBy([...(s.sellersInfoList || []), ...a.data.sellers], 'id'),
      }
    case Actions.API_PARTNER_INFO:
      return {
        ...s,
        loading: true,
      }
    case Actions.PARTNER_INFO:
      if (!a.data.id) {
        return {
          ...s,
          loading: false,
          newSellerInfo: {
            ...(s.newSellerInfo && { ...s.newSellerInfo }),
            ...a.data,
          },
        }
      }

      return {
        ...s,
        loading: false,
        sellersInfoList: s.sellersInfoList.map((sellerInfo) => {
          if (sellerInfo.id === a.data.id) {
            return {
              ...sellerInfo,
              ...a.data,
            }
          }

          return sellerInfo
        }),
      }
    case Actions.API_BANK_INFO:
      return {
        ...s,
        loading: true,
      }
    case Actions.BANK_INFO:
      if (!a.data.id) {
        return {
          ...s,
          loading: false,
          newSellerInfo: {
            ...(s.newSellerInfo && { ...s.newSellerInfo }),
            ...a.data,
          },
        }
      }

      return {
        ...s,
        loading: false,
        sellersInfoList: s.sellersInfoList.map((sellerInfo) => {
          if (sellerInfo.id === a.data.id) {
            return {
              ...sellerInfo,
              ...a.data,
            }
          }

          return sellerInfo
        }),
      }
    case Actions.SELLER_FILE: {
      const updateFileType = {
        ...(a.data.type === EFileType.PASSPORT_IP && { passportUrl: a.data.fileUrl }),
        ...(a.data.type === EFileType.LEGAL_REGISTRATION_CERTIFICATE && {
          legalRegistrationCertificateUrl: a.data.fileUrl,
        }),
        ...(a.data.type === EFileType.BANK_ACC_DOC && { bankAccDocUrl: a.data.fileUrl }),
        ...(a.data.type === EFileType.RENT_CONTRACT && { rentContractUrl: a.data.fileUrl }),
        ...(a.data.type === EFileType.CERTIFICATE && { certificateUrl: a.data.fileUrl }),
        ...(a.data.type === EFileType.LEGAL_ENTITIES_STATE_REGISTER && {
          legalEntitiesStateRegisterUrl: a.data.fileUrl,
        }),
        ...(a.data.type === EFileType.DECISION_APPOINTMENT && { decisionAppointmentUrl: a.data.fileUrl }),
        ...(a.data.type === EFileType.ORDER_APPOINTMENT && { orderAppointmentUrl: a.data.fileUrl }),
      }

      if (!a.data.id) {
        return {
          ...s,
          loading: false,
          newSellerInfo: {
            ...(s.newSellerInfo && { ...s.newSellerInfo }),
            ...updateFileType,
          },
        }
      }

      return {
        ...s,
        loading: false,
        sellersInfoList: s.sellersInfoList.map((sellerInfo) => {
          if (sellerInfo.id === a.data.id) {
            return {
              ...sellerInfo,
              ...updateFileType,
            }
          }

          return sellerInfo
        }),
      }
    }
    case Actions.API_CREATE_SELLER_INFO:
      return {
        ...s,
        loading: true,
      }
    case Actions.CREATE_SELLER_INFO:
      return {
        ...s,
        loading: false,
        sellersInfoList: [...s.sellersInfoList, a.data],
      }
    case Actions.API_UPDATE_SELLER_INFO:
      return {
        ...s,
        loading: true,
      }
    case Actions.UPDATE_SELLER_INFO:
      return {
        ...s,
        loading: false,
        sellersInfoList: s.sellersInfoList.map((sellerInfo) => {
          if (sellerInfo.id === a.data.id) {
            return {
              ...a.data,
            }
          }

          return sellerInfo
        }),
      }
    case Actions.API_REMOVE_SELLER_INFO:
      return {
        ...s,
        loading: true,
      }
    case Actions.REMOVE_SELLER_INFO:
      return {
        ...s,
        loading: false,
        sellersInfoList: s.sellersInfoList.filter((sellerInfo) => sellerInfo.id !== a.data.id),
      }
    case Actions.API_SELLER_REGISTER:
      return {
        ...s,
        loading: true,
      }
    case Actions.API_SELLER_INFO_ERROR:
      return {
        ...s,
        loading: false,
        ...(a.data.error && { errorApi: a.data.error }),
      }
    case Actions.DROP_SELLER_INFO_ERROR:
      return {
        ...s,
        loading: false,
        errorApi: '',
      }
    case Actions.SELLERS_DROP_LOADING:
      return {
        ...s,
        updateLoading: false,
        loading: false,
        errorApi: '',
      }
    case Actions.SELLER_UPDATE_IS_HIDE_SUCCESS: {
      const seller = s.sellersList.find((s) => s.id === a.data.id)

      if (!seller) {
        return s
      }

      const newSeller = {
        ...seller,
        isHide: a.data.isHide,
      }

      return {
        ...s,
        sellersList: unionBy([newSeller, ...(s.sellersList || [])], 'id'),
      }
    }
    case Actions.API_COURIER_SELLER_SUCCESS: {
      const seller = s.sellersList.find((s) => s.id === a.data.sellerId)

      if (!seller) {
        return s
      }

      const newSeller = {
        ...seller,
        courierId: a.data.courierId,
      }

      return {
        ...s,
        sellersList: unionBy([newSeller, ...(s.sellersList || [])], 'id'),
      }
    }
    case Actions.API_SELLERS_MARKETS:
      return {
        ...s,
        sellersMarketsLoading: true,
        sellersMarketsLoaded: false,
      }
    case Actions.SELLERS_MARKETS:
      return {
        ...s,
        sellersMarketsLoading: false,
        sellersMarketsLoaded: a.data.markets.length < SELLERS_MARKETS_LIMIT,
        sellersMarketsList: uniqBy([...(s.sellersMarketsList || []), ...a.data.markets], 'id'),
      }
    case Actions.API_SELLERS_LIST:
      return {
        ...s,
        loading: true,
      }
    case Actions.SELLERS_LIST:
      return {
        ...s,
        loading: false,
        sellersList: a.data.force ? a.data.sellers : uniqBy([...a.data.sellers, ...(s.sellersList || [])], 'id'),
      }
    case Actions.SELLERS_CATEGORIES:
      return {
        ...s,
        sellersCategories: a.data.categories,
      }
    case Actions.API_SELLERS_SEARCH:
      return {
        ...s,
        loadingSearch: true,
      }
    case Actions.SELLERS_SEARCH:
      return {
        ...s,
        loadingSearch: false,
        sellersSearchList: a.data.sellers,
      }
    case Actions.UPDATE_SAVED_SELLER_PROFILE:
      return {
        ...s,
        ...(a.data.avatarUrl && { avatarLoading: false }),
        ...(a.data.imageUrl && { imageLoading: false }),
        savedSellerProfile: {
          avatarUrl: a.data.avatarUrl || '',
          imageUrl: a.data.imageUrl || '',
        },
      }
    case Actions.DROP_SAVED_SELLER_PROFILE:
      return {
        ...s,
        savedSellerProfile: {
          avatarUrl: '',
          imageUrl: '',
        },
      }
    case Actions.UPDATE_SELLER:
      return {
        ...s,
        sellersList: s.sellersList.map((seller) => {
          if (seller.id === a.data.id) {
            return {
              ...seller,
              ...(a.data.name && { name: a.data.name }),
              ...(a.data.nickname && { nickname: a.data.nickname }),
              ...(a.data.imageUrl && { imageUrl: a.data.imageUrl }),
              ...(a.data.avatarUrl && { avatarUrl: a.data.avatarUrl }),
            }
          }

          return seller
        }),
      }
    case Actions.API_COPY_PRODUCTS:
      return {
        ...s,
        copyProducts: {
          loading: true,
        },
      }
    case Actions.COPY_PRODUCTS_START:
      return {
        ...s,
        copyProducts: {
          loading: true,
          tkn: a.data.tkn,
        },
      }
    case Actions.COPY_PRODUCTS_STATUS_SUCCESS:
      return {
        ...s,
        copyProducts: {
          loading: !a.data.success,
          status: a.data.status,
          ...(s.copyProducts && { tkn: s.copyProducts.tkn }),
        },
      }
    case Actions.API_UPLOAD_IMAGE: {
      if (a.data.type === EImageType.SELLER_IMAGE) {
        return {
          ...s,
          imageLoading: true,
        }
      }

      if (a.data.type === EImageType.SELLER_AVATAR) {
        return {
          ...s,
          avatarLoading: true,
        }
      }

      return s
    }
    case Actions.API_UPLOAD_IMAGE_ERROR: {
      if (a.data.type === EImageType.SELLER_IMAGE) {
        return {
          ...s,
          imageLoading: false,
        }
      }

      if (a.data.type === EImageType.SELLER_AVATAR) {
        return {
          ...s,
          avatarLoading: false,
        }
      }
    }
  }
  return s
}
