import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Rating } from 'semantic-ui-react'
import moment from 'moment'

import './index.scss'

import { ApiCustomerReviewsReq } from '../../../types/TApi'
import { ICustomer, IReview } from '../../../types/TClient'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'

type TOwnProps = {
  customer: ICustomer,
}

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  reviews: IReview[],
  total: number,
}

type TDispatchedProps = {
  apiCustomerReviews: (data: ApiCustomerReviewsReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  pages: number,
}

const PAGE_SIZE = 3

class CustomerReviewsCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      pages: 1,
    }
  }

  componentDidMount(): void {
    const { customer } = this.props

    this.props.apiCustomerReviews({
      userId: customer.userId,
      offset: 0,
    })
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { customer } = this.props

    if (customer.userId !== prevProps.customer.userId) {
      this.props.apiCustomerReviews({
        userId: customer.userId,
        offset: 0,
      })
    }
  }

  render() {
    const { reviews, loaded, total } = this.props
    const { formatMessage } = this.props.intl
    const { pages } = this.state

    return (
      <div className='customer__reviews'>
        <div className='customer__block-title'>
          {formatMessage(messages.OrdersReviews)} {total ? `(${total})` : ''}
        </div>
        {reviews.length > 0 ? (
          <div>
            {reviews.slice(0, pages * PAGE_SIZE).map(this.renderItem)}
            {(!loaded || reviews.length >= pages * PAGE_SIZE) && this.renderShowRest()}
          </div>
        ) : (
          this.renderEmpty()
        )}
      </div>
    )
  }

  renderEmpty() {
    const { formatMessage } = this.props.intl

    return <div className='customer__block-label'>{formatMessage(messages.CustomerNoReviews)}</div>
  }

  renderItem = (review: IReview) => {
    const { formatMessage } = this.props.intl

    return (
      <div key={review.id} className='customer__reviews-item'>
        <div className='customer__reviews-item__header'>
          <div className='customer__reviews-item__header-num'>
            №{review.orderNum} {formatMessage(messages.From).toLowerCase()}{' '}
            {moment(review.createdAt).format('DD.MM.YYYY')}
          </div>
          <div className='customer__reviews-item__header-rating'>
            <Rating disabled icon='star' rating={review.assessment} maxRating={5} size='huge' />
          </div>
        </div>
        {!!review.message && <div className='customer__reviews-item__message'>{review.message}</div>}
      </div>
    )
  }

  renderShowRest() {
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__reviews__show' onClick={this.getPageReviews}>
        {formatMessage(messages.ShowMore)}
      </div>
    )
  }

  getPageReviews = () => {
    const { customer, reviews } = this.props
    const { pages } = this.state

    this.setState({ pages: pages + 1 })

    if (reviews.length <= reviews.slice(0, (pages + 1) * PAGE_SIZE).length) {
      this.props.apiCustomerReviews({
        userId: customer.userId,
        offset: reviews.length,
      })
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { reviews } = s.customers

  const { customer } = own

  return {
    loading: reviews.loading,
    loaded: reviews.loaded,
    reviews: reviews.list.filter((item) => item.userId === customer.userId),
    total: reviews.total,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCustomerReviews: (data: ApiCustomerReviewsReq) => dispatch(Actions.action(Actions.API_CUSTOMER_REVIEWS, data)),
})

export const CustomerReviews = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerReviewsCmp))
