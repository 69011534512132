import * as React from 'react'
import { connect } from 'react-redux'
import { Message } from 'semantic-ui-react'

import './index.scss'

import { EAlertType, IAlert } from '../../../store/reducers/alerts'
import * as Actions from '../../../store/actions'

type Props = {
  alert: IAlert,
  close(id: string): any,
}

class AlertCmp extends React.Component<Props> {
  componentDidMount(): void {
    const { alert } = this.props

    if (alert.autoClose !== false) {
      setTimeout(() => this.props.close(alert.id), 10000)
    }
  }

  render() {
    const { alert } = this.props

    return (
      <div className='alert-container'>
        <Message
          info={alert.type === EAlertType.INFO}
          warning={alert.type === EAlertType.WARNING}
          positive={alert.type === EAlertType.SUCCESS}
          negative={alert.type === EAlertType.ERROR}
          onDismiss={() => this.props.close(alert.id)}
        >
          <Message.Header>{alert.title}</Message.Header>
          <p>{alert.text}</p>
        </Message>
      </div>
    )
  }
}

const mapDispatchToProps = {
  close: (id: string) => Actions.action(Actions.ALERT_POP, { id }),
}

export const Alert = connect(null, mapDispatchToProps)(AlertCmp)
