import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { IMarket, IStockCategory } from '../../../../../types/TClient'
import {
  ApiMarketCategoriesReq,
  ApiCreateMarketCategoryReq,
  ApiCreateMarketSubcategoryReq,
} from '../../../../../types/TApi'

import messages from '../../../../../localization/messages'
import * as Actions from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import { CategoryEdit } from './CategoryEdit'
import { SubcategoryEdit } from './SubcategoryEdit'
import { StockCategory } from './Category'
import { Button } from '../../../../../components/Button'
import { PlusIcon } from '../../../../../components/Icons'

type TOwnProps = {
  market: IMarket,
}

type TConnectedProps = {
  loading: boolean,
}

type TDispatchedProps = {
  getCategories: (data: ApiMarketCategoriesReq) => Actions.Action,
  createCategory: (data: ApiCreateMarketCategoryReq) => Actions.Action,
  createSubcategory: (data: ApiCreateMarketSubcategoryReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  edit: boolean,
}

class CategoriesCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      edit: false,
    }
  }

  componentDidMount(): void {
    const { market } = this.props

    this.props.getCategories({ marketId: market.id })
  }

  render() {
    const { market, loading } = this.props
    const { edit } = this.state
    const categories = market.categories || []

    return (
      <div className='categories'>
        {!edit && categories.length === 0 && !loading && this.renderEmpty()}
        {edit && this.renderEditStock()}
        {!edit && categories.length !== 0 && this.renderStock()}
      </div>
    )
  }

  renderEmpty = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='categories__empty'>
        <div className='categories__empty-title'>{formatMessage(messages.AssortmentNotCreated)}</div>
        <div className='categories__empty-text'>{formatMessage(messages.HereCreateCategoriesSubcategories)}</div>
        <Button title={formatMessage(messages.CreateAssortment)} onClick={this.startAdd} />
      </div>
    )
  }

  renderEditStock = () => {
    return (
      <div className='categories__stock-edit'>
        <div className='categories__stock-edit__block'>{this.renderEditCategories()}</div>
        {this.renderActions()}
      </div>
    )
  }

  renderStock = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const categories = market.categories || []

    return (
      <div className='categories__stock'>
        <div className='categories__stock__header'>
          <div className='categories__stock__header-text'>{formatMessage(messages.CategoriesAndSubcategories)}</div>
          <Button onClick={() => this.setState({ edit: true })} title={formatMessage(messages.Edit)} />
        </div>
        <div className='categories__stock__block'>
          {categories.map((category, i) =>
            this.renderCategory(category, categories.length > 1 && i < categories.length - 1),
          )}
        </div>
      </div>
    )
  }

  renderEditCategories = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    const categories = market.categories || []

    return (
      <div className='categories__stock-edit__categories'>
        <div className='categories__stock-edit__header'>
          <div className='categories__stock-edit__category-title'>{formatMessage(messages.Categories)}</div>
          <div className='categories__divider-empty' />
          <div className='categories__stock-edit__subcategory-title'>{formatMessage(messages.Subcategories)}</div>
        </div>
        <div className='categories__stock-edit__list'>{categories.map(this.renderEditItem)}</div>
      </div>
    )
  }

  renderEditItem = (category: IStockCategory, index: number) => {
    const { market } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div key={category.id} className='categories__stock-edit__item'>
        <CategoryEdit pos={index} market={market} category={category} />
        <div className='categories__divider' />
        <div className='categories__stock-edit__item-subcategories'>
          <div>
            {category.subcategories.map((subcategory, i) => {
              return (
                <SubcategoryEdit
                  key={subcategory.id}
                  market={market}
                  category={category}
                  subcategory={subcategory}
                  pos={i}
                />
              )
            })}
          </div>
          <div className='categories__stock-edit__add' onClick={() => this.addSubcategory(category)}>
            <PlusIcon color='#7677bc' />
            <div className='categories__stock-edit__add-text'>{formatMessage(messages.AddSubcategory)}</div>
          </div>
        </div>
      </div>
    )
  }

  renderActions = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='categories__actions'>
        <Button title={formatMessage(messages.FinishEditing)} onClick={this.endEdit} />
      </div>
    )
  }

  renderCategory = (category: IStockCategory, divider = false) => {
    return (
      <React.Fragment key={category.id}>
        <StockCategory market={this.props.market} category={category} />
        {divider && <div className='categories__stock__divider' />}
      </React.Fragment>
    )
  }

  startAdd = () => {
    const { market } = this.props
    const { formatMessage } = this.props.intl
    this.setState({ edit: true })

    if (!market.categories || market.categories.length === 0) {
      this.props.createCategory({
        marketId: market.id,
        name: formatMessage(messages.Category),
        index: 1,
        hidden: false,
      })
    }
  }

  addSubcategory = (category: IStockCategory) => {
    const { market } = this.props
    const { formatMessage } = this.props.intl

    this.props.createSubcategory({
      category: category,
      req: {
        marketId: market.id,
        categoryId: category.id,
        name: formatMessage(messages.Subcategory),
        index: category.subcategories.length + 1,
        hidden: false,
      },
    })
  }

  endEdit = () => {
    this.setState({ edit: false })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loadingCategories } = s.markets

  return {
    loading: loadingCategories,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getCategories: (data: ApiMarketCategoriesReq) => dispatch(Actions.action(Actions.API_MARKET_CATEGORIES, data)),
  createCategory: (data: ApiCreateMarketCategoryReq) =>
    dispatch(Actions.action(Actions.API_CREATE_MARKET_CATEGORY, data)),
  createSubcategory: (data: ApiCreateMarketSubcategoryReq) =>
    dispatch(Actions.action(Actions.API_CREATE_MARKET_SUBCATEGORY, data)),
})

export const Categories = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoriesCmp))
