import { EMPTY, of as observableOf } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'
import { uniqBy } from 'lodash'

import * as TApi from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import { URL_CUSTOMERS, URL_ORDERS_BY_MARKETS, URL_TOTAL_ORDERS } from '../../modules/network/urls'
import { DEF_CURRENCY } from '../../localization/currencies'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import { convertCurrency } from '../../utils/productsUtils'

const ordersByMarketsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiOrdersByMarkets>(a$, Actions.API_ORDERS_BY_MARKETS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_ORDERS_BY_MARKETS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.statuses && c.data.statuses.length > 0 && { statuses: c.data.statuses.join(',') }),
            ...(c.data.dateFrom && { dateFrom: c.data.dateFrom }),
            ...(c.data.dateTo && { dateTo: c.data.dateTo }),
          },
        }).pipe(
          mergeMap((_resp: { data: TApi.ApiOrdersByMarkersResp }) => {
            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const customersEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCustomers>(a$, Actions.API_CUSTOMERS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_CUSTOMERS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.dateFrom && { dateFrom: c.data.dateFrom }),
            ...(c.data.dateTo && { dateTo: c.data.dateTo }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCustomersResp }) => {
            return observableOf<Actions.Action>(Actions.action(Actions.NEW_CUSTOMERS, resp.data))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const totalOrdersEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiTotalOrders>(a$, Actions.API_TOTAL_ORDERS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_TOTAL_ORDERS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.dateFrom && { dateFrom: c.data.dateFrom }),
            ...(c.data.dateTo && { dateTo: c.data.dateTo }),
            ...(c.data.city && { city: c.data.city }),
            ...(c.data.market && { market: c.data.market }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
            ...(c.data.web !== undefined && { web: c.data.web }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiTotalOrdersResp }) => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.TOTAL_ORDERS, {
                totalOrders: resp.data.data.map((item) => {
                  const { currencyCode, ...rest } = item
                  const currency = convertCurrency(currencyCode)

                  return {
                    ...rest,
                    currency: convertCurrency(currencyCode),
                    currencies: uniqBy([currency, DEF_CURRENCY], 'code'),
                  }
                }),
                currencyPairs: resp.data.currencyPairs,
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const analyticsEpics: EpicFunc[] = [ordersByMarketsEpic, customersEpic, totalOrdersEpic]
