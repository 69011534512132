import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import moment from 'moment'

import './index.scss'

import { EOrderStatus, HistoryStatusAction, IOrder } from '../../../../types/TClient'

import { getColor, OrderHistoryConvertStatus } from '../../../../utils/ordersUtils'
import { Action } from '../Action'
import messages from '../../../../localization/messages'

interface IProps extends HistoryStatusAction {
  order: IOrder,
}
type TProps = IProps & WrappedComponentProps

const _StatusAction: React.FC<TProps> = ({ order, data: { status }, date, intl }) => {
  const { formatMessage } = intl
  const deliveryAt = order.deliveryAt || order.completedAt ? moment(order.deliveryAt || order.completedAt) : ''
  const deliveryPlan = order.deliveryEndTime
    ? moment(order.deliveryDate)
        .hours(+order.deliveryEndTime.split(':')[0])
        .minutes(+order.deliveryEndTime.split(':')[1])
    : ''
  const deliveryLate = deliveryPlan ? deliveryPlan.diff(deliveryAt, 'minute', true) : 0
  const isLate = order.statusId === EOrderStatus.DELIVERED && deliveryLate <= -1

  return (
    <Action date={date}>
      <div style={{ color: getColor(status) }}>{OrderHistoryConvertStatus(status)}</div>
      {status === EOrderStatus.DELIVERED && (
        <div>
          <FormattedMessage {...messages.IsLate} />{' '}
          <span style={{ color: isLate ? '#eb5757' : '' }}>
            {isLate
              ? `${Math.floor(Math.abs(deliveryLate))} ${formatMessage(messages.Min).toLowerCase()}`
              : deliveryAt
              ? formatMessage(messages.No).toLowerCase()
              : '-'}
          </span>
        </div>
      )}
    </Action>
  )
}

export const StatusAction = injectIntl(_StatusAction)
