import React from 'react'
import { FormattedMessage } from 'react-intl'

import './index.scss'

import { TDeliveryArea } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { getColorByPolygonNum } from '../../../utils/marketsUtils'

export interface IProps {
  area: TDeliveryArea,
}

const DeliveryArea: React.FC<IProps> = (props) => {
  const index = props?.area?.index

  if (!index) {
    return null
  }

  return (
    <div className='delivery-area'>
      <div className='delivery-area__icon' style={{ backgroundColor: getColorByPolygonNum(+index) }} />
      <div className='delivery-area__text'>
        <FormattedMessage {...messages.Polygon} /> №{index}
      </div>
    </div>
  )
}

export default DeliveryArea
