export const SID_KEY = 'SID'
export const COURIERS_MARKET = 'couriers_market'
export const ORDERS_MARKET = 'orders_market'
export const LANG = 'lang'

export const setValue = (key: string, sid: string) => {
  localStorage.setItem(key, sid)
  return localStorage.getItem(key)
}

export const removeValue = (key: string) => {
  localStorage.removeItem(key)
}

export const getValue = (key: string) => {
  return localStorage.getItem(key)
}
