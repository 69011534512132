import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import { Popup } from 'semantic-ui-react'

import './index.scss'

import {
  IGroupProducts,
  IMarket,
  IProduct,
  IProductOrder,
  IStockCategory,
  IStockSubcategory,
} from '../../../../../../types/TClient'
import { ApiMarketProductsReq, ApiProductsUpdateRating } from '../../../../../../types/TApi'

import messages from '../../../../../../localization/messages'
import * as Actions from '../../../../../../store/actions'
import { State } from '../../../../../../store/reducer'
import { ProductItem } from '../../../../../../components/ProductItem'
import { ChevronUpIcon, InfoUpdateIcon, PictureIcon, VisibleOffIcon } from '../../../../../../components/Icons'
import { ProductCard } from '../../../../../../components/ProductCard'
import { ModalConfirm } from '../../../../../../components/Modal/ModalConfirm'
import { convertProductToProductSeller, sortMarketProductByCategories } from '../../../../../../utils/productsUtils'

type TOwnProps = {
  market: IMarket,
  category: IStockCategory,
  groupProducts?: IGroupProducts,
  byProduct?: boolean,
  byGroup?: boolean,
  selectRoot?: boolean,
  updateGroup?(product: IProduct): void,
  orderId?: string,
}

type TConnectedProps = {
  loadingCategoryProducts: boolean,
  categoryProducts: IProduct[],
  preAddProducts: IProductOrder[],
}

type TDispatchedProps = {
  apiMarketProducts: (data: ApiMarketProductsReq) => Actions.Action,
  apiProductsUpdateRating: (data: ApiProductsUpdateRating) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  open: boolean,
  updateRatingModal: boolean,
  subcategoryUpdateRating: string,
}

const PRODUCTS_LIMIT = 10

class StockCategoryCmp extends React.Component<TProps, TState> {
  private productsRef = React.createRef<HTMLDivElement>()

  constructor(props: TProps) {
    super(props)

    this.state = {
      open: false,
      updateRatingModal: false,
      subcategoryUpdateRating: '',
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    if (this.props.categoryProducts.length === 0 && prevProps.categoryProducts.length > 0) {
      this.setState({ open: false })
    }
  }

  render() {
    const { category, byProduct, byGroup, preAddProducts } = this.props
    const { formatMessage } = this.props.intl
    const { open } = this.state

    return (
      <div className='stock-category'>
        {this.renderUpdateRatingModal()}
        <div className='stock-category__content'>
          <div className='stock-category__info' onClick={this.toggleOpen}>
            {this.renderImage()}
            <div className='stock-category__info-desc'>
              <div className='stock-category__title'>{category.name}</div>
              <div className='stock-category__title-info'>
                (
                {!byProduct || byGroup
                  ? `${formatMessage(messages.SubcategoriesR).toLowerCase()}: ${category.subcategories.length} / `
                  : ''}
                {formatMessage(messages.GoodsR).toLowerCase()}: {category.numProducts || 0})
                {byGroup && this.renderGroupProducts()}
              </div>
              {byProduct && preAddProducts.length > 0 && this.renderPreAddedProducts()}
            </div>
            {category.hidden && this.renderVisible()}
          </div>
          {(category.subcategories.length > 0 || byProduct) && (
            <div
              className={classnames('stock-category__action', open && 'stock-category__action-opened')}
              onClick={this.toggleOpen}
            >
              <ChevronUpIcon color='#000' />
            </div>
          )}
        </div>
        {open && !byProduct && category.subcategories.length > 0 && (
          <div className='stock-category__subcategories'>{category.subcategories.map(this.renderSubcategory)}</div>
        )}
        {open && byProduct && this.renderProducts()}
      </div>
    )
  }

  renderPreAddedProducts = () => {
    const { preAddProducts } = this.props
    const { formatMessage } = this.props.intl

    return (
      <>
        <div className='stock-category__dot' />
        <div className='stock-category__added-products'>
          {formatMessage(messages.AddedProducts)}: {preAddProducts.length}
        </div>
      </>
    )
  }

  renderGroupProducts = () => {
    const { groupProducts, category } = this.props
    const { formatMessage } = this.props.intl
    const products =
      groupProducts?.products.filter((item) => item.product?.marketCategoryId === category.id && !item.root) || []

    if (products.length === 0) {
      return
    }

    return (
      <>
        <div className='stock-category__dot' />
        <div className='stock-category__group-products'>
          {formatMessage(messages.ProductsSelected)}: {products.length}
        </div>
      </>
    )
  }

  renderImage = () => {
    const { category } = this.props

    return (
      <div className={classnames('stock-category__image', category.hidden && 'stock-category__image-hidden')}>
        {category.imageUrl ? (
          <div className='stock-category__image' style={{ backgroundImage: `url(${category.imageUrl})` }} />
        ) : (
          <div className={classnames('stock-category__image', 'stock-category__image-empty')}>
            <PictureIcon />
          </div>
        )}
      </div>
    )
  }

  renderVisible = () => {
    const { formatMessage } = this.props.intl

    return (
      <Popup
        wide
        content={
          <>
            <div className='stock-category__visible-title'>{formatMessage(messages.CategoryHidden)}</div>
            <div className='stock-category__visible-text'>{formatMessage(messages.CategoryHiddenInfo)}</div>
          </>
        }
        position='top center'
        trigger={
          <div className='stock-category__visible'>
            <VisibleOffIcon color='#f59e4d' />
          </div>
        }
      />
    )
  }

  renderSubcategory = (subcategory: IStockSubcategory) => {
    const { formatMessage } = this.props.intl

    return (
      <div key={subcategory.id} className='stock-category__subcategory'>
        <div className='stock-category__subcategory-title'>{subcategory.name}</div>
        <div className='stock-category__subcategory-info'>
          ({formatMessage(messages.GoodsR).toLowerCase()}: {subcategory.numProducts || 0})
        </div>
        {subcategory.hidden && (
          <Popup
            wide
            content={
              <>
                <div className='stock-category__visible-title'>{formatMessage(messages.SubcategoryHidden)}</div>
                <div className='stock-category__visible-text'>{formatMessage(messages.SubcategoryHiddenInfo)}</div>
              </>
            }
            position='top center'
            trigger={
              <div>
                <VisibleOffIcon color='#f59e4d' />
              </div>
            }
          />
        )}
      </div>
    )
  }

  renderProducts = () => {
    const { categoryProducts, orderId } = this.props

    return (
      <div
        className={classnames('stock-category__products', orderId && 'stock-category__products-modal')}
        ref={this.productsRef}
        onScroll={this.checkEndProducts}
      >
        {categoryProducts.map(this.renderProduct)}
      </div>
    )
  }

  renderProduct = (product: IProduct, index: number) => {
    const { orderId, byGroup, selectRoot, groupProducts, categoryProducts, preAddProducts } = this.props
    const { formatMessage } = this.props.intl
    const pre = index > 0 ? categoryProducts[index - 1] : null
    const isSubcategory = pre ? pre.marketSubcategoryId !== product.marketSubcategoryId : true
    const isPreAdd = preAddProducts.find((item) => item.id === product.id)
    const productOrder = isPreAdd || {
      ...product,
      itemId: '0',
      image: product.imageUrl,
      quantity: 0,
      quantityInitial: 0,
      market: product.marketId,
      currency: product.currency,
    }
    const productGroup = groupProducts
      ? groupProducts.products.find((item) => item.productId === product.id)
      : undefined
    const existRoot = groupProducts ? groupProducts.products.find((item) => item.root) : undefined

    return (
      <React.Fragment key={product.id}>
        {isSubcategory && (
          <div className='stock-category__subcategory-header'>
            <div className='stock-category__subcategory-header-text'>
              {product.marketSubcategory || formatMessage(messages.Other)}
            </div>
            {!byGroup && (
              <Popup
                wide
                content={
                  <>
                    <div className='stock-category__subcategory-header__rating-title'>
                      {formatMessage(messages.UpdateProductRatings)}
                    </div>
                  </>
                }
                position='top center'
                trigger={
                  <div
                    className='stock-category__subcategory-header__rating-icon'
                    onClick={() => this.updateRatingOpen(product.marketSubcategoryId)}
                  >
                    <InfoUpdateIcon color={'#000'} />
                  </div>
                }
              />
            )}
          </div>
        )}
        {orderId ? (
          <ProductItem editable preAdd orderId={orderId} product={productOrder} />
        ) : (
          ((byGroup && !productGroup?.root) || !byGroup) && (
            <ProductCard
              editable={!byGroup}
              key={product.id}
              product={convertProductToProductSeller(product)}
              marketProduct={product}
              chainNum={byGroup ? productGroup?.index || null : undefined}
              labelElem={
                selectRoot ? (
                  <div className='product-card__label' onClick={() => this.updateGroup(product)}>
                    {formatMessage(existRoot ? messages.SelectAnother : messages.Select)}
                  </div>
                ) : undefined
              }
              updateGroup={this.updateGroup}
            />
          )
        )}
      </React.Fragment>
    )
  }

  renderUpdateRatingModal = () => {
    const { formatMessage } = this.props.intl
    const { updateRatingModal } = this.state

    return (
      <ModalConfirm
        onClose={() => this.setState({ updateRatingModal: false })}
        onOk={this.productsUpdateRating}
        open={updateRatingModal}
        title={formatMessage(messages.SureUpdateRatingsInSubcategory)}
        text={formatMessage(messages.UpdateRatingsWarning)}
        titleBtn={formatMessage(messages.Refresh)}
      />
    )
  }

  checkEndProducts = () => {
    const { categoryProducts, loadingCategoryProducts } = this.props

    if (this.productsRef.current) {
      const { scrollHeight, offsetHeight, scrollTop } = this.productsRef.current

      if (
        !loadingCategoryProducts &&
        categoryProducts.length &&
        categoryProducts.length % PRODUCTS_LIMIT === 0 &&
        scrollHeight - offsetHeight - scrollTop < 300
      ) {
        this.getCategoryProducts(categoryProducts.length)
      }
    }
  }

  getCategoryProducts = (offset: number) => {
    const { market, category, orderId } = this.props

    this.props.apiMarketProducts({
      offset,
      market: market.id,
      marketCategory: category.id,
      byCategories: true,
      hiddenSeller: false,
      ...(orderId && { hidden: false }),
      ...(orderId && { notInOrder: orderId }),
    })
  }

  productsUpdateRating = () => {
    const { market, category } = this.props
    const { subcategoryUpdateRating } = this.state

    this.props.apiProductsUpdateRating({
      market: market.id,
      marketCategory: category.id,
      marketSubcategory: subcategoryUpdateRating,
    })

    this.setState({ updateRatingModal: false })
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })

    if (!this.state.open) {
      this.getCategoryProducts(0)
    }
  }

  updateRatingOpen = (subcategoryUpdateRating?: string) => {
    this.setState({ updateRatingModal: !this.state.updateRatingModal })

    if (subcategoryUpdateRating) {
      this.setState({ subcategoryUpdateRating })
    }
  }

  updateGroup = (product: IProduct) => {
    const { updateGroup } = this.props

    if (updateGroup) {
      updateGroup(product)
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { loadingMarketProducts, marketProducts, preAddProducts } = s.products

  const products = (marketProducts || []).filter((item) => item.marketCategoryId === own.category.id)

  products.sort(sortMarketProductByCategories)

  return {
    preAddProducts: preAddProducts.filter((item) => item.marketCategoryId === own.category.id),
    loadingCategoryProducts: loadingMarketProducts,
    categoryProducts: products,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiMarketProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
  apiProductsUpdateRating: (data: ApiProductsUpdateRating) =>
    dispatch(Actions.action(Actions.API_PRODUCTS_UPDATE_RATING, data)),
})

export const StockCategory = connect(mapStateToProps, mapDispatchToProps)(injectIntl(StockCategoryCmp))
