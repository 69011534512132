import { Reducer } from 'redux'

import * as Actions from '../actions'

export enum EAlertType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export type IAlert = {
  id: string,
  type: EAlertType,
  title: string,
  text?: string,
  autoClose?: boolean,
}

export type MutableStateAlerts = {
  alerts: IAlert[],
}

export type StateAlerts = Readonly<MutableStateAlerts>

const defStateAlerts: StateAlerts = {
  alerts: [],
}

export const alerts: Reducer<StateAlerts, Actions.Action> = (s = defStateAlerts, a): StateAlerts => {
  switch (a.type) {
    case Actions.ALERT_PUSH: {
      const newAlerts = [...s.alerts]
      newAlerts.push(a.data)

      return {
        ...s,
        alerts: newAlerts,
      }
    }
    case Actions.ALERT_POP: {
      const newAlerts = [...s.alerts]
      newAlerts.pop()

      return {
        ...s,
        alerts: newAlerts,
      }
    }
    case Actions.MODAL_CLEAR:
      return {
        ...s,
        alerts: [],
      }
  }
  return s
}
