import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { DropdownProps, Form, Icon } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { EDeliveryType, EOrderStatus, EPaymentType, IOrder } from '../../../types/TClient'
import * as TApi from '../../../types/TApi'

import messages from '../../../localization/messages'
import { EModalType, IModalDeliveryChange } from '../../../store/reducers/modals'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { checkIsInterval, formatTime, getDeliveryTypeName, getTimeWithTimeZone } from '../../../utils/ordersUtils'
import DeliveryArea from '../../Modal/DeliveryArea'
import { formatPrice } from '../../../utils/productsUtils'

type TOwnProps = {
  order: IOrder,
  disabled?: boolean,
}

type TConnectedProps = {
  updateLoading: boolean,
  loading: boolean,
  updateError: boolean,
}

type TDispatchedProps = {
  apiOrderUpdate: (data: TApi.ApiUpdateOrderReq) => Actions.Action,
  deliveryChangeModal(data: IModalDeliveryChange): Actions.Action,
  resetError(): void,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  activeDelivery: boolean,
  delivery: boolean,
}

class OrderDeliveryCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      activeDelivery: false,
      delivery: !props.order.selfPickup,
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { order } = this.props

    if (order.selfPickup !== prevProps.order.selfPickup) {
      this.setState({ activeDelivery: false, delivery: !order.selfPickup })
    }
  }

  render() {
    const { order } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='order__slot'>
        <div className='order__block-title'>{formatMessage(messages.Receipt)}</div>
        {this.renderDeliveryType()}
        {order.deliveryAddress && (
          <div className={classnames('order__block-item', 'order__block-padding')}>
            <div className='order__block-label'>{formatMessage(messages.Address)}</div>
            <div className='order__block-text'>{order.deliveryAddress}</div>
            {this.renderDeliveryArea()}
          </div>
        )}
        <div className='order__block-row'>
          {this.renderDeliveryDate()}
          {this.renderDeliveryTime()}
        </div>
      </div>
    )
  }

  renderDeliveryArea = () => {
    const { order } = this.props
    if (!order.deliveryArea) {
      return null
    }

    return (
      <div className='order__delivery__polygon'>
        <DeliveryArea area={order.deliveryArea} />
      </div>
    )
  }

  renderDeliveryType = () => {
    const { order, disabled } = this.props
    const { formatMessage } = this.props.intl
    const editableDelivery =
      !disabled &&
      ((order.paymentType === EPaymentType.BY_CARD && !order.paid) || order.paymentType === EPaymentType.CASH) &&
      order.statusId !== EOrderStatus.CANCELED &&
      order.statusId !== EOrderStatus.DELIVERED

    const currentValue = this.state.delivery ? EDeliveryType.DELIVERY : EDeliveryType.PICK_UP
    const options = [
      { key: EDeliveryType.PICK_UP, value: EDeliveryType.PICK_UP, text: formatMessage(messages.Pickup) },
      { key: EDeliveryType.DELIVERY, value: EDeliveryType.DELIVERY, text: formatMessage(messages.Delivery) },
    ]

    return (
      <div className='order__block-item'>
        {this.state.activeDelivery ? (
          <div className='order__delivery__form'>
            <Form.Dropdown
              className='order-info__item-value'
              value={currentValue}
              fluid
              search
              selection
              options={options}
              onChange={this.onChangeDeliveryType}
            />
            <div
              className='order__delivery__btn'
              onClick={this.updateDeliveryType}
              style={{ backgroundColor: '#7677bc', marginLeft: '10px' }}
            >
              <Icon name='check' className='order__delivery__btn-icon' style={{ color: '#fff' }} />
            </div>
            <div
              className='order__delivery__btn'
              onClick={() => this.setState({ activeDelivery: false, delivery: !order.selfPickup })}
            >
              <Icon name='close' className='order__delivery__btn-icon' style={{ color: '#7677bc' }} />
            </div>
          </div>
        ) : (
          <div className='order__block-text'>
            {order.selfPickup ? formatMessage(messages.Pickup) : formatMessage(messages.Delivery)}
            {editableDelivery && this.renderEditBtn(() => this.setState({ activeDelivery: true }))}
          </div>
        )}
      </div>
    )
  }

  renderDeliveryDate = () => {
    const { order, disabled } = this.props
    const { formatMessage } = this.props.intl

    if (!order.deliveryDate) {
      return
    }

    const editableDeliveryDate =
      !disabled && order.statusId !== EOrderStatus.CANCELED && order.statusId !== EOrderStatus.DELIVERED

    return (
      <div className={classnames('order__block-item', 'order__block-padding')}>
        <div className='order__block-label'>{formatMessage(messages.DeliveryDate)}</div>
        <div className='order__block-text'>
          {getTimeWithTimeZone(order.deliveryDate, order.cityTz).format('D MMMM YYYY')}
          {editableDeliveryDate && this.renderEditBtn(this.updateDeliveryType)}
        </div>
      </div>
    )
  }

  renderDeliveryTime = () => {
    const { order, disabled } = this.props
    const { formatMessage } = this.props.intl
    const { deliveryTime, deliveryEndTime } = order
    const currency = order.items.length ? order.items[0].currency : undefined

    if (!order.deliveryTime) {
      return
    }

    const editableDeliveryTime =
      !disabled && order.statusId !== EOrderStatus.CANCELED && order.statusId !== EOrderStatus.DELIVERED
    const byType = checkIsInterval(order.deliveryType)

    return (
      <div className={classnames('order__block-item', 'order__block-padding')}>
        <div className='order__block-label'>{formatMessage(messages.DeliveryTime)}</div>
        <div className='order__block-item'>
          <div className='order__block-text'>
            {byType
              ? getDeliveryTypeName(order.deliveryType)
              : `${formatTime(deliveryTime || '')} – ${formatTime(deliveryEndTime || '')}`}
            <span className='order__slot__delivery-price'> ({formatPrice(order.deliveryPrice, currency)})</span>
            {editableDeliveryTime && this.renderEditBtn(this.updateDeliveryType)}
          </div>
        </div>
      </div>
    )
  }

  renderEditBtn = (action: () => any, color?: string) => {
    return (
      <div className='order__edit-btn' onClick={action} style={{ backgroundColor: color }}>
        <Icon className='order__edit-btn__icon' name='pencil alternate' />
      </div>
    )
  }

  onChangeDeliveryType = (_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data

    this.setState({ delivery: value === EDeliveryType.DELIVERY })
  }

  updateDeliveryType = () => {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const { delivery } = this.state

    if (order.selfPickup === !delivery) {
      this.setState({ activeDelivery: false })
    }

    if (!delivery) {
      return this.props.apiOrderUpdate({ id: order.id, self_pickup: true })
    }

    this.props.deliveryChangeModal({
      type: EModalType.MODAL_DELIVERY_CHANGE,
      size: 'mini',
      style: { width: '558px', maxWidth: '90%', borderRadius: '16px' },
      close: true,
      props: {
        order,
        title: formatMessage(messages.ChangeReceiveMethod),
      },
    })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading, updateLoading } = s.orders

  return {
    updateLoading,
    loading,
    updateError: s.orders.updateError,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiOrderUpdate: (data: TApi.ApiUpdateOrderReq) => dispatch(Actions.action(Actions.API_UPDATE_ORDER, data)),
  deliveryChangeModal: (data: IModalDeliveryChange) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  resetError: () => dispatch(Actions.action(Actions.UPDATE_ORDER_ERROR, false)),
})

export const OrderDelivery = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDeliveryCmp))
