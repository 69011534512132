import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Card, Header, Icon } from 'semantic-ui-react'
import moment from 'moment'

import './index.scss'

import { ENotificationFrequency, ENotificationType, INotification } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { EModalType, IModalDeleteNotification } from '../../../../store/reducers/modals'
import * as Actions from '../../../../store/actions'
import { State } from '../../../../store/reducer'
import {
  LOCATION_NEW_NOTIFICATION,
  LOCATION_NOTIFICATIONS,
  LOCATION_NOTIFICATIONS_LOG,
} from '../../../../utils/locationUtils'
import { dayToString, frequencyToString, typeToString, userGroupToString } from '../../../../utils/notificationsUtils'

type TConnectedProps = {
  loading: boolean,
  notifications: INotification[],
}

type TDispatchedProps = {
  getNotificationsList: (offset?: number) => Actions.Action,
  deleteNotificationModal: (data: IModalDeleteNotification) => Actions.Action,
}

type Props = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

const NOTIFICATIONS_LIMIT = 20

class NotificationsCmp extends React.Component<Props> {
  listRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLInputElement>()

  componentDidMount(): void {
    this.props.getNotificationsList()
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='notifications'>
        <Header as='h1'>{formatMessage(messages.Notifications)}</Header>
        <Link to={LOCATION_NOTIFICATIONS_LOG}>
          <Button primary>
            <Icon disabled name='list' />
            {formatMessage(messages.History)}
          </Button>
        </Link>
        <div className='notifications__list' ref={this.listRef} onScroll={this.checkEnd}>
          {this.renderNotificationList()}
        </div>
      </div>
    )
  }

  renderNotificationList() {
    const { notifications } = this.props
    const { formatMessage } = this.props.intl

    return (
      <Card.Group>
        <Card link className='notifications__list-item' onClick={() => this.toNotification(LOCATION_NEW_NOTIFICATION)}>
          <Card.Content className='notifications__list-add'>
            <div className='notifications__list-add__icon'>
              <Icon name='plus' size='large' />
            </div>
            <div className='notifications__list-add__text'>{formatMessage(messages.Add)}</div>
          </Card.Content>
        </Card>
        {notifications.map((item) => {
          return (
            <Card
              link
              key={`${item.type}_${item.id}`}
              className='notifications__list-item'
              onClick={() => this.toNotification(`${item.type}/${item.id}`)}
            >
              <Card.Content>
                <div
                  className='notifications__list-item__delete'
                  onClick={(event) => this.deleteNotification(event, item)}
                >
                  <Icon style={{ margin: 0 }} name='trash alternate' size='large' />
                </div>
                <Card.Header content={item.title} />
                <Card.Meta content={`${this.renderType(item)}: ${this.renderTime(item)}`} />
                {item.frequency && (
                  <Card.Meta content={`${this.renderFrequency(item.frequency, item.frequencyWeek)}`} />
                )}
                <Card.Meta content={`${this.renderUserGroup(item)}`} />
                <Card.Description content={item.body} />
              </Card.Content>
            </Card>
          )
        })}
      </Card.Group>
    )
  }

  renderType = (item: INotification) => {
    const { type, days, date } = item
    let typeDesc = typeToString(item.type)

    switch (type) {
      case ENotificationType.BY_DAY_WEEK:
        if (days) {
          days.forEach((day, i) => {
            if (i === 0) {
              typeDesc += ' ('
            }

            typeDesc += dayToString(day)

            if (days.length > 0 && i + 1 < days.length) {
              typeDesc += ', '
            } else if (i + 1 === days.length) {
              typeDesc += ')'
            }
          })
        }
        break
      case ENotificationType.SPECIFIC_DAY:
        if (item.date) {
          typeDesc += ` ${moment(date).utcOffset(3).format('DD.MM.YYYY')}`
        }
        break
    }

    return typeDesc
  }

  renderTime = (item: INotification) => {
    return `${item.times.join(',')} UTC+3`
  }

  renderFrequency = (frequency: ENotificationFrequency, frequencyWeek?: number) => {
    return frequencyToString(frequency, frequencyWeek)
  }

  renderUserGroup = (item: INotification) => {
    const { formatMessage } = this.props.intl

    return item.clients && item.clients.length
      ? formatMessage(messages.ForSpecificCustomers)
      : userGroupToString(item.userGroup)
  }

  deleteNotification = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, notification: INotification) => {
    event.stopPropagation()
    const { formatMessage } = this.props.intl

    this.props.deleteNotificationModal({
      type: EModalType.MODAL_DELETE_NOTIFICATION,
      basic: true,
      props: {
        title: `${formatMessage(messages.SureRemoveNotification, { title: notification.title })}`,
        notification,
      },
    })
  }

  toNotification = (id: string) => {
    if (id === LOCATION_NEW_NOTIFICATION) {
      this.props.history.push(`${LOCATION_NOTIFICATIONS}/${id}`)
    }
    // if (type !== ENotificationType.SPECIFIC_DAY) {
    //   this.props.history.push(`${LOCATION_NOTIFICATIONS}/${id}`)
    // }
  }

  checkEnd = () => {
    const { notifications, loading } = this.props

    if (this.listRef.current) {
      const { scrollHeight, offsetHeight, scrollTop } = this.listRef.current

      if (
        !loading &&
        notifications.length &&
        notifications.length % NOTIFICATIONS_LIMIT === 0 &&
        scrollHeight - offsetHeight - scrollTop < 300
      ) {
        this.props.getNotificationsList(notifications.length)
      }
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  return {
    loading: s.notifications.loading,
    notifications: s.notifications.notificationsList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getNotificationsList: (offset?: number) => dispatch(Actions.action(Actions.API_NOTIFICATIONS_LIST, { offset })),
  deleteNotificationModal: (data: IModalDeleteNotification) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
})

export const Notifications = connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationsCmp))
