import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, Dropdown, Form, Loader, Message, Radio } from 'semantic-ui-react'
import moment from 'moment'

import './index.scss'

import { EFileType, ESellerType, ICity, IMarket, ISellerInfo } from '../../../../types/TClient'
import { ApiSellerInfoCreateReq, ApiSellerInfoUpdateReq } from '../../../../types/TApi'

import * as Actions from '../../../../store/actions'
import { State } from '../../../../store/reducer'
import { LOCATION_NEW_SELLER, LOCATION_SELLERS } from '../../../../utils/locationUtils'
import { convertSellerToApi } from '../../../../utils/sellerUtils'

type TConnectedProps = {
  loading: boolean,
  cities: ICity[],
  markets: IMarket[],
  sellerInfo?: Partial<ISellerInfo>,
  errorApi?: string,
}

type TDispatchedProps = {
  getCities: () => Actions.Action,
  getMarkets: (cityId: string) => Actions.Action,
  getPartnerInfo: (inn: string, id?: string) => Actions.Action,
  getBankInfo: (bik: string, id?: string) => Actions.Action,
  uploadFile: (file: File, type: EFileType, id?: string) => Actions.Action,
  apiCreateSellerInfo: (sellerInfo: ApiSellerInfoCreateReq) => Actions.Action,
  apiUpdateSellerInfo: (sellerInfo: ApiSellerInfoUpdateReq) => Actions.Action,
  apiSellerRegister: (id: string) => Actions.Action,
  dropApiError: () => Actions.Action,
}

type Props = RouteComponentProps<{ id: string }> & TConnectedProps & TDispatchedProps

type FieldError = {
  field: string,
  message: string,
}

type StateSellerRegister = {
  type: ESellerType,
  phone: string,
  email?: string,
  password: string,
  repeatPassword: string,
  isSystem: boolean,
  inn: string,
  city: string,
  market: string,
  location: string,
  fio: string,
  name: string,
  ogrnip: string,
  ogrn: string,
  address: string,
  registrationDate: string,
  bik: string,
  bankName: string,
  corAcc: string,
  checkingAcc: string,
  changed: boolean,
  errors: FieldError[],
  fileLoadings: Set<string>,
}

const FIELD_PHONE = 'phone'
const FIELD_EMAIL = 'email'
const FIELD_PASSWORD = 'password'
const FIELD_REPEAT_PASSWORD = 'repeat_password'
const FIELD_IS_SYSTEM = 'is_system'
const FIELD_CITY = 'city'
const FIELD_MARKET = 'market'
const FIELD_LOCATION = 'location'
const FIELD_INN = 'inn'
const FIELD_FIO = 'fio'
const FIELD_ORGANIZATION_NAME = 'name'
const FIELD_OGRNIP = 'ogrnip'
const FIELD_OGRN = 'ogrn'
const FIELD_ADDRESS = 'address'
const FIELD_DATE = 'registration_date'
const FIELD_BIK = 'bik'
const FIELD_BANK_NAME = 'bank_name'
const FIELD_COR_ACC = 'cor_acc'
const FIELD_CHECKING_ACC = 'checking_acc'
const FIELD_PASSPORT = 'passport'
const FIELD_LEGAL_REGISTRATION_CERTIFICATE = 'legal_registration_certificate'
const FIELD_BANK_ACC_DOC = 'bank_acc_doc'
const FIELD_RENT_CONTRACT = 'rent_contract'
const FIELD_CERTIFICATE = 'legal_certificate'
const FIELD_LEGAL_ENTITIES_STATE_REGISTER = 'legal_entities_state_register'
const FIELD_DECISION_APPOINTMENT = 'decision_appointment'
const FIELD_ORDER_APPOINTMENT = 'order_appointment'
const FIELD_SELLER_TYPE = 'seller_type'

const PHONE_REGEXP = /\+[1-9][0-9]{7,11}/
const EMAIL_REGEXP = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'

class SellerRegisterCmp extends React.Component<Props, StateSellerRegister> {
  constructor(props: Props) {
    super(props)

    const { sellerInfo } = props
    const cityId = sellerInfo?.city || ''

    this.state = {
      type: sellerInfo?.type || ESellerType.IP,
      phone: sellerInfo?.phone || '',
      password: sellerInfo?.password || '',
      repeatPassword: sellerInfo?.password || '',
      isSystem: sellerInfo?.isSystem || false,
      inn: sellerInfo?.inn || '',
      city: cityId,
      market: sellerInfo?.market || '',
      location: sellerInfo?.location || '',
      fio: sellerInfo?.fio || '',
      name: sellerInfo?.name || '',
      ogrnip: sellerInfo?.ogrnip || '',
      ogrn: sellerInfo?.ogrn || '',
      address: sellerInfo?.address || '',
      registrationDate:
        sellerInfo && sellerInfo.registrationDate ? moment(sellerInfo.registrationDate).format('YYYY-MM-DD') : '',
      email: sellerInfo?.email || '',
      bik: sellerInfo?.bik || '',
      bankName: sellerInfo?.bankName || '',
      corAcc: sellerInfo?.corAcc || '',
      checkingAcc: sellerInfo?.checkingAcc || '',
      changed: false,
      errors: [],
      fileLoadings: new Set(),
    }

    if (cityId) {
      this.props.getMarkets(cityId)
    }
  }

  componentDidMount(): void {
    this.props.getCities()
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    const { sellerInfo } = this.props

    if (
      (!sellerInfo && nextProps.sellerInfo) ||
      (sellerInfo && nextProps.sellerInfo && sellerInfo.inn !== nextProps.sellerInfo.inn)
    ) {
      const { registrationDate } = nextProps.sellerInfo

      this.setState({
        inn: nextProps.sellerInfo.inn || '',
        fio: nextProps.sellerInfo.fio || nextProps.sellerInfo.name || '',
        name: nextProps.sellerInfo.name || '',
        ogrnip: nextProps.sellerInfo.ogrnip || nextProps.sellerInfo.ogrn || '',
        ogrn: nextProps.sellerInfo.ogrn || '',
        address: nextProps.sellerInfo.address || '',
        registrationDate: moment(registrationDate || new Date()).format('YYYY-MM-DD'),
        fileLoadings: new Set(),
      })
    }

    if (
      (!sellerInfo && nextProps.sellerInfo) ||
      (sellerInfo && nextProps.sellerInfo && sellerInfo.bik !== nextProps.sellerInfo.bik)
    ) {
      this.setState({
        bik: nextProps.sellerInfo.bik || '',
        corAcc: nextProps.sellerInfo.corAcc || '',
        bankName: nextProps.sellerInfo.bankName || '',
      })
    }

    if (nextProps.sellerInfo && nextProps.sellerInfo.type && sellerInfo?.type !== nextProps.sellerInfo?.type) {
      this.setState({
        type: nextProps.sellerInfo.type,
      })
    }

    if (nextProps.sellerInfo?.passportUrl !== this.props.sellerInfo?.passportUrl) {
      this.changeLoading(FIELD_PASSPORT, true)
    }

    if (nextProps.sellerInfo?.bankAccDocUrl !== this.props.sellerInfo?.bankAccDocUrl) {
      this.changeLoading(FIELD_BANK_ACC_DOC, true)
    }

    if (nextProps.sellerInfo?.rentContractUrl !== this.props.sellerInfo?.rentContractUrl) {
      this.changeLoading(FIELD_RENT_CONTRACT, true)
    }

    if (nextProps.sellerInfo?.certificateUrl !== this.props.sellerInfo?.certificateUrl) {
      this.changeLoading(FIELD_CERTIFICATE, true)
    }

    if (
      nextProps.sellerInfo?.legalRegistrationCertificateUrl !== this.props.sellerInfo?.legalRegistrationCertificateUrl
    ) {
      this.changeLoading(FIELD_LEGAL_REGISTRATION_CERTIFICATE, true)
    }

    if (nextProps.sellerInfo?.legalEntitiesStateRegisterUrl !== this.props.sellerInfo?.legalEntitiesStateRegisterUrl) {
      this.changeLoading(FIELD_LEGAL_ENTITIES_STATE_REGISTER, true)
    }

    if (nextProps.sellerInfo?.decisionAppointmentUrl !== this.props.sellerInfo?.decisionAppointmentUrl) {
      this.changeLoading(FIELD_DECISION_APPOINTMENT, true)
    }

    if (nextProps.sellerInfo?.orderAppointmentUrl !== this.props.sellerInfo?.orderAppointmentUrl) {
      this.changeLoading(FIELD_ORDER_APPOINTMENT, true)
    }

    return true
  }

  render() {
    const { changed } = this.state
    const { loading, sellerInfo, errorApi } = this.props

    return (
      <div className='seller-register'>
        <div className='seller-register-title'>Регистрация продавца</div>
        <Form size='large'>
          {errorApi && this.renderErrorMessage()}

          {this.renderUserInfo()}
          {this.renderSellerLocation()}
          {this.renderOrganizationInfo()}
          {this.renderBankInfo()}
          {this.renderDocsInfo()}

          <div className='seller-register__actions'>
            {changed && (
              <Button
                className='seller-register__actions-btn'
                loading={loading}
                disabled={loading}
                color='blue'
                size='large'
                onClick={this.handleSave}
              >
                Сохранить
              </Button>
            )}
            {!changed && sellerInfo && sellerInfo.id && (
              <Button
                className='seller-register__actions-btn'
                loading={loading}
                disabled={loading}
                color='teal'
                size='large'
                onClick={this.handleSubmit}
              >
                Перенести в БД
              </Button>
            )}
          </div>
        </Form>
      </div>
    )
  }

  changeLoading = (key: string, remove?: boolean) => {
    const { fileLoadings } = this.state
    const loadings = new Set(fileLoadings)

    if (remove) {
      loadings.delete(key)
    } else {
      loadings.add(key)
    }

    this.setState({ fileLoadings: loadings })
  }

  renderErrorMessage = () => {
    const { errorApi } = this.props

    return (
      <Message negative>
        <Message.Content>
          <Message.Header>Ошибка</Message.Header>
          {errorApi}
        </Message.Content>
      </Message>
    )
  }

  renderPhone = () => {
    const { phone } = this.state
    const error = this.checkError(FIELD_PHONE)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Телефон:</div>
        <Form.Input
          disabled={this.props.sellerInfo?.createdUserDb}
          value={phone}
          className='seller-register__field-value'
          name={FIELD_PHONE}
          icon='phone'
          iconPosition='left'
          placeholder='+79123456789'
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderEmail = () => {
    const { email } = this.state
    const error = this.checkError(FIELD_EMAIL)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Email:</div>
        <Form.Input
          value={email}
          className='seller-register__field-value'
          name={FIELD_EMAIL}
          icon='mail'
          iconPosition='left'
          placeholder='mail@mail.com'
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderPassword = () => {
    const { password } = this.state
    const error = this.checkError(FIELD_PASSWORD)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Пароль:</div>
        <Form.Input
          disabled={this.props.sellerInfo?.createdUserDb}
          value={password}
          className='seller-register__field-value'
          name={FIELD_PASSWORD}
          icon='lock open'
          iconPosition='left'
          type='password'
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderRepeatPassword = () => {
    const { repeatPassword } = this.state
    const error = this.checkError(FIELD_REPEAT_PASSWORD)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Повторите пароль:</div>
        <Form.Input
          disabled={this.props.sellerInfo?.createdUserDb}
          value={repeatPassword}
          className='seller-register__field-value'
          name={FIELD_REPEAT_PASSWORD}
          icon='lock'
          iconPosition='left'
          type='password'
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderIsSystem = () => {
    const { isSystem } = this.state

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Системный продавец:</div>
        <Form.Checkbox
          className='seller-register__field-checkbox'
          disabled={this.props.sellerInfo?.createdUserDb}
          name={FIELD_IS_SYSTEM}
          checked={isSystem}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderINN = () => {
    const { loading } = this.props
    const { inn } = this.state
    const error = this.checkError(FIELD_INN)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>ИНН:</div>
        <Form.Input
          className='seller-register__field-value'
          value={inn}
          name={FIELD_INN}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        <div className='seller-register__field-btn'>
          <Button loading={loading} disabled={loading} color='blue' size='medium' onClick={this.handleInn}>
            Получить данные
          </Button>
        </div>
      </div>
    )
  }

  renderFIO = () => {
    const { fio } = this.state
    const error = this.checkError(FIELD_FIO)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>ФИО (для ИП):</div>
        <Form.Input
          className='seller-register__field-value'
          value={fio}
          name={FIELD_FIO}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderOrganizationName = () => {
    const { name } = this.state
    const error = this.checkError(FIELD_ORGANIZATION_NAME)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Название организации (для ООО):</div>
        <Form.Input
          className='seller-register__field-value'
          value={name}
          name={FIELD_ORGANIZATION_NAME}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderOGRN = () => {
    const { ogrn } = this.state
    const error = this.checkError(FIELD_OGRN)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>ОГРН (для ООО):</div>
        <Form.Input
          className='seller-register__field-value'
          value={ogrn}
          name={FIELD_OGRN}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderOGRNIP = () => {
    const { ogrnip } = this.state
    const error = this.checkError(FIELD_OGRNIP)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>ОГРНИП (для ИП):</div>
        <Form.Input
          className='seller-register__field-value'
          value={ogrnip}
          name={FIELD_OGRNIP}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderAddress = () => {
    const { address } = this.state
    const error = this.checkError(FIELD_ADDRESS)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Юридический адрес:</div>
        <Form.Input
          className='seller-register__field-value'
          value={address}
          name={FIELD_ADDRESS}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderRegistrationDate = () => {
    const { registrationDate } = this.state
    const error = this.checkError(FIELD_DATE)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Дата регистрации:</div>
        <Form.Input
          className='seller-register__field-value'
          value={registrationDate}
          name={FIELD_DATE}
          type='date'
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderCity = () => {
    const { cities } = this.props
    const { city } = this.state

    const options = cities.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Город:</div>
        <Dropdown value={city} name={FIELD_CITY} search selection options={options} onChange={this.handleChange} />
      </div>
    )
  }

  renderMarket = () => {
    const { markets } = this.props
    const { market } = this.state

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Рынок:</div>
        <Dropdown value={market} name={FIELD_MARKET} search selection options={options} onChange={this.handleChange} />
      </div>
    )
  }

  renderLocation = () => {
    const { location } = this.state
    const error = this.checkError(FIELD_LOCATION)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Место на рынке:</div>
        <Form.Input
          className='seller-register__field-value'
          value={location}
          name={FIELD_LOCATION}
          icon='map marker alternate'
          iconPosition='left'
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderUserInfo = () => {
    return (
      <div className='seller-register__item'>
        <div className='seller-register__item-title'>Информация о пользователе</div>
        {this.props.sellerInfo?.createdUserDb && (
          <Message positive>
            <Message.Content>
              <Message.Header>Успешно</Message.Header>
              Пользователь создан
            </Message.Content>
          </Message>
        )}
        {this.renderPhone()}
        {/*{this.renderEmail()}*/}
        {this.renderPassword()}
        {this.renderRepeatPassword()}
        {this.renderIsSystem()}
      </div>
    )
  }

  renderSellerLocation = () => {
    const { city, market } = this.state

    return (
      <div className='seller-register__item'>
        <div className='seller-register__item-title'>Информация о местоположении</div>
        {this.renderCity()}
        {city && this.renderMarket()}
        {market && this.renderLocation()}
      </div>
    )
  }

  renderOrganizationInfo = () => {
    const { type } = this.state

    return (
      <div className='seller-register__item'>
        <div className='seller-register__item-title'>
          Реквизиты компании {` (${type === ESellerType.IP ? ESellerType.IP : ESellerType.OOO})`}
        </div>
        {this.renderType()}
        {this.renderINN()}
        {type === ESellerType.IP && this.renderFIO()}
        {type === ESellerType.OOO && this.renderOrganizationName()}
        {type === ESellerType.IP && this.renderOGRNIP()}
        {type === ESellerType.OOO && this.renderOGRN()}
        {this.renderAddress()}
        {this.renderRegistrationDate()}
        {this.renderEmail()}
      </div>
    )
  }

  renderType() {
    return (
      <div className='seller-register__field'>
        <Radio
          label='ИП'
          name={FIELD_SELLER_TYPE}
          value={ESellerType.IP}
          checked={this.state.type === ESellerType.IP}
          onChange={this.handleChange}
        />
        <Radio
          className='seller-register__field-radio'
          label='ООО'
          name={FIELD_SELLER_TYPE}
          value={ESellerType.OOO}
          checked={this.state.type === ESellerType.OOO}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  renderBankInfo = () => {
    return (
      <div className='seller-register__item'>
        <div className='seller-register__item-title'>Банковские реквизиты</div>
        {this.renderBIK()}
        {this.renderBankName()}
        {this.renderCorAcc()}
        {this.renderCheckingAcc()}
      </div>
    )
  }

  renderBIK = () => {
    const { loading } = this.props
    const { bik } = this.state
    const error = this.checkError(FIELD_BIK)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>БИК:</div>
        <Form.Input
          className='seller-register__field-value'
          value={bik}
          name={FIELD_BIK}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        <div className='seller-register__field-btn'>
          <Button loading={loading} disabled={loading} color='blue' size='medium' onClick={this.handleBIK}>
            Получить данные
          </Button>
        </div>
      </div>
    )
  }

  renderBankName = () => {
    const { bankName } = this.state
    const error = this.checkError(FIELD_BANK_NAME)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Наименование банка:</div>
        <Form.Input
          className='seller-register__field-value'
          value={bankName}
          name={FIELD_BANK_NAME}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderCorAcc = () => {
    const { corAcc } = this.state
    const error = this.checkError(FIELD_COR_ACC)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Кор/счет:</div>
        <Form.Input
          className='seller-register__field-value'
          value={corAcc}
          name={FIELD_COR_ACC}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderCheckingAcc = () => {
    const { checkingAcc } = this.state
    const error = this.checkError(FIELD_CHECKING_ACC)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Р/счет:</div>
        <Form.Input
          className='seller-register__field-value'
          value={checkingAcc}
          name={FIELD_CHECKING_ACC}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderDocsInfo = () => {
    const { type } = this.state

    return (
      <div className='seller-register__item'>
        <div className='seller-register__item-title'>
          Загрузка документов {`(${type === ESellerType.IP ? 'для ИП' : 'для ООО'})`}
        </div>
        {this.renderCertificate()}
        {type === ESellerType.IP && this.renderPassportDoc()}
        {type === ESellerType.IP && this.renderBankAccDoc()}
        {type === ESellerType.OOO && this.renderLegalRegistrationCertificate()}
        {type === ESellerType.OOO && this.renderLegalEntitiesStateRegister()}
        {type === ESellerType.OOO && this.renderDecisionAppointment()}
        {type === ESellerType.OOO && this.renderOrderAppointment()}
        {this.renderRentContract()}
      </div>
    )
  }

  renderLoader = () => {
    return (
      <div className='seller-register__loader'>
        <Loader active inline />
      </div>
    )
  }

  renderPassportDoc = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_PASSPORT)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Паспорт индивидуального предпринимателя:</div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_PASSPORT}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_PASSPORT) && this.renderLoader()}
        {sellerInfo && sellerInfo.passportUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.passportUrl}>{sellerInfo.passportUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  renderBankAccDoc = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_BANK_ACC_DOC)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Документ о наличии счета в банке (справка из банка):</div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_BANK_ACC_DOC}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_BANK_ACC_DOC) && this.renderLoader()}
        {sellerInfo && sellerInfo.bankAccDocUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.bankAccDocUrl}>{sellerInfo.bankAccDocUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  renderRentContract = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_RENT_CONTRACT)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>Договор аренды ТТ на данном рынке:</div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_RENT_CONTRACT}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_RENT_CONTRACT) && this.renderLoader()}
        {sellerInfo && sellerInfo.rentContractUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.rentContractUrl}>{sellerInfo.rentContractUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  renderCertificate = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_CERTIFICATE)
    const label =
      this.state.type === ESellerType.IP
        ? 'Свидетельство о государственной регистрации в качестве ИП:'
        : 'Свидетельство о постановке на учет в налоговом органе:'

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>{label}</div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_CERTIFICATE}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_CERTIFICATE) && this.renderLoader()}
        {sellerInfo && sellerInfo.certificateUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.certificateUrl}>{sellerInfo.certificateUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  renderLegalRegistrationCertificate = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_LEGAL_REGISTRATION_CERTIFICATE)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>
          Свидетельсво о регистрации юр. лица (для компаний зарегистрированных до 01.01.2017):
        </div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_LEGAL_REGISTRATION_CERTIFICATE}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_LEGAL_REGISTRATION_CERTIFICATE) && this.renderLoader()}
        {sellerInfo && sellerInfo.legalRegistrationCertificateUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.legalRegistrationCertificateUrl}>{sellerInfo.legalRegistrationCertificateUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  renderLegalEntitiesStateRegister = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_LEGAL_ENTITIES_STATE_REGISTER)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>
          Лист записи Единого государственного реестра юридических лиц (для компаний зарегистрированных после
          01.01.2017):
        </div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_LEGAL_ENTITIES_STATE_REGISTER}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_LEGAL_ENTITIES_STATE_REGISTER) && this.renderLoader()}
        {sellerInfo && sellerInfo.legalEntitiesStateRegisterUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.legalEntitiesStateRegisterUrl}>{sellerInfo.legalEntitiesStateRegisterUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  renderDecisionAppointment = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_DECISION_APPOINTMENT)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>
          Решение о назначении (избрании) единоличного исполнительного органа или Протокол общего собрания о
          (назначении) единоличного исполнительного органа:
        </div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_DECISION_APPOINTMENT}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_DECISION_APPOINTMENT) && this.renderLoader()}
        {sellerInfo && sellerInfo.decisionAppointmentUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.decisionAppointmentUrl}>{sellerInfo.decisionAppointmentUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  renderOrderAppointment = () => {
    const { sellerInfo } = this.props
    const { fileLoadings } = this.state
    const error = this.checkError(FIELD_ORDER_APPOINTMENT)

    return (
      <div className='seller-register__field'>
        <div className='seller-register__field-label'>
          Приказ о назначении (вступлении в должность) единоличного исполнительного органа:
        </div>
        <Form.Input
          type='file'
          className='seller-register__field-value'
          name={FIELD_ORDER_APPOINTMENT}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {fileLoadings.has(FIELD_ORDER_APPOINTMENT) && this.renderLoader()}
        {sellerInfo && sellerInfo.orderAppointmentUrl && (
          <div className='seller-register__field-url'>
            URL: {<a href={sellerInfo.orderAppointmentUrl}>{sellerInfo.orderAppointmentUrl}</a>}
          </div>
        )}
      </div>
    )
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data
    const { sellerInfo } = this.props

    if (this.props.errorApi) {
      this.props.dropApiError()
    }

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    this.changeLoading(data.name)

    switch (name) {
      case FIELD_PHONE: {
        let phoneValue = this.state.phone

        if (!phoneValue && value !== '+') {
          phoneValue += `+${value}`
        } else {
          phoneValue = value
        }

        this.setState({ phone: phoneValue, errors: this.removeError(FIELD_PHONE) })
        break
      }
      case FIELD_EMAIL:
        this.setState({ email: value, errors: this.removeError(FIELD_EMAIL) })
        break
      case FIELD_PASSWORD:
        this.setState({ password: value, errors: this.removeError(FIELD_PASSWORD) })
        break
      case FIELD_REPEAT_PASSWORD:
        this.setState({ repeatPassword: value, errors: this.removeError(FIELD_REPEAT_PASSWORD) })
        break
      case FIELD_IS_SYSTEM:
        this.setState({ isSystem: !this.state.isSystem, errors: this.removeError(FIELD_IS_SYSTEM) })
        break
      case FIELD_CITY:
        this.setState({ city: value, market: '', errors: this.removeError(FIELD_CITY) })
        this.props.getMarkets(value)
        break
      case FIELD_MARKET:
        this.setState({ market: value, location: '', errors: this.removeError(FIELD_MARKET) })
        break
      case FIELD_LOCATION:
        this.setState({ location: value, errors: this.removeError(FIELD_LOCATION) })
        break
      case FIELD_SELLER_TYPE:
        this.setState({ type: value, errors: this.removeError(FIELD_SELLER_TYPE) })
        break
      case FIELD_INN:
        this.setState({ inn: value, errors: this.removeError(FIELD_INN) })
        break
      case FIELD_FIO:
        this.setState({ fio: value, errors: this.removeError(FIELD_FIO) })
        break
      case FIELD_ORGANIZATION_NAME:
        this.setState({ name: value, errors: this.removeError(FIELD_ORGANIZATION_NAME) })
        break
      case FIELD_OGRN:
        this.setState({ ogrn: value, errors: this.removeError(FIELD_OGRN) })
        break
      case FIELD_OGRNIP:
        this.setState({ ogrnip: value, errors: this.removeError(FIELD_OGRNIP) })
        break
      case FIELD_ADDRESS:
        this.setState({ address: value, errors: this.removeError(FIELD_ADDRESS) })
        break
      case FIELD_BIK:
        this.setState({ bik: value, errors: this.removeError(FIELD_BIK) })
        break
      case FIELD_BANK_NAME:
        this.setState({ bankName: value, errors: this.removeError(FIELD_BANK_NAME) })
        break
      case FIELD_COR_ACC:
        this.setState({ corAcc: value, errors: this.removeError(FIELD_COR_ACC) })
        break
      case FIELD_CHECKING_ACC:
        this.setState({ checkingAcc: value, errors: this.removeError(FIELD_CHECKING_ACC) })
        break
      case FIELD_DATE:
        this.setState({ registrationDate: value, errors: this.removeError(FIELD_DATE) })
        break
      case FIELD_PASSPORT:
        this.props.uploadFile(event.target.files[0], EFileType.PASSPORT_IP, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_PASSPORT) })
        break
      case FIELD_LEGAL_REGISTRATION_CERTIFICATE:
        this.props.uploadFile(event.target.files[0], EFileType.LEGAL_REGISTRATION_CERTIFICATE, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_LEGAL_REGISTRATION_CERTIFICATE) })
        break
      case FIELD_CERTIFICATE:
        this.props.uploadFile(event.target.files[0], EFileType.CERTIFICATE, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_CERTIFICATE) })
        break
      case FIELD_LEGAL_ENTITIES_STATE_REGISTER:
        this.props.uploadFile(event.target.files[0], EFileType.LEGAL_ENTITIES_STATE_REGISTER, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_LEGAL_ENTITIES_STATE_REGISTER) })
        break
      case FIELD_DECISION_APPOINTMENT:
        this.props.uploadFile(event.target.files[0], EFileType.DECISION_APPOINTMENT, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_DECISION_APPOINTMENT) })
        break
      case FIELD_ORDER_APPOINTMENT:
        this.props.uploadFile(event.target.files[0], EFileType.ORDER_APPOINTMENT, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_ORDER_APPOINTMENT) })
        break
      case FIELD_BANK_ACC_DOC:
        this.props.uploadFile(event.target.files[0], EFileType.BANK_ACC_DOC, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_BANK_ACC_DOC) })
        break
      case FIELD_RENT_CONTRACT:
        this.props.uploadFile(event.target.files[0], EFileType.RENT_CONTRACT, sellerInfo?.id)
        this.setState({ errors: this.removeError(FIELD_RENT_CONTRACT) })
        break
    }
  }

  handleSave = (e: any) => {
    e.preventDefault()
    const {
      phone,
      email,
      password,
      repeatPassword,
      isSystem,
      city,
      market,
      location,
      type,
      inn,
      fio,
      name,
      ogrnip,
      ogrn,
      address,
      bik,
      bankName,
      corAcc,
      checkingAcc,
      registrationDate,
    } = this.state
    const { sellerInfo } = this.props
    const errors: FieldError[] = []

    if (!phone) {
      errors.push({ field: FIELD_PHONE, message: 'Это поле обязательно' })
    } else if (!phone.match(PHONE_REGEXP)) {
      errors.push({ field: FIELD_PHONE, message: 'Неверный формат' })
    }

    if (email && !email.match(EMAIL_REGEXP)) {
      errors.push({ field: FIELD_EMAIL, message: 'Неверный формат' })
    }

    if (!password) {
      errors.push({ field: FIELD_PASSWORD, message: 'Это поле обязательно' })
    } else if (password.length < 4 || password.length > 24) {
      errors.push({ field: FIELD_PASSWORD, message: 'Не менее 4 и не более 24 символов' })
    }

    if (!repeatPassword) {
      errors.push({ field: FIELD_REPEAT_PASSWORD, message: 'Это поле обязательно' })
    }

    if (password !== repeatPassword) {
      errors.push({ field: FIELD_REPEAT_PASSWORD, message: 'Пароли не совпадают' })
    }

    if (inn && inn.length > 12) {
      errors.push({ field: FIELD_INN, message: 'Не более 12 символов' })
    }

    if (ogrnip && ogrnip.length > 15) {
      errors.push({ field: FIELD_OGRNIP, message: 'Не более 15 символов' })
    }

    if (ogrn && inn.length > 13) {
      errors.push({ field: FIELD_OGRN, message: 'Не более 13 символов' })
    }

    if (bik && bik.length > 9) {
      errors.push({ field: FIELD_BIK, message: 'Не более 9 символов' })
    }

    if (corAcc && corAcc.length > 20) {
      errors.push({ field: FIELD_COR_ACC, message: 'Не более 20 символов' })
    }

    if (checkingAcc && checkingAcc.length > 20) {
      errors.push({ field: FIELD_CHECKING_ACC, message: 'Не более 20 символов' })
    }

    if (errors.length === 0) {
      const sellerInfoDate = convertSellerToApi({
        phone,
        password,
        isSystem,
        city,
        market,
        location,
        inn,
        fio,
        ogrnip,
        ogrn,
        address,
        email,
        bik,
        bankName,
        corAcc,
        checkingAcc,
        name,
        type,
        isHide: false,
        ...(registrationDate && { registrationDate: new Date(registrationDate) }),
        ...(sellerInfo && sellerInfo.certificateUrl && { certificateUrl: sellerInfo.certificateUrl }),
        ...(sellerInfo && sellerInfo.passportUrl && { passportUrl: sellerInfo.passportUrl }),
        ...(sellerInfo && sellerInfo.bankAccDocUrl && { bankAccDocUrl: sellerInfo.bankAccDocUrl }),
        ...(sellerInfo &&
          sellerInfo.legalRegistrationCertificateUrl && {
            legalRegistrationCertificateUrl: sellerInfo.legalRegistrationCertificateUrl,
          }),
        ...(sellerInfo &&
          sellerInfo.legalEntitiesStateRegisterUrl && {
            legalEntitiesStateRegisterUrl: sellerInfo.legalEntitiesStateRegisterUrl,
          }),
        ...(sellerInfo &&
          sellerInfo.decisionAppointmentUrl && { decisionAppointmentUrl: sellerInfo.decisionAppointmentUrl }),
        ...(sellerInfo && sellerInfo.orderAppointmentUrl && { orderAppointmentUrl: sellerInfo.orderAppointmentUrl }),
        ...(sellerInfo && sellerInfo.rentContractUrl && { rentContractUrl: sellerInfo.rentContractUrl }),
      })

      this.setState({ changed: false, errors: [] })

      if (sellerInfo && sellerInfo.id) {
        this.props.apiUpdateSellerInfo({
          id: sellerInfo.id,
          ...sellerInfoDate,
        })
      } else {
        this.props.apiCreateSellerInfo(sellerInfoDate)
      }
    } else {
      this.setState({ errors })
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    const {
      city,
      market,
      location,
      type,
      email,
      inn,
      fio,
      name,
      ogrnip,
      ogrn,
      address,
      bik,
      bankName,
      corAcc,
      checkingAcc,
      registrationDate,
    } = this.state
    const { sellerInfo } = this.props
    const errors: FieldError[] = []

    if (!city) {
      errors.push({ field: FIELD_CITY, message: 'Это поле обязательно' })
    }

    if (!market) {
      errors.push({ field: FIELD_MARKET, message: 'Это поле обязательно' })
    }

    if (!location) {
      errors.push({ field: FIELD_LOCATION, message: 'Это поле обязательно' })
    }

    if (!inn) {
      errors.push({ field: FIELD_INN, message: 'Это поле обязательно' })
    }

    if (!ogrnip && type === ESellerType.IP) {
      errors.push({ field: FIELD_OGRNIP, message: 'Это поле обязательно' })
    }

    if (!fio && type === ESellerType.IP) {
      errors.push({ field: FIELD_FIO, message: 'Это поле обязательно' })
    }

    if (!ogrn && type === ESellerType.OOO) {
      errors.push({ field: FIELD_OGRN, message: 'Это поле обязательно' })
    }

    if (!name && type === ESellerType.OOO) {
      errors.push({ field: FIELD_ORGANIZATION_NAME, message: 'Это поле обязательно' })
    }

    if (!address) {
      errors.push({ field: FIELD_ADDRESS, message: 'Это поле обязательно' })
    }

    if (!registrationDate) {
      errors.push({ field: FIELD_DATE, message: 'Это поле обязательно' })
    }

    if (!email) {
      errors.push({ field: FIELD_EMAIL, message: 'Это поле обязательно' })
    }

    if (!bik) {
      errors.push({ field: FIELD_BIK, message: 'Это поле обязательно' })
    }

    if (!bankName) {
      errors.push({ field: FIELD_BANK_NAME, message: 'Это поле обязательно' })
    }

    if (!corAcc) {
      errors.push({ field: FIELD_COR_ACC, message: 'Это поле обязательно' })
    }

    if (!checkingAcc) {
      errors.push({ field: FIELD_CHECKING_ACC, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.certificateUrl) {
      errors.push({ field: FIELD_CERTIFICATE, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.passportUrl && type === ESellerType.IP) {
      errors.push({ field: FIELD_PASSPORT, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.bankAccDocUrl && type === ESellerType.IP) {
      errors.push({ field: FIELD_BANK_ACC_DOC, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.legalRegistrationCertificateUrl && type === ESellerType.OOO) {
      errors.push({ field: FIELD_LEGAL_REGISTRATION_CERTIFICATE, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.legalEntitiesStateRegisterUrl && type === ESellerType.OOO) {
      errors.push({ field: FIELD_LEGAL_ENTITIES_STATE_REGISTER, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.decisionAppointmentUrl && type === ESellerType.OOO) {
      errors.push({ field: FIELD_DECISION_APPOINTMENT, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.orderAppointmentUrl && type === ESellerType.OOO) {
      errors.push({ field: FIELD_ORDER_APPOINTMENT, message: 'Это поле обязательно' })
    }

    if (sellerInfo && !sellerInfo.rentContractUrl) {
      errors.push({ field: FIELD_RENT_CONTRACT, message: 'Это поле обязательно' })
    }

    if (!errors.length && sellerInfo && sellerInfo.id) {
      this.props.apiSellerRegister(sellerInfo.id)
    } else {
      this.setState({ errors })
    }
  }

  handleInn = (e: any) => {
    e.preventDefault()
    const { inn } = this.state
    const { sellerInfo } = this.props
    const errors: FieldError[] = []

    if (!inn) {
      errors.push({ field: FIELD_INN, message: 'Это поле обязательно' })
    }

    if (errors.length === 0) {
      this.setState({ errors: [] })
      this.props.getPartnerInfo(inn, sellerInfo?.id)
    } else {
      this.setState({ errors })
    }
  }

  handleBIK = (e: any) => {
    e.preventDefault()
    const { bik } = this.state
    const { sellerInfo } = this.props
    const errors: FieldError[] = []

    if (!bik) {
      errors.push({ field: FIELD_BIK, message: 'Это поле обязательно' })
    }

    if (errors.length === 0) {
      this.setState({ errors: [] })
      this.props.getBankInfo(bik, sellerInfo?.id)
    } else {
      this.setState({ errors })
    }
  }
}

const mapStateToProps = (s: State, own: Props): TConnectedProps => {
  const { newSellerInfo, sellersInfoList, loading } = s.sellers
  const sellerInfo =
    own.match.params.id === LOCATION_NEW_SELLER
      ? newSellerInfo
      : sellersInfoList.find((s) => s.id === own.match.params.id)

  if (!sellerInfo && own.match.params.id !== LOCATION_NEW_SELLER) {
    own.history.push(`${LOCATION_SELLERS}`)
  }

  return {
    loading,
    sellerInfo,
    cities: s.markets.cities || [],
    markets: s.markets.markets || [],
    errorApi: s.sellers.errorApi,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getCities: () =>
    dispatch(
      Actions.action(Actions.API_CITIES, {
        include_all: 'True',
      }),
    ),
  getMarkets: (cityId: string) =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        city: cityId,
        include_all: 'True',
      }),
    ),
  getPartnerInfo: (inn: string, id?: string) =>
    dispatch(
      Actions.action(Actions.API_PARTNER_INFO, {
        id,
        query: inn,
      }),
    ),
  getBankInfo: (bik: string, id?: string) =>
    dispatch(
      Actions.action(Actions.API_BANK_INFO, {
        id,
        bik,
      }),
    ),
  uploadFile: (file: File, type: EFileType, id?: string) =>
    dispatch(
      Actions.action(Actions.API_UPLOAD_FILE, {
        file,
        type,
        id,
      }),
    ),
  apiCreateSellerInfo: (sellerInfo: ApiSellerInfoCreateReq) =>
    dispatch(Actions.action(Actions.API_CREATE_SELLER_INFO, sellerInfo)),
  apiUpdateSellerInfo: (sellerInfo: ApiSellerInfoUpdateReq) =>
    dispatch(Actions.action(Actions.API_UPDATE_SELLER_INFO, sellerInfo)),
  dropApiError: () => dispatch(Actions.actionEmpty(Actions.DROP_API_ERROR)),
  apiSellerRegister: (id: string) => dispatch(Actions.action(Actions.API_SELLER_REGISTER, { id })),
})

export const SellerRegister = connect(mapStateToProps, mapDispatchToProps)(SellerRegisterCmp)
