import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import { ISlotInfo } from '../../types/TClient'

import messages from '../../localization/messages'
import { Segment } from '../Segment'
import { SlotChart } from '../SlotChart'
import { AlertIcon } from '../Icons'

type TProps = {
  slot: ISlotInfo,
  changeActive(isActive: boolean): void,
} & WrappedComponentProps

const _SlotCard: React.FC<TProps> = ({ slot, changeActive, intl }) => {
  const { formatMessage } = intl

  return (
    <Segment className={classnames('slot-card')}>
      <div className='slot-card__header'>
        <div className='slot-card__header__slot'>
          {slot.timeStart.slice(0, -3)} — {slot.timeEnd.slice(0, -3)}
        </div>
        {slot.load >= 90 && (
          <div className='slot-card__header__message'>
            <div className='slot-card__header__message__icon'>
              <AlertIcon width={24} height={24} color='#D8553F' />
            </div>
            <div>{formatMessage(messages.SlotOverloaded)}</div>
          </div>
        )}
        <div className='slot-card__header__close' onClick={() => changeActive(!slot.isActive)}>
          {slot.isActive ? formatMessage(messages.CloseSlot) : formatMessage(messages.OpenSlot)}
        </div>
      </div>
      <div className='slot-card__content'>
        <div className='slot-card__content__left'>
          <SlotChart data={slot.load} disabled={!slot.isActive} title={formatMessage(messages.SlotLoad)} />
        </div>
        <div className='slot-card__content__right'>
          <div className='slot-card__content__right__list'>
            <div className='slot-card__content__right__list__title'>{formatMessage(messages.FactPlan)}</div>
            <div>
              {formatMessage(messages.CouriersSlot)}:{' '}
              <span className='slot-card__content__right__list__info'>
                {slot.couriersCount} / {slot.allCouriersCount}
              </span>
            </div>
            <div>
              {formatMessage(messages.PickersSlot)}:{' '}
              <span className='slot-card__content__right__list__info'>
                {slot.pickersCount} / {slot.allPickersCount}
              </span>
            </div>
            <div>
              {formatMessage(messages.ManagersSlot)}:{' '}
              <span className='slot-card__content__right__list__info'>{slot.managersCount}</span>
            </div>
          </div>
          <div className='slot-card__content__right__list'>
            <div className='slot-card__content__right__list__title'>{formatMessage(messages.Orders)}</div>
            <div>
              {formatMessage(messages.TotalOrders)}:{' '}
              <span className='slot-card__content__right__list__info'>{slot.ordersCount}</span>
            </div>
            <div>
              {formatMessage(messages.DownloadIndex)}:{' '}
              <span className='slot-card__content__right__list__info'>{slot.loadFactor}</span>
            </div>
          </div>
        </div>
      </div>
    </Segment>
  )
}

export const SlotCard = injectIntl(_SlotCard)
