import { forEach } from 'lodash'

import { TCountriesDictionary, TCountryWithPrefix } from '../types/TClient'
import { COUNTRY_ID } from '../types/countryId'

export const COUNTRY_PREFIX = 'CODE_'

export const addPrefix = (country: string) =>
  `${COUNTRY_PREFIX}${country}`.toUpperCase() as TCountryWithPrefix<typeof COUNTRY_PREFIX>

export const withPrefix = (dictionary: Record<COUNTRY_ID, string>): TCountriesDictionary<typeof COUNTRY_PREFIX> => {
  let result = {}

  forEach(dictionary, (value, key) => {
    result = {
      ...result,
      [addPrefix(key)]: value,
    }
  })

  return result as TCountriesDictionary<typeof COUNTRY_PREFIX>
}
