import React from 'react'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'

import './index.scss'

type TProps = {
  data: {
    name: string,
    value: number,
    color: string,
  }[],
}

export const AnalyticsPieChart: React.FC<TProps> = ({ data }) => {
  const colors = data.map((item) => item.color)
  const total = data.reduce((sum, item) => sum + item.value, 0)
  const chartData = data.map((item) => ({
    name: `${item.name} - ${+((item.value / total) * 100).toFixed(1)}%`,
    value: item.value,
  }))

  return (
    <PieChart width={202} height={202}>
      <Pie
        dataKey='value'
        data={chartData}
        cx={99}
        cy={99}
        innerRadius={52}
        outerRadius={98}
        paddingAngle={1}
        startAngle={-270}
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  )
}
