import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header } from 'semantic-ui-react'

import './index.scss'

import messages from '../../../../localization/messages'
import { Tabs, ITab } from '../../../../components/Tabs'
import { Couriers } from './Couriers'
import { SlotsMonitoring } from './SlotsMonitoring'
import { ShiftSchedule } from './ShiftSchedule'
import { CouriersRoutes } from './CouriersRoutes'
import { OrdersMap } from './OrdersMap'

enum ETabId {
  COURIERS = 'couriers',
  SLOT_LOAD = 'slotLoad',
  SHIFT_SCHEDULE = 'shiftSchedule',
  SHIFT_INDICATORS = 'shiftIndicators',
  COURIERS_ROUTES = 'couriersRoutes',
  ORDERS_MAP = 'ordersMap',
}

type TProps = WrappedComponentProps

class _Monitoring extends React.Component<TProps> {
  render() {
    const { formatMessage } = this.props.intl
    const tabsData: ITab[] = [
      { name: formatMessage(messages.CollectorsAndCouriers), id: ETabId.COURIERS, component: <Couriers /> },
      { name: formatMessage(messages.SlotsLoad), id: ETabId.SLOT_LOAD, component: <SlotsMonitoring /> },
      { name: formatMessage(messages.ShiftSchedule), id: ETabId.SHIFT_SCHEDULE, component: <ShiftSchedule /> },
      { name: formatMessage(messages.ShiftIndicators), id: ETabId.SHIFT_INDICATORS, disabled: true },
      { name: formatMessage(messages.CourierRoutes), id: ETabId.COURIERS_ROUTES, component: <CouriersRoutes /> },
      { name: formatMessage(messages.OrdersMap), id: ETabId.ORDERS_MAP, component: <OrdersMap /> },
    ]

    return (
      <div className='monitoring'>
        <Header as='h1'>{formatMessage(messages.Monitoring)}</Header>
        <Tabs tabs={tabsData} />
      </div>
    )
  }
}

export const Monitoring = injectIntl(_Monitoring)
