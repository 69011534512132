import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Form, Icon, Input } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { EOrderStatus, EPaymentType, IOrder, IProductOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { ModalConfirm } from '../../Modal/ModalConfirm'
import { ArrowRightIcon } from '../../Icons'
import { formatPrice, sourcePrice, UNIT_PCS, unitIntl } from '../../../utils/productsUtils'
import { countryLocalization } from '../../../utils/langUtils'

type TOwnProps = {
  order: IOrder,
  product: IProductOrder,
  byCustomer?: boolean,
  disabled?: boolean,
}

type TDispatchedProps = {
  apiChangeProduct(data: { orderId: string, itemId: string, quantity: number }): Actions.Action,
}

type TProps = TOwnProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type TState = {
  active: boolean,
  quantity: number,
  removeProductModal: boolean,
  errors: FieldError[],
}

const FIELD_QUANTITY = 'quantity'

class OrderProductCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      active: false,
      quantity: props.product.quantity,
      removeProductModal: false,
      errors: [],
    }
  }

  render() {
    const { product, byCustomer } = this.props

    return (
      <div key={product.id} className={classnames('order-product', byCustomer && 'order-product__customer')}>
        {this.renderRemoveProductModal()}
        <div className='order-product__image' style={{ backgroundImage: `url(${product.image})` }} />
        <div className='order-product__content'>
          <div className='order-product__info'>
            <div className='order-product__info-item'>
              <div className='order-product__name'>{product.name}</div>
              <div className='order-product__country'>{countryLocalization(product.country)}</div>
            </div>
            <div>
              <div className='order-product__price'>
                {formatPrice(product.price * product.quantity, product.currency)}
                {product.sellerIsCommon && (
                  <div className='order-product__source-price'>
                    (
                    {formatPrice(
                      sourcePrice(product.price * product.quantity, product.sellerCommission),
                      product.currency,
                    )}
                    )
                  </div>
                )}
              </div>
              <div className='order-product__unit'>
                {formatPrice(product.price, product.currency)}
                <div className='order-product__unit-label'>/{unitIntl(product.unit)}</div>
              </div>
            </div>
          </div>
          {this.renderQuantity()}
        </div>
      </div>
    )
  }

  renderQuantity = () => {
    const { product, disabled, order } = this.props
    const { errors, active, quantity } = this.state
    const editable = !disabled && this.isChangeProducts(order.statusId, product.quantity)
    const error = errors.length > 0 ? errors[0] : null

    return (
      <div className='order-product__quantity'>
        {editable ? (
          active ? (
            <Form className='order-product__form'>
              <Input
                label={{ content: unitIntl(product.unit) }}
                labelPosition='right'
                className='order-product__input'
                value={quantity}
                disabled={!editable}
                onChange={this.changeQuantity}
              />
              <div
                className='order-product__btn'
                onClick={editable ? () => this.updateProduct() : undefined}
                style={{
                  backgroundColor: editable ? '#7677bc' : '#a0a3b5',
                  marginLeft: '44px',
                  borderColor: !editable ? '#a0a3b5' : undefined,
                }}
              >
                <Icon name='check' className='order-product__btn-icon' style={{ color: '#fff' }} />
              </div>
              <div className='order-product__btn' onClick={() => this.setState({ active: false })}>
                <Icon name='close' className='order-product__btn-icon' style={{ color: '#7677bc' }} />
              </div>
              <div
                className='order-product__btn'
                onClick={() => this.setState({ removeProductModal: true })}
                style={{ borderColor: '#a0a3b5' }}
              >
                <Icon name='trash alternate outline' className='order-product__btn-icon' style={{ color: '#a0a3b5' }} />
              </div>
            </Form>
          ) : (
            <div className='order-product__weight'>
              {product.quantityInitial && product.quantityInitial !== product.quantity && (
                <>
                  {product.quantityInitial} {unitIntl(product.unit)}
                  <div className='order-product__weight-icon'>
                    <ArrowRightIcon color='#bec2cd' />
                  </div>
                </>
              )}
              {product.quantity} {unitIntl(product.unit)}
              {editable && this.renderEditBtn(this.activeChangeQuantity)}
            </div>
          )
        ) : (
          <div className='order-product__weight'>
            {product.quantityInitial && product.quantityInitial !== product.quantity && (
              <>
                {product.quantityInitial} {unitIntl(product.unit)}
                <div className='order-product__weight-icon'>
                  <ArrowRightIcon color='#bec2cd' />
                </div>
              </>
            )}
            {product.quantity} {unitIntl(product.unit)}
          </div>
        )}
        {error && active && <div className='order-product__error'>{error.message}</div>}
      </div>
    )
  }

  renderEditBtn = (action: () => any) => {
    return (
      <div className='order-product__edit-btn' onClick={action}>
        <Icon className='order-product__edit-btn__icon' name='pencil alternate' />
      </div>
    )
  }

  renderRemoveProductModal = () => {
    const { formatMessage } = this.props.intl
    const { removeProductModal } = this.state

    return (
      <ModalConfirm
        alert
        onClose={() => this.setState({ removeProductModal: false })}
        onOk={() => this.updateProduct(true)}
        open={removeProductModal}
        title={formatMessage(messages.SureRemoveItemFromOrder)}
        text={formatMessage(messages.NotCancelRemoveItemOrder)}
      />
    )
  }

  paymentStatus = (order: IOrder) => {
    const { formatMessage } = this.props.intl

    if (order.paymentType === EPaymentType.BY_CARD) {
      return order.paid
        ? order.statusId === EOrderStatus.CANCELED
          ? formatMessage(messages.Refund)
          : formatMessage(messages.Paid)
        : formatMessage(messages.NotPaid)
    }
  }

  isChangeProducts = (statusId: EOrderStatus, quantity: number) => {
    return (
      (statusId === EOrderStatus.NEW || statusId === EOrderStatus.COURIER || statusId === EOrderStatus.IN_ASSEMBLY) &&
      (quantity > 0 || this.props.order.paymentType === EPaymentType.CASH)
    )
  }

  activeChangeQuantity = () => {
    this.setState({ active: true, quantity: this.props.product.quantity, errors: [] })
  }

  changeQuantity = (event: any) => {
    const { value } = event.target

    this.setState({ quantity: value, errors: [] })
  }

  updateProduct = (remove = false) => {
    const { order, product } = this.props
    const { formatMessage } = this.props.intl
    const { quantity } = this.state
    const errors: FieldError[] = []

    if (remove) {
      this.props.apiChangeProduct({
        orderId: order.id,
        quantity: 0,
        itemId: product.itemId,
      })

      this.setState({ active: false, removeProductModal: false })
    }

    if (Number.isNaN(+quantity)) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.ValueMustNumber) })
    }

    if (
      quantity > product.quantity &&
      order.paymentType === EPaymentType.BY_CARD &&
      order.statusId >= EOrderStatus.COURIER
    ) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.QuantityItemMustLess) })
    }

    if (product.unit === UNIT_PCS && !Number.isInteger(+quantity)) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.QuantityPcsMustInteger) })
    }

    if (!errors.length) {
      this.props.apiChangeProduct({
        orderId: order.id,
        quantity: this.state.quantity,
        itemId: product.itemId,
      })

      this.setState({ active: false })
    } else {
      this.setState({ errors })
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiChangeProduct: (data: { orderId: string, itemId: string, quantity: number }) =>
    dispatch(Actions.action(Actions.ORDER_CHANGE_PRODUCT, data)),
})

export const OrderProduct = connect(null, mapDispatchToProps)(injectIntl(OrderProductCmp))
