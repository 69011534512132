import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Icon } from 'semantic-ui-react'
import moment from 'moment'
import classnames from 'classnames'
import { uniqBy } from 'lodash'

import './index.scss'

import { EOrderStatus, IOrder, IProductOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { EModalType, IModalAddProductOrder } from '../../../store/reducers/modals'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { OrderProduct } from '../OrderProduct'
import { OrderDivider } from '../OrderDivider'
import { localeWeight } from '../../../utils/ordersUtils'
import { formatPrice } from '../../../utils/productsUtils'

type TSellerOrder = {
  id: string,
  isCommon: boolean,
  name: string,
  added?: boolean,
  addedTime?: Date,
  aboutSeller?: string,
}

type TOwnProps = {
  order: IOrder,
  byCustomer?: boolean,
  disabled?: boolean,
}

type TConnectedProps = {
  sellersOrder: TSellerOrder[],
}

type TDispatchedProps = {
  addProductOrderModal(data: IModalAddProductOrder): Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

class OrderProductsCmp extends React.Component<TProps> {
  render() {
    const { order, sellersOrder, disabled } = this.props
    const { formatMessage } = this.props.intl
    const { weightGr } = order
    const isEditable = !disabled && order.statusId < EOrderStatus.DELIVERED

    return (
      <div className='order__products'>
        <div className='order__block-title'>{formatMessage(messages.OrderList)}</div>
        {!!weightGr && (
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.OrderWeight)}</div>
            <div className='order__block-text'>{localeWeight(weightGr)}</div>
          </div>
        )}
        <OrderDivider />
        <div>
          {sellersOrder.map((seller, i) =>
            this.renderSeller(seller, sellersOrder.length > 1 && i !== sellersOrder.length - 1),
          )}
        </div>
        {isEditable && (
          <div className='order__products-action'>
            <Button className='order__btn' color='blue' onClick={this.addProduct} disabled={order.isPicked}>
              {formatMessage(messages.AddProducts)}
            </Button>
          </div>
        )}
      </div>
    )
  }

  renderSeller = (seller: TSellerOrder, divider = false) => {
    const { order } = this.props
    const { formatMessage } = this.props.intl

    if (!order) {
      return null
    }

    const products = order.items.filter((item) => {
      if (seller.addedTime) {
        return (
          item.sellerId === seller.id &&
          item.aboutSeller === seller.aboutSeller &&
          moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss') ===
            moment(seller.addedTime).format('DD.MM.YYYY HH:mm:ss')
        )
      }

      if (seller.aboutSeller) {
        return item.sellerId === seller.id && item.aboutSeller === seller.aboutSeller
      }

      return item.sellerId === seller.id && !item.aboutSeller
    })

    let totalPriceSeller = 0
    let organisationName = ''
    const currency = products.length ? products[0].currency : undefined

    products.forEach((item) => {
      totalPriceSeller += item.totalPrice * item.quantity

      if (item.organisationName) {
        organisationName = item.organisationName
      }
    })

    const key =
      seller.id +
      (seller.aboutSeller || '') +
      (seller.addedTime ? moment(seller.addedTime).format('DD.MM.YYYY HH:mm:ss') : '')

    return (
      <div key={key}>
        {seller.added && (
          <div className='order__seller-added'>
            <div className='order__seller-added__title'>{formatMessage(messages.AdditionalOrder)}</div>
            {seller.addedTime !== undefined && (
              <>
                <div className='order__block-dot' />
                <div className='order__seller-added__time'>{moment(seller.addedTime).format('DD.MM.YYYY HH:mm')}</div>
              </>
            )}
          </div>
        )}
        <div className='order__seller-name'>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.Seller)}</div>
            <div className='order__block-text'>
              {seller.name || formatMessage(messages.NoSeller)} {organisationName ? `(${organisationName})` : ''}
              {seller.isCommon && <Icon className='order__seller-name__icon' name='home' />}
            </div>
          </div>
        </div>
        <div className='order__block-row'>
          {seller.aboutSeller && (
            <div className={classnames('order__block-item', 'order__block-padding')}>
              <div className='order__block-label'>{formatMessage(messages.Place)}</div>
              <div className='order__block-text'>{seller.aboutSeller}</div>
            </div>
          )}
          <div className={classnames('order__block-item', 'order__block-padding')}>
            <div className='order__block-label'>{formatMessage(messages.SettlementAmount)}</div>
            <div className='order__block-text'>{formatPrice(totalPriceSeller, currency)}</div>
          </div>
        </div>
        <div className='order__seller-products'>{products.map(this.renderProduct)}</div>
        {divider && <OrderDivider />}
      </div>
    )
  }

  renderProduct = (product: IProductOrder) => {
    const { order, disabled } = this.props
    const isDisabled = disabled || order.statusId > EOrderStatus.COURIER

    return (
      <OrderProduct
        key={product.id || product.itemId}
        product={product}
        order={order}
        byCustomer
        disabled={isDisabled}
      />
    )
  }

  addProduct = () => {
    const { order } = this.props

    this.props.addProductOrderModal({
      type: EModalType.MODAL_ADD_PRODUCT_ORDER,
      size: 'mini',
      style: { width: '980px', maxWidth: '98%', borderRadius: '16px' },
      props: {
        marketId: order.marketId,
        orderId: order.id,
      },
    })
  }
}

const mapStateToProps = (_s: State, own: TOwnProps): TConnectedProps => {
  const { order } = own
  const { items } = order
  let sellers: TSellerOrder[] = []

  items.forEach((item) => {
    sellers = uniqBy(
      [
        {
          id: item.sellerId || '',
          isCommon: item.sellerIsCommon || false,
          name: item.sellerName || '',
          added: item.added,
          addedTime: item.createdAt,
          ...(item.aboutSeller && { aboutSeller: item.aboutSeller }),
        },
        ...sellers,
      ],
      (el) =>
        el.id +
        (el.aboutSeller || '') +
        (el.added?.toString() || '') +
        (el.addedTime ? moment(el.addedTime).format('DD.MM.YYYY HH:mm:ss') : ''),
    )
  })

  sellers.sort((s1, s2) => {
    const { addedTime: addedTime1 } = s1
    const { addedTime: addedTime2 } = s2
    const added1 = s1.added ? 1 : 0
    const added2 = s2.added ? 1 : 0

    if (added1 !== added2) {
      return added1 - added2
    } else if (addedTime1 !== addedTime2) {
      return moment(addedTime1).diff(moment(addedTime2), 'minute')
    }

    return -1
  })

  return {
    sellersOrder: sellers,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  addProductOrderModal: (data: IModalAddProductOrder) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
})

export const OrderProducts = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderProductsCmp))
