import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import { IMarket, IProduct, IProductOrder, ISellerCategory, ISeller } from '../../types/TClient'
import { ApiMarketProductsReq } from '../../types/TApi'

import messages from '../../localization/messages'
import * as Actions from '../../store/actions'
import { State } from '../../store/reducer'
import { ProductItem } from '../ProductItem'
import { SellerItem } from '../SellerItem'
import { ProductCard } from '../ProductCard'
import { convertProductToProductSeller, sortMarketProductBySeller } from '../../utils/productsUtils'

type TOwnProps = {
  market: IMarket,
  seller: ISeller,
  orderId?: string,
}

type TConnectedProps = {
  loadingSellerProducts: boolean,
  sellerProducts: IProduct[],
  preAddProducts: IProductOrder[],
}

type TDispatchedProps = {
  apiMarketProducts: (data: ApiMarketProductsReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  selectCategory: string,
}

const PRODUCTS_LIMIT = 10

class SellerProductsCmp extends React.Component<TProps, TState> {
  private productsRef = React.createRef<HTMLDivElement>()

  constructor(props: TProps) {
    super(props)

    this.state = {
      selectCategory: '',
    }
  }

  componentDidMount(): void {
    this.getSellerProducts(0)
  }

  render() {
    const { seller, orderId } = this.props

    return (
      <div className={classnames('seller-products', orderId && 'seller-products__modal')}>
        <div className='seller-products__content'>
          <SellerItem disabled seller={seller} byProduct />
        </div>
        {this.renderCategories()}
        {this.renderProducts()}
      </div>
    )
  }

  renderCategories = () => {
    const { seller } = this.props
    const { formatMessage } = this.props.intl
    const { selectCategory } = this.state
    const options = [{ id: '', name: formatMessage(messages.All) }, ...seller.categories]

    if (seller.categories.length === 0) {
      return
    }

    return (
      <div className='seller-products__categories'>
        {options.map((item) => (
          <div
            key={item.id}
            className={classnames(
              'seller-products__categories__category',
              selectCategory === item.id && 'seller-products__categories__category-active',
            )}
            onClick={() => this.selectSellersCategory(item)}
          >
            {item.name}
          </div>
        ))}
      </div>
    )
  }

  renderProducts = () => {
    const { sellerProducts } = this.props

    return (
      <div className='seller-products__products' ref={this.productsRef} onScroll={this.checkEndProducts}>
        {sellerProducts.map(this.renderProduct)}
      </div>
    )
  }

  renderProduct = (product: IProduct, index: number) => {
    const { orderId, sellerProducts, preAddProducts } = this.props
    const { selectCategory } = this.state

    const pre = index > 0 ? sellerProducts[index - 1] : null
    const renderSubcategory = pre
      ? selectCategory
        ? pre.subcategoryId !== product.subcategoryId
        : pre.categoryId !== product.categoryId
      : true
    const isPreAdd = preAddProducts.find((item) => item.id === product.id)
    const productOrder = isPreAdd || {
      ...product,
      itemId: '0',
      image: product.imageUrl,
      quantity: 0,
      quantityInitial: 0,
      market: product.marketId,
    }

    return (
      <React.Fragment key={product.id}>
        {renderSubcategory && (
          <div className='seller-products__subcategory-header'>
            <div className='seller-products__subcategory-header-text'>
              {selectCategory ? product.subcategory : product.category}
            </div>
          </div>
        )}
        {orderId ? (
          <ProductItem editable preAdd orderId={orderId} product={productOrder} />
        ) : (
          <ProductCard
            editable
            key={product.id}
            product={convertProductToProductSeller(product)}
            marketProduct={product}
          />
        )}
      </React.Fragment>
    )
  }

  checkEndProducts = () => {
    const { sellerProducts, loadingSellerProducts } = this.props

    if (this.productsRef.current) {
      const { scrollHeight, offsetHeight, scrollTop } = this.productsRef.current

      if (
        !loadingSellerProducts &&
        sellerProducts.length &&
        sellerProducts.length % PRODUCTS_LIMIT === 0 &&
        scrollHeight - offsetHeight - scrollTop < 300
      ) {
        this.getSellerProducts(sellerProducts.length)
      }
    }
  }

  selectSellersCategory = (item: ISellerCategory) => {
    this.setState({ selectCategory: item.id }, () => this.getSellerProducts(0))
  }

  getSellerProducts = (offset: number) => {
    const { market, seller, orderId } = this.props
    const { selectCategory } = this.state

    this.props.apiMarketProducts({
      offset,
      market: market.id,
      seller: seller.id,
      ...(orderId && { notInOrder: orderId }),
      ...(orderId && { hidden: false }),
      ...(selectCategory && { category: selectCategory }),
    })
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { loadingMarketProducts, marketProducts, preAddProducts } = s.products

  const products = (marketProducts || []).filter((item) => item.sellerId === own.seller.id)

  products.sort(sortMarketProductBySeller)

  return {
    preAddProducts: preAddProducts.filter((item) => item.sellerId === own.seller.id),
    loadingSellerProducts: loadingMarketProducts,
    sellerProducts: products,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiMarketProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
})

export const SellerProducts = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SellerProductsCmp))
