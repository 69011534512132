import { EAuditory, IReviewOrder, MarketingPushType } from './TApi'
import { EInputType } from '../App/pages/Home/Market/Slots/Slot'
import { COUNTRY_ID } from './countryId'

export enum EUserRole {
  ADMIN = 1,
  MANAGER = 2,
  OPERATOR = 3,
}

export interface IUser {
  email: string,
  login: string,
  role: EUserRole,
}

export interface IClientProfile {
  id: number,
  phone: string,
}

export type TDeliveryArea = {
  id: number,
  index: string,
}

export type OrderHistoryMap = Map<string, TLogAction[]>

export interface IOrder {
  id: string,
  num: number,
  price: number,
  paymentType: EPaymentType,
  paid: boolean,
  web: boolean,
  paymentUrl?: string,
  isCollected: boolean,
  createdAt: Date,
  items: IProductOrder[],
  selfPickup: boolean,
  orderConfirmed: boolean,
  statusId: EOrderStatus,
  statusName: string,
  userId: number,
  userPhone: string,
  userBusiness?: boolean,
  userFirstName?: string,
  userLastName?: string,
  userCallMe?: boolean,
  userCallAboutChangeProduct?: boolean,
  userCallAboutDeleteProduct?: boolean,
  userCallAboutChangeProductWeight?: boolean,
  userCallChangeProduct?: boolean,
  userCallDeleteProductByWeight?: boolean,
  courierId?: string,
  courierPhone?: string,
  courierFirstName?: string,
  courierLastName?: string,
  collectorId?: string,
  collectorPhone?: string,
  collectorFirstName?: string,
  collectorLastName?: string,
  marketName: string,
  marketId: string,
  completedAt?: Date,
  marketAddress?: string,
  marketOpeningHours?: string,
  marketClosingHours?: string,
  marketSelfPickupStartTime: string,
  marketSelfPickupEndTime: string,
  marketDeliveryStartTime: string,
  marketDeliveryEndTime: string,
  isPicked: boolean,
  pickerFirstName?: string,
  pickerLastName?: string,
  pickerId?: string,
  pickerPhone?: string,
  updatedAt?: Date,
  weightKg?: number,
  weightGr?: number,
  deliveryPrice?: number,
  deliveryAddress?: string,
  deliveryDate?: Date,
  deliveryTime?: string,
  deliveryEndTime?: string,
  deliveryDistance?: number,
  deliveryLat?: number,
  deliveryLon?: number,
  comment?: string,
  commentHide?: string,
  city?: string,
  cityTz?: string,
  assemblyAt?: Date,
  courierAt?: Date,
  deliveryAt?: Date,
  canceledAt?: Date,
  collectedAt?: Date,
  collectedLate: number,
  deliveryLate: number,
  deliveryEndLate: number,
  review?: IReviewOrder,
  extPaid?: boolean,
  extPaymentUrl?: string,
  deliveryType?: ESlotType,
  deliveryArea?: TDeliveryArea,
}

export interface IOrderByMap {
  deliveryAddress: string,
  deliveryLat: number,
  deliveryLong: number,
  userId: string,
  userPhone: string,
  userBusiness: boolean,
  userFirstName?: string,
  userLastName?: string,
  count: number,
  lastOrderDate: Date,
}

export const INTERVAL_SIZE_MINUTE = 90
export const DAY_HOUR = 27
export const HOUR_MINUTE = 60
export const HALF_HOUR_MINUTE = 30
export const DELIVERY_BLOCK_MINUTE = 90
export const MINUTE_SEC = 60
export const SEC = 1000

export enum EDeliveryTime {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FORTH = 4,
}

export enum EOrderStatus {
  NEW = 0,
  IN_ASSEMBLY = 1,
  COURIER = 2,
  DELIVERED = 3,
  CANCELED = 4,
}

export enum EPaymentStatus {
  NEW = 'NEW',
  AUTHORIZED = 'AUTHORIZED',
  CONFIRMED = 'CONFIRMED',
  REVERSED = 'REVERSED',
  REFUNDED = 'REFUNDED',
  REJECTED = 'REJECTED',
}

export interface IPaymentOption {
  id: EPaymentType,
  name: string,
}

export enum EPaymentType {
  BY_CARD = 0,
  CASH = 1,
}

export enum EDeliveryType {
  DELIVERY = 0,
  PICK_UP = 1,
}

export interface ICurrency {
  symbol: string,
  name: string,
  symbol_native: string,
  decimal_digits: number,
  rounding: number,
  code: string,
  name_plural: string,
  dirRight: boolean,
  spaceStr: boolean,
}

export interface IProductOrder {
  id: string,
  itemId: string,
  name: string,
  country: string,
  market: string,
  image: string,
  price: number,
  totalPrice: number,
  currency: ICurrency,
  quantity: number,
  quantityInitial: number,
  paymentQuantity?: number,
  extendQuantity?: number,
  pcsWeightGr: number,
  sellerId: string,
  sellerIsSystem: boolean,
  sellerIsCommon: boolean,
  sellerName: string,
  sellerCommission: number,
  aboutSeller?: string,
  category?: string,
  subcategory: string,
  marketCategoryId?: string,
  marketCategory?: string,
  marketSubcategoryId?: string,
  marketSubcategory?: string,
  organisationName?: string,
  unit: string,
  added?: boolean,
  createdAt?: Date,
}

export enum EGroupType {
  GROUP = 1,
  CHAIN = 2,
}

export interface IGroupProducts {
  id: string,
  marketId?: string,
  type: EGroupType,
  disable?: boolean,
  products: IGroupProduct[],
}

export interface IGroupProduct {
  id: string,
  productId: string,
  index: number,
  groupId: string,
  root?: boolean,
  product?: IProduct,
}

export interface ITransaction {
  id: string,
  amount: number,
  details: string,
  errorCode: string,
  message: string,
  orderId: string,
  paymentId: string,
  paymentURL: string,
  status: EPaymentStatus,
  success: boolean,
  terminalKey: string,
}

export interface ICity {
  id: string,
  name: string,
}

export interface IMarket {
  id: string,
  name: string,
  city: string,
  city_id: string,
  city_tz: string,
  address: string,
  active: boolean,
  delivery_end_time: string,
  delivery_radius: string,
  delivery_start_time: string,
  latitude: number,
  longitude: number,
  max_delivery_radius: string,
  open_hours_end: string,
  open_hours_start: string,
  self_pickup_end_time: string,
  self_pickup_start_time: string,
  currency: ICurrency,
  delivery_areas?: IDeliveryArea[],
  categories?: IStockCategory[],
}

type TPoint = [number, number]

export enum EDeliverySlotType {
  ASAP = 1,
  BY_SLOTS = 2,
  BY_DAY = 3,
}

export enum POLYGON_COLOR {
  GREEN = '#27ae60',
  ORANGE = '#f59e4d',
  RED = '#eb5757',
  BLUE = '#2f80ed',
  PURPLE = '#9b51e0',
  DEFAULT = '#000000',
}

export interface IDeliveryAsap {
  type: EDeliverySlotType.ASAP,
  price: number,
}

export interface IDeliveryByDay {
  type: EDeliverySlotType.BY_DAY,
  price: number,
}

export interface IDeliveryBySlots {
  type: EDeliverySlotType.BY_SLOTS,
  numBlockSlot: number,
  price: number,
}

export type TDeliveryType = IDeliveryAsap | IDeliveryByDay | IDeliveryBySlots

export enum EDeliveryAreaStatus {
  ACTIVE = 0,
  BLOCK_TODAY = 1,
  BLOCK_ALWAYS = 2,
}

export interface IDeliveryArea {
  id?: number,
  index: number,
  polygon: TPoint[],
  types: TDeliveryType[],
  minOrderPrice?: number,
  status: EDeliveryAreaStatus,
  marketId: string,
}

export interface IStockSubcategory {
  id: string,
  categoryId: string,
  marketId: string,
  name: string,
  index: number,
  imageUrl?: string,
  hidden: boolean,
  numProducts?: number,
}

export interface IStockCategory {
  id: string,
  marketId: string,
  name: string,
  index: number,
  imageUrl?: string,
  hidden: boolean,
  numProducts?: number,
  subcategories: IStockSubcategory[],
}

export interface IMarketWork {
  openingHours: string,
  closingHours: string,
}

export enum ESellerType {
  IP = 'ИП',
  OOO = 'ООО',
}

export interface IPartner {
  type: ESellerType,
  value: string,
  name: string,
  kpp?: string,
  registrationDate: Date,
  inn: string,
  ogrn: string,
  okpo: string,
  okato: string,
  oktmo?: string,
  okogu: string,
  okfs: string,
  okved: string,
  address: string,
  postalCode: string,
  country: string,
  countryIsoCode: string,
  timezone: string,
  sourceAddress: string,
}

export interface ISellerInfo {
  id: string,
  phone: string,
  password: string,
  isHide: boolean,
  isSystem?: boolean,
  createdUserDb?: boolean,
  createdProfileDb?: boolean,
  createdAt?: Date,
  email?: string,
  city?: string,
  market?: string,
  location?: string,
  inn?: string,
  type?: ESellerType,
  fio?: string,
  name?: string,
  ogrnip?: string,
  ogrn?: string,
  address?: string,
  registrationDate?: Date,
  bik?: string,
  bankName?: string,
  corAcc?: string,
  checkingAcc?: string,
  certificateUrl?: string,
  passportUrl?: string,
  bankAccDocUrl?: string,
  legalRegistrationCertificateUrl?: string,
  legalEntitiesStateRegisterUrl?: string,
  decisionAppointmentUrl?: string,
  orderAppointmentUrl?: string,
  rentContractUrl?: string,
}

export enum EFileType {
  PASSPORT_IP = 1,
  LEGAL_REGISTRATION_CERTIFICATE = 2,
  BANK_ACC_DOC = 3,
  RENT_CONTRACT = 4,
  CERTIFICATE = 5,
  LEGAL_ENTITIES_STATE_REGISTER = 6,
  DECISION_APPOINTMENT = 7,
  ORDER_APPOINTMENT = 8,
}

export interface IMarketInfo {
  id: string,
  name: string,
  address: string,
  openingHours: string,
  closingHours: string,
}

export interface ISellerCategory {
  id: string,
  name: string,
}

export interface ISeller {
  id: string,
  userId: number,
  courierId?: string,
  isSystem: boolean,
  isCommon: boolean,
  commission: number,
  isHide: boolean,
  name: string,
  nickname: string,
  organization: string,
  location: string,
  imageUrl: string,
  avatarUrl: string,
  categories: ISellerCategory[],
  aboutSellers?: string[],
  cityId: string,
  cityName: string,
  marketId: string,
  marketAddress: string,
  marketName: string,
  marketOpeningHours?: string,
  marketClosingHours?: string,
}

export interface ISellerSearch {
  id: string,
  isSystem: boolean,
  isCommon: boolean,
  isHide: boolean,
  name: string,
  nickname: string,
  location: string,
  marketId: string,
  marketName: string,
  organization: string,
}

export interface ISellerProduct {
  id: string,
  imageUrl: string,
  market: string,
  name: string,
  pcsWeightGr: number,
  price: string,
  seller: string,
  sellerIsCommon?: boolean,
  sellerIsSystem?: boolean,
  sellerName?: string,
  sellerCommission?: number,
  step: number,
  subcategory: string,
  unit: string,
  country: string,
  hide: boolean,
  tags: number[],
  priceWithoutDiscount: number,
  description?: string,
  category?: string,
  aboutSeller?: string,
  stockCategory?: string,
  stockSubcategory?: string,
  vendorCode?: string,
  rating?: number,
  priceWeightGr?: number,
  currency?: ICurrency,
}

export interface IProduct {
  id: string,
  name: string,
  isHide: boolean,
  price: number,
  totalPrice: number,
  currency: ICurrency,
  unit: string,
  step: number,
  pcsWeightGr: number,
  country: string,
  imageUrl: string,
  marketId: string,
  sellerId: string,
  sellerIsCommon: boolean,
  sellerIsSystem: boolean,
  sellerName: string,
  sellerCommission: number,
  sellerNickname: string,
  sellerLocation: string,
  sellerOrganisation: string,
  sellerINN: string,
  sellerAddress: string,
  category: string,
  categoryId: string,
  priceWithoutDiscount: number,
  categoryPriority?: number,
  subcategory: string,
  subcategoryId: string,
  subcategoryPriority?: number,
  marketCategory?: string,
  marketCategoryId?: string,
  marketCategoryPriority?: number,
  marketSubcategory?: string,
  marketSubcategoryId?: string,
  marketSubcategoryPriority?: number,
  vendorCode?: string,
  description?: string,
  aboutSeller?: string,
  priceWeightGr?: number,
  tags: number[],
  rating?: number,
}

export interface IModerationProduct {
  id: string,
  num: number,
  productId: string,
  status: EModerateProductStatus,
  text?: string,
  createdAt: Date,
  moderateAt?: Date,
  name: string,
  isHide: boolean,
  price: number,
  totalPrice: number,
  currency: ICurrency,
  unit: string,
  step: number,
  pcsWeightGr: number,
  country: string,
  imageUrl: string,
  marketId: string,
  sellerId: string,
  sellerIsCommon: boolean,
  sellerIsSystem: boolean,
  sellerName: string,
  sellerOrganisation: string,
  sellerCommission: number,
  sellerNickname: string,
  sellerLocation: string,
  category: string,
  categoryId: string,
  categoryPriority?: number,
  subcategory: string,
  subcategoryId: string,
  subcategoryPriority?: number,
  description?: string,
  aboutSeller?: string,
  priceWeightGr?: number,
  oldName: string,
  oldPrice: number,
  oldUnit: string,
  oldStep: number,
  oldPcsWeightGr: number,
  oldCountry: string,
  oldImageUrl: string,
  oldCategory: string,
  oldCategoryId: string,
  oldSubcategory: string,
  oldSubcategoryId: string,
  oldDescription?: string,
  oldPriceWeightGr?: number,
}

export type CopyProductsStatus = {
  current: number,
  all: number,
}

export interface ISavedSellerProfile {
  imageUrl: string,
  avatarUrl: string,
}

export enum EImageType {
  SELLER_IMAGE = 1,
  SELLER_AVATAR = 2,
  PRODUCT_IMAGE = 3,
  TAG_IMAGE = 4,
  MARKET_CATEGORY_IMAGE = 5,
}

export interface ICountry {
  name: string,
}

export interface IStep {
  id: number,
  name: string,
}

export interface IUnit {
  id: string,
  name: string,
}

export interface ISubcategory {
  id: string,
  name: string,
  priority: number,
}

export interface ICategory {
  id: string,
  name: string,
  priority: number,
  subcategories: ISubcategory[],
}

export interface IDeliveryAddress {
  userId: number,
  address: string,
  createdAt: Date,
}

export interface IReview {
  id: string,
  orderId: string,
  orderNum: number,
  createdAt: Date,
  userId: number,
  assessment: number,
  message?: string,
}

export interface ICustomer {
  id: string,
  userId: number,
  phone: string,
  business: boolean,
  registerAt: Date,
  firstName?: string,
  lastName?: string,
  lastOrderCreatedAt?: Date,
  firstOrderCreatedAt?: Date,
  cityName?: string,
  ordersCount: number,
  averageOrderPrice: number,
  comment?: string,
}

export interface ICustomerUpdateProfile extends Partial<ICustomer> {
  id: string,
}

export enum ECourierRole {
  MANAGER = 'MANAGER',
  PICKER = 'PICKER',
  COURIER = 'COURIER',
}

export enum EVehicleType {
  MOTO = 'MOTO',
  CAR = 'CAR',
}

export interface IShiftSlot {
  id?: string,
  marketId: string,
  courierId: string,
  shiftDate: string,
  slotId: string,
  startAt?: string,
  completeAt?: string,
}

export interface ICourier {
  id: string,
  num: number,
  firstName: string,
  lastName: string,
  phone: string,
  role: ECourierRole,
  isOnShift: boolean,
  cityId: string,
  cityName: string,
  marketId: string,
  marketAddress: string,
  marketName: string,
  numActiveOrders: number,
  vehicleType?: EVehicleType,
  carNumber?: string,
  latitude?: number,
  longitude?: number,
  isBusy?: boolean,
  shiftSlots: IShiftSlot[],
}

export interface ICourierLocation {
  id: string,
  isOnShift: boolean,
  latitude: number,
  longitude: number,
}

export interface ICourierShiftsFilter {
  market: string,
  date: string,
  dateFrom?: string,
  dateTo?: string,
}

export enum ENotificationType {
  DAILY = 1,
  BY_DAY_WEEK = 2,
  SPECIFIC_DAY = 3,
}

export enum ENotificationDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum ENotificationFrequency {
  ONCE = 1,
  WEEKLY = 2,
  BY_X_WEEK = 3,
}

export enum ENotificationUsers {
  ALL_CUSTOMER = 1,
  REGISTER_CUSTOMER = 2,
  NOT_REGISTER_CUSTOMER = 3,
  SPECIFIC_USERS = 4,
}

export interface INotification {
  id: string,
  title: string,
  body: string,
  createdAt?: Date,
  times: string[],
  type: ENotificationType,
  days?: ENotificationDay[],
  date?: Date,
  frequency?: ENotificationFrequency,
  frequencyWeek?: number,
  userGroup: ENotificationUsers,
  clients?: number[],
}

export interface INotificationInfo {
  id: number,
  title: string,
  body: string,
  auditory: EAuditory,
  sendDate: string,
  usersCount: number,
  marketingPushId: number,
  marketingPushType: MarketingPushType,
}

export interface IPeriodFilter {
  dateFrom?: string,
  dateTo?: string,
  dateDeliveryFrom?: string,
  dateDeliveryTo?: string,
}

export interface IPaginationFilter {
  offset?: number,
  limit?: number,
}

export interface IOrdersFilter extends IPeriodFilter, IPaginationFilter {
  byCouriers?: boolean,
  search?: string,
  ids?: string[],
  city?: string,
  market?: string,
  seller?: string,
  statuses?: number[],
  asc?: boolean,
  force?: boolean,
  b2b?: boolean,
}

export enum EOrdersFilter {
  CURRENT = 1,
  ARCHIVE = 2,
}

export const CURRENT_FILTER = {
  statuses: [EOrderStatus.NEW, EOrderStatus.IN_ASSEMBLY, EOrderStatus.COURIER],
}

export const ARCHIVE_FILTER = {
  statuses: [EOrderStatus.DELIVERED, EOrderStatus.CANCELED],
}

export interface IProductsFilter {
  market?: string,
  marketName?: string,
  seller?: string,
  sellerName?: string,
  hidden?: boolean,
  byCategories?: boolean,
}

export interface IAnalyticsFilter extends IPeriodFilter {
  city?: string,
  market?: string,
  b2b?: boolean,
  web?: boolean,
}

export interface IOrdersByMarketsFilter {
  city?: string,
  market?: string,
  dateFrom?: string,
  dateTo?: string,
  statuses?: number[],
}

export interface ICouriersFilter {
  market?: string,
}

export interface IPeriodOrders {
  year: number,
  month: number,
  orders: number,
  customers: number,
}

export interface ITotalOrders {
  cityId: string,
  cityName: string,
  marketId: string,
  marketName?: string,
  totalUniqueClients: number,
  totalNumDelivered: number,
  totalNumCanceled: number,
  totalNumFirst: number,
  totalCustomersFirstMonth: number,
  totalOrdersFirstMonth: number,
  totalCustomersLastMonth: number,
  totalOrdersLastMonth: number,
  totalOrdersFirstMonthByPeriods: IPeriodOrders[],
  totalOrdersLastMonthByPeriods: IPeriodOrders[],
  totalPrice: number,
  totalCommission: number,
  totalDeliveryPrice: number,
  totalWeightKg: number,
  currency: ICurrency,
  currencies?: ICurrency[],
}

export interface ICurrencyPairs {
  [currencyPair: string]: number,
}

export interface INewCustomers {
  data: number,
}

export interface ICustomersFilter extends IPeriodFilter {
  ids?: number[],
  b2b?: boolean,
  city?: string,
  search?: string,
  limit?: number,
  offset?: number,
  force?: boolean,
  lastOrderDesc?: boolean,
  firstOrderDesc?: boolean,
  registerDesc?: boolean,
  numOrdersDesc?: boolean,
  lastOrderDateFrom?: string,
  lastOrderDateTo?: string,
  firstOrderDateFrom?: string,
  firstOrderDateTo?: string,
}

export enum EModerateProductStatus {
  NEW = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export interface IModerationProductsFilter extends IPeriodFilter {
  ids?: string[],
  statuses?: EModerateProductStatus[],
  city?: string,
  search?: string,
  limit?: number,
  offset?: number,
  force?: boolean,
}

export enum EReportFormat {
  CSV = 'csv',
  EXCEL = 'excel',
}

export interface ITag {
  id: number,
  tag: string,
  market: string,
  color: string,
  position: number,
  image_url: string,
  products_count: number,
}

export interface IOrderBlock {
  index: string,
  deliveryDate: Date,
  orders: IOrder[],
  titles: {
    text: string,
    color?: string,
    colorText?: string,
  }[],
}

export enum ESlotAction {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type ISlot = {
  id: string,
  from: string,
  to: string,
  error: EInputType[],
  isActive?: boolean,
  type?: ESlotAction,
}

export interface ISlotInfo {
  timeEnd: string,
  timeStart: string,
  id: string,
  isActive: boolean,
  loadFactor: number,
  load: number,
  ordersCount: number,
  couriersCount: number,
  managersCount: number,
  pickersCount: number,
  allCouriersCount: number,
  allManagersCount: number,
  allPickersCount: number,
}

export enum ESlotType {
  AS_SOON_AS_POSSIBLE,
  DURING_THE_DAY,
  NEAREST_SLOT,
  THROUGH_ONE_SLOT,
  THROUGH_TWO_SLOTS,
}

export interface IRouteStep {
  id?: string,
  index?: number,
  marketId: string,
  orderId?: string,
  text: string,
  address: string,
  order?: IOrder,
}
export interface IRoute {
  id: string,
  marketId: string,
  courierId: string,
  createdAt: Date,
  estimateTime: number,
  startAt?: Date,
  completedAt?: Date,
  points: IRouteStep[],
}

export enum OrderHistoryActionType {
  STATUS = 'STATUS',
  PICK_PRODUCT = 'PICK_PRODUCT',
  CHANGE_PRODUCT = 'CHANGE_PRODUCT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  COLLECTED = 'COLLECTED',
  PICKED = 'PICKED',
  ASSIGN = 'ASSIGN',
  PAYED = 'PAYED',
}

interface OrderHistoryActionEmpty<Type extends OrderHistoryActionType> {
  orderId: string,
  type: Type,
  date: Date,
}

interface OrderHistoryActionWithData<Type extends OrderHistoryActionType, Data extends Record<string, any>>
  extends OrderHistoryActionEmpty<Type> {
  data: Data,
}

export type HistoryStatusData = {
  status: EOrderStatus,
}

export type HistoryOrderAssignData = {
  role: ECourierRole,
  courierId: string,
  courierName: string,
  orderStatus: EOrderStatus,
  pickingUntil?: Date,
  deliveryUntil?: Date,
}

export type HistoryPickProductData = {
  name: string,
  price: string,
  quantity: number,
  productId: string,
  unit: string,
  currencyCode: string,
}

export type HistoryChangeProductData = {
  productId: string,
  name: string,
  quantity: number,
  quantityInitial: number,
  unit: string,
}

export type HistoryRemoveProductData = {
  name: string,
  price: string,
  quantity: number,
  unit: string,
  currencyCode: string,
  productId: string,
}

export type HistoryCollectedAction = OrderHistoryActionEmpty<OrderHistoryActionType.COLLECTED>
export type HistoryPickedAction = OrderHistoryActionEmpty<OrderHistoryActionType.PICKED>
export type HistoryPayedAction = OrderHistoryActionEmpty<OrderHistoryActionType.PAYED>
export type HistoryStatusAction = OrderHistoryActionWithData<OrderHistoryActionType.STATUS, HistoryStatusData>
export type HistoryOrderAssignAction = OrderHistoryActionWithData<OrderHistoryActionType.ASSIGN, HistoryOrderAssignData>
export type HistoryChangeProductAction = OrderHistoryActionWithData<
  OrderHistoryActionType.CHANGE_PRODUCT,
  HistoryChangeProductData
>
export type HistoryRemoveProductAction = OrderHistoryActionWithData<
  OrderHistoryActionType.REMOVE_PRODUCT,
  HistoryRemoveProductData
>
export type HistoryPickProductAction = OrderHistoryActionWithData<
  OrderHistoryActionType.PICK_PRODUCT,
  HistoryPickProductData
>

export type TLogAction =
  | HistoryStatusAction
  | HistoryOrderAssignAction
  | HistoryPickProductAction
  | HistoryChangeProductAction
  | HistoryRemoveProductAction
  | HistoryPickedAction
  | HistoryCollectedAction
  | HistoryPayedAction

export type TCountryWithPrefix<T extends string> = `${T}${COUNTRY_ID}`
export type TCountriesDictionary<T extends string> = Record<TCountryWithPrefix<T>, string>
