import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Form } from 'semantic-ui-react'
import classnames from 'classnames'
import { isEqual } from 'lodash'
import moment from 'moment'

import './index.scss'

import { DAY_HOUR, HOUR_MINUTE, MINUTE_SEC, SEC, ICustomersFilter } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'

type TOwnProps = {
  title: string,
  onClose: () => void,
}

type TConnectedProps = {
  loading: boolean,
  filter: ICustomersFilter,
}

type TDispatchedProps = {
  updateFilter: (filter: ICustomersFilter) => Actions.Action,
  dropApiError: () => Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  changed: boolean,
  dateFrom?: string,
  dateTo?: string,
  lastOrderDateTo?: string,
  lastOrderDateFrom?: string,
  firstOrderDateTo?: string,
  firstOrderDateFrom?: string,
  errors: FieldError[],
}

const FIELD_DATE_FROM = 'register_date_from'
const FIELD_DATE_TO = 'register_date_to'
const FIELD_FIRST_ORDER_DATE_FROM = 'first_date_from'
const FIELD_FIRST_ORDER_DATE_TO = 'first_date_to'
const FIELD_LAST_ORDER_DATE_FROM = 'last_date_from'
const FIELD_LAST_ORDER_DATE_TO = 'last_date_to'

class CustomersFilterCmp extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      changed: false,
      dateTo: props.filter.dateTo,
      dateFrom: props.filter.dateFrom,
      lastOrderDateTo: props.filter.lastOrderDateTo,
      lastOrderDateFrom: props.filter.lastOrderDateFrom,
      firstOrderDateTo: props.filter.firstOrderDateTo,
      firstOrderDateFrom: props.filter.firstOrderDateFrom,
      errors: [],
    }
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='customers-filter'>
        <div className='customers-filter__title'>{this.props.title}</div>
        {this.renderDivider()}
        <div>
          <Form>
            {this.renderLastOrderPeriod()}
            {this.renderFirstOrderPeriod()}
            {this.renderRegisterPeriod()}
          </Form>
        </div>
        <div className='customers-filter__actions'>
          <div className='customers-filter__actions-btn' onClick={this.updateFilter}>
            {formatMessage(messages.Apply)}
          </div>
          <div
            className={classnames('customers-filter__actions-btn', 'customers-filter__actions-btn-cancel')}
            onClick={this.dropFilter}
          >
            {formatMessage(messages.Reset)}
          </div>
        </div>
      </div>
    )
  }

  renderLastOrderPeriod = () => {
    const { formatMessage } = this.props.intl
    const { lastOrderDateFrom, lastOrderDateTo } = this.state

    return (
      <div>
        <div className='customers-filter__field-label'>{formatMessage(messages.LastOrder)}</div>
        <div className='customers-filter__period'>
          {this.renderDate(FIELD_LAST_ORDER_DATE_FROM, lastOrderDateFrom)}
          <div className='customers-filter__period-divider'>–</div>
          {this.renderDate(FIELD_LAST_ORDER_DATE_TO, lastOrderDateTo)}
        </div>
        {this.renderDivider()}
      </div>
    )
  }

  renderFirstOrderPeriod = () => {
    const { formatMessage } = this.props.intl
    const { firstOrderDateFrom, firstOrderDateTo } = this.state

    return (
      <div>
        <div className='customers-filter__field-label'>{formatMessage(messages.FirstOrder)}</div>
        <div className='customers-filter__period'>
          {this.renderDate(FIELD_FIRST_ORDER_DATE_FROM, firstOrderDateFrom)}
          <div className='customers-filter__period-divider'>–</div>
          {this.renderDate(FIELD_FIRST_ORDER_DATE_TO, firstOrderDateTo)}
        </div>
        {this.renderDivider()}
      </div>
    )
  }

  renderRegisterPeriod = () => {
    const { formatMessage } = this.props.intl
    const { dateFrom, dateTo } = this.state

    return (
      <div>
        <div className='customers-filter__field-label'>{formatMessage(messages.Registration)}</div>
        <div className='customers-filter__period'>
          {this.renderDate(FIELD_DATE_FROM, dateFrom)}
          <div className='customers-filter__period-divider'>–</div>
          {this.renderDate(FIELD_DATE_TO, dateTo)}
        </div>
        {this.renderDivider()}
      </div>
    )
  }

  renderDate = (name: string, date?: string) => {
    const error = this.checkError(name)

    return (
      <Form.Input
        value={date}
        name={name}
        type='date'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderDivider = () => {
    return <div className='customers-filter__divider' />
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_DATE_FROM:
        this.setState({ dateFrom: value, errors: this.removeError(FIELD_DATE_FROM) })
        break
      case FIELD_DATE_TO:
        this.setState({ dateTo: value, errors: this.removeError(FIELD_DATE_TO) })
        break
      case FIELD_LAST_ORDER_DATE_FROM:
        this.setState({ lastOrderDateFrom: value, errors: this.removeError(FIELD_LAST_ORDER_DATE_FROM) })
        break
      case FIELD_LAST_ORDER_DATE_TO:
        this.setState({ lastOrderDateTo: value, errors: this.removeError(FIELD_LAST_ORDER_DATE_TO) })
        break
      case FIELD_FIRST_ORDER_DATE_FROM:
        this.setState({ firstOrderDateFrom: value, errors: this.removeError(FIELD_FIRST_ORDER_DATE_FROM) })
        break
      case FIELD_FIRST_ORDER_DATE_TO:
        this.setState({ firstOrderDateTo: value, errors: this.removeError(FIELD_FIRST_ORDER_DATE_TO) })
        break
    }
  }

  updateFilter = () => {
    const { filter } = this.props
    const { dateFrom, dateTo, firstOrderDateTo, firstOrderDateFrom, lastOrderDateTo, lastOrderDateFrom } = this.state
    const errors: FieldError[] = []

    if (!errors.length) {
      const newFilter = {
        offset: 0,
        b2b: filter.b2b,
        city: filter.city,
        search: filter.search,
        lastOrderDesc: filter.lastOrderDesc,
        firstOrderDesc: filter.firstOrderDesc,
        registerDesc: filter.registerDesc,
        numOrdersDesc: filter.numOrdersDesc,
        ...(dateFrom && { dateFrom }),
        ...(dateTo && { dateTo }),
        ...(firstOrderDateFrom && { firstOrderDateFrom }),
        ...(firstOrderDateTo && { firstOrderDateTo }),
        ...(lastOrderDateFrom && { lastOrderDateFrom }),
        ...(lastOrderDateTo && { lastOrderDateTo }),
      }

      this.checkFilterUpdate(newFilter)
    } else {
      this.setState({ errors })
    }
  }

  dropFilter = () => {
    const { filter } = this.props

    const newFilter = {
      offset: 0,
      b2b: filter.b2b,
      city: filter.city,
      search: filter.search,
      lastOrderDesc: filter.lastOrderDesc,
      firstOrderDesc: filter.firstOrderDesc,
      registerDesc: filter.registerDesc,
      numOrdersDesc: filter.numOrdersDesc,
      firstOrderDateTo: moment().format('YYYY-MM-DD'),
      firstOrderDateFrom: moment(Date.now() - 6 * DAY_HOUR * HOUR_MINUTE * MINUTE_SEC * SEC).format('YYYY-MM-DD'),
    }

    this.checkFilterUpdate(newFilter)
  }

  checkFilterUpdate = (newFilter: ICustomersFilter) => {
    const { filter } = this.props

    if (!isEqual(filter, newFilter)) {
      this.props.updateFilter(newFilter)
    }

    this.props.onClose()
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  return {
    loading: false,
    filter: s.customers.filter,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  updateFilter: (filter: ICustomersFilter) =>
    dispatch(
      Actions.action(Actions.CUSTOMERS_FILTER, {
        ...filter,
      }),
    ),
  dropApiError: () => dispatch(Actions.actionEmpty(Actions.DROP_PRODUCT_ERROR)),
})

export const CustomersFilterModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomersFilterCmp))
