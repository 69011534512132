import { combineEpics } from 'redux-observable'

import { userEpics } from './userEpics'
import { ordersEpics } from './ordersEpics'
import { marketsEpics } from './marketsEpics'
import { sellersEpics } from './sellersEpics'
import { customersEpics } from './customersEpics'
import { productsEpics } from './productsEpics'
import { couriersEpics } from './couriersEpics'
import { notificationsEpics } from './notificationsEpics'
import { reportsEpics } from './reportsEpics'
import { analyticsEpics } from './analyticsEpics'
import { tagsEpics } from './tagsEpics'

const epics = combineEpics(
  ...userEpics,
  ...ordersEpics,
  ...marketsEpics,
  ...sellersEpics,
  ...customersEpics,
  ...productsEpics,
  ...couriersEpics,
  ...notificationsEpics,
  ...reportsEpics,
  ...analyticsEpics,
  ...tagsEpics,
)

export default epics
