import { uniqBy } from 'lodash'

import { ICurrencyPairs, ITotalOrders } from '../types/TClient'

import { intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'
import { DEF_CURRENCY } from '../localization/currencies'
import { round } from './ordersUtils'
import { convertCurrency } from './productsUtils'

export const calcTotal = (totalOrders: ITotalOrders[], currencyPairs: ICurrencyPairs) => {
  const total: ITotalOrders = {
    cityId: '0',
    cityName: intlMessage(messages.AllMarkets),
    marketId: '0',
    totalUniqueClients: 0,
    totalNumDelivered: 0,
    totalNumCanceled: 0,
    totalNumFirst: 0,
    totalCustomersFirstMonth: 0,
    totalOrdersFirstMonth: 0,
    totalCustomersLastMonth: 0,
    totalOrdersLastMonth: 0,
    totalOrdersLastMonthByPeriods: [],
    totalOrdersFirstMonthByPeriods: [],
    totalPrice: 0,
    totalCommission: 0,
    totalDeliveryPrice: 0,
    totalWeightKg: 0,
    currency: convertCurrency(),
  }
  const totalCurs: { [cur: string]: ITotalOrders } = {}
  let defCur = ''

  totalOrders.forEach((item) => {
    const cur = item.currency.code

    if (!defCur) {
      defCur = cur
    }

    if (!totalCurs[defCur]) {
      total.currency = convertCurrency(defCur)

      totalCurs[defCur] = { ...total }
      totalCurs[defCur].marketId = defCur
    }

    const totalCur = totalCurs[defCur]

    totalCur.totalUniqueClients = round(totalCur.totalUniqueClients + item.totalUniqueClients)
    totalCur.totalNumDelivered = round(totalCur.totalNumDelivered + item.totalNumDelivered)
    totalCur.totalNumCanceled = round(totalCur.totalNumCanceled + item.totalNumCanceled)
    totalCur.totalNumFirst = round(totalCur.totalNumFirst + item.totalNumFirst)
    totalCur.totalCustomersFirstMonth = round(totalCur.totalCustomersFirstMonth + item.totalCustomersFirstMonth)
    totalCur.totalOrdersFirstMonth = round(totalCur.totalOrdersFirstMonth + item.totalOrdersFirstMonth)
    totalCur.totalCustomersLastMonth = round(totalCur.totalCustomersLastMonth + item.totalCustomersLastMonth)
    totalCur.totalOrdersLastMonth = round(totalCur.totalOrdersLastMonth + item.totalOrdersLastMonth)
    totalCur.totalWeightKg = round(totalCur.totalWeightKg + item.totalWeightKg)

    for (const val of item.totalOrdersFirstMonthByPeriods) {
      const exist = totalCur.totalOrdersFirstMonthByPeriods.find((t) => t.year === val.year && t.month === val.month)

      if (exist) {
        totalCur.totalOrdersFirstMonthByPeriods = totalCur.totalOrdersFirstMonthByPeriods.map((t) => {
          if (t.year === exist.year && t.month === exist.month) {
            return {
              ...t,
              orders: +t.orders + +val.orders,
              customers: +t.customers + +val.customers,
            }
          }

          return t
        })
      } else {
        totalCur.totalOrdersFirstMonthByPeriods.push({ ...val })
      }
    }

    totalCur.totalOrdersFirstMonthByPeriods.sort((a, b) => a.year - b.year || a.month - b.month)

    for (const val of item.totalOrdersLastMonthByPeriods) {
      const exist = totalCur.totalOrdersLastMonthByPeriods.find((t) => t.year === val.year && t.month === val.month)

      if (exist) {
        totalCur.totalOrdersLastMonthByPeriods = totalCur.totalOrdersLastMonthByPeriods.map((t) => {
          if (t.year === exist.year && t.month === exist.month) {
            return {
              ...t,
              orders: +t.orders + +val.orders,
              customers: +t.customers + +val.customers,
            }
          }

          return t
        })
      } else {
        totalCur.totalOrdersLastMonthByPeriods.push({ ...val })
      }
    }

    totalCur.totalOrdersLastMonthByPeriods.sort((a, b) => a.year - b.year || a.month - b.month)

    totalCur.totalPrice = round(
      totalCur.totalPrice + convertPriceByCurrency(item.totalPrice, currencyPairs, cur, defCur),
    )
    totalCur.totalCommission = round(
      totalCur.totalCommission + convertPriceByCurrency(item.totalCommission, currencyPairs, cur, defCur),
    )
    totalCur.totalDeliveryPrice = round(
      totalCur.totalDeliveryPrice + convertPriceByCurrency(item.totalDeliveryPrice, currencyPairs, cur, defCur),
    )

    totalCur.currencies = uniqBy(
      [DEF_CURRENCY, ...(totalCur.currencies || []), ...(item.currency ? [item.currency] : [])],
      'code',
    )
  })

  return totalCurs
}

export const convertPriceByCurrency = (
  price: number,
  currencyPairs: ICurrencyPairs,
  currencyFrom: string,
  currencyTo: string,
) => {
  let currencyPair = currencyPairs[`${currencyFrom}${currencyTo}`]

  if (currencyPair) {
    return price * currencyPair
  }

  currencyPair = currencyPairs[`${currencyTo}${currencyFrom}`]

  if (currencyPair) {
    return price * (1 / currencyPair)
  }

  return price
}
