import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Rating } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'

type TOwnProps = {
  order: IOrder,
}

type TProps = TOwnProps & WrappedComponentProps

class OrderReviewCmp extends React.Component<TProps> {
  render() {
    const { formatMessage } = this.props.intl
    const { review } = this.props.order

    if (!review) {
      return
    }

    return (
      <div className='order__review'>
        <div className='order__block-title'>{formatMessage(messages.CustomerReview)}</div>
        <div className='order__block-item'>
          <div className='order__block-label'>{formatMessage(messages.Assessment)}</div>
          <div className='order__review-rate'>
            <Rating disabled icon='star' rating={review.assessment} maxRating={5} size='massive' />
          </div>
        </div>
        {!!review.message && (
          <div className={classnames('order__block-item', 'order__block-padding')}>
            <div className='order__block-label'>{formatMessage(messages.Review)}</div>
            <div className='order__block-text'>{review.message}</div>
          </div>
        )}
      </div>
    )
  }
}

export const OrderReview = injectIntl(OrderReviewCmp)
