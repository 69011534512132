import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { HistoryCollectedAction } from '../../../../types/TClient'

import { Action } from '../Action'
import messages from '../../../../localization/messages'

type IProps = HistoryCollectedAction & WrappedComponentProps

const _CollectedAction: React.FC<IProps> = ({ date }) => {
  return (
    <Action date={date}>
      <div className='collected-action'>
        <FormattedMessage {...messages.OrderCollected} />
      </div>
    </Action>
  )
}

export const CollectedAction = injectIntl(_CollectedAction)
