import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'

import './index.scss'

import { EOrderStatus, IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'

type TOwnProps = {
  order: IOrder,
}

type TProps = TOwnProps & WrappedComponentProps

class OrderTotalCmp extends React.Component<TProps> {
  render() {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const deliveryAt = order.deliveryAt || order.completedAt ? moment(order.deliveryAt || order.completedAt) : ''
    const deliveryPlan = order.deliveryEndTime
      ? moment(order.deliveryDate)
          .hours(+order.deliveryEndTime.split(':')[0])
          .minutes(+order.deliveryEndTime.split(':')[1])
      : ''
    const deliveryLate = deliveryPlan ? deliveryPlan.diff(deliveryAt, 'minute', true) : 0
    const isLate = order.statusId === EOrderStatus.DELIVERED && deliveryLate <= -1

    return (
      <div className='order__total'>
        <div className='order__block-title'>{formatMessage(messages.TotalOrder)}</div>
        <div className='order__block-row'>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.Delivered)}</div>
            <div className='order__block-text'>
              {order.statusId === EOrderStatus.DELIVERED && deliveryAt ? deliveryAt.format('HH:mm') : '–'}
            </div>
          </div>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.BeingLate)}</div>
            <div className={classnames('order__block-text', isLate && 'order__total-late')}>
              {isLate
                ? `${Math.floor(Math.abs(deliveryLate))} ${formatMessage(messages.Min).toLowerCase()}`
                : deliveryAt
                ? formatMessage(messages.No).toLowerCase()
                : '–'}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const OrderTotal = injectIntl(OrderTotalCmp)
