import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { IOrder } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { OrderPanel } from '../NewOrders/OrderPanel'
import { Button } from '../../../../components/Button'
import { LOCATION_ORDERS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  loading: boolean,
  order?: IOrder,
}

type TDispatchedProps = {
  apiOrdersList: (id: string) => Actions.Action,
}

type TProps = RouteComponentProps<{ id: string }> & TConnectedProps & TDispatchedProps & WrappedComponentProps

class OrderCmp extends React.Component<TProps> {
  componentDidMount(): void {
    const { order } = this.props

    if (!order) {
      this.props.apiOrdersList(this.props.match.params.id)
    }
  }

  render() {
    const { order } = this.props

    if (!order) {
      return null
    }

    return (
      <div className='order-container'>
        {this.renderBack()}
        <OrderPanel order={order} />
      </div>
    )
  }

  renderBack() {
    const { formatMessage } = this.props.intl

    return (
      <div className='order__back'>
        <Button secondary title={formatMessage(messages.Back)} onClick={this.back} />
      </div>
    )
  }

  back = () => {
    this.props.history.push(`${LOCATION_ORDERS}`)
  }
}

const mapStateToProps = (s: State, own: TProps): TConnectedProps => {
  const { ordersList, loading } = s.orders
  const order = ordersList.find((order) => order.id === own.match.params.id)

  if (!order && ordersList && ordersList.length > 0 && !loading) {
    own.history.push(`${LOCATION_ORDERS}`)
  }

  return {
    loading,
    order,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiOrdersList: (id: string) => dispatch(Actions.action(Actions.API_ORDERS_LIST, { ids: [id] })),
})

export const Order = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderCmp))
