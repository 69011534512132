import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Grid, Segment, Form, Header, Message } from 'semantic-ui-react'

import './index.scss'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'

type TConnectedProps = {
  loading: boolean,
  errorApi?: string,
}

type TDispatchedProps = {
  dropApiError: () => Actions.Action,
  apiLogin: (data: { login: string, password: string }) => Actions.Action,
}

type Props = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type StateLogin = {
  login: string,
  password: string,
  key: string,
  errors: FieldError[],
}

const FIELD_LOGIN = 'login'
const FIELD_PASSWORD = 'password'
const FIELD_KEY = 'key'

class LoginCmp extends React.Component<Props, StateLogin> {
  constructor(props: Props) {
    super(props)

    this.state = {
      login: '',
      password: '',
      key: '',
      errors: [],
    }
  }

  componentDidMount(): void {
    if (this.props.errorApi) {
      this.props.dropApiError()
    }
  }

  render() {
    const { loading, errorApi } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='login'>
        <Grid textAlign='center' style={{ height: '100vh', width: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: '90%', width: 400, textAlign: 'left' }}>
            <Header as='h2'>{formatMessage(messages.SignIn)}</Header>
            <Segment raised>
              <Form size='large' error={!!errorApi}>
                {this.renderErrorMessage()}

                {this.renderLogin()}
                {this.renderPassword()}

                <Button
                  fluid
                  loading={loading}
                  disabled={loading}
                  color='teal'
                  size='large'
                  onClick={this.handleSubmit}
                >
                  {formatMessage(messages.SignIn)}
                </Button>
              </Form>
            </Segment>

            {/*<Message className='login__message'>*/}
            {/*  New to us? <Link to={'/signup'}>Sign Up</Link>*/}
            {/*</Message>*/}
          </Grid.Column>
        </Grid>
      </div>
    )
  }

  renderLogin = () => {
    const { formatMessage } = this.props.intl
    const error = this.checkError(FIELD_LOGIN)

    return (
      <Form.Input
        fluid
        name={FIELD_LOGIN}
        label={formatMessage(messages.Login)}
        icon='user'
        iconPosition='left'
        placeholder={formatMessage(messages.EnterLogin)}
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderPassword = () => {
    const { formatMessage } = this.props.intl
    const error = this.checkError(FIELD_PASSWORD)

    return (
      <Form.Input
        fluid
        name={FIELD_PASSWORD}
        label={formatMessage(messages.Password)}
        icon='lock'
        iconPosition='left'
        placeholder={formatMessage(messages.EnterPassword)}
        type='password'
        onChange={this.handleChange}
        error={error ? error.message : false}
      />
    )
  }

  renderErrorMessage = () => {
    const { errorApi } = this.props
    const { formatMessage } = this.props.intl

    return (
      <Message error>
        <Message.Content className='login__message-error'>
          <Message.Header>{formatMessage(messages.Error)}</Message.Header>
          {errorApi}
        </Message.Content>
      </Message>
    )
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any) => {
    const { name, value } = event.target

    switch (name) {
      case FIELD_LOGIN:
        this.setState({ login: value, errors: this.removeError(FIELD_LOGIN) })
        break
      case FIELD_PASSWORD:
        this.setState({ password: value, errors: this.removeError(FIELD_PASSWORD) })
        break
      case FIELD_KEY:
        this.setState({ key: value, errors: this.removeError(FIELD_KEY) })
        break
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    const { formatMessage } = this.props.intl
    const { login, password } = this.state
    const errors: FieldError[] = []

    if (!login) {
      errors.push({ field: FIELD_LOGIN, message: formatMessage(messages.FieldRequired) })
    }

    if (!password) {
      errors.push({ field: FIELD_PASSWORD, message: formatMessage(messages.FieldRequired) })
    }

    if (errors.length === 0) {
      this.props.apiLogin({ login, password })
    } else {
      this.setState({ errors })
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  loading: s.user.loading,
  errorApi: s.user.errorApi,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  dropApiError: () => dispatch(Actions.actionEmpty(Actions.DROP_API_ERROR)),
  apiLogin: (data: { login: string, password: string }) => dispatch(Actions.action(Actions.API_LOGIN, data)),
})

export const Login = connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginCmp))
