import { ICustomerApi, IDeliveryAddressApi, IReviewApi } from '../types/TApi'
import { ICustomer, IDeliveryAddress, IReview } from '../types/TClient'

export const CUSTOMERS_LIMIT = 10

export const convertCustomerFromApi = (customer: ICustomerApi): ICustomer => {
  return {
    id: customer.id,
    userId: customer.userId,
    phone: customer.phone,
    registerAt: customer.registerAt,
    business: customer.business,
    ordersCount: customer.ordersCount,
    averageOrderPrice: customer.averageOrderPrice,
    comment: customer.comment,
    ...(customer.firstName && { firstName: customer.firstName }),
    ...(customer.lastName && { lastName: customer.lastName }),
    ...(customer.cityName && { cityName: customer.cityName }),
    ...(customer.firstOrderCreatedAt && { firstOrderCreatedAt: customer.firstOrderCreatedAt }),
    ...(customer.lastOrderCreatedAt && { lastOrderCreatedAt: customer.lastOrderCreatedAt }),
  }
}

export const convertCustomerReviewFromApi = (review: IReviewApi): IReview => {
  return {
    id: review.id,
    orderId: review.orderId,
    orderNum: review.orderNum,
    createdAt: review.createdAt,
    userId: review.userId,
    assessment: review.assessment,
    ...(review.message && { message: review.message }),
  }
}

export const convertCustomerAddressFromApi = (address: IDeliveryAddressApi): IDeliveryAddress => {
  return {
    userId: address.userId,
    address: address.address,
    createdAt: address.createdAt,
  }
}

export const getName = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) {
    return '–'
  }

  return `${firstName || ''} ${lastName || ''}`
}

export const formatPhone = (phone: string) => {
  if (!phone) {
    return ''
  }

  return `${phone.slice(0, 2)} (${phone.slice(2, 5)}) ${phone.slice(5, 8)} ${phone.slice(8, 10)} ${phone.slice(10, 12)}`
}
