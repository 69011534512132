import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Form, Input } from 'semantic-ui-react'

import './index.scss'

import { IProductOrder } from '../../types/TClient'

import messages from '../../localization/messages'
import * as Actions from '../../store/actions'
import { CheckIcon, CloseIcon, EditIcon, PlusIcon, TrashIcon } from '../Icons'
import { round } from '../../utils/ordersUtils'
import { formatPrice, sourcePrice, UNIT_PCS, unitIntl } from '../../utils/productsUtils'
import { countryLocalization } from '../../utils/langUtils'

type TOwnProps = {
  editable: boolean,
  product: IProductOrder,
  orderId?: string,
  preAdd?: boolean,
}

type TDispatchedProps = {
  apiChangeProduct(data: { orderId: string, itemId: string, quantity: number }): Actions.Action,
  updatePreAddProduct(data: IProductOrder): Actions.Action,
  removePreAddProduct(id: string): Actions.Action,
}

type TProps = TOwnProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type TState = {
  active: boolean,
  quantity: string,
  errors: FieldError[],
}

const FIELD_QUANTITY = 'quantity'

class ProductItemCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      active: false,
      quantity: (props.product.quantity || 0).toString(),
      errors: [],
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { product } = this.props

    if (prevProps.product.quantity !== product.quantity) {
      this.setState({ quantity: (product.quantity || 0).toString() })
    }
  }

  render() {
    const { product } = this.props
    const price = round(product.quantity ? product.price * product.quantity : product.price)
    const srcPrice = sourcePrice(
      product.quantity ? product.price * product.quantity : product.price,
      product.sellerCommission,
    )

    return (
      <div key={product.id} className='product-item-container'>
        <div className='product-item'>
          <div className='product-item__image' style={{ backgroundImage: `url(${product.image})` }} />
          <div className='product-item__content'>
            <div className='product-item__info'>
              <div className='product-item__info-item'>
                <div className='product-item__name'>{product.name}</div>
                <div className='product-item__country'>{countryLocalization(product.country)}</div>
              </div>
              <div>
                <div className='product-item__price'>
                  {formatPrice(price, product.currency)}
                  {product.sellerIsCommon && (
                    <div className='product-item__source-price'>({formatPrice(srcPrice, product.currency)})</div>
                  )}
                </div>
                <div className='product-item__unit'>
                  {formatPrice(product.price, product.currency)}
                  <div className='product-item__unit-label'>/{unitIntl(product.unit)}</div>
                </div>
              </div>
            </div>
            {this.renderQuantity()}
          </div>
        </div>
      </div>
    )
  }

  renderQuantity = () => {
    const { editable, product } = this.props
    const { formatMessage } = this.props.intl
    const { errors, active, quantity } = this.state
    const error = errors.length > 0 ? errors[0] : null

    return (
      <div className='product-item__quantity'>
        {editable ? (
          active ? (
            this.renderQuantityForm()
          ) : +quantity && +quantity > 0 ? (
            <div className='product-item__weight'>
              {product.quantity} {unitIntl(product.unit)}
              {editable && (
                <div className='product-item__edit-btn' onClick={this.activeChangeQuantity}>
                  <EditIcon color={'#fff'} />
                </div>
              )}
            </div>
          ) : (
            <div className='product-item__quantity-add' onClick={this.activeChangeQuantity}>
              <div className='product-item__quantity-add__icon'>
                <PlusIcon width={18} height={18} color='#fff' />
              </div>
              <div className='product-item__quantity-add__text'>{formatMessage(messages.Add)}</div>
            </div>
          )
        ) : (
          <div className='product-item__weight'>
            {product.quantity} {unitIntl(product.unit)}
          </div>
        )}
        {error && active && <div className='product-item__error'>{error.message}</div>}
      </div>
    )
  }

  renderQuantityForm = () => {
    const { product } = this.props
    const { quantity } = this.state

    return (
      <Form className='product-item__form'>
        <Input
          label={{ content: unitIntl(product.unit) }}
          labelPosition='right'
          className='product-item__input'
          value={quantity}
          onChange={this.changeQuantity}
        />
        <div
          className='product-item__btn'
          onClick={() => this.updateProduct()}
          style={{ backgroundColor: '#7677bc', marginLeft: '44px' }}
        >
          <div className='product-item__btn-icon'>
            <CheckIcon color={'#fff'} />
          </div>
        </div>
        <div
          className='product-item__btn'
          onClick={() => this.setState({ active: false, quantity: (product.quantity || 0).toString() })}
        >
          <div className='product-item__btn-icon'>
            <CloseIcon color={'#7677bc'} />
          </div>
        </div>
        {+product.quantity > 0 && (
          <div
            className='product-item__btn'
            style={{ borderColor: '#a0a3b5' }}
            onClick={() => this.updateProduct(true)}
          >
            <div className='product-item__btn-icon'>
              <TrashIcon color={'#a0a3b5'} />
            </div>
          </div>
        )}
      </Form>
    )
  }

  activeChangeQuantity = () => {
    this.setState({ active: true, quantity: (this.props.product.quantity || 1).toString(), errors: [] })
  }

  changeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    this.setState({ quantity: value, errors: [] })
  }

  updateProduct = (remove = false) => {
    const { product, preAdd } = this.props
    const { formatMessage } = this.props.intl
    const { quantity } = this.state
    const errors: FieldError[] = []

    if (remove) {
      if (preAdd) {
        this.props.removePreAddProduct(product.id)
      }

      this.setState({ active: false })

      return
    }

    if (Number.isNaN(+quantity)) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.ValueMustNumber) })
    }

    if (product.unit === UNIT_PCS && !Number.isInteger(+quantity)) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.QuantityPcsMustInteger) })
    }

    if (!errors.length) {
      if (preAdd && +quantity && +quantity > 0) {
        this.props.updatePreAddProduct({
          ...product,
          quantity: +quantity,
        })
      }

      this.setState({ active: false })
    } else {
      this.setState({ errors })
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiChangeProduct: (data: { orderId: string, itemId: string, quantity: number }) =>
    dispatch(Actions.action(Actions.ORDER_CHANGE_PRODUCT, data)),
  updatePreAddProduct: (data: IProductOrder) =>
    dispatch(Actions.action(Actions.ORDER_PRE_ADD_PRODUCT, { product: data })),
  removePreAddProduct: (id: string) => dispatch(Actions.action(Actions.ORDER_REMOVE_PRE_ADD_PRODUCT, { id })),
})

export const ProductItem = connect(null, mapDispatchToProps)(injectIntl(ProductItemCmp))
