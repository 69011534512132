import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { getTimeWithTimeZone } from '../../../utils/ordersUtils'

type TOwnProps = {
  order: IOrder,
  withNum?: boolean,
}

type TProps = TOwnProps & WrappedComponentProps

class OrderHeaderCmp extends React.Component<TProps> {
  render() {
    const { order, withNum } = this.props
    const { formatMessage } = this.props.intl
    const createdDate = getTimeWithTimeZone(order.createdAt, order.cityTz).format('D MMMM YYYY')
    const createdTime = getTimeWithTimeZone(order.createdAt, order.cityTz).format('HH:mm')

    return (
      <div className='order__header'>
        <div className='order__header-created'>
          {formatMessage(messages.OrderCreatedAt, {
            date: createdDate,
            time: createdTime,
          })}
          <div className='order__block-dot' />
          {order.web ? formatMessage(messages.WebSite) : formatMessage(messages.MobileApp)}
        </div>
        <div className='order__block-text'>
          {withNum ? `${order.num} / ` : ''}#{order.id}
        </div>
      </div>
    )
  }
}

export const OrderHeader = injectIntl(OrderHeaderCmp)
