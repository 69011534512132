import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, DropdownProps, Form, Icon } from 'semantic-ui-react'

import './index.scss'

import { ECourierRole, EOrderStatus, ICourier, ICouriersFilter, IOrder } from '../../../types/TClient'
import * as TApi from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { convertRole } from '../../../utils/courierUtils'

type TOwnProps = {
  order: IOrder,
  disabled?: boolean,
}

type TConnectedProps = {
  updateLoading: boolean,
  loading: boolean,
  couriers: ICourier[],
}

type TDispatchedProps = {
  apiCouriersList(filter: ICouriersFilter): Actions.Action,
  apiAssignOrder(data: TApi.AssignOrderReq): Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  assignPickerActive: boolean,
  picker?: string,
}

class OrderPickerCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      assignPickerActive: false,
    }
  }

  componentDidMount() {
    const { order } = this.props

    this.props.apiCouriersList({ market: order.marketId })
  }

  render() {
    const { order, disabled } = this.props
    const { formatMessage } = this.props.intl
    const { assignPickerActive } = this.state
    const { pickerId, isCollected, isPicked } = order
    const editablePicker = !disabled && isCollected && !isPicked && order.statusId < EOrderStatus.COURIER
    const isPicker = !!pickerId
    const notAssigned = !editablePicker && !isPicker && !assignPickerActive

    return (
      <div className='order__picker'>
        <div className='order__block-title'>
          {formatMessage(messages.Picker)}
          {editablePicker && isPicker && !assignPickerActive && this.renderEditBtn(this.activeAssignOrder)}
        </div>
        {assignPickerActive && this.renderAssignField()}
        {isPicker && !assignPickerActive && this.renderInfo()}
        {isCollected && !disabled && !isPicker && !assignPickerActive && (
          <>
            {order.statusId < EOrderStatus.COURIER ? (
              <Button className='order__btn' color='blue' onClick={this.activeAssignOrder}>
                {formatMessage(messages.AssignPicker)}
              </Button>
            ) : (
              <>{!notAssigned && <div className='order__block-text'>{formatMessage(messages.NotBeenAssigned)}</div>}</>
            )}
          </>
        )}
        {notAssigned && <div className='order__block-text'>{formatMessage(messages.NotAssigned)}</div>}
      </div>
    )
  }

  renderAssignField = () => {
    const { updateLoading, couriers } = this.props
    const { formatMessage } = this.props.intl
    const { picker } = this.state

    const options = couriers.map((c) => ({
      key: c.id,
      value: c.id,
      description: convertRole(c.role),
      text: this.getPickerName(c.phone, c.firstName, c.lastName),
    }))

    return (
      <Form className='order__picker-form'>
        <Form.Dropdown
          search
          selection
          placeholder={formatMessage(messages.SelectPicker)}
          value={picker}
          options={options}
          onChange={this.onChangePicker}
        />
        <Button
          className='order__btn'
          loading={updateLoading}
          disabled={updateLoading}
          color='blue'
          onClick={this.assignOrder}
        >
          {formatMessage(messages.Save)}
        </Button>
        <div
          className='order__btn-outline'
          onClick={() => this.setState({ assignPickerActive: false, picker: undefined })}
        >
          {formatMessage(messages.Cancel)}
        </div>
      </Form>
    )
  }

  renderInfo = () => {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const { pickerFirstName, pickerLastName, pickerPhone } = order

    return (
      <div className='order__block-row'>
        <div className='order__block-item'>
          <div className='order__block-label'>{formatMessage(messages.Name)}</div>
          <div className='order__block-text'>
            {pickerFirstName ? `${pickerFirstName} ${pickerLastName || ''}` : '–'}
          </div>
        </div>
        <div className='order__block-item'>
          <div className='order__block-label'>{formatMessage(messages.Phone)}</div>
          <div className='order__block-text'>{pickerPhone}</div>
        </div>
      </div>
    )
  }

  renderEditBtn = (action: () => any, color?: string) => {
    return (
      <div className='order__edit-btn' onClick={action} style={{ backgroundColor: color }}>
        <Icon className='order__edit-btn__icon' name='pencil alternate' />
      </div>
    )
  }

  getPickerName = (phone: string, firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
      return phone
    }
    return `${firstName || ''} ${lastName || ''} (${phone})`
  }

  activeAssignOrder = () => {
    const { order } = this.props

    this.setState({ assignPickerActive: true, picker: order.pickerId || undefined })
  }

  assignOrder = () => {
    const { order } = this.props
    const { picker } = this.state

    if (picker) {
      this.props.apiAssignOrder({
        orderId: order.id,
        courierId: picker,
      })

      this.setState({ assignPickerActive: false, picker: undefined })
    }
  }

  onChangePicker = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data

    this.setState({ picker: String(value) })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading, updateLoading } = s.orders

  return {
    updateLoading,
    loading,
    couriers: s.couriers.couriersList.filter((c) => c.role === ECourierRole.MANAGER || c.role === ECourierRole.PICKER),
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiAssignOrder: (data: TApi.AssignOrderReq) => dispatch(Actions.action(Actions.API_ORDER_ASSIGN, data)),
})

export const OrderPicker = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderPickerCmp))
