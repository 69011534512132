import React, { HTMLAttributes, useState } from 'react'
import { Menu } from 'semantic-ui-react'

import './index.scss'

export interface ITab {
  name: string,
  id: string,
  component?: React.ReactNode,
  tabActions?: React.ComponentType<any>,
  disabled?: boolean,
}

type TProps = {
  tabs: ITab[],
} & HTMLAttributes<HTMLDivElement>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Tabs: React.FC<TProps> = ({ tabs, ...htmlProps }) => {
  const [tab, setTab] = useState<string>(tabs.length ? tabs[0].id : '')
  const currentTab = tabs.find((t) => t.id === tab)

  return (
    <div {...htmlProps}>
      <Menu color='blue' pointing secondary>
        {tabs.map((t) => (
          <Menu.Item
            key={t.id}
            name={t.name}
            active={tab === t.id}
            disabled={t.disabled}
            onClick={() => setTab(t.id)}
          />
        ))}
        {currentTab && currentTab.tabActions && <currentTab.tabActions />}
      </Menu>
      <div className='menu-tabs__component'>{currentTab && currentTab.component}</div>
    </div>
  )
}
