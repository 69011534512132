import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import messages from '../../../../localization/messages'
import { Button } from '../../../Button'
import { InputField } from '../../../InputField'

type TState = {
  change: boolean,
  newComment: string,
}

type TOwnProps = {
  clientId: string,
  update: (message: string) => void,
  message?: string,
}

type TProps = TOwnProps & WrappedComponentProps

class _CustomerComment extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      change: false,
      newComment: props.message || '',
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    if (this.props.clientId !== prevProps.clientId) {
      this.resetState()
    }
  }

  render() {
    const { change } = this.state
    const { message } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__info'>
        <div className='customer__block-padding' />
        <div className='customer__block-block'>
          <div className='customer__block-label'>{formatMessage(messages.Comment)}</div>
          {change ? this.renderCommentInput() : <div className='customer__block-text'>{message}</div>}
        </div>
        {this.changeActions()}
      </div>
    )
  }

  renderCommentInput = () => {
    const { newComment } = this.state

    return (
      <div className='customer__comment__comment'>
        <InputField onChange={(_, data) => this.setState({ newComment: data?.value || '' })} value={newComment} />
      </div>
    )
  }

  changeActions = () => {
    const { change } = this.state
    const { message } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__comment__change-actions'>
        {change ? (
          <>
            <Button onClick={this.updateComment} title={formatMessage(messages.Change)} />
            <Button outline onClick={this.resetState} title={formatMessage(messages.Cancel)} />
          </>
        ) : (
          <Button
            onClick={() => this.setState({ change: true })}
            title={formatMessage(message ? messages.Change : messages.Add)}
          />
        )}
      </div>
    )
  }

  resetState = () => this.setState({ change: false, newComment: this.props.message || '' })

  updateComment = () => {
    const { message } = this.props
    const { newComment, change } = this.state

    if (newComment === message && !change) {
      return
    }

    this.props.update(newComment)
    this.setState({ change: false })
  }
}

export const CustomerComment = injectIntl(_CustomerComment)
