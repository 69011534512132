import React from 'react'
import moment from 'moment/moment'

import './index.scss'

type TProps = {
  date: string | Date,
  children?: React.ReactNode,
}

export const Action: React.FC<TProps> = ({ date, children }) => {
  return (
    <div className='action'>
      <div className='action__date'>{moment(date).format('DD.MM.YYYY в HH:mm')}</div>
      <div className='action__content'>{children}</div>
    </div>
  )
}
