import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'
import { ESlotAction, ISlot } from '../../../../../../types/TClient'

import messages from '../../../../../../localization/messages'
import { TimeInput } from '../../../../../../components/TimeInput'
import { TrashIcon } from '../../../../../../components/Icons'

type TProps = {
  slot: ISlot,
  removable: boolean,
  editable: boolean,
  index: number,
  onRemove: VoidFunction,
  onChange: (slot: ISlot) => void,
} & WrappedComponentProps

export enum EInputType {
  FROM,
  TO,
}

class _Slot extends React.Component<TProps> {
  render() {
    const { editable } = this.props

    return editable ? this.renderEditable() : this.renderDefault()
  }

  renderEditable = () => {
    const { slot, index, removable } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='slot'>
        <div className='slot__index'>{index + 1}</div>
        <div>
          <TimeInput
            value={slot.from}
            error={slot.error.includes(EInputType.FROM)}
            placeholder={formatMessage(messages.FromWith)}
            onChange={this.changeSlot(EInputType.FROM)}
          />
          <span className='slot__hyphen'>{'–'}</span>
          <TimeInput
            value={slot.to}
            error={slot.error.includes(EInputType.TO)}
            placeholder={formatMessage(messages.Before)}
            onChange={this.changeSlot(EInputType.TO)}
          />
        </div>
        {removable && (
          <div className='slot__icon' onClick={this.removeSlot}>
            <TrashIcon />
          </div>
        )}
      </div>
    )
  }

  renderDefault = () => {
    const { slot, index } = this.props

    return (
      <div className='slot'>
        <div className='slot__index-default'>{index + 1}</div>
        <div className='slot__index__text'>
          <span>{slot.from}</span>
          {' – '}
          <span>{slot.to}</span>
        </div>
      </div>
    )
  }

  removeSlot = () => {
    const { slot, onChange } = this.props

    onChange({ ...slot, type: ESlotAction.DELETE })
  }

  changeSlot = (type: EInputType) => (value: string) => {
    const { onChange, slot } = this.props
    const filteredSlot = {
      ...slot,
      error: slot.error.filter((e) => e !== type),
      type: !slot.type ? ESlotAction.UPDATE : slot.type,
    }

    const newSlot = type === EInputType.FROM ? { ...filteredSlot, from: value } : { ...filteredSlot, to: value }

    onChange(newSlot)
  }
}

export const Slot = injectIntl(_Slot)
