import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { ESlotAction, ISlot } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import * as Actions from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import { EInputType, Slot } from './Slot'
import { Button } from '../../../../../components/Button'
import { PanIcon } from '../../../../../components/Icons'
import { Alert } from '../../../../../components/Alert'

type TState = {
  edit: boolean,
  slots: ISlot[],
}

type TStateToProps = {
  errorModal: boolean,
  slots: ISlot[],
}

type TOwnProps = {
  market: string,
}

type TDispatchProps = {
  getSlots(market: string): Actions.Action,
  updateSlots(market: string, slots: ISlot[]): Actions.Action,
  hideErrorModal(): Actions.Action,
}

type TProps = TDispatchProps & TStateToProps & TOwnProps & WrappedComponentProps

class SlotsCmp extends Component<TProps, TState> {
  constructor(props: Readonly<TProps>) {
    super(props)

    this.state = {
      edit: false,
      slots: this.props.slots || [],
    }
  }

  componentDidMount() {
    const { getSlots, market } = this.props

    getSlots(market)
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    const { slots, edit } = this.state

    if (this.props.slots !== prevProps.slots) {
      this.setState({ slots: this.props.slots })
    }

    if (!slots.length && edit) {
      this.changeEdit(false)
    }
  }

  render() {
    const { slots, edit } = this.state
    const { errorModal, hideErrorModal } = this.props
    const { formatMessage } = this.props.intl
    const filteredSlots = slots.filter((slot) => slot.type !== ESlotAction.DELETE)

    return (
      <div className='slots'>
        {!edit && !!slots.length && this.renderHeader()}
        <Alert
          show={errorModal}
          timeout={5000}
          error
          onClose={hideErrorModal}
          title={formatMessage(messages.SomeIntervalsNotSaved)}
          text={formatMessage(messages.CannotCreateIntervalsOverlapAnother)}
        />
        {slots.length
          ? filteredSlots.map((slot, index, arr) => (
              <Slot
                removable={arr.length - 1 > 0}
                editable={edit}
                key={slot.id}
                index={index}
                slot={slot}
                onChange={this.changeSlot}
                onRemove={() => this.removeSlot(slot.id)}
              />
            ))
          : this.renderEmptySlots()}
        {edit && this.renderActions()}
      </div>
    )
  }

  renderHeader = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='slots__header'>
        <h3 className='slots__title'>{formatMessage(messages.DeliverySlots)}</h3>
        <div className='slots__header__icon' onClick={() => this.changeEdit(true)}>
          <PanIcon />
        </div>
      </div>
    )
  }

  renderEmptySlots = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='slots__empty'>
        <h3 className='slots__title'>{formatMessage(messages.DeliverySlotsNotSet)}</h3>
        <p className='slots__empty__text'>{formatMessage(messages.HereConfigureDeliverySlots)}</p>
        <Button title={formatMessage(messages.AddSlots)} onClick={this.addSlot} />
      </div>
    )
  }

  renderActions = () => {
    const { formatMessage } = this.props.intl

    return (
      <div className='slots__actions'>
        <div className='slots__actions__add-slot' onClick={this.addSlot}>
          <span className='slots__actions__add-slot__plus'>+ </span>
          {formatMessage(messages.AddSlot)}
        </div>
        <div className='slots__actions__buttons'>
          <div className='slots__actions__buttons__button'>
            <Button title={formatMessage(messages.Cancel)} secondary onClick={this.onCancel} />
          </div>
          <div className='slots__actions__buttons__button'>
            <Button title={formatMessage(messages.Save)} onClick={this.onSave} />
          </div>
        </div>
      </div>
    )
  }

  changeSlot = (slot: ISlot) => {
    this.setState((s) => ({
      slots: s.slots.map((oldSlot) => (oldSlot.id === slot.id ? slot : oldSlot)),
    }))
  }

  removeSlot = (id: string) => this.setState((s) => ({ slots: s.slots.filter((slot) => slot.id !== id) }))

  addSlot = () => {
    const id = Date.now().toString()

    this.setState((s) => ({
      slots: [...s.slots, { id, to: '', from: '', error: [], type: ESlotAction.ADD }],
      edit: true,
    }))
  }

  changeEdit = (edit: boolean) => this.setState({ edit })

  onSave = () => {
    const { updateSlots, market } = this.props
    const { slots } = this.state

    if (this.checkErrors()) {
      return
    }

    this.changeEdit(false)
    const ApiSlots = slots.filter((slot) => slot.type)

    updateSlots(market, ApiSlots)
  }

  onCancel = () => {
    this.resetState()
  }

  resetState = () =>
    this.setState({
      edit: false,
      slots: this.props.slots,
    })

  checkErrors = (): boolean => {
    const { slots } = this.state
    let error = false

    if (!slots.length) {
      return false
    }

    const newSlots = slots.map((slot) => {
      const errors = []

      if (!slot.from) {
        error = true
        errors.push(EInputType.FROM)
      }

      if (!slot.to) {
        error = true
        errors.push(EInputType.TO)
      }

      return { ...slot, error: errors }
    })

    this.setState({ slots: newSlots })

    return error
  }
}

const mapStateToProps = (state: State): TStateToProps => ({
  errorModal: state.markets.updateSlotsErrorModal,
  slots: state.markets.slots,
})

const mapDispatchToProps: TDispatchProps = {
  getSlots: (market: string) => Actions.action(Actions.API_SLOTS, { market }),
  updateSlots: (market: string, slots: ISlot[]) => Actions.action(Actions.API_UPDATE_SLOTS, { slots, market }),
  hideErrorModal: () => Actions.action(Actions.UPDATE_SLOTS_ERROR_MODAL, false),
}

export const Slots = connect<TStateToProps, TDispatchProps, TOwnProps, State>(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SlotsCmp))
