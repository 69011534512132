import React from 'react'
import { Form, DropdownItemProps } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { ChevronUpIcon } from '../Icons'

type TProps = {
  onChange(event: any, data: any): void,
  value: string | number | string[] | number[],
  options: DropdownItemProps[],
  allowAdditions?: boolean,
  search?: boolean,
  multiple?: boolean,
  name?: string,
  label?: string,
  placeholder?: string,
  error?: string,
}

export const DropdownField: React.FC<TProps> = ({
  onChange,
  value,
  options,
  label,
  placeholder,
  name,
  search,
  multiple,
  allowAdditions,
  error,
}) => {
  return (
    <div className='dropdown-field'>
      {!!label && (typeof value !== 'number' ? value.length > 0 : value > 0) && (
        <div className='dropdown-field__label'>{label}</div>
      )}
      <Form.Dropdown
        fluid
        selection
        allowAdditions={allowAdditions}
        search={search}
        multiple={multiple}
        icon={
          <i className='dropdown-field__icon'>
            <ChevronUpIcon color='#a4aabf' />
          </i>
        }
        options={options}
        value={value}
        placeholder={placeholder}
        className={classnames(
          'dropdown-field__input',
          !multiple && 'dropdown-field__input-single',
          multiple &&
            (typeof value !== 'number' ? value.length > 0 : value > 0) &&
            'dropdown-field__input-multiple-with-label',
          !multiple &&
            (typeof value !== 'number' ? value.length > 0 : value > 0) &&
            'dropdown-field__input-single-with-label',
          !!label && !!value && 'dropdown-field__input-with-label',
        )}
        name={name}
        onChange={onChange}
        error={!!error}
      />
    </div>
  )
}
