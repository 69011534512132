import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Modal } from 'semantic-ui-react'

import './index.scss'

import { EImageType, ITag } from '../../../types/TClient'
import { TCreateTag, TUpdateTag } from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { EModalType, IModalImageCropper } from '../../../store/reducers/modals'
import { TagCard } from '../../TagCard'

type TOwnProps = {
  tag?: ITag,
  market: string,
  onClose: () => void,
}

type TConnectedProps = {
  loadingImage: boolean,
  savedImageUrl: string,
}

type TDispatchedProps = {
  // cropImage: (data: { image: File } | { imageUrl: string }) => Actions.Action,
  cropImage: (data: IModalImageCropper) => Actions.Action,
  uploadImage: (file: File, type: EImageType) => Actions.Action,
  dropImage(): Actions.Action,
  createTag(tag: TCreateTag): Actions.Action,
  updateTag(tag: TUpdateTag): Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  changed: boolean,
  title: string,
  color: string,
  position: number,
  image: string,
  errors: FieldError[],
}

const FIELD_TITLE = 'title'
const FIELD_COLOR = 'color'
const FIELD_PRIORITY = 'priority'

const TITLE_LENGTH = 20

class EditCreateTagModalCmp extends React.Component<Props, IState> {
  private imageRef = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)
    const { formatMessage } = props.intl

    this.state = {
      changed: false,
      title: props.tag?.tag || formatMessage(messages.EnterTitle),
      color: props.tag?.color || '#7677BC',
      image: props.tag?.image_url || '',
      position: props.tag?.position || 0,
      errors: [],
    }
  }

  componentDidMount() {
    if (this.props.savedImageUrl) {
      this.setState({ image: this.props.savedImageUrl, changed: true })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.savedImageUrl !== prevProps.savedImageUrl) {
      this.setState({ image: this.props.savedImageUrl, changed: true })
    }
  }

  render() {
    const { tag } = this.props
    const { formatMessage } = this.props.intl
    const { changed } = this.state

    return (
      <>
        <Modal.Header>{tag ? formatMessage(messages.EditTag) : formatMessage(messages.CreateTag)}</Modal.Header>
        <Modal.Content>
          <Form>
            <div className='edit-tag'>
              <div className='edit-tag_field-container'>
                {this.renderTitleField()}
                {this.renderColorField()}
                {this.renderPriorityField()}
                {this.renderImageField()}
              </div>
              {this.renderCard()}
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={this.onClose}>
            {formatMessage(messages.Cancel)}
          </Button>
          <Button positive disabled={!changed} onClick={this.onOk}>
            {formatMessage(messages.Save)}
          </Button>
          <input
            ref={this.imageRef}
            type='file'
            onChange={this.handleChangeImage}
            accept='image/x-png,image/jpeg'
            style={{ display: 'none' }}
          />
        </Modal.Actions>
      </>
    )
  }

  renderCard = () => {
    const { color, title, image } = this.state

    return <TagCard interactive={false} color={color} title={title} imageUrl={image} />
  }

  renderTitleField = () => {
    const { formatMessage } = this.props.intl
    const { title } = this.state
    const error = this.checkError(FIELD_TITLE)

    return (
      <div className='edit-tag_field'>
        <div className='edit-tag_field__label'>{formatMessage(messages.NamePlace)}:</div>
        <div className='edit-tag_field__value edit-tag_field__value-name'>
          <Form.Input
            value={title}
            name={FIELD_TITLE}
            fluid
            onChange={this.handleChange}
            error={error ? error.message : false}
          />
        </div>
      </div>
    )
  }

  renderColorField = () => {
    const { formatMessage } = this.props.intl
    const { color } = this.state
    const error = this.checkError(FIELD_COLOR)

    return (
      <div className='edit-tag_field'>
        <div className='edit-tag_field__label'>{formatMessage(messages.Color)}:</div>
        <div className='edit-tag_field__value edit-tag_field__value-color'>
          <Form.Input
            fluid
            value={color}
            name={FIELD_COLOR}
            type='color'
            onChange={this.handleChange}
            error={error ? error.message : false}
          />
        </div>
      </div>
    )
  }

  renderPriorityField = () => {
    const { formatMessage } = this.props.intl
    const { position } = this.state
    const error = this.checkError(FIELD_PRIORITY)

    return (
      <div className='edit-tag_field'>
        <div className='edit-tag_field__label'>{formatMessage(messages.Priority)}:</div>
        <div className='edit-tag_field__value edit-tag_field__value-priority'>
          <Form.Input
            value={position}
            name={FIELD_PRIORITY}
            fluid
            type='number'
            onChange={this.handleChange}
            error={error ? error.message : false}
          />
        </div>
      </div>
    )
  }

  renderImageField = () => {
    const { formatMessage } = this.props.intl
    const { image } = this.state

    return (
      <div className='edit-tag_field'>
        <div className='edit-tag_field__label'>{formatMessage(messages.Image)}:</div>
        <div className='edit-tag_field__value-image'>
          <Button primary onClick={() => this.uploadImage()}>
            {formatMessage(messages.Download)}
          </Button>
          {!!image && (
            <Button primary onClick={() => this.uploadImage(image)}>
              {formatMessage(messages.Edit)}
            </Button>
          )}
        </div>
      </div>
    )
  }

  onOk = () => {
    const { tag, createTag, market, updateTag } = this.props
    const { errors, position, image, color, title } = this.state

    if (errors.length || !market) {
      return
    }

    if (tag) {
      updateTag({
        ...tag,
        position,
        color,
        tag: title,
        image_url: image,
      })
      this.onClose()
      return
    }

    createTag({
      position,
      color,
      market,
      tag: title,
      image_url: image,
    })

    this.onClose()
  }

  uploadImage = (imageUrl?: string) => {
    const { formatMessage } = this.props.intl

    if (!imageUrl) {
      return this.imageRef.current?.click()
    }

    return this.props.cropImage({
      type: EModalType.MODAL_IMAGE_CROPPER,
      props: {
        title: formatMessage(messages.ImageEditing),
        aspectRatio: 1,
        imageUrl,
        uploadImage: (file: File) => this.props.uploadImage(file, EImageType.TAG_IMAGE),
      },
    })
  }

  handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formatMessage } = this.props.intl
    const { files } = event.target

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    if (files && files.length) {
      this.props.cropImage({
        type: EModalType.MODAL_IMAGE_CROPPER,
        props: {
          title: formatMessage(messages.ImageEditing),
          aspectRatio: 1,
          image: files[0],
          uploadImage: (file: File) => this.props.uploadImage(file, EImageType.TAG_IMAGE),
        },
      })
    }
  }

  handleChange = (event: any, data: any) => {
    const { formatMessage } = this.props.intl
    const { name, value } = data

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    if (name === FIELD_TITLE && value.length > TITLE_LENGTH) {
      this.setState(({ errors }) => ({
        errors: [
          ...errors,
          {
            field: FIELD_TITLE,
            message: formatMessage(messages.NoMoreThanCharacters, { length: TITLE_LENGTH }),
          },
        ],
      }))
      return
    }

    switch (name) {
      case FIELD_TITLE:
        this.setState({ title: value, errors: this.removeError(FIELD_TITLE) })
        break
      case FIELD_COLOR:
        this.setState({ color: value, errors: this.removeError(FIELD_COLOR) })
        break
      case FIELD_PRIORITY:
        this.setState({
          position: +value < 0 ? 0 : value,
          errors: this.removeError(FIELD_PRIORITY),
        })
        break
    }
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  onClose = () => {
    this.props.onClose()
    this.props.dropImage()
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  dropImage: () => dispatch(Actions.actionEmpty(Actions.DROP_TAG_SAVED)),
  createTag: (tag: TCreateTag) => dispatch(Actions.action(Actions.API_CREATE_TAG, tag)),
  updateTag: (tag: TUpdateTag) => dispatch(Actions.action(Actions.API_UPDATE_TAG, tag)),
  cropImage: (data: IModalImageCropper) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  uploadImage: (file: File, type: EImageType) =>
    dispatch(
      Actions.action(Actions.API_UPLOAD_IMAGE, {
        file,
        type,
      }),
    ),
})

const mapStateToProps = (s: State): TConnectedProps => ({
  loadingImage: s.tags.loadingImage,
  savedImageUrl: s.tags.savedImageUrl,
})

export const EditCreateTagModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditCreateTagModalCmp))
