import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import './index.scss'

import { EOrderStatus, IMarket, IOrder } from '../../../../../types/TClient'

import * as Actions from '../../../../../store/actions'
import {
  OrderClient,
  OrderCollector,
  OrderCourier,
  OrderDelivery,
  OrderDivider,
  OrderHeader,
  OrderHistory,
  OrderMarket,
  OrderPayment,
  OrderPaymentType,
  OrderProducts,
  OrderReview,
  OrderSlot,
  OrderTotal,
} from '../../../../../components/OrderInfo'
import { OrderPicker } from '../../../../../components/OrderInfo/OrderPicker'
import { State } from '../../../../../store/reducer'

type TOwnProps = {
  order: IOrder,
}

type TConnectedProps = {
  market?: IMarket,
}

type TDispatchedProps = {
  apiOrdersList(id: string): Actions.Action,
  apiOrderHistory(orderId: string): Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps

class OrderPanelCmp extends React.Component<TProps> {
  componentDidMount() {
    const { apiOrderHistory, order } = this.props

    apiOrderHistory(order.id)
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    const { apiOrderHistory, order } = this.props

    if (order.id !== prevProps.order.id) {
      apiOrderHistory(order.id)
    }
  }

  render() {
    const { order } = this.props

    return (
      <div className='order'>
        {this.renderHeader()}
        {this.renderPayment()}
        {!order.selfPickup && this.renderSlot()}
        {this.renderHistory()}
        {!order.selfPickup && this.renderTotal()}
        {order.review && this.renderReview()}
        {this.renderDelivery()}
        {this.renderPaymentType()}
        {this.renderMarket()}
        {this.renderClient()}
        {this.renderCollector()}
        {(order.isCollected || order.statusId >= EOrderStatus.COURIER) && this.renderPicker()}
        {((order.isCollected && order.isPicked) || order.statusId >= EOrderStatus.COURIER) && this.renderCourier()}
        {this.renderProducts()}
      </div>
    )
  }

  renderHeader = () => {
    return (
      <>
        <OrderHeader order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderPayment = () => {
    return (
      <>
        <OrderPayment order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderSlot = () => {
    return (
      <>
        <OrderSlot order={this.props.order} market={this.props.market} />
        <OrderDivider />
      </>
    )
  }

  renderHistory = () => {
    return (
      <>
        <OrderHistory order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderTotal = () => {
    return (
      <>
        <OrderTotal order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderReview = () => {
    return (
      <>
        <OrderReview order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderDelivery = () => {
    return (
      <>
        <OrderDelivery order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderPaymentType = () => {
    return (
      <>
        <OrderPaymentType order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderMarket = () => {
    return (
      <>
        <OrderMarket order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderClient = () => {
    return (
      <>
        <OrderClient order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderCollector = () => {
    const { order } = this.props

    return (
      <>
        <OrderCollector order={order} disabled={order.statusId >= EOrderStatus.COURIER || order.isCollected} />
        <OrderDivider />
      </>
    )
  }

  renderPicker = () => {
    const { order } = this.props

    return (
      <>
        <OrderPicker order={order} />
        {(order.statusId >= EOrderStatus.COURIER || order.isCollected) && <OrderDivider />}
      </>
    )
  }

  renderCourier = () => {
    return (
      <>
        <OrderCourier order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderProducts = () => {
    const { order } = this.props

    return <OrderProducts order={order} />
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { markets } = s.markets

  const market = markets.find((market) => market.id === own.order.marketId)

  return {
    market,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiOrdersList: (id: string) => dispatch(Actions.action(Actions.API_ORDERS_LIST, { ids: [id] })),
  apiOrderHistory: (orderId: string) => dispatch(Actions.action(Actions.API_ORDER_HISTORY, { orderId })),
})

export const OrderPanel = connect(mapStateToProps, mapDispatchToProps)(OrderPanelCmp)
