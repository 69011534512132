import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import { Dropdown, Icon } from 'semantic-ui-react'

import './index.scss'

import { EModerateProductStatus, IModerationProduct } from '../../types/TClient'
import { ApiUpdateModerationProductReq } from '../../types/TApi'

import messages from '../../localization/messages'
import { EModalType, IModalRejectProduct } from '../../store/reducers/modals'
import * as Actions from '../../store/actions'
import { CheckIcon, CloseIcon, MoreIcon } from '../Icons'
import defaultImg from '../../assets/images/default-image.png'
import { firstModeration } from '../../utils/productsUtils'

type TOwnProps = {
  num: number,
  product: IModerationProduct,
  selectedProductId?: string,
  onSelectProduct: (product: IModerationProduct) => any,
}

type TDispatchedProps = {
  rejectProductModal: (data: IModalRejectProduct) => Actions.Action,
  apiUpdateModerationProduct(data: ApiUpdateModerationProductReq): Actions.Action,
}

type Props = TOwnProps & TDispatchedProps & WrappedComponentProps

class ModerateProductItemCmp extends React.Component<Props> {
  render = () => {
    const { product, selectedProductId } = this.props

    return (
      <div
        className={classnames(
          'moderate-product-item-wrapper',
          product.id === selectedProductId && 'moderate-product-item-wrapper__selected',
        )}
        onClick={() => this.selectProduct(product)}
      >
        <div className='moderate-product-item'>
          {this.renderImage()}
          <div className={classnames('moderate-product-item__value', 'moderate-product-item__name')}>
            {product.name}
          </div>
          <div className={classnames('moderate-product-item__value', 'moderate-product-item__seller')}>
            {product.sellerName} ({product.sellerOrganisation})
            {product.sellerIsCommon && (
              <div className='moderate-product-item__seller-common__icon'>
                <Icon name='home' size='large' />
              </div>
            )}
          </div>
          <div className={classnames('moderate-product-item__value', 'moderate-product-item__actions')}>
            {product.status === EModerateProductStatus.NEW && this.renderActions()}
          </div>
        </div>
      </div>
    )
  }

  renderActions = () => {
    const { formatMessage } = this.props.intl

    return (
      <Dropdown
        className='moderate-product-item__actions-dropdown'
        icon={
          <div className='moderate-product-item__actions-icon'>
            <MoreIcon color='#a0a3b5' />
          </div>
        }
      >
        <Dropdown.Menu style={{ left: '-32px', borderRadius: '12px' }}>
          <Dropdown.Item onClick={this.approveProduct}>{formatMessage(messages.Approve)}</Dropdown.Item>
          <Dropdown.Item onClick={this.rejectProduct}>{formatMessage(messages.Reject)}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderImage = () => {
    const { product } = this.props
    const isFirst = firstModeration(product)

    return (
      <div className={classnames('moderate-product-item__value', 'moderate-product-item__photo')}>
        {product.status !== EModerateProductStatus.NEW ? (
          this.renderImageStatus()
        ) : (
          <div
            className='moderate-product-item__photo-state'
            style={{ backgroundColor: isFirst ? '#5bc258' : '#f5934d' }}
          />
        )}
        <img
          alt={'product-image'}
          className='moderate-product-item__photo-image'
          src={product.imageUrl ? product.imageUrl : defaultImg}
        />
      </div>
    )
  }

  renderImageStatus = () => {
    const { product } = this.props

    return (
      <div
        className='moderate-product-item__photo-status'
        style={{ backgroundColor: this.getColorStatus(product.status) }}
      >
        <div className='moderate-product-item__photo-status__icon'>
          {product.status === EModerateProductStatus.APPROVED && <CheckIcon width={20} height={20} color='#ffffff' />}
          {product.status === EModerateProductStatus.REJECTED && <CloseIcon width={14} height={14} color='#ffffff' />}
        </div>
      </div>
    )
  }

  getColorStatus = (status: EModerateProductStatus) => {
    switch (status) {
      case EModerateProductStatus.APPROVED:
        return '#5bc258'
      case EModerateProductStatus.REJECTED:
        return '#d8663f'
    }
  }

  selectProduct = (product: IModerationProduct) => {
    this.props.onSelectProduct(product)
  }

  approveProduct = () => {
    const { product } = this.props

    this.props.apiUpdateModerationProduct({
      id: product.id,
      status: EModerateProductStatus.APPROVED,
    })
  }

  rejectProduct = () => {
    this.props.rejectProductModal({
      type: EModalType.MODAL_REJECT_PRODUCT,
      size: 'mini',
      style: { width: '600px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        product: this.props.product,
      },
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  rejectProductModal: (data: IModalRejectProduct) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  apiUpdateModerationProduct: (data: ApiUpdateModerationProductReq) =>
    dispatch(Actions.action(Actions.API_UPDATE_MODERATION_PRODUCT, data)),
})

export const ModerateProductItem = connect(null, mapDispatchToProps)(injectIntl(ModerateProductItemCmp))
