import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { ApiCustomerAddressesReq } from '../../../types/TApi'
import { ICustomer, IDeliveryAddress } from '../../../types/TClient'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'

type TOwnProps = {
  customer: ICustomer,
}

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  addresses: IDeliveryAddress[],
  total: number,
}

type TDispatchedProps = {
  apiCustomerAddresses: (data: ApiCustomerAddressesReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  pages: number,
}

const PAGE_SIZE = 3

class CustomerAddressesCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      pages: 1,
    }
  }

  componentDidMount(): void {
    const { customer } = this.props

    this.props.apiCustomerAddresses({
      userId: customer.userId,
      offset: 0,
    })
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { customer } = this.props

    if (customer.userId !== prevProps.customer.userId) {
      this.props.apiCustomerAddresses({
        userId: customer.userId,
        offset: 0,
      })
    }
  }

  render() {
    const { addresses, loaded, total } = this.props
    const { formatMessage } = this.props.intl
    const { pages } = this.state

    return (
      <div className='customer__addresses'>
        <div className='customer__block-title'>
          {formatMessage(messages.DeliveryAddresses)} {total ? `(${total})` : ''}
        </div>
        {addresses.length > 0 ? (
          <div className='customer__addresses-list'>
            {addresses.slice(0, pages * PAGE_SIZE).map(this.renderItem)}
            {!loaded && addresses.length >= pages * PAGE_SIZE && this.renderShowRest()}
          </div>
        ) : (
          this.renderEmpty()
        )}
      </div>
    )
  }

  renderEmpty() {
    const { formatMessage } = this.props.intl

    return <div className='customer__block-label'>{formatMessage(messages.CustomerNoOrder)}</div>
  }

  renderItem(address: IDeliveryAddress, index: number) {
    return (
      <div key={index} className='customer__addresses-item'>
        <div className='customer__addresses-item__address'>{address.address}</div>
      </div>
    )
  }

  renderShowRest() {
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__addresses__show' onClick={this.getPageReviews}>
        {formatMessage(messages.ShowMore)}
      </div>
    )
  }

  getPageReviews = () => {
    const { customer, addresses } = this.props
    const { pages } = this.state

    this.setState({ pages: pages + 1 })

    if (addresses.length <= addresses.slice(0, (pages + 1) * PAGE_SIZE).length) {
      this.props.apiCustomerAddresses({
        userId: customer.userId,
        offset: addresses.length,
      })
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { addresses } = s.customers

  const { customer } = own

  return {
    loading: addresses.loading,
    loaded: addresses.loaded,
    addresses: addresses.list.filter((item) => item.userId === customer.userId),
    total: addresses.total,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCustomerAddresses: (data: ApiCustomerAddressesReq) =>
    dispatch(Actions.action(Actions.API_CUSTOMER_ADDRESSES, data)),
})

export const CustomerAddresses = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerAddressesCmp))
