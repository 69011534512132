import React from 'react'

import './index.scss'

type TProps = {
  data: number,
  disabled?: boolean,
  title?: string,
}

export class SlotChart extends React.Component<TProps> {
  canvas = React.createRef<HTMLCanvasElement>()
  scale = 2
  width = 190 * 2
  height = 98 * 2
  diff = 1.05

  componentDidMount() {
    const { data, disabled } = this.props

    this.draw(data, disabled)
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    const { data, disabled } = this.props

    if (prevProps.data !== this.props.data || prevProps.disabled !== this.props.disabled) {
      this.draw(data, disabled)
    }
  }

  draw(percent: number, disabled?: boolean) {
    const ctx = this.canvas.current?.getContext('2d')

    if (!ctx) {
      return
    }

    const lineColor = disabled ? '#BEC2CD' : this.createGradient(ctx)
    this.drawArc(ctx, 100, '#F3F5F9')
    this.drawArc(ctx, percent, lineColor)
  }

  render() {
    const { data, title } = this.props
    return (
      <div className='slot-chart'>
        <div className='slot-chart__content'>
          <div className='slot-chart__content-title'>{data}%</div>
          <div className='slot-chart__content-desc'>{title}</div>
        </div>
        <canvas className='slot-chart__canvas' ref={this.canvas} width={this.width} height={this.height} />
      </div>
    )
  }

  drawArc = (ctx: CanvasRenderingContext2D, percent = 0, color: string | CanvasGradient = '') => {
    const lineWidth = 13 * this.scale
    const x = this.width / 2
    const y = this.height

    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.lineCap = 'round'

    ctx.beginPath()
    ctx.arc(x, y, this.height / 1.1, Math.PI * this.diff, this.piToPercent(percent), false)
    ctx.stroke()

    ctx.closePath()
  }

  piToPercent = (percent: number) => {
    let filteredPercent = Math.abs(percent)

    if (filteredPercent <= 10) {
      filteredPercent = 7
    }

    if (filteredPercent >= 95) {
      filteredPercent = 100
    }

    if (filteredPercent < 95 && filteredPercent > 10) {
      filteredPercent += 2
    }

    return Math.PI + (Math.PI / this.diff / 100) * filteredPercent
  }

  createGradient = (ctx: CanvasRenderingContext2D) => {
    const gradient = ctx.createLinearGradient(50, 0, 330, 0)

    gradient.addColorStop(0, '#6EF68D')
    gradient.addColorStop(0.5, '#FBE600')
    gradient.addColorStop(1, '#FB0000')

    return gradient
  }
}
