import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Icon, Placeholder } from 'semantic-ui-react'
import classnames from 'classnames'

import './index.scss'

import { IProductOrder, ISeller } from '../../types/TClient'

import messages from '../../localization/messages'
import { State } from '../../store/reducer'
import defaultImg from '../../assets/images/default-image.png'
import defaultAvatar from '../../assets/images/avatar-placeholder.png'

type TOwnProps = {
  seller: ISeller,
  byProduct?: boolean,
  toSeller?(seller: ISeller): void,
  disabled?: boolean,
}

type TConnectedProps = {
  preAddProducts: IProductOrder[],
}

type TProps = TOwnProps & TConnectedProps & WrappedComponentProps

type TState = {
  imgLoading: boolean,
  avatarLoading: boolean,
}

class SellerItemCmp extends React.Component<TProps, TState> {
  state = {
    imgLoading: true,
    avatarLoading: true,
  }

  render() {
    const { seller, disabled, toSeller } = this.props

    return (
      <div
        className={classnames('seller-item', disabled ? 'seller-item__disabled' : 'seller-item__active')}
        onClick={toSeller ? () => toSeller(seller) : undefined}
      >
        {(seller.isSystem || seller.isCommon) && (
          <div className='seller-item__types'>
            {seller.isSystem && (
              <div className='seller-item__types-item'>
                <Icon name='id card outline' size='large' />
              </div>
            )}
            {seller.isCommon && (
              <div className='seller-item__type-item'>
                <Icon name='home' size='large' />
              </div>
            )}
          </div>
        )}
        {this.renderImage()}
        {this.renderInfo()}
      </div>
    )
  }

  renderImage() {
    const { seller } = this.props
    const { imgLoading, avatarLoading } = this.state

    return (
      <div className='seller-item-image'>
        <div className='seller-item__shop'>
          {imgLoading && (
            <Placeholder className='seller-item__placeholder'>
              <Placeholder.Image square />
            </Placeholder>
          )}
          <img
            alt='seller'
            className='seller-item__image'
            src={seller.imageUrl ? seller.imageUrl : defaultImg}
            onLoad={() => this.setImgLoading(false)}
          />
        </div>
        <div className='seller-item__avatar'>
          {avatarLoading && (
            <Placeholder className='seller-item__placeholder'>
              <Placeholder.Image square />
            </Placeholder>
          )}
          <img
            alt='avatar'
            className='seller-item__image'
            onLoad={() => this.setAvatarLoading(false)}
            src={seller.avatarUrl ? seller.avatarUrl : defaultAvatar}
          />
        </div>
      </div>
    )
  }

  renderInfo() {
    const { seller, byProduct, preAddProducts } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='seller-item-info'>
        <div className='seller-item-info__name'>
          {seller.name || `<${formatMessage(messages.Missing)}>`}
          {seller.isHide && <span className='seller-item-info__hidden'>({formatMessage(messages.Hidden)})</span>}
        </div>
        <div className='seller-item-info__organization'>{seller.organization}</div>
        <div className='seller-item-info__row'>
          <div className='seller-item-info__nickname'>{seller.nickname || `<${formatMessage(messages.Missing)}>`}</div>
          <div className='seller-item-info__dot' />
          <div className='seller-item-info__location'>{seller.location || `<${formatMessage(messages.Missing)}>`}</div>
          <div className='seller-item-info__dot' />
          <div className='seller-item-info__commission'>{seller.commission}%</div>
          {byProduct && preAddProducts.length > 0 && this.renderPreAddedProducts()}
        </div>
      </div>
    )
  }

  renderPreAddedProducts = () => {
    const { preAddProducts } = this.props
    const { formatMessage } = this.props.intl

    return (
      <>
        <div className='seller-item-info__dot' />
        <div className='seller-item-info__added-products'>
          {formatMessage(messages.Added)}: {preAddProducts.length}
        </div>
      </>
    )
  }

  setImgLoading = (loading: boolean) => {
    this.setState({ imgLoading: loading })
  }

  setAvatarLoading = (loading: boolean) => {
    this.setState({ avatarLoading: loading })
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { preAddProducts } = s.products

  return {
    preAddProducts: preAddProducts.filter((item) => item.sellerId === own.seller.id),
  }
}

export const SellerItem = connect(mapStateToProps)(injectIntl(SellerItemCmp))
