import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { IModerationProduct } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { Button } from '../../../../components/Button'
import { ProductPanel } from '../ModerationProducts/ProductPanel'
import { LOCATION_MODERATION_PRODUCTS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  loading: boolean,
  product?: IModerationProduct,
}

type TDispatchedProps = {
  apiProductsList: (id: string) => Actions.Action,
}

type TProps = RouteComponentProps<{ id: string }> & TConnectedProps & TDispatchedProps & WrappedComponentProps

class ModerationProductCmp extends React.Component<TProps> {
  componentDidMount(): void {
    const { product } = this.props

    if (!product) {
      this.props.apiProductsList(this.props.match.params.id)
    }
  }

  render() {
    const { product } = this.props

    if (!product) {
      return null
    }

    return (
      <div className='moderation-product-container'>
        {this.renderBack()}
        <ProductPanel product={product} />
      </div>
    )
  }

  renderBack() {
    const { formatMessage } = this.props.intl

    return (
      <div className='moderation-product__back'>
        <Button secondary title={formatMessage(messages.Back)} onClick={this.back} />
      </div>
    )
  }

  back = () => {
    this.props.history.push(`${LOCATION_MODERATION_PRODUCTS}`)
  }
}

const mapStateToProps = (s: State, own: TProps): TConnectedProps => {
  const { list, loading } = s.products.moderationProducts
  const product = list.find((item) => item.id === own.match.params.id)

  if (!product && list && list.length > 0 && !loading) {
    own.history.push(`${LOCATION_MODERATION_PRODUCTS}`)
  }

  return {
    loading,
    product,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiProductsList: (id: string) => dispatch(Actions.action(Actions.API_PRODUCTS_MODERATION_LIST, { ids: [id] })),
})

export const ModerationProduct = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModerationProductCmp))
