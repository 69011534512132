import React from 'react'
import { FormattedMessage } from 'react-intl'

import './index.scss'

import { HistoryPayedAction } from '../../../../types/TClient'

import { Action } from '../Action'
import messages from '../../../../localization/messages'

type TProps = HistoryPayedAction

const _PayedAction: React.FC<TProps> = ({ date }) => {
  return (
    <Action date={date}>
      <div className='payed-action'>
        <FormattedMessage {...messages.OrderPayed} />
      </div>
    </Action>
  )
}

export const PayedAction = _PayedAction
