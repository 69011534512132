import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { ICustomer } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { CustomerPanel } from '../Customers/CustomerPanel'
import { Button } from '../../../../components/Button'
import { LOCATION_CUSTOMERS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  loading: boolean,
  customer?: ICustomer,
}

type TDispatchedProps = {
  apiCustomersList: (id: number) => Actions.Action,
}

type TProps = RouteComponentProps<{ userId: string }> & TConnectedProps & TDispatchedProps & WrappedComponentProps

class CustomerCmp extends React.Component<TProps> {
  componentDidMount(): void {
    const { customer } = this.props

    if (!customer) {
      this.props.apiCustomersList(+this.props.match.params.userId)
    }
  }

  render() {
    const { customer } = this.props

    if (!customer) {
      return null
    }

    return (
      <div className='customer-container'>
        {this.renderBack()}
        <CustomerPanel customer={customer} />
      </div>
    )
  }

  renderBack() {
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__back'>
        <Button secondary title={formatMessage(messages.Back)} onClick={this.back} />
      </div>
    )
  }

  back = () => {
    this.props.history.push(`${LOCATION_CUSTOMERS}`)
  }
}

const mapStateToProps = (s: State, own: TProps): TConnectedProps => {
  const { customersList, loading } = s.customers
  const customer = customersList.find((item) => item.userId === +own.match.params.userId)

  if (!customer && customersList && customersList.length > 0 && !loading) {
    own.history.push(`${LOCATION_CUSTOMERS}`)
  }

  return {
    loading,
    customer,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCustomersList: (id: number) => dispatch(Actions.action(Actions.API_CUSTOMERS_LIST, { ids: [id] })),
})

export const Customer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerCmp))
