import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header, Table } from 'semantic-ui-react'
import moment from 'moment'
import 'moment/locale/ru'

import './index.scss'

import { INotificationInfo } from '../../../../types/TClient'
import { ApiNotificationsLogReq, EAuditory, MarketingPushType } from '../../../../types/TApi'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'

export const PUSH_LIMIT = 10

type TConnectedProps = {
  notificationsLog: INotificationInfo[],
  loading: boolean,
}

type TDispatchedProps = {
  getLog: (data: ApiNotificationsLogReq) => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & WrappedComponentProps

class NotificationsLogCmp extends Component<TProps> {
  ref!: HTMLDivElement

  componentDidMount() {
    this.props.getLog({ offset: 0 })
  }

  render() {
    const { notificationsLog } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='notify-log' onScroll={this.checkEnd} ref={this.saveRef}>
        <Header as='h1'>{formatMessage(messages.SentNotifications)}</Header>
        <Table celled striped selectable>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>{formatMessage(messages.SentNotifications)}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage(messages.NamePlace)}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage(messages.Type)}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage(messages.UserType)}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage(messages.Quantity)}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage(messages.DepartureDate)}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{notificationsLog.map(this.renderRow)}</Table.Body>
        </Table>
      </div>
    )
  }

  renderRow = ({ id, title, body, usersCount, marketingPushType, sendDate, auditory }: INotificationInfo) => (
    <Table.Row key={id}>
      <Table.Cell>{title}</Table.Cell>
      <Table.Cell>{body}</Table.Cell>
      <Table.Cell>{this.pushTypeToText(marketingPushType)}</Table.Cell>
      <Table.Cell>{this.auditoryToText(auditory)}</Table.Cell>
      <Table.Cell>{usersCount}</Table.Cell>
      <Table.Cell>{moment(sendDate).format('llll')}</Table.Cell>
    </Table.Row>
  )

  pushTypeToText = (type: MarketingPushType): string => {
    const { formatMessage } = this.props.intl

    switch (type) {
      case MarketingPushType.SINGLE:
        return formatMessage(messages.Single)
      case MarketingPushType.SCHEDULE:
        return formatMessage(messages.Scheduled)
      default:
        return formatMessage(messages.Single)
    }
  }

  auditoryToText = (auditory: EAuditory): string => {
    const { formatMessage } = this.props.intl

    switch (auditory) {
      case EAuditory.ALL:
        return formatMessage(messages.AllUsers)
      case EAuditory.REGISTERED:
        return formatMessage(messages.RegisteredUsers)
      case EAuditory.NOT_REGISTERED:
        return formatMessage(messages.UnregisteredUsers)
      default:
        return formatMessage(messages.Single)
    }
  }

  saveRef = (ref: HTMLDivElement) => {
    this.ref = ref
  }

  checkEnd = () => {
    const { getLog, loading, notificationsLog } = this.props
    const { scrollHeight, offsetHeight, scrollTop } = this.ref

    if (!loading && scrollHeight - offsetHeight - scrollTop < 300 && !(notificationsLog.length % PUSH_LIMIT)) {
      getLog({ offset: this.props.notificationsLog.length })
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  notificationsLog: s.notifications.notificationsLog,
  loading: s.notifications.logLoading,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getLog: (data: ApiNotificationsLogReq) => dispatch(Actions.action(Actions.API_NOTIFICATIONS_LOG, data)),
})

export const NotificationsLog = connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationsLogCmp))
