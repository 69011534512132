import { Reducer } from 'redux'
import moment from 'moment'

import { IAnalyticsFilter, ICurrencyPairs, ITotalOrders } from '../../types/TClient'

import * as Actions from '../actions'

export type MutableStateAnalytics = {
  loading: boolean,
  filter: IAnalyticsFilter,
  totalOrders: ITotalOrders[],
  currencyPairs: ICurrencyPairs,
  newCustomers: number,
}

export type StateAnalytics = Readonly<MutableStateAnalytics>

const defStateAnalytics: StateAnalytics = {
  loading: false,
  filter: {
    dateFrom: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    dateTo: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  },
  totalOrders: [],
  currencyPairs: {},
  newCustomers: 0,
}

export const analytics: Reducer<StateAnalytics, Actions.Action> = (s = defStateAnalytics, a): StateAnalytics => {
  switch (a.type) {
    case Actions.API_TOTAL_ORDERS:
      return {
        ...s,
        loading: true,
      }
    case Actions.TOTAL_ORDERS:
      return {
        ...s,
        loading: false,
        totalOrders: a.data.totalOrders,
        ...(a.data.currencyPairs && { currencyPairs: a.data.currencyPairs }),
      }
    case Actions.NEW_CUSTOMERS:
      return {
        ...s,
        newCustomers: a.data.data,
      }
  }
  return s
}
