import { Reducer } from 'redux'
import { uniqBy } from 'lodash'

import { IClientProfile, INotification, INotificationInfo } from '../../types/TClient'

import * as Actions from '../actions'
import { CLIENTS_RESET, CLIENTS_SUCCESS } from '../actions'

export type MutableStateNotifications = {
  updateLoading: boolean,
  loading: boolean,
  loaded: boolean,
  logLoading: boolean,
  notificationsList: INotification[],
  notificationsLog: INotificationInfo[],
  clients: IClientProfile[],
}

export type StateNotifications = Readonly<MutableStateNotifications>

const defStateNotifications: StateNotifications = {
  updateLoading: false,
  loading: false,
  loaded: false,
  logLoading: false,
  notificationsList: [],
  notificationsLog: [],
  clients: [],
}

const NOTIFICATIONS_LIMIT = 10

export const notifications: Reducer<StateNotifications, Actions.Action> = (
  s = defStateNotifications,
  a,
): StateNotifications => {
  switch (a.type) {
    case Actions.API_NOTIFICATIONS_LIST:
      return {
        ...s,
        logLoading: true,
      }
    case Actions.NOTIFICATIONS_LIST:
      return {
        ...s,
        loading: false,
        loaded: a.data.notifications.length < NOTIFICATIONS_LIMIT,
        notificationsList: uniqBy([...(s.notificationsList || []), ...a.data.notifications], (elem) =>
          [elem.type, elem.id].join('_'),
        ),
      }
    case Actions.NOTIFICATIONS_LOG:
      return {
        ...s,
        logLoading: false,
        notificationsLog: uniqBy([...a.data.notifications, ...(s.notificationsLog || [])], (elem) =>
          [elem.marketingPushId, elem.id].join('_'),
        ).sort((a, b) => new Date(b.sendDate).getTime() - new Date(a.sendDate).getTime()),
      }
    case Actions.API_NOTIFICATIONS_LOG_ERROR: {
      return {
        ...s,
        logLoading: false,
      }
    }
    case Actions.API_NOTIFICATION_CREATE:
      return {
        ...s,
        loading: true,
      }
    case Actions.NOTIFICATION_CREATE:
      return {
        ...s,
        updateLoading: false,
        loading: false,
        notificationsList: [a.data, ...s.notificationsList],
      }
    case Actions.API_NOTIFICATION_UPDATE:
      return {
        ...s,
        loading: true,
      }
    case Actions.NOTIFICATION_UPDATE:
      return {
        ...s,
        updateLoading: false,
        loading: false,
        notificationsList: s.notificationsList.map((notification) => {
          if (notification.id === a.data.id) {
            return {
              ...notification,
              ...a.data,
            }
          }

          return notification
        }),
      }
    case Actions.API_NOTIFICATION_REMOVE:
      return {
        ...s,
        loading: true,
      }
    case Actions.NOTIFICATION_REMOVE:
      return {
        ...s,
        updateLoading: false,
        loading: false,
        notificationsList: s.notificationsList.filter((notification) => {
          return !(notification.id === a.data.id && notification.type === a.data.type)
        }),
      }
    case CLIENTS_SUCCESS:
      return {
        ...s,
        clients: uniqBy(a.data, 'id'),
      }
    case CLIENTS_RESET:
      return {
        ...s,
        clients: [],
      }
    case Actions.NOTIFICATIONS_RESET_LOADING:
      return {
        ...s,
        loading: false,
        updateLoading: false,
        loaded: false,
      }
  }
  return s
}
