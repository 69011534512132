import React from 'react'
import { Form } from 'semantic-ui-react'
import moment from 'moment'

import '../index.scss'

import { IOrder } from '../../../types/TClient'

import { getTimeWithTimeZone } from '../../../utils/ordersUtils'

type IProps = {
  name: string,
  onChange(event: any, data: any): void,
  order: IOrder,
  date?: Date,
  error?: string | boolean,
  customMargin?: number,
  disabled?: boolean,
}

export const DeliveryDatePicker: React.FC<IProps> = ({ order, name, error, date, onChange, disabled }) => {
  const { deliveryDate } = order

  return (
    <div className='picker-item'>
      {!disabled ? (
        <Form.Input
          className='order-info__field-value'
          value={moment(date || new Date()).format('YYYY-MM-DD')}
          name={name}
          type='date'
          min={moment(deliveryDate || new Date()).format('YYYY-MM-DD')}
          onChange={onChange}
          error={error}
        />
      ) : (
        <div>{getTimeWithTimeZone(deliveryDate).format('DD.MM.YYYY')}</div>
      )}
    </div>
  )
}
