import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Card, Header, Icon } from 'semantic-ui-react'

import './index.scss'

import { ISellerProduct, ITag } from '../../../../types/TClient'
import { TagProductsReq } from '../../../../types/TApi'

import messages from '../../../../localization/messages'
import * as Actions from '../../../../store/actions'
import { State } from '../../../../store/reducer'
import { EModalType, IModalEditProductTag } from '../../../../store/reducers/modals'
import { ProductCard } from '../../../../components/ProductCard'
import { LOCATION_TAGS } from '../../../../utils/locationUtils'

type TConnectedProps = {
  tag?: ITag,
  products: ISellerProduct[],
  marketId: string,
  tagId: number,
}

type TDispatchedProps = {
  getProducts(data: TagProductsReq): Actions.Action,
  getTags(market_id: string): Actions.Action,
  productChangeTagModal(data: IModalEditProductTag): Actions.Action,
}

type TProps = RouteComponentProps<{ marketId: string, tagId: string }> &
  TDispatchedProps &
  TConnectedProps &
  WrappedComponentProps

class TagProductsCmp extends Component<TProps> {
  componentDidMount() {
    const { marketId, tagId } = this.props

    this.props.getProducts({ marketId, tagId })
    this.props.getTags(marketId)
  }

  render() {
    const { tag, products } = this.props
    const { formatMessage } = this.props.intl

    if (!tag) {
      return null
    }

    return (
      <div className='products-container'>
        <Button icon labelPosition='left' onClick={this.onBack}>
          <Icon name='arrow left' />
          {formatMessage(messages.Back)}
        </Button>
        <Header as='h1'>
          {formatMessage(messages.Tag)}: {tag.tag}
        </Header>
        <div className='products-container_list'>
          <Card.Group>
            {products.map((p) => (
              <ProductCard key={p.id} product={p} showModal={() => this.changeProductTag(p)} />
            ))}
          </Card.Group>
        </div>
      </div>
    )
  }

  onBack = () => {
    const { marketId } = this.props

    this.props.history.push(`${LOCATION_TAGS}/${marketId}`)
  }

  changeProductTag = (product: ISellerProduct) => {
    const { productChangeTagModal } = this.props
    const { formatMessage } = this.props.intl

    productChangeTagModal({
      type: EModalType.MODAL_EDIT_PRODUCT_TAG,
      props: {
        product,
        title: formatMessage(messages.ChangeProductTag),
      },
    })
  }
}

const mapStateToProps = (s: State, own: TProps): TConnectedProps => {
  const { marketId, tagId } = own.match.params

  if (!marketId || !tagId) {
    own.history.push(LOCATION_TAGS)
  }

  return {
    marketId,
    tagId: +tagId,
    tag: s.tags.tags.find((t) => t.id === +tagId),
    products: s.products.sellersProducts,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getProducts: (data: TagProductsReq) => dispatch(Actions.action(Actions.API_TAG_PRODUCTS, data)),
  getTags: (market_id: string) => dispatch(Actions.action(Actions.API_GET_TAGS, { market_id })),
  productChangeTagModal: (data: IModalEditProductTag) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
})

export const TagProducts = connect(mapStateToProps, mapDispatchToProps)(injectIntl(TagProductsCmp))
