import { Reducer } from 'redux'

import { EImageType, ITag } from '../../types/TClient'

import * as Actions from '../actions'
import { sortTags } from '../../utils/tagsUtils'

export type MutableStateTags = {
  loadingImage: boolean,
  savedImageUrl: string,
  tags: ITag[],
}

export type StateTags = Readonly<MutableStateTags>

const defStateReports: StateTags = {
  loadingImage: false,
  savedImageUrl: '',
  tags: [],
}

export const tags: Reducer<StateTags, Actions.Action> = (s = defStateReports, a): StateTags => {
  switch (a.type) {
    case Actions.TAG_SAVED_IMAGE:
      return {
        ...s,
        loadingImage: false,
        savedImageUrl: a.data.imageUrl,
      }
    case Actions.DROP_TAG_SAVED:
      return {
        ...s,
        loadingImage: false,
        savedImageUrl: '',
      }
    case Actions.API_UPLOAD_IMAGE: {
      if (a.data.type === EImageType.TAG_IMAGE) {
        return {
          ...s,
          loadingImage: true,
        }
      }

      return s
    }
    case Actions.API_UPLOAD_IMAGE_ERROR: {
      if (a.data.type === EImageType.TAG_IMAGE) {
        return {
          ...s,
          loadingImage: false,
        }
      }

      return s
    }
    case Actions.GET_TAGS:
      return {
        ...s,
        tags: sortTags(a.data),
      }
    case Actions.CREATE_TAG:
      return {
        ...s,
        tags: sortTags([...s.tags, a.data]),
      }
    case Actions.UPDATE_TAG:
      return {
        ...s,
        tags: sortTags(s.tags.map((el) => (el.id === a.data.id ? a.data : el))),
      }
    case Actions.REMOVE_TAG:
      return {
        ...s,
        tags: sortTags(s.tags.filter((t) => t.id !== a.data)),
      }
  }
  return s
}
