import { of as observableOf, EMPTY, concat } from 'rxjs'
import { catchError, concatAll, delay, map, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'
import { isEqual } from 'lodash'

import * as TApi from '../../types/TApi'
import { ESlotAction } from '../../types/TClient'
import { ISlotInfoResp } from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, guardMergeMap, ofType } from './epicHelpers'
import {
  URL_CITIES,
  URL_MARKETS,
  URL_DELIVERY_AREA_ADD,
  URL_DELIVERY_AREA_REMOVE,
  URL_DELIVERY_AREA_UPDATE,
  URL_SLOTS_LIST,
  URL_SLOTS_UPDATE,
  URL_MARKET_CATEGORIES,
  URL_MARKET_CATEGORY_CREATE,
  URL_MARKET_CATEGORY_UPDATE,
  URL_MARKET_CATEGORY_REMOVE,
  URL_MARKET_SUBCATEGORY_CREATE,
  URL_MARKET_SUBCATEGORY_UPDATE,
  URL_MARKET_SUBCATEGORY_REMOVE,
  URL_SLOTS_INFO,
  URL_MARKET_GROUPS,
  URL_MARKET_GROUP_UPDATE,
  URL_MARKET_GROUP_REMOVE,
  URL_MARKET_GROUP_CREATE,
  URL_MARKET_ROUTES,
  URL_MARKET_ROUTE_UPDATE,
  URL_MARKET_ROUTE_REMOVE,
  URL_MARKET_ROUTE_CREATE,
} from '../../modules/network/urls'
import { authRequestConfig } from '../../utils/requestUtils'
import { apiSlotToClient, clientSlotToApi, convertMarketFromApi } from '../../utils/marketsUtils'
import { convertGroupFromApi } from '../../utils/productsUtils'
import { convertRouteFromApi } from '../../utils/courierUtils'
import { EAlertType } from '../reducers/alerts'
import { intlMessage } from '../../App/LangContainer'
import messages from '../../localization/messages'

const getCitiesEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCities>(a$, Actions.API_CITIES), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_CITIES, {
          ...authRequestConfig(),
          params: c.data,
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCitiesResp }) => {
            if (resp.data && resp.data.records) {
              return observableOf<Actions.Action>(Actions.action(Actions.CITIES, { cities: resp.data.records }))
            }

            return EMPTY
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  )

const getMarketsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiMarkets>(a$, Actions.API_MARKETS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_MARKETS, {
          ...authRequestConfig(),
          params: c.data,
        }).pipe(
          mergeMap((resp: { data: TApi.ApiMarketsResp }) => {
            if (resp.data && Array.isArray(resp.data)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.MARKETS, {
                  markets: resp.data.map(convertMarketFromApi),
                }),
              )
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const saveDeliveryAreasEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiSaveDeliveryAreas>(a$, Actions.API_SAVE_DELIVERY_AREAS), (b) =>
    b.pipe(
      mergeMap((c) => {
        const market = store.value.markets.markets.find((item) => item.id === c.data.market)
        const actions: Actions.Action[] = []

        for (const area of c.data.deliveryAreas) {
          if (area.id && market) {
            const oldArea = (market.delivery_areas || []).find((item) => item.id === area.id)

            if (!isEqual(area, oldArea)) {
              actions.push(
                Actions.action(Actions.API_UPDATE_DELIVERY_AREA, {
                  id: area.id,
                  ...area,
                }),
              )
            }
          } else if (market) {
            actions.push(Actions.action(Actions.API_ADD_DELIVERY_AREA, area))
          }
        }

        if (market && market.delivery_areas && market.delivery_areas.length > 0) {
          for (const existDeliveryArea of market.delivery_areas) {
            const savedArea = c.data.deliveryAreas.find((item) => item.id === existDeliveryArea.id)

            if (!savedArea && existDeliveryArea.id) {
              actions.push(
                Actions.action(Actions.API_REMOVE_DELIVERY_AREA, {
                  id: existDeliveryArea.id,
                  marketId: existDeliveryArea.marketId,
                }),
              )
            }
          }
        }

        actions.push(Actions.action(Actions.MARKET_DELIVERY_AREAS, c.data))

        return concat(
          observableOf<Actions.Action>(...actions).pipe(
            map((x) => observableOf(x).pipe(delay(300))),
            concatAll(),
          ),
          observableOf<Actions.Action>(Actions.action(Actions.API_MARKETS, { include_all: 'True' })),
        )
      }),
    ),
  )

const addDeliveryAreaEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiAddDeliveryArea>(a$, Actions.API_ADD_DELIVERY_AREA), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_DELIVERY_AREA_ADD, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiAddDeliveryAreaResp }) => {
            if (resp.data && resp.data.id) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.MARKET_DELIVERY_AREA_ADD, {
                  market: c.data.marketId,
                  deliveryArea: {
                    id: resp.data.id,
                    index: resp.data.index,
                    ...c.data,
                  },
                }),
              )
            } else {
              return EMPTY
            }
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateDeliveryAreaEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiUpdateDeliveryArea>(a$, Actions.API_UPDATE_DELIVERY_AREA), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_DELIVERY_AREA_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.MARKET_DELIVERY_AREA_UPDATE, {
                market: c.data.marketId,
                deliveryArea: { ...c.data },
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const removeDeliveryAreaEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiRemoveDeliveryArea>(a$, Actions.API_REMOVE_DELIVERY_AREA), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_DELIVERY_AREA_REMOVE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.MARKET_DELIVERY_AREA_REMOVE, {
                market: c.data.marketId,
                deliveryAreaId: c.data.id,
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const getSlots: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiSlots>(a$, Actions.API_SLOTS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_SLOTS_LIST, {
          ...authRequestConfig(),
          params: c.data,
        }).pipe(
          mergeMap((resp: AxiosResponse<TApi.ISlotApi[]>) => {
            if (!resp.data) {
              return EMPTY
            }

            return observableOf<Actions.Action>(Actions.action(Actions.SLOTS, resp.data.map(apiSlotToClient)))
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  )

const updateSlots: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiUpdateSlots>(a$, Actions.API_UPDATE_SLOTS), (b) =>
    b.pipe(
      mergeMap((c) => {
        const data: TApi.IUpdateSlotReq = { marketId: c.data.market, update: [], delete: [], add: [] }

        c.data.slots.forEach((slot) => {
          switch (slot.type) {
            case ESlotAction.UPDATE:
              return data.update.push(clientSlotToApi(c.data.market, slot))
            case ESlotAction.ADD:
              return data.add.push(clientSlotToApi(c.data.market, slot))
            case ESlotAction.DELETE:
              return data.delete.push(clientSlotToApi(c.data.market, slot))
          }
        })

        return Axios.post(URL_SLOTS_UPDATE, data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: AxiosResponse<TApi.IUpdateSlotsResp>) => {
            if (!resp.data) {
              return EMPTY
            }

            const actions: Actions.Action[] = [
              Actions.action(Actions.UPDATE_SLOTS, resp.data.slots.map(apiSlotToClient)),
            ]

            if (c.data.updateInfo) {
              actions.push(Actions.action(Actions.API_SLOTS_INFO, { market: c.data.market }))
            }

            if (resp.data.error) {
              actions.push(Actions.action(Actions.UPDATE_SLOTS_ERROR_MODAL, true))
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError(() => EMPTY),
        )
      }),
    ),
  )

const marketCategoriesEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiMarketCategories>(a$, Actions.API_MARKET_CATEGORIES), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_MARKET_CATEGORIES, {
          ...authRequestConfig(),
          params: c.data,
        }).pipe(
          mergeMap((resp: { data: TApi.ApiMarketCategoriesResp }) => {
            if (resp.data && Array.isArray(resp.data.categories)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.MARKET_CATEGORIES, {
                  market: c.data.marketId,
                  categories: resp.data.categories,
                }),
              )
            } else {
              return EMPTY
            }
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const createCategoryEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiCreateMarketCategory>(a$, Actions.API_CREATE_MARKET_CATEGORY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_CATEGORY_CREATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCreateMarketCategoryResp }) => {
            if (resp.data && resp.data.id) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.API_MARKET_CATEGORIES, {
                  marketId: c.data.marketId,
                }),
              )
            } else {
              return EMPTY
            }
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateCategoryEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateMarketCategory>(a$, Actions.API_UPDATE_MARKET_CATEGORY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_CATEGORY_UPDATE, c.data.req, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            const { category, changed, req } = c.data
            const actions: Actions.Action[] = [
              Actions.action(Actions.MARKET_CATEGORY_UPDATE, {
                market: c.data.category.marketId,
                category: { ...category, ...req, index: req.index },
              }),
            ]

            if (changed) {
              actions.push(
                Actions.action(Actions.MARKET_CATEGORY_UPDATE, {
                  market: c.data.category.marketId,
                  category: { ...changed, index: category.index },
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const removeCategoryEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiRemoveMarketCategory>(a$, Actions.API_REMOVE_MARKET_CATEGORY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_CATEGORY_REMOVE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_MARKET_CATEGORIES, {
                marketId: c.data.marketId,
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const createSubcategoryEpic: EpicFunc = (a$, store) =>
  guardMergeMap(ofType<Actions.ApiCreateMarketSubcategory>(a$, Actions.API_CREATE_MARKET_SUBCATEGORY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_SUBCATEGORY_CREATE, c.data.req, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCreateMarketSubcategoryResp }) => {
            if (resp.data && resp.data.id) {
              const { category, req } = c.data
              const storeMarket = store.value.markets.markets.find((item) => item.id === req.marketId)
              const storeCategory = storeMarket?.categories?.find((item) => item.id === category.id) || category

              return observableOf<Actions.Action>(
                Actions.action(Actions.MARKET_CATEGORY_UPDATE, {
                  market: req.marketId,
                  category: {
                    ...storeCategory,
                    subcategories: [
                      ...storeCategory.subcategories,
                      {
                        id: resp.data.id,
                        categoryId: storeCategory.id,
                        marketId: storeCategory.marketId,
                        name: req.name,
                        index: storeCategory.subcategories.length + 1,
                        hidden: false,
                      },
                    ],
                  },
                }),
              )
            } else {
              return EMPTY
            }
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateSubcategoryEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateMarketSubcategory>(a$, Actions.API_UPDATE_MARKET_SUBCATEGORY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_SUBCATEGORY_UPDATE, c.data.req, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            const { category, changedSub, oldIndex, req } = c.data

            return observableOf<Actions.Action>(
              Actions.action(Actions.MARKET_CATEGORY_UPDATE, {
                market: category.marketId,
                category: {
                  ...category,
                  subcategories: category.subcategories
                    .map((item) => {
                      if (changedSub && oldIndex !== undefined) {
                        if (item.id === req.id) {
                          return {
                            ...item,
                            ...req,
                            index: req.index,
                          }
                        }

                        if (item.id === changedSub.id) {
                          return {
                            ...changedSub,
                            index: oldIndex,
                          }
                        }
                      } else {
                        if (item.id === req.id) {
                          return {
                            ...item,
                            ...req,
                          }
                        }
                      }

                      return item
                    })
                    .sort((a, b) => a.index - b.index),
                },
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const removeSubcategoryEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiRemoveMarketSubcategory>(a$, Actions.API_REMOVE_MARKET_SUBCATEGORY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_SUBCATEGORY_REMOVE, c.data.req, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            const { category } = c.data

            return observableOf<Actions.Action>(
              Actions.action(Actions.API_MARKET_CATEGORIES, {
                marketId: category.marketId,
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const marketRoutesEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiMarketRoutes>(a$, Actions.API_MARKET_ROUTES), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_MARKET_ROUTES, {
          ...authRequestConfig(),
          params: c.data,
        }).pipe(
          mergeMap((resp: { data: TApi.ApiMarketRoutesResp }) => {
            if (resp.data && Array.isArray(resp.data.routes)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.MARKET_ROUTES, {
                  market: c.data.marketId,
                  routes: resp.data.routes.map(convertRouteFromApi),
                }),
              )
            } else {
              return EMPTY
            }
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const createRouteEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiCreateMarketRoute>(a$, Actions.API_CREATE_MARKET_ROUTE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_ROUTE_CREATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCreateMarketRouteResp }) => {
            if (resp.data && resp.data.id) {
              const actions: Actions.Action[] = [
                Actions.action(Actions.API_MARKET_ROUTES, {
                  marketId: c.data.marketId,
                }),
                Actions.action(Actions.ALERT_PUSH, {
                  id: Date.now().toString(),
                  type: EAlertType.SUCCESS,
                  title: intlMessage(messages.Successfully),
                  text: intlMessage(messages.RouteCreatedSuccessfully),
                }),
              ]

              return observableOf<Actions.Action>(...actions)
            } else {
              return EMPTY
            }
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateRouteEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateMarketRoute>(a$, Actions.API_UPDATE_MARKET_ROUTE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_ROUTE_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_MARKET_ROUTES, {
                marketId: c.data.marketId,
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const removeRouteEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiRemoveMarketRoute>(a$, Actions.API_REMOVE_MARKET_ROUTE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_ROUTE_REMOVE, c.data.req, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_MARKET_ROUTES, {
                marketId: c.data.marketId,
              }),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const slotsInfo: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiSlotsInfo>(a$, Actions.API_SLOTS_INFO), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_SLOTS_INFO, {
          ...authRequestConfig(),
          params: {
            market: c.data.market,
          },
        }).pipe(
          mergeMap((resp: AxiosResponse<ISlotInfoResp>) => {
            return observableOf<Actions.Action>(Actions.action(Actions.SLOTS_INFO, resp.data))
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const groupsEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiGroupsList>(a$, Actions.API_GROUPS_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_MARKET_GROUPS, {
          ...authRequestConfig(),
          params: {
            marketId: c.data.marketId,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiGroupsListResp }) => {
            if (resp.data && Array.isArray(resp.data.results)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.GROUPS_LIST, { groups: resp.data.results.map(convertGroupFromApi) }),
              )
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const createGroupEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiCreateGroup>(a$, Actions.API_CREATE_GROUP), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_GROUP_CREATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCreateGroupResp }) => {
            if (resp.data && resp.data.id) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.CREATE_GROUP_PRODUCT, convertGroupFromApi(resp.data)),
              )
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateGroupEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateGroup>(a$, Actions.API_UPDATE_GROUP), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_GROUP_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiUpdateGroupResp }) => {
            if (resp.data && resp.data.id) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.UPDATE_GROUP_PRODUCT, convertGroupFromApi(resp.data)),
              )
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const removeGroupEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiRemoveGroup>(a$, Actions.API_REMOVE_GROUP), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_MARKET_GROUP_REMOVE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(Actions.action(Actions.REMOVE_GROUP_PRODUCT, c.data.id))
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const marketsEpics: EpicFunc[] = [
  getCitiesEpic,
  getMarketsEpic,
  getSlots,
  updateSlots,
  saveDeliveryAreasEpic,
  addDeliveryAreaEpic,
  updateDeliveryAreaEpic,
  removeDeliveryAreaEpic,
  marketCategoriesEpic,
  createCategoryEpic,
  updateCategoryEpic,
  removeCategoryEpic,
  createSubcategoryEpic,
  updateSubcategoryEpic,
  removeSubcategoryEpic,
  marketRoutesEpic,
  createRouteEpic,
  updateRouteEpic,
  removeRouteEpic,
  slotsInfo,
  groupsEpic,
  createGroupEpic,
  updateGroupEpic,
  removeGroupEpic,
]
