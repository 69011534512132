import React from 'react'

type TProps = {
  color?: string,
}

export const ArrowUpIcon: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='M17.565 10.759a.79.79 0 0 1-.56.24c-.2 0-.4-.08-.56-.24l-3.64-3.64v11.68c0 .44-.36.8-.8.8-.44 0-.8-.36-.8-.8V7.119l-3.64 3.62c-.32.32-.82.32-1.14 0a.797.797 0 0 1 0-1.14l5-4.98c.32-.32.82-.32 1.12 0l5 4.98c.34.34.34.84.02 1.16Z'
      />
    </svg>
  )
}
