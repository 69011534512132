import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { EOrderStatus, HistoryOrderAssignAction, IOrder } from '../../../../types/TClient'

import { Action } from '../Action'
import { convertRole } from '../../../../utils/courierUtils'
import { getCollectedPlan, getCourierPlan } from '../../../../utils/ordersUtils'
import messages from '../../../../localization/messages'

interface IProps extends HistoryOrderAssignAction {
  order: IOrder,
}

type TProps = IProps & WrappedComponentProps

const _AssignAction: React.FC<TProps> = ({ order, data: { courierName, role, orderStatus }, date, intl }) => {
  const { formatMessage } = intl
  const collectPlan = getCollectedPlan(order)
  const courierPlan = !order.selfPickup ? getCourierPlan(order) : undefined

  return (
    <Action date={date}>
      {convertRole(role)} <span className='assign-action__courier'>{courierName}</span>{' '}
      <FormattedMessage {...messages.Assign} />
      <div>
        {orderStatus === EOrderStatus.IN_ASSEMBLY &&
          `${formatMessage(messages.AssemblyTo)} ${collectPlan ? collectPlan.format('HH:mm') : '–'}`}
        {orderStatus === EOrderStatus.COURIER &&
          !order.selfPickup &&
          `${formatMessage(messages.DeliveryTo)} ${courierPlan ? courierPlan.format('HH:mm') : '–'}`}
      </div>
    </Action>
  )
}

export const AssignAction = injectIntl(_AssignAction)
