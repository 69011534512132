import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Header, Icon, Modal } from 'semantic-ui-react'

import './index.scss'

import { EPaymentType, IProductOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { UNIT_PCS, unitIntl } from '../../../utils/productsUtils'

type TOwnProps = {
  title: string,
  orderId: string,
  product: IProductOrder,
  paymentType: EPaymentType,
  onClose: () => void,
}

type TDispatchedProps = {
  apiChangeProduct(data: { orderId: string, itemId: string, quantity: number }): Actions.Action,
}

type Props = TOwnProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  quantity: number,
  changed: boolean,
  deleteModal: boolean,
  errors: FieldError[],
}

const FIELD_QUANTITY = 'quantity'

class ProductChangeModalCmp extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      quantity: props.product.quantity,
      changed: false,
      deleteModal: false,
      errors: [],
    }
  }

  render() {
    const { formatMessage } = this.props.intl
    const { changed } = this.state

    return (
      <>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>
          {this.renderModalConfirmation()}
          <Form>{this.renderQuantityField()}</Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={this.props.onClose}>
            {formatMessage(messages.Cancel)}
          </Button>
          <Button color='red' onClick={() => this.setState({ deleteModal: true })}>
            {formatMessage(messages.Delete)}
          </Button>
          <Button positive onClick={this.changeProduct} disabled={!changed}>
            {formatMessage(messages.Save)}
          </Button>
        </Modal.Actions>
      </>
    )
  }

  renderModalConfirmation = () => {
    const { formatMessage } = this.props.intl
    const { deleteModal } = this.state

    return (
      <Modal basic onClose={() => this.setState({ deleteModal: false })} open={deleteModal} size='small'>
        <Header icon>
          <Icon name='trash alternate' />
          <p>{formatMessage(messages.SureRemoveItemFromOrder)}</p>
        </Header>
        <Modal.Actions>
          <Button basic color='green' inverted onClick={() => this.setState({ deleteModal: false })}>
            <Icon name='remove' /> {formatMessage(messages.Cancel)}
          </Button>
          <Button color='red' inverted onClick={this.deleteProduct}>
            <Icon name='checkmark' /> {formatMessage(messages.Confirm)}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  renderQuantityField = () => {
    const { product } = this.props
    const { formatMessage } = this.props.intl
    const { quantity } = this.state
    const error = this.checkError(FIELD_QUANTITY)

    return (
      <div className='create-product__field'>
        <div className='create-product__field-label'>{formatMessage(messages.QuantityGoods)}:</div>
        <div>
          <Form.Input
            value={quantity}
            className='create-product__field-value'
            name={FIELD_QUANTITY}
            fluid
            onChange={this.handleChange}
            error={error ? error.message : false}
          />
        </div>
        <div className='create-product__field-unit'>{unitIntl(product.unit)}</div>
      </div>
    )
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_QUANTITY:
        this.setState({ quantity: value, errors: this.removeError(FIELD_QUANTITY) })
        break
    }
  }

  deleteProduct = () => {
    const { apiChangeProduct, product, orderId, onClose } = this.props

    onClose()

    apiChangeProduct({
      orderId,
      itemId: product.itemId,
      quantity: 0,
    })
  }

  changeProduct = () => {
    const { apiChangeProduct, product, orderId, paymentType } = this.props
    const { formatMessage } = this.props.intl
    const { quantity, changed } = this.state
    const errors: FieldError[] = []

    if (+quantity === 0) {
      this.setState({ deleteModal: true })
      return
    }

    if (Number.isNaN(+quantity)) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.ValueMustNumber) })
    }

    if (quantity > product.quantity && paymentType === EPaymentType.BY_CARD) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.QuantityItemMustLess) })
    }

    if (product.unit === UNIT_PCS && !Number.isInteger(+quantity)) {
      errors.push({ field: FIELD_QUANTITY, message: formatMessage(messages.QuantityPcsMustInteger) })
    }

    if (!changed) {
      return
    }

    if (!errors.length) {
      this.props.onClose()

      apiChangeProduct({
        orderId,
        quantity,
        itemId: product.itemId,
      })
    } else {
      this.setState({ errors })
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiChangeProduct: (data: { orderId: string, itemId: string, quantity: number }) =>
    dispatch(Actions.action(Actions.ORDER_CHANGE_PRODUCT, data)),
})

export const ProductChangeModal = connect(null, mapDispatchToProps)(injectIntl(ProductChangeModalCmp))
