import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const CartIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='M17.735 3.439a1.793 1.793 0 0 0-.668-.623 1.974 1.974 0 0 0-.912-.237H4.257L3.718.637a.86.86 0 0 0-.345-.468A.983.983 0 0 0 2.79 0H.929a.97.97 0 0 0-.657.251A.828.828 0 0 0 0 .86c0 .228.098.447.272.608a.97.97 0 0 0 .658.252h1.152l2.566 8.819a.861.861 0 0 0 .344.468.985.985 0 0 0 .585.168h8.366a.99.99 0 0 0 .486-.128.891.891 0 0 0 .341-.345l3.049-5.639a1.609 1.609 0 0 0-.084-1.624ZM5.113 15c.77 0 1.394-.577 1.394-1.289s-.624-1.29-1.394-1.29c-.77 0-1.394.578-1.394 1.29 0 .712.624 1.29 1.394 1.29ZM14.406 15c.77 0 1.394-.577 1.394-1.289s-.624-1.29-1.394-1.29c-.77 0-1.394.578-1.394 1.29 0 .712.624 1.29 1.394 1.29Z'
      />
    </svg>
  )
}
