import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const ModerationIcon: React.FC<TProps> = ({ width = 24, height = 24, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='m19.296 8.809-7.364 7.364-3.33-3.33-2.541 2.542 5.871 5.872 9.906-9.906-2.542-2.542Z' />
      <path
        fill={color}
        d='M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14S21.732 0 14 0Zm0 24.405A10.4 10.4 0 0 1 3.595 14 10.4 10.4 0 0 1 14 3.595 10.4 10.4 0 0 1 24.405 14 10.4 10.4 0 0 1 14 24.405Z'
      />
    </svg>
  )
}
