import React from 'react'

export const FilterIcon: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21 4H3l7.2 8.514V18.4l3.6 1.8v-7.686L21 4Z'
        stroke='#7677BC'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
