import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import { IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { EModalType, IModalCustomer, IModalCustomerCallMe } from '../../../store/reducers/modals'
import * as Actions from '../../../store/actions'
import { ForwardIcon } from '../../Icons'

type TOwnProps = {
  order: IOrder,
  disabled?: boolean,
}

type TDispatchedProps = {
  customerModal: (data: IModalCustomer) => Actions.Action,
  customerPhoneMeModal: (data: IModalCustomerCallMe) => Actions.Action,
}

type TProps = TOwnProps & TDispatchedProps & WrappedComponentProps

class OrderClientCmp extends React.Component<TProps> {
  render() {
    const { order, disabled } = this.props
    const { formatMessage } = this.props.intl
    const { userFirstName, userLastName, userPhone, userId, userBusiness } = order

    return (
      <div className='order__client'>
        <div className='order__block-title'>{formatMessage(messages.Customer)}</div>
        <div>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.Name)}</div>
            <div className='order__block-text'>{userFirstName ? `${userFirstName} ${userLastName || ''}` : '–'}</div>
          </div>
          <div className='order__block-row'>
            <div className={classnames('order__block-item', 'order__block-padding')}>
              <div className='order__block-label'>{formatMessage(messages.Phone)}</div>
              <div
                className={classnames('order__block-text', !disabled && 'order__client-phone')}
                onClick={disabled ? undefined : () => this.selectCustomer(userId)}
              >
                {userPhone}
              </div>
            </div>
            <div className={classnames('order__block-item', 'order__block-padding')}>
              <div className='order__block-label'>{formatMessage(messages.ClientType)}</div>
              <div className='order__block-text'>{userBusiness ? 'B2B' : 'B2C'}</div>
            </div>
          </div>
          <div className='order__client-call-me' onClick={this.callMeInfo}>
            <div className='order__client-call-me__text'>
              {order.userCallMe ? formatMessage(messages.CallMeIf) : formatMessage(messages.NotCallMeIf)}
            </div>
            <div className='order__client-call-me__icon'>
              <ForwardIcon width={23} height={23} color='#7677bc' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  callMeInfo = () => {
    const { order } = this.props

    this.props.customerPhoneMeModal({
      type: EModalType.MODAL_CUSTOMER_CALL_ME,
      size: 'mini',
      style: { width: '528px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        userId: order.userId,
        customerCallMe: {
          callMe: order.userCallMe,
          aboutChangeProduct: order.userCallAboutChangeProduct,
          aboutDeleteProduct: order.userCallAboutDeleteProduct,
          aboutChangeProductWeight: order.userCallAboutChangeProductWeight,
          changeProduct: order.userCallChangeProduct,
          deleteProductByWeight: order.userCallDeleteProductByWeight,
        },
      },
    })
  }

  selectCustomer = (userId: number) => {
    this.props.customerModal({
      type: EModalType.MODAL_CUSTOMER,
      size: 'mini',
      style: { width: '980px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        userId,
        marketId: this.props.order.marketId,
      },
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  customerModal: (data: IModalCustomer) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  customerPhoneMeModal: (data: IModalCustomerCallMe) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
})

export const OrderClient = connect(null, mapDispatchToProps)(injectIntl(OrderClientCmp))
