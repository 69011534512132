import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Modal } from 'semantic-ui-react'

import './index.scss'

import { ISeller, ISellerProduct, ITag } from '../../../types/TClient'
import { ApiProductUpdateReq } from '../../../types/TApi'
import * as TApi from '../../../types/TApi'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'

type TOwnProps = {
  title: string,
  product: ISellerProduct,
  onClose: () => void,
}

type TConnectedProps = {
  tags: ITag[],
  seller?: ISeller,
}

type TDispatchedProps = {
  apiUpdateProduct: (data: ApiProductUpdateReq) => Actions.Action,
  apiSellersList: (data: TApi.ApiSellersListReq) => Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type IState = {
  changed: boolean,
  selectedTags: number[],
}

const FIELD_TAGS = 'product_tags'

class ProductTagChangeModalCmp extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      changed: false,
      selectedTags: props.product.tags.filter((t) => !!t) || [],
    }
  }

  componentDidMount() {
    const { product } = this.props

    this.props.apiSellersList({ offset: 0, id: product.seller })
  }

  render() {
    const { formatMessage } = this.props.intl
    const { changed } = this.state

    return (
      <>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>
          <Form>{this.renderTags()}</Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={this.props.onClose}>
            {formatMessage(messages.Cancel)}
          </Button>
          <Button positive onClick={this.changeProduct} disabled={!changed}>
            {formatMessage(messages.Save)}
          </Button>
        </Modal.Actions>
      </>
    )
  }

  renderTags = () => {
    const { tags } = this.props
    const { formatMessage } = this.props.intl
    const { selectedTags } = this.state
    const options = tags.map((t) => ({ key: t.id, value: t.id, text: t.tag }))

    return (
      <div className='change-tag__field'>
        <div className='change-tag__field-label'>{formatMessage(messages.MarketingTags)}:</div>
        <Form.Dropdown
          value={selectedTags}
          className='change-tag__field-value'
          name={FIELD_TAGS}
          fluid
          multiple
          selection
          options={options}
          placeholder={formatMessage(messages.SelectTags)}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_TAGS:
        this.setState({ selectedTags: value })
        break
    }
  }

  changeProduct = () => {
    const { apiUpdateProduct, product, seller, onClose } = this.props
    const { selectedTags } = this.state

    if (!seller) {
      return
    }

    apiUpdateProduct({
      productId: product.id,
      ...(seller && { userId: seller.userId }),
      sellerId: product.seller,
      tags: selectedTags,
    })

    onClose()
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => ({
  seller: s.sellers.sellersList.find((s) => s.id === own.product.seller),
  tags: s.tags.tags,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiUpdateProduct: (data: ApiProductUpdateReq) => dispatch(Actions.action(Actions.API_PRODUCT_UPDATE, data)),
  apiSellersList: (data: TApi.ApiSellersListReq) => dispatch(Actions.action(Actions.API_SELLERS_LIST, data)),
})

export const ProductTagChangeModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductTagChangeModalCmp))
