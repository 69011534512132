import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Icon, Modal, Placeholder } from 'semantic-ui-react'

import './index.scss'

import { IProduct, ISeller, ISellerProduct } from '../../types/TClient'
import { ApiProductUpdateReq } from '../../types/TApi'

import messages from '../../localization/messages'
import * as Actions from '../../store/actions'
import { EModalType, IModalProduct } from '../../store/reducers/modals'
import { InformationIcon, StarIcon } from '../Icons'
import { formatPrice, sourcePrice, unitIntl } from '../../utils/productsUtils'
import defaultImg from '../../assets/images/default-image.png'

type TOwnProps = {
  product: ISellerProduct,
  marketProduct?: IProduct,
  editable?: boolean,
  seller?: ISeller,
  chainNum?: number | null,
  labelElem?: React.ReactNode,
  showModal?(product: ISellerProduct): void,
  toggleHideProduct?(event: React.MouseEvent<HTMLDivElement>, product: ISellerProduct): void,
  showRemoveProduct?(event: React.MouseEvent<HTMLDivElement>, product: ISellerProduct): void,
  updateGroup?(product: IProduct): void,
}

type TDispatchedProps = {
  productModal: (data: IModalProduct) => Actions.Action,
  apiUpdateProduct: (data: ApiProductUpdateReq) => Actions.Action,
}

type TProps = TOwnProps & TDispatchedProps & WrappedComponentProps

type TState = {
  loading: boolean,
  showInfoModal: boolean,
}

class ProductCardCmp extends React.Component<TProps, TState> {
  state = {
    loading: true,
    showInfoModal: false,
  }

  render() {
    const { product, chainNum, labelElem, editable, toggleHideProduct, showRemoveProduct } = this.props
    const { loading } = this.state

    return (
      <div
        key={product.id}
        className='product-card'
        onClick={editable ? this.showModal : chainNum !== undefined ? this.toggleToGroup : undefined}
      >
        {this.renderInfoModal()}
        {labelElem || (
          <>
            {chainNum === undefined ? (
              <>
                {toggleHideProduct || editable ? (
                  <div className='product-card__hide' onClick={this.toggleHide}>
                    <Icon className='product-card__hide-icon' size='large' name={product.hide ? 'eye slash' : 'eye'} />
                  </div>
                ) : (
                  <div className='product-card__hide product-card__hide-disable'>
                    <Icon className='product-card__hide-icon' size='large' name={product.hide ? 'eye slash' : 'eye'} />
                  </div>
                )}
              </>
            ) : (
              <div className='product-card__chain' onClick={this.toggleToGroup}>
                {chainNum ? (
                  <div className='product-card__chain-num'>{chainNum}</div>
                ) : (
                  <div className='product-card__chain-empty' />
                )}
              </div>
            )}
          </>
        )}
        {showRemoveProduct && (
          <div className='product-card__remove' onClick={(e) => showRemoveProduct(e, product)}>
            <Icon className='product-card__remove-icon' size='large' name='trash alternate' />
          </div>
        )}
        {!showRemoveProduct && (
          <div className='product-card__seller-info' onClick={this.toggleInfo}>
            <div className='product-card__seller-info-icon'>
              <InformationIcon color='#858897' />
            </div>
          </div>
        )}
        {!!product.rating && (
          <div className='product-card__rating'>
            <div className='product-card__rating-icon'>
              <StarIcon width={20} height={20} />
            </div>
            <div className='product-card__rating-text'>{product.rating}</div>
          </div>
        )}
        {!!product.tags && !!product.tags.length && (
          <div className='product-card__tag'>
            <Icon className='product-card__tag__icon' size='large' name='tag' />
          </div>
        )}
        {loading && (
          <div className='product-card__image__loading'>
            <Placeholder className='product-card__image__loading-placeholder'>
              <Placeholder.Image square />
            </Placeholder>
          </div>
        )}
        <img
          alt={'cardBg'}
          className='product-card__image'
          src={product.imageUrl ? product.imageUrl : defaultImg}
          onLoad={() => this.setLoading(false)}
        />
        {this.renderInfo()}
      </div>
    )
  }

  renderInfo = () => {
    const { product, marketProduct, seller } = this.props
    const isCommon = seller ? seller.isCommon : marketProduct ? marketProduct.sellerIsCommon : false
    const commission = seller ? seller.commission : marketProduct ? marketProduct.sellerCommission : 15

    return (
      <div className='product-card__info'>
        {marketProduct && <div className='product-card__info-seller__name'>{marketProduct.sellerName}</div>}
        <div className='product-card__info-name'>{product.name}</div>
        {marketProduct && (
          <div className='product-card__info-seller__location'>
            {marketProduct.sellerNickname}
            <div className='product-card__info-dot' />
            {marketProduct.sellerLocation}
            <div className='product-card__info-dot' />
            {marketProduct.sellerCommission}%
          </div>
        )}
        <div className='product-card__price'>
          <div className='product-card__divider' />
          <div className='product-card__info-price'>
            {formatPrice(product.price, product.currency)}/{unitIntl(product.unit).toLowerCase()}
            {isCommon && commission > 0 && (
              <div className='product-card__info-source-price'>
                ({formatPrice(sourcePrice(+product.price, commission), product.currency)}/
                {unitIntl(product.unit).toLowerCase()})
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  showModal = () => {
    const { seller, product, marketProduct, showModal } = this.props
    const { formatMessage } = this.props.intl

    if (showModal) {
      showModal(product)
    }

    this.props.productModal({
      type: EModalType.MODAL_PRODUCT,
      size: 'mini',
      style: { width: '980px', borderRadius: '16px' },
      close: true,
      props: {
        product,
        marketProduct,
        title: product ? formatMessage(messages.ProductChange) : formatMessage(messages.AddingProduct),
        marketId: product.market,
        ...(seller && { seller }),
      },
    })
  }

  toggleHide = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    const { seller, product } = this.props

    this.props.apiUpdateProduct({
      productId: product.id,
      ...(seller && { userId: seller.userId }),
      sellerId: product.seller,
      hide: !product.hide,
    })
  }

  toggleToGroup = () => {
    const { marketProduct, updateGroup } = this.props

    if (marketProduct && updateGroup) {
      updateGroup(marketProduct)
    }
  }

  renderInfoModal = () => {
    const { product, marketProduct } = this.props
    const { formatMessage } = this.props.intl
    const { showInfoModal } = this.state

    return (
      <Modal closeIcon onClose={this.toggleInfo} open={showInfoModal} size='tiny' style={{ borderRadius: '16px' }}>
        <div className='product-card__modal'>
          <div className='product-card__modal__content'>
            <div className='product-card__modal__title'>{product.sellerName}</div>
            <div className='product-card__modal__text'>
              {formatMessage(messages.Seller)}: {marketProduct?.sellerOrganisation}
            </div>
            <div className='product-card__modal__text'>
              {formatMessage(messages.Address)}: {marketProduct?.sellerAddress}
            </div>
            <div className='product-card__modal__text'>
              {formatMessage(messages.INN)}: {marketProduct?.sellerINN}
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  toggleInfo = () => {
    this.setState({ showInfoModal: !this.state.showInfoModal })
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  productModal: (data: IModalProduct) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  apiUpdateProduct: (data: ApiProductUpdateReq) => dispatch(Actions.action(Actions.API_PRODUCT_UPDATE, data)),
})

export const ProductCard = connect(null, mapDispatchToProps)(injectIntl(ProductCardCmp))
