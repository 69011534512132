import { createBrowserHistory } from 'history'

export const LOCATION_LOGIN = '/login'
export const LOCATION_SIGN_UP = '/signup'

export const LOCATION_MAIN = '/'

export const LOCATION_ORDERS = '/orders'
export const LOCATION_SELLERS = '/sellers'
export const LOCATION_SELLERS_REGISTER = '/sellers/register'
export const LOCATION_SELLERS_IN_PROGRESS = '/sellers/progress'
export const LOCATION_SELLERS_MARKETS = '/sellers/markets'
export const LOCATION_SELLERS_SELLER = '/sellers/seller'
export const LOCATION_SELLERS_COPY_PRODUCTS = '/sellers/copyProducts'
export const LOCATION_COURIERS = '/couriers'
export const LOCATION_NOTIFICATIONS = '/notifications'
export const LOCATION_NOTIFICATIONS_LOG = '/notifications/log'
export const LOCATION_ANALYTICS = '/analytics'
export const LOCATION_TAGS = '/tags'

export const LOCATION_MARKETS = '/markets'

export const LOCATION_CUSTOMERS = '/customers'

export const LOCATION_MODERATION_PRODUCTS = '/moderationProducts'

export const LOCATION_NEW_SELLER = 'new'
export const LOCATION_NEW_NOTIFICATION = 'new'

const NO_AUTH_LOCATIONS = [LOCATION_SIGN_UP, LOCATION_LOGIN]

export const history = createBrowserHistory()

export const isAuthRequired = (location: string): boolean => {
  return !NO_AUTH_LOCATIONS.includes(location)
}

export const historyReplace = (url: string, state?: any, title?: string) => {
  window.history.pushState(state || null, title || '', url)
  window.location.replace(url)
}

export const historyPush = (url: string, state?: any) => {
  history.replace(url, state || null)
}

export const reload = () => {
  window.location.reload()
}
