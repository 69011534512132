import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Header, Icon, Modal, Placeholder } from 'semantic-ui-react'
import classnames from 'classnames'
import { uniq } from 'lodash'

import './index.scss'
import { IMarket, ISeller, ISellerCategory, ISellerProduct } from '../../../../types/TClient'
import { ApiSellerProductsReq } from '../../../../types/TApi'

import messages from '../../../../localization/messages'
import defaultAvatar from '../../../../assets/images/avatar-placeholder.png'
import defaultImg from '../../../../assets/images/default-image.png'
import { State } from '../../../../store/reducer'
import { EModalType, IModalEditSeller, IModalProduct } from '../../../../store/reducers/modals'
import * as Actions from '../../../../store/actions'
import { ProductCard } from '../../../../components/ProductCard'
import { ReportProductsBtn } from '../../../../components/ReportProductsBtn'
import { LOCATION_SELLERS_MARKETS } from '../../../../utils/locationUtils'
import { sortProductBy, withCategory } from '../../../../utils/productsUtils'

type TConnectedProps = {
  loaded: boolean,
  loading: boolean,
  products: ISellerProduct[],
  commonSellers: string[],
  categories: ISellerCategory[],
  markets: IMarket[],
  seller?: ISeller,
}

type TDispatchedProps = {
  getMarkets: () => Actions.Action,
  editSeller: (data: IModalEditSeller) => Actions.Action,
  productModal: (data: IModalProduct) => Actions.Action,
  apiSellerProducts: (data: ApiSellerProductsReq) => Actions.Action,
  apiDeleteProduct: (productId: string, userId: number) => Actions.Action,
  dropSavedSellerProfile: () => Actions.Action,
  getCategories: () => Actions.Action,
}

type Props = RouteComponentProps<{ id: string }> & TConnectedProps & TDispatchedProps & WrappedComponentProps

enum ETabs {
  ALL = 'all',
  ACTIVE = 'active',
  HIDE = 'hide',
}

interface ICategoryTab {
  id: string,
  name: string,
}

type IState = {
  activeTab: ETabs,
  selectedSeller: string,
  selectedCategory: string,
  deleteProductModal: boolean,
  avatarLoading: boolean,
  topImageLoading: boolean,
  removedProduct: ISellerProduct | null,
}

const DEFAULT_CATEGORY_ID = '0'
const PRODUCTS_LIMIT = 20

class SellerCmp extends React.Component<Props, IState> {
  listRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      activeTab: ETabs.ALL,
      selectedSeller: '',
      selectedCategory: DEFAULT_CATEGORY_ID,
      deleteProductModal: false,
      removedProduct: null,
      avatarLoading: true,
      topImageLoading: true,
    }
  }

  componentDidMount(): void {
    const { seller, categories, markets } = this.props

    if (categories.length === 0) {
      this.props.getCategories()
    }

    if (seller && seller.userId) {
      this.getProducts()
    }

    if (markets.length === 0) {
      this.props.getMarkets()
    }
  }

  render() {
    const { seller } = this.props
    const { formatMessage } = this.props.intl

    if (!seller) {
      return <div className='seller' />
    }

    return (
      <div className='seller' ref={this.listRef} onScroll={this.checkEnd}>
        {this.renderBack()}
        <Header as='h1' className='seller__title'>
          {formatMessage(messages.ShowcaseSeller)}:{' '}
          {seller.nickname ? `"${seller.nickname}"` : `«${formatMessage(messages.Missing)}»`}
        </Header>
        {this.renderProductDeleteModal()}
        {this.renderImage(seller)}
        {this.renderSellerProfile(seller)}
        {this.renderReport()}
        {seller.isCommon && this.renderSelectSeller()}
        {this.renderTabs()}
        {this.renderAddProduct()}
        {this.renderCategories()}
        {this.renderProducts()}
      </div>
    )
  }

  renderBack() {
    const { formatMessage } = this.props.intl

    return (
      <div className='seller__back'>
        <Button size='large' onClick={this.back}>
          {formatMessage(messages.Back)}
        </Button>
      </div>
    )
  }

  renderProductDeleteModal = () => {
    const { formatMessage } = this.props.intl
    const { deleteProductModal, removedProduct } = this.state

    if (!removedProduct) {
      return
    }

    return (
      <Modal basic onClose={() => this.setState({ deleteProductModal: false })} open={deleteProductModal} size='small'>
        <Header icon>
          <Icon name='trash alternate' />
          <p>
            {formatMessage(messages.SureDeleteProduct)} "{removedProduct.name}"?
          </p>
        </Header>
        <Modal.Actions>
          <Button basic color='green' inverted onClick={() => this.setState({ deleteProductModal: false })}>
            <Icon name='remove' /> {formatMessage(messages.Cancel)}
          </Button>
          <Button color='red' inverted onClick={() => this.deleteProduct(removedProduct.id)}>
            <Icon name='checkmark' /> {formatMessage(messages.Confirm)}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  renderImage(seller: ISeller) {
    const { topImageLoading } = this.state
    return (
      <>
        <div className='seller__top-image-container'>
          {topImageLoading && (
            <div className='seller__shop__placeholder'>
              <Placeholder fluid>
                <Placeholder.Image rectangular />
              </Placeholder>
            </div>
          )}
          <div className='seller__shop' />
          <img
            alt='top'
            className='seller__shop__image'
            src={seller.imageUrl ? seller.imageUrl : defaultImg}
            onClick={this.editSeller}
            onLoad={() => this.setState({ topImageLoading: false })}
          />
        </div>
      </>
    )
  }

  renderSellerProfile(seller: ISeller) {
    const { formatMessage } = this.props.intl
    const { avatarLoading } = this.state

    return (
      <div className='seller__profile'>
        <div className='seller__profile__avatar'>
          {avatarLoading && (
            <Placeholder className='seller__profile__avatar__img-placeholder'>
              <Placeholder.Image square />
            </Placeholder>
          )}
          <img
            alt='avatar'
            className='seller__profile__avatar__img'
            onLoad={() => this.setState({ avatarLoading: false })}
            src={seller.avatarUrl ? seller.avatarUrl : defaultAvatar}
          />
        </div>
        <div className='seller__profile__info'>
          <div className='sellers-profile__info__organization'>{seller.organization}</div>
          <div className='seller__profile__info__name'>
            {seller.name || `<${formatMessage(messages.Missing)}>`}{' '}
            {seller.isHide && <span className='seller__profile__info__hidden'>({formatMessage(messages.Hidden)})</span>}
          </div>
          <div className='seller__profile__info__nickname'>
            {seller.nickname || `<${formatMessage(messages.Missing)}>`}
          </div>
          <div className='seller__profile__info__location'>
            {seller.location || `<${formatMessage(messages.Missing)}>`}
          </div>
        </div>
        <div className='seller__profile__edit' onClick={this.editSeller}>
          <Icon size='big' name='pencil alternate' />
        </div>
      </div>
    )
  }

  renderSelectSeller = () => {
    const { commonSellers } = this.props
    const { formatMessage } = this.props.intl
    const { selectedSeller } = this.state
    const options = commonSellers.map((c) => ({ key: c, value: c, text: c }))

    return (
      <div className='seller__select-field'>
        <Form.Dropdown
          fluid
          search
          selection
          clearable
          value={selectedSeller}
          placeholder={formatMessage(messages.SellerPlace)}
          className='seller__select-field__value'
          options={options}
          onChange={this.selectSeller}
        />
      </div>
    )
  }

  renderTabs() {
    const { formatMessage } = this.props.intl
    const { activeTab } = this.state

    return (
      <div className='seller__products__tabs'>
        <div
          className={classnames(
            'seller__products__tabs-tab',
            activeTab === ETabs.ALL && 'seller__products__tabs-tab__active',
          )}
          onClick={() => this.selectTab(ETabs.ALL)}
        >
          {formatMessage(messages.All)}
        </div>
        <div
          className={classnames(
            'seller__products__tabs-tab',
            activeTab === ETabs.ACTIVE && 'seller__products__tabs-tab__active',
          )}
          onClick={() => this.selectTab(ETabs.ACTIVE)}
        >
          {formatMessage(messages.Active)}
        </div>
        <div
          className={classnames(
            'seller__products__tabs-tab',
            activeTab === ETabs.HIDE && 'seller__products__tabs-tab__active',
          )}
          onClick={() => this.selectTab(ETabs.HIDE)}
        >
          {formatMessage(messages.HiddenS)}
        </div>
      </div>
    )
  }

  renderAddProduct() {
    const { formatMessage } = this.props.intl

    return (
      <div className='seller__products__add'>
        <div className='seller__products__add-btn' onClick={() => this.showProductModal(null)}>
          <div className='seller__products__add-btn__text'>{formatMessage(messages.AddNewProduct)}</div>
        </div>
      </div>
    )
  }

  renderCategories() {
    const { categories } = this.props
    const { formatMessage } = this.props.intl
    const { selectedCategory } = this.state
    const sellerCategories = [
      {
        id: DEFAULT_CATEGORY_ID,
        name: formatMessage(messages.All),
      },
      ...categories,
    ]

    return (
      <div className='seller__categories'>
        {sellerCategories.map((category) => {
          return (
            <div
              key={category.id}
              className={classnames(
                'seller__categories__item',
                selectedCategory === category.id && 'seller__categories__item-selected',
              )}
              onClick={() => this.selectCategory(category)}
            >
              <div className='seller__categories__item-text'>{category.name}</div>
            </div>
          )
        })}
      </div>
    )
  }

  renderProducts() {
    const { seller, products } = this.props

    return (
      <div className='seller__products'>
        {products.map((item) => (
          <ProductCard
            editable
            key={item.id}
            product={item}
            seller={seller}
            // showModal={this.showProductModal}
            showRemoveProduct={this.showRemoveProduct}
          />
        ))}
      </div>
    )
  }

  renderReport = () => {
    const { seller } = this.props

    return <ReportProductsBtn seller={seller} />
  }

  checkEnd = () => {
    const { products, loading, loaded } = this.props

    if (this.listRef && this.listRef.current) {
      const { scrollHeight, offsetHeight, scrollTop } = this.listRef.current

      if (!loading && products.length && !loaded && scrollHeight - offsetHeight - scrollTop < 300) {
        this.getProducts(products.length)
      }
    }
  }

  selectTab = (tab: ETabs) => {
    this.setState({ activeTab: tab })
    this.getProducts(0, this.state.selectedCategory, this.state.selectedSeller, tab)
  }

  getProducts = (offset?: number, category?: string, sellerLocation?: string, tab?: ETabs) => {
    const { seller, loaded } = this.props
    const selectedCategory = (category !== undefined ? category : this.state.selectedCategory) || ''
    const selectedSeller = (sellerLocation !== undefined ? sellerLocation : this.state.selectedSeller) || ''
    let isHidden = undefined

    if (tab === ETabs.HIDE || (!tab && this.state.activeTab === ETabs.HIDE)) {
      isHidden = true
    } else if (tab === ETabs.ACTIVE || (!tab && this.state.activeTab === ETabs.ACTIVE)) {
      isHidden = false
    }

    if (seller && (!loaded || !offset)) {
      this.props.apiSellerProducts({
        offset: offset || 0,
        limit: PRODUCTS_LIMIT,
        seller: seller.userId,
        ...(selectedCategory && selectedCategory !== DEFAULT_CATEGORY_ID && { category: selectedCategory }),
        ...(selectedSeller && { about_seller: selectedSeller }),
        ...(isHidden !== undefined && { hidden: isHidden }),
      })
    }
  }

  showProductModal = (product: ISellerProduct | null) => {
    const { seller } = this.props
    const { formatMessage } = this.props.intl

    if (seller) {
      this.props.productModal({
        type: EModalType.MODAL_PRODUCT,
        size: 'mini',
        style: { width: '980px', borderRadius: '16px' },
        close: true,
        props: {
          title: product ? formatMessage(messages.ProductChange) : formatMessage(messages.AddingProduct),
          marketId: seller.marketId,
          product,
          seller,
        },
      })
    }
  }

  showRemoveProduct = (event: React.MouseEvent<HTMLDivElement>, product: ISellerProduct) => {
    event.stopPropagation()
    const { seller } = this.props

    if (seller) {
      this.setState({ removedProduct: product, deleteProductModal: true })
    }
  }

  selectCategory = (category: ICategoryTab) => {
    this.setState({ selectedCategory: category.id })
    this.getProducts(0, category.id)
  }

  deleteProduct = (productId: string) => {
    const { seller } = this.props

    if (seller) {
      this.props.apiDeleteProduct(productId, seller.userId)
      this.setState({ removedProduct: null, deleteProductModal: false })
    }
  }

  selectSeller = (event: any, data: any) => {
    const { value } = data

    this.setState({ selectedSeller: value })
    this.getProducts(0, this.state.selectedCategory, value)
  }

  editSeller = () => {
    const { seller } = this.props
    const { formatMessage } = this.props.intl

    if (seller) {
      this.props.editSeller({
        type: EModalType.MODAL_EDIT_SELLER,
        props: {
          title: formatMessage(messages.EditingSellerShowcase),
          seller,
        },
      })
    }

    this.props.dropSavedSellerProfile()
  }

  back = () => {
    this.props.history.goBack()
  }
}

const mapStateToProps = (s: State, own: Props): TConnectedProps => {
  const { sellersList } = s.sellers
  const { loading, loaded, sellersProducts, categories = [] } = s.products
  const seller = sellersList.find((seller) => seller.id === own.match.params.id)
  let products: ISellerProduct[] = []

  if (!seller) {
    own.history.push(`${LOCATION_SELLERS_MARKETS}`)
  } else {
    products = sellersProducts.filter((product) => product.seller === seller.id)
  }

  const productsSeller = withCategory(products, categories)
  const commonSellers: string[] = seller?.aboutSellers || []

  if (seller && seller.isCommon && commonSellers.length === 0) {
    sellersProducts.forEach((item) => {
      if (item.aboutSeller) {
        commonSellers.push(item.aboutSeller)
      }
    })
  }

  let sellerCategories = seller?.categories || []

  if (sellerCategories.length === 0) {
    const inCategories = uniq(productsSeller.map((item) => item.category))
    sellerCategories = categories.filter((item) => inCategories.includes(item.id))
  }

  return {
    loaded,
    loading,
    seller,
    categories: sellerCategories,
    commonSellers: uniq(commonSellers),
    products: productsSeller.sort(sortProductBy),
    markets: s.markets.markets,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getMarkets: () =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        include_all: 'True',
      }),
    ),
  editSeller: (data: IModalEditSeller) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  productModal: (data: IModalProduct) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  apiSellerProducts: (data: ApiSellerProductsReq) => dispatch(Actions.action(Actions.API_SELLER_PRODUCTS, data)),
  apiDeleteProduct: (productId: string, userId: number) =>
    dispatch(
      Actions.action(Actions.API_PRODUCT_DELETE, {
        productId,
        userId,
      }),
    ),
  dropSavedSellerProfile: () => dispatch(Actions.actionEmpty(Actions.DROP_SAVED_SELLER_PROFILE)),
  getCategories: () => dispatch(Actions.actionEmpty(Actions.API_PRODUCT_CATEGORIES)),
})

export const Seller = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SellerCmp))
