import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Header } from 'semantic-ui-react'

import './index.scss'

import { IMarketInfo } from '../../../../types/TClient'

import messages from '../../../../localization/messages'
import { State } from '../../../../store/reducer'
import * as Actions from '../../../../store/actions'
import { SellersMarket } from './SellersMarket'

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  markets: IMarketInfo[],
}

type TDispatchedProps = {
  apiSellersMarkets: (offset: number) => Actions.Action,
}

type Props = RouteComponentProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

class SellersMarketsCmp extends React.Component<Props> {
  listRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLInputElement>()

  componentDidMount(): void {
    this.props.apiSellersMarkets(0)
  }

  render() {
    const { markets } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div ref={this.listRef} className='sellers-markets' onScroll={this.checkEnd}>
        <Header as='h1'>{formatMessage(messages.ShowcasesSellers)}</Header>
        <div className='sellers-markets__help'>{formatMessage(messages.SelectMarket)}</div>
        <div className='sellers-markets__list'>
          <SellersMarket marketName={formatMessage(messages.SystemVendors)} system />
          {markets.map(this.renderMarketItem)}
        </div>
      </div>
    )
  }

  renderMarketItem = (item: IMarketInfo) => {
    return <SellersMarket key={item.id} marketId={item.id} marketName={item.name} />
  }

  checkEnd = () => {
    const { markets, loading, loaded } = this.props

    if (this.listRef.current) {
      const { scrollHeight, offsetHeight, scrollTop } = this.listRef.current

      if (!loading && !loaded && scrollHeight - offsetHeight - scrollTop < 300) {
        this.props.apiSellersMarkets(markets.length)
      }
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => ({
  loading: s.sellers.sellersMarketsLoading,
  loaded: s.sellers.sellersMarketsLoaded,
  markets: s.sellers.sellersMarketsList,
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiSellersMarkets: (offset: number) =>
    dispatch(
      Actions.action(Actions.API_SELLERS_MARKETS, {
        offset,
      }),
    ),
})

export const SellersMarkets = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SellersMarketsCmp))
