import { EUserRole, IClientProfile } from '../types/TClient'
import { ApiClient } from '../types/TApi'

export const checkAvailableContent = (role: EUserRole, perm: EUserRole) => {
  return role <= perm
}

export const clientsToApp = (client: ApiClient): IClientProfile => ({
  id: client.id,
  phone: client.name ? `${client.phone} (${client.name})` : client.phone,
})
