import { of as observableOf, EMPTY } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'

import * as TApi from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, guardMergeMap, ofType } from './epicHelpers'
import {
  URL_CUSTOMER_ADDRESSES,
  URL_CUSTOMER_REVIEWS,
  URL_CUSTOMER_UPDATE_PROFILE,
  URL_CUSTOMERS_LIST,
  URL_ORDERS_LIST,
} from '../../modules/network/urls'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import {
  convertCustomerAddressFromApi,
  convertCustomerFromApi,
  convertCustomerReviewFromApi,
} from '../../utils/customersUtils'
import { convertOrderFromApi } from '../../utils/ordersUtils'

const customersListEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiCustomersList>(a$, Actions.API_CUSTOMERS_LIST), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_CUSTOMERS_LIST, {
          ...authRequestConfig(),
          params: {
            ...(c.data.ids && { ids: c.data.ids.join(',') }),
            ...(c.data.offset && { offset: c.data.offset }),
            ...(c.data.city && { city: c.data.city }),
            ...(c.data.search && { search: c.data.search }),
            ...(c.data.b2b !== undefined && { b2b: c.data.b2b }),
            ...(c.data.lastOrderDesc !== undefined && { lastOrderDesc: c.data.lastOrderDesc }),
            ...(c.data.firstOrderDesc !== undefined && { firstOrderDesc: c.data.firstOrderDesc }),
            ...(c.data.registerDesc !== undefined && { registerDesc: c.data.registerDesc }),
            ...(c.data.numOrdersDesc !== undefined && { numOrdersDesc: c.data.numOrdersDesc }),
            ...(c.data.dateTo !== undefined && { dateTo: c.data.dateTo }),
            ...(c.data.dateFrom !== undefined && { dateFrom: c.data.dateFrom }),
            ...(c.data.lastOrderDateTo !== undefined && { lastOrderDateTo: c.data.lastOrderDateTo }),
            ...(c.data.lastOrderDateFrom !== undefined && { lastOrderDateFrom: c.data.lastOrderDateFrom }),
            ...(c.data.firstOrderDateTo !== undefined && { firstOrderDateTo: c.data.firstOrderDateTo }),
            ...(c.data.firstOrderDateFrom !== undefined && { firstOrderDateFrom: c.data.firstOrderDateFrom }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCustomersListResp }) => {
            if (resp.data && Array.isArray(resp.data.customers)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.CUSTOMERS_LIST, {
                  customers: resp.data.customers.map(convertCustomerFromApi),
                  force: !c.data.offset,
                  total: resp.data.total,
                  totalWithOrders: resp.data.totalWithOrders,
                  totalWithoutOrders: resp.data.totalWithoutOrders,
                }),
              )
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const customerAddressesEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCustomerAddresses>(a$, Actions.API_CUSTOMER_ADDRESSES), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_CUSTOMER_ADDRESSES, {
          ...authRequestConfig(),
          params: {
            ...(c.data.offset && { offset: c.data.offset }),
            ...(c.data.userId && { userId: c.data.userId }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCustomerAddressesResp }) => {
            if (resp.data && Array.isArray(resp.data.addresses)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.CUSTOMER_ADDRESSES, {
                  addresses: resp.data.addresses.map(convertCustomerAddressFromApi),
                  total: resp.data.total,
                  force: !c.data.offset,
                }),
              )
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const customerReviewsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCustomerReviews>(a$, Actions.API_CUSTOMER_REVIEWS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_CUSTOMER_REVIEWS, {
          ...authRequestConfig(),
          params: {
            ...(c.data.offset && { offset: c.data.offset }),
            ...(c.data.userId && { userId: c.data.userId }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCustomerReviewsResp }) => {
            if (resp.data && Array.isArray(resp.data.reviews)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.CUSTOMER_REVIEWS, {
                  reviews: resp.data.reviews.map(convertCustomerReviewFromApi),
                  total: resp.data.total,
                  force: !c.data.offset,
                }),
              )
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const customerOrdersEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCustomerOrders>(a$, Actions.API_CUSTOMER_ORDERS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_ORDERS_LIST, {
          ...authRequestConfig(),
          params: {
            byDashboard: false,
            ...(c.data.offset && { offset: c.data.offset }),
            ...(c.data.userId && { userId: c.data.userId }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiCustomerOrdersResp }) => {
            if (resp.data && Array.isArray(resp.data.orders)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.CUSTOMER_ORDERS, {
                  orders: resp.data.orders.map(convertOrderFromApi),
                  total: resp.data.total,
                  force: !c.data.offset,
                }),
              )
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const customerUpdateProfileEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCustomerUpdate>(a$, Actions.API_CUSTOMER_UPDATE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(
          URL_CUSTOMER_UPDATE_PROFILE,
          {
            clientId: c.data.clientId,
            comment: c.data.comment,
          },
          { ...authRequestConfig() },
        ).pipe(
          mergeMap((resp: { data: TApi.CustomerUpdateProfileResp }) => {
            if ((resp.data && !resp.data.updated) || !resp.data) {
              return EMPTY
            }

            return observableOf<Actions.Action>(
              Actions.action(Actions.CUSTOMER_UPDATE, { id: c.data.clientId, comment: c.data.comment }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.CUSTOMER_UPDATE_ERROR))
          }),
        ),
      ),
    ),
  )

export const customersEpics: EpicFunc[] = [
  customersListEpic,
  customerAddressesEpic,
  customerReviewsEpic,
  customerOrdersEpic,
  customerUpdateProfileEpic,
]
