import React from 'react'
import InputMask from 'react-input-mask'
import classNames from 'classnames'

import './index.scss'

type TPropsM = {
  value: string,
  placeholder?: string,
  error?: boolean,
  onChange(value: string): void,
}

export const TimeInput: React.FC<TPropsM> = ({ value, onChange, placeholder, error }) => {
  const startsWithTwo = value[0] === '2'

  const mask = [/[0-2]/, startsWithTwo ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/]
  return (
    <InputMask
      mask={mask}
      value={value}
      placeholder={placeholder}
      maskPlaceholder={'00:00'}
      onChange={(e) => onChange(e.target.value)}
      className={classNames('masked-input', error && 'masked-input-error')}
    />
  )
}
