import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import { IOrder, IOrderBlock } from '../../types/TClient'

import messages from '../../localization/messages'
import { OrderItem } from './OrderItem'

type TOwnProps = {
  ordersBlock: IOrderBlock,
  selectedOrderId?: string,
  onSelectOrder: (order: IOrder) => any,
}

type Props = TOwnProps & WrappedComponentProps

class OrdersBlockCmp extends React.Component<Props> {
  render() {
    const { ordersBlock } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div>
        <div className='orders-block__tags'>
          {ordersBlock.titles.map((item) => {
            return (
              <div
                key={item.text}
                className='orders-block__tags-item'
                style={{ color: item.colorText, backgroundColor: item.color }}
              >
                {item.text}
              </div>
            )
          })}
        </div>
        <div className='orders-block'>
          <div className='orders-block__header'>
            <div className={classnames('orders-block__header-value', 'orders-block__num')}>
              {formatMessage(messages.Number)}
            </div>
            <div className={classnames('orders-block__header-value', 'orders-block__address')}>
              {formatMessage(messages.Receipt)}
            </div>
            <div className={classnames('orders-block__header-value', 'orders-block__weight')}>
              {formatMessage(messages.OrderWeight)}
            </div>
            <div className={classnames('orders-block__header-value', 'orders-block__courier')}>
              {formatMessage(messages.CollectorCourier)}
            </div>
            <div className={classnames('orders-block__header-value', 'orders-block__payment')}>
              {formatMessage(messages.Payment)}
            </div>
            <div className={classnames('orders-block__header-value', 'orders-block__status')}>
              {formatMessage(messages.Status)}
            </div>
          </div>
          <div className='orders-block__list'>{ordersBlock.orders.map(this.renderItem)}</div>
        </div>
      </div>
    )
  }

  renderItem = (item: IOrder, index: number) => {
    return (
      <React.Fragment key={item.id}>
        {index > 0 && <div className='orders-block__divider' />}
        <OrderItem selectedOrderId={this.props.selectedOrderId} order={item} onSelectOrder={this.props.onSelectOrder} />
      </React.Fragment>
    )
  }
}

export const OrdersBlock = injectIntl(OrdersBlockCmp)
