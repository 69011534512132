import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, DropdownProps, Form, Icon } from 'semantic-ui-react'

import './index.scss'

import { EOrderStatus, ICourier, ICouriersFilter, IOrder } from '../../../types/TClient'
import * as TApi from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { convertRole } from '../../../utils/courierUtils'

type TOwnProps = {
  order: IOrder,
  disabled?: boolean,
}

type TConnectedProps = {
  updateLoading: boolean,
  loading: boolean,
  couriers: ICourier[],
}

type TDispatchedProps = {
  apiCouriersList(filter: ICouriersFilter): Actions.Action,
  apiAssignOrder(data: TApi.AssignOrderReq): Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  assignCollectorActive: boolean,
  collector?: string,
}

class OrderCollectorCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      assignCollectorActive: false,
    }
  }

  componentDidMount() {
    const { order } = this.props

    this.props.apiCouriersList({ market: order.marketId })
  }

  render() {
    const { order, disabled } = this.props
    const { formatMessage } = this.props.intl
    const { assignCollectorActive } = this.state
    const { collectorPhone } = order
    const editableCollector = !disabled && order.statusId < EOrderStatus.COURIER
    const isCollector = !!collectorPhone

    return (
      <div className='order__collector'>
        <div className='order__block-title'>
          {formatMessage(messages.Collector)}
          {editableCollector && isCollector && !assignCollectorActive && this.renderEditBtn(this.activeAssignOrder)}
        </div>
        {assignCollectorActive && this.renderAssignField()}
        {isCollector && !assignCollectorActive && this.renderInfo()}
        {!disabled && !isCollector && !assignCollectorActive && (
          <>
            {order.statusId < EOrderStatus.DELIVERED ? (
              <Button className='order__btn' color='blue' onClick={this.activeAssignOrder}>
                {formatMessage(messages.AssignCollector)}
              </Button>
            ) : (
              <div className='order__block-text'>{formatMessage(messages.NotBeenAssigned)}</div>
            )}
          </>
        )}
        {disabled && !isCollector && !assignCollectorActive && (
          <div className='order__block-text'>{formatMessage(messages.NotAssigned)}</div>
        )}
      </div>
    )
  }

  renderAssignField = () => {
    const { updateLoading, couriers } = this.props
    const { formatMessage } = this.props.intl
    const { collector } = this.state

    const options = couriers.map((c) => ({
      key: c.id,
      value: c.id,
      description: convertRole(c.role),
      text: this.getCollectorName(c.phone, c.firstName, c.lastName),
    }))

    return (
      <Form className='order__collector-form'>
        <Form.Dropdown
          search
          selection
          placeholder={formatMessage(messages.SelectCourier)}
          value={collector}
          options={options}
          onChange={this.onChangeCollector}
        />
        <Button
          className='order__btn'
          loading={updateLoading}
          disabled={updateLoading}
          color='blue'
          onClick={this.assignOrder}
        >
          {formatMessage(messages.Save)}
        </Button>
        <div
          className='order__btn-outline'
          onClick={() => this.setState({ assignCollectorActive: false, collector: undefined })}
        >
          {formatMessage(messages.Cancel)}
        </div>
      </Form>
    )
  }

  renderInfo = () => {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const { collectorFirstName, collectorLastName, collectorPhone } = order

    return (
      <div className='order__block-row'>
        <div className='order__block-item'>
          <div className='order__block-label'>{formatMessage(messages.Name)}</div>
          <div className='order__block-text'>
            {collectorFirstName ? `${collectorFirstName} ${collectorLastName || ''}` : '–'}
          </div>
        </div>
        <div className='order__block-item'>
          <div className='order__block-label'>{formatMessage(messages.Phone)}</div>
          <div className='order__block-text'>{collectorPhone}</div>
        </div>
      </div>
    )
  }

  renderEditBtn = (action: () => any, color?: string) => {
    return (
      <div className='order__edit-btn' onClick={action} style={{ backgroundColor: color }}>
        <Icon className='order__edit-btn__icon' name='pencil alternate' />
      </div>
    )
  }

  getCollectorName = (phone: string, firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
      return phone
    }
    return `${firstName || ''} ${lastName || ''} (${phone})`
  }

  activeAssignOrder = () => {
    const { order } = this.props

    this.setState({ assignCollectorActive: true, collector: order.collectorId || undefined })
  }

  assignOrder = () => {
    const { order } = this.props
    const { collector } = this.state

    if (collector) {
      this.props.apiAssignOrder({
        orderId: order.id,
        courierId: collector,
      })

      this.setState({ assignCollectorActive: false, collector: undefined })
    }
  }

  onChangeCollector = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data

    this.setState({ collector: String(value) })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loading, updateLoading } = s.orders

  return {
    updateLoading,
    loading,
    couriers: s.couriers.couriersList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiAssignOrder: (data: TApi.AssignOrderReq) => dispatch(Actions.action(Actions.API_ORDER_ASSIGN, data)),
})

export const OrderCollector = connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderCollectorCmp))
