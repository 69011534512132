import {
  ICategoryApi,
  ICountryApi,
  IGroupApi,
  IModerationProductApi,
  IProductApi,
  ISellerProductApi,
  IStepApi,
  IUnitApi,
} from '../types/TApi'
import {
  EGroupType,
  ICategory,
  ICountry,
  ICurrency,
  IGroupProducts,
  IModerationProduct,
  IProduct,
  IProductOrder,
  ISellerProduct,
  IStep,
  IUnit,
} from '../types/TClient'

import { getIntl, intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'
import { CURRENCIES, DEF_CURRENCY } from '../localization/currencies'
import { APP_LINK } from '../modules/network/urls'
import { round } from './ordersUtils'

export const UNIT_PCS = 'шт'
export const UNIT_KG = 'Кг'
export const UNIT_LITRE = 'Л'

export const convertProductFromApi = (product: IProductApi): IProduct => {
  return {
    id: product.id,
    imageUrl: product.image_url,
    marketId: product.market_id,
    name: product.name,
    pcsWeightGr: product.pcs_weight_gr,
    price: product.price,
    totalPrice: product.total_price,
    sellerId: product.seller_id,
    sellerName: product.seller_name,
    sellerIsCommon: product.seller_is_common,
    sellerIsSystem: product.seller_is_system,
    sellerCommission: product.seller_commission,
    sellerNickname: product.seller_nickname,
    sellerLocation: product.seller_location,
    sellerOrganisation: product.seller_organisation,
    sellerINN: product.seller_inn,
    sellerAddress: product.seller_address,
    step: product.step,
    category: product.category,
    categoryId: product.category_id,
    categoryPriority: product.category_priority,
    subcategory: product.subcategory,
    subcategoryId: product.subcategory_id,
    subcategoryPriority: product.subcategory_priority,
    unit: product.unit,
    country: product.country,
    isHide: product.is_hide,
    description: product.description,
    tags: product.tags,
    priceWeightGr: product.price_weight_gr || 1000,
    priceWithoutDiscount: +product.price_without_discount,
    currency: convertCurrency(product.currency_code),
    ...(product.about_seller && { aboutSeller: product.about_seller }),
    ...(product.market_category && { marketCategory: product.market_category }),
    ...(product.market_category_id && { marketCategoryId: product.market_category_id }),
    ...(product.market_category_priority && { marketCategoryPriority: product.market_category_priority }),
    ...(product.market_subcategory && { marketSubcategory: product.market_subcategory }),
    ...(product.market_subcategory_id && { marketSubcategoryId: product.market_subcategory_id }),
    ...(product.market_subcategory_priority && { marketSubcategoryPriority: product.market_subcategory_priority }),
    ...(product.vendor_code && { vendorCode: product.vendor_code }),
    ...(product.rating !== undefined && { rating: +product.rating }),
  }
}

export const convertModerationProductFromApi = (product: IModerationProductApi): IModerationProduct => {
  return {
    id: product.id,
    num: product.num,
    productId: product.product_id,
    status: product.status,
    text: product.text,
    createdAt: product.created_at,
    moderateAt: product.moderate_at,
    imageUrl: product.image_url,
    marketId: product.market_id,
    name: product.name,
    pcsWeightGr: product.pcs_weight_gr,
    price: product.price,
    totalPrice: product.total_price,
    currency: convertCurrency(product.currency_code),
    sellerId: product.seller_id,
    sellerName: product.seller_name,
    sellerOrganisation: product.seller_organisation,
    sellerIsCommon: product.seller_is_common,
    sellerIsSystem: product.seller_is_system,
    sellerCommission: product.seller_commission,
    sellerNickname: product.seller_nickname,
    sellerLocation: product.seller_location,
    step: product.step,
    category: product.category,
    categoryId: product.category_id,
    categoryPriority: product.category_priority,
    subcategory: product.subcategory,
    subcategoryId: product.subcategory_id,
    subcategoryPriority: product.subcategory_priority,
    unit: product.unit,
    country: product.country,
    isHide: product.is_hide,
    description: product.description,
    oldImageUrl: product.old_image_url,
    oldName: product.old_name,
    oldPcsWeightGr: product.old_pcs_weight_gr,
    oldPrice: product.old_price,
    oldStep: product.old_step,
    oldCategory: product.old_category,
    oldCategoryId: product.old_category_id,
    oldSubcategory: product.old_subcategory,
    oldSubcategoryId: product.old_subcategory_id,
    oldUnit: product.old_unit,
    oldCountry: product.old_country,
    oldDescription: product.old_description,
    priceWeightGr: product.price_weight_gr || 1000,
    oldPriceWeightGr: product.old_price_weight_gr || 1000,
    ...(product.about_seller && { aboutSeller: product.about_seller }),
  }
}

export const convertProductSellerFromApi = (product: ISellerProductApi): ISellerProduct => {
  return {
    id: product.id,
    imageUrl: product.image_url,
    market: product.market,
    name: product.name,
    pcsWeightGr: product.pcs_weight_gr,
    price: product.price,
    seller: product.seller,
    step: product.step,
    subcategory: product.subcategory,
    unit: product.unit,
    country: product.country,
    hide: product.hide,
    description: product.description,
    priceWithoutDiscount: +product.price_without_discount,
    priceWeightGr: product.price_weight_gr || 1000,
    tags: (product.tags || []).map((t) => t.id),
    currency: convertCurrency(product.currency_code),
    ...(product.about_seller && { aboutSeller: product.about_seller }),
    ...(product.market_category && { stockCategory: product.market_category }),
    ...(product.market_sub_category && { stockSubcategory: product.market_sub_category }),
    ...(product.vendor_code && { vendorCode: product.vendor_code }),
    ...(product.rating !== undefined && { rating: +product.rating }),
  }
}

export const convertProductToProductSeller = (product: IProduct): ISellerProduct => {
  return {
    id: product.id,
    imageUrl: product.imageUrl,
    market: product.marketId,
    name: product.name,
    pcsWeightGr: product.pcsWeightGr,
    price: product.price.toString(),
    seller: product.sellerId,
    sellerIsCommon: product.sellerIsCommon,
    sellerIsSystem: product.sellerIsSystem,
    sellerName: product.sellerName,
    sellerCommission: product.sellerCommission,
    step: product.step,
    subcategory: product.subcategoryId,
    unit: product.unit,
    country: product.country,
    hide: product.isHide,
    tags: product.tags,
    description: product.description,
    category: product.categoryId,
    aboutSeller: product.aboutSeller,
    stockCategory: product.marketCategoryId,
    stockSubcategory: product.marketSubcategoryId,
    vendorCode: product.vendorCode,
    rating: product.rating,
    priceWithoutDiscount: product.priceWithoutDiscount,
    priceWeightGr: product.priceWeightGr || 1000,
    currency: product.currency,
  }
}

export const convertCategoriesFromApi = (category: ICategoryApi): ICategory => {
  return {
    id: category.id,
    name: category.name,
    priority: category.priority,
    subcategories: category.subcategories.map((subcategory) => ({
      id: subcategory.subcategory_id,
      name: subcategory.subcategory_name,
      priority: subcategory.subcategory_priority,
    })),
  }
}

export const convertGroupFromApi = (group: IGroupApi): IGroupProducts => {
  return {
    id: group.id,
    marketId: group.marketId,
    type: group.key === '1' ? EGroupType.GROUP : EGroupType.CHAIN,
    disable: group.disable,
    products: group.products.map((item) => ({
      id: item.id.toString(),
      productId: item.productId,
      groupId: item.groupId,
      index: item.index,
      root: item.root,
      ...(item.product && { product: convertProductFromApi(item.product) }),
    })),
  }
}

export const convertCountriesFromApi = (country: ICountryApi): ICountry => {
  return {
    name: country.code,
  }
}

export const convertStepsFromApi = (step: IStepApi): IStep => {
  return {
    id: step.step_id,
    name: step.step_name,
  }
}

export const convertUnitsFromApi = (unit: IUnitApi): IUnit => {
  return {
    id: unit.unit_id,
    name: unit.unit_name,
  }
}

export const withCategory = (products: ISellerProduct[], categories: ICategory[]) => {
  return products.map((product) => {
    const category = categories.find((item) => item.subcategories.find((sub) => sub.id === product.subcategory))

    return {
      ...product,
      ...(category && { category: category.id }),
    }
  })
}

export const updatePriceByCommission = (price: number, commission: number): number => {
  return round((price * (100 + commission)) / 100)
}

export const sourcePrice = (price: number, commission: number): number => {
  return round(price / ((100 + commission) / 100))
}

export const totalByProducts = (products: IProductOrder[]) => {
  let totalPrice = 0
  let totalWeightKg = 0

  for (const product of products) {
    totalPrice += product.price * product.quantity
    totalWeightKg += (product.unit === UNIT_PCS ? product.pcsWeightGr : 1000) * product.quantity
  }

  totalPrice = round(totalPrice)
  totalWeightKg = round(totalWeightKg / 1000)

  return { totalPrice, totalWeightKg }
}

export const sortProductBy = (product1: ISellerProduct, product2: ISellerProduct) => {
  const byName = product1.name.localeCompare(product2.name, 'ru', { sensitivity: 'base' })
  const byId = product1.id.localeCompare(product2.id)

  if (
    +product1.price > +product2.price ||
    (+product1.price === +product2.price && byName === 1) ||
    (+product1.price === +product2.price && byName === 0 && product1.hide && !product2.hide) ||
    (+product1.price === +product2.price && byName === 0 && product1.hide === product2.hide && byId === 1)
  ) {
    return 1
  }

  return -1
}

export const sortMarketProductByCategories = (product1: IProduct, product2: IProduct) => {
  const {
    marketCategoryPriority: categoryPriority1 = Infinity,
    marketSubcategoryPriority: subcategoryPriority1 = Infinity,
    rating: rating1 = 0,
  } = product1
  const {
    marketCategoryPriority: categoryPriority2 = Infinity,
    marketSubcategoryPriority: subcategoryPriority2 = Infinity,
    rating: rating2 = 0,
  } = product2

  const price1 = +product1.price
  const price2 = +product2.price

  const byName = product1.name.localeCompare(product2.name, 'ru', { sensitivity: 'base' })
  const byId = product1.id.localeCompare(product2.id)

  if (categoryPriority1 > categoryPriority2) {
    return 1
  } else if (categoryPriority1 === categoryPriority2) {
    if (subcategoryPriority1 > subcategoryPriority2) {
      return 1
    } else if (subcategoryPriority1 === subcategoryPriority2) {
      if (rating1 < rating2) {
        return 1
      } else if (rating1 === rating2) {
        if (price1 > price2) {
          return 1
        } else if (price1 === price2) {
          if (byName === 1 || (byName === 0 && byId === 1)) {
            return 1
          }
        }
      }
    }
  }

  return -1
}

export const sortMarketProductBySeller = (product1: IProduct, product2: IProduct) => {
  const { categoryPriority: categoryPriority1 = Infinity, subcategoryPriority: subcategoryPriority1 = Infinity } =
    product1
  const { categoryPriority: categoryPriority2 = Infinity, subcategoryPriority: subcategoryPriority2 = Infinity } =
    product2
  const price1 = +product1.price
  const price2 = +product2.price

  const byName = product1.name.localeCompare(product2.name, 'ru', { sensitivity: 'base' })
  const byId = product1.id.localeCompare(product2.id)

  if (categoryPriority1 > categoryPriority2) {
    return 1
  } else if (categoryPriority1 === categoryPriority2) {
    if (subcategoryPriority1 > subcategoryPriority2) {
      return 1
    } else if (subcategoryPriority1 === subcategoryPriority2) {
      if (price1 > price2) {
        return 1
      } else if (price1 === price2) {
        if (byName === 1 || (byName === 0 && byId === 1)) {
          return 1
        }
      }
    }
  }

  return -1
}

export const linkByProducts = (cityId: string, marketId: string, productId: string) => {
  return `${APP_LINK}city/${cityId}/market/${marketId}/products/product/${productId}`
}

export const linkBySeller = (cityId: string, marketId: string, productId: string) => {
  return `${APP_LINK}city/${cityId}/market/${marketId}/sellers/product/${productId}/SELLER`
}

export const firstModeration = (product: IModerationProduct) => {
  return (
    product.imageUrl === product.oldImageUrl &&
    product.name === product.oldName &&
    product.description === product.oldDescription &&
    product.price === product.oldPrice &&
    product.step === product.oldStep &&
    product.unit === product.oldUnit &&
    product.pcsWeightGr === product.oldPcsWeightGr &&
    product.category === product.oldCategory &&
    product.subcategory === product.oldSubcategory &&
    product.country === product.oldCountry
  )
}

export const formatPrice = (price?: number | string, currency?: ICurrency) => {
  if (price === undefined) {
    return ''
  }

  const priceStr = new Intl.NumberFormat(getIntl()?.locale).format(round(+price))
  const currencyStr = currency?.symbol_native || ''
  const spaceStr = currency?.spaceStr || !currency ? '\u00A0' : ''

  if (currency?.dirRight) {
    return `${priceStr}\u00A0${currencyStr}`
  }

  return `${currencyStr}${currencyStr ? spaceStr : ''}${priceStr}`
}

export const formatWeight = (weightGr: number, unit: string) => {
  return weightGr >= 1000
    ? `${+(weightGr / 1000).toFixed(3)} ${unit === UNIT_LITRE ? intlMessage(messages.Litre) : intlMessage(messages.Kg)}`
    : `${weightGr} ${unit === UNIT_LITRE ? intlMessage(messages.Millilitre) : intlMessage(messages.Gram)}`
}

export const unitIntl = (unit: string) => {
  switch (unit) {
    case UNIT_PCS:
      return intlMessage(messages.Pcs)
    case UNIT_KG:
      return intlMessage(messages.Kg)
    case UNIT_LITRE:
      return intlMessage(messages.Litre)
    default:
      return intlMessage(messages.Kg)
  }
}

export const convertCurrency = (currencyCode?: string) => {
  if (!currencyCode) {
    return DEF_CURRENCY
  }

  const currency = CURRENCIES.find((item) => item.code === currencyCode)

  return currency || DEF_CURRENCY
}
