import * as React from 'react'
import { connect } from 'react-redux'

import './index.scss'

import { State } from '../../store/reducer'
import { IAlert } from '../../store/reducers/alerts'
import * as Actions from '../../store/actions'
import { Alert } from './Alert'

type Props = {
  alerts: IAlert[] | null,
  close(): any,
  closeAll(): any,
}

class AlertsCmp extends React.Component<Props> {
  render() {
    const { alerts } = this.props

    return <div className='alerts-container'>{alerts && alerts.map(this.renderItem)}</div>
  }

  renderItem = (item: IAlert) => {
    return <Alert key={item.id} alert={item} />
  }
}

const mapStateToProps = (s: State) => {
  return { alerts: s.alerts.alerts }
}

const mapDispatchToProps = {
  close: () => Actions.action(Actions.ALERT_POP, {}),
  closeAll: () => Actions.actionEmpty(Actions.ALERT_CLEAR),
}

export const AlertsContainer = connect(mapStateToProps, mapDispatchToProps)(AlertsCmp)
