import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { IMarket, ICustomer } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'
import {
  CustomerDesc,
  CustomerReviews,
  CustomerOrders,
  CustomerAddresses,
  CustomerActivity,
  CustomerDivider,
} from '../../CustomerInfo'
import { checkMobile } from '../../../utils/deviceUtils'

type TOwnProps = {
  marketId: string,
  userId: number,
  onClose: () => void,
}

type TConnectedProps = {
  customer?: ICustomer,
  market?: IMarket,
}

type TDispatchedProps = {
  apiCustomersList: (id: number) => Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

class CustomerCmp extends React.Component<Props> {
  componentDidMount(): void {
    const { userId, customer } = this.props

    if (!customer) {
      this.props.apiCustomersList(userId)
    }
  }

  render() {
    const { customer } = this.props
    const { formatMessage } = this.props.intl
    const isMobile = checkMobile()

    return (
      <div className='modal-customer'>
        <div className='modal-customer__header'>
          <div className='modal-customer__title'>{formatMessage(messages.CustomerCard)}</div>
        </div>
        <div className='modal-customer__customer'>
          <div className='modal-customer__column'>
            {customer && this.renderDesc(customer)}
            {customer && this.renderActivity(customer)}
            {customer && this.renderAddresses(customer)}
          </div>
          {!isMobile ? <div className='modal-customer__column-divider' /> : <div className='customer__block-padding' />}
          <div className='modal-customer__column'>
            {customer && this.renderOrders(customer)}
            {customer && this.renderReviews(customer)}
          </div>
        </div>
      </div>
    )
  }

  renderDesc = (customer: ICustomer) => {
    return (
      <>
        <CustomerDesc customer={customer} />
        <CustomerDivider />
      </>
    )
  }

  renderActivity = (customer: ICustomer) => {
    return (
      <>
        <CustomerActivity customer={customer} />
        <CustomerDivider />
      </>
    )
  }

  renderAddresses = (customer: ICustomer) => {
    return <CustomerAddresses customer={customer} />
  }

  renderOrders = (customer: ICustomer) => {
    return (
      <>
        <CustomerOrders customer={customer} />
        <CustomerDivider />
      </>
    )
  }

  renderReviews = (customer: ICustomer) => {
    return <CustomerReviews customer={customer} />
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { markets } = s.markets
  const { customersList, loading } = s.customers

  const market = markets.find((market) => market.id === own.marketId)

  if (!market && markets && markets.length > 0) {
    own.onClose()
  }

  const customer = customersList.find((customer) => customer.userId === own.userId)

  if (!customer && customersList && customersList.length > 0 && !loading) {
    own.onClose()
  }

  return {
    market,
    customer,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCustomersList: (id: number) => dispatch(Actions.action(Actions.API_CUSTOMERS_LIST, { ids: [id] })),
})

export const CustomerModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerCmp))
