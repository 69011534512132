import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Button, Form, Icon, Loader, Modal } from 'semantic-ui-react'

import './index.scss'
import { EImageType, ISavedSellerProfile, ISeller } from '../../../types/TClient'
import { ApiUpdateSellerReq } from '../../../types/TApi'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import { EModalType, IModalImageCropper } from '../../../store/reducers/modals'
import * as Actions from '../../../store/actions'
import defaultImg from '../../../assets/images/default-image.png'
import defaultAvatar from '../../../assets/images/avatar-placeholder.png'

type TOwnProps = {
  title: string,
  seller: ISeller,
  onClose: () => void,
}

type TConnectedProps = {
  avatarLoading: boolean,
  imageLoading: boolean,
  loading: boolean,
  savedSellerProfile: ISavedSellerProfile,
  errorApi?: string,
}

type TDispatchedProps = {
  cropImage: (data: IModalImageCropper) => Actions.Action,
  uploadImage: (file: File, type: EImageType) => Actions.Action,
  dropApiError: () => Actions.Action,
  updateSeller: (data: ApiUpdateSellerReq) => Actions.Action,
  updateIsHide: (data: { id: string, isHide: boolean }) => Actions.Action,
  updateCourier: (sellerId: string) => Actions.Action,
}

type Props = TOwnProps & TConnectedProps & TDispatchedProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  changed: boolean,
  name: string,
  nickname: string,
  errors: FieldError[],
  avatarLoading: boolean,
  imgLoading: boolean,
  hide: boolean,
  courier: boolean,
}

const FIELD_NAME = 'seller_name'
const FIELD_NICKNAME = 'seller_nickname'
const FIELD_IMAGE = 'seller_image'
const FIELD_AVATAR = 'seller_avatar'
const FIELD_HIDE_SELLER = 'hide_seller'
const FIElD_COURIER = 'courier'

class UpdateSellerCmp extends React.Component<Props, IState> {
  private imageRef = React.createRef<HTMLInputElement>()
  private avatarRef = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      changed: false,
      name: props.seller.name || '',
      nickname: props.seller.nickname || '',
      errors: [],
      avatarLoading: true,
      imgLoading: true,
      hide: props.seller.isHide || false,
      courier: !!props.seller.courierId,
    }
  }

  shouldComponentUpdate(nextProps: Readonly<Props>) {
    if (nextProps.avatarLoading !== this.props.avatarLoading) {
      this.setState({ avatarLoading: nextProps.avatarLoading })
    }

    if (nextProps.seller.isHide !== this.props.seller.isHide) {
      this.setState({ hide: nextProps.seller.isHide })
    }

    if (nextProps.imageLoading !== this.props.imageLoading) {
      this.setState({ imgLoading: nextProps.imageLoading })
    }

    return true
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>
          {this.renderImage()}
          {this.renderAvatar()}
          {this.renderName()}
          {this.renderNickname()}
          {this.renderHideSeller()}
          {this.renderCourierSeller()}
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={this.props.onClose}>
            {formatMessage(messages.Cancel)}
          </Button>
          <Button positive onClick={this.updateSeller}>
            {formatMessage(messages.Save)}
          </Button>
        </Modal.Actions>
      </>
    )
  }

  renderName = () => {
    const { formatMessage } = this.props.intl
    const { name } = this.state
    const error = this.checkError(FIELD_NAME)

    return (
      <div className='seller-update__field'>
        <div className='seller-update__field-label'>{formatMessage(messages.ShopName)}:</div>
        <Form.Input
          value={name}
          className='seller-update__field-value'
          name={FIELD_NAME}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderNickname = () => {
    const { formatMessage } = this.props.intl
    const { nickname } = this.state
    const error = this.checkError(FIELD_NICKNAME)

    return (
      <div className='seller-update__field'>
        <div className='seller-update__field-label'>{formatMessage(messages.SellerNameNickname)}:</div>
        <Form.Input
          value={nickname}
          className='seller-update__field-value'
          name={FIELD_NICKNAME}
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
      </div>
    )
  }

  renderHideSeller = () => {
    const { formatMessage } = this.props.intl
    const { hide } = this.state
    const error = this.checkError(FIELD_HIDE_SELLER)

    return (
      <div className='seller-update__field'>
        <div className='seller-update__field-label'>{formatMessage(messages.Hidden)}:</div>
        <Form.Checkbox
          toggle
          checked={hide}
          name={FIELD_HIDE_SELLER}
          error={error}
          onChange={() => this.setState(({ hide }) => ({ hide: !hide }))}
        />
      </div>
    )
  }

  renderCourierSeller = () => {
    const { formatMessage } = this.props.intl
    const { courierId } = this.props.seller
    const { courier } = this.state
    const error = this.checkError(FIElD_COURIER)

    return (
      <div className='seller-update__field'>
        <div className='seller-update__field-label'>{formatMessage(messages.Courier)}:</div>
        <Form.Checkbox
          toggle
          checked={courier}
          name={FIElD_COURIER}
          error={error}
          onChange={() => this.setState((s) => ({ courier: courierId ? true : !s.courier }))}
        />
      </div>
    )
  }

  renderImage = () => {
    const { savedSellerProfile, seller } = this.props
    const { formatMessage } = this.props.intl
    const { imgLoading } = this.state
    const imageUrl = savedSellerProfile.imageUrl || seller.imageUrl

    return (
      <div className='seller-update__field'>
        <input
          ref={this.imageRef}
          type='file'
          className='seller-update__field-value'
          name={FIELD_IMAGE}
          onChange={this.handleChangeImage}
          accept='image/x-png,image/jpeg'
          style={{ display: 'none' }}
        />
        <div className='seller-update__field-label'>{formatMessage(messages.PhotoYourShop)}: </div>
        <div className='seller-update__field-image__wrapper'>
          <div className='seller-update__field-image-container'>
            {imgLoading && (
              <div className='seller-update__field__placeholder'>
                <Loader active />
              </div>
            )}
            <img
              alt='seller img'
              className='seller-update__field-image'
              src={imageUrl ? imageUrl : defaultImg}
              onLoad={() => this.setState({ imgLoading: false })}
              onError={() => this.setState({ imgLoading: false })}
            />
          </div>
          <div className='seller-update__field-image__actions'>
            <div className='seller-update__field-image__actions-btn' onClick={() => this.editImage(imageUrl)}>
              <Icon className='seller-update__field-image__actions-btn-icon' name='crop' size='large' />
            </div>
            <div className='seller-update__field-image__actions-btn' onClick={this.uploadImage}>
              <Icon className='seller-update__field-image__actions-btn-icon' name='upload' size='large' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAvatar = () => {
    const { savedSellerProfile, seller } = this.props
    const { formatMessage } = this.props.intl
    const { avatarLoading } = this.state
    const imageUrl = savedSellerProfile.avatarUrl || seller.avatarUrl

    return (
      <div className='seller-update__field'>
        <input
          ref={this.avatarRef}
          type='file'
          className='seller-update__field-value'
          name={FIELD_AVATAR}
          onChange={this.handleChangeImage}
          accept='image/x-png,image/jpeg'
          style={{ display: 'none' }}
        />
        <div className='seller-update__field-label'>{formatMessage(messages.SellerPhoto)}: </div>
        <div className='seller-update__field-avatar__wrapper'>
          <div className='seller-update__field-avatar'>
            {avatarLoading && (
              <div className='seller-update__field__placeholder'>
                <Loader active />
              </div>
            )}
            <img
              alt='avatar'
              className='seller-update__field-image'
              src={imageUrl ? imageUrl : defaultAvatar}
              onClick={this.uploadAvatar}
              onLoad={() => this.setState({ avatarLoading: false })}
              onError={() => this.setState({ avatarLoading: false })}
            />
          </div>
          <div className='seller-update__field-image__actions'>
            <div className='seller-update__field-avatar__actions-btn' onClick={() => this.editAvatar(imageUrl)}>
              <Icon className='seller-update__field-avatar__actions-btn-icon' name='crop' size='large' />
            </div>
            <div className='seller-update__field-image__actions-btn' onClick={this.uploadAvatar}>
              <Icon className='seller-update__field-image__actions-btn-icon' name='upload' size='large' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  editImage = (imageUrl: string) => {
    const { formatMessage } = this.props.intl

    this.props.cropImage({
      type: EModalType.MODAL_IMAGE_CROPPER,
      props: {
        imageUrl,
        title: formatMessage(messages.ImageEditing),
        aspectRatio: 16 / 9,
        uploadImage: (file: File) => this.props.uploadImage(file, EImageType.SELLER_IMAGE),
      },
    })
  }

  editAvatar = (imageUrl: string) => {
    const { formatMessage } = this.props.intl

    this.props.cropImage({
      type: EModalType.MODAL_IMAGE_CROPPER,
      props: {
        imageUrl,
        title: formatMessage(messages.ImageEditing),
        aspectRatio: 1,
        uploadImage: (file: File) => this.props.uploadImage(file, EImageType.SELLER_AVATAR),
      },
    })
  }

  uploadImage = () => {
    this.imageRef.current?.click()
  }

  uploadAvatar = () => {
    this.avatarRef.current?.click()
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any, data: any) => {
    const { name, value } = data

    if (this.props.errorApi) {
      this.props.dropApiError()
    }

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_NAME:
        this.setState({ name: value, errors: this.removeError(FIELD_NAME) })
        break
      case FIELD_NICKNAME:
        this.setState({ nickname: value, errors: this.removeError(FIELD_NICKNAME) })
        break
    }
  }

  handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { formatMessage } = this.props.intl
    const { name, files } = event.target

    if (this.props.errorApi) {
      this.props.dropApiError()
    }

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    if (files && files.length) {
      switch (name) {
        case FIELD_IMAGE:
          this.props.cropImage({
            type: EModalType.MODAL_IMAGE_CROPPER,
            props: {
              title: formatMessage(messages.ImageEditing),
              image: files[0],
              aspectRatio: 16 / 9,
              uploadImage: (file: File) => this.props.uploadImage(file, EImageType.SELLER_IMAGE),
            },
          })
          break
        case FIELD_AVATAR:
          this.props.cropImage({
            type: EModalType.MODAL_IMAGE_CROPPER,
            props: {
              title: formatMessage(messages.ImageEditing),
              image: files[0],
              aspectRatio: 1,
              uploadImage: (file: File) => this.props.uploadImage(file, EImageType.SELLER_AVATAR),
            },
          })
          break
      }
    }
  }

  updateSeller = () => {
    const { savedSellerProfile, seller } = this.props
    const { name, nickname, hide, courier } = this.state

    this.props.onClose()

    const imageUrl = savedSellerProfile.imageUrl || seller.imageUrl
    const avatarUrl = savedSellerProfile.avatarUrl || seller.avatarUrl

    if (!!seller.courierId !== courier) {
      this.props.updateCourier(seller.id)
    }

    this.props.updateIsHide({
      id: seller.id,
      isHide: hide,
    })

    this.props.updateSeller({
      id: seller.id,
      userId: seller.userId,
      name,
      nickname,
      imageUrl,
      avatarUrl,
    })
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { errorApi, loading, savedSellerProfile, imageLoading, avatarLoading } = s.sellers

  return {
    imageLoading,
    avatarLoading,
    savedSellerProfile,
    loading,
    errorApi,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  updateIsHide: (data: { id: string, isHide: boolean }) =>
    dispatch(Actions.action(Actions.SELLER_UPDATE_IS_HIDE, data)),
  updateCourier: (sellerId: string) => dispatch(Actions.action(Actions.API_COURIER_SELLER, { sellerId })),
  cropImage: (data: IModalImageCropper) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  updateSeller: (data: ApiUpdateSellerReq) => dispatch(Actions.action(Actions.API_UPDATE_SELLER, data)),
  dropApiError: () => dispatch(Actions.actionEmpty(Actions.DROP_API_ERROR)),
  uploadImage: (file: File, type: EImageType) =>
    dispatch(
      Actions.action(Actions.API_UPLOAD_IMAGE, {
        file,
        type,
      }),
    ),
})

export const UpdateSellerModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(UpdateSellerCmp))
