import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import './index.scss'

import { ICustomer } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { formatPhone, getName } from '../../../utils/customersUtils'
import { CustomerComment } from './CustomerComment'
import * as Actions from '../../../store/actions'

type TOwnProps = {
  customer: ICustomer,
}

type TDispatchedProps = {
  updateComment(clientId: string, comment: string): Actions.Action,
}

type TProps = TOwnProps & WrappedComponentProps & TDispatchedProps

class CustomerDescCmp extends React.Component<TProps> {
  render() {
    const { customer } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='customer__info'>
        <div className='customer__block-block'>
          <div className='customer__block-label'>{formatMessage(messages.Name)}</div>
          <div className='customer__block-text'>{getName(customer.firstName, customer.lastName)}</div>
        </div>
        <div className='customer__block-padding' />
        <div className='customer__block-row'>
          <div className='customer__block-item'>
            <div className='customer__block-label'>{formatMessage(messages.Phone)}</div>
            <div className='customer__block-text'>{formatPhone(customer.phone)}</div>
          </div>
          <div className='customer__block-item'>
            <div className='customer__block-label'>{formatMessage(messages.ClientType)}</div>
            <div className='customer__block-text'>
              {customer.business ? formatMessage(messages.B2B) : formatMessage(messages.B2B)}
            </div>
          </div>
        </div>
        <CustomerComment
          clientId={customer.id}
          message={customer.comment}
          update={(msg: string) => this.props.updateComment(customer.id, msg)}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  updateComment: (clientId: string, comment: string) =>
    dispatch(Actions.action(Actions.API_CUSTOMER_UPDATE, { clientId, comment })),
})

export const CustomerDesc = connect(null, mapDispatchToProps)(injectIntl(CustomerDescCmp))
