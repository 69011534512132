import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const Cart2Icon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='M17.278 21.325a1.232 1.232 0 1 0 2.45-.253l-.567-5.498a1.232 1.232 0 0 0-2.451.253l.568 5.498ZM24.98 22.424a1.23 1.23 0 0 0 1.353-1.099l.568-5.498a1.232 1.232 0 1 0-2.451-.253l-.568 5.498a1.232 1.232 0 0 0 1.099 1.352ZM15.446 27.781a3.818 3.818 0 0 0-3.813 3.814 3.818 3.818 0 0 0 3.813 3.814 3.818 3.818 0 0 0 3.814-3.814 3.818 3.818 0 0 0-3.814-3.814Zm0 5.164a1.351 1.351 0 0 1 0-2.7 1.351 1.351 0 0 1 0 2.7ZM28.173 27.781a3.818 3.818 0 0 0-3.814 3.814 3.818 3.818 0 0 0 3.814 3.814 3.819 3.819 0 0 0 3.814-3.814 3.818 3.818 0 0 0-3.814-3.814Zm0 5.164c-.744 0-1.35-.606-1.35-1.35 0-.744.606-1.35 1.35-1.35.744 0 1.35.606 1.35 1.35 0 .744-.606 1.35-1.35 1.35Z'
      />
      <path
        fill={color}
        d='M35.538 10.366a1.232 1.232 0 0 0-.975-.478H10.005l-1.03-3.966A1.232 1.232 0 0 0 7.781 5h-5.05a1.232 1.232 0 0 0 0 2.464h4.097l1.022 3.93c.006.024.012.049.02.073l3.802 14.624c.141.543.631.922 1.192.922H30.75c.561 0 1.051-.38 1.193-.922l3.813-14.661a1.233 1.233 0 0 0-.218-1.064Zm-5.74 14.183h-15.98l-3.172-12.197h22.323l-3.172 12.197Z'
      />
    </svg>
  )
}
