import React from 'react'

type TProps = {
  color?: string,
}

export const UploadIcon: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={color}
        strokeWidth='2.3'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M3 16.625v2.25a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25v-2.25M6.375 7.625 12 2l5.625 5.625M12 2v13.5'
      />
    </svg>
  )
}
