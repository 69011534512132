import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Checkbox, Dropdown } from 'semantic-ui-react'
import { GoogleMap, Marker } from '@react-google-maps/api'

import './index.scss'

import { ECourierRole, ICourier, ICouriersFilter, IMarket } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import * as Actions from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import { CouriersByRole } from '../../../../../components/CouriersByRole'
import { getCourierStatus, sortCouriersByRole } from '../../../../../utils/courierUtils'
import * as storage from '../../../../../utils/storageUtils'
import pinMarket from '../../../../../assets/images/pin-map.png'
import pinRed from '../../../../../assets/images/pin-red.png'
import pinGreen from '../../../../../assets/images/pin-green.png'

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  couriersList: ICourier[],
  couriersByRole: Map<ECourierRole, ICourier[]>,
  markets: IMarket[],
}

type TDispatchedProps = {
  apiCouriersList: (filter: ICouriersFilter) => Actions.Action,
  apiCouriersLocations: (ids: string[]) => Actions.Action,
  getMarkets: () => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  market: string,
  hidePicker: boolean,
  marketLocation?: [number, number],
  mapCenter?: {
    lat: number,
    lng: number,
  },
}

class CouriersCmp extends React.Component<TProps, TState> {
  timer: number | null = null
  mapRef: google.maps.Map | null = null

  constructor(props: TProps) {
    super(props)

    const { markets } = props

    const savedMarket = storage.getValue(storage.COURIERS_MARKET)
    const market =
      markets.length > 0
        ? savedMarket
          ? markets.find((item) => item.id === savedMarket) || markets[0]
          : markets[0]
        : null

    this.state = {
      market: market ? market.id : '',
      hidePicker: true,
      marketLocation: market ? [market.latitude, market.longitude] : undefined,
      mapCenter: market
        ? {
            lat: market.latitude,
            lng: market.longitude,
          }
        : undefined,
    }
  }

  componentDidMount(): void {
    const { markets } = this.props
    this.props.getMarkets()

    if (!this.state.market && markets[0]) {
      this.handleChangeMarket(null, { value: markets[0].id })
    } else if (this.state.market) {
      this.props.apiCouriersList({ market: this.state.market })
    }

    this.restartInterval()
  }

  componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>): void {
    const { markets } = this.props

    if (!this.state.market && markets.length > 0) {
      this.setState({
        market: markets[0].id,
      })
    }

    if (prevState.market !== this.state.market) {
      const market = markets.length > 0 ? markets.find((item) => item.id === this.state.market) : null

      this.props.apiCouriersList({ market: this.state.market })

      if (market) {
        this.setState({
          marketLocation: [market.latitude, market.longitude],
          mapCenter: market
            ? {
                lat: market.latitude,
                lng: market.longitude,
              }
            : undefined,
        })
      }
    }

    if (prevProps.markets.length === 0 && markets.length > 0 && !this.state.market) {
      const savedMarket = storage.getValue(storage.COURIERS_MARKET)
      const market =
        markets.length > 0
          ? savedMarket
            ? markets.find((item) => item.id === savedMarket) || markets[0]
            : markets[0]
          : null

      this.setState({
        market: market ? market.id : '',
      })

      if (!savedMarket && market) {
        this.props.apiCouriersList({ market: market.id })
        storage.setValue(storage.COURIERS_MARKET, market.id)
      }
    }
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    return (
      <div className='couriers-content'>
        {this.renderMarket()}
        {this.renderMarketMap()}
        <div className='couriers-list'>{this.renderCouriers()}</div>
      </div>
    )
  }

  renderMarket = () => {
    const { markets } = this.props
    const { formatMessage } = this.props.intl
    const { market, hidePicker } = this.state

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='couriers-filter'>
        <div className='couriers-filter__field'>
          <Dropdown value={market} search selection options={options} onChange={this.handleChangeMarket} />
        </div>
        <div className='couriers-filter__field'>
          <div className='couriers-filter__field-label' style={{ width: '160px' }}>
            {formatMessage(messages.HideCollectors)}:
          </div>
          <Checkbox toggle checked={hidePicker} onChange={() => this.setState({ hidePicker: !hidePicker })} />
        </div>
      </div>
    )
  }

  renderCouriers = () => {
    const { formatMessage } = this.props.intl

    return [
      this.renderCouriersByRole(ECourierRole.PICKER, formatMessage(messages.Collectors)),
      this.renderCouriersByRole(ECourierRole.COURIER, formatMessage(messages.Couriers)),
      this.renderCouriersByRole(ECourierRole.MANAGER, formatMessage(messages.Managers)),
    ]
  }

  renderCouriersByRole = (role: ECourierRole, title: string) => {
    const { couriersByRole } = this.props
    const couriers = couriersByRole.get(role) || []

    if (!couriers.length) {
      return null
    }

    return <CouriersByRole couriers={couriers} title={title} key={title} />
  }

  renderMarketMap = () => {
    const { markets, couriersList } = this.props
    const { mapCenter, hidePicker } = this.state
    const market = markets.find((item) => item.id === this.state.market)

    if (!market) {
      return
    }

    const location = [market.latitude, market.longitude]
    const courierListOnMap = couriersList.filter(
      (courier) =>
        courier.isOnShift &&
        (hidePicker ? courier.role !== ECourierRole.PICKER : true) &&
        courier.latitude &&
        courier.longitude,
    )
    let mapWidth =
      window.innerWidth > 840 ? window.innerWidth - 500 : window.innerWidth < 600 ? 0.9 * window.innerWidth : 480

    if (mapWidth > 960) {
      mapWidth = 960
    }

    const mapHeight = (mapWidth * 9) / 14

    return (
      <div className='couriers-map' style={{ width: '100%', height: `${mapHeight}px` }}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: `${mapHeight}px` }}
          center={mapCenter}
          zoom={15.4}
          options={{
            maxZoom: 19,
            minZoom: 11,
          }}
          onLoad={(map) => {
            this.mapRef = map
          }}
        >
          <Marker
            position={{
              lat: location[0],
              lng: location[1],
            }}
            icon={{
              url: pinMarket,
              scaledSize: new window.google.maps.Size(54, 74.3),
            }}
          />
          {courierListOnMap.map((courier) => {
            if (courier.latitude && courier.longitude) {
              const status = getCourierStatus(courier.role, courier.isOnShift, courier.isBusy)
              return (
                <Marker
                  key={courier.id}
                  position={{
                    lat: courier.latitude,
                    lng: courier.longitude,
                  }}
                  label={{
                    text: `№${courier.num.toString()}`,
                    fontSize: '14px',
                    fontWeight: '700',
                  }}
                  icon={{
                    url: status.available ? pinGreen : pinRed,
                    scaledSize: new window.google.maps.Size(48, 63),
                    origin: new window.google.maps.Point(0, -15),
                    size: new window.google.maps.Size(48, 77),
                  }}
                />
              )
            }

            return
          })}
        </GoogleMap>
      </div>
    )
  }

  restartInterval = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }

    this.timer = Number(
      setInterval(() => {
        const { market } = this.state
        const courierIds = this.props.couriersList.map((item) => item.id)
        const center = this.mapRef?.getCenter()

        if (center) {
          this.setState({
            mapCenter: {
              lat: center.lat(),
              lng: center.lng(),
            },
          })
        }

        if (market && courierIds.length > 0) {
          this.props.apiCouriersLocations(courierIds)
          // this.props.apiCouriersList({ market })
        }
      }, 30000),
    )
  }

  handleChangeMarket = (event: any, data: any) => {
    const { value } = data

    this.props.apiCouriersList({ market: value })

    this.setState({ market: value })
    storage.setValue(storage.COURIERS_MARKET, value)
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loaded, loading, couriersList } = s.couriers

  return {
    loaded,
    loading,
    couriersList,
    markets: s.markets.markets || [],
    couriersByRole: sortCouriersByRole(couriersList),
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiCouriersLocations: (ids: string[]) => dispatch(Actions.action(Actions.API_COURIERS_LOCATIONS, { ids })),
  getMarkets: () =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        include_all: 'True',
      }),
    ),
})

export const Couriers = connect<TConnectedProps, TDispatchedProps, any, State>(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CouriersCmp))
