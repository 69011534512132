import { EMPTY, EMPTY as observableEmpty, of as observableOf } from 'rxjs'
import { catchError, debounceTime, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'

import * as TApi from '../../types/TApi'
import { EModerateProductStatus, ICategory, ISubcategory } from '../../types/TClient'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import {
  URL_PRODUCT_CATEGORIES,
  URL_PRODUCT_COPY,
  URL_PRODUCT_COUNTRIES,
  URL_PRODUCT_CREATE,
  URL_PRODUCT_DELETE,
  URL_PRODUCT_MODERATION_UPDATE,
  URL_PRODUCT_STEPS,
  URL_PRODUCT_UNITS,
  URL_PRODUCT_UPDATE,
  URL_PRODUCTS_LIST,
  URL_PRODUCTS_MODERATION,
  URL_PRODUCTS_UPDATE_RATING,
  URL_SELLER_PRODUCTS,
  URL_TAGS_PRODUCTS,
} from '../../modules/network/urls'
import { EAlertType } from '../reducers/alerts'
import { intlMessage } from '../../App/LangContainer'
import messages from '../../localization/messages'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import {
  convertCategoriesFromApi,
  convertCountriesFromApi,
  convertModerationProductFromApi,
  convertProductFromApi,
  convertProductSellerFromApi,
  convertProductToProductSeller,
  convertStepsFromApi,
  convertUnitsFromApi,
} from '../../utils/productsUtils'

const productCategoriesEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiProductCategories>(a$, Actions.API_PRODUCT_CATEGORIES), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_PRODUCT_CATEGORIES, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductCategoriesResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.categories)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.PRODUCT_CATEGORIES, {
                    categories: resp.data.categories.map(convertCategoriesFromApi),
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const productCountriesEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiProductCountries>(a$, Actions.API_PRODUCT_COUNTRIES), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_PRODUCT_COUNTRIES, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductCountriesResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.countries)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.PRODUCT_COUNTRIES, {
                    countries: resp.data.countries.map(convertCountriesFromApi),
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const productStepsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiProductSteps>(a$, Actions.API_PRODUCT_STEPS), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_PRODUCT_STEPS, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductStepsResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.steps)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.PRODUCT_STEPS, {
                    steps: resp.data.steps.map(convertStepsFromApi),
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const productUnitsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiProductUnits>(a$, Actions.API_PRODUCT_UNITS), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_PRODUCT_UNITS, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductUnitsResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.units)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.PRODUCT_UNITS, {
                    units: resp.data.units.map(convertUnitsFromApi),
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const sellerProductsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiSellerProducts>(a$, Actions.API_SELLER_PRODUCTS), (b) =>
    b.pipe(
      debounceTime(500),
      mergeMap((c) =>
        Axios.get(URL_SELLER_PRODUCTS, {
          ...authRequestConfig(),
          params: { ...c.data },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiSellerProductsResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.products)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.SELLER_PRODUCTS, {
                    products: resp.data.products.map(convertProductSellerFromApi),
                    force: !c.data.offset,
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const marketProductsEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiMarketProducts>(a$, Actions.API_MARKET_PRODUCTS), (b) =>
    b.pipe(
      debounceTime(200),
      mergeMap((c) =>
        Axios.get(URL_PRODUCTS_LIST, {
          ...authRequestConfig(),
          params: { ...c.data },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiMarketProductsResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.products)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.MARKET_PRODUCTS, {
                    products: resp.data.products.map(convertProductFromApi),
                    force: !c.data.offset,
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const createProductEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiProductCreate>(a$, Actions.API_PRODUCT_CREATE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCT_CREATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductCreateResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data) {
              const seller = store.value.sellers.sellersList.find((s) => s.userId === c.data.userId)

              if (seller) {
                actions.push(
                  Actions.action(Actions.PRODUCT_CREATE, {
                    id: resp.data.id,
                    seller: seller.id,
                    market: seller.marketId,
                    imageUrl: c.data.imageUrl,
                    name: c.data.name,
                    pcsWeightGr: c.data.weightPcsGr,
                    price: c.data.price,
                    priceWithoutDiscount: +c.data.price_without_discount,
                    step: c.data.step,
                    subcategory: c.data.subcategory,
                    unit: c.data.unit,
                    country: c.data.country,
                    hide: c.data.hide,
                    description: c.data.description,
                    aboutSeller: c.data.aboutSeller,
                    stockCategory: c.data.stockCategory,
                    stockSubcategory: c.data.stockSubcategory,
                    vendorCode: c.data.vendorCode,
                    rating: c.data.rating,
                    tags: c.data.tags,
                    price_weight_gr: c.data.price_weight_gr,
                  }),
                )
              }
            } else {
              actions.push(
                Actions.action(Actions.API_PRODUCT_ERROR, {
                  error: intlMessage(messages.ProductCreationError),
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_PRODUCT_ERROR, {
                error: intlMessage(messages.ProductCreationError),
              }),
            )
          }),
        ),
      ),
    ),
  )

const updateProductEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiProductUpdate>(a$, Actions.API_PRODUCT_UPDATE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCT_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductUpdateResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data) {
              // const seller = store.value.sellers.sellersList.find((s) => s.userId === c.data.userId)
              const {
                name,
                hide,
                imageUrl,
                weightPcsGr,
                step,
                subcategory,
                unit,
                country,
                price,
                description,
                aboutSeller,
                tags,
                stockCategory,
                stockSubcategory,
                vendorCode,
                rating,
                userId,
                price_without_discount,
                price_weight_gr,
              } = c.data

              const marketProduct = store.value.products.marketProducts.find((item) => item.id === resp.data.id)
              const product = store.value.products.sellersProducts.find((item) => item.id === resp.data.id)
              const market = store.value.markets.markets.find(
                (item) => item.id === marketProduct?.marketId || product?.market,
              )
              const marketCategories = market?.categories || []
              const categories = store.value.products.categories || []

              const newProduct = {
                id: resp.data.id,
                ...(tags && { tags }),
                ...(imageUrl && { imageUrl }),
                ...(name && { name }),
                ...(weightPcsGr !== undefined && { pcsWeightGr: weightPcsGr }),
                ...(step && { step }),
                ...(unit && { unit }),
                ...(country && { country }),
                ...(description !== undefined && { description }),
                ...(aboutSeller && { aboutSeller }),
                ...(vendorCode !== undefined && { vendorCode }),
                ...(rating !== undefined && { rating }),
                ...(price_without_discount !== undefined && { priceWithoutDiscount: +price_without_discount }),
                ...(price_weight_gr !== undefined && { priceWeightGr: price_weight_gr }),
              }

              if (userId) {
                actions.push(
                  Actions.action(Actions.PRODUCT_UPDATE, {
                    ...newProduct,
                    ...(price && { price }),
                    ...(subcategory && { subcategory }),
                    ...(hide !== undefined && { hide }),
                    ...(stockCategory !== undefined && { stockCategory }),
                    ...(stockSubcategory !== undefined && { stockSubcategory }),
                    ...(price_without_discount !== undefined && { priceWithoutDiscount: +price_without_discount }),
                    ...(price_weight_gr !== undefined && { priceWeightGr: price_weight_gr }),
                  }),
                )
              } else {
                let categoryInfo: ICategory | undefined = undefined
                let subcategoryInfo: ISubcategory | undefined = undefined

                for (const category of categories) {
                  for (const item of category.subcategories) {
                    if (item.id === subcategory) {
                      subcategoryInfo = item
                      categoryInfo = category
                    }
                  }
                }

                const marketCategory = marketCategories.find((item) => item.id === stockCategory)
                const marketSubcategory = marketCategory?.subcategories.find((item) => item.id === stockSubcategory)

                if (marketProduct) {
                  actions.push(
                    Actions.action(Actions.MARKET_PRODUCT, {
                      product: {
                        ...newProduct,
                        ...(hide !== undefined && { isHide: hide }),
                        ...(price && { price: +price }),
                        ...(subcategory &&
                          subcategory !== marketProduct.subcategoryId && {
                            categoryId: categoryInfo?.id || undefined,
                            category: categoryInfo?.name || undefined,
                            categoryPriority: categoryInfo?.priority || undefined,
                          }),
                        ...(subcategory &&
                          subcategory !== marketProduct.subcategoryId && {
                            subcategoryId: subcategory || undefined,
                            subcategory: subcategoryInfo?.name || undefined,
                            subcategoryPriority: subcategoryInfo?.priority || undefined,
                          }),
                        ...(stockCategory !== undefined &&
                          stockCategory !== marketProduct.marketCategoryId && {
                            marketCategoryId: stockCategory || undefined,
                            marketCategory: marketCategory?.name || undefined,
                            marketCategoryPriority: marketCategory?.index || undefined,
                          }),
                        ...(stockSubcategory !== undefined &&
                          stockSubcategory !== marketProduct.marketSubcategoryId && {
                            marketSubcategoryId: stockSubcategory || undefined,
                            marketSubcategory: marketSubcategory?.name || undefined,
                            marketSubcategoryPriority: marketSubcategory?.index || undefined,
                          }),
                      },
                    }),
                  )
                }
              }
            } else {
              actions.push(
                Actions.action(Actions.API_PRODUCT_ERROR, {
                  error: intlMessage(messages.ProductUpdateError),
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_PRODUCT_ERROR, {
                error: intlMessage(messages.ProductUpdateError),
              }),
            )
          }),
        ),
      ),
    ),
  )

const deleteProductEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiProductDelete>(a$, Actions.API_PRODUCT_DELETE), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCT_DELETE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductDeleteReq }) => {
            const actions: Actions.Action[] = []

            if (resp.data) {
              const seller = store.value.sellers.sellersList.find((s) => s.userId === c.data.userId)

              if (seller) {
                actions.push(
                  Actions.action(Actions.PRODUCT_DELETE, {
                    id: c.data.productId,
                  }),
                )
              }
            } else {
              actions.push(
                Actions.action(Actions.API_PRODUCT_ERROR, {
                  error: intlMessage(messages.ProductDeletionError),
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_PRODUCT_ERROR, {
                error: intlMessage(messages.ProductDeletionError),
              }),
            )
          }),
        ),
      ),
    ),
  )

const copyProductEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiProductCopy>(a$, Actions.API_PRODUCT_COPY), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCT_COPY, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductCopyResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data) {
              actions.push(
                Actions.action(Actions.PRODUCT_CREATE, convertProductToProductSeller(convertProductFromApi(resp.data))),
              )
            } else {
              actions.push(
                Actions.action(Actions.API_PRODUCT_ERROR, {
                  error: intlMessage(messages.ProductCopyError),
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_PRODUCT_ERROR, {
                error: intlMessage(messages.ProductCopyError),
              }),
            )
          }),
        ),
      ),
    ),
  )

const updateProductsRatingEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiProductsUpdateRating>(a$, Actions.API_PRODUCTS_UPDATE_RATING), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCTS_UPDATE_RATING, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.API_MARKET_PRODUCTS, {
                offset: 0,
                market: c.data.market,
                marketCategory: c.data.marketCategory,
                byCategories: true,
              }),
            )
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableEmpty
          }),
        ),
      ),
    ),
  )

const sellerProductsByTagEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiTagProducts>(a$, Actions.API_TAG_PRODUCTS), (b) =>
    b.pipe(
      debounceTime(500),
      mergeMap((c) =>
        Axios.get(URL_TAGS_PRODUCTS, {
          ...authRequestConfig(),
          params: { ...c.data },
        }).pipe(
          mergeMap((resp: { data: TApi.TagProductsResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.records)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.SELLER_PRODUCTS, {
                    products: resp.data.records.map((p) =>
                      convertProductSellerFromApi({
                        id: p.id,
                        image_url: p.image,
                        market: p.market_id,
                        name: p.name,
                        pcs_weight_gr: p.pcs_weight_gr,
                        price: p.price + '',
                        seller: p.seller_id,
                        step: p.step,
                        subcategory: p.subcategory_id,
                        unit: p.unit,
                        country: p.country,
                        hide: p.hidden === 'True',
                        tags: p.tags,
                        description: p.description,
                        price_without_discount: p.price_without_discount,
                        currency_code: p.market_currency_code,
                      }),
                    ),
                    force: true,
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const productsModerationEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiProductsModerationList>(a$, Actions.API_PRODUCTS_MODERATION_LIST), (b) =>
    b.pipe(
      debounceTime(200),
      mergeMap((c) =>
        Axios.get(URL_PRODUCTS_MODERATION, {
          ...authRequestConfig(),
          params: {
            ...c.data,
            ...(c.data.statuses && c.data.statuses.length > 0 && { statuses: c.data.statuses.join(',') }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductsModerationListResp }) => {
            if (resp.data) {
              if (Array.isArray(resp.data.products)) {
                return observableOf<Actions.Action>(
                  Actions.action(Actions.PRODUCTS_MODERATION_LIST, {
                    products: resp.data.products.map(convertModerationProductFromApi),
                    total: resp.data.total,
                    totalOnModeration: resp.data.totalOnModeration,
                    force: !c.data.offset,
                  }),
                )
              }
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateModerationProductEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateModerationProduct>(a$, Actions.API_UPDATE_MODERATION_PRODUCT), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCT_MODERATION_UPDATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiUpdateModerationProductResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data) {
              let text = intlMessage(messages.ProductApproved)

              if (c.data.status == EModerateProductStatus.REJECTED) {
                text = intlMessage(messages.ProductRejected)
              }

              actions.push(
                Actions.action(Actions.ALERT_PUSH, {
                  id: Date.now().toString(),
                  type: EAlertType.SUCCESS,
                  title: intlMessage(messages.Successfully),
                  text,
                }),
              )

              const product = store.value.products.moderationProducts.list.find((item) => item.id === c.data.id)

              if (product) {
                actions.push(
                  Actions.action(Actions.UPDATE_MODERATION_PRODUCT, {
                    ...product,
                    status: c.data.status,
                    text: c.data.text,
                  }),
                )
              }
            } else {
              actions.push(
                Actions.action(Actions.ALERT_PUSH, {
                  id: Date.now().toString(),
                  type: EAlertType.ERROR,
                  title: intlMessage(messages.Error),
                  text: intlMessage(messages.ModerationUpdateError),
                }),
              )
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(
              Actions.action(Actions.ALERT_PUSH, {
                id: Date.now().toString(),
                type: EAlertType.ERROR,
                title: intlMessage(messages.Error),
                text: intlMessage(messages.ModerationUpdateError),
              }),
            )
          }),
        ),
      ),
    ),
  )

export const productsEpics: EpicFunc[] = [
  productCategoriesEpic,
  productCountriesEpic,
  productStepsEpic,
  productUnitsEpic,
  marketProductsEpic,
  sellerProductsEpic,
  createProductEpic,
  updateProductEpic,
  deleteProductEpic,
  copyProductEpic,
  sellerProductsByTagEpic,
  updateProductsRatingEpic,
  productsModerationEpic,
  updateModerationProductEpic,
]
