import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Dispatch } from 'redux'

import './index.scss'

import { EOrderStatus, IMarket, IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'
import { State } from '../../../store/reducer'
import * as Actions from '../../../store/actions'
import {
  OrderClient,
  OrderCollector,
  OrderCourier,
  OrderDelivery,
  OrderDivider,
  OrderHeader,
  OrderHistory,
  OrderMarket,
  OrderPayment,
  OrderPaymentType,
  OrderProducts,
  OrderReview,
  OrderSlot,
  OrderTotal,
} from '../../OrderInfo'
import { checkMobile } from '../../../utils/deviceUtils'
import { OrderPicker } from '../../OrderInfo/OrderPicker'

type TOwnProps = {
  marketId: string,
  order: IOrder,
  onClose: () => void,
}

type TConnectedProps = {
  market?: IMarket,
  errorApi?: string,
}

type DispatchedProps = {
  apiOrderHistory(orderId: string): Actions.Action,
}

type Props = TOwnProps & TConnectedProps & WrappedComponentProps & DispatchedProps

class OrderCmp extends React.Component<Props> {
  componentDidMount() {
    const { apiOrderHistory, order } = this.props

    apiOrderHistory(order.id)
  }

  render() {
    const { order } = this.props
    const { formatMessage } = this.props.intl
    const isMobile = checkMobile()

    return (
      <div className='modal-order'>
        <div className='modal-order__header'>
          <div className='modal-order__title'>{formatMessage(messages.OrderCard)}</div>
        </div>
        <div className='modal-order__order'>
          <div className='modal-order__column'>
            {this.renderOrderHeader()}
            {this.renderPayment()}
            {!order.selfPickup && this.renderSlot()}
            {!order.selfPickup && this.renderHistory()}
            {!order.selfPickup && this.renderTotal()}
            {order.review && this.renderReview()}
          </div>
          {!isMobile && <div className='modal-order__column-divider' />}
          <div className='modal-order__column'>
            {this.renderDelivery()}
            {this.renderPaymentType()}
            {this.renderMarket()}
            {this.renderClient()}
            {this.renderCollector()}
            {this.renderPicker()}
            {(order.isCollected || order.statusId >= EOrderStatus.COURIER) && this.renderCourier()}
          </div>
        </div>
        <OrderDivider />
        {this.renderProducts()}
      </div>
    )
  }

  renderOrderHeader = () => {
    return (
      <>
        <OrderHeader order={this.props.order} withNum />
        <OrderDivider />
      </>
    )
  }

  renderPayment = () => {
    return (
      <>
        <OrderPayment order={this.props.order} byCustomer />
        <OrderDivider />
      </>
    )
  }

  renderSlot = () => {
    const { market, order } = this.props

    return (
      <>
        <OrderSlot order={order} market={market} withDate withStatus />
        <OrderDivider />
      </>
    )
  }

  renderHistory = () => {
    return (
      <>
        <OrderHistory order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderTotal = () => {
    return (
      <>
        <OrderTotal order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderReview = () => {
    return <OrderReview order={this.props.order} />
  }

  renderDelivery = () => {
    return (
      <>
        <OrderDelivery order={this.props.order} disabled />
        <OrderDivider />
      </>
    )
  }

  renderPaymentType = () => {
    return (
      <>
        <OrderPaymentType order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderMarket = () => {
    return (
      <>
        <OrderMarket order={this.props.order} />
        <OrderDivider />
      </>
    )
  }

  renderClient = () => {
    return (
      <>
        <OrderClient order={this.props.order} disabled />
        <OrderDivider />
      </>
    )
  }

  renderCollector = () => {
    const { order } = this.props

    return (
      <>
        <OrderCollector order={order} disabled />
        <OrderDivider />
      </>
    )
  }

  renderPicker = () => {
    const { order } = this.props

    return (
      <>
        <OrderPicker order={order} />
        {(order.isCollected || order.statusId >= EOrderStatus.COURIER) && <OrderDivider />}
      </>
    )
  }

  renderCourier = () => {
    return <OrderCourier order={this.props.order} disabled />
  }

  renderProducts = () => {
    return <OrderProducts order={this.props.order} byCustomer disabled />
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { markets } = s.markets

  const market = markets.find((market) => market.id === own.marketId)

  if (!market && markets && markets.length > 0) {
    own.onClose()
  }

  return {
    market,
  }
}

const dispatchToProps = (dispatch: Dispatch): DispatchedProps => ({
  apiOrderHistory: (orderId: string) => dispatch(Actions.action(Actions.API_ORDER_HISTORY, { orderId })),
})

export const OrderModal = connect(mapStateToProps, dispatchToProps)(injectIntl(OrderCmp))
