import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import { HistoryPickProductAction } from '../../../../types/TClient'

import { Action } from '../Action'
import { convertCurrency, formatPrice, unitIntl } from '../../../../utils/productsUtils'
import messages from '../../../../localization/messages'

type IProps = HistoryPickProductAction & WrappedComponentProps

const _PickProductAction: React.FC<IProps> = ({ data: { name, quantity, unit, price, currencyCode }, date }) => {
  return (
    <Action date={date}>
      <FormattedMessage {...messages.ProductAssembled} />
      <div className='pick-product-action__product-name'>
        {name} {quantity} {unitIntl(unit)} / {formatPrice(price, convertCurrency(currencyCode))}
      </div>
    </Action>
  )
}

export const PickProductAction = injectIntl(_PickProductAction)
