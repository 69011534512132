import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { DirectionsRenderer, DirectionsService, GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'
import moment from 'moment'
import { differenceWith, uniqBy } from 'lodash'
import classnames from 'classnames'

import './index.scss'

import {
  ECourierRole,
  EOrderStatus,
  EVehicleType,
  ICourier,
  ICouriersFilter,
  IMarket,
  IOrder,
  IOrdersFilter,
  IRoute,
  IRouteStep,
} from '../../../../../types/TClient'
import { ApiCreateMarketRouteReq, ApiRemoveMarketRouteReq, ApiUpdateMarketRouteReq } from '../../../../../types/TApi'

import messages from '../../../../../localization/messages'
import * as Actions from '../../../../../store/actions'
import { State } from '../../../../../store/reducer'
import { EModalType, IModalAssignOrder, IModalCustomer, IModalOrder } from '../../../../../store/reducers/modals'
import {
  ArrowUpIcon,
  BackIcon,
  CarIcon,
  CartIcon,
  MapRouteIcon,
  MotoIcon,
  TrashIcon,
} from '../../../../../components/Icons'
import { Button } from '../../../../../components/Button'
import { ModalConfirm } from '../../../../../components/Modal/ModalConfirm'
import { formatPhone } from '../../../../../utils/customersUtils'
import { formatPrice } from '../../../../../utils/productsUtils'
import { convertStatus, formatTime, getColor } from '../../../../../utils/ordersUtils'
import * as storage from '../../../../../utils/storageUtils'
import pinMarket from '../../../../../assets/images/pin-map.png'
import pinRed from '../../../../../assets/images/pin-red.png'
import pinGreen from '../../../../../assets/images/pin-green.png'
import pinMapBlue from '../../../../../assets/images/pin-map-dark-blue.png'
import pinMapGreen from '../../../../../assets/images/pin-map-green.png'
import pinMapGray from '../../../../../assets/images/pin-map-gray.png'
import pinMapRed from '../../../../../assets/images/pin-map-red.png'

type TConnectedProps = {
  loading: boolean,
  loaded: boolean,
  orders: IOrder[],
  ordersRoutes: IOrder[],
  couriersList: ICourier[],
  routes: IRoute[],
  markets: IMarket[],
}

type TDispatchedProps = {
  apiCouriersList: (filter: ICouriersFilter) => Actions.Action,
  apiCouriersLocations: (ids: string[]) => Actions.Action,
  apiOrdersMapList: (filter: IOrdersFilter) => Actions.Action,
  orderModal: (data: IModalOrder) => Actions.Action,
  customerModal: (data: IModalCustomer) => Actions.Action,
  assignOrderModal: (data: IModalAssignOrder) => Actions.Action,
  createRoute: (data: ApiCreateMarketRouteReq) => Actions.Action,
  updateRoute: (data: ApiUpdateMarketRouteReq) => Actions.Action,
  removeRoute: (data: ApiRemoveMarketRouteReq) => Actions.Action,
  getRoutes: (marketId: string) => Actions.Action,
  getMarkets: () => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & WrappedComponentProps

type TState = {
  response: any,
  market: string,
  setRoute?: boolean,
  setCourier?: boolean,
  statuses?: EOrderStatus[],
  hidePicker: boolean,
  marketLocation?: [number, number],
  mapCenter?: {
    lat: number,
    lng: number,
  },
  orderId: string,
  courierId: string,
  route: IRouteStep[],
  removeRouteModal: boolean,
}

const STATUS_DEFAULT = [EOrderStatus.NEW, EOrderStatus.IN_ASSEMBLY, EOrderStatus.COURIER, EOrderStatus.DELIVERED]

class CouriersRoutesCmp extends React.Component<TProps, TState> {
  timer: number | null = null
  mapRef: google.maps.Map | null = null

  constructor(props: TProps) {
    super(props)

    const { markets } = props
    const savedMarket = storage.getValue(storage.COURIERS_MARKET)
    const market =
      markets.length > 0
        ? savedMarket
          ? markets.find((item) => item.id === savedMarket) || markets[0]
          : markets[0]
        : null

    this.state = {
      response: null,
      market: market ? market.id : '',
      setRoute: false,
      setCourier: false,
      hidePicker: true,
      marketLocation: market ? [market.latitude, market.longitude] : undefined,
      mapCenter: market
        ? {
            lat: market.latitude,
            lng: market.longitude,
          }
        : undefined,
      orderId: '',
      courierId: '',
      route: [],
      removeRouteModal: false,
    }
  }

  componentDidMount(): void {
    const { markets } = this.props
    this.props.getMarkets()

    if (!this.state.market && markets[0]) {
      this.handleChangeMarket(null, { value: markets[0].id })
    } else if (this.state.market) {
      this.refreshData()
    }

    this.restartInterval()
  }

  componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>): void {
    const { markets } = this.props

    if (!this.state.market && markets.length > 0) {
      this.setState({
        market: markets[0].id,
        orderId: '',
        courierId: '',
      })
    }

    if (prevState.market !== this.state.market) {
      const market = markets.length > 0 ? markets.find((item) => item.id === this.state.market) : null

      this.refreshData()

      if (market) {
        this.setState({
          marketLocation: [market.latitude, market.longitude],
          mapCenter: market
            ? {
                lat: market.latitude,
                lng: market.longitude,
              }
            : undefined,
        })
      }
    }

    if (prevProps.markets.length === 0 && markets.length > 0 && !this.state.market) {
      const savedMarket = storage.getValue(storage.COURIERS_MARKET)
      const market =
        markets.length > 0
          ? savedMarket
            ? markets.find((item) => item.id === savedMarket) || markets[0]
            : markets[0]
          : null

      this.setState({ market: market ? market.id : '' }, this.refreshData)

      if (!savedMarket && market) {
        storage.setValue(storage.COURIERS_MARKET, market.id)
      }
    }
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    return (
      <div className='couriers-routes-content'>
        {this.renderRemoveModal()}
        {this.renderMarket()}
        {this.renderFilters()}
        {this.renderMarketMap()}
      </div>
    )
  }

  renderMarket = () => {
    const { markets } = this.props
    const { market } = this.state

    const options = markets.map((c) => ({ key: c.id, value: c.id, text: c.name }))

    return (
      <div className='couriers-routes-filter'>
        <div className='couriers-routes-filter__field'>
          <Dropdown value={market} search selection options={options} onChange={this.handleChangeMarket} />
        </div>
      </div>
    )
  }

  renderFilters = () => {
    return (
      <div className='couriers-routes__filters'>
        {this.renderStatuses()}
        {this.renderSetCourier()}
        {this.renderSetRoute()}
      </div>
    )
  }

  renderStatuses = () => {
    const { formatMessage } = this.props.intl
    const { statuses } = this.state

    const options = [
      { key: '1', text: formatMessage(messages.New), value: EOrderStatus.NEW },
      { key: '2', text: formatMessage(messages.InAssembly), value: EOrderStatus.IN_ASSEMBLY },
      { key: '3', text: formatMessage(messages.AtCourier), value: EOrderStatus.COURIER },
      { key: '4', text: formatMessage(messages.Delivered), value: EOrderStatus.DELIVERED },
      { key: '5', text: formatMessage(messages.Canceled), value: EOrderStatus.CANCELED },
    ]

    return (
      <div>
        <Dropdown
          className='couriers-routes-filter__field-value'
          multiple
          selection
          clearable
          value={statuses || []}
          options={options}
          placeholder={formatMessage(messages.OrderStatus)}
          onChange={this.handleChangeStatuses}
        />
      </div>
    )
  }

  renderSetCourier = () => {
    const { formatMessage } = this.props.intl
    const { setCourier } = this.state

    const options = [
      { key: '1', text: `${formatMessage(messages.Picked)}: ${formatMessage(messages.Yes)}`, value: true },
      { key: '2', text: `${formatMessage(messages.Picked)}: ${formatMessage(messages.No)}`, value: false },
    ]

    return (
      <div>
        <Dropdown
          className='couriers-routes-filter__field-value'
          selection
          clearable
          value={setCourier}
          options={options}
          placeholder={formatMessage(messages.Courier)}
          onChange={this.handleChangeCourier}
        />
      </div>
    )
  }

  renderSetRoute = () => {
    const { formatMessage } = this.props.intl
    const { setRoute } = this.state

    const options = [
      { key: '1', text: `${formatMessage(messages.Route)}: ${formatMessage(messages.Set)}`, value: true },
      { key: '2', text: `${formatMessage(messages.Route)}: ${formatMessage(messages.NotSet)}`, value: false },
    ]

    return (
      <div>
        <Dropdown
          className='couriers-routes-filter__field-value'
          selection
          clearable
          value={setRoute}
          options={options}
          placeholder={formatMessage(messages.Route)}
          onChange={this.handleChangeRoute}
        />
      </div>
    )
  }

  renderMarketMap = () => {
    const { markets, couriersList, orders, ordersRoutes } = this.props
    const { formatMessage } = this.props.intl
    const { mapCenter, orderId, courierId, route, setRoute, setCourier, response } = this.state
    const market = markets.find((item) => item.id === this.state.market)
    const courier = couriersList.find((item) => item.id === courierId)
    let ordersMap = orders

    if (setRoute !== undefined) {
      ordersMap = setRoute ? ordersRoutes : differenceWith(orders, ordersRoutes, (a, b) => a.id === b.id)
    }

    if (setCourier !== undefined) {
      ordersMap = ordersMap.filter((item) => (setCourier ? item.isPicked && item.courierId : !item.isPicked))
    }

    if (!market) {
      return
    }

    const location = [market.latitude, market.longitude]

    let mapWidth =
      window.innerWidth > 840 ? window.innerWidth - 500 : window.innerWidth < 600 ? 0.9 * window.innerWidth : 480

    if (mapWidth > 960) {
      mapWidth = 960
    }

    const mapHeight = (mapWidth * 9) / 14

    return (
      <div className='couriers-routes-map' style={{ width: '100%', height: `${mapHeight}px` }}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: `${mapHeight}px` }}
          center={mapCenter}
          zoom={15.4}
          options={{ maxZoom: 19, minZoom: 10 }}
          onLoad={(map) => {
            this.mapRef = map
          }}
        >
          {route && route.length > 0 && !response && (
            <DirectionsService
              options={{
                destination: new google.maps.LatLng(market.latitude, market.longitude),
                origin: new google.maps.LatLng(market.latitude, market.longitude),
                travelMode:
                  courier?.vehicleType === EVehicleType.MOTO
                    ? google.maps.TravelMode.BICYCLING
                    : google.maps.TravelMode.DRIVING,
                waypoints: route.slice(1, route.length - 1).map((item) => {
                  if (item.order) {
                    const { deliveryLat, deliveryLon } = item.order

                    if (deliveryLat && deliveryLon) {
                      return {
                        location: new google.maps.LatLng(deliveryLat, deliveryLon),
                        stopover: true,
                      }
                    }
                  }

                  return {
                    location: item.address,
                    stopover: true,
                  }
                }),
              }}
              callback={this.directionsCallback}
            />
          )}

          <Marker
            position={{
              lat: location[0],
              lng: location[1],
            }}
            icon={{
              url: pinMarket,
              scaledSize: new window.google.maps.Size(54, 74.3),
            }}
          />
          {couriersList.map((courier) => {
            if (courier.latitude && courier.longitude) {
              // const status = getCourierStatus(courier.role, courier.isOnShift, courier.isBusy)
              return (
                <Marker
                  key={courier.id}
                  position={{
                    lat: courier.latitude,
                    lng: courier.longitude,
                  }}
                  label={{
                    text: `№${courier.num.toString()}`,
                    fontSize: '14px',
                    fontWeight: '700',
                  }}
                  icon={{
                    url: courier.isBusy ? pinRed : pinGreen,
                    scaledSize: new window.google.maps.Size(48, 63),
                    origin: new window.google.maps.Point(0, -15),
                    size: new window.google.maps.Size(48, 77),
                  }}
                />
              )
            }

            return
          })}
          {ordersMap.map((order) => {
            if (order.deliveryLat && order.deliveryLon) {
              return (
                <Marker
                  key={order.id}
                  position={{
                    lat: order.deliveryLat,
                    lng: order.deliveryLon,
                  }}
                  onClick={() => this.selectOrder(order.id)}
                  icon={{
                    url:
                      orderId === order.id
                        ? pinMapGreen
                        : order.statusId === EOrderStatus.DELIVERED
                        ? pinMapGray
                        : order.statusId === EOrderStatus.CANCELED
                        ? pinMapRed
                        : pinMapBlue,
                    scaledSize: new window.google.maps.Size(30.5, 40.25),
                  }}
                >
                  {orderId === order.id ? (
                    <InfoWindow onCloseClick={() => this.selectOrder('')}>{this.renderOrderInfo(order)}</InfoWindow>
                  ) : null}
                </Marker>
              )
            }

            return
          })}

          {courierId && response && <DirectionsRenderer options={{ directions: response }} />}
        </GoogleMap>
        <div className='couriers-routes-map__legend'>
          {courierId ? (
            <div className='couriers-routes-map__legend-back' onClick={this.dropRoute}>
              <BackIcon width={16} height={16} color={'#858897'} />
              {formatMessage(messages.Back)}
            </div>
          ) : (
            <div className='couriers-routes-map__legend-title'>{formatMessage(messages.Couriers).toUpperCase()}</div>
          )}
          {courierId ? this.renderCourierRoute() : couriersList.map(this.renderCourier)}
        </div>
      </div>
    )
  }

  renderOrderInfo = (order: IOrder) => {
    const { formatMessage } = this.props.intl
    const currency = order.items.length ? order.items[0].currency : undefined
    const statusColor = getColor(order.statusId)
    const statusText =
      order.isCollected && order.statusId < EOrderStatus.COURIER
        ? order.isPicked
          ? formatMessage(messages.Picked)
          : formatMessage(messages.Collected)
        : convertStatus(order.statusId) || '–'

    return (
      <div className='couriers-routes-map__order-info'>
        <div className='couriers-routes-map__order-info__header' onClick={() => this.orderInfo(order)}>
          <div className='couriers-routes-map__order-info__header-id'>
            {formatMessage(messages.Order)} №{order.num}
          </div>
          <div className='couriers-routes-map__order-info__header-dot' />
          <div className='couriers-routes-map__order-info__header-status' style={{ color: statusColor }}>
            {statusText}
          </div>
        </div>
        <div className='couriers-routes-map__order-info__row'>
          <span className='couriers-routes-map__order-info__label'>{formatMessage(messages.Address)}:</span>
          {order.deliveryAddress}
        </div>
        {order.deliveryEndTime && (
          <div className='couriers-routes-map__order-info__row'>
            <span className='couriers-routes-map__order-info__label'>{formatMessage(messages.DeliveryTo)}:</span>
            {formatTime(order.deliveryEndTime)}
          </div>
        )}
        <div className='couriers-routes-map__order-info__row'>
          <span className='couriers-routes-map__order-info__label'>{formatMessage(messages.SettlementAmount)}:</span>
          {formatPrice(order.price + (order.deliveryPrice || 0), currency)}
        </div>
        <div className='couriers-routes-map__order-info__divider' />
        <div>
          <span className='couriers-routes-map__order-info__label'>{formatMessage(messages.Name)}:</span>
          {order.userFirstName} {order.userLastName}
        </div>
        <div className='couriers-routes-map__order-info__row'>
          <span className='couriers-routes-map__order-info__label'>{formatMessage(messages.Phone)}:</span>
          <span className='couriers-routes-map__order-info__phone' onClick={() => this.customerInfo(order.userId)}>
            {formatPhone(order.userPhone)}
          </span>
        </div>
        {order.statusId < EOrderStatus.COURIER && (
          <div className='couriers-routes-map__order-info__btn' onClick={() => this.assignOrder(order)}>
            {formatMessage(messages.AssignCourier)}
          </div>
        )}
      </div>
    )
  }

  renderCourier = (item: ICourier) => {
    const { routes } = this.props
    const { formatMessage } = this.props.intl
    const { courierId, route } = this.state
    const planRoute = routes.find((route) => route.courierId === item.id)
    const onRoute = (planRoute?.points || route).filter((item) => item.orderId).length
    const isMoto = item.vehicleType === EVehicleType.MOTO
    const exist = planRoute
    let lastTime = ''

    if (planRoute) {
      for (const point of planRoute.points) {
        if (point.order && point.order.deliveryEndTime) {
          lastTime = formatTime(point.order.deliveryEndTime)
        }
      }
    }

    return (
      <div
        key={item.id}
        className={classnames(
          'couriers-routes-map__legend-courier',
          courierId && planRoute?.startAt && 'couriers-routes-map__legend-courier-header',
        )}
      >
        <div className='couriers-routes-map__legend-courier__icon'>
          {isMoto ? <MotoIcon color='#858897' /> : <CarIcon color='#858897' />}
          <div
            className={classnames(
              'couriers-routes-map__legend-courier__status',
              !item.isBusy && 'couriers-routes-map__legend-courier__status-shift',
            )}
          />
        </div>
        <div className='couriers-routes-map__legend-courier__info'>
          <div className='couriers-routes-map__legend-courier__name'>
            №{item.num}. {item.firstName} {item.lastName}
          </div>
          <div className='couriers-routes-map__legend-courier__phone'>{formatPhone(item.phone)}</div>
          {item.isBusy ? (
            <>
              <div className='couriers-routes-map__legend-courier__busy'>
                {planRoute?.startAt
                  ? `${formatMessage(messages.OnRoute)}: ${onRoute}`
                  : `${formatMessage(messages.Assigned)}: ${item.numActiveOrders}`}
                {lastTime && planRoute?.startAt ? (
                  <div className='couriers-routes-map__legend-courier__busy-route'>
                    {formatMessage(messages.BreakFreeIn)}: {lastTime}
                  </div>
                ) : (
                  <div className='couriers-routes-map__legend-courier__busy-route'>
                    {formatMessage(messages.OnRoute)}: {onRoute}
                  </div>
                )}
              </div>
              {!courierId && (
                <div
                  className={classnames(
                    'couriers-routes-map__legend-courier__route',
                    !exist && 'couriers-routes-map__legend-courier__route-edit',
                  )}
                  onClick={!planRoute ? () => this.buildRoute(item.id) : () => this.activeRoute(planRoute)}
                >
                  {planRoute
                    ? formatMessage(planRoute.startAt ? messages.ViewRoute : messages.ChangeRoute)
                    : formatMessage(messages.BuildRoute)}
                  <div className='couriers-routes-map__legend-courier__route-icon'>
                    <MapRouteIcon width={24} height={24} color={!exist ? '#6162be' : '#858897'} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className='couriers-routes-map__legend-courier__busy'>{formatMessage(messages.Free)}</div>
          )}
        </div>
      </div>
    )
  }

  renderCourierRoute = () => {
    const { couriersList, routes } = this.props
    const { formatMessage } = this.props.intl
    const { courierId, route, response } = this.state
    const courier = couriersList.find((item) => item.id === courierId)
    const planRoute = routes.find((item) => item.courierId === courierId)
    let routeSteps = route
    const isMoto = courier?.vehicleType === EVehicleType.MOTO
    let distance = 0
    let duration = 0

    if (planRoute && planRoute.startAt) {
      routeSteps = routeSteps.filter((step) => !!step.order)
    }

    if (response) {
      response.routes[0].legs.forEach((item: any) => {
        distance += item.distance.value
        duration += item.duration.value
      })

      distance = distance / 1000
      duration = duration / 60
    }

    return (
      <div>
        {courier && this.renderCourier(courier)}
        <div className='couriers-routes-map__legend-courier__route-steps'>
          {planRoute && planRoute.startAt && (
            <div className='couriers-routes-map__legend-courier__route-steps__orders'>
              <span className='couriers-routes-map__legend-courier__route-steps__orders-label'>
                {formatMessage(messages.OnDelivery).toUpperCase()}:
              </span>
              {routeSteps.filter((item) => !item.order || item.order.statusId < EOrderStatus.DELIVERED).length}
            </div>
          )}
          {routeSteps.map((item, index) => this.renderRouteStep(item, index, planRoute))}
        </div>
        {!planRoute?.startAt && duration > 0 && distance > 0 && (
          <div className='couriers-routes-map__legend-courier__route-steps__estimate'>
            <div className='couriers-routes-map__legend-courier__route-steps__estimate-icon'>
              {isMoto ? <MotoIcon color='#858897' /> : <CarIcon color='#858897' />}
            </div>
            <div className='couriers-routes-map__legend-courier__route-steps__estimate-duration'>
              {duration.toFixed(0)} {formatMessage(messages.Min)}
            </div>
            <div className='couriers-routes-map__legend-courier__route-steps__estimate-dot' />
            <div className='couriers-routes-map__legend-courier__route-steps__estimate-distance'>
              {distance.toFixed(1)} {formatMessage(messages.Km)}
            </div>
          </div>
        )}
        {!planRoute && (
          <div className='couriers-routes-map__legend-courier__route-steps__action'>
            <Button onClick={this.createRoute} title={formatMessage(messages.SetRoute)} />
          </div>
        )}
        {planRoute && !planRoute.startAt && (
          <div className='couriers-routes-map__legend-courier__route-steps__actions'>
            <div
              className='couriers-routes-map__legend-courier__route-steps__actions-delete'
              onClick={this.deleteRouteModal}
            >
              {formatMessage(messages.Delete)}
            </div>
            <div className='couriers-routes-map__legend-courier__route-steps__actions-btn' onClick={this.updateRoute}>
              {formatMessage(messages.Save)}
            </div>
          </div>
        )}
      </div>
    )
  }

  renderRouteStep = (step: IRouteStep, index: number, planRoute?: IRoute) => {
    const { formatMessage } = this.props.intl
    const { route } = this.state
    const { order } = step

    if (order && planRoute && planRoute.startAt) {
      return (
        <div key={index} className='couriers-routes-map__legend-courier__route-point'>
          <div className='couriers-routes-map__legend-courier__route-point__num' onClick={() => this.orderInfo(order)}>
            {formatMessage(messages.Order)} №{order.num}
          </div>
          <div className='couriers-routes-map__legend-courier__route-point__address'>{order.deliveryAddress}</div>
          {order.statusId < EOrderStatus.DELIVERED && (
            <div className='couriers-routes-map__legend-courier__route-point__text'>
              {formatMessage(messages.DeliveryTo)}: {formatTime(order.deliveryEndTime || '')}
            </div>
          )}
          {order.statusId === EOrderStatus.DELIVERED && (
            <div className='couriers-routes-map__legend-courier__route-point__delivered'>
              {formatMessage(messages.Delivered)}: {moment(order.completedAt).format('HH:mm')}
            </div>
          )}
          {order.statusId === EOrderStatus.CANCELED && (
            <div className='couriers-routes-map__legend-courier__route-point__canceled'>
              {formatMessage(messages.Canceled)}: {moment(order.completedAt).format('HH:mm')}
            </div>
          )}
        </div>
      )
    }

    return (
      <div key={index} className='couriers-routes-map__legend-courier__route-step'>
        <div className='couriers-routes-map__legend-courier__route-step__icon'>
          {step.orderId ? (
            <div className='couriers-routes-map__legend-courier__route-step__icon-point' />
          ) : (
            <CartIcon color={'#a0a3b5'} />
          )}
          {index !== route.length - 1 && <div className='couriers-routes-map__legend-courier__route-step__icon-dash' />}
        </div>
        <div className='couriers-routes-map__legend-courier__route-step__value'>
          {step.orderId ? formatMessage(messages.Order) : ''} {step.text}
          {step.orderId && (index > 1 || index < route.length - 2) && (
            <div className='couriers-routes-map__legend-courier__route-step__value__move'>
              {index > 1 && (
                <div
                  className='couriers-routes-map__legend-courier__route-step__value__move-up'
                  onClick={() => this.moveRoutePoint(index, index - 1)}
                >
                  <ArrowUpIcon color={'#a0a3b5'} />
                </div>
              )}
              {index < route.length - 2 && (
                <div
                  className='couriers-routes-map__legend-courier__route-step__value__move-down'
                  onClick={() => this.moveRoutePoint(index, index + 1)}
                >
                  <ArrowUpIcon color={'#a0a3b5'} />
                </div>
              )}
            </div>
          )}
        </div>
        {step.orderId && (
          <div
            className='couriers-routes-map__legend-courier__route-step__remove'
            onClick={() => this.removeRoutePoint(index)}
          >
            <TrashIcon color={'#bec2cd'} />
          </div>
        )}
      </div>
    )
  }

  renderRemoveModal = () => {
    const { formatMessage } = this.props.intl
    const { removeRouteModal } = this.state

    return (
      <ModalConfirm
        alert
        onClose={() => this.setState({ removeRouteModal: false })}
        onOk={this.removeRoute}
        open={removeRouteModal}
        title={formatMessage(messages.DeleteRouteWarning)}
        text={formatMessage(messages.CannotUndone)}
      />
    )
  }

  buildRoute = (courierId: string) => {
    const { orders, markets } = this.props
    const ordersCourier = orders.filter(
      (item) => item.courierId === courierId && item.deliveryAddress && item.statusId < EOrderStatus.DELIVERED,
    )
    const market = markets.find((item) => item.id === this.state.market)

    if (market) {
      this.setState({
        courierId,
        route: [
          {
            marketId: market.id,
            text: market.name,
            address: market.address,
          },
          ...ordersCourier.map((item) => ({
            marketId: item.marketId,
            orderId: item.id,
            text: `№ ${item.num}. ${item.deliveryAddress}`,
            address: item.deliveryAddress || '',
            order: item,
          })),
          {
            marketId: market.id,
            text: market.name,
            address: market.address,
          },
        ],
      })
    }
  }

  activeRoute = (route: IRoute) => {
    this.setState({ courierId: route.courierId, route: route.points })
  }

  moveRoutePoint = (oldPos: number, pos: number) => {
    const route = [...this.state.route]
    const moved = route.splice(oldPos, 1)

    route.splice(pos, 0, ...moved)

    this.setState({ route, response: undefined })
  }

  removeRoutePoint = (pos: number) => {
    const route = [...this.state.route]

    route.splice(pos, 1)

    const existOrder = route.find((item) => item.orderId)

    if (existOrder) {
      this.setState({ route, response: undefined })
    } else {
      this.dropRoute()
    }
  }

  selectOrder = (id: string) => {
    this.setState({ orderId: id })
  }

  orderInfo = (order: IOrder) => {
    this.props.orderModal({
      type: EModalType.MODAL_ORDER,
      size: 'mini',
      style: { width: '980px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        order,
        marketId: order.marketId,
      },
    })
  }

  customerInfo = (userId: number) => {
    const { market } = this.state

    this.props.customerModal({
      type: EModalType.MODAL_CUSTOMER,
      size: 'mini',
      style: { width: '980px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        userId,
        marketId: market,
      },
    })
  }

  assignOrder = (order: IOrder) => {
    this.props.assignOrderModal({
      type: EModalType.MODAL_ASSIGN_ORDER,
      size: 'mini',
      style: { width: '880px', maxWidth: '98%', borderRadius: '16px' },
      close: true,
      props: {
        order,
      },
    })
  }

  directionsCallback = (response: any) => {
    if (response !== null) {
      if (response.status === 'OK') {
        this.setState(() => ({ response }))
      }
    }
  }

  restartInterval = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }

    this.timer = Number(
      setInterval(() => {
        const { market } = this.state
        const courierIds = this.props.couriersList.map((item) => item.id)
        const center = this.mapRef?.getCenter()

        if (center) {
          this.setState({
            mapCenter: {
              lat: center.lat(),
              lng: center.lng(),
            },
          })
        }

        if (market && courierIds.length > 0) {
          this.props.apiCouriersLocations(courierIds)
        }
      }, 30000),
    )
  }

  handleChangeMarket = (_event: React.SyntheticEvent<HTMLElement, Event> | null, data: DropdownProps) => {
    const { value } = data

    this.dropRoute()
    this.setState({ market: String(value) }, this.refreshData)
    storage.setValue(storage.COURIERS_MARKET, String(value))
  }

  handleChangeStatuses = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { value } = data
    const statuses = [...(value as Array<EOrderStatus>)]

    statuses.sort()

    this.setState({ statuses }, this.refreshData)
  }

  handleChangeRoute = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { value } = data

    this.setState({ setRoute: value === '' ? undefined : Boolean(value) })
  }

  handleChangeCourier = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { value } = data

    this.setState({ setCourier: value === '' ? undefined : Boolean(value) })
  }

  refreshData = () => {
    const { market } = this.state

    this.props.apiCouriersList({ market })
    this.props.apiOrdersMapList({
      market,
      force: true,
      byCouriers: true,
      statuses: this.state.statuses || STATUS_DEFAULT,
      dateDeliveryTo: moment().format('YYYY-MM-DD'),
      dateDeliveryFrom: moment().format('YYYY-MM-DD'),
    })
    this.props.getRoutes(market)
  }

  createRoute = () => {
    const { route, market, courierId, response } = this.state
    let duration = 0

    if (market && courierId && route && route.length > 0) {
      if (response) {
        response.routes[0].legs.forEach((item: any) => {
          // distance += item.distance.value
          duration += item.duration.value
        })

        // distance = distance / 1000
        duration = duration / 60
      }

      this.props.createRoute({
        marketId: market,
        courierId,
        estimateTime: +duration.toFixed(),
        points: route.map((item, index) => ({
          index,
          courierId,
          orderId: item.orderId,
          marketId: item.marketId,
          text: item.text,
          address: item.address,
        })),
      })
    }

    this.dropRoute()
  }

  dropRoute = () => {
    this.setState({ route: [], courierId: '', orderId: '', response: undefined })
  }

  deleteRouteModal = () => {
    this.setState({ removeRouteModal: true })
  }

  updateRoute = () => {
    const { routes } = this.props
    const { route, courierId, response } = this.state
    const planRoute = routes.find((item) => item.courierId === courierId)
    let duration = 0

    if (planRoute && route && route.length > 0) {
      if (response) {
        response.routes[0].legs.forEach((item: any) => {
          // distance += item.distance.value
          duration += item.duration.value
        })

        // distance = distance / 1000
        duration = duration / 60
      }

      this.props.updateRoute({
        id: planRoute.id,
        marketId: planRoute.marketId,
        estimateTime: +duration.toFixed() || planRoute.estimateTime,
        points: route.map((item, index) => ({
          index,
          id: item.id,
          orderId: item.orderId,
          marketId: item.marketId,
          text: item.text,
          address: item.address,
        })),
      })
    }

    this.dropRoute()
  }

  removeRoute = () => {
    const { routes } = this.props
    const { courierId } = this.state
    const planRoute = routes.find((item) => item.courierId === courierId)

    if (planRoute) {
      this.props.removeRoute({
        req: { id: planRoute.id },
        marketId: planRoute.marketId,
      })

      this.setState({ removeRouteModal: false })
      this.dropRoute()
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { loaded, loading, couriersList, routes = [] } = s.couriers

  const ordersRoutes = []

  for (const route of routes) {
    for (const point of route.points) {
      if (point.order) {
        ordersRoutes.push(point.order)
      }
    }
  }

  return {
    loaded,
    loading,
    couriersList: couriersList.filter(
      (item) => item.role === ECourierRole.MANAGER || item.role === ECourierRole.COURIER,
    ),
    routes,
    markets: s.markets.markets || [],
    orders: uniqBy([...s.orders.ordersList, ...ordersRoutes], 'id'),
    ordersRoutes,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiCouriersLocations: (ids: string[]) => dispatch(Actions.action(Actions.API_COURIERS_LOCATIONS, { ids })),
  apiOrdersMapList: (filter: IOrdersFilter) => dispatch(Actions.action(Actions.API_ORDERS_LIST, filter)),
  orderModal: (data: IModalOrder) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  customerModal: (data: IModalCustomer) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  assignOrderModal: (data: IModalAssignOrder) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  getRoutes: (marketId: string) => dispatch(Actions.action(Actions.API_MARKET_ROUTES, { marketId })),
  createRoute: (data: ApiCreateMarketRouteReq) => dispatch(Actions.action(Actions.API_CREATE_MARKET_ROUTE, data)),
  updateRoute: (data: ApiUpdateMarketRouteReq) => dispatch(Actions.action(Actions.API_UPDATE_MARKET_ROUTE, data)),
  removeRoute: (data: ApiRemoveMarketRouteReq) => dispatch(Actions.action(Actions.API_REMOVE_MARKET_ROUTE, data)),
  getMarkets: () =>
    dispatch(
      Actions.action(Actions.API_MARKETS, {
        include_all: 'True',
      }),
    ),
})

export const CouriersRoutes = connect<TConnectedProps, TDispatchedProps, any, State>(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CouriersRoutesCmp))
