import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'

import './index.scss'

import { EOrderStatus, EPaymentType, IOrder } from '../../../types/TClient'

import messages from '../../../localization/messages'

type TOwnProps = {
  order: IOrder,
}

type TProps = TOwnProps & WrappedComponentProps

class OrderPaymentTypeCmp extends React.Component<TProps> {
  render() {
    const { formatMessage } = this.props.intl
    const { paymentType } = this.props.order

    return (
      <div className='order__payment-type'>
        <div className='order__block-title'>{formatMessage(messages.Payment)}</div>
        <div>
          <div className='order__block-item'>
            <div className='order__block-label'>{formatMessage(messages.PaymentMethod)}</div>
            <div className='order__block-text'>
              {paymentType === EPaymentType.CASH ? formatMessage(messages.Cash) : `${formatMessage(messages.Card)}.`}
              {this.paymentStatus(this.props.order)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  paymentStatus = (order: IOrder) => {
    const { formatMessage } = this.props.intl

    if (order.paymentType === EPaymentType.BY_CARD) {
      if (order.paid && order.statusId === EOrderStatus.CANCELED) {
        return (
          <div className={classnames('order__payment-type__status', 'order__payment-type__refund')}>
            {formatMessage(messages.Refund)}
          </div>
        )
      }

      if (order.extPaymentUrl !== undefined) {
        return (
          <div
            className={classnames(
              'order__payment-type__status',
              order.extPaid ? 'order__payment-type__paid' : 'order__payment-type__not-paid',
            )}
          >
            {order.extPaid ? formatMessage(messages.ExtraPaid) : formatMessage(messages.Surcharge)}
          </div>
        )
      }

      return (
        <div
          className={classnames(
            'order__payment-type__status',
            order.paid ? 'order__payment-type__paid' : 'order__payment-type__not-paid',
          )}
        >
          {order.paid ? formatMessage(messages.Paid) : formatMessage(messages.NotPaid)}
        </div>
      )
    }
  }
}

export const OrderPaymentType = injectIntl(OrderPaymentTypeCmp)
