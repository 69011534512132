import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Form } from 'semantic-ui-react'
import moment from 'moment'
import classnames from 'classnames'
import { debounce } from 'lodash'

import './index.scss'

import { ApiUpdateOrderReq, IClientSlot, ISlotType } from '../../../types/TApi'
import { ESlotType, IMarket, IOrder, TDeliveryArea } from '../../../types/TClient'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { DeliveryDatePicker, DeliveryTimePicker } from '../../PickerField'
import { State } from '../../../store/reducer'
import { getSlotType } from '../../../utils/ordersUtils'
import DeliveryArea from '../DeliveryArea'

const GET_SLOTS_DELAY = 500

type Slot = {
  slot?: string,
  delivery_type: ESlotType,
}

type TOwnProps = {
  title: string,
  order: IOrder,
  onClose: () => void,
}

type TStateToProps = {
  slots: IClientSlot[],
  slotTypes: ISlotType[],
  deliveryArea?: TDeliveryArea,
  market?: IMarket,
}

type TDispatchedProps = {
  apiOrderUpdate(data: ApiUpdateOrderReq): Actions.Action,
  apiGetSlots(data: { orderId: string, address?: string, date?: string }): Actions.Action,
}

type Props = TOwnProps & TDispatchedProps & TStateToProps & WrappedComponentProps

type FieldError = {
  field: string,
  message: string,
}

type IState = {
  address: string,
  changed: boolean,
  newDeliveryDate: Date,
  errors: FieldError[],
  slot?: Slot,
}

const FIELD_ADDRESS = 'address'
const FIELD_DELIVERY_DATE = 'delivery_date'
const FIELD_DELIVERY_TIME = 'delivery_time'

class ProductChangeModalCmp extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      address: props.order.deliveryAddress || '',
      changed: false,
      newDeliveryDate: props.order.deliveryDate || new Date(),
      errors: [],
    }
  }

  componentDidMount() {
    const { order } = this.props

    this.getSlots()

    if (order.deliveryAddress) {
      this.setState({ changed: true })
    }
  }

  render() {
    const { formatMessage } = this.props.intl
    const { changed } = this.state

    return (
      <div className='modal-delivery'>
        <div className='modal-delivery__title'>{formatMessage(messages.ChangeHowReceive)}</div>
        <Form>
          {this.renderAddressField()}
          <div className='modal-delivery__time'>
            {this.renderDate()}
            {this.renderTime()}
          </div>
        </Form>
        <div className='modal-delivery__divider' />
        <div className='modal-delivery__actions'>
          <div
            className={classnames('modal-delivery__btn', 'modal-delivery__btn-outline')}
            onClick={this.props.onClose}
          >
            {formatMessage(messages.Cancel)}
          </div>
          <div className='modal-delivery__btn' onClick={changed ? this.updateDelivery : undefined}>
            {formatMessage(messages.Confirm)}
          </div>
        </div>
      </div>
    )
  }

  renderDeliveryArea = () => {
    const { deliveryArea } = this.props

    if (!deliveryArea) {
      return null
    }

    return <DeliveryArea area={deliveryArea} />
  }

  renderTime = () => {
    const { slot } = this.state
    const { order, slots, slotTypes, market } = this.props
    const error = this.checkError(FIELD_DELIVERY_TIME)

    return (
      <div className='modal-delivery__time-slot'>
        <DeliveryTimePicker
          market={market}
          slotTypes={slotTypes}
          order={order}
          slots={slots}
          error={error ? error.message : false}
          name={FIELD_DELIVERY_TIME}
          slot={slot?.slot || slot?.delivery_type}
          onChange={this.handleChange}
          customMargin={16}
        />
      </div>
    )
  }

  renderDate = () => {
    const { newDeliveryDate } = this.state
    const { order } = this.props
    const error = this.checkError(FIELD_DELIVERY_DATE)

    return (
      <div className='modal-delivery__time-date'>
        <DeliveryDatePicker
          order={order}
          error={error ? error.message : false}
          name={FIELD_DELIVERY_DATE}
          date={newDeliveryDate}
          onChange={this.handleChange}
          customMargin={16}
        />
      </div>
    )
  }

  renderAddressField = () => {
    const { formatMessage } = this.props.intl
    const { address } = this.state
    const error = this.checkError(FIELD_ADDRESS)

    return (
      <div className='modal-delivery__address'>
        <Form.Input
          className='modal-delivery__address-field'
          placeholder={formatMessage(messages.DeliveryAddress)}
          value={address}
          name={FIELD_ADDRESS}
          fluid
          onChange={this.handleChange}
          error={error ? error.message : false}
        />
        {this.renderDeliveryArea()}
      </div>
    )
  }

  checkError = (field: string) => {
    const { errors } = this.state

    return errors.find((error) => error.field === field)
  }

  removeError = (field: string) => {
    const { errors } = this.state

    return errors.filter((error) => error.field !== field)
  }

  handleChange = (event: any, data: any) => {
    const { order, slots, slotTypes } = this.props
    const { name, value } = data

    if (!this.state.changed) {
      this.setState({ changed: true })
    }

    switch (name) {
      case FIELD_ADDRESS:
        this.setState({ address: value, errors: this.removeError(FIELD_ADDRESS) }, this.checkAddress)
        break
      case FIELD_DELIVERY_DATE:
        if (order) {
          const newDate = moment(value)
          const oldDate = order.deliveryDate ? moment(order.deliveryDate) : moment()
          const diff = newDate.diff(oldDate, 'day')

          if (diff >= 0) {
            this.setState(
              {
                newDeliveryDate: moment(value).toDate(),
                errors: this.removeError(FIELD_DELIVERY_DATE),
                slot: undefined,
              },
              this.getSlots,
            )
          }
        }
        break
      case FIELD_DELIVERY_TIME:
        {
          const isSlot = !!slots.find((s) => s.id === value)

          const slot: Slot = {
            slot: isSlot ? value : undefined,
            delivery_type: isSlot ? getSlotType(slotTypes)?.delivery_interval : value,
          }

          this.setState({ slot: slot, errors: this.removeError(FIELD_DELIVERY_TIME) })
        }
        break
    }
  }

  updateDelivery = () => {
    const { apiOrderUpdate, order } = this.props
    const { formatMessage } = this.props.intl
    const { address, changed, slot, newDeliveryDate } = this.state
    const errors: FieldError[] = []

    if (!address) {
      errors.push({ field: FIELD_ADDRESS, message: formatMessage(messages.EnterAddress) })
    }

    if (!newDeliveryDate) {
      errors.push({ field: FIELD_DELIVERY_DATE, message: formatMessage(messages.EnterDeliveryDate) })
    }

    if (!slot) {
      errors.push({ field: FIELD_DELIVERY_TIME, message: formatMessage(messages.SpecifyDeliveryTime) })
    }

    if (!changed) {
      return
    }

    if (!errors.length) {
      this.props.onClose()
      const timezone = order.cityTz || moment.tz.guess()

      apiOrderUpdate({
        id: order.id,
        self_pickup: false,
        delivery_address: address,
        delivery_date: this.formatDeliveryDate(newDeliveryDate, timezone),
        delivery_end_date: this.formatDeliveryDate(newDeliveryDate, timezone),
        ...slot,
      })
    } else {
      this.setState({ errors })
    }
  }

  formatDeliveryDate = (newDeliveryDate: Date, timezone: string) =>
    moment(newDeliveryDate).tz(timezone).format('YYYY-MM-DD')

  checkAddress = debounce(() => this.getSlots(), GET_SLOTS_DELAY)

  getSlots = () => {
    const { apiGetSlots, order } = this.props
    const { address, newDeliveryDate } = this.state
    const timezone = order.cityTz || moment.tz.guess()

    apiGetSlots({
      address,
      orderId: order.id,
      date: this.formatDeliveryDate(newDeliveryDate, timezone),
    })
  }
}

const stateToProps = (s: State, own: TOwnProps): TStateToProps => ({
  slots: s.orders.slots,
  slotTypes: s.orders.slotTypes,
  deliveryArea: s.orders.deliveryArea,
  market: s.markets?.markets.find((item) => item.id === own.order.marketId),
})

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiOrderUpdate: (data: ApiUpdateOrderReq) => dispatch(Actions.action(Actions.API_UPDATE_ORDER, data)),
  apiGetSlots: (data: { orderId: string, address?: string, date?: string }) =>
    dispatch(Actions.action(Actions.APP_GET_SLOTS, data)),
})

export const DeliveryChangeModal = connect(stateToProps, mapDispatchToProps)(injectIntl(ProductChangeModalCmp))
