import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'

import './index.scss'

import * as TApi from '../../../../../types/TApi'
import { ISeller } from '../../../../../types/TClient'

import { SellerItem } from '../../../../../components/SellerItem'
import { State } from '../../../../../store/reducer'
import * as Actions from '../../../../../store/actions'
import { LOCATION_SELLERS_SELLER } from '../../../../../utils/locationUtils'
import { sortSellerBy } from '../../../../../utils/sellerUtils'

type IOwnProps = {
  marketName: string,
  marketId?: string,
  system?: boolean,
}

type TConnectedProps = {
  loading: boolean,
  sellers: ISeller[],
}

type TDispatchedProps = {
  apiSellersList: (data: TApi.ApiSellersListReq) => Actions.Action,
}

type Props = IOwnProps & RouteComponentProps & TConnectedProps & TDispatchedProps

type IState = {
  opened: boolean,
}

const SELLERS_LIMIT = 25

class SellersMarketCmp extends React.Component<Props, IState> {
  listRef!: HTMLDivElement

  state = {
    opened: false,
  }

  render() {
    const { sellers, marketName, system } = this.props
    const { opened } = this.state

    return (
      <div className='sellers-market'>
        <div className='sellers-market__title' onClick={this.openSellersMarket}>
          <Icon size='big' name={opened ? 'caret down' : 'caret right'} />
          <div className='sellers-market__title-text'>{marketName}</div>
          {system && (
            <div className='sellers-market__type'>
              <Icon name='id card outline' size='big' />
            </div>
          )}
        </div>
        {opened && (
          <div ref={this.saveRef} className='sellers-market__list' onScroll={this.checkEnd}>
            {sellers.map((item) => (
              <div key={item.id} className='sellers-market__list-seller'>
                <SellerItem seller={item} toSeller={this.toSeller} />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  saveRef = (ref: HTMLDivElement) => {
    this.listRef = ref
  }

  openSellersMarket = () => {
    const { opened } = this.state

    this.setState({ opened: !opened })
    this.getSellersMarket(0)
  }

  getSellersMarket = (offset: number) => {
    const { marketId, system } = this.props

    this.props.apiSellersList({
      offset,
      ...(marketId && { market: marketId }),
      ...(system && { isSystem: system }),
    })
  }

  checkEnd = () => {
    const { sellers, loading } = this.props
    const { scrollHeight, offsetHeight, scrollTop } = this.listRef

    if (
      !loading &&
      sellers.length &&
      sellers.length % SELLERS_LIMIT === 0 &&
      scrollHeight - offsetHeight - scrollTop < 300
    ) {
      this.getSellersMarket(sellers.length)
    }
  }

  toSeller = (item: ISeller) => {
    this.props.history.push(`${LOCATION_SELLERS_SELLER}/${item.id}`)
  }
}

const mapStateToProps = (s: State, own: IOwnProps): TConnectedProps => {
  const { marketId, system } = own
  const { loading, sellersList } = s.sellers
  let sellers: ISeller[] = []

  if (marketId) {
    sellers = sellersList.filter((seller) => seller.marketId === marketId)
  } else if (system) {
    sellers = sellersList.filter((seller) => seller.isSystem)
  }

  sellers.sort(sortSellerBy)

  return {
    loading,
    sellers: [...sellers],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiSellersList: (data: TApi.ApiSellersListReq) => dispatch(Actions.action(Actions.API_SELLERS_LIST, data)),
})

export const SellersMarket = withRouter(connect(mapStateToProps, mapDispatchToProps)(SellersMarketCmp))
