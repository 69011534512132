import {
  CopyProductsStatus,
  EModerateProductStatus,
  ENotificationDay,
  ENotificationFrequency,
  ENotificationType,
  ENotificationUsers,
  EOrderStatus,
  ESlotType,
  ICurrencyPairs,
  ICustomersFilter,
  IModerationProductsFilter,
  INotification,
  ITag,
  TDeliveryArea,
  TLogAction,
} from './TClient'
import * as TClient from './TClient'

export interface IMarketApi {
  id: string,
  name: string,
  city: string,
  city_id: string,
  city_tz: string,
  address: string,
  active: boolean,
  delivery_end_time: string,
  delivery_radius: string,
  delivery_start_time: string,
  latitude: number,
  longitude: number,
  max_delivery_radius: string,
  open_hours_end: string,
  open_hours_start: string,
  self_pickup_end_time: string,
  self_pickup_start_time: string,
  currency_code?: string,
  delivery_areas?: IDeliveryArea[],
  categories?: IStockCategory[],
}

export enum EUserRoleApi {
  ADMIN = 1,
  MANAGER = 2,
  OPERATOR = 3,
}

export interface IProductOrderApi {
  id: string,
  item_id: string,
  country: string,
  image_url: string,
  market: string,
  name: string,
  price: number,
  total_price: number,
  quantity: number,
  quantity_initial: number,
  payment_quantity?: number,
  extend_quantity?: number,
  seller_id: string,
  seller_is_common: boolean,
  seller_is_system: boolean,
  seller_name: string,
  seller_commission: number,
  about_seller?: string,
  subcategory: string,
  unit: string,
  pcs_weight_gr: number,
  organisation_name?: string,
  city_tz?: string,
  added?: boolean,
  created_at?: Date,
  currency_code?: string,
}

export interface ITransactionApi {
  id: string,
  Amount: string,
  Details: string,
  ErrorCode: string,
  Message: string,
  OrderId: string,
  PaymentId: string,
  PaymentURL: string,
  Status: string,
  Success: boolean,
  TerminalKey: string,
}

export interface IOrderApi {
  id: string,
  num: number,
  price: number,
  payment_type: number,
  is_payed: boolean,
  web: boolean,
  payment_url: string,
  weight_gr: number,
  self_pickup: boolean,
  delivery_price?: number,
  delivery_address?: string,
  delivery_date?: string,
  delivery_time?: string,
  delivery_end_time?: string,
  delivery_distance?: number,
  delivery_lat?: number,
  delivery_lon?: number,
  market_address: string,
  market_id: string,
  market_name: string,
  market_open_hours_start: string,
  market_open_hours_end: string,
  market_self_pickup_start_time: string,
  market_self_pickup_end_time: string,
  market_delivery_start_time: string,
  market_delivery_end_time: string,
  is_picked: boolean,
  created_at: Date,
  completed_at?: string,
  items: IProductOrderApi[],
  user_id: number,
  user_phone: string,
  user_business?: boolean,
  user_first_name?: string,
  user_last_name?: string,
  user_call_me?: boolean,
  user_call_about_change_product?: boolean,
  user_call_about_delete_product?: boolean,
  user_call_about_change_product_weight?: boolean,
  user_call_change_product?: boolean,
  user_call_delete_product_by_weight?: boolean,
  courier_id?: string,
  courier_phone?: string,
  courier_first_name?: string,
  courier_last_name?: string,
  picker_first_name?: string,
  picker_last_name?: string,
  picker_id?: string,
  picker_phone?: string,
  collector_id?: string,
  collector_phone?: string,
  collector_first_name?: string,
  collector_last_name?: string,
  order_confirmed: boolean,
  status_id: EOrderStatus,
  updated_at?: string,
  message?: string,
  message_hide?: string,
  city?: string,
  city_tz?: string,
  is_collected: boolean,
  assembly_at?: Date,
  courier_at?: Date,
  delivery_at?: Date,
  canceled_at?: Date,
  collected_at?: Date,
  collected_late: number,
  delivery_late: number,
  delivery_end_late: number,
  review?: IReviewOrder,
  ext_paid?: boolean,
  ext_payment_url?: string,
  delivery_type?: ESlotType,
  delivery_area?: TDeliveryArea,
}

export interface IReviewOrder {
  assessment: number,
  message: string,
}

export interface IOrderByMapApi {
  delivery_address: string,
  delivery_lat: string,
  delivery_long: string,
  user_id: string,
  user_phone: string,
  user_business: boolean,
  user_first_name?: string,
  user_last_name?: string,
  count: number,
  last_order_date: Date,
}

export enum ESellerTypeApi {
  IP = 'ИП',
  OOO = 'ООО',
}

export interface IPartnerIPApi {
  value: string,
  data: {
    state: {
      registration_date: number,
    },
    opf: {
      short: ESellerTypeApi.IP,
    },
    name: {
      full: string,
    },
    inn: string,
    ogrn: string,
    okpo: string,
    okato: string,
    okogu: string,
    okfs: string,
    okved: string,
    address: {
      value: string,
      unrestricted_value: string,
      data: {
        postal_code: string,
        country: string,
        country_iso_code: string,
        timezone: string,
        source: string,
      },
    },
  },
}

export interface IPartnerOOOApi {
  value: string,
  data: {
    kpp: string,
    state: {
      registration_date: number,
    },
    opf: {
      short: ESellerTypeApi.OOO,
    },
    name: {
      short_with_opf: string,
    },
    inn: string,
    ogrn: string,
    okpo: string,
    okato: string,
    oktmo: string,
    okogu: string,
    okfs: string,
    okved: string,
    address: {
      value: string,
      unrestricted_value: string,
      data: {
        postal_code: string,
        country: string,
        country_iso_code: string,
        timezone: string,
        source: string,
      },
    },
  },
}

export interface ISellerInfoApi {
  id: string,
  createdAt: Date,
  phone: string,
  password: string,
  isHide: boolean,
  isSystem?: boolean,
  createdUserDb?: boolean,
  createdProfileDb?: boolean,
  email?: string,
  city?: string,
  market?: string,
  location?: string,
  inn?: string,
  type?: ESellerTypeApi,
  fio?: string,
  organizationName?: string,
  ogrnip?: string,
  ogrn?: string,
  address?: string,
  registrationDate?: Date,
  bik?: string,
  bankName?: string,
  corAcc?: string,
  checkingAcc?: string,
  certificateUrl?: string,
  passportUrl?: string,
  bankAccDocUrl?: string,
  legalRegistrationCertificateUrl?: string,
  legalEntitiesStateRegisterUrl?: string,
  decisionAppointmentUrl?: string,
  orderAppointmentUrl?: string,
  rentContractUrl?: string,
}

export interface IOrdersByMarket {
  marketId: string,
  marketName: string,
  totalNum: number,
  totalPrice: number,
  totalWeightKg: number,
}

export interface ITotalOrders {
  cityId: string,
  cityName: string,
  marketId: string,
  marketName: string,
  totalUniqueClients: number,
  totalNumDelivered: number,
  totalNumCanceled: number,
  totalNumFirst: number,
  totalCustomersFirstMonth: number,
  totalOrdersFirstMonth: number,
  totalCustomersLastMonth: number,
  totalOrdersLastMonth: number,
  totalPrice: number,
  totalCommission: number,
  totalDeliveryPrice: number,
  totalWeightKg: number,
}

export type ApiLoginReq = {
  login: string,
  password: string,
}
export type ApiLoginResp = {
  user: {
    login: string,
    email: string,
    role: EUserRoleApi,
  },
  sid: string,
}

export type ApiSignUpReq = {
  email: string,
  login: string,
  password: string,
  adminKey: string,
  role: EUserRoleApi,
}
export type ApiSignUpResp = {
  sid: string,
}

export type ApiUserInfoResp = {
  login: string,
  email: string,
  role: EUserRoleApi,
}

export type ApiOrdersListResp = {
  orders: IOrderApi[],
  total?: number,
}

export type ApiOrdersMapListResp = {
  orders: IOrderByMapApi[],
}

export type ApiUpdateOrderReq = {
  id: string,
  payment_type?: number,
  message_hide?: string,
  courier?: string,
  delivery_date?: string,
  delivery_price?: number,
  delivery_end_date?: string,
  delivery_time?: string,
  delivery_end_time?: string,
  self_pickup?: boolean,
  delivery_address?: string,
  slot?: string,
  delivery_type?: ESlotType,
}

export type ApiAddProductsOrderReq = {
  id: string,
  products: {
    id: string,
    quantity: number,
  }[],
}

export type ApiCity = {
  id: string,
  name: string,
}
export type ApiCitiesReq = {
  include_all?: string,
}
export type ApiCitiesResp = {
  records: ApiCity[],
}

export type ApiMarket = {
  id: string,
  name: string,
  city: string,
  address: string,
}
export type ApiMarketsReq = {
  market?: string,
  city?: string,
  seller?: string,
  include_all?: string,
}
export type ApiMarketsResp = {
  records: ApiMarket[],
}

export enum EDeliveryAreaStatus {
  ACTIVE = 0,
  BLOCK_TODAY = 1,
  BLOCK_ALWAYS = 2,
}
export enum EDeliverySlotType {
  ASAP = 1,
  BY_SLOTS = 2,
  BY_DAY = 3,
}
export interface IDeliveryAsap {
  type: EDeliverySlotType.ASAP,
  price: number,
}
export interface IDeliveryByDay {
  type: EDeliverySlotType.BY_DAY,
  price: number,
}
export interface IDeliveryBySlots {
  type: EDeliverySlotType.BY_SLOTS,
  numBlockSlot: number,
  price: number,
}
export type TDeliveryType = IDeliveryAsap | IDeliveryByDay | IDeliveryBySlots
export interface IDeliveryArea {
  id: number,
  polygon: [number, number][],
  status: EDeliveryAreaStatus,
  minOrderPrice?: number,
  types: TDeliveryType[],
  index: number,
  marketId: string,
}
export type ApiAddDeliveryAreaReq = Omit<IDeliveryArea, 'id' | 'index'>
export type ApiAddDeliveryAreaResp = {
  id: number,
  index: number,
}
export type ApiUpdateDeliveryAreaReq = IDeliveryArea
export type ApiRemoveDeliveryAreaReq = Pick<IDeliveryArea, 'id' | 'marketId'>

export interface IStockSubcategory {
  id: string,
  categoryId: string,
  marketId: string,
  name: string,
  index: number,
  imageUrl?: string,
  hidden: boolean,
  numProducts?: number,
}
export interface IStockCategory {
  id: string,
  marketId: string,
  name: string,
  index: number,
  imageUrl?: string,
  hidden: boolean,
  numProducts?: number,
  subcategories: IStockSubcategory[],
}

export type ApiMarketCategoriesReq = {
  marketId: string,
  hidden?: boolean,
  hiddenSeller?: boolean,
  notInOrder?: string,
}
export type ApiMarketCategoriesResp = { categories: IStockCategory[] }

export type ApiCreateMarketCategoryReq = Omit<IStockCategory, 'id' | 'subcategories' | 'numProducts'>
export type ApiCreateMarketCategoryResp = { id: string }

export type ApiUpdateMarketCategoryReq = {
  category: IStockCategory,
  changed?: TClient.IStockCategory,
  req: Omit<IStockCategory, 'subcategories' | 'numProducts'>,
}

export type ApiRemoveMarketCategoryReq = Pick<IStockCategory, 'id' | 'marketId'>

export type ApiCreateMarketSubcategoryReq = {
  category: TClient.IStockCategory,
  req: Omit<IStockSubcategory, 'id' | 'numProducts'>,
}
export type ApiCreateMarketSubcategoryResp = { id: string }

export type ApiUpdateMarketSubcategoryReq = {
  category: TClient.IStockCategory,
  changedSub?: TClient.IStockSubcategory,
  oldIndex?: number,
  req: Omit<IStockSubcategory, 'numProducts'>,
}

export type ApiRemoveMarketSubcategoryReq = {
  category: TClient.IStockCategory,
  req: Pick<IStockSubcategory, 'id'>,
}

export type ApiPartnersReq = {
  query: string,
}
export type ApiPartnersResp = {
  suggestions: [IPartnerIPApi | IPartnerOOOApi],
}

export interface IBankInfoApi {
  value: string,
  data: {
    bic: string,
    correspondent_account: string,
  },
}

export type ApiBankReq = {
  bik: string,
}
export type ApiBankResp = {
  suggestions: [IBankInfoApi],
}

export enum ImageSize {
  LARGE = 'l',
  MEDIUM = 'm',
  SMALL = 's',
}

export type StorageFileErrorApi = { error: string }
export type StorageFileApi = {
  file_id: number,
  url: string,
  tkn: string,
  orig_filename: string,
  size: number,
  thumbnails?: ImageSize[],
}

export type ApiUploadFileResp = [StorageFileApi | StorageFileErrorApi]

export type ApiSellersInfoListResp = {
  sellers: ISellerInfoApi[],
}

export type ApiSellerInfoData = Omit<ISellerInfoApi, 'id' | 'createdUserDb' | 'createdProfileDb' | 'createdAt'>

export type ApiSellerInfoCreateReq = ApiSellerInfoData
export type ApiSellerInfoCreateResp = {
  id: string,
}

export type ApiSellerInfoUpdateReq = Partial<Omit<ISellerInfoApi, 'createdUserDb' | 'createdProfileDb' | 'createdAt'>>

export type ApiSellerRegisterReq = {
  id: string,
}

export type ApiSellerCourierReq = {
  sellerId: string,
}

export type ApiSellerCourierResp = {
  created: boolean,
  courierId?: string,
}

export type ApiSellerRegisterResp = {
  createdUser: boolean,
  createdProfile: boolean,
}

export interface IMarketApi {
  id: string,
  name: string,
  address: string,
  openingHours: string,
  closingHours: string,
}

export interface ISellerCategoryApi {
  id: string,
  name: string,
}

export interface ISellerApi {
  id: string,
  userId: number,
  isHide: boolean,
  isSystem: boolean,
  isCommon: boolean,
  commission: number,
  name: string,
  nickname: string,
  organization: string,
  location: string,
  imageUrl: string,
  avatarUrl: string,
  categories: ISellerCategoryApi[],
  courierId?: string,
  aboutSellers?: string[],
  cityId: string,
  cityName: string,
  marketId: string,
  marketAddress: string,
  marketName: string,
  marketOpeningHours?: string,
  marketClosingHours?: string,
}

export interface ISellerSearchApi {
  id: string,
  isSystem: boolean,
  isCommon: boolean,
  isHide: boolean,
  name: string,
  nickname: string,
  location: string,
  marketId: string,
  marketName: string,
  organization: string,
}

export interface ISellerProductApi {
  id: string,
  image_url: string,
  market: string,
  name: string,
  pcs_weight_gr: number,
  price: string,
  seller: string,
  step: number,
  subcategory: string,
  unit: string,
  country: string,
  hide: boolean,
  tags: ITag[],
  price_without_discount: string,
  description?: string,
  is_season?: boolean,
  about_seller?: string,
  market_category?: string,
  market_sub_category?: string,
  vendor_code?: string,
  rating?: number,
  price_weight_gr?: number,
  currency_code?: string,
}

export interface IProductApi {
  id: string,
  name: string,
  is_hide: boolean,
  price: number,
  total_price: number,
  unit: string,
  step: number,
  pcs_weight_gr: number,
  country: string,
  image_url: string,
  market_id: string,
  seller_id: string,
  seller_is_common: boolean,
  seller_is_system: boolean,
  seller_name: string,
  seller_commission: number,
  seller_nickname: string,
  seller_location: string,
  seller_organisation: string,
  seller_inn: string,
  seller_address: string,
  category: string,
  category_id: string,
  price_without_discount: string,
  category_priority?: number,
  subcategory: string,
  subcategory_id: string,
  price_weight_gr?: number,
  subcategory_priority?: number,
  market_category?: string,
  market_category_id?: string,
  market_category_priority?: number,
  market_subcategory?: string,
  market_subcategory_id?: string,
  market_subcategory_priority?: number,
  vendor_code?: string,
  description?: string,
  about_seller?: string,
  tags: number[],
  rating?: number,
  currency_code?: string,
}

export interface IModerationProductApi {
  id: string,
  num: number,
  status: EModerateProductStatus,
  product_id: string,
  text?: string,
  created_at: Date,
  moderate_at?: Date,
  name: string,
  is_hide: boolean,
  price: number,
  currency_code?: string,
  total_price: number,
  unit: string,
  step: number,
  pcs_weight_gr: number,
  country: string,
  image_url: string,
  market_id: string,
  seller_id: string,
  seller_is_common: boolean,
  seller_is_system: boolean,
  seller_name: string,
  seller_organisation: string,
  seller_commission: number,
  seller_nickname: string,
  seller_location: string,
  category: string,
  category_id: string,
  category_priority?: number,
  subcategory: string,
  subcategory_id: string,
  subcategory_priority?: number,
  description?: string,
  about_seller?: string,
  price_weight_gr?: number,
  old_name: string,
  old_price: number,
  old_unit: string,
  old_step: number,
  old_pcs_weight_gr: number,
  old_country: string,
  old_image_url: string,
  old_category: string,
  old_category_id: string,
  old_subcategory: string,
  old_subcategory_id: string,
  old_description?: string,
  old_price_weight_gr?: number,
}

export interface IDeliveryAddressApi {
  userId: number,
  address: string,
  createdAt: Date,
}

export interface IReviewApi {
  id: string,
  orderId: string,
  orderNum: number,
  createdAt: Date,
  userId: number,
  assessment: number,
  message?: string,
}

export interface ICustomerApi {
  id: string,
  userId: number,
  phone: string,
  business: boolean,
  registerAt: Date,
  firstName?: string,
  lastName?: string,
  firstOrderCreatedAt?: Date,
  lastOrderCreatedAt?: Date,
  cityName?: string,
  ordersCount: number,
  averageOrderPrice: number,
  comment?: string,
}

export enum ECourierRole {
  MANAGER = 'MANAGER',
  PICKER = 'PICKER',
  COURIER = 'COURIER',
}

export interface IShiftSlotApi {
  id?: string,
  marketId: string,
  courierId: string,
  shiftDate: string,
  slotId: string,
  startAt?: string,
  completeAt?: string,
}

export interface ICourierApi {
  id: string,
  firstName: string,
  lastName: string,
  phone: string,
  role: ECourierRole,
  isOnShift: boolean,
  cityId: string,
  cityName: string,
  marketId: string,
  marketAddress: string,
  marketName: string,
  numActiveOrders: number,
  isBusy?: boolean,
  vehicleType?: string,
  carNumber?: string,
  latitude?: number,
  longitude?: number,
  shiftSlots?: IShiftSlotApi[],
}

export interface ICourierLocationApi {
  id: string,
  isOnShift: boolean,
  latitude: number,
  longitude: number,
}

export type ApiSellersMarketsReq = {
  offset: number,
}
export type ApiSellersMarketsResp = {
  markets: IMarketApi[],
}

export type ApiSellersListReq = {
  offset: number,
  id?: string,
  market?: string,
  isSystem?: boolean,
  hidden?: boolean,
  search?: string,
  searchByProducts?: string,
  category?: string,
  force?: boolean,
}
export type ApiSellersListResp = {
  sellers: ISellerApi[],
  categories: ISellerCategoryApi[],
}

export type ApiSellersSearchReq = {
  search?: string,
}
export type ApiSellersSearchResp = {
  sellers: ISellerSearchApi[],
}

export type ApiSellerProductsReq = {
  seller: number,
  offset?: number,
  limit?: number,
  search?: string,
  about_seller?: string,
  category?: string,
  hidden?: boolean,
}
export type ApiSellerProductsResp = {
  products: ISellerProductApi[],
}

export type ApiMarketProductsReq = {
  market: string,
  byCategories?: boolean,
  marketCategory?: string,
  seller?: string,
  category?: string,
  search?: string,
  offset?: number,
  limit?: number,
  hidden?: boolean,
  hiddenSeller?: boolean,
  ids?: string,
  notInOrder?: string,
}
export type ApiMarketProductsResp = {
  products: IProductApi[],
}

export type ApiCopyProductsReq = {
  source: string,
  target: string,
}
export type ApiCopyProductsResp = {
  tkn: string,
}

export type ApiCopyProductsStatusReq = {
  tkn: string,
}
export type ApiCopyProductsStatusResp = {
  status: {
    products: CopyProductsStatus,
  },
  success: boolean,
}

export type ApiUpdateSellerReq = {
  id: string,
  userId: number,
  nickname: string,
  name: string,
  avatarUrl: string,
  imageUrl: string,
}
export type ApiUpdateSellerResp = any

export interface ICountryApi {
  name: string,
  code: string,
}

export interface ISubcategoryApi {
  subcategory_id: string,
  subcategory_name: string,
  subcategory_priority: number,
}

export interface ICategoryApi {
  id: string,
  name: string,
  priority: number,
  subcategories: ISubcategoryApi[],
}

export interface IStepApi {
  step_id: number,
  step_name: string,
}

export interface IUnitApi {
  unit_id: string,
  unit_name: string,
}

export type ApiProductCategoriesResp = {
  categories: ICategoryApi[],
}
export type ApiProductCountriesResp = {
  countries: ICountryApi[],
}
export type ApiProductStepsResp = {
  steps: IStepApi[],
}
export type ApiProductUnitsResp = {
  units: IUnitApi[],
}

export type ApiProductCreateReq = {
  userId: number,
  name: string,
  hide: boolean,
  country: string,
  subcategory: string,
  unit: string,
  weightPcsGr: number,
  step: number,
  price: string,
  price_without_discount: string,
  imageUrl: string,
  tags: number[],
  stockCategory?: string,
  stockSubcategory?: string,
  vendorCode?: string,
  description?: string,
  aboutSeller?: string,
  rating?: number,
  price_weight_gr?: number,
}
export type ApiProductCreateResp = {
  id: string,
}

export type ApiProductUpdateReq = {
  userId?: number,
  sellerId: string,
  productId: string,
  price_without_discount?: string,
  hide?: boolean,
  name?: string,
  country?: string,
  subcategory?: string,
  stockCategory?: string,
  stockSubcategory?: string,
  vendorCode?: string,
  unit?: string,
  weightPcsGr?: number,
  step?: number,
  price?: string,
  imageUrl?: string,
  description?: string,
  aboutSeller?: string,
  tags?: number[],
  rating?: number,
  price_weight_gr?: number,
}

export type ApiProductsUpdateRating = {
  market: string,
  marketCategory?: string,
  marketSubcategory?: string,
}

export type ApiProductsModerationListReq = IModerationProductsFilter
export type ApiProductsModerationListResp = {
  products: IModerationProductApi[],
  total: number,
  totalOnModeration: number,
}

export type ApiUpdateModerationProductReq = {
  id: string,
  status: EModerateProductStatus,
  text?: string,
}
export type ApiUpdateModerationProductResp = {
  success: boolean,
}

export type ApiCustomersListReq = ICustomersFilter
export type ApiCustomersListResp = {
  customers: ICustomerApi[],
  total?: number,
  totalWithOrders?: number,
  totalWithoutOrders?: number,
}

export type ApiCustomerUpdateProfileReq = {
  clientId: string,
  comment: string,
}
export type CustomerUpdateProfileResp = {
  updated: boolean,
}

export type ApiCustomerAddressesReq = {
  offset?: number,
  limit?: number,
  city?: string,
  market?: string,
  userId?: number,
  rate?: number,
  dateFrom?: string,
  dateTo?: string,
  b2b?: boolean,
}
export type ApiCustomerAddressesResp = {
  addresses: IDeliveryAddressApi[],
  total?: number,
}

export type ApiCustomerReviewsReq = {
  offset?: number,
  limit?: number,
  city?: string,
  market?: string,
  userId?: number,
  rate?: number,
  dateFrom?: string,
  dateTo?: string,
  b2b?: boolean,
}
export type ApiCustomerReviewsResp = {
  reviews: IReviewApi[],
  total?: number,
}

export type ApiCustomerOrdersReq = {
  offset?: number,
  limit?: number,
  userId?: number,
}
export type ApiCustomerOrdersResp = {
  orders: IOrderApi[],
  total?: number,
}

export type ApiCouriersListReq = {
  offset?: number,
  id?: string,
  market?: string,
}
export type ApiCouriersListResp = {
  couriers: ICourierApi[],
}

export type ApiCouriersLocationsReq = {
  ids: string[],
}
export type ApiCouriersLocationsResp = {
  couriersLocations: ICourierLocationApi[],
}

export enum MarketingPushType {
  SINGLE = 'SINGLE',
  SCHEDULE = 'SCHEDULE',
}

export enum EAuditory {
  ALL = 'ALL',
  REGISTERED = 'REGISTERED',
  NOT_REGISTERED = 'NOT_REGISTERED',
}

export interface INotificationInfoApi {
  id: number,
  title: string,
  body: string,
  auditory: EAuditory,
  sendDate: string,
  usersCount: number,
  marketingPushId: number,
  marketingPushType: MarketingPushType,
}

export interface IPeriodOrders {
  year: number,
  month: number,
  orders: number,
  customers: number,
}

export interface ITotalOrdersApi {
  cityId: string,
  cityName: string,
  marketId: string,
  marketName: string,
  totalUniqueClients: number,
  totalNumDelivered: number,
  totalNumCanceled: number,
  totalNumFirst: number,
  totalCustomersFirstMonth: number,
  totalOrdersFirstMonth: number,
  totalCustomersLastMonth: number,
  totalOrdersLastMonth: number,
  totalOrdersFirstMonthByPeriods: IPeriodOrders[],
  totalOrdersLastMonthByPeriods: IPeriodOrders[],
  totalPrice: number,
  totalCommission: number,
  totalDeliveryPrice: number,
  totalWeightKg: number,
  currencyCode: string,
}

export type ApiProductUpdateResp = {
  id: string,
}

export type ApiProductDeleteReq = {
  userId: number,
  productId: string,
}

export type ApiProductCopyReq = {
  productId: string,
  sellerId: string,
}
export type ApiProductCopyResp = IProductApi

export type ApiNotificationsListReq = {
  offset?: number,
}
export type ApiNotificationsLogReq = {
  offset?: number,
}
export type ApiNotificationsListResp = {
  notifications: INotification[],
}
export type ApiNotificationsLogResp = INotificationInfoApi[]

export type ApiClient = {
  id: number,
  phone: string,
  name?: string,
}

export type ApiGetClientsResp = ApiClient[]

export type ApiNotificationCreateReq = {
  title: string,
  body: string,
  times: string[],
  type: ENotificationType,
  days?: ENotificationDay[],
  date?: Date,
  frequency?: ENotificationFrequency,
  frequencyWeek?: number,
  userGroup?: ENotificationUsers,
  clients?: number[],
}
export type ApiNotificationCreateResp = INotification[]

export type ApiNotificationUpdateReq = {
  id: string,
  title: string,
  body: string,
  times: string[],
  type: ENotificationType,
  days?: ENotificationDay[],
  date?: Date,
  frequency?: ENotificationFrequency,
  frequencyWeek?: number,
  userGroup?: ENotificationUsers,
  clients?: number[],
}

export type ApiNotificationRemoveReq = {
  notification: INotification,
}

export type UpdateIsHideReq = {
  id: string,
  isHide: boolean,
}

export type ApiReportOrdersResp = {
  data: string,
}

export type ApiReportProductsResp = {
  data: string,
}

export type ApiReportCourierShiftsResp = {
  data: string,
}

export type ApiOrdersByMarkersResp = {
  data: IOrdersByMarket[],
}

export type ApiTotalOrdersResp = {
  data: ITotalOrdersApi[],
  currencyPairs?: ICurrencyPairs,
}

export type ApiCustomersResp = {
  data: number,
}

export type ApiGetTagsReq = {
  market_id: string,
}
export type TCreateTag = Omit<ITag, 'id' | 'products_count'>

export type TUpdateTag = Partial<ITag> & { id: number }

export type TagProductsReq = {
  tagId: number,
  marketId: string,
}

export type AssignOrderReq = {
  courierId: string,
  orderId: string,
}

export interface IProductByTagResp {
  id: string,
  name: string,
  description: string,
  price: number,
  unit: string,
  pcs_weight_gr: number,
  step: number,
  country: string,
  image: string,
  hidden: string,
  category_id: string,
  category_name: string,
  subcategory_id: string,
  subcategory_name: string,
  market_id: string,
  market_name: string,
  market_currency_code: string,
  seller_id: string,
  seller_name: string,
  seller_image: string,
  seller_fio: string,
  seller_nickname: string,
  seller_organisation_name: string,
  seller_avatar: string,
  price_without_discount: string,
  tags: ITag[],
}

export type TagProductsResp = {
  records: IProductByTagResp[],
}

export interface ISlotApi {
  id?: string,
  timeStart: string,
  timeEnd: string,
  market: string,
  isActive: boolean,
}

export interface IUpdateSlotReq {
  marketId: string,
  update: ISlotApi[],
  delete: ISlotApi[],
  add: ISlotApi[],
}
export interface IUpdateSlotsResp {
  error: boolean,
  slots: ISlotApi[],
}

export enum EDeliveryInfoErrorStatus {
  ADDRESS_NOT_VALID = '1',
  MARKET_NOT_MATCH = '2',
  CALCULATE_PRICE = '3',
  MIN_DELIVERY_PRICE = '4',
  MAX_DELIVERY_RADIUS = '5',
  MARKET_CLOSED = '6',
  NO_SLOTS = '7',
  NOT_ACTIVE_TODAY = '8',
  CONSTANTLY_NON_ACTIVE = '9',
  MARKET_AND_POLYGON_CLOSED = '10',
}

export type TOrderItem = { productId: string, quantity: number }

export type TAddressPoint = {
  lat: string,
  lon: string,
}

export interface IClientSlot {
  id: string,
  time_start: string,
  time_end: string,
}

export interface ISlotType {
  delivery_interval: ESlotType,
  delivery_price: number,
}

export type TError = {
  detail: string,
  status: EDeliveryInfoErrorStatus,
}

export type IClientSlotsReq = {
  orderId: string,
  fromModal?: boolean,
  address?: string,
  date?: string,
}

export type IClientSlotsResp = {
  min_order_price: number,
  slots: IClientSlot[],
  type: ISlotType[],
  point: TAddressPoint,
  delivery_area?: {
    id: number,
    index: string,
  },

  status?: EDeliveryInfoErrorStatus,
}

export interface ISlotInfo {
  timeEnd: string,
  timeStart: string,
  id: string,
  isActive: boolean,
  loadFactor: number,
  load: number,
  ordersCount: number,
  couriersCount: number,
  managersCount: number,
  pickersCount: number,
  allCouriersCount: number,
  allManagersCount: number,
  allPickersCount: number,
}

export type ISlotInfoResp = ISlotInfo[]

export interface IGroupApi {
  id: string,
  marketId: string,
  createdAt: Date,
  disable?: boolean,
  key?: string,
  products: IGroupProductApi[],
}
export interface IGroupProductApi {
  id: string,
  productId: string,
  groupId: string,
  marketId: string,
  index: number,
  root: boolean,
  product?: IProductApi,
}

export type ApiGroupsListReq = {
  marketId: string,
}
export type ApiGroupsListResp = {
  results: IGroupApi[],
}

export type ApiCreateGroupReq = Omit<IGroupApi, 'id' | 'createdAt'> & {
  products: Omit<IGroupProductApi, 'id' | 'groupId'>[],
}
export type ApiCreateGroupResp = IGroupApi

export type ApiUpdateGroupReq = Pick<IGroupApi, 'id'> &
  Partial<IGroupApi> & {
    products: Omit<IGroupProductApi, 'id' | 'createdAt'>[],
  }
export type ApiUpdateGroupResp = IGroupApi

export type ApiRemoveGroupReq = Pick<IGroupApi, 'id'>

export interface IRouteApi {
  id: string,
  marketId: string,
  courierId: string,
  createdAt: Date,
  estimateTime: number,
  startAt?: Date,
  completedAt?: Date,
  points: IRoutePointApi[],
}

export interface IRoutePointApi {
  id: string,
  index: number,
  marketId: string,
  courierId: string,
  orderId?: string,
  address: string,
  text: string,
  order?: IOrderApi,
}

export type ApiMarketRoutesReq = {
  marketId: string,
}
export type ApiMarketRoutesResp = { routes: IRouteApi[] }

export type ApiCreateMarketRouteReq = Omit<IRouteApi, 'id' | 'createdAt' | 'points'> & {
  points: Omit<IRoutePointApi, 'id'>[],
}
export type ApiCreateMarketRouteResp = { id: string }

export type ApiUpdateMarketRouteReq = Omit<IRouteApi, 'createdAt' | 'courierId' | 'points'> & {
  points: Partial<IRoutePointApi>[],
}

export type ApiRemoveMarketRouteReq = {
  req: Pick<IRouteApi, 'id'>,
  marketId: string,
}

export type ApiOrderHistoryReq = {
  orderId: string,
}

export type ApiOrderHistoryResp = TLogAction[]
export type ApiOrderHistoryData = {
  orderId: string,
  history: TLogAction[],
}

export type ApiCourierShiftsReq = {
  marketId: string,
  date: string,
}
export type ApiCourierShiftResp = {
  shiftSlots: IShiftSlotApi[],
}

export type ApiUpdateCourierShiftsReq = {
  courierId: string,
  date: string,
  shiftSlots: IShiftSlotApi[],
}
export type ApiUpdateCourierShiftsResp = {
  shiftSlots: IShiftSlotApi[],
}
