import React, { useEffect } from 'react'
import { Icon, Message } from 'semantic-ui-react'

import './index.scss'

type TProps = {
  show: boolean,
  onClose(): void,
  timeout?: number,
  title?: string,
  text?: string,
  success?: boolean,
  warning?: boolean,
  negative?: boolean,
  info?: boolean,
  error?: boolean,
}

export const Alert: React.FC<TProps> = ({
  timeout = 3000,
  onClose,
  info,
  title,
  text,
  show,
  success,
  warning,
  negative,
  error,
}) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(onClose, timeout)

      return () => clearTimeout(timer)
    }
  }, [show, timeout, onClose])

  if (!show) {
    return null
  }

  return (
    <div className='alert'>
      <div className='alert_message-container'>
        <Message
          className='alert_message-container_message'
          negative={negative}
          success={success}
          warning={warning}
          info={info}
          error={error}
        >
          <div className='alert_message-container_message_content'>
            {title && <Message.Header>{title}</Message.Header>}
            {text && <p>{text}</p>}
          </div>
          <div className='alert_message-container_message_close' onClick={onClose}>
            <Icon name='remove' />
          </div>
        </Message>
      </div>
    </div>
  )
}
