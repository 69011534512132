import React, { useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Checkbox } from 'semantic-ui-react'

import './styles.scss'

import { ICourier } from '../../types/TClient'

import { CourierCard } from '../CourierCard'
import messages from '../../localization/messages'

type TProps = {
  couriers: ICourier[],
  title: string,
} & WrappedComponentProps

const _CouriersByRole: React.FC<TProps> = ({ couriers, title, intl }) => {
  const [isOnShift, setIsOnShift] = useState<boolean>(false)
  const filteredCouriers = isOnShift ? couriers.filter((courier) => courier.isOnShift) : couriers

  return (
    <div className='couriers-role'>
      <div className='couriers-role__header'>
        <div>{title}</div>
        <div className='couriers-role__header__actions'>
          {intl.formatMessage(messages.OnlyOnShift)}
          <Checkbox
            toggle
            className='couriers-role__header__actions__check'
            checked={isOnShift}
            onChange={(_e, data) => setIsOnShift(data.checked as boolean)}
          />
        </div>
      </div>
      <div className='couriers-role__content'>
        {filteredCouriers.map((c) => (
          <CourierCard courier={c} key={c.id} />
        ))}
      </div>
    </div>
  )
}

export const CouriersByRole = injectIntl(_CouriersByRole)
