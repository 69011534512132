import React from 'react'

type TProps = {
  color?: string,
}

export const PictureIcon: React.FC<TProps> = ({ color }) => {
  return (
    <svg width='48' height='48' fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={color}
        d='M36 40.5H3A1.5 1.5 0 0 1 1.5 39V3A1.5 1.5 0 0 1 3 1.5h33A1.5 1.5 0 0 1 37.5 3v36a1.5 1.5 0 0 1-1.5 1.5Zm-31.5-3h30v-33h-30v33Z'
      />
      <path
        fill={color}
        d='M45 46.5H12a1.5 1.5 0 1 1 0-3h31.5v-33H42a1.5 1.5 0 1 1 0-3h3A1.5 1.5 0 0 1 46.5 9v36a1.5 1.5 0 0 1-1.5 1.5Z'
      />
      <path
        fill={color}
        d='M3 32.788a1.5 1.5 0 0 1-1.05-.435 1.5 1.5 0 0 1 0-2.115l6.075-6.24a4.005 4.005 0 0 1 5.22-.435l5.385 3.9a1.02 1.02 0 0 0 1.32-.12l5.55-5.58a4.125 4.125 0 0 1 5.73 0l5.82 5.895a1.5 1.5 0 0 1-2.13 2.1l-5.82-5.895a1.035 1.035 0 0 0-1.5 0l-5.565 5.625a4.02 4.02 0 0 1-5.22.45l-5.385-3.9a1.005 1.005 0 0 0-1.32.105l-6.045 6.195a1.5 1.5 0 0 1-1.065.45ZM20.775 19.291a6 6 0 1 1 0-12 6 6 0 0 1 0 12Zm0-9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z'
      />
    </svg>
  )
}
