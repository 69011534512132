import React from 'react'
import classNames from 'classnames'

import './index.scss'

type TProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const Segment: React.FC<TProps> = ({ className, ...props }) => {
  return <div className={classNames('segment', className)} {...props} />
}
