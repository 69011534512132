import { combineReducers, Reducer } from 'redux'
import moment from 'moment'

import * as Actions from './actions'
import { user, StateUser } from './reducers/user'
import { orders, StateOrders } from './reducers/orders'
import { markets, StateMarkets } from './reducers/markets'
import { sellers, StateSellers } from './reducers/sellers'
import { customers, StateCustomers } from './reducers/customers'
import { products, StateProducts } from './reducers/products'
import { couriers, StateCouriers } from './reducers/couriers'
import { notifications, StateNotifications } from './reducers/notifications'
import { analytics, StateAnalytics } from './reducers/analytics'
import { reports, StateReports } from './reducers/reports'
import { modals, StateModals } from './reducers/modals'
import { alerts, StateAlerts } from './reducers/alerts'
import { StateTags, tags } from './reducers/tags'
import { checkLang } from '../utils/langUtils'
import * as storage from '../utils/storageUtils'

export type State = Readonly<{
  modals: StateModals,
  alerts: StateAlerts,
  user: StateUser,
  orders: StateOrders,
  markets: StateMarkets,
  sellers: StateSellers,
  customers: StateCustomers,
  products: StateProducts,
  couriers: StateCouriers,
  notifications: StateNotifications,
  analytics: StateAnalytics,
  reports: StateReports,
  tags: StateTags,
  lang: string,
  appLoaded: boolean,
}>

export const appLoaded: Reducer<boolean, Actions.Action> = (s = false, a): boolean => {
  switch (a.type) {
    case Actions.APP_LOADED:
      return true
  }
  return s
}

export const lang: Reducer<string, Actions.Action> = (s = checkLang(navigator.language), a) => {
  switch (a.type) {
    case Actions.SET_INTERFACE_LANG:
      storage.setValue(storage.LANG, a.data.lang)
      moment.locale(a.data.lang)
      return a.data.lang
  }
  return s
}

export const rootReducer = combineReducers<State, Actions.Action>({
  modals,
  alerts,
  user,
  orders,
  markets,
  sellers,
  customers,
  products,
  couriers,
  notifications,
  analytics,
  reports,
  appLoaded,
  lang,
  tags,
})
