import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Icon, Popup } from 'semantic-ui-react'
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import classnames from 'classnames'
import moment from 'moment'

import './index.scss'

import { ITotalOrders } from '../../../../../types/TClient'

import messages from '../../../../../localization/messages'
import { AnalyticsBlockStat } from '../AnalyticsBlockStat'
import { round } from '../../../../../utils/ordersUtils'

type TOwnProps = {
  marketInfo: ITotalOrders,
}

type TProps = TOwnProps & WrappedComponentProps

type TState = {
  firstMonth: boolean,
}

class AnalyticsOrdersByPeriodCmp extends React.Component<TProps, TState> {
  state = {
    firstMonth: true,
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='analytics__block-container'>
        <div className='analytics__block-title'>{formatMessage(messages.NumberOrdersPerUser)}</div>
        <div className='analytics__block'>
          {this.renderHeader()}
          {this.renderChart()}
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { firstMonth } = this.state
    const { formatMessage } = this.props.intl

    return (
      <div className='analytics__block-header'>
        <div
          className={classnames('analytics__block-header__tab', firstMonth && 'analytics__block-header__tab-active')}
          onClick={() => this.setState({ firstMonth: true })}
        >
          {formatMessage(messages.FirstMonthUsersOrders)}
          <Popup
            wide
            content={formatMessage(messages.AverageOrdersFirstMonth)}
            position='top center'
            trigger={<Icon className='analytics__block-header__tab-icon' name='question circle outline' />}
          />
        </div>
        <div
          className={classnames('analytics__block-header__tab', !firstMonth && 'analytics__block-header__tab-active')}
          onClick={() => this.setState({ firstMonth: false })}
        >
          {formatMessage(messages.LastMonthUsersOrders)}
          <Popup
            wide
            content={formatMessage(messages.AverageOrdersLastMonth)}
            position='top center'
            trigger={<Icon className='analytics__block-header__tab-icon' name='question circle outline' />}
          />
        </div>
      </div>
    )
  }

  renderChart = () => {
    const { marketInfo } = this.props
    const { firstMonth } = this.state
    const { formatMessage } = this.props.intl
    let chartData = []

    if (firstMonth) {
      chartData = marketInfo.totalOrdersFirstMonthByPeriods.map((item) => ({
        name: moment()
          .year(item.year)
          .month(item.month - 1)
          .format('MMM')
          .slice(0, 3)
          .toUpperCase(),
        value: item.customers ? round(item.orders / item.customers) : 0,
      }))
    } else {
      chartData = marketInfo.totalOrdersLastMonthByPeriods.map((item) => ({
        name: moment()
          .year(item.year)
          .month(item.month - 1)
          .format('MMM')
          .slice(0, 3)
          .toUpperCase(),
        value: item.customers ? round(item.orders / item.customers) : 0,
      }))
    }

    const averageFirstMonth = marketInfo.totalCustomersFirstMonth
      ? round(marketInfo.totalOrdersFirstMonth / marketInfo.totalCustomersFirstMonth)
      : 0
    const averageLastMonth = marketInfo.totalCustomersLastMonth
      ? round(marketInfo.totalOrdersLastMonth / marketInfo.totalCustomersLastMonth)
      : 0

    return (
      <div className='analytics__block__period-chart'>
        <div className='analytics__block__period-total'>
          <AnalyticsBlockStat
            label={formatMessage(messages.AverageForPeriod)}
            value={firstMonth ? averageFirstMonth : averageLastMonth}
          />
        </div>
        <BarChart
          width={528}
          height={180}
          data={chartData}
          margin={{
            top: 5,
            right: 0,
            left: -20,
            bottom: 5,
          }}
          barSize={10}
        >
          <XAxis dataKey='name' scale='point' axisLine={false} tickLine={false} padding={{ left: 20, right: 20 }} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip />
          <Bar
            dataKey='value'
            fill='#6516ce'
            background={{ fill: '#decfeb', opacity: 0.3, radius: [5, 5, 5, 5] }}
            radius={[5, 5, 5, 5]}
          />
        </BarChart>
      </div>
    )
  }
}

export const AnalyticsOrdersByPeriod = injectIntl(AnalyticsOrdersByPeriodCmp)
