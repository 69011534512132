import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import moment from 'moment'

import './index.scss'

import { ICourier, ICouriersFilter, IShiftSlot, ISlot } from '../../../types/TClient'
import { ApiUpdateCourierShiftsReq } from '../../../types/TApi'

import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
import { Button } from '../../Button'
import { CheckIcon, TrashIcon } from '../../Icons'
import { ModalConfirm } from '../ModalConfirm'
import { formatPhone } from '../../../utils/customersUtils'

type TOwnProps = {
  courier: ICourier,
  index: number,
  shiftDate: string,
  onClose: () => void,
}

type TConnectedProps = {
  slots: ISlot[],
}

type TDispatchedProps = {
  apiCouriersList(filter: ICouriersFilter): Actions.Action,
  apiUpdateCourierShifts: (data: ApiUpdateCourierShiftsReq) => Actions.Action,
}

type Props = TOwnProps & TDispatchedProps & TConnectedProps & WrappedComponentProps

type IState = {
  shiftSlots: IShiftSlot[],
  removeShiftModal: boolean,
  changed: boolean,
  visible: boolean,
}

class CourierShiftModalCmp extends React.Component<Props, IState> {
  dropdownRef = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      shiftSlots: props.courier.shiftSlots,
      removeShiftModal: false,
      changed: false,
      visible: false,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount(): void {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <div className='courier-shift'>
        {this.renderRemoveShiftModal()}
        <div className='courier-shift__title'>{formatMessage(messages.WorkingHours)}</div>
        {this.renderCourier()}
        {this.renderSlots()}
        <div className='courier-shift__actions'>
          <div className='courier-shift__actions-remove' onClick={() => this.setState({ removeShiftModal: true })}>
            <div className='courier-shift__actions-remove__icon'>
              <TrashIcon color={'#858897'} />
            </div>
            <div className='courier-shift__actions-remove__text'>{formatMessage(messages.DeleteEmployee)}</div>
          </div>
          <Button outline title={formatMessage(messages.Cancel)} onClick={this.props.onClose} />
          <div style={{ marginLeft: '24px' }}>
            <Button title={formatMessage(messages.Save)} onClick={this.updateShift} />
          </div>
        </div>
      </div>
    )
  }

  renderCourier = () => {
    const { courier, index } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='courier-shift__courier'>
        <div className='courier-shift__courier-block'>
          <div className='courier-shift__courier-block__label'>{formatMessage(messages.Collector)}</div>
          <div className='courier-shift__courier-block__text'>
            <span className='courier-shift__courier-block__num'>№{index}.</span>
            {courier.firstName} {courier.lastName}
          </div>
        </div>
        <div className='courier-shift__courier-block'>
          <div className='courier-shift__courier-block__label'>{formatMessage(messages.Phone)}</div>
          <div className='courier-shift__courier-block__text'>{formatPhone(courier.phone)}</div>
        </div>
      </div>
    )
  }

  renderSlots = () => {
    const { slots, shiftDate } = this.props
    const { formatMessage } = this.props.intl
    const { shiftSlots } = this.state

    return (
      <div className='courier-shift__slots'>
        <div className='courier-shift__slots-title'>{formatMessage(messages.SlotsWork)}</div>
        <div className='courier-shift__slots-list'>
          {slots.map((item) => {
            const isSelected = shiftSlots.find((slot) => slot.slotId === item.id && slot.shiftDate === shiftDate)

            return (
              <div key={item.id} className='courier-shift__slots-item' onClick={() => this.toggleShift(item)}>
                <div
                  className={classnames(
                    'courier-shift__slots-item__field',
                    isSelected && 'courier-shift__slots-item__field-checked',
                  )}
                >
                  <CheckIcon color={'#fff'} />
                </div>
                <div className='courier-shift__slots-text'>
                  {item.from} — {item.to}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderRemoveShiftModal = () => {
    const { courier, index, shiftDate } = this.props
    const { formatMessage } = this.props.intl
    const { removeShiftModal } = this.state

    return (
      <ModalConfirm
        alert
        onClose={() => this.setState({ removeShiftModal: false })}
        onOk={this.removeShifts}
        open={removeShiftModal}
        title={formatMessage(messages.DeletingEmployee)}
        text={formatMessage(messages.WarningDeletingEmployee, {
          courier: `№${index}.${courier.firstName} ${courier.lastName}`,
          date: moment(shiftDate).format('DD MMMM YYYY'),
        })}
      />
    )
  }

  toggleShift = (slot: ISlot) => {
    const { courier, shiftDate } = this.props
    const { shiftSlots } = this.state
    const exist = shiftSlots.find((item) => item.slotId === slot.id && item.shiftDate === shiftDate)

    if (exist) {
      this.setState({
        shiftSlots: shiftSlots.filter((item) => !(item.slotId === slot.id && item.shiftDate === shiftDate)),
      })
    } else {
      this.setState({
        shiftSlots: [
          ...shiftSlots,
          {
            marketId: courier.marketId,
            courierId: courier.id,
            slotId: slot.id,
            shiftDate,
          },
        ],
      })
    }
  }

  updateShift = () => {
    const { courier, shiftDate } = this.props
    const { shiftSlots } = this.state

    this.props.apiUpdateCourierShifts({
      courierId: courier.id,
      date: shiftDate,
      shiftSlots,
    })
    this.props.onClose()
  }

  removeShifts = () => {
    const { courier, shiftDate } = this.props

    this.props.apiUpdateCourierShifts({
      courierId: courier.id,
      date: shiftDate,
      shiftSlots: [],
    })
    this.setState({ removeShiftModal: false })
    this.props.onClose()
  }

  handleClickOutside = (event: any) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ visible: false })
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  apiCouriersList: (filter: ICouriersFilter) => dispatch(Actions.action(Actions.API_COURIERS_LIST, filter)),
  apiUpdateCourierShifts: (data: ApiUpdateCourierShiftsReq) =>
    dispatch(Actions.action(Actions.API_UPDATE_COURIER_SHIFTS, data)),
})

const mapStateToProps = (s: State): TConnectedProps => ({
  slots: s.markets.slots,
})

export const CourierShiftModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CourierShiftModalCmp))
